import { useState, useEffect } from 'react'
import { getDeviceInfo } from '../../utils'

/**
 * useDevices returns devices information from laptop, mobile, tablet etc.
 * @return  {Object} deviceInfo
 */

export const useDevices = () => {
  const [deviceInfo, setDeviceInfo] = useState({
    audioInputDevices: [],
    videoInputDevices: [],
    audioOutputDevices: [],
    hasAudioInputDevices: false,
    hasVideoInputDevices: false,
  })

  useEffect(() => {
    const getDevices = () =>
      getDeviceInfo().then((devices) => setDeviceInfo(devices))
    if (navigator?.mediaDevices?.addEventListener) {
      navigator.mediaDevices.addEventListener('devicechange', getDevices)
    }
    getDevices()

    return () => {
      if (navigator?.mediaDevices?.removeEventListener) {
        navigator.mediaDevices.removeEventListener('devicechange', getDevices)
      }
    }
  }, [])

  return deviceInfo
}
