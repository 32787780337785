import { View } from '@react-pdf/renderer'
import {
  DisclaimerBanner,
  ScalePerYear,
  TableHeaderBlack,
  TranscriptionScriptPDF,
} from '../../../../components'
import { AreaColor, scoreIsNotAvailable, tw } from '../../../../utils'
import { useReportPdfContext } from '../../../../provider'
import { getIMCPerYearList, getIMCScaleList } from './utils'

export const IMCResults = () => {
  const context = useReportPdfContext()
  const { t, person, scores } = context
  const { score: imcScore, scoreName: imcScoreName } = scores?.imcScore || {}

  const imcScaleList = getIMCScaleList(context)
  const imcPerYearList = getIMCPerYearList(context)

  const TRANSLATION_BASE_KEY = 'initial-report:sectionNutritional.results'

  return (
    <View style={tw('flex flex-col gap-6')}>
      <TableHeaderBlack
        title={t(`initial-report:scores.imc.title`)}
        list={imcScaleList}
      />
      <ScalePerYear scales={imcPerYearList} areaColor={AreaColor.nutritional} />
      <DisclaimerBanner />
      <DisclaimerBanner
        text={
          <TranscriptionScriptPDF
            style={tw('text-xs leading-7')}
            script={`${TRANSLATION_BASE_KEY}.imc.disclaimer`}
          />
        }
      />
      {!scoreIsNotAvailable(imcScore) && (
        <TranscriptionScriptPDF
          script={`${TRANSLATION_BASE_KEY}.imc.result`}
          scriptProps={{
            patientName: person?.name,
            score: imcScore,
            scale: t(
              `${TRANSLATION_BASE_KEY}.imc.scaleValues.${imcScoreName}.valueLower`,
            ),
          }}
        />
      )}
    </View>
  )
}
