import { getRoot, types } from 'mobx-state-tree'
import { RootModel } from '@shared/data-access'

export const MODEL_NAME = 'Collective'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    companyName: types.string,
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
