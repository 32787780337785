export const chatBubbleContainerClasses = `
    flex
    gap-2
`
export const chatBubbleContentContainerClasses = `
    border
    border-[#999]
    grow
    p-2
    rounded
    rounded-lg
    text-xs
`

export const chatBubbleAvatarClasses = `
    rounded
    rounded-full
    w-[40px]
    h-[40px]
    md:w-[60px]
    md:h-[60px]
`
export const chatAuthorClasses = `
    flex
    justify-between
    items-center
`

export const chatBubbleTimeClasses = `
    text-xs
    text-[#999999]
`
