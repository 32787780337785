export const SOCIAL_REFERENT_TYPE = Object.freeze({
  normal: 0,
  coordinator: 1,
})

export const EMPLOYEE_STATE = Object.freeze({
  ACTIVE: 0,
  INACTIVE: 1,
})

export const EMPLOYEE_STATE_TEXT = Object.freeze({
  [EMPLOYEE_STATE.ACTIVE]: 'employeeState.active',
  [EMPLOYEE_STATE.INACTIVE]: 'employeeState.inactive',
})
