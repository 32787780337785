import { useEffect, useState } from 'react'
import { Filters, NewList } from './components'
import { CareUserListContainer } from './styles'
import { useLayoutContext } from '@sr/routes/common'
import { useTranslation } from 'react-i18next'

export const UserList = () => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState({})
  const { setBackButtonText } = useLayoutContext()

  useEffect(() => {
    setBackButtonText(t('dashboard.userListVcx.title'))
  }, [])

  return (
    <CareUserListContainer>
      <Filters onChangeFilters={setFilters} />
      <NewList filters={filters} />
    </CareUserListContainer>
  )
}
