import { flow, types } from 'mobx-state-tree'
import { actions } from '@sr/routes/common'
import { BaseRouteState, Policy } from '@shared/data-access'

export const MonitoringAreaRouteState = BaseRouteState.named(
  'MonitoringAreaRouteState'
)
  .props({
    policyId: types.maybeNull(types.string),
    policy: types.maybeNull(types.reference(Policy)),
    roomSid: types.maybeNull(types.string),
  })
  .actions((self) => ({
    afterAttach() {
      self.loadMonitoringDependencies()
    },
    loadMonitoringDependencies: flow(function* loadMonitoringDependencies() {
      self.setLoading('MonitoringAreaRouteState')
      try {
        const policy = self.loadPolicy()
        const room = self.getTwilioRoomSeed()

        yield Promise.all([policy, room])
      } finally {
        self.removeLoading('MonitoringAreaRouteState')
      }
    }),
    ...actions(self),
  }))
