import { CoordinatorSrList } from '@sr/routes/coordinator/sr-list'
import {
  CampaignList,
  CareList,
  OPList,
  PortfolioManagement,
  PrivateArea,
} from '@sr/routes/coordinator/private-area'
import { ROUTE_PATH } from './enums'

export const coordinatorRoutes = [
  {
    path: ROUTE_PATH.srList,
    element: <CoordinatorSrList />,
  },
  {
    path: ROUTE_PATH.srPrivateArea(':id'),
    element: <PrivateArea />,
  },
  {
    path: ROUTE_PATH.portfolioManagementCare(':id'),
    element: <PortfolioManagement />,
    children: [
      {
        index: true,
        element: <CareList />,
      },
      {
        path: ROUTE_PATH.portfolioManagementOp,
        element: <OPList type="care" />,
      },
    ],
  },
  {
    path: ROUTE_PATH.portfolioManagementCampaign(':id'),
    element: <PortfolioManagement />,
    children: [
      {
        index: true,
        element: <CampaignList />,
      },
      {
        path: ROUTE_PATH.portfolioManagementOp,
        element: <OPList type="campaign" />,
      },
    ],
  },
]
