import { ChangeEventHandler, FC } from 'react'
import { useTranslation } from 'react-i18next'

type NativeSelectProps = {
  id: string
  dataTestid?: string
  name?: string
  options?: { name: string; value: number | string }[]
  onChange?: ChangeEventHandler<HTMLSelectElement> | undefined
  selectClassName?: string
  hasNoneOption?: boolean
  value: number | string
  disabled?: boolean
}

export const NativeSelect: FC<NativeSelectProps> = ({
  id,
  dataTestid = '',
  name,
  options = [],
  onChange,
  selectClassName = '',
  hasNoneOption = false,
  value,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <select
      id={id}
      name={name}
      data-testid={dataTestid}
      disabled={disabled}
      onChange={onChange}
      className={selectClassName}
      value={value ? value : hasNoneOption ? 'none' : undefined}
    >
      {hasNoneOption && (
        <option disabled value="none">
          {t('common:select')}
        </option>
      )}
      {options.map(({ value, name }) => {
        const isValid = (value === 0 || value) && name
        return (
          isValid && (
            <option
              key={`select-option-${value}`}
              data-testid="select-option"
              value={value}
            >
              {name}
            </option>
          )
        )
      })}
    </select>
  )
}
