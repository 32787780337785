import tw from 'tailwind-styled-components'

export const TranscriptionScriptBold = tw.span`
  font-semibold
`

export const TranscriptionScriptItalic = tw.span`
  italic
`

export const TranscriptionScriptItalicUnderline = tw(TranscriptionScriptItalic)`
  underline
`

export const TranscriptionScriptBoldUnderline = tw(TranscriptionScriptBold)`
  underline
`

export const TranscriptionScriptBox = tw.div`
  my-8
  px-2
  py-4
  border
  border-zinc-300
  rounded
`
