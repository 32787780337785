import { FC } from 'react'
import {
  FilterBoxContainer,
  FilterBoxHeader,
  FilterBoxHeaderIconContainer,
  FilterBoxHeaderRemoveContainer,
} from './styles'
import { useTranslation } from 'react-i18next'
import { AudioConsole } from '@carbon/icons-react'

type SRListFilterBoxProps = {
  children: JSX.Element | JSX.Element[]
  onClear: () => void
}

export const SRListFilterBox: FC<SRListFilterBoxProps> = ({
  children,
  onClear,
}) => {
  const { t } = useTranslation()

  return (
    <FilterBoxContainer>
      <FilterBoxHeader>
        <FilterBoxHeaderIconContainer>
          <AudioConsole />
          {t('userList.filter.title')}
        </FilterBoxHeaderIconContainer>

        <FilterBoxHeaderRemoveContainer onClick={onClear}>
          {t('userList.filter.removeAll')}
        </FilterBoxHeaderRemoveContainer>
      </FilterBoxHeader>
      {children}
    </FilterBoxContainer>
  )
}
