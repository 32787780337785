export const Paragraph = ({
  primary = false,
  children,
  className,
  ...rest
}) => {
  return (
    <p
      data-testid="Paragraph"
      {...rest}
      className={`${className} ${
        primary ? 'text-primary' : 'text-black'
      } text-base lg:text-xl`}
    >
      {children}
    </p>
  )
}
