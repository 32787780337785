import { getRoot, types } from 'mobx-state-tree'
import { DateString, RootModel } from '@shared/data-access'

export const MODEL_NAME = 'GenericAnalysisResults'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    testResults: types.maybeNull(types.string),
    genericAnalysisDate: types.maybeNull(DateString),
    genericAnalysisResultsDate: types.maybeNull(DateString),
  })
  .views((self) => ({
    get parsed() {
      return self.testResults && JSON.parse(self.testResults)
    },
  }))
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))

export default Model
