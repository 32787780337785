import { Select } from '@shared/ui'
import { useFormContext } from 'react-hook-form'
import {
  errorSpanClassName,
  inputClassName,
  selectErrorClassName,
} from '../styles'
import { FC } from 'react'

export type SRSelectOptions = {
  name: string
  disabled?: boolean
  value: string | number
}[]

interface SRSelectProps {
  name: string
  options: SRSelectOptions
  hasNoneOption?: boolean
  defaultValue?: undefined | string | number
  disabled?: boolean
  selectClasses?: string
  error?: string
  placeholder?: string
}

export const SRSelect: FC<SRSelectProps> = ({
  name,
  options,
  hasNoneOption,
  defaultValue,
  disabled,
  selectClasses = '',
  error = '',
  placeholder = '',
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  const hasError = errors[name] || error
  const errorMessage = error || (errors[name]?.message as string)

  return (
    <div>
      <Select
        selectClassName={`${inputClassName} ${selectClasses} ${
          hasError ? selectErrorClassName : ''
        }`}
        name={name}
        options={options}
        hasNoneOption={hasNoneOption}
        defaultValue={defaultValue}
        disabled={disabled}
        placeholder={placeholder}
      />
      {hasError && <span className={errorSpanClassName}>* {errorMessage}</span>}
    </div>
  )
}
