import {
  isServerError,
  isNotFound,
  isUnauthorized,
  isForbidden,
  isBadRequest,
} from '@shared/utils'

export const errorCallback = ({
  error,
  self,
  onBadRequest = () => {},
  onForbiddenRequest = () => {},
  onNotFoundRequest = () => {},
  onServerErrorRequest = () => {},
}) => {
  const errorStatus = error?.response?.status
  const locale = self?.locale || window.__locale__

  const routeControl = (errorStatus) =>
    window.location.href.indexOf(String(errorStatus)) === -1

  if (isBadRequest(errorStatus)) {
    return onBadRequest({ locale, errorStatus, error })
  }
  if (isForbidden(errorStatus) || isUnauthorized(errorStatus)) {
    return onForbiddenRequest({ locale, errorStatus })
  }
  if (isNotFound(errorStatus)) {
    return onNotFoundRequest({ locale, errorStatus, error })
  }
  if (isServerError(errorStatus)) {
    return onServerErrorRequest({ locale, errorStatus })
  }

  if (routeControl(errorStatus)) {
    throw error
  }
}
