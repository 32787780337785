import * as yup from 'yup'
import { EVENT_FORM } from '../../common'

const TRANSLATION_BASE_KEY = 'agenda.formModal.labels'

export const useSchemaGeneric = (t) => {
  return yup.object().shape({
    [EVENT_FORM.appointmentType]: yup
      .number()
      .required()
      .label(t(`${TRANSLATION_BASE_KEY}.appointmentType`)),
    [EVENT_FORM.appointmentDay]: yup
      .string()
      .required()
      .label(t(`${TRANSLATION_BASE_KEY}.appointmentDay`)),
    [EVENT_FORM.startDate]: yup
      .string()
      .required()
      .label(t(`${TRANSLATION_BASE_KEY}.startDate`)),
    [EVENT_FORM.endDate]: yup
      .string()
      .label(t(`${TRANSLATION_BASE_KEY}.endDate`)),
    [EVENT_FORM.formPersonId]: yup
      .string()
      .required()
      .label(t(`${TRANSLATION_BASE_KEY}.personId`)),
  })
}
