import { yupResolver } from '@hookform/resolvers/yup'
import { Button, BUTTON_COLORS, BUTTON_SIZES } from '@shared/ui'
import { useRouteState } from '@shared/utils'
import { SrProfile, UserInfo } from '@sr/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSchema } from './schema'
import { MyProfileState } from './state'
import { MyProfileWrapper } from './styles'
import { UserInfoForm } from './components/user-info-form'
import { ModalConfirmUnsuscribe } from './components/modal-confirm-unsuscribe'
import { useParams } from 'react-router-dom'
import { useLayoutContext } from '@sr/routes/common'

const ActionButton = ({ onClick, text }) => {
  return (
    <Button onClick={onClick} size={BUTTON_SIZES.small}>
      {text}
    </Button>
  )
}

export const MyProfile = observer(() => {
  const { id } = useParams()

  const {
    isLoading,
    user,
    socialReferent,
    saveSocialReferent,
    personalData,
    unsubscribeSocialReferent,
    toggleLanguage,
    activeLanguage,
  } = useRouteState(MyProfileState, { socialReferentId: id })

  const [editSocialReferent, setEditSocialReferent] = useState(false)
  const { t } = useTranslation()

  const [openModalConfirmUnsuscribe, setOpenModalConfirmUnsuscribe] =
    useState(false)

  const schema = useSchema(t, socialReferent)
  const methods = useForm({
    resolver: yupResolver(schema),
  })

  const { handleSubmit, reset } = methods

  const onClickEditSocialReferent = () => {
    reset()
    setEditSocialReferent(!editSocialReferent)
  }
  const { setIsLoading, setBackButtonText } = useLayoutContext()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    setBackButtonText(t('myProfile.title'))
  }, [socialReferent])

  const onSubmit = handleSubmit(async (form) => {
    await saveSocialReferent(form)
    setEditSocialReferent(false)
    reset()
  })

  const doUnsuscribe = () => {
    setOpenModalConfirmUnsuscribe(false)
    unsubscribeSocialReferent()
  }

  const canAppearUnsuscribeButton =
    !editSocialReferent &&
    !socialReferent?.isCoordinator &&
    socialReferent?.isActive &&
    socialReferent?.canSuspendSr

  return (
    <>
      {openModalConfirmUnsuscribe && (
        <ModalConfirmUnsuscribe
          user={socialReferent}
          onClose={() => setOpenModalConfirmUnsuscribe(false)}
          onConfirm={doUnsuscribe}
        />
      )}
      <MyProfileWrapper>
        <SrProfile
          actionButton={
            <ActionButton
              onClick={onClickEditSocialReferent}
              text={editSocialReferent ? t('common:cancel') : t('common:edit')}
            />
          }
          fullName={socialReferent?.fullName}
          image={socialReferent?.pictureUrl}
          toggleLanguage={toggleLanguage}
          activeLanguage={activeLanguage}
        >
          {editSocialReferent && socialReferent ? (
            <UserInfoForm
              onSave={onSubmit}
              methods={methods}
              user={socialReferent}
              isCoordinator={user?.isCoordinator}
              activeLanguage={activeLanguage}
            />
          ) : (
            socialReferent && <UserInfo data={personalData} />
          )}
        </SrProfile>

        {canAppearUnsuscribeButton && (
          <Button
            color={BUTTON_COLORS.secondary}
            size={BUTTON_SIZES.small}
            className="mt-4 mx-auto"
            onClick={() => setOpenModalConfirmUnsuscribe(true)}
            type="submit"
          >
            Dar de baja OP
          </Button>
        )}
      </MyProfileWrapper>
    </>
  )
})
