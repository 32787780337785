import { flow, types } from 'mobx-state-tree'
import { BaseRouteState, ServiceSlotModel } from '@shared/data-access'
import { actionsCampaign } from '@sr/routes/common'
import { AnnuityModel } from '@sr/data-access'

export const UserAreaAgendaRouteState = BaseRouteState.named(
  'UserAreaAgendaRouteState',
)
  .props({
    annuityId: types.maybeNull(types.string),
    serviceId: types.maybeNull(types.string),
    slots: types.array(types.reference(ServiceSlotModel)),
    annuity: types.maybeNull(types.reference(AnnuityModel)),
  })
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('UserAreaAgendaRouteState')
      try {
        yield self.loadAnnuity()
        yield self.loadServiceSlots()
      } finally {
        self.removeLoading('UserAreaAgendaRouteState')
      }
    }),
    reload() {
      self.loadDependencies()
    },
    ...actionsCampaign(self),
  }))
