import { useReportPdfContext } from '../report-pdf-provider'
import {
  ExecutiveSummary,
  GeneralRecommendations,
} from '../../report-pdf/sections'

export const SectionTwo = () => {
  const { executiveSummary } = useReportPdfContext()

  return (
    <>
      {executiveSummary && <ExecutiveSummary number={2} />}
      <GeneralRecommendations number={3} />
    </>
  )
}
