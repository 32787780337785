import { SRBigCalendarEventWeekWrapperStyle } from '../styles'

export const SRBigCalendarEventWeekWrapper = ({
  components,
  style = {},
  event,
  onClick,
  ...rest
}) => {
  if (!event) return

  const {
    appointmentType: { color, textColor = '' },
  } = event

  const Event = components.event
  const colorClasses = color ? `bg-${color}-base border-${color}-tone` : ''

  return (
    <div
      style={SRBigCalendarEventWeekWrapperStyle(style)}
      className={`absolute rounded border ${colorClasses} ${textColor}`}
      onClick={onClick}
      data-testid="bc-event-week-wrapper"
    >
      <Event event={event} {...rest} />
    </div>
  )
}
