import { getRoot, types } from 'mobx-state-tree'
import { Policy, RootModel, SocialReferent } from '@shared/data-access'

export const MODEL_NAME = 'SocialReferentChanges'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    policiesList: types.maybeNull(types.array(types.reference(Policy))),
    socialReferent: types.maybeNull(types.reference(SocialReferent)),
    reason: types.maybeNull(types.number),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
