import { Survey } from 'survey-react-ui'
import 'survey-core/defaultV2.min.css'
import { StylesManager } from 'survey-core'
import 'survey-core/survey.i18n'
import './index.css'
import { cssClassesForSurvey } from './css-classes'
import { useTranslation } from 'react-i18next'
import { exitButtonClasses, extraActionButtonClasses } from './styles'
import { useEffect } from 'react'

StylesManager.applyTheme('defaultV2')

export const SRSurvey = ({
  survey,
  onChange,
  onComplete,
  completeText,
  onExit,
  extraAction,
  extraActionText,
  extraActionVisible,
  hideNavigation = false,
  autoValidate = false,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    survey.locale = 'es'
    survey.onValueChanging.add(onChange)
    survey.css = cssClassesForSurvey
    survey.onComplete.add((sender, options) => onComplete(sender, options))
    survey.completeText = completeText

    if (hideNavigation) {
      survey.showNavigationButtons = false
    }

    if (onExit) {
      survey.addNavigationItem({
        id: 'survey-on-exit-button',
        css: exitButtonClasses,
        title: t('common:exit'),
        visibleIndex: 49,
        action: () => onExit(),
      })
    }

    if (extraAction) {
      survey.addNavigationItem({
        id: 'survey-extra-action-button',
        css: extraActionButtonClasses,
        title: extraActionText,
        visibleIndex: 51,
        visible: extraActionVisible,
        action: () => extraAction(),
      })
    }

    if (autoValidate) {
      survey.validate()
    }
  }, [survey])

  return <Survey model={survey} />
}
