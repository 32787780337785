import { View } from '@react-pdf/renderer'
import { AreaColor, scoreIsNotAvailable, tw } from '../../../../utils'
import {
  DisclaimerBanner,
  ScalePerYear,
  TableHeaderBlack,
  TranscriptionScriptPDF,
} from '../../../../components'
import { useReportPdfContext } from '../../../../provider'
import { getRichmondPerYearList, getRichmondScaleList } from './utils'

export const RichmondTable = () => {
  const context = useReportPdfContext()
  const { t, scores } = context

  return (
    <View style={tw('flex flex-col gap-6')}>
      <TableHeaderBlack
        title={t(`initial-report:scores.richmond.title`)}
        list={getRichmondScaleList(context)}
      />
      <DisclaimerBanner />
      <ScalePerYear
        scales={getRichmondPerYearList(context)}
        areaColor={AreaColor.tobaccoAlcohol}
        none={scoreIsNotAvailable(scores?.richmondScore?.score)}
      />
      <View>
        <TranscriptionScriptPDF
          script={`initial-report:scores.richmond.high.description`}
        />
        <TranscriptionScriptPDF
          script={`initial-report:scores.richmond.medium.description`}
        />
        <TranscriptionScriptPDF
          script={`initial-report:scores.richmond.doubt.description`}
        />
        <TranscriptionScriptPDF
          script={`initial-report:scores.richmond.low.description`}
        />
      </View>
    </View>
  )
}
