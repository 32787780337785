import { flow, types } from 'mobx-state-tree'
import { actions } from '@sr/routes/common'
import { t } from 'i18next'
import { getDateFormatted } from '@shared/utils'
import {
  APPOINTMENT_TYPES_TRANSLATION,
  BaseRouteState,
} from '@shared/data-access'
import { Appointment } from '@sr/data-access'

export const MonitoringAreaAgendaRouteState = BaseRouteState.named(
  'MonitoringAreaAgendaRouteState'
)
  .props({
    policyId: types.maybeNull(types.string),
    appointments: types.maybeNull(types.array(types.reference(Appointment))),
  })
  .views((self) => ({
    get appointmentsFiltered() {
      return self.appointments?.map(({ startDate, appointmentType }) => {
        if (!startDate && !appointmentType) return {}
        return {
          startDate: getDateFormatted(startDate, 'YYYY-MM-DD'),
          appointmentType: t(APPOINTMENT_TYPES_TRANSLATION[appointmentType]),
        }
      }, [])
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadMonitoringAgendaDependencies()
    },
    loadMonitoringAgendaDependencies: flow(
      function* loadMonitoringAgendaDependencies() {
        self.setLoading('MonitoringAreaAgendaRouteState')
        try {
          yield self.loadAgenda()
        } finally {
          self.removeLoading('MonitoringAreaAgendaRouteState')
        }
      }
    ),
    ...actions(self),
  }))
