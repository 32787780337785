import { BaseRouteState } from '@shared/data-access'
import { GLOBAL_ENVIRONMENT } from '@shared/utils'
import { ConversationMetaData, ConversationParticipants } from '@sr/data-access'
import { actions } from '@sr/routes/common'
import { flow, getEnv, getRoot, isRoot, types } from 'mobx-state-tree'

export const HeaderActionsRouteState = BaseRouteState.named(
  'HeaderActionsRouteState',
)
  .props({
    conversationMetaData: types.maybeNull(
      types.reference(ConversationMetaData),
    ),
    conversationParticipants: types.maybeNull(
      types.array(types.reference(ConversationParticipants)),
    ),
    interval: false,
  })
  .views((self) => ({
    get unreadMessages() {
      return self.conversationMetaData?.unreadMessagesCount || 0
    },
    get conversationsList() {
      if (!self.conversationParticipants) return []
      return self.conversationParticipants.map(
        ({ conversation, unreadMessagesCount }) => ({
          unreadMessagesCount,
          ...conversation,
        }),
      )
    },
    getConversationBySid(roomSid) {
      if (!self.conversationParticipants) return []
      return self.conversationParticipants.findIndex(
        ({ conversation }) => conversation.sid === roomSid,
      )
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadAppDependencies()
    },
    loadAppDependencies: flow(function* loadAppDependencies() {
      self.setLoading('HeaderActionsRouteState')

      try {
        yield self.getUnreadMessagesCount()
        yield self.getConversations()

        if (!self.interval) {
          self.interval = true
          setInterval(async () => {
            await self.getUnreadMessagesCount()
            await self.getConversations()
          }, 20000)
        }
      } finally {
        self.removeLoading('HeaderActionsRouteState')
      }
    }),
    getLocalPing: flow(function* getLocalPing() {
      if (process.env.NX_ENV === GLOBAL_ENVIRONMENT.develop) {
        yield getEnv(self).client.get(
          `http://localhost:8000/auth_test/fetch_room_metadata`,
        )
      }
    }),
    getUnreadMessagesCount: flow(function* getUnreadMessagesCount() {
      self.setLoading('getUnreadMessagesCount')
      if (isRoot(self)) return
      try {
        //yield self.getLocalPing()
        const response = yield getRoot(self).ConversationMetaDataStore.get(
          null,
          { throwError: true },
        )

        if (response) {
          self.conversationMetaData = response
        }
      } finally {
        self.removeLoading('getUnreadMessagesCount')
      }
    }),
    reload: flow(function* reload() {
      self.setLoading('reload')
      try {
        yield self.loadAppDependencies()
      } finally {
        self.removeLoading('reload')
      }
    }),
    updateItemMessages({ roomSid, count }) {
      self.setLoading('updateItemMessages')
      try {
        const itemIndex = self.getConversationBySid(roomSid)
        const conversation = self.conversationParticipants?.[itemIndex]
        if (conversation) {
          conversation.updateUnreadMessages(count)
        }
      } finally {
        self.removeLoading('updateItemMessages')
      }
    },
    ...actions(self),
  }))
