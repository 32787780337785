import { BaseRouteState } from '@shared/data-access'
import { AnnuityModel } from '@sr/data-access'
import { actionsCampaign } from '@sr/routes/common'
import { flow, types } from 'mobx-state-tree'

export const UserAreaCampaignRouteState = BaseRouteState.named(
  'UserAreaCampaignRouteState'
)
  .props({
    annuityId: types.string,
    annuity: types.maybeNull(types.reference(AnnuityModel)),
  })
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('UserAreaCampaignRouteState')
      try {
        yield self.loadAnnuity()
      } finally {
        self.removeLoading('UserAreaCampaignRouteState')
      }
    }),
    ...actionsCampaign(self),
  }))
