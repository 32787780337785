import { VideoOff } from '@carbon/icons-react'
import {
  PreviewScreenVideo,
  SelectCameraContainer,
  UnsupportedMediaText,
  UnsupportedMediaVideo,
  selectClassName,
} from './styles'
import { useTranslation } from 'react-i18next'
import { Form, Select } from '../../../../../form'
import { useVideoContext } from '../../../../hooks'
import { useVideoInputOptions } from '../../../../hooks/useVideoInputsOptions'
import { VideoTrack } from '../../../meet-screen/components/video-track'
import { SELECTED_VIDEO_INPUT_KEY, videoDeviceChange } from '../../../../utils'
import { useForm } from 'react-hook-form'
import { ChangeEvent, FC } from 'react'

type CameraPreviewProps = {
  mediaError?: boolean
}

export const CameraPreview: FC<CameraPreviewProps> = ({
  mediaError = false,
}) => {
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { localTracks, branding, changeVideoTrack } = useVideoContext()

  const videoTrack = localTracks?.find((track: any) => track.kind === 'video')

  const videoInputOptions = useVideoInputOptions()

  const selectedVideoDeviceId = window.localStorage.getItem(
    SELECTED_VIDEO_INPUT_KEY,
  )

  const handleVideoDeviceChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): void =>
    videoDeviceChange(
      event,
      selectedVideoDeviceId,
      changeVideoTrack,
      SELECTED_VIDEO_INPUT_KEY,
    )

  const methods = useForm()

  return (
    <div>
      <PreviewScreenVideo>
        {mediaError ? (
          <UnsupportedMediaVideo>
            <VideoOff
              size={150}
              className={`mx-auto ${branding?.primaryColor}`}
            />
            <UnsupportedMediaText>
              {t('common:videoCall.mediaError')}
            </UnsupportedMediaText>
          </UnsupportedMediaVideo>
        ) : (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <VideoTrack className="rounded-xl" track={videoTrack} isLocal />
        )}
      </PreviewScreenVideo>
      {videoInputOptions.length > 0 && (
        <SelectCameraContainer>
          <Form methods={methods} className="w-full lg:w-[300px]">
            <Select
              name="videoDevice"
              options={videoInputOptions}
              onChange={handleVideoDeviceChange}
              placeholder={t('common:videoCall.placeholderCameras')}
              selectClassName={selectClassName}
            />
          </Form>
        </SelectCameraContainer>
      )}
    </div>
  )
}
