import * as yup from 'yup'
import { EVENT_FORM } from '../../common'
import { isGreaterTime } from '@shared/utils'

const TRANSLATION_BASE_KEY = 'agenda.formModal.labels'

export const useSchemaEvent = (t, isEdit, needsTracking) => {
  return yup.object().shape({
    [EVENT_FORM.appointmentType]: yup
      .number()
      .required()
      .label(t(`${TRANSLATION_BASE_KEY}.appointmentType`)),
    [EVENT_FORM.appointmentDay]: yup
      .string()
      .required()
      .label(t(`${TRANSLATION_BASE_KEY}.appointmentDay`)),
    [EVENT_FORM.startDate]: yup
      .string()
      .required()
      .label(t(`${TRANSLATION_BASE_KEY}.startDate`)),
    [EVENT_FORM.endDate]: yup
      .string()
      .required()
      .test(
        'is-greater',
        t(`agenda.formModal.formError.minEndDate`),
        (value, { parent }) => {
          const isValid = (isGreaterTime(value, parent.startDate) !== true)
          return isValid 
        },
      )
      .label(t(`${TRANSLATION_BASE_KEY}.endDate`)),
    ...(!isEdit && {
      [EVENT_FORM.appointmentPolicy]: yup
        .string()
        .required()
        .label(t(`${TRANSLATION_BASE_KEY}.appointmentPolicy`)),
    }),
    ...(needsTracking && {
      [EVENT_FORM.changeOrigin]: yup
        .number()
        .typeError(t('agenda.formModal.formError.changeOrigin'))
        .required()
        .label(t(`${TRANSLATION_BASE_KEY}.changeOrigin`)),
      [EVENT_FORM.changeReason]: yup
        .number()
        .typeError(t('agenda.formModal.formError.changeReason'))
        .required()
        .label(t(`${TRANSLATION_BASE_KEY}.changeReason`)),
    }),
  })
}
