import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { TFunction } from 'i18next'

import { configOptions } from '../form-block/interfaces'
import { SRCheckbox } from '@sr/ui'
import { SRBlockRadioButton } from './components/radiobutton'
import { Button, BUTTON_TYPES, Form } from '@shared/ui'

import { useRouteState } from '@shared/utils'

import { ProfileCareActionsRouteState } from '../../actions/care-actions/state'
import { flattenObject } from '@shared/utils'

interface SectionBlockProps {
  id?: string
  data?: (t: TFunction) => configOptions
  field: string
}
interface FormData {
  [key: string]: Record<string, boolean>
}

export const SectionBlock: React.FC<SectionBlockProps> = ({
  id,
  data,
  field,
}) => {
  const { id: policyId } = useParams()
  const { t } = useTranslation()

  const { setClientPreferences, isLoading } = useRouteState(
    ProfileCareActionsRouteState,
    {
      policyId,
    },
  )

  const [isEditing, setIsEditing] = useState(false)
  const methodsChangePreferences = useForm<FormData>()
  const { control } = methodsChangePreferences

  const [originalRadioValue, setOriginalRadioValue] = useState<
    string | number | boolean | boolean[] | string[] | null | undefined
  >(undefined)
  const [originalRadioLabel, setOriginalRadioLabel] = useState<
    string | undefined
  >(undefined)
  const [originalCheckboxLabel, setOriginalCheckboxLabel] = useState<
    string[] | undefined
  >(undefined)

  const openEdit = () => {
    setIsEditing(true)
    setOriginalRadioValue(selectedRadioValue)
    setOriginalRadioLabel(selectedRadioLabel)
    setOriginalCheckboxLabel(selectedCheckboxLabel)
  }

  const closeEdit = () => setIsEditing(false)

  const configOptions = data ? data(t) : null

  const [selectedRadioValue, setSelectedRadioValue] = useState<
    string | number | boolean | boolean[] | string[] | null | undefined
  >(undefined)
  const [selectedCheckboxLabel, setSelectedCheckboxLabel] = useState(
    configOptions?.selectedCheckboxLabels,
  )
  const [selectedRadioLabel, setSelectedRadioLabel] = useState(
    configOptions?.selectedRadioLabel,
  )

  useEffect(() => {
    if (isLoading) {
      console.log('Client preferences are loading...')
    } else {
      if (configOptions?.type === 'checkbox') {
        if (
          configOptions?.selectedCheckboxLabels &&
          configOptions?.selectedCheckboxLabels.length > 0
        ) {
          setSelectedCheckboxLabel(configOptions.selectedCheckboxLabels)
        }
      } else {
        if (
          configOptions?.selectedRadioLabel &&
          configOptions?.selectedRadioLabel.length > 0
        ) {
          setSelectedRadioLabel(configOptions.selectedRadioLabel)
          setSelectedRadioValue(configOptions.selected)
        }
      }
    }
  }, [isLoading])

  const extractFirstKey = (
    flattenedObject: Record<string, any>,
  ): string | null => {
    const keys = Object.keys(flattenedObject)
    return keys.length > 0 ? keys[0] : null
  }

  const submit: SubmitHandler<FormData> = (data) => {
    const firstkey = extractFirstKey(data)
    if (
      firstkey === 'additional_services' ||
      firstkey === 'prefered_channel' ||
      firstkey === 'schedule_prefer'
    ) {
      setClientPreferences(flattenObject(data), id)
    } else {
      setClientPreferences(data, id)
    }
    closeEdit()
  }

  function handleCancel() {
    setSelectedRadioValue(originalRadioValue)
    setSelectedRadioLabel(originalRadioLabel)
    setSelectedCheckboxLabel(originalCheckboxLabel)
    closeEdit()
  }

  return (
    <>
      <div
        className="mb-3 grid grid-cols-[3fr_1fr]"
        data-testid={field}
        onSubmit={submit as any}
      >
        <h3 className="text-base font-semibold text-black mb-3">
          {configOptions?.title?.label || 'Estado del cliente'}
        </h3>
        <div className="flex flex-col items-end pr-[55px]">
          {isEditing ? (
            <button
              title={t('common:cancel')}
              className="underline text-primary text-base font-semibold cursor-pointer"
              onClick={handleCancel}
            >
              {t('common:cancel')}
            </button>
          ) : (
            <button
              onClick={openEdit}
              className="underline text-black text-base text-primary font-semibold cursor-pointer"
            >
              {t('userArea.profile.actions.editProfile.button')}
            </button>
          )}
        </div>

        {!isEditing && (
          <span
            className="text-dark-gray font-normal text-base"
            data-testid="service-info"
          >
            {configOptions?.type === 'checkbox' &&
              selectedCheckboxLabel?.map((label, index) =>
                label ? (
                  <span key={index}>
                    {label}
                    <br />
                  </span>
                ) : (
                  ''
                ),
              )}
            {selectedRadioLabel ? (
              <span>{selectedRadioLabel}</span>
            ) : selectedCheckboxLabel ? (
              ''
            ) : (
              t('userArea.profile.actions.services.form.noInfo')
            )}
          </span>
        )}

        {isEditing && (
          <Form methods={methodsChangePreferences} onSubmit={submit}>
            <div className="mb-3">
              <ul className="text-base font-normal text-black">
                {configOptions?.options?.map((option, index) => (
                  <li key={`${option.type}-${index}`} className="w-full mb-2">
                    {option.type === 'checkbox' ? (
                      <Controller
                        name={field}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <SRCheckbox
                            label={option.label}
                            checked={option.selected}
                            onChange={(checked) => {
                              if (checked) {
                                if (
                                  typeof option.value === 'string' ||
                                  typeof option.value === 'number'
                                ) {
                                  const newValue = {
                                    ...value,
                                    [String(option.value)]: checked,
                                  }
                                  onChange(newValue)
                                  setSelectedCheckboxLabel((prevLabels) => {
                                    if (checked) {
                                      return [
                                        ...(prevLabels || []),
                                        option.label,
                                      ]
                                    } else {
                                      return (
                                        prevLabels?.filter(
                                          (label) => label !== option.label,
                                        ) || []
                                      )
                                    }
                                  })
                                }
                              } else {
                                const newValue = {
                                  ...value,
                                  [String(option.value)]: null,
                                }

                                onChange(newValue)
                                setSelectedCheckboxLabel(undefined)
                              }
                            }}
                            className="accent-[#004039] !py-1"
                          />
                        )}
                      />
                    ) : (
                      <Controller
                        name={field}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <SRBlockRadioButton
                            label={option.label}
                            className="accent-[#004039]"
                            value={
                              option.value !== undefined ? option.value : null
                            }
                            checked={selectedRadioValue === option.value}
                            onChange={(
                              val: string | number | boolean | null,
                              label,
                            ) => {
                              onChange(val)
                              if (typeof val === 'number') {
                                onChange(val)
                                setSelectedRadioValue(val)
                                setSelectedRadioLabel(label)
                              }
                            }}
                            name={field}
                          />
                        )}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <Button type={BUTTON_TYPES.submit}>{t('common:save')}</Button>
          </Form>
        )}
      </div>
      <hr className="my-3" />
    </>
  )
}
