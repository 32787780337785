import { useReportPdfContext } from '../../provider'
import { AreaColor } from '../../utils'
import { FC, ReactElement } from 'react'
import { LayoutPage } from '../../components'
import { Pfeiffer } from './pfeiffer'

type SectionCognitiveProps = {
  children: ReactElement
  sectionNumber: string
}

export const SectionCognitive: FC<SectionCognitiveProps> = ({
  children,
  sectionNumber,
}) => {
  const { t } = useReportPdfContext()

  return (
    <LayoutPage
      headerText={t('initial-report:sectionCognitive.layout.headerText', {
        number: sectionNumber,
      })}
      noSpacing
      section={AreaColor.cognitive}
      sectionNumber={sectionNumber}
    >
      <Pfeiffer />

      {children}
    </LayoutPage>
  )
}
