import { Text, View } from '@react-pdf/renderer'
import { FC } from 'react'
import { SubSection, TranscriptionScriptPDF } from '../../../components'
import { tw } from '../../../utils/styles'
import { useReportPdfContext } from '../../../provider'
import { getRecommendations } from '../../../utils'

type TobaccoAndAlcoholObjectivesProps = {
  sectionLetter: string
}

export const TobaccoAndAlcoholObjectives: FC<
  TobaccoAndAlcoholObjectivesProps
> = ({ sectionLetter }) => {
  const { t, recommendations, person } = useReportPdfContext()
  const { ALCOHOL, TOBACCO } = recommendations || { ALCOHOL: [], TOBACCO: [] }

  const defaultKeyTobacco = 'rec_tobacco_congratulations_no_smoking'
  const defaultKeyAlcohol = 'rec_alcohol_congratulations_no_drinking'

  const tobaccoObjectives: string[] = getRecommendations({
    recKeys: TOBACCO,
    generalKey: '',
    defaultKey: defaultKeyTobacco,
  })

  const alcoholObjectives: string[] = getRecommendations({
    recKeys: ALCOHOL,
    generalKey: '',
    defaultKey: defaultKeyAlcohol,
  })

  // At the moment they only want to show one objective per type
  const objectives = [alcoholObjectives[0], tobaccoObjectives[0]]

  const TRANSLATION_BASE_KEY =
    'initial-report:sectionTobaccoAndAlcohol.objectives'

  return (
    <View style={tw('px-12')}>
      <SubSection
        title={t('initial-report:subSection.objectives.title', {
          letter: sectionLetter,
        })}
      >
        <Text style={tw('pb-4')}>
          {t('initial-report:generic.objectives.doubleObjective')}
        </Text>

        {objectives.map((key, ix) => {
          if (!key) return null
          return (
            <View
              style={
                (tw('flex flex-row items-center gap-2 pl-4 pt-2'),
                { position: 'relative' })
              }
              key={`list-item-${ix}`}
            >
              <Text
                style={
                  (tw('font-bold'),
                  { position: 'absolute', top: '1px', left: '0' })
                }
              >
                •
              </Text>
              <TranscriptionScriptPDF
                key={`objective-${key}`}
                script={`${TRANSLATION_BASE_KEY}.${key}`}
                scriptProps={{ patientName: person?.name }}
                style={
                  (tw('font-bold'),
                  { position: 'relative', left: '10px', marginBottom: '10px' })
                }
              />
            </View>
          )
        })}
      </SubSection>
    </View>
  )
}
