import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
  compareDates,
  getDateFormatted,
  getDateFormattedFromSchedule,
  setLocalStorageItem,
} from '@shared/utils'
import { ScheduleRadioButton } from './schedule-radio-button'

interface ScheduleHourProps {
  slotStart: string,
  slotEnd: string,
  isAvailable: boolean,
  isSelected: boolean,
  className?: string,
  disabled?: boolean,
  fieldName: string,
  watchValue: string | Date | null | undefined,
  noDigital: boolean,
  modifyDate: Date | string | null,
  setModifyDate: (date: Date | string | null) => void,
  isMod: boolean
}

export const ScheduleHour: React.FC<ScheduleHourProps> = observer(
  ({
    className = '',
    disabled = false,
    fieldName,
    watchValue,
    noDigital,
    modifyDate,
    slotStart,
    slotEnd,
    isAvailable,
    isSelected,
    setModifyDate,
    isMod,
  }) => {

    const startHour = getDateFormattedFromSchedule(slotStart, 'HH:mm')
    const endHour = getDateFormattedFromSchedule(slotEnd, 'HH:mm')
    const dateFormatted = getDateFormatted(slotStart, '')

    const disabledClasses = disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
    const heightClasses = noDigital ? 'h-28' : 'h-8'
    const checkClasses = isSelected ? 'bg-primary text-white font-bold' : 'bg-vcx-dark-white'

    const handleClick = (date: any, startHour: string, endHour: string) => {
      if (isSelected) return
      
      const previousDate = localStorage.getItem('dateSelected')
    
      if (previousDate) {
        setLocalStorageItem('previousDateSelected', previousDate)
      }
      setLocalStorageItem('dateSelected', date)
      setLocalStorageItem('slotsSelected', `${startHour} - ${endHour}`)
      setLocalStorageItem('clicked', true)
      setModifyDate(date)
    }

    return (
      <button
        type="button"
        className={`${checkClasses} ${heightClasses} ${className} ${disabledClasses}`}
        data-testid={dateFormatted}
        onClick={() => handleClick(dateFormatted, startHour, endHour)}
        disabled={disabled}
      >
        {`${startHour} - ${endHour}`}
        <ScheduleRadioButton
          id={dateFormatted}
          className="opacity-0 fixed w-0"
          name={fieldName}
          value={dateFormatted}
          disabled={isSelected}
        >
          {`${startHour} - ${endHour}`}
        </ScheduleRadioButton>
      </button>
    )
  }
)
