// Import css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Slider from 'react-slick'
import { FC, ReactElement, ReactNode, RefObject, useRef, useState } from 'react'

type DotsComponentProps = {
  sliderRef: RefObject<any>
  currentSlide: number
  isChanging: boolean
}

type CarouselProps = {
  children: ReactNode
  settings?: any
  DotsComponent?: any
}

export const Carousel: FC<CarouselProps> = ({
  children,
  settings,
  DotsComponent,
}) => {
  const sliderRef: RefObject<any> = useRef()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isChanging, setIsChanging] = useState(false)

  const afterChange = (current: number) => {
    setCurrentSlide(current)
    setIsChanging(false)
  }

  const beforeChange = () => {
    setIsChanging(true)
  }

  const sliderSettings = settings || {
    dots: true,
    infinite: false,
    afterChange,
    beforeChange,
    ...(DotsComponent && {
      appendDots: (dots: ReactElement<DotsComponentProps>) => (
        <DotsComponent
          sliderRef={sliderRef}
          currentSlide={currentSlide}
          isChanging={isChanging}
        >
          {dots}
        </DotsComponent>
      ),
    }),
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Slider ref={sliderRef} {...sliderSettings}>
      {children}
    </Slider>
  )
}
