import { getRoot } from 'mobx-state-tree'
import { TestModel } from '../test'

export const MODEL_NAME = 'InitialAnalysis'

export const Model = TestModel.named(MODEL_NAME).volatile((self) => ({
  _store: getRoot(self)[`${MODEL_NAME}Store`],
}))

export default Model
