import { SRBigCalendarEventDayWrapperStyle } from '../styles'

export const SRBigCalendarEventDayWrapper = ({
  components,
  onClick,
  style,
  event,
  ...rest
}) => {
  if (!event) return

  const {
    appointmentType: { color, textColor = '' },
  } = event

  const Event = components.event
  const colorClasses = color ? `bg-${color}-base border-${color}-tone` : ''

  return (
    <div
      style={SRBigCalendarEventDayWrapperStyle(style)}
      className={`absolute rounded border ${colorClasses} ${textColor}`}
      onClick={onClick}
      data-testid="bc-event-day-wrapper"
    >
      <Event event={event} {...rest} />
    </div>
  )
}
