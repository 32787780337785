import { flow } from 'mobx-state-tree'
import { Home } from '@carbon/icons-react'

export const stateActions = (self) => ({
  afterAttach() {
    self.loadDependencies()
  },
  loadDependencies: flow(function* loadDependencies() {
    self.setLoading('DashboardCoordinatorRouteState')
    try {
      const schedulePolicies = self.loadPoliciesNoScheduled()
      const noDigitalPolicies = self.loadPoliciesNoDigital()
      yield Promise.all([schedulePolicies, noDigitalPolicies])
    } finally {
      self.removeLoading('DashboardCoordinatorRouteState')
    }
  }),
  myBoxCarePolicyListStyled({ id, fullName, noDigital }) {
    const box = {
      text: fullName,
      action: () => self.onSelectUser(id),
    }
    if (noDigital) {
      box.icon = <Home size={20} />
    }

    return box
  },
  setOnSelectCallback({ onSelectUser }) {
    self.onSelectUser = onSelectUser
  },
})
