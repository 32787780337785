import { useRouteState } from '@shared/utils'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GenericAnalysisListState } from './state'
import { observer } from 'mobx-react-lite'
import { BUTTON_SIZES, BUTTON_VARIANTS, Button, DotList } from '@shared/ui'
import { SRAddButton } from '@sr/ui'
import { AddButtonClasses, DotListClasses } from './styles'
import { useTranslation } from 'react-i18next'
import { FORM_FIELDS, TEST_TYPES, TEST_TYPE_NAME } from './enum'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { useLayoutContext } from '@sr/routes/common'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSchema } from './schema'
import { useForm } from 'react-hook-form'
import { CreateTestModal } from './components'

type NewTestProps = {
  personId: string
  userAreaUrl?: string
}

export const NewTest: FC<NewTestProps> = observer(
  ({ personId, userAreaUrl }) => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const { setIsLoading } = useLayoutContext()

    const [showModal, setShowModal] = useState(false)

    const {
      genericAnalysisList,
      isLoading,
      loadGenericAnalysis,
      genericAnalysisAvailableParsed,
      createTest,
      hasAvailableAnalysis,
    } = useRouteState(GenericAnalysisListState, {
      personId,
    })

    const genericAnalysisParsed = genericAnalysisList?.map(
      (test: {
        id: string
        genericAnalysisType: TEST_TYPES
        genericAnalysisDate: string
      }) => {
        const testType: TEST_TYPES = test.genericAnalysisType
        const yearTest = new Date(test.genericAnalysisDate).getFullYear()

        return {
          name: `${t(TEST_TYPE_NAME[testType])} ${yearTest}`,
          action: (
            <Button
              size={BUTTON_SIZES.small}
              variant={BUTTON_VARIANTS.outlined}
              onClick={() =>
                navigate(
                  ROUTE_PATH.userScriptTest({ userAreaUrl, testId: test.id })
                )
              }
            >
              {t('testList.initTest')}
            </Button>
          ),
        }
      },
      []
    )

    useEffect(() => {
      setIsLoading(isLoading)
    }, [isLoading])

    const schema = useSchema()
    const methods = useForm({
      resolver: yupResolver(schema),
    })

    const { handleSubmit, setError, reset } = methods

    const submit = handleSubmit(async (form) => {
      try {
        await createTest(form.testType)
        await loadGenericAnalysis()
        setShowModal(false)
        reset()
      } catch {
        setError(FORM_FIELDS.testType, {
          message: t('testList.createError'),
        })
      }
    })

    const onClose = () => {
      setShowModal(false)
      reset()
    }

    return (
      <>
        {showModal && (
          <CreateTestModal
            onClose={onClose}
            onSubmit={submit}
            options={genericAnalysisAvailableParsed}
            methods={methods}
            disabled={isLoading}
          />
        )}
        <SRAddButton
          action={async () => {
            reset()
            setShowModal(true)
          }}
          className={AddButtonClasses}
          disabled={!hasAvailableAnalysis}
        />

        <div className={DotListClasses}>
          <DotList items={genericAnalysisParsed} dotsGray />
        </div>
      </>
    )
  }
)
