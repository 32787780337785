import tw from 'tailwind-styled-components'

export const PortfolioManagementListContainer = tw.div`
    flex
    gap-8
    p-4
`
export const ListContainer = tw.div`
    flex
    flex-col
    pb-20
    w-[80%]
`

export const InputContainer = tw.div`
    flex
    gap-16
    items-center
    justify-between
    mb-6
`
