import { SRConfirmModal, SRErrorModal, SRSuccessModal } from '@sr/ui'
import { ModalType } from './enums'
import { useTranslation } from 'react-i18next'
import { ROUTE_PATH_CAMPAIGN } from 'apps/social-referent/src/routes/enums'

type AnalysisModalParams = {
  modalType?: ModalType
  reload: () => void
  resend?: () => void
  onChangeOp?: () => void
  onClose: () => void
  fullName: string
  onCancelText?: string
  onConfirmText?: string
  confirmModalTextMain?: string
  confirmModalTextSecond?: string
  successModalTextMain?: string
  successModalTextSecond?: string
  noRoute?: boolean
}

type ModalComponent = {
  [ModalType.confirmation]: React.ReactElement
  [ModalType.genericError]: React.ReactElement
  [ModalType.sendError]: React.ReactElement
  [ModalType.success]: React.ReactElement
}

export const useAnalysisModal = ({
  modalType = ModalType.genericError,
  reload,
  resend,
  onChangeOp,
  onClose,
  fullName,
  onCancelText,
  onConfirmText,
  confirmModalTextMain = 'analysis:confirmModal.mainMessagePostal',
  confirmModalTextSecond = 'analysis:confirmModal.secondaryMessage',
  successModalTextMain = 'analysis:successModal.mainMessage',
  successModalTextSecond = 'analysis:successModal.secondaryMessage',
  noRoute = false,
}: AnalysisModalParams) => {
  const { t } = useTranslation()
  const ModalComponent: ModalComponent = {
    [ModalType.confirmation]: (
      <SRConfirmModal
        onAction={onChangeOp || resend}
        onClose={onClose}
        onCancelText={onCancelText}
        onConfirmText={onConfirmText}
        icon={true}
      >
        <div className="flex flex-col gap-2">
          <span>{t(confirmModalTextMain)}<strong className='ml-2'>{t(confirmModalTextSecond)}</strong></span>
        </div>
      </SRConfirmModal>
    ),
    [ModalType.genericError]: <SRErrorModal onRetry={reload} />,
    [ModalType.sendError]: <SRErrorModal onRetry={resend} />,
    [ModalType.success]: (
      <SRSuccessModal
        mainMessage={t(successModalTextMain)}
        secondaryMessage={t(successModalTextSecond, {
          fullName,
        })}
        backUrl={`../${ROUTE_PATH_CAMPAIGN.userNewTest}`}
        onClose={onClose}
        noRoute={noRoute}
      />
    ),
  }

  const Modal = ModalComponent[modalType] || <SRErrorModal onRetry={reload} />

  return Modal
}
