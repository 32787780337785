import tw from 'tailwind-styled-components'

export const HeaderActionsContainer = tw.div`
    flex
    items-center
    gap-5
`

export const HeaderActionButton = tw.div`
    flex
    gap-2
    justify-end
    items-center
    cursor-pointer
`

export const ProfileAvatar = tw.img`
    block
    rounded-full
    w-[30px]
    h-[30px]
`
export const NotificationBubble = tw.div`
    absolute
    rounded-full
    bg-dark-red
    flex
    justify-center
    items-center
    text-[5px]
    w-[7.5px]
    h-[7.5px]
    right-[0px]
    top-[2px]
`
