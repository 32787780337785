import { FC } from 'react'
import { Props } from './types'
import { Accordion, TranscriptionScript } from '@shared/ui'
import { accordionContainerClassName } from './styles'

export const SRTestWithScript: FC<Props> = ({
  children,
  script = null,
  scriptProps = {},
}) => {
  return (
    <div
      style={script ? { gridTemplateColumns: 'auto 1fr' } : {}}
      className={script ? 'grid gap-8' : ''}
    >
      {script && (
        <Accordion isVertical containerClassName={accordionContainerClassName}>
          <TranscriptionScript script={script} scriptProps={scriptProps} />
        </Accordion>
      )}
      <div className="flex-1 ">{children}</div>
    </div>
  )
}
