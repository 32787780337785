import { useTranslation } from 'react-i18next'
import {
  CountDownDots,
  CountDownItem,
  CountDownNumbers,
  CountDownWrapper,
} from './styles'
import { useCountdown } from './useCountDown'
import { useVideoContext } from '../video-call'

export const CountDown = ({ date }) => {
  const { t } = useTranslation()

  const { branding } = useVideoContext()

  const [days, hours, minutes, seconds] = useCountdown(date)

  return (
    <div
      data-testid="countdown"
      className={`${CountDownWrapper} ${branding?.primaryColor}`}
    >
      {days > 0 && (
        <>
          <div className={CountDownItem}>
            <div>{t('common:day')}</div>
            <div className={CountDownNumbers} data-testid="countdown-day">
              {days}
            </div>
          </div>
          <div className={CountDownDots}>:</div>
        </>
      )}
      {(days > 0 || hours > 0) && (
        <>
          <div className={CountDownItem}>
            <div>{t('common:hours')}</div>
            <div className={CountDownNumbers} data-testid="countdown-hours">
              {hours}
            </div>
          </div>
          <div className={CountDownDots}>:</div>
        </>
      )}
      {(hours > 0 || minutes > 0) && (
        <>
          <div className={CountDownItem}>
            <div>{t('common:minutes')}</div>
            <div className={CountDownNumbers} data-testid="countdown-minutes">
              {minutes < 10 && '0'}
              {minutes}
            </div>
          </div>
          <div className={CountDownDots}>:</div>
        </>
      )}
      <div className={CountDownItem}>
        <div>{t('common:seconds')}</div>
        <div className={CountDownNumbers} data-testid="countdown-seconds">
          {seconds < 10 && '0'}
          {seconds}
        </div>
      </div>
    </div>
  )
}
