import { isRoot, types } from 'mobx-state-tree'

export const BaseRouteState = types
  .model('BaseRouteState', {
    loading: types.array(types.string),
  })
  .views((self) => ({
    get isLoading() {
      return Boolean(self.loading.length)
    },
  }))
  .actions((self) => ({
    setLoading(loading: string) {
      if (isRoot(self)) return
      self.loading.push(loading)
    },
    removeLoading(loading: string) {
      if (isRoot(self)) return
      const ix = self.loading.indexOf(loading)
      if (ix >= 0) {
        self.loading.splice(ix, 1)
      }
    },
  }))
