import { create } from 'zustand'

export interface BreadcrumbItem {
  path: string | null;
  label: string;
}

interface BreadcrumbState {
  breadcrumbs: BreadcrumbItem[];
  setBreadcrumb: (breadcrumbs: BreadcrumbItem[]) => void;
  clearBreadcrumb: () => void;
  setRouteState: (breadcrumbs: BreadcrumbItem[]) => void;
  clearRouteState: () => void;
  getRouteState: () => BreadcrumbItem[];
}

export const useBreadcrumbStore = create<BreadcrumbState>((set, get) => ({
  breadcrumbs: [],
  setBreadcrumb: (breadcrumbs) => set({ breadcrumbs }),
  clearBreadcrumb: () => set({ breadcrumbs: [] }),
  setRouteState: (breadcrumbs: BreadcrumbItem[]) => set({ breadcrumbs }),
  clearRouteState: () => set({ breadcrumbs: [] }),
  getRouteState: () => get().breadcrumbs,
}));
