import { NUTRITIONAL_KEYS } from '../enums'

interface FREQUENCY {
  [key: string]: string | string[]
}

export const TRANSLATION_BASE_KEY =
  'initial-report:sectionNutritional.frequencyTable'

export const VEGETABLES_FREQUENCY: FREQUENCY = {
  [NUTRITIONAL_KEYS.recNutritionalIrritableBowelSyndrome]: `${TRANSLATION_BASE_KEY}.rows.vegetables.bowelSyndrome`,
  [NUTRITIONAL_KEYS.recNutritionalGastritis]: `${TRANSLATION_BASE_KEY}.rows.vegetables.gastritis`,
  [NUTRITIONAL_KEYS.recNutritionalGastroesophagealReflux]: [
    `${TRANSLATION_BASE_KEY}.rows.vegetables.refluxOne`,
    `${TRANSLATION_BASE_KEY}.rows.vegetables.refluxTwo`,
    `${TRANSLATION_BASE_KEY}.rows.vegetables.refluxThree`,
    `${TRANSLATION_BASE_KEY}.rows.vegetables.refluxFour`,
  ],
}

export const FRUITS_FREQUENCY: FREQUENCY = {
  [NUTRITIONAL_KEYS.recNutritionalMediterranean]: [
    `${TRANSLATION_BASE_KEY}.general.fruits`,
    `${TRANSLATION_BASE_KEY}.general.alergicOrIntolerant`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanKeep]: [
    `${TRANSLATION_BASE_KEY}.general.fruits`,
    `${TRANSLATION_BASE_KEY}.general.alergicOrIntolerant`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalInsufficientWeight]: [
    `${TRANSLATION_BASE_KEY}.general.fruits`,
    `${TRANSLATION_BASE_KEY}.general.alergicOrIntolerant`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalIrritableBowelSyndrome]: `${TRANSLATION_BASE_KEY}.rows.fruits.bowelSyndrome`,
  [NUTRITIONAL_KEYS.recNutritionalGastroesophagealReflux]: [
    `${TRANSLATION_BASE_KEY}.general.fruits`,
    `${TRANSLATION_BASE_KEY}.rows.fruits.reflux`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalGastritis]: `${TRANSLATION_BASE_KEY}.rows.fruits.gastritis`,
}

export const LEGUMES_FREQUENCY: FREQUENCY = {
  [NUTRITIONAL_KEYS.recNutritionalIrritableBowelSyndrome]: `${TRANSLATION_BASE_KEY}.rows.legumes.bowelSyndrome`,
  [NUTRITIONAL_KEYS.recNutritionalCeliacDisease]: `${TRANSLATION_BASE_KEY}.rows.legumes.celiac`,
  [NUTRITIONAL_KEYS.recNutritionalGastritis]: `${TRANSLATION_BASE_KEY}.rows.legumes.bowelSyndrome`,
}

export const NUTS_FREQUENCY: FREQUENCY = {
  [NUTRITIONAL_KEYS.recNutritionalMediterranean]: [
    `${TRANSLATION_BASE_KEY}.general.nuts`,
    `${TRANSLATION_BASE_KEY}.general.alergicOrIntolerantNuts`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanKeep]: [
    `${TRANSLATION_BASE_KEY}.general.nuts`,
    `${TRANSLATION_BASE_KEY}.general.alergicOrIntolerantNuts`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalInsufficientWeight]: [
    `${TRANSLATION_BASE_KEY}.general.nuts`,
    `${TRANSLATION_BASE_KEY}.general.alergicOrIntolerantNuts`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalDyslipidemia]: `${TRANSLATION_BASE_KEY}.rows.nuts.combo`,
  [NUTRITIONAL_KEYS.recNutritionalCombo1]: `${TRANSLATION_BASE_KEY}.rows.nuts.combo`,
  [NUTRITIONAL_KEYS.recNutritionalCombo4]: `${TRANSLATION_BASE_KEY}.rows.nuts.combo`,
  [NUTRITIONAL_KEYS.recNutritionalCombo6]: `${TRANSLATION_BASE_KEY}.rows.nuts.combo`,
  [NUTRITIONAL_KEYS.recNutritionalCombo7]: `${TRANSLATION_BASE_KEY}.rows.nuts.combo`,
  [NUTRITIONAL_KEYS.recNutritionalCombo0]: `${TRANSLATION_BASE_KEY}.rows.nuts.combo`,
  [NUTRITIONAL_KEYS.recNutritionalCombo11]: `${TRANSLATION_BASE_KEY}.rows.nuts.combo`,
}

export const WHOLE_GRAINS_FREQUENCY: FREQUENCY = {
  [NUTRITIONAL_KEYS.recNutritionalMediterranean]: [
    `${TRANSLATION_BASE_KEY}.general.wholeGrains`,
    `${TRANSLATION_BASE_KEY}.general.noGlutenOptions`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanKeep]: [
    `${TRANSLATION_BASE_KEY}.general.wholeGrains`,
    `${TRANSLATION_BASE_KEY}.general.noGlutenOptions`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalIrritableBowelSyndrome]: `${TRANSLATION_BASE_KEY}.rows.wholeGrains.bowelSyndrome`,
  [NUTRITIONAL_KEYS.recNutritionalOsteoarthritisArthritis]: `${TRANSLATION_BASE_KEY}.rows.wholeGrains.arthritis`,
  [NUTRITIONAL_KEYS.recNutritionalGastroesophagealReflux]: `${TRANSLATION_BASE_KEY}.rows.wholeGrains.reflux`,
  [NUTRITIONAL_KEYS.recNutritionalCeliacDisease]: `${TRANSLATION_BASE_KEY}.rows.wholeGrains.celiac`,
}

export const FISH_AND_EGGS_FREQUENCY: FREQUENCY = {
  [NUTRITIONAL_KEYS.recNutritionalMediterranean]: [
    `${TRANSLATION_BASE_KEY}.general.fish`,
    `${TRANSLATION_BASE_KEY}.general.eggs`,
    `${TRANSLATION_BASE_KEY}.general.specialDiet`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanKeep]: [
    `${TRANSLATION_BASE_KEY}.general.fish`,
    `${TRANSLATION_BASE_KEY}.general.eggs`,
    `${TRANSLATION_BASE_KEY}.general.specialDiet`,
  ],
  [NUTRITIONAL_KEYS.recLactoseIntolerant]: [
    `${TRANSLATION_BASE_KEY}.rows.fishAndEggs.lactoseIntolerant`,
    `${TRANSLATION_BASE_KEY}.general.eggs`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCeliacDisease]: [
    `${TRANSLATION_BASE_KEY}.rows.fishAndEggs.celiac`,
    `${TRANSLATION_BASE_KEY}.general.eggs`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalUlcerativeColitis]: [
    `${TRANSLATION_BASE_KEY}.rows.fishAndEggs.celiac`,
    `${TRANSLATION_BASE_KEY}.general.eggs`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCrohnsDisease]: [
    `${TRANSLATION_BASE_KEY}.rows.fishAndEggs.celiac`,
    `${TRANSLATION_BASE_KEY}.general.eggs`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalVegan]: `${TRANSLATION_BASE_KEY}.rows.fishAndEggs.vegan`,
  [NUTRITIONAL_KEYS.recNutritionalVegetarian]: [
    `${TRANSLATION_BASE_KEY}.general.eggs`,
    `${TRANSLATION_BASE_KEY}.rows.fishAndEggs.vegetarian`,
  ],
}

export const MEAT_FREQUENCY: FREQUENCY = {
  [NUTRITIONAL_KEYS.recNutritionalMediterranean]: [
    `${TRANSLATION_BASE_KEY}.general.meat`,
    `${TRANSLATION_BASE_KEY}.general.meatExcluded`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanKeep]: [
    `${TRANSLATION_BASE_KEY}.general.meat`,
    `${TRANSLATION_BASE_KEY}.general.meatExcluded`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalGastroesophagealReflux]: `${TRANSLATION_BASE_KEY}.rows.meat.reflux`,
  [NUTRITIONAL_KEYS.recNutritionalHypertension]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalHeartFailure]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalDiabetesType1]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalDiabetesType2]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalDyslipidemia]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalOverweight]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalObesity]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recLactoseIntolerant]: `${TRANSLATION_BASE_KEY}.rows.meat.lactoseIntolerant`,
  [NUTRITIONAL_KEYS.recNutritionalIrritableBowelSyndrome]: `${TRANSLATION_BASE_KEY}.rows.meat.bowelSyndrome`,
  [NUTRITIONAL_KEYS.recNutritionalUlcerativeColitis]: `${TRANSLATION_BASE_KEY}.rows.meat.colitis`,
  [NUTRITIONAL_KEYS.recNutritionalCrohnsDisease]: `${TRANSLATION_BASE_KEY}.rows.meat.colitis`,
  [NUTRITIONAL_KEYS.recNutritionalVegan]: `${TRANSLATION_BASE_KEY}.rows.meat.vegan`,
  [NUTRITIONAL_KEYS.recNutritionalVegetarian]: `${TRANSLATION_BASE_KEY}.rows.meat.vegetarian`,
  [NUTRITIONAL_KEYS.recNutritionalCombo0]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalCombo1]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalCombo2]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalCombo3]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalCombo4]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalCombo5]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalCombo6]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalCombo7]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalCombo8]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalCombo9]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalCombo10]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
  [NUTRITIONAL_KEYS.recNutritionalCombo11]: `${TRANSLATION_BASE_KEY}.rows.meat.noProcessed`,
}

export const SALT_FREQUENCY: FREQUENCY = {
  [NUTRITIONAL_KEYS.recNutritionalMediterranean]: `${TRANSLATION_BASE_KEY}.rows.salt.mediterranean`,
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanKeep]: `${TRANSLATION_BASE_KEY}.rows.salt.mediterranean`,
  [NUTRITIONAL_KEYS.recNutritionalInsufficientWeight]: `${TRANSLATION_BASE_KEY}.rows.salt.mediterranean`,
  [NUTRITIONAL_KEYS.recNutritionalHypertension]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalHeartFailure]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalDiabetesType1]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalDiabetesType2]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalDyslipidemia]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalOverweight]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalObesity]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalCombo1]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalCombo2]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalCombo3]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalCombo4]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalCombo5]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalCombo6]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalCombo7]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalCombo0]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalCombo8]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalCombo9]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalCombo10]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
  [NUTRITIONAL_KEYS.recNutritionalCombo11]: `${TRANSLATION_BASE_KEY}.rows.salt.noSalt`,
}

export const DAIRY_FREQUENCY: FREQUENCY = {
  [NUTRITIONAL_KEYS.recNutritionalMediterranean]: [
    `${TRANSLATION_BASE_KEY}.rows.dairy.mediterranean`,
    `${TRANSLATION_BASE_KEY}.rows.dairy.mediterraneanIntolerance`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanKeep]: [
    `${TRANSLATION_BASE_KEY}.rows.dairy.mediterranean`,
    `${TRANSLATION_BASE_KEY}.rows.dairy.mediterraneanIntolerance`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalInsufficientWeight]: [
    `${TRANSLATION_BASE_KEY}.rows.dairy.mediterranean`,
    `${TRANSLATION_BASE_KEY}.rows.dairy.mediterraneanIntolerance`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalDyslipidemia]: `${TRANSLATION_BASE_KEY}.rows.dairy.dyslipidemia`,
  [NUTRITIONAL_KEYS.recLactoseIntolerant]: `${TRANSLATION_BASE_KEY}.rows.dairy.lactoseIntolerant`,
  [NUTRITIONAL_KEYS.recNutritionalIrritableBowelSyndrome]: `${TRANSLATION_BASE_KEY}.rows.dairy.bowelSyndrome`,
  [NUTRITIONAL_KEYS.recNutritionalUlcerativeColitis]: `${TRANSLATION_BASE_KEY}.rows.dairy.colitis`,
  [NUTRITIONAL_KEYS.recNutritionalCrohnsDisease]: `${TRANSLATION_BASE_KEY}.rows.dairy.crohnsDisease`,
  [NUTRITIONAL_KEYS.recNutritionalOverweight]: [
    `${TRANSLATION_BASE_KEY}.rows.dairy.mediterranean`,
    `${TRANSLATION_BASE_KEY}.rows.dairy.mediterraneanIntolerance`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalObesity]: [
    `${TRANSLATION_BASE_KEY}.rows.dairy.mediterranean`,
    `${TRANSLATION_BASE_KEY}.rows.dairy.mediterraneanIntolerance`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalVegan]: `${TRANSLATION_BASE_KEY}.rows.dairy.vegan`,
  [NUTRITIONAL_KEYS.recNutritionalVegetarian]: [
    `${TRANSLATION_BASE_KEY}.rows.dairy.mediterranean`,
    `${TRANSLATION_BASE_KEY}.rows.dairy.mediterraneanIntolerance`,
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo1]: `${TRANSLATION_BASE_KEY}.rows.dairy.dyslipidemia`,
  [NUTRITIONAL_KEYS.recNutritionalCombo4]: `${TRANSLATION_BASE_KEY}.rows.dairy.dyslipidemia`,
  [NUTRITIONAL_KEYS.recNutritionalCombo6]: `${TRANSLATION_BASE_KEY}.rows.dairy.dyslipidemia`,
  [NUTRITIONAL_KEYS.recNutritionalCombo7]: `${TRANSLATION_BASE_KEY}.rows.dairy.dyslipidemia`,
  [NUTRITIONAL_KEYS.recNutritionalCombo0]: `${TRANSLATION_BASE_KEY}.rows.dairy.dyslipidemia`,
  [NUTRITIONAL_KEYS.recNutritionalCombo11]: `${TRANSLATION_BASE_KEY}.rows.dairy.dyslipidemia`,
}
