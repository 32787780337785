import { Text } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { FC } from 'react'

type AreaTitleProps = {
  title: string
  areaColor: string
}

export const AreaTitle: FC<AreaTitleProps> = ({ title, areaColor }) => {
  return (
    <Text
      style={{
        ...tw(`font-bold text-${areaColor}`),
        fontSize: '14px',
      }}
    >
      {title}
    </Text>
  )
}
