import { Image, Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import OmsImage from '../../assets/oms.png'
import { useReportPdfContext } from '../../report-pdf-provider'

export const OmsLogo = ({ omsText }) => {
  const { t } = useReportPdfContext()

  return (
    <View
      style={{
        ...tw(
          'px-24 py-10 flex flex-row gap-4 items-center justify-center my-5'
        ),
        backgroundColor: '#F2F2F2',
      }}
      break
    >
      <Image
        src={OmsImage}
        style={{
          width: '120px',
          height: '110px',
        }}
      />
      <View style={tw('flex-1')}>
        <Text style={tw('font-bold')}>{t('initial-report:omsTitle')}</Text>
        <Text style={tw('text-black leading-6 pt-2')}>{omsText}</Text>
      </View>
    </View>
  )
}
