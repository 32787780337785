import { CALL_REASON_OPTIONS } from '@shared/data-access'
import { Button, Form } from '@shared/ui'
import { SRSelect } from '@sr/ui'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ButtonCancel, ModalCall } from './style'

export const ModalCallReason = ({ onClick, onCancel }) => {
  const methods = useForm()
  const { watch } = methods
  const callReason = watch('callReason')

  const { t } = useTranslation()

  const parseCallReasonOptions = CALL_REASON_OPTIONS.map((type) => {
    return {
      ...type,
      name: t(type.name),
    }
  })

  return (
    <ModalCall>
      <Form className="h-full flex flex-col justify-around" methods={methods}>
        <SRSelect
          name="callReason"
          hasNoneOption
          options={parseCallReasonOptions}
        />
        <Button
          disabled={!callReason || callReason === 'none'}
          onClick={() => onClick(callReason)}
        >
          {t('common:save')}
        </Button>
        <ButtonCancel>
          <Button onClick={onCancel}>{t('common:cancel')}</Button>
        </ButtonCancel>
      </Form>
    </ModalCall>
  )
}
