import { BigCalendar, BIG_CALENDAR_VIEW } from '@shared/ui'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { SRBigCalendarToolbar } from './components'
import {
  SRBigCalendarEventDay,
  SRBigCalendarEventDayWrapper,
} from './components/sr-big-calendar-event/day'
import {
  SRBigCalendarEventMonth,
  SRBigCalendarEventMonthWrapper,
} from './components/sr-big-calendar-event/month'
import {
  SRBigCalendarEventWeek,
  SRBigCalendarEventWeekWrapper,
} from './components/sr-big-calendar-event/week'
import './styles.css'
import { DateLocalizer } from 'react-big-calendar'
import { PolicyProps } from '@shared/data-access'

export type SRBigCalendarEvent = {
  id: string
  startDate: string
  endDate: string
  meetingUrl: string
  visibleByClient: null | boolean
  appointmentType: {
    reference: number
    text: string
    color: string
    textColor: string
  }
  appointmentModality: number
  personId: string
  socialReferentId: string
  policy: null | PolicyProps
  policyId: null
  start: Date
  end: Date
}

type SRBigCalendarProps = {
  events: SRBigCalendarEvent[]
  view: string
  onChange: (date: Date) => void
  initialDate: Date
  onSelectEvent: () => void
  onSelectSlot: () => void
}

export const SRBigCalendar: FC<SRBigCalendarProps> = ({
  events,
  view,
  onChange,
  initialDate,
  onSelectEvent,
  onSelectSlot,
}) => {
  const [date, setDate] = useState(initialDate)

  useEffect(() => {
    setDate(initialDate)
  }, [initialDate])

  const onChangeDate = (date: Date) => {
    if (onChange) {
      onChange(new Date(date))
    }
    setDate(date)
  }

  const slotGroupPropGetter = useCallback(
    () => ({
      style: {
        minHeight: 60,
        padding: view === BIG_CALENDAR_VIEW.day ? '2px 20px' : '2px 5px',
        borderColor: '#D9D9D9',
      },
    }),
    [view]
  )

  const sanitizeFormat = (text: string) => {
    return text.toUpperCase().split('.').join('')
  }

  const { formats } = useMemo(
    () => ({
      formats: {
        timeGutterFormat: (
          date: Date,
          culture: string,
          localizer: DateLocalizer
        ) => localizer.format(date, 'HH:mm A', culture),
        dayFormat: (date: Date, culture: string, localizer: DateLocalizer) =>
          sanitizeFormat(localizer.format(date, 'ddd D MMM', culture)),
        weekdayFormat: (
          date: Date,
          culture: string,
          localizer: DateLocalizer
        ) => sanitizeFormat(localizer.format(date, 'dddd', culture)),
      },
    }),
    []
  )

  //TODO: Change any for the correct type when migrate components to tsx
  const components = {
    toolbar: (props: any) => (
      <SRBigCalendarToolbar {...props} setDate={onChangeDate} />
    ),
    day: {
      event: (props: any) => <SRBigCalendarEventDay {...props} />,
      eventWrapper: (props: any) => <SRBigCalendarEventDayWrapper {...props} />,
    },
    work_week: {
      event: (props: any) => <SRBigCalendarEventWeek {...props} />,
      eventWrapper: (props: any) => (
        <SRBigCalendarEventWeekWrapper {...props} />
      ),
    },
    month: {
      event: (props: any) => <SRBigCalendarEventMonth {...props} />,
      eventWrapper: (props: any) => (
        <SRBigCalendarEventMonthWrapper {...props} />
      ),
    },
  }
  return (
    <BigCalendar
      formats={formats}
      events={events}
      view={view}
      components={components}
      date={date}
      popup={true}
      slotGroupPropGetter={slotGroupPropGetter}
      min={new Date(0, 0, 0, 7, 0, 0)}
      max={new Date(0, 0, 0, 22, 0, 0)}
      onSelectSlot={onSelectSlot}
      onSelectEvent={onSelectEvent}
      selectable
    />
  )
}
