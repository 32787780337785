import { useEffect } from 'react'
import { EVENT_FORM } from '../enums'
import { useModalContext } from '../../provider/modal-provider'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getDefaultModality } from '../getters'

export const useModalForm = ({
  schema = {},
  defaultValues = {},
  isEdit,
} = {}) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const { watch, setValue } = methods
  const [formAppointmentType, formAppointmentDay] = watch([
    EVENT_FORM.appointmentType,
    EVENT_FORM.appointmentDay,
  ])

  const {
    appointmentType,
    appointmentDay,
    noDigital,
    onChangeAppointmentType,
    onChangeAppointmentDay,
  } = useModalContext()

  useEffect(() => {
    const formTypeParsed = Number(formAppointmentType)

    if (formTypeParsed && formTypeParsed !== appointmentType) {
      onChangeAppointmentType(formTypeParsed)
      if (!isEdit) {
        setValue(
          EVENT_FORM.appointmentModality,
          getDefaultModality({ type: formTypeParsed, noDigital }),
        )
      }
    }
  }, [formAppointmentType])

  useEffect(() => {
    if (formAppointmentDay && formAppointmentDay !== appointmentDay) {
      onChangeAppointmentDay(formAppointmentDay)
    }
  }, [formAppointmentDay])

  return { methods }
}
