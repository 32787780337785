import { getRoot, types } from 'mobx-state-tree'
import { RootModel, SEX } from '@shared/data-access'
import { PERSON_TYPE } from './enum'

export const MODEL_NAME = 'Person'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    identification: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    surname: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    postalCode: types.maybeNull(types.string),
    preferredLanguage: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    region: types.maybeNull(types.string),
    locality: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    sex: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(SEX).some((sex) => sex === value)
      )
    ),
    birthDay: types.maybeNull(types.string),
    personType: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(PERSON_TYPE).some((policyType) => policyType === value)
      )
    ),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
  .views((self) => ({
    get fullName() {
      return `${self.name} ${self.surname}`
    },
  }))
