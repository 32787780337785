import { t } from 'i18next'
import { Calendar, DataCheck, Warning } from '@carbon/icons-react'

export const getHeadersCommunication = () => [
  { icon: <Calendar size={24} />, title: t('common:date') },
  { icon: <DataCheck size={24} />, title: t('common:register') },
  {
    icon: <Warning size={24} />,
    title: t('userArea.monitoringArea.headers.requiredActions'),
  },
]
