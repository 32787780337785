export const inputSearchClasses = `
  pl-12
  py-2
  pr-2
  h-12
  focus:outline-none
  border
  border-grayLight
  rounded-lg
  w-full
  bg-white
  lg:bg-gray-background
  !bg-[length:18px]
`
