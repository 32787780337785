import { Numberedlist } from '../../../components/NumberedList'
import { SubSection } from '../../../components/SubSection'
import { TranscriptionScriptPDF } from '../../../components/TranscriptionScriptPDF'
import { Banner } from '../../../components/Banner/Banner'
import { View } from '@react-pdf/renderer'
import { tw } from '../../../utils'
import { useReportPdfContext } from '../../../report-pdf-provider'
import { ReportType } from '../../../../report-pdf'

export const ThirdPage = ({ data }) => {
  const { t, person, type } = useReportPdfContext()
  const planType = t('planType')
  const numberedList = [
    {
      title: t('initial-report:sectionOne.introduction.numberedList.one.title'),
      content: t(
        'initial-report:sectionOne.introduction.numberedList.one.content',
      ),
    },
    {
      title: t('initial-report:sectionOne.introduction.numberedList.two.title'),
      content: (
        <TranscriptionScriptPDF script="initial-report:sectionOne.introduction.numberedList.two.content" />
      ),
    },
  ]

  return (
    <View>
      <View style={tw('px-12')}>
        <SubSection
          classes="text-primary"
          title={t('sectionOne.introduction.prevention_title')}
        >
          <View style={tw('flex flex-col gap-4')}>
            <TranscriptionScriptPDF script="sectionOne.introduction.prevention_one" />
            <TranscriptionScriptPDF script="sectionOne.introduction.prevention_two" />
            <TranscriptionScriptPDF script="sectionOne.introduction.prevention_three" />
          </View>
        </SubSection>
        <SubSection
          classes="text-primary"
          title={t('sectionOne.introduction.reasons_title')}
        >
          <View style={tw('flex flex-col gap-4')}>
            <TranscriptionScriptPDF script="sectionOne.introduction.reasons_one" />
            <TranscriptionScriptPDF script="sectionOne.introduction.reasons_two" />
            <TranscriptionScriptPDF script="sectionOne.introduction.reasons_three" />
            <TranscriptionScriptPDF script="sectionOne.introduction.reasons_four" />
          </View>
        </SubSection>
      </View>
    </View>
  )
}
