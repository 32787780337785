export const enum BUTTON_COLORS {
  primary = 'primary',
  secondary = 'secondary',
  error = 'error',
  transparent = 'transparent',
}

export const enum BUTTON_VARIANTS {
  contained = 'contained',
  outlined = 'outlined',
  text = 'text',
}

export enum BUTTON_SIZES {
  text = 'text',
  small = 'small',
  medium = 'medium',
  xs = 'xs',
}

export enum BUTTON_TYPES {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}
