import tw from 'tailwind-styled-components'

export const MonitoringAreaContainer = tw.div`
    py-4
    h-full
`

export const MonitoringAreaContentContainer = tw.div`
    p-8
    h-[90%]
`
