import {
  wizardTimelineButtonClasses,
  wizardTimelineCircleClasses,
  wizardTimelineContainerClasses,
} from './styles'

export const WizardTimeline = ({
  pages = [],
  onClickTimeline,
  activePage,
  disableNavigation,
}) => {
  return (
    <div className={wizardTimelineContainerClasses}>
      {pages.length &&
        pages.map((page, ix) => {
          const isActive = ix === activePage
          return (
            <button
              data-testid={`wizard-timeline-button-${ix}`}
              className={`${wizardTimelineButtonClasses} ${
                isActive ? 'border-secondary text-secondary' : ''
              }`}
              disabled={disableNavigation}
              onClick={() => onClickTimeline(ix)}
              key={ix}
            >
              <div
                className={`${wizardTimelineCircleClasses} ${
                  isActive ? 'bg-secondary' : ''
                }`}
              />
              {page.props.name}
            </button>
          )
        })}
    </div>
  )
}
