import { flow, types } from 'mobx-state-tree'
import { actions } from '@sr/routes/common'
import { t } from 'i18next'
import { getDateFormatted } from '@shared/utils'
import {
  APPOINTMENT_MODALITY_TRANSLATION,
  APPOINTMENT_TYPES,
  APPOINTMENT_TYPES_TRANSLATION,
  BaseRouteState,
  POLICY_STATUS,
  Policy,
} from '@shared/data-access'
import { Appointment } from '@sr/data-access'

export const AppointmentManagerRouteState = BaseRouteState.named(
  'AppointmentManagerRouteState',
)
  .props({
    policyId: types.maybeNull(types.string),
    policy: types.maybeNull(types.reference(Policy)),
    appointments: types.maybeNull(types.array(types.reference(Appointment))),
  })
  .views((self) => ({
    //TODO: Paginación para las citas, si hay más de 100 resultados
    get appointmentsFiltered() {
      return self.appointments?.map(
        ({ startDate, appointmentType, appointmentModality, endDate, id }) => {
          if (!startDate && !appointmentType) return {}
          return {
            dateHour: getDateFormatted(startDate, 'LLLL'),
            type: t(APPOINTMENT_TYPES_TRANSLATION[appointmentType]),
            opName: `${self.policy?.socialReferent?.name} ${self.policy?.socialReferent?.surname}`,
            modality: t(APPOINTMENT_MODALITY_TRANSLATION[appointmentModality]),
            place:
              appointmentType === APPOINTMENT_TYPES.INITIAL_REPORT
                ? self.policy?.officeControl
                  ? t(`common:office`)
                  : t(`common:home`)
                : t(`common:home`),
            dataToModify: {
              appointmentType: appointmentType,
              startDate: new Date(startDate),
              endDate: new Date(endDate),
              appointmentModality: appointmentModality,
              policy: JSON.stringify(self.policy),
              id: id,
            },
          }
        },
        [],
      )
    },
    get isPostCs() {
      return self.policy?.state === POLICY_STATUS.accepted
    },
    get socialReferentId() {
      return self.policy?.socialReferent?.id
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadMonitoringAgendaDependencies()
    },
    loadMonitoringAgendaDependencies: flow(
      function* loadMonitoringAgendaDependencies() {
        self.setLoading('AppointmentManagerRouteState')
        try {
          yield self.loadAgenda()
          yield self.loadPolicy()
        } finally {
          self.removeLoading('AppointmentManagerRouteState')
        }
      },
    ),
    ...actions(self),
  }))
