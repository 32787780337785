import {
  AuditCScore,
  ClockScore,
  FagerstromScore,
  IMCScore,
  LoboScore,
  MedasScore,
  PfeifferScore,
  RichmondScore,
} from '../scales'

export interface Scores {
  loboScore: LoboScore
  clockScore: ClockScore
  pfeifferScore: PfeifferScore
  medasScore: MedasScore
  imcScore: IMCScore
  fagerstromScore: FagerstromScore
  richmondScore: RichmondScore
  auditCScore: AuditCScore
}

export enum SummaryLevel {
  attention,
  improvement,
  optimal,
}

type ExecutiveSummarySection = {
  score: SummaryLevel
  content: string
}
export interface ExecutiveSummaryValues {
  auditiveAndOcular: ExecutiveSummarySection
  cognitive: ExecutiveSummarySection
  emotional: ExecutiveSummarySection
  nutritional: ExecutiveSummarySection
  physicalActivity: ExecutiveSummarySection
  social: ExecutiveSummarySection
  tobaccoAlcohol: ExecutiveSummarySection
}
