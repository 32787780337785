import { useRouteState } from '@shared/utils'
import { useLayoutContext } from '@sr/routes/common'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { SRUserName } from '@sr/ui'
import { CareAppointmentManagerContainer } from './styles'
import { UserAreaCareRouteState } from '../state'
import { NewEvent } from './new-event'

export const CareAppointmentManager = observer(() => {
  const { id } = useParams()

  const { setIsLoading } = useLayoutContext()

  const { isLoading, policy } = useRouteState(UserAreaCareRouteState, {
    policyId: id,
  })

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  if (!policy) return

  return (
    <CareAppointmentManagerContainer>
      <SRUserName fullName={policy.fullName} />

      <NewEvent id={policy.id} />
    </CareAppointmentManagerContainer>
  )
})
