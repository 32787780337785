import { Button, BUTTON_COLORS, BUTTON_SIZES } from '@shared/ui'
import {
  PhoneOff,
  MicrophoneFilled,
  MicrophoneOffFilled,
  VideoFilled,
  VideoOffFilled,
  Share,
} from '@carbon/icons-react'
import {
  useDevices,
  useLocalAudioToggle,
  useLocalVideoToggle,
  useRoomState,
  useVideoContext,
} from '../../../../hooks'
import { useCallback, useRef } from 'react'
import { caixaBankButton } from '../../../../utils'
import { BRANDING_KEYS } from '../../../../utils/branding'

export const ActionButtons = ({ className }) => {
  const [isAudioEnabled, toggleAudioEnabled, audioTrack] = useLocalAudioToggle()
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()
  const { hasVideoInputDevices } = useDevices()
  const { toggleScreenShare, isSharingScreen, branding } = useVideoContext()

  const lastClickTimeRef = useRef(0)
  const roomState = useRoomState()
  const isReconnecting = roomState === 'reconnecting'
  const { room } = useVideoContext()

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now()
      toggleVideoEnabled()
    }
  }, [toggleVideoEnabled])

  const buttonStyle =
    branding?.branding === BRANDING_KEYS.VCX ? caixaBankButton : ''

  return (
    <div className={`flex gap-4 items-center ${className}`}>
      <Button
        size={BUTTON_SIZES.small}
        className={buttonStyle}
        color={
          branding?.branding === BRANDING_KEYS.VCX
            ? BUTTON_COLORS.transparent
            : BUTTON_COLORS.primary
        }
        rounded
        onClick={toggleAudioEnabled}
        disabled={!audioTrack || isReconnecting}
      >
        {isAudioEnabled ? (
          <MicrophoneFilled size={20} />
        ) : (
          <MicrophoneOffFilled size={20} />
        )}
      </Button>
      <Button
        onClick={() => room.disconnect()}
        color={BUTTON_COLORS.secondary}
        rounded
      >
        <PhoneOff size={25} />
      </Button>
      <Button
        size={BUTTON_SIZES.small}
        color={
          branding?.branding === BRANDING_KEYS.VCX
            ? BUTTON_COLORS.transparent
            : BUTTON_COLORS.primary
        }
        className={buttonStyle}
        onClick={toggleVideo}
        disabled={!hasVideoInputDevices || isReconnecting || isSharingScreen}
        rounded
      >
        {isVideoEnabled ? (
          <VideoFilled size={20} />
        ) : (
          <VideoOffFilled size={20} />
        )}
      </Button>
      <Button
        size={BUTTON_SIZES.small}
        color={
          branding?.branding === BRANDING_KEYS.VCX
            ? BUTTON_COLORS.transparent
            : BUTTON_COLORS.primary
        }
        className={buttonStyle}
        onClick={toggleScreenShare}
        disabled={isReconnecting}
        rounded
      >
        <Share />
      </Button>
    </div>
  )
}
