import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { PDFViewer } from '@react-pdf/renderer'

import { setLocalStorageItem, useRouteState } from '@shared/utils'
import { PDFPreviewRouteState } from './state'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import {
  Button,
  BUTTON_VARIANTS,
  NotificationsOptions,
  ReportPdf,
} from '@shared/ui'
import { ErrorList } from './components'

import { SRErrorModal, SRModal } from '@sr/ui'
import { ROUTE_PATH_CAMPAIGN } from 'apps/social-referent/src/routes/enums'

import { CheckmarkFilled } from '@carbon/icons-react'

import './styles.scss'

type TestParams = {
  page: number
  id: string
}

type Props = {
  name: string
  onSelectTest: (testParams: TestParams) => void
  onPreviewError: () => void
  testOrder: string
  isCompleting: boolean
  statusPostal: 'off' | 'on' | 'disabled'
  sendByPostal: () => void
  dataPostal?: string
  statusEmail: boolean | undefined
  sendByEmail: () => void
  dataEmail?: string
  reportType: number
  generateInform: boolean
}

export const PDFPreview: FC<Props> = observer(
  ({
    onSelectTest,
    onPreviewError,
    isCompleting,
    statusPostal,
    sendByPostal,
    dataPostal,
    statusEmail,
    sendByEmail,
    dataEmail,
    generateInform,
    reportType,
  }) => {
    const { testId } = useParams<{ testId: string }>()
    const { t } = useTranslation()

    const [hasSendedPostal, setHasSendedPostal] = useState<boolean>(false)
    const [sentPostalDate, setSentPostalDate] = useState<string | undefined>()
    const [modifiedState, setModifiedState] = useState<'off' | 'on' | 'disabled'>(statusPostal)

    const [emailState, setEmailState] = useState<boolean | undefined>(statusEmail)
    const [sentEmailDate, setSentEmailDate] = useState<string | undefined>()

    const [hasSendedEmail, setHasSendedEmail] = useState<boolean>(false)

    const { setIsLoading } = useLayoutContext()
    const { 
      hasError, 
      isLoading, 
      testList, 
      resultsParsed,
      analysisType,
      analysisStatus } = useRouteState(
      PDFPreviewRouteState,
      { testId },
    )
    const pdfData = useMemo(() => resultsParsed, [resultsParsed])

    const handleToggleChange = (state: 'off' | 'on' | 'disabled' | boolean) => {
      setHasSendedPostal(state === 'on')
    }

    useEffect(() => {
      if(statusPostal !== 'on') {
        if(analysisStatus === 3) {
          setModifiedState('off')
        } else {
          setModifiedState('disabled')
        }
      }
      if(generateInform) {
        setModifiedState('off') 
      }
      setEmailState(statusEmail)
    }, [statusPostal, statusEmail, analysisStatus, generateInform])

    const closeModalPostal = () => {
      setHasSendedPostal(false)
      setModifiedState('off')
    }

    const handleSendedPostal = () => {
      sendByPostal()
      const currentDate = new Date().toISOString().split('T')[0]
      setLocalStorageItem('date_Sent_Postal', currentDate)
      setSentPostalDate(currentDate)
      setHasSendedPostal(false)
      setModifiedState('on')
      setIsLoading(true)
    }

    const handleEmailChange = () => {
      setHasSendedEmail(true)
    }

    const handleSendedEmail = async () => {
      sendByEmail()
      const currentDate = new Date().toISOString().split('T')[0]
      setLocalStorageItem('date_Sent_Email', currentDate)
      setSentEmailDate(currentDate)
      setHasSendedEmail(false)
    }

    const closeModalEmail = () => {
      setHasSendedEmail(false)
    }

    return (
      <>
        <div className="preview">
          <div className="py-0 pl-6 pr-0 xl:pr-6 w-3/6 lg:max-w-3/6 flex flex-col h-full">
            <ErrorList list={testList} onSelectTest={onSelectTest} />

            <div className="w-full h-full flex flex-col items-end justify-end">
              <NotificationsOptions
                title={t('userArea.notifications.emailTitle')}
                subTitle={t('userArea.notifications.emailSubTitle')}
                notificationId="email"
                handleEmailModal={handleEmailChange}
                icon="attach"
                cta="cta_button"
                status={emailState || (analysisStatus === 3)}
                dataSentNotification={dataEmail || sentEmailDate}
              />
              {statusPostal && (
                <NotificationsOptions
                  title={t('userArea.notifications.postalEmailTitle')}
                  subTitle={t('userArea.notifications.postalEmailSubTitle')}
                  notificationId="postal-mail"
                  handleToggleChange={handleToggleChange}
                  icon="print"
                  cta="cta_toggle"
                  status={modifiedState}
                  dataSentNotification={dataPostal || sentPostalDate}
                />
              )}
            </div>

            {hasSendedPostal && (
              <SRModal onClose={closeModalPostal}>
                <div
                  className="flex flex-col justify-center items-center gap-4 mb-4 text-primary"
                  data-testid="agenda-schedule-modal"
                >
                  <CheckmarkFilled size={50} />
                  <div className="font-normal text-xl text-black">
                    <p>{t('userArea.notifications.modal.postalEmailDescription')}
                    <strong className='ml-2'>{t('userArea.monitoringArea.services.confirmModal.action')}</strong></p>
                  </div>
                  <div className="flex flex-row align-end gap-2 justify-end w-full">
                    <Button
                      variant={BUTTON_VARIANTS.outlined}
                      onClick={closeModalPostal}
                    >
                      {t('common:cancel')}
                    </Button>
                    <Button onClick={handleSendedPostal}>
                      {t('common:continue')}
                    </Button>
                  </div>
                </div>
              </SRModal>
            )}

            {hasSendedEmail && (
              <SRModal onClose={closeModalEmail}>
                <div
                  className="flex flex-col justify-center items-center gap-4 mb-4 text-primary"
                  data-testid="agenda-schedule-modal"
                >
                  <CheckmarkFilled size={50} />
                  <div className="font-normal text-xl text-center text-black">
                    <p>{t('userArea.notifications.modal.emailDescription')}
                    <strong className='ml-2'>{t('userArea.monitoringArea.services.confirmModal.action')}</strong></p>
                  </div>
                  <div className="flex flex-row align-end gap-2 justify-end w-full">
                    <Button
                      variant={BUTTON_VARIANTS.outlined}
                      onClick={closeModalEmail}
                    >
                      {t('common:cancel')} 
                    </Button>
                    <Button onClick={handleSendedEmail}>
                      {t('common:continue')}
                    </Button>
                  </div>
                </div>
              </SRModal>
            )}
          </div>

          <div className="bg-primary p-6 max-w-[70%] lg:w-[52%]">
            {isLoading || isCompleting ? (
              <div>
                <span className="p-3 text-white">
                  {t('common:loadingDownload')}
                </span>
              </div>
            ) : (
              <PDFViewer className="preview__pdf-container" showToolbar={false}>
                <ReportPdf type={analysisType} results={pdfData} />
              </PDFViewer>
            )}
          </div>
        </div>

        {hasError && (
          <SRErrorModal
            onRetry={onPreviewError}
            backUrl={`../${ROUTE_PATH_CAMPAIGN.userNewTest}`}
            mainMessage="analysis:errorModal.previewError"
          />
        )}
      </>
    )
  }
)
