import { Text, View } from '@react-pdf/renderer'
import { AreaColor, getRecommendations, tw } from '../../../utils'
import { useReportPdfContext } from '../../../provider'
import { AreaTable } from '../../../components'
import {
  DAIRY_FREQUENCY,
  FISH_AND_EGGS_FREQUENCY,
  FRUITS_FREQUENCY,
  LEGUMES_FREQUENCY,
  MEAT_FREQUENCY,
  NUTS_FREQUENCY,
  SALT_FREQUENCY,
  TRANSLATION_BASE_KEY,
  VEGETABLES_FREQUENCY,
  WHOLE_GRAINS_FREQUENCY,
} from './enums'

export const NutritionalFrequencyTable = () => {
  const { t, recommendations } = useReportPdfContext()

  const defaultKey = 'rec_nutritional_mediterranean_keep'

  const keyTips = getRecommendations({
    recKeys: recommendations?.NUTRITIONAL,
    defaultKey,
  })[0]

  const list = {
    header: [
      `${TRANSLATION_BASE_KEY}.header.recommendation`,
      `${TRANSLATION_BASE_KEY}.header.frequency`,
    ],
    rows: [
      [
        `${TRANSLATION_BASE_KEY}.rows.vegetables.title`,
        VEGETABLES_FREQUENCY[keyTips] ||
          `${TRANSLATION_BASE_KEY}.general.vegetables`,
      ],
      [
        `${TRANSLATION_BASE_KEY}.rows.fruits.title`,
        FRUITS_FREQUENCY[keyTips] || `${TRANSLATION_BASE_KEY}.general.fruits`,
      ],
      [
        `${TRANSLATION_BASE_KEY}.rows.legumes.title`,
        LEGUMES_FREQUENCY[keyTips] || `${TRANSLATION_BASE_KEY}.general.legumes`,
      ],
      [
        `${TRANSLATION_BASE_KEY}.rows.nuts.title`,
        NUTS_FREQUENCY[keyTips] || `${TRANSLATION_BASE_KEY}.general.nuts`,
      ],
      [
        `${TRANSLATION_BASE_KEY}.rows.wholeGrains.title`,
        WHOLE_GRAINS_FREQUENCY[keyTips] ||
          `${TRANSLATION_BASE_KEY}.general.wholeGrains`,
      ],
      [
        `${TRANSLATION_BASE_KEY}.rows.oliveOil.title`,
        `${TRANSLATION_BASE_KEY}.general.oliveOil`,
      ],
      [
        `${TRANSLATION_BASE_KEY}.rows.fishAndEggs.title`,
        FISH_AND_EGGS_FREQUENCY[keyTips] || [
          `${TRANSLATION_BASE_KEY}.general.fish`,
          `${TRANSLATION_BASE_KEY}.general.eggs`,
        ],
      ],
      [
        `${TRANSLATION_BASE_KEY}.rows.meat.title`,
        MEAT_FREQUENCY[keyTips] || `${TRANSLATION_BASE_KEY}.general.meat`,
      ],
      [
        `${TRANSLATION_BASE_KEY}.rows.salt.title`,
        SALT_FREQUENCY[keyTips] || `${TRANSLATION_BASE_KEY}.general.salt`,
      ],
      [
        `${TRANSLATION_BASE_KEY}.rows.sugar.title`,
        `${TRANSLATION_BASE_KEY}.general.sugar`,
      ],
      [
        `${TRANSLATION_BASE_KEY}.rows.drinks.title`,
        `${TRANSLATION_BASE_KEY}.general.drinks`,
      ],
      [
        `${TRANSLATION_BASE_KEY}.rows.dairy.title`,
        DAIRY_FREQUENCY[keyTips] || `${TRANSLATION_BASE_KEY}.general.dairy`,
      ],
    ],
  }

  return (
    <View style={tw('px-12')}>
      <Text style={tw('font-bold text-base mb-6')}>
        {t(`${TRANSLATION_BASE_KEY}.title`)}
      </Text>
      <AreaTable list={list} areaColor={AreaColor.nutritional} />
    </View>
  )
}
