export const nextButtonClasses = `
  absolute
  right-0
  flex
  items-center
  gap-2
`

export const previousButtonClasses = `
  absolute
  left-0
  flex
  items-center
  gap-2
`
