import { ANNUITY_STATE_COLOR, SERVICE_STATE } from '@sr/data-access'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type AnnuityStateCellProps = {
  state: any
  row: any
}

export const AnnuityStateCell: FC<AnnuityStateCellProps> = ({ state, row }) => {
  const { t } = useTranslation()
  return (
    <div className={ANNUITY_STATE_COLOR[state]}>
      {t(`common:annuityState.${SERVICE_STATE[state]}`)}
    </div>
  )
}
