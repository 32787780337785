import { Text, View } from '@react-pdf/renderer'
import { FC } from 'react'
import {
  DisclaimerBanner,
  ScalePerYear,
  SubSection,
  TableHeaderBlack,
  TranscriptionScriptPDF,
} from '../../../../components'
import { AreaColor, tw } from '../../../../utils/styles'
import { getPfeifferPerYearList, getPfeifferScaleList } from './utils'
import { useReportPdfContext } from '../../../../provider'
import { getRecommendations } from '../../../../utils'

type PfeifferResultsProps = {
  sectionLetter: string
}
export const PfeifferResults: FC<PfeifferResultsProps> = ({
  sectionLetter,
}) => {
  const context = useReportPdfContext()
  const { t, recommendations, person, isCare } = context
  const TRANSLATION_BASE_KEY = 'initial-report:sectionCognitive.results'
  const assessmentScaleList = getPfeifferScaleList(context)
  const assessmentPerYearList = getPfeifferPerYearList(context)

  const defaultKey = 'rec_cognitive_pfeiffer_not_available'

  const feedback = getRecommendations({
    recKeys: recommendations?.COGNITIVE,
    generalKey: '',
    defaultKey,
  })

  return (
    <View style={tw('px-12')}>
      <SubSection
        title={t('initial-report:subSection.results.title', {
          letter: sectionLetter,
        })}
      >
        <View style={tw('flex flex-col gap-6')}>
          <View style={tw('flex flex-col gap-6')}>
            <TableHeaderBlack
              title={t(`initial-report:scores.pfeiffer.title`)}
              list={assessmentScaleList}
            />
            <DisclaimerBanner />
            <ScalePerYear
              scales={assessmentPerYearList}
              areaColor={AreaColor.cognitive}
              none={isCare}
            />
            <View style={tw('flex flex-col pl-6')}>
              <Text>
                <TranscriptionScriptPDF
                  script={`initial-report:scores.pfeiffer.severe.description`}
                />
              </Text>
              <Text>
                <TranscriptionScriptPDF
                  script={`initial-report:scores.pfeiffer.moderate.description`}
                />
              </Text>
              <Text>
                <TranscriptionScriptPDF
                  script={`initial-report:scores.pfeiffer.mild.description`}
                />
              </Text>
              <Text>
                <TranscriptionScriptPDF
                  script={`initial-report:scores.pfeiffer.normal.description`}
                />
              </Text>
            </View>
            {feedback.sort().map((key) => {
              if (!key) return null

              return (
                <TranscriptionScriptPDF
                  key={key}
                  script={`${TRANSLATION_BASE_KEY}.feedback.pfeiffer.${key}`}
                  scriptProps={{ patientName: person?.name }}
                />
              )
            })}
          </View>
        </View>
      </SubSection>
    </View>
  )
}
