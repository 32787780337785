import { LANGUAGES, useRouteState } from '@shared/utils'
import { SRPreSubWizard } from '@sr/ui'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useLayoutContext } from '@sr/routes/common'
import { TranscriptionScript } from '@shared/ui'
import { PreInitialReportRouteState } from './state'

export const PreInitialReport = observer(() => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const onComplete = () => {
    navigate(`../${ROUTE_PATH.initialReport}`)
  }
  const { i18n } = useTranslation()

  const { isLoading, policy, hasStartedTest } = useRouteState(
    PreInitialReportRouteState,
    {
      policyId: id,
    }
  )

  const { setIsLoading } = useLayoutContext()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    i18n.loadLanguages(Object.values(LANGUAGES))
    if (hasStartedTest) return onComplete()
  }, [hasStartedTest])

  const lng = policy?.preferredLanguage

  const onExit = () => {
    console.log('onExit')
  }

  return (
    <SRPreSubWizard
      hidePrev
      onComplete={onComplete}
      onExit={onExit}
      completeText={t('userArea.preInitialReport.finish')}
    >
      <TranscriptionScript
        script={'userArea.preInitialReport.speechOne'}
        scriptProps={{
          clientName: policy?.fullName,
          socialReferent: policy?.socialReferent.name,
          lng,
        }}
      />
      <TranscriptionScript script={'userArea.preInitialReport.speechTwo'} />
      <TranscriptionScript script={'userArea.preInitialReport.speechThree'} />
    </SRPreSubWizard>
  )
})
