export const conversationContainerClasses = `
    flex
    flex-col
    justify-between
    grow
`

export const conversationMessagesBoxClasses = `
    flex
    flex-col-reverse
    grow
    gap-3
    my-5
    overflow-auto
`
