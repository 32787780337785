import { useEffect, useCallback } from 'react'
import {
  VirtualBackgroundProcessor,
  ImageFit,
  isSupported,
  Pipeline,
} from '@twilio/video-processors'

let imageElements = new Map()

const getImage = (index, backgroundImage) => {
  return new Promise((resolve, reject) => {
    if (imageElements.has(index)) {
      return resolve(imageElements.get(index))
    }
    const img = new Image()
    img.onload = () => {
      imageElements.set(index, img)
      resolve(img)
    }
    img.onerror = reject
    img.src = backgroundImage
  })
}

const virtualBackgroundAssets = '/assets/virtualbackground'
let virtualBackgroundProcessor

export default function useBackgroundSettings(
  videoTrack,
  room,
  isAdmin,
  backgroundImage
) {
  const removeProcessor = useCallback(() => {
    if (videoTrack && videoTrack.processor) {
      videoTrack.removeProcessor(videoTrack.processor)
    }
  }, [videoTrack])

  const addProcessor = useCallback(
    (processor) => {
      if (!videoTrack || videoTrack.processor === processor) {
        return
      }
      removeProcessor()
      videoTrack.addProcessor(processor)
    },
    [videoTrack, removeProcessor]
  )

  useEffect(() => {
    if (!isSupported || !isAdmin || !backgroundImage) {
      return
    }
    // make sure localParticipant has joined room before applying video processors
    // this ensures that the video processors are not applied on the LocalVideoPreview
    const handleProcessorChange = async () => {
      if (!virtualBackgroundProcessor) {
        virtualBackgroundProcessor = new VirtualBackgroundProcessor({
          assetsPath: virtualBackgroundAssets,
          backgroundImage: await getImage(0, backgroundImage),
          fitType: ImageFit.Cover,
          pipeline: Pipeline.Canvas2D,
          debounce: true,
        })
        await virtualBackgroundProcessor.loadModel()
      }
      if (!room?.localParticipant) {
        return
      }

      addProcessor(virtualBackgroundProcessor)
    }
    handleProcessorChange()
  }, [videoTrack, room, addProcessor, removeProcessor])
}
