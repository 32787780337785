import { useRouteState } from '@shared/utils'
import { CONTROL_TEST_NAME } from '@sr/data-access'
import { SrTest, SRWizard } from '@sr/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ControlTestScript, TestCancelModal } from './components'
import { useParams } from 'react-router-dom'
import { ControlSummary } from './components/summary/summary'
import { SubscriptionControlRouteState } from './state'
import { useLayoutContext } from '@sr/routes/common'
import {
  TEST_NAME,
  TEST_PAGE,
  TEST_PAGE_NAME,
  TRANSLATION_BASE_KEY,
} from './enums'
import { TranscriptionScript } from '@shared/ui'
import { ClockTextContainer, ScriptBlue } from './styles'

export const SubscriptionControl = observer(() => {
  const [activePageNumber, setActivePageNumber] = useState(0)
  const [cancelModal, setCancelModal] = useState(false)
  const { t } = useTranslation()
  const { id } = useParams()
  const {
    health,
    lobo,
    clock,
    updateTest,
    completeTest,
    loadTestResults,
    testResults,
    policy,
    isLoading,
    hasAllTests,
    allTestCompleted,
    lastTestNotCompleted,
  } = useRouteState(SubscriptionControlRouteState, { policyId: id })

  const { setBackButtonText, setIsLoading } = useLayoutContext()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (hasAllTests) {
      if (allTestCompleted) {
        loadTestResults()
        setActivePageNumber(TEST_PAGE.summary)
      } else {
        setActivePageNumber(TEST_PAGE[TEST_PAGE_NAME[lastTestNotCompleted]])
      }
    }
  }, [
    health?.testCompleted,
    lobo?.testCompleted,
    clock?.testCompleted,
    lastTestNotCompleted,
  ])

  useEffect(() => {
    setBackButtonText(t(TEST_NAME[TEST_PAGE_NAME[activePageNumber]]))
    /*
     * SurveyJS has scroll to form elements,
     * so we have added 100ms of timeout to force scroll to top
     */
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }, [activePageNumber])

  const onExit = () => {
    setCancelModal(true)
  }

  const onCloseModal = () => {
    setCancelModal(false)
  }

  return (
    <>
      <SRWizard
        disableNavigation
        timeline
        hidePrev
        hideNext
        activePageNumber={activePageNumber}
      >
        <ControlTestScript
          name={t(TEST_NAME.health)}
          script={`${TRANSLATION_BASE_KEY}.healthTest.script`}
        >
          <SrTest
            type={CONTROL_TEST_NAME.health}
            test={health?.testMetaJson}
            values={health?.prevData}
            updateTest={updateTest}
            onComplete={() => completeTest(CONTROL_TEST_NAME.health)}
            completeText={t(`${TRANSLATION_BASE_KEY}.buttons.next`)}
            onExit={onExit}
          />
        </ControlTestScript>

        <ControlTestScript
          name={t(TEST_NAME.lobo)}
          script={`${TRANSLATION_BASE_KEY}.loboTest.script`}
        >
          <SrTest
            type={CONTROL_TEST_NAME.lobo}
            test={lobo?.testMetaJson}
            values={lobo?.prevData}
            updateTest={updateTest}
            completeText={t(`${TRANSLATION_BASE_KEY}.buttons.next`)}
            onComplete={() => completeTest(CONTROL_TEST_NAME.lobo)}
            onExit={onExit}
          />
        </ControlTestScript>

        <ControlTestScript name={t(TEST_NAME.clock)}>
          <ClockTextContainer>
            <TranscriptionScript
              script={t(`${TRANSLATION_BASE_KEY}.clockTest.script`)}
              components={{ blue: <ScriptBlue /> }}
            />
          </ClockTextContainer>
          <SrTest
            type={CONTROL_TEST_NAME.clock}
            test={clock?.testMetaJson}
            values={clock?.prevData}
            updateTest={updateTest}
            completeText={t(`${TRANSLATION_BASE_KEY}.buttons.finish`)}
            onComplete={() => completeTest(CONTROL_TEST_NAME.clock)}
            onExit={onExit}
          />
        </ControlTestScript>

        <ControlSummary
          userId={id}
          result={testResults?.parsed}
          name={t(TEST_NAME.summary)}
          policy={policy}
          onExit={onExit}
        />
      </SRWizard>
      {cancelModal && <TestCancelModal onClose={onCloseModal} />}
    </>
  )
})
