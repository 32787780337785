import { flow, types } from 'mobx-state-tree'
import { Policy } from '@shared/data-access'
import { actions } from '@sr/routes/common'
import { BaseTableState } from '@sr/routes/common/store/table-state/table-state'

export const PortfolioManagementClientListRouteState = BaseTableState.named(
  'PortfolioManagementClientListRouteState',
)
  .props({
    socialReferentId: types.maybeNull(types.string),
    policies: types.maybeNull(types.array(types.reference(Policy))),
  })
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('PortfolioManagementClientListRouteState')
      try {
        const [property] = self.sort || []

        self.policies = yield self.loadPaginatedAndFilteredPolicies({
          isClear: true,
          sort: property && `${property.desc ? '-' : ''}${property.id}`,
          filters: {
            ...self.filters,
            socialReferent: self.socialReferentId,
          },
        })
      } finally {
        self.removeLoading('PortfolioManagementClientListRouteState')
      }
    }),
    ...actions(self),
  }))
