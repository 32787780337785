import { SERVICE_TYPES } from '@shared/data-access'

export const TRANSLATION_BASE_KEY = 'userArea.profile.actions.services'

export enum ServiceBlockFormValues {
  wantService = 'wantService',
  serviceType = 'serviceType',
  subState = 'subState',
}

export enum WantServiceValues {
  yes = 'yes',
  no = 'no',
}

export const ServiceDropdownTranslation = {
  [SERVICE_TYPES.PREVENTION_PLAN]: 'services.campaignAnalysis',
}

export const wantServiceOptions = (t: (translation: string) => string) => [
  {
    name: t('common:yes'),
    value: WantServiceValues.yes,
  },
  { name: t('common:no'), value: WantServiceValues.no },
]
