import tw from 'tailwind-styled-components'

export const dotListContainerClasses = `
    py-6
`

export const headerContainerClasses = `
    grid
    items-center
    text-xl
    text-primary
    p-3
    border-b
    border-primary
`

export const headerTitleClasses = `
    flex
    items-center
    gap-3
    basis-0
    grow
    first:pl-4
`

export const itemsListContainer = `
    grid
    gap-4
    grid-cols-12
    px-4
    pt-2
`

export const ItemsListLine = tw.div`
    col-span-12
    relative
    pl-4
    pr-0
    md:pr-4
    space-y-10
    before:absolute
    before:top-7
    before:bottom-6
    before:w-px
    before:-left-3
    ${({ $dotsGray }) =>
      $dotsGray ? 'before:bg-grayLight' : 'before:bg-black'}
   
`

export const RowContainerClasses = tw.li`
    flex
    flex-col-reverse
    md:grid
    md:gap-4
    relative
    pt-1
    before:absolute
    before:top-4
    before:w-5
    before:h-5
    before:rounded-full
    before:left-[-37px]
    before:z-[1]
    ${({ $dotsGray }) =>
      $dotsGray ? 'before:bg-grayLight' : 'before:bg-primary'}
`

export const rowItemClasses = `
    flex
    items-center
    flex-1
    min-h-[40px]
`
