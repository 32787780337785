export const POLICY_REMINDER_CODE = Object.freeze({
  ONLINE_CONFIRMATION_001: '001',
  ONLINE_CONFIRMATION_REQUEST_002: '002',
  ONLINE_CONFIRMATION_REQUEST_003: '003',
  ONLINE_CONFIRMATION_REQUEST_004: '004',
  ONLINE_REMAINDER_005: '005',
  ONLINE_REMAINDER_006: '006',
  ONLINE_REMAINDER_007: '007',
  FACE_TO_FACE_CONFIRMATION_008: '008',
  FACE_TO_FACE_CONFIRMATION_REQUEST_009: '009',
  FACE_TO_FACE_CONFIRMATION_REQUEST_010: '010',
  FACE_TO_FACE_CONFIRMATION_REQUEST_011: '011',
  FACE_TO_FACE_REMAINDER_012: '012',
  FACE_TO_FACE_REMAINDER_013: '013',
  FACE_TO_FACE_REMAINDER_014: '014',
  CLIENT_SIGN_UP: 'AI_000',
  ONLINE_CONFIRMATION_AI_001: 'AI_001',
  ONLINE_REMAINDER_AI_002: 'AI_002',
  ONLINE_REMAINDER_AI_003: 'AI_003',
  FACE_TO_FACE_CONFIRMATION_AI_007: 'AI_007',
  FACE_TO_FACE_REMAINDER_AI_008: 'AI_008',
  FACE_TO_FACE_REMAINDER_AI_009: 'AI_009',
  PHONE_CALL_CONFIRMATION_AI_013: 'AI_013',
  PHONE_CALL_REMAINDER_AI_014: 'AI_014',
  PHONE_CALL_REMAINDER_AI_015: 'AI_015',
})

export const POLICY_REMINDER_TYPE = Object.freeze({
  EMAIL: 0,
  SMS: 1,
  PHONE_CALL: 2,
})

export const POLICY_REMINDER_TYPE_NAMES = Object.freeze({
  [POLICY_REMINDER_TYPE.EMAIL]: 'userArea.communications.reminderType.email',
  [POLICY_REMINDER_TYPE.SMS]: 'userArea.communications.reminderType.sms',
  [POLICY_REMINDER_TYPE.PHONE_CALL]:
    'userArea.communications.reminderType.phoneCall',
})

export const POLICY_REMINDER_TYPE_AND_CODE_NAMES = Object.freeze({
  [POLICY_REMINDER_TYPE.EMAIL]: {
    [POLICY_REMINDER_CODE.ONLINE_CONFIRMATION_001]:
      'userArea.communications.code.001',
    [POLICY_REMINDER_CODE.ONLINE_CONFIRMATION_REQUEST_002]:
      'userArea.communications.code.002',
    [POLICY_REMINDER_CODE.ONLINE_REMAINDER_006]:
      'userArea.communications.code.006',
    [POLICY_REMINDER_CODE.FACE_TO_FACE_CONFIRMATION_008]:
      'userArea.communications.code.008',
    [POLICY_REMINDER_CODE.FACE_TO_FACE_CONFIRMATION_REQUEST_009]:
      'userArea.communications.code.009',
    [POLICY_REMINDER_CODE.FACE_TO_FACE_REMAINDER_013]:
      'userArea.communications.code.013',
  },
  [POLICY_REMINDER_TYPE.SMS]: {
    [POLICY_REMINDER_CODE.ONLINE_CONFIRMATION_001]:
      'userArea.communications.code.001',
    [POLICY_REMINDER_CODE.ONLINE_CONFIRMATION_REQUEST_002]:
      'userArea.communications.code.002',
    [POLICY_REMINDER_CODE.ONLINE_CONFIRMATION_REQUEST_003]:
      'userArea.communications.code.003',
    [POLICY_REMINDER_CODE.ONLINE_REMAINDER_005]:
      'userArea.communications.code.005',
    [POLICY_REMINDER_CODE.ONLINE_REMAINDER_006]:
      'userArea.communications.code.006',
    [POLICY_REMINDER_CODE.ONLINE_REMAINDER_007]:
      'userArea.communications.code.007',
    [POLICY_REMINDER_CODE.FACE_TO_FACE_CONFIRMATION_008]:
      'userArea.communications.code.008',
    [POLICY_REMINDER_CODE.FACE_TO_FACE_CONFIRMATION_REQUEST_009]:
      'userArea.communications.code.009',
    [POLICY_REMINDER_CODE.FACE_TO_FACE_CONFIRMATION_REQUEST_010]:
      'userArea.communications.code.010',
    [POLICY_REMINDER_CODE.FACE_TO_FACE_REMAINDER_013]:
      'userArea.communications.code.013',
  },
  [POLICY_REMINDER_TYPE.PHONE_CALL]: {
    [POLICY_REMINDER_CODE.ONLINE_CONFIRMATION_REQUEST_004]:
      'userArea.communications.code.004',
    [POLICY_REMINDER_CODE.FACE_TO_FACE_CONFIRMATION_REQUEST_011]:
      'userArea.communications.code.011',
    [POLICY_REMINDER_CODE.FACE_TO_FACE_REMAINDER_012]:
      'userArea.communications.code.012',
    [POLICY_REMINDER_CODE.FACE_TO_FACE_REMAINDER_014]:
      'userArea.communications.code.014',
  },
})
