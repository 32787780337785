import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type ScriptBoxProps = {
  isHealthPassed: boolean
  isLoboPassed: boolean
  isClockPassed: boolean
}
export const ScriptBox: FC<ScriptBoxProps> = ({
  isHealthPassed,
  isLoboPassed,
  isClockPassed,
}) => {
  const { t } = useTranslation()
  return (
    <ul>
      <li className={isHealthPassed ? 'text-success' : 'text-error font-bold'}>
        - {t('userArea.control.healthTest.name')}
      </li>
      <li className={isLoboPassed ? 'text-success' : 'text-error font-bold'}>
        - {t('userArea.control.loboTest.name')}
      </li>
      <li className={isClockPassed ? 'text-success' : 'text-error font-bold'}>
        - {t('userArea.control.clockTest.name')}
      </li>
    </ul>
  )
}
