import { useTranslation } from 'react-i18next'
import { UploadFile } from './components'
import { FC } from 'react'
import { ActionsBlock, ActionsBlockTitle } from '../../../styles'

type UploadFileBlockProps = {
  onDrop: () => void
}

export const UploadFileBlock: FC<UploadFileBlockProps> = ({ onDrop }) => {
  const { t } = useTranslation()
  const TRANSLATION_BASE_KEY = 'userArea.profile.actions.upload'

  return (
    <ActionsBlock>
      <ActionsBlockTitle>
        {t(`${TRANSLATION_BASE_KEY}.title`)}
      </ActionsBlockTitle>
      <UploadFile onDrop={onDrop} />
    </ActionsBlock>
  )
}
