import React, { FC } from 'react'
import { EventSchedule, Edit } from '@carbon/icons-react'
import { AppointmentFields } from './enums'
import {
  appointmentRowBorders,
  appointmentRowItem,
  appointmentRowPlaceItem,
} from './styles'

interface Item {
  title?: string
  type?: string
  dateHour?: string
  opName?: string
  modality?: string
  place?: string
  dataToModify?: object
}

interface AppointmentRowProps {
  appointment: Item
  isPast?: boolean
  isCare?: boolean
  onClick?: (dataToModify?: { [key: string]: any }) => void
}

export const AppointmentRow: FC<AppointmentRowProps> = ({
  appointment,
  isPast,
  isCare,
  onClick,
}) => {
  const handleClick = (dataToModify?: object) => {
    if (onClick) {
      onClick(dataToModify)
    }
  }

  const keysToShow = (Object.keys(appointment) as (keyof Item)[]).filter(
    (key) =>
      key !== AppointmentFields.type &&
      key !== AppointmentFields.socialReferentId &&
      key !== AppointmentFields.dataToModify &&
      (isCare || key !== AppointmentFields.place),
  )

  return (
    <tr className={appointmentRowBorders}>
      <td className={appointmentRowItem}>
        <div className='flex flex-row'>
          <EventSchedule width={25} height={25} className="mr-2 relative" />
          <p>{appointment.type}</p>
        </div>
      </td>
      {keysToShow.map((key, index) => (
        <td
          key={key}
          className={
            index === keysToShow.length - 1 && !isPast
              ? appointmentRowPlaceItem
              : appointmentRowItem
          }
        >
          <p className="align-bottom">{String(appointment[key])}</p>
          {index === keysToShow.length - 1 && !isPast && (
            <Edit
              onClick={() => handleClick(appointment?.dataToModify)}
              width={25}
              height={25}
              className="ml-2 cursor-pointer"
            />
          )}
        </td>
      ))}
    </tr>
  )
}
