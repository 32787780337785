import { BaseRouteState, SocialReferent } from '@shared/data-access'
import { actions } from '@sr/routes/common'
import { flow, types } from 'mobx-state-tree'

export const CampaignUserListFilterState = BaseRouteState.named(
  'CampaignUserListFilterState',
)
  .props({
    socialReferent: types.maybeNull(
      types.array(types.reference(SocialReferent)),
    ),
  })
  .views((self) => ({
    get socialReferentList() {
      if (!self.socialReferent?.length) return []
      return self.socialReferent.map(({ id, fullName }) => ({
        value: id,
        label: fullName,
      }))
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('CampaignUserListFilterState')
      try {
        yield self.loadSocialReferent()
      } finally {
        self.removeLoading('CampaignUserListFilterState')
      }
    }),
    ...actions(self),
  }))
