import { types } from 'mobx-state-tree'
import { RootModel } from '@shared/data-access'

export const MODEL_NAME = 'Test'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    // testMetaJson is questions and variants
    testMetaJson: types.maybeNull(types.string),
    // prevData is values already filled
    prevData: types.maybeNull(types.string),
    // testValues is values repopulated or new values
    testValues: types.maybeNull(types.string),
    testType: types.maybeNull(types.integer),
    testCompleted: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    setTestValues(values) {
      self.testValues = JSON.stringify(values)
    },
    setPrevData(values) {
      self.prevData = JSON.stringify(values)
    },
  }))

export default Model
