import { useMessages, useRouteState } from '@shared/utils'
import { MessagesState } from './state'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Conversation } from '@shared/ui'
import { useLayoutContext } from '@sr/routes/common'
import { useTranslation } from 'react-i18next'
import { MessagesContainer, conversationClasses } from './styles'

export const Messages = observer(() => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [conversation, setConversation] = useState(null)
  const [messageCount, setMessageCount] = useState(0)
  const { isLoading, roomSid, user } = useRouteState(MessagesState, {
    policyId: id,
  })
  const {
    isLoading: isLoadingMessages,
    client,
    conversations,
    getConversation,
    setNewMessages,
  } = useMessages()
  const { setIsLoading } = useLayoutContext()

  useEffect(() => {
    if (conversations) {
      const conversation = getConversation({ roomSid })
      setConversation(conversation)
    }
  }, [conversations, roomSid])

  useEffect(() => {
    setIsLoading(isLoading || isLoadingMessages)
  }, [isLoading, isLoadingMessages])

  useEffect(() => {
    if (conversation) {
      conversation.setAllMessagesRead().then(() => {
        setNewMessages(null)
      })
      conversation.getMessagesCount().then((count) => {
        setMessageCount(count)
      })
    }
  }, [conversation])

  if (isLoading || isLoadingMessages) return

  return (
    <MessagesContainer>
      {messageCount ? (
        <Conversation
          conversation={conversation}
          identity={client?.user?.identity}
          ownerName={user?.fullName}
          className={conversationClasses}
        />
      ) : (
        <div>{t('userArea.monitoringArea.chat.noConversation')}</div>
      )}
    </MessagesContainer>
  )
})
