import { areaColorKey } from '../../utils'
import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'
import { FollowRecomendations } from '../../components/FollowRecomendations'
import { useReportPdfContext } from '../../report-pdf-provider'

export const SecondPage = () => {
  const { t, person, type } = useReportPdfContext()

  const areaColor = areaColorKey.red

  const keepInMind = [
    <TranscriptionScriptPDF script="initial-report:sectionSeven.linksAndWorkSheet.keepInMind.one" />,
    <TranscriptionScriptPDF script="initial-report:sectionSeven.linksAndWorkSheet.keepInMind.two" />,
  ]

  const links = [0, 1, 2, 3]
  const workSheetLinks = [0]

  return (
    <FollowRecomendations
      translateFrom="initial-report:sectionSeven.linksAndWorkSheet"
      areaColor={areaColor}
      linksAndWorkSheetTitle={t(
        `initial-report:generic.linksAndWorkSheet.title.${type}`,
      )}
      workSheetList={workSheetLinks}
      linksAndWorkSheetText={
        <TranscriptionScriptPDF
          script={`initial-report:sectionSeven.linksAndWorkSheet.text.${type}`}
          scriptProps={{ patientName: person.name }}
        />
      }
      links={links}
      keepInMindList={keepInMind}
      followRecommendationsText={t(
        'initial-report:sectionSeven.linksAndWorkSheet.followRecommendations.text',
      )}
      omsText={t(
        'initial-report:sectionSeven.linksAndWorkSheet.followRecommendations.omsText',
      )}
      worksheetId={`worksheet-social`}
    />
  )
}
