import tw from 'tailwind-styled-components'

export const UserListContainer = tw.div`
    py-8
    px-20
`

export const inputSearchClassName = `
    mb-6
`
