import { types } from 'mobx-state-tree'

export const ContentObject = types.custom({
  name: 'ContentObject',
  fromSnapshot: (data) => {
    if (!data) return null

    return data
  },
  toSnapshot: (data) => {
    if (!data) return null
    return data
  },
  isTargetType: (maybeData) => {
    return !!maybeData
  },
  getValidationMessage: (snapshot) => {
    if (snapshot === undefined) return ''
    return ''
  },
})
