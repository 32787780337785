import {
  DashboardContainer,
  DashboardWelcome,
  PrimaryBackground,
} from './styles'
import { useTranslation } from 'react-i18next'
import { getDateFormatted, getToday, useRouteState } from '@shared/utils'
import { observer } from 'mobx-react-lite'
import { CoordinatorDashboard } from './coordinator-dashboard'
import { useEffect } from 'react'
import { useLayoutContext } from '../common'
import { SRDashboard } from './sr-dashboard'
import { DashboardRouteState } from './state'

export const Dashboard = observer(() => {
  const { t } = useTranslation()

  const { user, isLoading, annuityList } = useRouteState(DashboardRouteState)

  const { setIsLoading } = useLayoutContext()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  if (!user && !annuityList.length) return

  return (
    <DashboardContainer>
      <PrimaryBackground />
      <DashboardWelcome>
        {t('dashboard.welcome', {
          name: user?.fullName,
          date: getDateFormatted(getToday(), 'dddd, D [de] MMMM [de] YYYY'),
        })}
      </DashboardWelcome>
      {user?.isCoordinator ? (
        <CoordinatorDashboard id={user.id} annuityList={annuityList} />
      ) : (
        <SRDashboard user={user} annuityList={annuityList} />
      )}
    </DashboardContainer>
  )
})
