import {
  User,
  WatsonHealthTextAnnotationToggle,
  ToolBox,
  ReportData,
} from '@carbon/icons-react'
import { ROUTE_PATH_CAMPAIGN } from 'apps/social-referent/src/routes/enums'

export const userCards = (t) => {
  const userCardsArray = [
    {
      icon: <User size={60} />,
      title: t('campaign.userArea.cards.userData.title'),
      linkText: t('campaign.userArea.cards.userData.linkText'),
      path: ROUTE_PATH_CAMPAIGN.userProfile,
      variant: 'secondary',
    },
    {
      icon: <WatsonHealthTextAnnotationToggle size={60} />,
      title: t('campaign.userArea.cards.preventionPlan.title'),
      linkText: t('campaign.userArea.cards.preventionPlan.linkText'),
      path: ROUTE_PATH_CAMPAIGN.userNewTest,
      variant: 'secondary',
    },
    {
      icon: <ToolBox size={60} />,
      title: t('campaign.userArea.cards.services.title'),
      linkText: t('campaign.userArea.cards.services.linkText'),
      path: ROUTE_PATH_CAMPAIGN.userServices,
      variant: 'secondary',
    },
    {
      icon: <ReportData width={60} height={60} />,
      title: t(`campaign.userArea.cards.appointmentManager.title`),
      linkText: t(`campaign.userArea.cards.appointmentManager.link`),
      path: `${ROUTE_PATH_CAMPAIGN.appointmentManager}`,
      variant: 'secondary',
    },
  ]

  return userCardsArray
}
