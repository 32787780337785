import { View } from '@react-pdf/renderer'
import {
  LayoutPage,
  SubSection,
  TranscriptionScriptPDF,
} from '../../components'
import { tw } from '../../utils'
import { Numberedlist } from '../../../sr-initial-report-pdf/components/NumberedList'
import { FC } from 'react'
import { useReportPdfContext } from '../../provider'
import { ListItem } from './components'

type GeneralRecommendationsProps = {
  number: number
}

type TransItem = {
  title: string
  description: string
  extra: string[]
}

export const GeneralRecommendations: FC<GeneralRecommendationsProps> = ({
  number,
}) => {
  const { t, person } = useReportPdfContext()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const listOfTranslations: TransItem[] =
    t('generalRecommendations.list', {
      returnObjects: true,
    }) || []

  const list = listOfTranslations.map(({ title, description, extra }) => ({
    title,
    content: extra ? (
      <ListItem
        key={`general-recommendations-item-${title}`}
        scriptProps={{ opName: person?.socialReferent.name }}
        description={description}
        extra={extra}
      />
    ) : (
      <TranscriptionScriptPDF
        key={`general-recommendations-item-${title}`}
        script={description}
        scriptProps={{ opName: person?.socialReferent.name }}
      />
    ),
    isComponent: Boolean(extra),
  }))

  return (
    <LayoutPage
      title={t('initial-report:generalRecommendations.title', { number })}
    >
      <SubSection>
        <View style={tw('flex flex-col gap-2')}>
          <TranscriptionScriptPDF script="initial-report:generalRecommendations.description" />
          <Numberedlist list={list} column withLetters />
        </View>
      </SubSection>
    </LayoutPage>
  )
}
