import { Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { Bulb } from '../../assets/global/bulb'
import { FC } from 'react'

type TitleIconProps = {
  areaColor: string
  title: string
  icon?: JSX.Element
}

export const TitleIcon: FC<TitleIconProps> = ({
  areaColor = 'primary',
  title,
  icon,
}) => {
  return (
    <View
      break
      style={tw(
        `flex flex-row justify-between text-white items-center text-lg font-semibold bg-${areaColor} px-4 py-2`
      )}
    >
      <Text style={tw('leading-5')}>{title}</Text>
      {icon ? icon : <Bulb height="20" width="20" />}
    </View>
  )
}
