import { getDateFormatted, getJSDate } from '@shared/utils'
import { SLOT_TYPE } from 'libs/shared/data-access/src/lib/service-slot/enum'
import { useMemo } from 'react'
import { DaySlot, Schedule, SingleSchedule, Slot, UseSchedule } from './types'
import { ScheduleDay } from '../schedule/components/schedule-day'

export const useSchedule: UseSchedule = (slots) => {
  const splitArray = (array: SingleSchedule) => {
    const WEEK_DAYS = 7
    const result: Schedule = []
    let currentSubarray: SingleSchedule = []

    for (let i = 0; i < array.length; i++) {
      if (currentSubarray.length < WEEK_DAYS) {
        currentSubarray.push(array[i])
      } else {
        result.push(currentSubarray)
        currentSubarray = [array[i]]
      }
    }

    if (currentSubarray.length > 0) {
      result.push(currentSubarray)
    }

    return result
  }

  const getWorkDays = (slots: Slot[]) => {
    const workDays: string[] = []

    slots.forEach((slot) => {
      const date = getDateFormatted(slot.startDate, 'DD/MM/YYYY').toString()

      if (!workDays.includes(date)) {
        workDays.push(date)
      }
    })

    return workDays
  }

  const getDaySlots = (slots: Slot[], day: string) => {
    const daySlots: DaySlot[] = []

    slots.forEach((slot) => {
      const date = getDateFormatted(slot.startDate, 'DD/MM/YYYY').toString()

      if (date === day) {
        daySlots.push({
          startDate: slot.startDate,
          endDate: slot.endDate,
          isUnavailable: slot.slotType === SLOT_TYPE.unavailable,
        })
      }
    })

    return daySlots
  }

  const formatDay = ({ day }: { day: string }) => {
    const dateObject = getJSDate(day)

    return (
      <ScheduleDay
        dayClassName="flex place-content-center"
        containerClassName="w-full flex gap-2 justify-center items-end uppercase font-semibold"
        dayNumberClassName="font-semibold text-2xl"
        day={dateObject}
      />
    )
  }

  const schedule = useMemo(() => {
    const workDays = getWorkDays(slots)

    const slotDays = workDays.map((day) => {
      const daySlots = getDaySlots(slots, day)

      return {
        day: formatDay({ day }),
        daySlots,
      }
    })

    return splitArray(slotDays)
  }, [slots, getWorkDays, getDaySlots])

  return schedule
}
