import { useCallback, useState } from 'react'
import Video from 'twilio-video'
import { notify } from '@shared/ui'
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  getDeviceInfo,
  isPermissionDenied,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
} from '../../../../utils'

/**
 * useLocalTracks prepare all for up the Preview Screen and VideoCall Screen
 * @return  {Array} localTracks
 * @return  {Boolean} isAcquiringLocalTracks
 * @return  {Function} removeLocalAudioTrack
 * @return  {Function} removeLocalVideoTrack
 * @return  {Function} getAudioAndVideoTracks
 * @return  {Function} getLocalVideoTrack
 */

export const useLocalTracks = () => {
  const [audioTrack, setAudioTrack] = useState()
  const [videoTrack, setVideoTrack] = useState()
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false)

  /** Gets an audio track on the video device selected by the browser
   * IMPORTANT: it is the audio track that is already in use
   */
  const getLocalVideoTrack = useCallback(async () => {
    const selectedVideoDeviceId = window.localStorage.getItem(
      SELECTED_VIDEO_INPUT_KEY
    )

    const { videoInputDevices } = await getDeviceInfo()

    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    )

    const options = {
      ...DEFAULT_VIDEO_CONSTRAINTS,
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && {
        deviceId: { exact: selectedVideoDeviceId },
      }),
    }

    return Video.createLocalVideoTrack(options).then((newTrack) => {
      setVideoTrack(newTrack)
      return newTrack
    })
  }, [])

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop()
      setAudioTrack(undefined)
    }
  }, [audioTrack])

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop()
      setVideoTrack(undefined)
    }
  }, [videoTrack])

  /** Gets the audio and video track preselected in the browser.
   * It is used for the preview screen
   */
  const getAudioAndVideoTracks = useCallback(async () => {
    const {
      audioInputDevices,
      videoInputDevices,
      hasAudioInputDevices,
      hasVideoInputDevices,
    } = await getDeviceInfo()

    if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve()
    if (isAcquiringLocalTracks || audioTrack || videoTrack)
      return Promise.resolve()

    setIsAcquiringLocalTracks(true)

    const selectedAudioDeviceId = window.localStorage.getItem(
      SELECTED_AUDIO_INPUT_KEY
    )
    const selectedVideoDeviceId = window.localStorage.getItem(
      SELECTED_VIDEO_INPUT_KEY
    )

    const hasSelectedAudioDevice = audioInputDevices.some(
      (device) =>
        selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
    )
    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    )

    // In Chrome, it is possible to deny permissions to only audio or only video.
    // If that has happened, then we don't want to attempt to acquire the device.
    const isCameraPermissionDenied = await isPermissionDenied('camera')
    const isMicrophonePermissionDenied = await isPermissionDenied('microphone')

    const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied
    const shouldAcquireAudio =
      hasAudioInputDevices && !isMicrophonePermissionDenied

    const localTrackConstraints = {
      video: shouldAcquireVideo && {
        ...DEFAULT_VIDEO_CONSTRAINTS,
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && {
          deviceId: { exact: selectedVideoDeviceId },
        }),
      },
      audio: shouldAcquireAudio && {
        ...(hasSelectedAudioDevice && {
          deviceId: { exact: selectedAudioDeviceId },
        }),
      },
    }

    return Video.createLocalTracks(localTrackConstraints)
      .then((tracks) => {
        const newVideoTrack = tracks.find((track) => track.kind === 'video')
        const newAudioTrack = tracks.find((track) => track.kind === 'audio')
        if (newVideoTrack) {
          setVideoTrack(newVideoTrack)
          window.localStorage.setItem(
            SELECTED_VIDEO_INPUT_KEY,
            newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? ''
          )
        }
        if (newAudioTrack) {
          setAudioTrack(newAudioTrack)
        }

        if (isCameraPermissionDenied) {
          notify.error('isCameraPermissionDenied')
        }

        if (isMicrophonePermissionDenied) {
          notify.error('isMicrophonePermissionDenied')
        }
      })
      .finally(() => setIsAcquiringLocalTracks(false))
  }, [audioTrack, notify, videoTrack, isAcquiringLocalTracks])

  const localTracks = [audioTrack, videoTrack].filter(
    (track) => track !== undefined
  )
  const changeVideoTrack = async ({ deviceId }) => {
    const { hasVideoInputDevices } = await getDeviceInfo()
    const isCameraPermissionDenied = await isPermissionDenied('camera')
    const shouldAcquireVideo = hasVideoInputDevices && !isCameraPermissionDenied

    if (shouldAcquireVideo) {
      if (
        videoTrack &&
        videoTrack.mediaStreamTrack.getSettings().deviceId !== deviceId
      ) {
        videoTrack.stop()

        const localTrackConstraints = {
          ...DEFAULT_VIDEO_CONSTRAINTS,
          name: `camera-${Date.now()}`,
          deviceId: { exact: deviceId },
        }

        try {
          const newTracks = await Video.createLocalTracks({
            video: localTrackConstraints,
          })
          const newVideoTrack = newTracks.find(
            (track) => track.kind === 'video'
          )

          if (newVideoTrack) {
            setVideoTrack(newVideoTrack)
          }
        } catch (error) {
          console.error(error)
        }
      }
    } else {
      console.error(
        'No se puede cambiar la pista de video: Permiso denegado o dispositivos no disponibles'
      )
    }
  }

  return {
    localTracks,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
    getLocalVideoTrack,
    changeVideoTrack,
  }
}
