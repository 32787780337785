import { SRConfirmModal, SRErrorModal, SRSuccessModal } from '@sr/ui'
import { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ConfirmModalContentContainer,
  inputsModalContainer,
  RadioModalContentContainer,
  inputsModal,
  speechBubbleContainer,
  errorStyle,
  errorBorders,
} from './styles'
import { FORM_TYPE, Form, Input, SpeechBubble } from '@shared/ui'
import {
  resendCommunicationsNames,
  resendCommunicationsValues,
  useSchema,
} from './enum'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Information, Warning } from '@carbon/icons-react'
import { isNotEmptyObject } from '@shared/utils'

type ConfirmResendCommunicationsModalProps = {
  onClose: () => void
  action: (email?: string, phone?: string) => Promise<boolean>
}

export const ConfirmResendCommunicationsModal: FC<
  ConfirmResendCommunicationsModalProps
> = ({ onClose, action }) => {
  const { t } = useTranslation()
  const schema = useSchema(t)
  const methods = useForm({
    resolver: yupResolver(schema),
  })
  const {
    handleSubmit,
    formState: { errors },
  } = methods

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [showInformation, setShowInformation] = useState(false)
  const [selectedValue, setSelectedValue] = useState<string | null>(null)

  const radioOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value)
  }

  const onAction = async () => {
    if (selectedValue === resendCommunicationsValues.sameEmail) {
      try {
        await action()
        setIsSuccess(true)
      } catch (error) {
        setIsError(true)
      }
    } else if (selectedValue === resendCommunicationsValues.otherEmail) {
      handleSubmit(async (formData) => {
        try {
          await action(formData.email || undefined, formData.phone || undefined)
          setIsSuccess(true)
        } catch (error) {
          setIsError(true)
        }
      })()
    }
  }

  const handleError = (error: any) => {
    // eslint-disable-next-line
    // @ts-ignore
    return isNotEmptyObject(error) || isNotEmptyObject(errors.emailOrPhone)
  }

  return (
    <>
      {!isSuccess && !isError && (
        <SRConfirmModal
          onClose={onClose}
          onAction={onAction}
          onConfirmText={t('common:resend')}
          onCancelText={t('common:cancel')}
          selectedValue={selectedValue}
          title={t('agenda.formModal.confirmResendModal.title')}
          location="end"
          textColor="primary"
          disabledValidation
        >
          <ConfirmModalContentContainer $fontColor='text-black'>
            <div>{t('agenda.formModal.confirmResendModal.question')}</div>
            <RadioModalContentContainer>
              <div>
                <Input
                  className="accent-primary"
                  type={FORM_TYPE.radio}
                  id={`${resendCommunicationsValues.sameEmail}_radio`}
                  value={resendCommunicationsValues.sameEmail}
                  name={resendCommunicationsNames.resendCommunications}
                  onChange={radioOnChange}
                  isChecked={
                    selectedValue === resendCommunicationsValues.sameEmail
                  }
                />
                <label
                  className="ml-4"
                  htmlFor={`${resendCommunicationsValues.sameEmail}_radio`}
                >
                  {t('agenda.formModal.confirmResendModal.sameEmail')}
                </label>
              </div>
              <div className="flex">
                <Input
                  className="accent-primary"
                  type={FORM_TYPE.radio}
                  id={`${resendCommunicationsValues.otherEmail}_radio`}
                  value={resendCommunicationsValues.otherEmail}
                  name={resendCommunicationsNames.resendCommunications}
                  onChange={radioOnChange}
                  isChecked={
                    selectedValue === resendCommunicationsValues.otherEmail
                  }
                />
                <label
                  className="ml-4"
                  htmlFor={`${resendCommunicationsValues.otherEmail}_radio`}
                >
                  {t('agenda.formModal.confirmResendModal.otherEmail')}
                </label>
                <div className={speechBubbleContainer}>
                  <Information
                    data-tooltip-id="suscriptionAppointmentText"
                    size={15}
                    className="text-primary"
                    onMouseEnter={() => setShowInformation(true)}
                    onMouseLeave={() => setShowInformation(false)}
                  />
                  {showInformation && (
                    <SpeechBubble
                      background="bg-white"
                    >
                      {t('agenda.formModal.confirmResendModal.informationText')}
                    </SpeechBubble>
                  )}
                </div>
              </div>
            </RadioModalContentContainer>
            {selectedValue === resendCommunicationsValues.otherEmail && (
              <Form methods={methods} className={inputsModalContainer}>
                <Input
                  className={`${
                    handleError(errors?.email) && errorBorders
                  } ${inputsModal}`}
                  type={FORM_TYPE.email}
                  name={resendCommunicationsNames.email}
                  placeholder={t('agenda.formModal.confirmResendModal.email')}
                />
    
                {errors.email && (
                  <p className={errorStyle}>{errors.email?.message}</p>
                )}
                <Input
                  className={`${
                    handleError(errors?.phone) && errorBorders
                  } ${inputsModal}`}
                  type={FORM_TYPE.phone}
                  name={resendCommunicationsNames.phone}
                  placeholder={t('agenda.formModal.confirmResendModal.phone')}
                />
                {errors.phone && (
                  <p className={errorStyle}>{errors?.phone?.message}</p>
                )}

                {
                  // eslint-disable-next-line
                  // @ts-ignore
                  errors.emailOrPhone && (
                    <p className={errorStyle}>
                      <Warning size={20} />
                      {/**
                       *  @ts-ignore */}
                      {errors?.emailOrPhone?.message}
                    </p>
                  )
                }
              </Form>
            )}
          </ConfirmModalContentContainer>
        </SRConfirmModal>
      )}
      {isSuccess && (
        <SRSuccessModal
          onClose={onClose}
          mainMessage={t('agenda.formModal.confirmResendModal.success')}
          noRoute
        />
      )}
      {isError && (
        <SRErrorModal
          onClose={onClose}
          mainMessage={t('agenda.formModal.confirmResendModal.error')}
          noRoute
          hideActions
        />
      )}
    </>
  )
}
