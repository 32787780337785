type MedasScoreProps = {
  score: number
}

export enum MedasScoreResult {
  lowAdherence,
  highAdherence,
}

interface SCORE_NAME {
  [key: number]: string
}

export const MedasScoreName: SCORE_NAME = Object.freeze({
  [MedasScoreResult.lowAdherence]: 'lowAdherence',
  [MedasScoreResult.highAdherence]: 'highAdherence',
})

export class MedasScore {
  score: number
  result: number
  lowAdherence: number
  highAdherence: number
  scoreName: string
  scale: string

  constructor({ score }: MedasScoreProps) {
    this.score = score
    this.result = MedasScoreResult.lowAdherence
    this.lowAdherence = 9
    this.highAdherence = 14
    this.scoreName = ''
    this.scale = `${score}/${this.highAdherence}`
    this.init()
  }

  private init() {
    this.setResult()
    this.setScoreName()
  }

  private setResult() {
    if (typeof this.score === 'string') {
      return
    }
    if (this.score < this.lowAdherence) {
      this.result = MedasScoreResult.lowAdherence
    }
    if (this.score >= this.lowAdherence && this.score <= this.highAdherence) {
      this.result = MedasScoreResult.highAdherence
    }
  }

  private setScoreName() {
    this.scoreName = MedasScoreName[this.result]
  }
}
