import { View } from '@react-pdf/renderer'
import {
  DisclaimerBanner,
  ScalePerYear,
  TableHeaderBlack,
  TranscriptionScriptPDF,
} from '../../../../components'
import { AreaColor, scoreIsNotAvailable, tw } from '../../../../utils'
import { useReportPdfContext } from '../../../../provider'
import { getFagerstromPerYearList, getFagerstromScaleList } from './utils'

export const FagerstromTable = () => {
  const context = useReportPdfContext()
  const { t, scores } = context

  return (
    <View style={tw('flex flex-col gap-6')}>
      <TableHeaderBlack
        title={t(`initial-report:scores.fagerstrom.title`)}
        list={getFagerstromScaleList(context)}
      />
      <DisclaimerBanner />
      <ScalePerYear
        scales={getFagerstromPerYearList(context)}
        areaColor={AreaColor.tobaccoAlcohol}
        none={scoreIsNotAvailable(scores?.fagerstromScore?.score)}
      />
      <View>
        <TranscriptionScriptPDF
          script={`initial-report:scores.fagerstrom.high.description`}
        />
        <TranscriptionScriptPDF
          script={`initial-report:scores.fagerstrom.medium.description`}
        />
        <TranscriptionScriptPDF
          script={`initial-report:scores.fagerstrom.low.description`}
        />
      </View>
    </View>
  )
}
