import { FC } from 'react'
import { ReportComponent, ReportType } from './enums'
import { ReportData } from './types'
import { PreviewError } from './components'

type ReportPdfProps = {
  type: ReportType
  results: ReportData
}

export const ReportPdf: FC<ReportPdfProps> = ({ type, results }) => {
  const Report = ReportComponent[type]

  if (!results?.results) return <PreviewError />

  return <Report data={results} type={type} />
}
