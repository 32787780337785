import { getTimeFromTodayInText } from '@shared/utils'
import {
  chatAuthorClasses,
  chatBubbleAvatarClasses,
  chatBubbleContainerClasses,
  chatBubbleContentContainerClasses,
  chatBubbleTimeClasses,
} from './styles'

export const ChatBubble = ({ message, identity, ownerName, otherName }) => {
  if (!message) return

  const {
    state: { author, body, dateUpdated },
    friendlyName,
  } = message

  const isMine = author === identity
  const bubbleColor = isMine ? 'bg-[#D9D9D9]' : 'bg-[#F5F5F5]'
  const messageAuthor = isMine ? 'Tú' : otherName || friendlyName

  return (
    <div className={chatBubbleContainerClasses}>
      <img
        src={`https://ui-avatars.com/api/?name=${
          isMine ? ownerName : otherName || friendlyName
        }&background=random`}
        alt={isMine ? ownerName : otherName || friendlyName}
        className={`${chatBubbleAvatarClasses} ${isMine && 'order-1'}`}
      />
      <div className={`${chatBubbleContentContainerClasses} ${bubbleColor}`}>
        <div className={chatAuthorClasses}>
          <b>{messageAuthor}</b>
          <span className={chatBubbleTimeClasses}>
            {getTimeFromTodayInText(dateUpdated)}
          </span>
        </div>
        <div>{body}</div>
      </div>
    </div>
  )
}
