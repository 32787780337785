import { SectionTobaccoAndAlcohol } from '../../report-pdf/sections'
import { SecondPage } from './components/second-page'

export const SectionNine = () => {
  return (
    <SectionTobaccoAndAlcohol sectionNumber="07">
      <SecondPage />
    </SectionTobaccoAndAlcohol>
  )
}
