import { useState, useMemo } from 'react'
import { VideoCallPermissions } from '../../utils'

export const usePermissions = ({ onError = () => {} }) => {
  const [permissionStatus, setPermissionStatus] = useState(
    VideoCallPermissions.loading
  )

  const isLoading = useMemo(() => {
    return permissionStatus === VideoCallPermissions.loading
  }, [permissionStatus])

  const hasDenied = useMemo(() => {
    return permissionStatus === VideoCallPermissions.denied
  }, [permissionStatus])

  const hasPermissions = useMemo(() => {
    return permissionStatus === VideoCallPermissions.granted
  }, [permissionStatus])

  const checkPermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      })

      stream.getTracks().forEach((track) => track.stop())
      setPermissionStatus(VideoCallPermissions.granted)
    } catch (error) {
      console.error({ error })
      onError(error.name)
      setPermissionStatus(VideoCallPermissions.denied)
    }
  }

  return { isLoading, hasDenied, hasPermissions, checkPermissions }
}
