import {
  applySnapshot,
  flow,
  getRoot,
  isReferenceType,
  getPropertyMembers,
  isArrayType,
  isOptionalType,
  getChildType,
  getSnapshot,
  onPatch,
  types,
} from 'mobx-state-tree'

const RELATIONSHIP_TYPES = {
  one: 'one',
  many: 'many',
}

const getRelationshipType = (valueType) => {
  // One to one relationships checks types.maybeNull(types.reference.ModelName))
  if (isReferenceType(valueType)) {
    return RELATIONSHIP_TYPES.one
  }
  // One to many relationships checks types.optional(types.array(types.reference.ModelName), [])
  // When changes other models checks that one to many relationships has the previous structure
  if (
    isOptionalType(valueType) &&
    isArrayType(valueType._subtype) &&
    isReferenceType(valueType._subtype._subType)
  ) {
    return RELATIONSHIP_TYPES.many
  }
  return false
}

export const Model = types
  .model('RootModel', {
    id: types.identifier,
  })
  .volatile((self) => ({
    _store: getRoot(self).ModelStore || null,
    _links: {},
    _relationships: {},
    _dirtyFields: [],
  }))
  .views((self) => ({
    get locale() {
      return getRoot(self).locale
    },
    dirtyFields() {
      const snapshot = getSnapshot(self)
      return self._dirtyFields.reduce((obj, key) => {
        return { ...obj, [key]: snapshot[key] }
      }, {})
    },
    getRelationshipFetchMethod(relationshipType) {
      const fetchMethodsMap = {
        [RELATIONSHIP_TYPES.one]: self.fetchOneToOneRelationship,
        [RELATIONSHIP_TYPES.many]: self.fetchOneToManyRelationship,
      }

      return fetchMethodsMap[relationshipType] || null
    },
  }))
  .actions((self) => ({
    afterAttach() {
      onPatch(self, self.logDirtyField)
      self.clearDirtyFields()
    },
    logDirtyField(patch) {
      const field = patch.path.split('/')[1]
      if (field) {
        self._dirtyFields = [...new Set([...self._dirtyFields, field])]
      }
    },
    clearDirtyFields(fields = []) {
      if (fields.length === 0) self._dirtyFields = []
      else
        self._dirtyFields = self._dirtyFields.filter(
          (field) => fields.indexOf(field) < 0
        )
    },
    uploadFiles: flow(function* uploadFiles(files) {
      return yield self._store.uploadFiles(self, files)
    }),
    setLinks(links) {
      self._links = { ...self._links, ...links }
    },
    setRelationships(relationships) {
      self._relationships = { ...self._relationships, ...relationships }
    },
    update(data) {
      const updatedData = { ...getSnapshot(self), ...data }
      applySnapshot(self, updatedData)
      return self
    },
    refresh() {
      return self._store.refreshItem(self.id)
    },
    delete() {
      return self._store.deleteItem(self.id, {
        customUrl: self._links.related,
      })
    },
    save: flow(function* save(options) {
      yield self._store.save(self, {
        customUrl: self._links?.related,
        ...options,
      })
      return self
    }),

    fetchRelationships(properties = []) {
      const allProps = getPropertyMembers(self).properties
      const propsToFetch = !properties.length
        ? Object.entries(allProps)
        : properties.map(({ property, options }) => [
            property,
            allProps[property],
            options,
          ])

      const promises = propsToFetch.reduce(
        (acc, [key, value, options = {}]) => {
          const relationshipType = getRelationshipType(value)
          if (!relationshipType) return acc

          const result = self.getRelationshipFetchMethod(relationshipType)(
            key,
            options
          )

          if (result) acc.push(result)
          return acc
        },
        []
      )

      return Promise.all(promises)
    },

    fetchOneToOneRelationship: flow(function* fetchOneToOneRelationship(
      field,
      options
    ) {
      const modelName = getChildType(self, field)._types[0]?.targetType?.name

      if (!self._relationships[field]?.data?.id || !modelName) return

      const storeName = `${modelName}Store`
      const element = yield getRoot(self)[storeName]?.get(
        self._relationships[field].data.id,
        {
          customUrl: self._relationships[field].links.related,
          action: `fetch${field}`,
          ...options,
        }
      )
      self[field] = element
      return element
    }),

    fetchOneToManyRelationship: flow(function* fetchOneToManyRelationship(
      field,
      options
    ) {
      const modelName = getChildType(self, field)._subtype?._subType?.targetType
        ?.name

      if (!self._relationships[field]?.data?.length || !modelName) return

      const storeName = `${modelName}Store`
      const elements = yield getRoot(self)[storeName].fetchAll({
        customUrl: self._relationships[field].links.related,
        action: `fetch${field}`,
        noClear: true,
        ...options,
      })
      self[field] = elements
      self.clearDirtyFields([field])
      return elements
    }),
  }))

export default Model
