import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

interface LayoutContextValues {
  backButtonText: string
  setBackButtonText: (text: string) => void
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  bgNeutral: boolean
  setBgNeutral: Dispatch<SetStateAction<boolean>>
}

const LayoutProvider = createContext<LayoutContextValues>(
  {} as LayoutContextValues,
)

export function LayoutContextProvider({ children }: { children: JSX.Element }) {
  const [backButtonText, setBackButton] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [bgNeutral, setBgNeutral] = useState(false)

  const setBackButtonText = (newText: string) => {
    if (backButtonText === newText) return
    setBackButton(newText)
  }

  return (
    <LayoutProvider.Provider
      value={{
        backButtonText,
        setBackButtonText,
        isLoading,
        setIsLoading,
        bgNeutral,
        setBgNeutral,
      }}
    >
      {children}
    </LayoutProvider.Provider>
  )
}

export function useLayoutContext() {
  return useContext(LayoutProvider)
}
