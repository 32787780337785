export const modalOverlayClassName = `
    overflow-y-auto
    overflow-x-hidden
    fixed
    top-0
    right-0
    left-0
    z-40
    w-full
    h-full
    flex
    items-center
    justify-center
    bg-modal-overlay
`

export const modalContainerClassName = `
    py-4
    px-6
    bg-white
    text-dark-gray
    rounded-2xl
    w-[592px]
    shadow-md
`

export const crossCancelContainerClasses = `
    flex
    flex-row-reverse
    mb-4
`

export const titleInCross = `
    justify-between
    mt-2.5
    items-center
`


export const crossCancelIconClasses = `
    cursor-pointer
`

export const crossCancelTitle = `
    text-xl
    font-medium
`
