import { Button, BUTTON_COLORS, BUTTON_SIZES } from '@shared/ui'
import { useTranslation } from 'react-i18next'
import { TestModalActions, TestModalContent } from './styles'

export const TestCancelFirst = ({ onCancel, onClose }) => {
  const { t } = useTranslation()

  return (
    <>
      <TestModalContent>
        {t('userArea.control.modal.confirmation')}
      </TestModalContent>
      <TestModalActions>
        <Button
          size={BUTTON_SIZES.small}
          color={BUTTON_COLORS.secondary}
          onClick={onCancel}
          testId={'abandon-button'}
        >
          {t('userArea.control.modal.leaveTest')}
        </Button>
        <Button size={BUTTON_SIZES.small} onClick={onClose}>
          {t('userArea.control.modal.continueTest')}
        </Button>
      </TestModalActions>
    </>
  )
}
