import { types } from 'mobx-state-tree'

const FromPolicyHistory = (data) => ({
  model: data.model,
  pk: data.pk,
  state: data.state,
  subState: data.sub_state,
})

const ToPolicyHistory = (data) => ({
  model: data.model,
  pk: data.pk,
  state: data.state,
  sub_state: data.subState,
})

const FromEmailCommunication = (data) => ({
  model: data.model,
  pk: data.pk,
  communicationType: data.communication_type,
})

const ToEmailCommunication = (data) => ({
  model: data.model,
  pk: data.pk,
  communication_type: data.communicationType,
})

const FromSmsCommunication = (data) => ({
  model: data.model,
  pk: data.pk,
  communicationType: data.communication_type,
})

const ToSmsCommunication = (data) => ({
  model: data.model,
  pk: data.pk,
  communication_type: data.communicationType,
})

const FromPhoneCall = (data) => ({
  pk: data.pk,
  callDate: data.call_date,
  callType: data.call_type,
  callDuration: data.call_duration,
  callDirection: data.call_direction,
  model: data.model,
})

const ToPhoneCall = (data) => ({
  pk: data.pk,
  call_date: data.callDate,
  call_type: data.callType,
  call_duration: data.callDuration,
  call_direction: data.callDirection,
  model: data.model,
})

export const ModelType = {
  PolicyHistory: 'PolicyHistory',
  EmailCommunication: 'EmailCommunication',
  SmsCommunication: 'SmsCommunication',
  PhoneCall: 'PhoneCall',
}

export const ModelTypeFrom = (data) => ({
  [ModelType.PolicyHistory]: FromPolicyHistory(data),
  [ModelType.EmailCommunication]: FromEmailCommunication(data),
  [ModelType.SmsCommunication]: FromSmsCommunication(data),
  [ModelType.PhoneCall]: FromPhoneCall(data),
})

export const ModelTypeTo = (data) => ({
  [ModelType.PolicyHistory]: ToPolicyHistory(data),
  [ModelType.EmailCommunication]: ToEmailCommunication(data),
  [ModelType.SmsCommunication]: ToSmsCommunication(data),
  [ModelType.PhoneCall]: ToPhoneCall(data),
})

export const ContentObject = types.custom({
  name: 'ContentObject',
  fromSnapshot: (data) => {
    if (!data) return null
    return ModelTypeFrom(data)[data.model]
  },
  toSnapshot: (data) => {
    if (!data) return null
    return ModelTypeTo(data)[data.model]
  },
  isTargetType: (maybeData) => {
    return !!maybeData
  },
  getValidationMessage: (snapshot) => {
    if (snapshot === undefined) return ''
    return ''
  },
})
