import tw from 'tailwind-styled-components'

export const MessagesContainer = tw.div`
    w-full
    h-full
    md:w-8/12
    lg:w-6/12
    text-sm
    lg:text-base
`
export const conversationClasses = `
    w-full
    w-10/12
    h-[60vh]
    max-h-[60vh]
    grow
`
export const conversationBoxClasses = `
    h-[50vh]
    lg:h-full
`
