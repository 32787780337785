import { flow, getEnv, getRoot } from 'mobx-state-tree'

export const actionsAnalysis = (self) => ({
  getTestById: flow(function* getTestById() {
    self.setLoading('getTestById')
    try {
      const test = yield getRoot(self).GenericAnalysisTestStore.fetch(
        self.testId,
        {
          throwError: true,
        }
      )
      if (test) {
        self.test = test
      }
    } catch (error) {
      self.test = null
      self.setError()
    } finally {
      self.removeLoading('getTestById')
    }
  }),
  loadTestsList: flow(function* loadTestsList({ onError } = {}) {
    self.setLoading('loadTestsList')
    try {
      const testList = yield getRoot(self).GenericAnalysisTestStore.fetchAll({
        withoutId: true,
        customUrl: `/generic_analysis/${self.testId}/generic_analysis_tests/`,
      })

      if (testList) {
        self.testList = testList
      }
    } catch (error) {
      if (onError) onError()
      self.setError()
    } finally {
      self.removeLoading('loadTestsList')
    }
  }),
  updateTest: flow(function* updateTest(type, data) {
    if (!self.test) return
    try {
      self.test.setTestValues(null)
      self.test.setTestValues(data)
      yield getRoot(self).GenericAnalysisTestStore.save(self.test, {
        throwError: true,
      })
    } catch (error) {
      self.test = null
      self.setError()
    }
  }),
  generateTestResults: flow(function* generateTestResults({ onError } = {}) {
    self.setLoading('generateTestResults')
    try {
      const response = yield getRoot(self).GenericAnalysisResultsStore.create(
        { testResults: {} },
        {
          withoutId: true,
          customUrl: `/generic_analysis/${self.testId}/generate_results`,
          throwError: true,
        }
      )      
      if (response) {
        self.testResults = response
      }
    } catch (error) {
      if (onError) onError()
      self.setError()
    } finally {
      self.removeLoading('generateTestResults')
    }
      
      
  }),
  getTestResults: flow(function* getTestResults() {
    self.setLoading('getTestResults')
    try {
      const results = yield getRoot(self).GenericAnalysisResultsStore.fetch(
        self.testId,
        {
          throwError: true,
        }
      )
      if (results) {
        self.testResults = test
      }
    } catch (error) {
      self.testResults = null
      throw error
    } finally {
      self.removeLoading('getTestResults')
    }
  }),
  loadTest: flow(function* loadTest() {
    self.setLoading('loadTest')
    try {
      const test = yield getRoot(self).GenericAnalysisStore.fetch(self.testId, {
        include: 'person',
      })

      if (test) {
        self.test = test
      }
    } catch (error) {
      self.testResults = null
      throw error
    } finally {
      self.removeLoading('loadTest')
    }
  }),
  setMockedData: flow(function* loadTest() {
    self.setLoading('setMockedData')
    try {
      const response = yield getEnv(self).client.get(
        `/generic_analysis_test/${self.testId}/generic_analysis_tests_fields_mock`
      )
      if (!self.test || !response) return
      const prevData = JSON.parse(response.data.data.attributes.prevData)

      self.updateTest(null, prevData)
    } finally {
      self.removeLoading('setMockedData')
    }
  }),
  setError() {
    self.hasError = true
  },
  reload() {
    self.hasError = false
    self.loadDependencies()
  },
})
