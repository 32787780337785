export const TEST_MODALITY = Object.freeze({
  SUBSCRIPTION: 0,
  MANDATORY: 1,
  MONITORING: 2,
  PAYMENT: 3,
})

export const TEST_MODALITY_NAMES = Object.freeze({
  [TEST_MODALITY.SUBSCRIPTION]: 'common:testModality.suscription',
  [TEST_MODALITY.MANDATORY]: 'common:testModality.mandatory',
  [TEST_MODALITY.MONITORING]: 'common:testModality.monitoring',
  [TEST_MODALITY.PAYMENT]: 'common:testModality.payment',
})
