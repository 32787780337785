import { useEffect } from 'react'

/** useRestartAudioTrackOnDeviceChange
 * If a user has published an audio track from an external audio input device and
 * disconnects the device, the published audio track will be stopped and the user
 * will no longer be heard by other participants.
 *
 * To prevent this issue, this hook will re-acquire a mediaStreamTrack from the system's
 * default audio device when it detects that the published audio device has been disconnected.
 *
 * @param  {Object} localTracks  options for room and all participants
 */

export const useRestartAudioTrackOnDeviceChange = (localTracks) => {
  const audioTrack = localTracks.find((track) => track.kind === 'audio')

  useEffect(() => {
    const handleDeviceChange = async (e) => {
      if (audioTrack?.mediaStreamTrack.readyState === 'ended') {
        audioTrack.restart({})
      }
    }

    if (navigator?.mediaDevices?.addEventListener) {
      navigator.mediaDevices.addEventListener(
        'devicechange',
        handleDeviceChange,
      )
    }

    return () => {
      if (navigator?.mediaDevices?.removeEventListener) {
        navigator.mediaDevices.removeEventListener(
          'devicechange',
          handleDeviceChange,
        )
      }
    }
  }, [audioTrack, localTracks])
}
