import tw from 'tailwind-styled-components'

export const InitialReportContainer = tw.div`
    py-6
    mx-16
`

export const NextStepsButtonContainer = tw.div`
    flex
    justify-center
    gap-8
    p-8
`
