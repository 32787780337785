import tw from 'tailwind-styled-components'

export const ListContainer = tw.div`
    flex
    flex-col
    pb-20
    w-[80%]
`

export const InputContainer = tw.div`
    flex
    gap-16
    items-center
    justify-between
    mb-6
`

export const ButtonContainer = tw.div`
    flex
    justify-end
    gap-2
`
