import tw from 'tailwind-styled-components'

import { WarningAlt } from '@carbon/icons-react'
import { Images } from '../../assets/images'

export const BannerContainer = tw.div`
  py-5
  border
  border-solid
  rounded-lg
  flex
  gap-2
  mb-14
`

export const InfoBannerContainer = tw(BannerContainer)`
  border-primary
`

export const WarningBannerContainer = tw(BannerContainer)`
  border-yellow-400
`

export const ErrorBannerContainer = tw(BannerContainer)`
  inline-flex
  border-2
  border-vcx-error-red
`

export const BannerIconContainer = tw.div`
  px-6
`

export const warningIconClasses = `
  fill-yellow-400
`

export const infoIconClasses = `
  fill-primary
`
export const errorIconClasses = `
  fill-vcx-error-red
`

export const BannerText = tw.div`
  flex
  items-center
  px-6
  border-l
  border-solid
  border-vcx-light-gray
`

export const ErrorBannerText = tw(BannerText)`
  border-0
  pl-2
`

export const BANNER = {
  info: {
    container: InfoBannerContainer,
    icon: ({ className = '' }) => (
      <Images.ExclamationIconSvg className={`${className} w-12 h-12`} />
    ),
    iconClasses: infoIconClasses,
    textClasses: BannerText,
  },
  warning: {
    container: WarningBannerContainer,
    icon: WarningAlt,
    iconClasses: warningIconClasses,
    textClasses: BannerText,
  },
  error: {
    container: ErrorBannerContainer,
    icon: WarningAlt,
    iconClasses: errorIconClasses,
    textClasses: ErrorBannerText,
  },
}
