import { Text, View } from '@react-pdf/renderer'
import { areaColors, tw } from '../../utils'

export const Banner = ({ children, areaColor, title }) => {
  const bgColor = areaColor ? `bg-[${areaColors[areaColor]}]` : 'bg-primary'

  return (
    <View
      style={tw(
        `flex flex-col justify-center items-center text-white text-center font-bold w-full px-12 ${bgColor} mt-4 min-h-[60px]`,
      )}
      wrap={false}
    >
      {title ? <Text>{title}</Text> : children}
    </View>
  )
}
