import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import QidaLogo from '../../assets/global/qida_logo_footer.png'
import QidaLogoCat from '../../assets/global/Qida_LogoMail_Cat.png'
import { LANGUAGES, LANGUAGES_LOCALE_CODE, getDateHeadPDF } from '@shared/utils'

import { FC } from 'react'
import { useReportPdfContext } from '../../provider'
import { SummaryLevels } from '../summary-levels'

type LayoutPageProps = {
  children: JSX.Element | JSX.Element[]
  title?: string
  headerText?: string
  primaryTitle?: boolean
  noSpacing?: boolean
  section?: string
  sectionNumber?: string
}

export const LayoutPage: FC<LayoutPageProps> = ({
  children,
  title,
  headerText,
  primaryTitle = true,
  noSpacing,
  section,
  sectionNumber,
}) => {
  const { data, person, executiveSummary } = useReportPdfContext()
  const getLogoSource =
    person?.preferredLanguage === LANGUAGES.es ? QidaLogo : QidaLogoCat
  const logoStyles =
    person?.preferredLanguage === LANGUAGES.es
      ? { height: '18px' }
      : { height: '29px' }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      fontFamily: 'Montserrat',
    },
  })

  const { t } = useReportPdfContext()

  const isLangEs = person?.preferredLanguage === LANGUAGES.es
  const date = getDateHeadPDF(
    data?.results.modificationDate,
    isLangEs ? LANGUAGES_LOCALE_CODE.es : LANGUAGES_LOCALE_CODE.ca,
  )

  return (
    <Page
      size="A4"
      style={{
        ...styles.page,
        ...tw('h-full'),
      }}
    >
      <View fixed>
        <Text
          style={{
            ...tw('tracking-wider text-[#d3d3d3] py-6 px-12 text-xs'),
          }}
        >
          {headerText || t(`reportTitle`)}
        </Text>
      </View>
      <View>
        {title && (
          <View style={tw('flex flex-row justify-between items-center px-12')}>
            <Text
              style={tw(
                `font-semibold text-2xl leading-none ${
                  primaryTitle ? 'text-primary' : 'text-black'
                }`,
              )}
            >
              {title}
            </Text>
            <View style={tw('text-sm text-black')}>
              <Text style={tw('ml-auto')}>
                <Text>{date}</Text>
              </Text>
              <Text style={tw('font-bold')}>{person?.fullName}</Text>
            </View>
          </View>
        )}
        {title && <View style={tw('h-px bg-lightGrey my-6 mx-12')}></View>}
      </View>
      {section && (
        <View wrap={false} id={`section-${section}`}>
          <View style={tw('flex flex-row justify-between items-center')}>
            <View style={tw(`flex flex-row w-[50%] pl-12 py-8 bg-${section}`)}>
              <View
                style={tw(`flex flex-row items-center text-[20px] font-bold`)}
              >
                <Text style={tw('text-white')}>
                  {`${sectionNumber}. ${t(
                    `initial-report:sectionTitle.${section}`,
                  )}`}
                </Text>
              </View>
            </View>
            <View style={tw('w-[45%] text-sm pr-12')}>
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <SummaryLevels level={executiveSummary[section]?.score} />
              }
            </View>
          </View>
        </View>
      )}
      <View
        style={{ ...tw(`${noSpacing ? 'p-0' : 'px-12'}`), fontSize: '11px' }}
      >
        {children}
      </View>

      <View style={tw('mt-auto p-12')} fixed>
        <View style={tw('w-full bg-lightGrey h-px')}></View>
        <View style={tw('flex flex-row items-center justify-between mt-6')}>
          <Image
            style={{
              paddingLeft: '5px',
              width: '180px',
              ...logoStyles,
            }}
            src={getLogoSource}
          />
          <Text
            style={tw('text-sm')}
            render={({ pageNumber }) => pageNumber}
            fixed
          />
        </View>
      </View>
    </Page>
  )
}
