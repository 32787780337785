import {
  FC,
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react'

interface PortfolioContextType {
  clients: string[] | null
  setClients: Dispatch<SetStateAction<string[] | null>>
}

const PortfolioContext = createContext<PortfolioContextType | null>(null)

export const PortfolioProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [clients, setClients] = useState<string[] | null>(null)

  return (
    <PortfolioContext.Provider value={{ clients, setClients }}>
      {children}
    </PortfolioContext.Provider>
  )
}

export const usePortfolioContext = (): PortfolioContextType => {
  const context = useContext(PortfolioContext)
  if (!context) {
    throw new Error(
      'usePortfolioContext must be used within a PortfolioProvider',
    )
  }
  return context
}
