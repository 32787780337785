import { CALL_TYPE_OPTIONS } from '@shared/data-access'
import { Button, Form } from '@shared/ui'
import { SRSelect } from '@sr/ui'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ButtonCancel, ModalCall } from './style'

export const ModalCallType = ({ onClick, onCancel }) => {
  const methods = useForm()
  const { watch } = methods
  const callType = watch('callType')

  const { t } = useTranslation()

  const parseCallTypeOptions = CALL_TYPE_OPTIONS.map((type) => {
    return {
      ...type,
      name: t(type.name),
    }
  }, [])

  return (
    <ModalCall>
      <Form className="h-full flex flex-col justify-around" methods={methods}>
        <SRSelect
          name="callType"
          hasNoneOption
          options={parseCallTypeOptions}
        />
        <Button
          disabled={!callType || callType === 'none'}
          onClick={() => onClick(callType)}
        >
          Iniciar registro de llamada
        </Button>
        <ButtonCancel>
          <Button onClick={onCancel}>{t('common:cancel')}</Button>
        </ButtonCancel>
      </Form>
    </ModalCall>
  )
}
