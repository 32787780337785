import tw from 'tailwind-styled-components'

export const MiniMenuItemContainer = tw.div`
    flex
    items-center
    text-xs
    gap-2
    bg-white
    p-2
`
