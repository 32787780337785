import { SectionCognitive } from '../../report-pdf/sections'
import { SecondPage } from './components/second-page'

export const SectionFive = () => {
  return (
    <SectionCognitive sectionNumber="03">
      <SecondPage />
    </SectionCognitive>
  )
}
