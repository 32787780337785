export const DS_TYPOGRAPHY_HEADLINE_H1 = `
  font-aeonik
  font-normal
  text-headline-h1
`

export const DS_TYPOGRAPHY_HEADLINE_H2 = `
  font-aeonik
  font-normal
  text-headline-h2
`

export const DS_TYPOGRAPHY_TITLE_LG = `
  font-aeonik
  font-medium
  text-title-lg
`

export const DS_TYPOGRAPHY_TITLE_MD = `
  font-aeonik
  font-medium
  text-xl
  leading-7
`

export const DS_TYPOGRAPHY_TITLE_SM = `
  font-aeonik
  font-medium
  text-lg
  leading-6
`

export const DS_TYPOGRAPHY_BODY_XL = `
  font-aeonik
  font-normal
  text-xl
  leading-6
`

export const DS_TYPOGRAPHY_BODY_LG = `
  font-aeonik
  font-normal
  text-lg
  leading-6
`

export const DS_TYPOGRAPHY_BODY_MD_REGULAR = `
  font-aeonik
  font-normal
  text-base
  leading-6
`

export const DS_TYPOGRAPHY_BODY_MD_MEDIUM = `
  font-aeonik
  font-medium
  text-base
  leading-6
`

export const DS_TYPOGRAPHY_BODY_SM_REGULAR = `
  font-aeonik
  font-normal
  text-sm
  leading-5
`

export const DS_TYPOGRAPHY_BODY_SM_MEDIUM = `
  font-aeonik
  font-medium
  text-sm
  leading-5
`
