import { SRCard, SRCardList, SR_CARD_SIZE, SR_CARD_VARIANTS } from '@sr/ui'
import { DashboardCardsContainer, srCardContainerClasses } from '../styles'
import { User, Badge, CalendarAdd } from '@carbon/icons-react'
import { useTranslation } from 'react-i18next'
import {
  ROUTE_PATH,
  ROUTE_PATH_CAMPAIGN,
} from 'apps/social-referent/src/routes/enums'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useRouteState } from '@shared/utils'
import { ModalAgenda, useLayoutContext } from '@sr/routes/common'
import { observer } from 'mobx-react-lite'
import { DashboardSRRouteState } from './state'

export const SRDashboard = observer(({ user, annuityList }) => {
  const TRANSLATION_BASE_KEY = 'dashboard'
  const { t } = useTranslation()

  const [isVisible, setIsVisible] = useState(false)
  const [eventSelected, setEventSelected] = useState(false)
  const navigate = useNavigate()

  const openModal = () => setIsVisible(true)
  const closeModal = () => setIsVisible(false)

  const onSelectEvent = (event) => {
    setEventSelected(event)
    openModal()
  }

  const onSelectUser = (id) => {
    navigate(ROUTE_PATH.userArea(id))
  }

  const state = useRouteState(DashboardSRRouteState, { user })
  state.setOnSelectCallback({ onSelectEvent, onSelectUser })

  const { todayAppointments, isLoading } = state

  const { setIsLoading } = useLayoutContext()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  return (
    <>
      <SRCardList
        title={t(`${TRANSLATION_BASE_KEY}.schedule.title`)}
        list={todayAppointments}
      />

      <SRCardList
        title={t(`${TRANSLATION_BASE_KEY}.campaignUsers.title`)}
        defaultIcon={<Badge size={20} />}
        list={annuityList}
      />

      <DashboardCardsContainer>
        <SRCard
          containerClasses={srCardContainerClasses}
          icon={<CalendarAdd width={60} height={60} />}
          title={t(`${TRANSLATION_BASE_KEY}.agenda.title`)}
          linkText={t(`${TRANSLATION_BASE_KEY}.agenda.link`)}
          path={ROUTE_PATH.agenda(user.id)}
          variant={SR_CARD_VARIANTS.ghost}
          size={SR_CARD_SIZE.small}
        />
        <SRCard
          containerClasses={srCardContainerClasses}
          icon={<User width={60} height={60} />}
          title={t(`${TRANSLATION_BASE_KEY}.userListVcx.title`)}
          linkText={t(`${TRANSLATION_BASE_KEY}.showList`)}
          path={ROUTE_PATH.userList}
          variant={SR_CARD_VARIANTS.ghost}
          size={SR_CARD_SIZE.small}
        />
        <SRCard
          containerClasses={srCardContainerClasses}
          icon={<User width={60} height={60} />}
          title={t(`${TRANSLATION_BASE_KEY}.userListCampaign.title`)}
          linkText={t(`${TRANSLATION_BASE_KEY}.showList`)}
          path={ROUTE_PATH_CAMPAIGN.userList}
          variant={SR_CARD_VARIANTS.ghost}
          size={SR_CARD_SIZE.small}
        />
      </DashboardCardsContainer>
      {isVisible && (
        <ModalAgenda
          isReadOnly={true}
          onClose={closeModal}
          event={eventSelected}
          isCoordinator={user?.isCoordinator}
        />
      )}
    </>
  )
})
