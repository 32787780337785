export const FILTER_OPTIONS_TYPE = Object.freeze({
  range: 'range',
  isnull: 'isnull',
  lte: 'lte',
})

export const FILTER_SUFIX = {
  [FILTER_OPTIONS_TYPE.range]: '__range',
  [FILTER_OPTIONS_TYPE.isnull]: '__isnull',
  [FILTER_OPTIONS_TYPE.lte]: '__lte',
}
