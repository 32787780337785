import { sortByKey } from '@shared/utils'

export const views = (self) => ({
  get todayAppointments() {
    if (!self.fullAppointmentList) return []
    return sortByKey({
      array: self.fullAppointmentList,
      key: 'startDate',
    }).map(self.getAppointmentListStyled)
  },
  get fullAppointmentList() {
    return [
      ...(self.appointments ? self.appointments : []),
      ...(self.unavailableSlots
        ? self.formatUnavailableSlots(self.unavailableSlots)
        : []),
    ]
  },
})
