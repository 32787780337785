import { getRoot, types } from 'mobx-state-tree'
import { BaseConsent } from '../base-consent'
import { PersonModel } from '../person'

export const MODEL_NAME = 'PersonConsent'

export const Model = BaseConsent.named(MODEL_NAME)
  .props({
    person: types.maybeNull(types.reference(PersonModel)),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
