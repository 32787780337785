import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  AppointmentBlock,
  ChangeOpBlock,
  FormBlock,
  ReminderBlock,
  PoliceBlock,
  UploadFileBlock,
  StatusPoliceBlock,
} from '../../blocks'

import { useRouteState } from '@shared/utils'
import { ProfileCareActionsRouteState } from './state'

import { useLayoutContext } from '@sr/routes/common/layout-provider'
import { ModalType, useAnalysisModal } from '@sr/routes/common/analysis'
import { POLICEBLOCK, ROUTE_PATH } from 'apps/social-referent/src/routes/enums'

import { Form } from '@shared/ui'
import { IPreferences } from './enums'

type CareActionsProps = {
  reload: () => void
  isEditing?: boolean
  preferences?: IPreferences
}

export const CareActions: FC<CareActionsProps> = observer(
  ({ reload, isEditing, preferences }) => {
    const { t } = useTranslation()
    const { id: policyId } = useParams()
    const navigate = useNavigate()
    const {
      isLoading,
      policy,
      user,
      sendSignUpCommunication,
      uploadVideo,
      appointment,
      updateSubState,
      canChangeOp,
      availableSocialReferentListParsed,
      loadAvailableSrForChangeCare,
      modalType,
      setModal,
      changeOpCare,
      showSendEmailButton,
      canUploadFile,
    } = useRouteState(ProfileCareActionsRouteState, {
      policyId,
    })

    const { setIsLoading } = useLayoutContext()
    const sendEmailTranslation = policy?.canResendEmail ? 'resend' : 'send'

    const showAppointment = !user?.isCoordinator && appointment

    const onRejection = (form: { 'policy-options': string }) => {
      const subState = form['policy-options']
      updateSubState(subState)
    }

    const onSchedule = () => {
      navigate(`${ROUTE_PATH.userArea(policyId)}/${ROUTE_PATH.schedule}`)
    }

    const goToVideoCall = () => {
      window.location.replace(appointment.meetingUrl)
    }

    const methodsChangeOp = useForm()
    const { handleSubmit: handleSubmitChangeOp } = methodsChangeOp

    const onChangeOp = handleSubmitChangeOp(async (form) => {
      await changeOpCare(form)
      reload()
      await loadAvailableSrForChangeCare()
      setModal(ModalType.success)
    })

    const openModal = () => {
      setModal(ModalType.confirmation)
    }
    const onClose = () => {
      setModal(null)
    }

    const Modal = useAnalysisModal({
      modalType,
      reload,
      onChangeOp,
      onClose,
      fullName: policy?.fullName,
      onConfirmText: 'common:save',
      confirmModalTextMain:
        'campaign.userArea.profile.changeOpModalConfirmationMain',
      confirmModalTextSecond:
        'campaign.userArea.profile.changeOpModalConfirmationSecond',
      successModalTextMain:
        'campaign.userArea.profile.changeOpModalSuccessMain',
      successModalTextSecond: '',
      noRoute: true,
    })

    useEffect(() => {
      setIsLoading(isLoading)
    }, [isLoading])

    return (
      <>
        <StatusPoliceBlock idProces={policy?.state} />

        {policy?.state === POLICEBLOCK.active && (
          <PoliceBlock t={t} action={onRejection} subState={policy?.subState} />
        )}
        <hr className="bg-gray-200 w-full h-px mb-6" />
        {showAppointment && (
          <>
            <AppointmentBlock
              action={goToVideoCall}
              actionText={t(
                'userArea.profile.actions.appointment.videocallButton',
              )}
              showAppointment
              appointment={appointment}
            />
            <hr className="bg-gray-200 w-full h-px mb-6" />
          </>
        )}
        {showSendEmailButton && (
          <>
            <div className="text-primary text-xl font-semibold mb-9">
              {t('userArea.profile.welcomeEmail.title')}
            </div>
            <ReminderBlock
              sendEmail={sendSignUpCommunication}
              sendEmailTranslation={sendEmailTranslation}
            />
            <hr className="bg-gray-200 w-full h-px mb-6" />
          </>
        )}
        {canUploadFile && <UploadFileBlock onDrop={uploadVideo} />}
        {canChangeOp && (
          <Form methods={methodsChangeOp}>
            <ChangeOpBlock
              srList={availableSocialReferentListParsed}
              action={openModal}
            />
          </Form>
        )}
        <div className="mb-6">
          <h2 className="mb-6 font-semibold">
            {t('userArea.profile.preferences.communicationTitle')}
          </h2>
          <FormBlock
            section="prefered-communication-channel"
            data={preferences}
          />
        </div>

        <div className="mb-6">
          <h2 className="mb-6 font-semibold">
            {t('userArea.profile.preferences.additionalServicesTitle')}
          </h2>
          <FormBlock
            section="additional-services-interest"
            data={preferences}
          />
        </div>

        <div className="mb-6">
          <h2 className="mb-6 font-semibold">
            {t('userArea.profile.preferences.additionalCategoriesTitle')}
          </h2>
          <FormBlock
            section="additional-services-interest-II"
            data={preferences}
          />
        </div>

        <div className="mb-6">
          <h2 className="mb-6 font-semibold">Notas</h2>
          <FormBlock section="notes" data={preferences} />
        </div>
        {modalType && Modal}
      </>
    )
  },
)
