import { Image, Text, View } from '@react-pdf/renderer'
import { TextTitle } from '../../../components'
import { tw } from '../../../utils'
import { LANGUAGES, LANGUAGES_LOCALE_CODE, getDateHeadPDF } from '@shared/utils'
import QidaLogo from '../../../assets/qida_logo.png'
import { HeroComponent } from './components/heroComponents'
import { useReportPdfContext } from '../../../report-pdf-provider'
import { TranscriptionScriptPDF } from '../../../components/TranscriptionScriptPDF'
import { ReportType } from '../../../../report-pdf/enums'

export const FirstPage = () => {
  const { data, t, person, type, isCare } = useReportPdfContext()
  const isLangEs = person?.preferredLanguage === LANGUAGES.es
  const date = getDateHeadPDF(
    data?.results.modificationDate,
    isLangEs ? LANGUAGES_LOCALE_CODE.es : LANGUAGES_LOCALE_CODE.ca,
    true,
  )

  return (
    <View style={tw('h-full text-base')}>
      <View style={tw('px-12')}>
        <TextTitle style={tw('font-semibold')}>
          {t('reportTitleBreak')} 
        </TextTitle>
        {ReportType[type] === ReportType.care && (
          <TextTitle>{t('initial-report:sectionOne.personalized')}</TextTitle>
        )}
      </View>
      <HeroComponent isCare={isCare} />
      <View style={tw('px-12 flex flex-row justify-between')}>
        <View>
          <Text style={tw('tracking-wider font-semibold text-secondary')}>
            {t('productName')}
          </Text>
          <Text style={tw('font-semibold')}>{person.fullName}</Text>
        </View>
        <View>
          {ReportType[type] === ReportType.care && (
            <Text style={tw('ml-auto')}>
              {t('initial-report:sectionOne.contractNumber', {
                contractNumber: person.contractNumber,
              })}
            </Text>
          )}
          <Text style={tw('ml-auto')}>{date}</Text>
        </View>
      </View>
      <View style={tw('mt-auto p-12')}>
        <View style={tw('w-full h-px bg-lightGrey')}></View>
        <View style={tw('flex flex-row items-center justify-between mt-4')}>
          <View>
            <TranscriptionScriptPDF
              style={{ fontSize: '11px' }}
              scriptProps={{ opName: person.socialReferent.fullName }}
              script="initial-report:sectionOne.doItFor"
            />
            <TranscriptionScriptPDF
              style={{ ...tw('italic'), fontSize: '11px' }}
              scriptProps={{
                academicTitle: person.socialReferent.academicTitle,
              }}
              script="op.academicTitle"
            />
          </View>
          <Image
            style={{
              ...tw('self-end mb-1 w-14'),
              height: '18px',
            }}
            src={QidaLogo}
          />
        </View>
      </View>
    </View>
  )
}
