import tw from 'tailwind-styled-components'

export const NotificationsChatContainer = tw.div`
    flex
    flex-col
    items-center
    py-4
    w-[529px]
    m-auto
`
