import { flow, getRoot, types } from 'mobx-state-tree'
import { SocialReferent } from '@shared/data-access'
import { actions } from '@sr/routes/common'
import { BaseTableState } from '@sr/routes/common/store/table-state/table-state'
import { CollisionType } from '@sr/data-access'
import { MODAL_TYPE } from '../../common/components/op-generic-list/enums'

export const PortfolioManagementOPListCampaignRouteState = BaseTableState.named(
  'PortfolioManagementOPListCampaignRouteState',
)
  .props({
    modalType: types.maybeNull(types.string),
    socialReferentId: types.maybeNull(types.string),
    socialReferent: types.maybeNull(
      types.array(types.reference(SocialReferent)),
    ),
    conflicts: types.maybeNull(types.array(types.string)),
  })
  .views((self) => ({
    get socialReferentList() {
      if (!self.socialReferent) return []
      return self.socialReferent.filter(
        ({ id }) => id !== self.socialReferentId,
      )
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('PortfolioManagementOPListCampaignRouteState')
      try {
        const [property] = self.sort || []

        self.socialReferent =
          yield self.loadPaginatedAndFilteredSocialReferents({
            isClear: true,
            sort: property && `${property.desc ? '-' : ''}${property.id}`,
            filters: self.filters,
          })
      } finally {
        self.removeLoading('PortfolioManagementOPListCampaignRouteState')
      }
    }),
    assignPortfolio: flow(function* assignPortfolio({ list, socialReferent, reason = 2 }) {
      self.setLoading('assignPortfolio')
      try {
        yield getRoot(self).SocialReferentChangesStore.post(
          { annuitiesList: list, socialReferent, reason },
          {
            customUrl: '/social_referent_changes/campaigns',
            throwError: true,
            avoidUpdate: true,
          },
        )
        self.setModal(MODAL_TYPE.success)
      } catch (error) {
        const isConflict = error?.response?.status === 409
        if (isConflict) {
          const conflicts = error?.response?.data?.errors?.filter(
            ({ collisionType }) =>
              collisionType === CollisionType.incidentWithSr,
          )
          const conflictsId = conflicts?.map(({ originId }) => originId)
          self.conflicts = conflictsId
          self.setModal(MODAL_TYPE.conflict)
        } else {
          throw error
        }
      } finally {
        self.removeLoading('assignPortfolio')
      }
    }),
    setModal(modalType) {
      self.modalType = modalType
    },
    ...actions(self),
  }))
