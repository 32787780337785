import tw from 'tailwind-styled-components'

type ConfirmModalContentContainerProps = {
  $fontColor?: string
}

export const ConfirmModalContentContainer = tw.div<ConfirmModalContentContainerProps>`
    flex
    flex-col
    gap-8
    mb-6
    ${({ $fontColor }) => ($fontColor ? $fontColor : '')}
`

export const RadioModalContentContainer = tw.div`
    flex
    flex-col
    gap-4
    items-start
`

export const inputsModalContainer = `
    flex
    flex-col
    gap-4
    items-start
`

export const inputsModal = `
    bg-white
    border
    border-solid
    border-gray
    w-72
    py-2.5
    px-3
    rounded
    text-zinc-500
    disabled:opacity-75
    disabled:cursor-not-allowed
`
export const speechBubbleContainer = `
    flex
    gap-1
    items-center
    ml-4
    text-base
    relative
`
export const errorStyle = 'flex text-[#B3261E] text-sm'

export const errorBorders = 'border-[#B3261E] text-[#B3261E]'
