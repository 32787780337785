import { flow, getRoot, types } from 'mobx-state-tree'
import { actions } from '@sr/routes/common'
import {
  PERSON_SERVICE_STATES,
  Policy,
  PersonService,
  BaseRouteState,
} from '@shared/data-access'

export const ServiceDetailRouteState = BaseRouteState.named(
  'ServiceDetailRouteState'
)
  .props({
    policyId: types.maybeNull(types.string),
    personServiceId: types.maybeNull(types.string),
    policy: types.maybeNull(types.reference(Policy)),
    personService: types.maybeNull(types.reference(PersonService)),
  })
  .views((self) => ({
    get personServiceAccepted() {
      return (
        self.personService?.personServiceState ===
        PERSON_SERVICE_STATES.ACCEPTED
      )
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadPersonService()
      self.loadPolicy()
    },
    loadPersonService: flow(function* loadPersonService() {
      self.setLoading('ServiceDetailRouteState')
      try {
        const personService = yield getRoot(self).PersonServiceStore.get(
          self.personServiceId,
          {
            withoutId: true,
            include: 'service',
            customUrl: `policies/${self.policyId}/services/${self.personServiceId}`,
          }
        )

        if (personService) {
          self.personService = personService
        }
      } finally {
        self.removeLoading('ServiceDetailRouteState')
      }
    }),
    acceptPersonService: flow(function* acceptPersonService() {
      self.setLoading('acceptPersonService')
      try {
        const personService = yield getRoot(self).PersonServiceStore.post(
          { personServiceId: self.personServiceId },
          {
            customUrl: `policies/${self.policyId}/services/${self.personServiceId}/accept_policy_service`,
          }
        )

        if (personService) {
          self.personService = personService
          self.loadPersonService()
        }
      } finally {
        self.removeLoading('acceptPersonService')
      }
    }),
    ...actions(self),
  }))
