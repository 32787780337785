export const FEATURE_FLAG = Object.freeze({
  control: 'control',
  initial: 'initial',
  generic: 'generic',
})

export const FEATURE_FLAGS = [
  FEATURE_FLAG.control,
  FEATURE_FLAG.initial,
  FEATURE_FLAG.generic,
]
