import { flow, types } from 'mobx-state-tree'
import { actionsCampaign } from '@sr/routes/common'
import { AnnuityModel } from '@sr/data-access'
import { BaseTableState } from '@sr/routes/common/store/table-state/table-state'

export const PortfolioManagementCampaignListRouteState = BaseTableState.named(
  'PortfolioManagementCampaignListRouteState',
)
  .props({
    socialReferentId: types.maybeNull(types.string),
    annuities: types.maybeNull(types.array(types.reference(AnnuityModel))),
  })
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('PortfolioManagementCampaignListRouteState')
      try {
        const [property] = self.sort || []

        self.annuities = yield self.loadPaginatedAndFilteredAnnuities({
          isClear: true,
          sort: property && `${property.desc ? '-' : ''}${property.id}`,
          filters: {
            ...self.filters,
            socialReferent: self.socialReferentId,
          },
        })
      } finally {
        self.removeLoading('PortfolioManagementCampaignListRouteState')
      }
    }),
    ...actionsCampaign(self),
  }))
