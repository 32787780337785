import { APPOINTMENT_TYPES } from '@shared/data-access'

interface AppointmentDataToModify {
  appointmentType: number
  startDate: string | Date
}

interface Appointment {
  dataToModify: AppointmentDataToModify
}

export const hasFutureInitialReport = (
  appointmentsFiltered: Appointment[],
  isCare?: boolean,
): boolean => {
  if (!appointmentsFiltered || !appointmentsFiltered.length) {
    return false
  }
  return appointmentsFiltered.some((appointment) => {
    const { appointmentType, startDate } = appointment.dataToModify
    const now = new Date()
    const start = new Date(startDate)

    const typeToCheck = isCare
      ? APPOINTMENT_TYPES.INITIAL_REPORT
      : APPOINTMENT_TYPES.GENERIC_ANALYSIS

    return appointmentType === typeToCheck && start > now
  })
}
