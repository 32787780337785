type ClockScoreProps = {
  score: number
}

export enum ClockScoreResult {
  none,
  possible,
}

export const ClockScoreName = Object.freeze({
  [ClockScoreResult.none]: 'none',
  [ClockScoreResult.possible]: 'possible',
})

export class ClockScore {
  score: number
  result: number
  none: number
  possible: number
  scoreName: string
  scale: string

  constructor({ score }: ClockScoreProps) {
    this.score = score
    this.result = ClockScoreResult.none
    this.none = 10
    this.possible = 6
    this.scoreName = ''
    this.scale = `${score}/10`
    this.init()
  }

  private init() {
    this.setResult()
    this.setScoreName()
  }

  private setResult() {
    if (typeof this.score === 'string') {
      return
    }
    if (this.score <= this.possible) {
      this.result = ClockScoreResult.possible
    }
    if (this.score > this.possible && this.score <= this.none) {
      this.result = ClockScoreResult.none
    }
  }

  private setScoreName() {
    this.scoreName = ClockScoreName[this.result as keyof typeof ClockScoreName]
  }
}
