import { getDateFormatted } from '@shared/utils'
import {
  SRBigCalendarEventWeekBlockStyle,
  SRBigCalendarEventWeekStyle,
} from './styles'

export const SRBigCalendarEventWeek = ({ event = {} }) => {
  const { start, end } = event

  const startDate = getDateFormatted(start, 'HH:mm A')
  const endDate = getDateFormatted(end, 'HH:mm A')

  if (!start || !end) return

  return (
    <SRBigCalendarEventWeekStyle>
      <SRBigCalendarEventWeekBlockStyle>
        <div className="text-center">{startDate}</div>
        <div className="text-center">-</div>
        <div className="text-center">{endDate}</div>
      </SRBigCalendarEventWeekBlockStyle>
    </SRBigCalendarEventWeekStyle>
  )
}
