import { FC, ChangeEvent, useState } from 'react'

type SRCheckboxProps = {
  name?: string
  label?: string
  className?: string
  checked?: boolean
  onChange?: (value: string | number | boolean) => void
}

export const SRCheckbox: FC<SRCheckboxProps> = ({
  name,
  label = '',
  className = '',
  checked,
  onChange
}) => {
  const [isChecked, setIsChecked] = useState(checked)

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.checked)
    }
    setIsChecked(e.target.checked)
  }

  return (
    <div className={`flex items-center gap-2 py-1 ${className}`}>
      <label className="inline-flex items-center space-x-2 cursor-pointer">
        <input
          type="checkbox"
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          checked={isChecked}
          onChange={handleOnChange}
        />
        <span className="text-gray-700">{label}</span>
      </label>

    </div>
  )
}
