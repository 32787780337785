import { GLOBAL_ENVIRONMENT } from '../enums'
import { FEATURE_FLAGS } from './enums'

export class FeatureFlags {
  constructor(flags = []) {
    this.flags = flags
  }

  isValid(flag) {
    return (
      this.flags.some((fl) => fl === flag) &&
      process.env.NX_ENV !== GLOBAL_ENVIRONMENT.prod &&
      process.env.NX_ENV !== GLOBAL_ENVIRONMENT.stage
    )
  }

  getFeatureFlag(flag) {
    const value = global.localStorage.getItem(flag)
    return String(value).toLowerCase() === 'true'
  }

  hasFeatureFlag(flag) {
    return this.isValid(flag) && this.getFeatureFlag(flag)
  }
}

const featureFlags = new FeatureFlags(FEATURE_FLAGS)

export { featureFlags }
