import { Button } from '@shared/ui'
import { SRModal } from '@sr/ui'
import { useTranslation } from 'react-i18next'
import { ConfirmModalContainer } from './styles'

export const ConfirmRegenerateRoom = ({
  onClose,
  onSubmit,
  endedCall,
  error,
}) => {
  const { t } = useTranslation()

  return (
    <SRModal onClose={onClose}>
      <div className={ConfirmModalContainer}>
        <div>{t('agenda.formModal.confirmModal.areYouSure')}</div>
        <Button className="mx-auto" onClick={onSubmit}>
          {t('agenda.formModal.confirmModal.confirm')}
        </Button>

        {endedCall && (
          <div className={error ? 'text-error' : 'text-black'}>
            {error
              ? t('agenda.formModal.confirmModal.error')
              : t('agenda.formModal.confirmModal.success')}
          </div>
        )}
      </div>
    </SRModal>
  )
}
