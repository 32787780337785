import { UserAvatar } from '@carbon/icons-react'
import {
  UserListItemContainer,
  UserListItemName,
  UserListItemState,
} from './styles'
import { FC } from 'react'

interface UserListItemProps {
  onClick: (id: string) => void
  name: string
  state: string
  stateColor: string
  id: string
}

export const UserListItem: FC<UserListItemProps> = ({
  onClick,
  name,
  state,
  stateColor,
  id,
}) => {
  return (
    <UserListItemContainer
      onClick={() => onClick(id)}
      data-testid="user-list-item"
    >
      <UserListItemName>
        <UserAvatar size={24} />
        {name}
      </UserListItemName>
      <UserListItemState className={stateColor}>
        {state && state}
      </UserListItemState>
    </UserListItemContainer>
  )
}
