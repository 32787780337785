import { useRouteState } from '@shared/utils'
import { ServiceRouteState } from './state'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { Divider, RouterLink } from '@shared/ui'
import { useTranslation } from 'react-i18next'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { ServicesLink, ServicesWrapper } from './styles'

export const Services = observer(() => {
  const { t } = useTranslation()

  const { id } = useParams()

  const { personServices } = useRouteState(ServiceRouteState, {
    policyId: id,
  })

  return personServices?.length ? (
    <div className={ServicesWrapper}>
      {personServices.map((personService, index) => (
        <div key={index}>
          <div className={ServicesLink} key={personService.id}>
            <div>{t(`${personServices[0]?.service.serviceName}.title`)}</div>
            <RouterLink
              className="text-secondary"
              to={`${ROUTE_PATH.userArea(id)}/service-detail/${
                personService.id
              }`}
            >
              {t('userArea.monitoringArea.services.seeProcess')}
            </RouterLink>
          </div>
          <Divider className="mt-2" />
        </div>
      ))}
    </div>
  ) : (
    <div>{t('userArea.monitoringArea.services.noItems')}</div>
  )
})
