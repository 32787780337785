import { UserAvatar } from '@carbon/icons-react'
import { UserNameContainerClasses } from './styles'
import { FC } from 'react'

interface SRUserNameProps {
  fullName: string
}

export const SRUserName: FC<SRUserNameProps> = ({ fullName }) => {
  return (
    <div className={UserNameContainerClasses}>
      <UserAvatar width={36} height={36} />
      {fullName}
    </div>
  )
}
