import { Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { SummaryLevel } from '../../provider/types'
import { useReportPdfContext } from '../../provider'
import { FC } from 'react'

type SummaryLevelsProps = {
  level: SummaryLevel
}

export const SummaryLevels: FC<SummaryLevelsProps> = ({ level }) => {
  const { t } = useReportPdfContext()

  return (
    <View style={tw('flex flex-row items-center')}>
      <View
        style={tw(
          `flex flex-row items-center p-2 border-r w-1/3 justify-center ${
            level === SummaryLevel.attention
              ? 'bg-darkRed text-white font-bold border-darkRed'
              : 'text-grey border-lightGrey'
          }`,
        )}
      >
        <Text>{t('initial-report:summaryLevels.attention')}</Text>
      </View>
      <View
        style={tw(
          `flex flex-row items-center p-2 border-r w-1/3 justify-center ${
            level === SummaryLevel.improvement
              ? 'bg-orange text-white font-bold border-orange'
              : 'text-grey border-lightGrey'
          }`,
        )}
      >
        <Text>{t('initial-report:summaryLevels.improvement')}</Text>
      </View>
      <View
        style={tw(
          `flex flex-row items-center p-2 w-1/3 justify-center ${
            level === SummaryLevel.optimal
              ? 'bg-green text-white font-bold'
              : 'text-grey'
          }`,
        )}
      >
        <Text>{t('initial-report:summaryLevels.optimal')}</Text>
      </View>
    </View>
  )
}
