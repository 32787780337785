import { StyleSheet } from '@react-pdf/renderer'

export const executiveSummaryClasses = [
  StyleSheet.create({
    '*': {
      fontSize: '11px',
      fontFamily: 'Montserrat',
      lineHeight: '1.5px',
      marginTop: '0px',
    },
  }),
]
