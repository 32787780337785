import { useRouteState } from '@shared/utils'
import { SRCard, SRCardList, SR_CARD_SIZE, SR_CARD_VARIANTS } from '@sr/ui'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { User, Badge, Document } from '@carbon/icons-react'
import { useEffect } from 'react'
import { useLayoutContext } from '@sr/routes/common'
import {
  ROUTE_PATH,
  ROUTE_PATH_CAMPAIGN,
} from 'apps/social-referent/src/routes/enums'
import { useNavigate } from 'react-router-dom'
import { DashboardCardsContainer, srCardContainerClasses } from '../styles'
import { CalendarAdd } from '@carbon/icons-react'
import { DashboardCoordinatorRouteState } from './state'

export const CoordinatorDashboard = observer(({ id, annuityList }) => {
  const TRANSLATION_BASE_KEY = 'dashboard'
  const { t } = useTranslation()
  const navigate = useNavigate()

  const state = useRouteState(DashboardCoordinatorRouteState)
  const { myBoxCarePolicyList, isLoading } = state

  const onSelectUser = (id) => {
    navigate(ROUTE_PATH.userArea(id))
  }

  state.setOnSelectCallback({ onSelectUser })

  const { setIsLoading } = useLayoutContext()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  return (
    <>
      <SRCardList
        title={t(`${TRANSLATION_BASE_KEY}.vidaCareClients.title`)}
        list={myBoxCarePolicyList}
        defaultIcon={<User size={20} />}
      />
      <SRCardList
        title={t(`${TRANSLATION_BASE_KEY}.campaignUsers.title`)}
        list={annuityList}
        defaultIcon={<Badge size={20} />}
      />

      <DashboardCardsContainer>
        <SRCard
          containerClasses={srCardContainerClasses}
          icon={<CalendarAdd width={60} height={60} />}
          title={t(`${TRANSLATION_BASE_KEY}.agenda.title`)}
          linkText={t(`${TRANSLATION_BASE_KEY}.agenda.link`)}
          path={ROUTE_PATH.agenda(id)}
          variant={SR_CARD_VARIANTS.ghost}
          size={SR_CARD_SIZE.small}
        />
        <SRCard
          containerClasses={srCardContainerClasses}
          icon={<User width={60} height={60} />}
          title={t(`${TRANSLATION_BASE_KEY}.userListVcx.title`)}
          linkText={t(`${TRANSLATION_BASE_KEY}.showList`)}
          path={ROUTE_PATH.userList}
          variant={SR_CARD_VARIANTS.ghost}
          size={SR_CARD_SIZE.small}
        />
        <SRCard
          containerClasses={srCardContainerClasses}
          icon={<User width={60} height={60} />}
          title={t(`${TRANSLATION_BASE_KEY}.userListCampaign.title`)}
          linkText={t(`${TRANSLATION_BASE_KEY}.showList`)}
          path={ROUTE_PATH_CAMPAIGN.userList}
          variant={SR_CARD_VARIANTS.ghost}
          size={SR_CARD_SIZE.small}
        />
        <SRCard
          containerClasses={srCardContainerClasses}
          icon={<Document width={60} height={60} />}
          title={t(`${TRANSLATION_BASE_KEY}.srList.title`)}
          linkText={t(`${TRANSLATION_BASE_KEY}.showList`)}
          path={ROUTE_PATH.srList}
          variant={SR_CARD_VARIANTS.ghost}
          size={SR_CARD_SIZE.small}
        />
      </DashboardCardsContainer>
    </>
  )
})
