import { useTranslation } from 'react-i18next'
import { AbsencesForm } from './absences-form'
import { useModalContext } from '../../provider/modal-provider'
import { useParams } from 'react-router-dom'
import { Form } from '@shared/ui'
import { useSchemaUnavailableSlot } from './schema'
import {
  parseCustomDate,
  getDayWithExactHour,
  useRouteState,
} from '@shared/utils'
import { AbsencesAppointmentState } from './state'
import { EVENT_FORM, useModalForm } from '../../common'
import { useEffect } from 'react'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import { SRModal } from '@sr/ui'

export const Absences = ({
  isReadOnly,
  isEdit,
  actionAfterSubmit,
  onClose,
}) => {
  const { t } = useTranslation()
  const { id: socialReferentId } = useParams()
  const {
    eventId,
    appointmentType,
    appointmentDay,
    unavailableSlotType,
    startTime,
    endTime,
  } = useModalContext()
  const { setIsLoading } = useLayoutContext()

  const { isLoading, saveAbsence, createAbsence, deleteAbsence } =
    useRouteState(AbsencesAppointmentState)

  const defaultValues = {
    [EVENT_FORM.appointmentType]: appointmentType,
    [EVENT_FORM.appointmentDay]: appointmentDay,
    [EVENT_FORM.unavailableSlotType]: unavailableSlotType,
    [EVENT_FORM.startDate]: startTime,
    [EVENT_FORM.endDate]: endTime,
  }
  const schema = useSchemaUnavailableSlot(t)
  const { methods } = useModalForm({ schema, defaultValues })

  const { handleSubmit } = methods

  const onSubmit = handleSubmit((form) => {
    const { appointmentType, unavailableSlotType, allDay } = form
    const { startDate, endDate } = form
    let newStartDate = parseCustomDate(appointmentDay, startDate)
    let newEndDate = parseCustomDate(appointmentDay, endDate)

    if (allDay) {
      newStartDate = getDayWithExactHour(appointmentDay, { hour: 9, minute: 0 })
      newEndDate = getDayWithExactHour(appointmentDay, {
        hour: 18,
        minute: 0,
      })
    }

    const eventForm = {
      startDate: newStartDate,
      endDate: newEndDate,
      id: eventId || '',
      appointmentType,
      socialReferentId,
      unavailableSlotType,
    }

    const action = isEdit ? saveAbsence : createAbsence
    action(eventForm).then(() => {
      actionAfterSubmit()
    })
  })

  const onDelete = () => {
    deleteAbsence(eventId).then(() => {
      actionAfterSubmit()
    })
  }

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  if (isLoading) return

  return (
    <SRModal onClose={onClose}>
      <Form methods={methods}>
        <AbsencesForm
          onSubmit={onSubmit}
          isReadOnly={isReadOnly}
          isEdit={isEdit}
          onDelete={onDelete}
        />
      </Form>
    </SRModal>
  )
}
