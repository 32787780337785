import { View } from '@react-pdf/renderer'
import { FC } from 'react'
import { getRecommendations, tw } from '../../../utils'
import {
  ListItem,
  SubSection,
  TranscriptionScriptPDF,
} from '../../../components'
import { useReportPdfContext } from '../../../provider'

type NutritionalGuidelinesProps = {
  sectionLetter: string
}

export const NutritionalGuidelines: FC<NutritionalGuidelinesProps> = ({
  sectionLetter,
}) => {
  const { t, recommendations, person } = useReportPdfContext()

  const TRANSLATION_BASE_KEY = 'initial-report:sectionNutritional.keyGuidelines'

  const defaultKey = 'rec_nutritional_mediterranean_keep'

  const keyGuidelines = getRecommendations({
    recKeys: recommendations?.NUTRITIONAL,
    defaultKey,
  })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const listOfTranslations: string[] = t(
    `${TRANSLATION_BASE_KEY}.guidelines.${keyGuidelines}.list`,
    {
      returnObjects: true,
    },
  )

  const list = Array.isArray(listOfTranslations)
    ? listOfTranslations.map((literal) => {
        return <TranscriptionScriptPDF key={literal} script={literal} />
      })
    : []

  return (
    <View style={tw('px-12')}>
      <SubSection
        title={t('initial-report:subSection.guidelines.title', {
          letter: sectionLetter,
        })}
      >
        <View style={tw('flex flex-col gap-4')}>
          <View style={tw('flex flex-col gap-4')}>
            <TranscriptionScriptPDF
              script={`${TRANSLATION_BASE_KEY}.guidelines.${keyGuidelines}.text`}
              scriptProps={{ patientName: person?.name }}
            />
            <ListItem list={list} />
          </View>
          <TranscriptionScriptPDF
            script={`${TRANSLATION_BASE_KEY}.OMSMessage`}
          />
        </View>
      </SubSection>
    </View>
  )
}
