import { isPlainObject } from 'is-plain-object'
import {
  VideoCallDevices,
  VideoCallPermissions,
  browsersType,
  osType,
} from './enums'
import {
  applePopUpSafariOne,
  mobileApplePopupSafariOne,
  pcPromptChrome,
  pcPromptFirefox,
  pcPromptEdge,
  mobileChromePermissionsPrompt,
  pcPromptMobileFirefox,
  applePromptFirefox,
} from './images'

export const isMobile = (() => {
  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent !== 'string'
  ) {
    return false
  }
  return /Mobile/.test(navigator.userAgent)
})()

// Recursively removes any object keys with a value of undefined
export function removeUndefineds(obj) {
  if (!isPlainObject(obj)) return obj

  const target = {}

  for (const key in obj) {
    const val = obj[key]
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val)
    }
  }

  return target
}

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices()

  return {
    audioInputDevices: devices.filter(
      (device) => device.kind === VideoCallDevices.audioinput,
    ),
    videoInputDevices: devices.filter(
      (device) => device.kind === VideoCallDevices.videoinput,
    ),
    audioOutputDevices: devices.filter(
      (device) => device.kind === VideoCallDevices.audiooutput,
    ),
    hasAudioInputDevices: devices.some(
      (device) => device.kind === VideoCallDevices.audioinput,
    ),
    hasVideoInputDevices: devices.some(
      (device) => device.kind === VideoCallDevices.videoinput,
    ),
  }
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name) {
  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name })
      return result.state === VideoCallPermissions.denied
    } catch {
      return false
    }
  } else {
    return false
  }
}

export const getBrowserAndOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor

  const browsers = {
    Edge: /Edg(\/\d+\.\d+\.\d+\.\d+)?/,
    Chrome: /(Chrome|CriOS)(?!.*Edg)/,
    Firefox: /Firefox/,
    Safari: /Safari(?!.*CriOS)(?!.*Chrome)/,
  }

  const osList = {
    Android: /android/i,
    iOS: /iPad|iPhone|iPod/,
    Mac: /Macintosh|MacIntel|MacPPC|Mac68K/,
    Windows: /Win32|Win64|Windows|WinCE/,
    Linux: /Linux|X11/,
  }

  const detectedBrowser =
    Object.keys(browsers).find((browser) =>
      browsers[browser].test(userAgent),
    ) || 'Unknown'

  const detectedOS =
    Object.keys(osList).find((os) => osList[os].test(userAgent)) || 'Unknown'

  return { browser: detectedBrowser, os: detectedOS, userAgent: userAgent }
}

export const getImageByBrowser = () => {
  const { browser, os } = getBrowserAndOS()
  const isApple = browser === browsersType.Safari || os === osType.iOS
  const isMobile = os === osType.Android || os === osType.iOS
  let image

  if (isApple) {
    switch (browser) {
      case browsersType.Chrome:
        image = isMobile ? mobileApplePopupSafariOne : pcPromptChrome
        break
      case browsersType.Firefox:
        image = isMobile ? '' : applePromptFirefox
        break
      case browsersType.Safari:
        image = isMobile ? mobileApplePopupSafariOne : applePopUpSafariOne
        break
    }
  } else {
    switch (browser) {
      case browsersType.Chrome:
        image = isMobile ? mobileChromePermissionsPrompt : pcPromptChrome
        break
      case browsersType.Firefox:
        image = isMobile ? pcPromptMobileFirefox : pcPromptFirefox
        break
      case browsersType.Edge:
        image = isMobile ? '' : pcPromptEdge
        break
    }
  }
  return { image, isMobile }
}

export const videoDeviceChange = (
  event,
  selectedVideoDeviceId,
  changeVideoTrack,
  localStorageKey,
) => {
  const newDeviceId = event.target.value
  if (newDeviceId !== selectedVideoDeviceId) {
    window.localStorage.setItem(localStorageKey, newDeviceId)
    changeVideoTrack({ deviceId: newDeviceId })
  }
}

export const formatPhoneNumber = (phone) => {
  const phoneWithoutSpaces = phone.replace(/\s/g, '')

  const numberOnly = phone.replace(/(\+\d{1,3}\s?)/, '')

  const phoneWithSpaces = numberOnly.replace(/(\d{3})(\d{3})(\d+)/, '$1 $2 $3')

  return { phoneWithoutSpaces, phoneWithSpaces }
}
