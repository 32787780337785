import tw from 'tailwind-styled-components'

export const RoleChipContainer = tw.div`
    flex
    justify-center
    items-center
    bg-secondary
    rounded
    m-4
    px-11
    py-1
    font-medium
    font-montserrat
    text-neutral-100
    text-xs
`
