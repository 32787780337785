export enum TEST_TYPES {
  initialAnalysis = 1,
  preventionPlan = 2,
  followUpAnalysis = 4,
}

export const TEST_TYPE_NAME = Object.freeze({
  [TEST_TYPES.initialAnalysis]: 'testList.initialAnalysis',
  [TEST_TYPES.preventionPlan]: 'testList.preventionPlan',
  [TEST_TYPES.followUpAnalysis]: 'testList.followUpAnalysis',

})

export enum FORM_FIELDS {
  testType = 'testType',
}
