// DELETE THIS FILE AND DO REFACTOR WHEN VIDEO CALL COMPONENT BE PACKAGE

import { Images } from '../../../assets/images'
import videoCallBackground from './videocall-background.jpg'

export const BRANDING_KEYS = Object.freeze({
  QIDA: 'QIDA',
  VCX: 'VCX',
})

export const BRANDING_QIDA = Object.freeze({
  branding: BRANDING_KEYS.QIDA,
  primaryColor: 'text-primary',
  primaryColorHex: '#004039',
  backgroundColor: 'bg-primary',
  borderColor: 'border-primary',
  logo: (
    <img
      className="w-32 h-12 md:w-48 md:h-20"
      src={Images.mainLogo}
      alt="Logo Qida"
    />
  ),
  backgroundImage: videoCallBackground,
})

export const BRANDING_VCX = Object.freeze({
  branding: BRANDING_KEYS.VCX,
  primaryColor: 'text-caixa-primary',
  primaryColorHex: '#007EAE',
  backgroundColor: 'bg-caixa-primary',
  borderColor: 'border-caixa-primary',
  logo: <Images.CaixaLogoWithTextSvg className="w-48 h-12" />,
  backgroundImage: videoCallBackground,
})

export const BRANDING = Object.freeze({
  [BRANDING_KEYS.QIDA]: BRANDING_QIDA,
  [BRANDING_KEYS.VCX]: BRANDING_VCX,
})
