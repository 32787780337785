import { useBreakpoint } from '../../hooks'
import {
  dotListContainerClasses,
  headerContainerClasses,
  headerTitleClasses,
  itemsListContainer,
  rowItemClasses,
  ItemsListLine,
  RowContainerClasses,
} from './styles'

export const DotList = ({
  headers = null,
  items = [],
  noItemsText = 'No hay items que mostrar',
  dotsGray = false,
}) => {
  const { isMd } = useBreakpoint('md')

  return (
    <div className={dotListContainerClasses}>
      {items.length ? (
        <>
          {isMd && headers && (
            <div
              style={{
                gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
              }}
              className={headerContainerClasses}
            >
              {headers.map(({ icon, title }, ix) => {
                if (!title) return null
                return (
                  <div key={`header-${ix}`} className={headerTitleClasses}>
                    {icon} {title}
                  </div>
                )
              })}
            </div>
          )}
          <ul className={itemsListContainer}>
            <ItemsListLine $dotsGray={dotsGray}>
              {items.map((item, ix) => {
                const values = Object.values(item)
                if (values.length) {
                  return (
                    <RowContainerClasses
                      key={`item-${ix}`}
                      style={{
                        gridTemplateColumns: `repeat(${values.length}, 1fr)`,
                      }}
                      $dotsGray={dotsGray}
                    >
                      {values.map((itemValue, ix) => {
                        return (
                          <div
                            key={`item-value-${ix}`}
                            className={rowItemClasses}
                          >
                            {itemValue}
                          </div>
                        )
                      })}
                    </RowContainerClasses>
                  )
                }
                return null
              })}
            </ItemsListLine>
          </ul>
        </>
      ) : (
        <div className="text-center">{noItemsText}</div>
      )}
    </div>
  )
}
