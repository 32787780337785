import { BIG_CALENDAR_VIEW } from '@shared/ui'
import { getDateFormatted, getWorkDayFromDate } from '@shared/utils'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { CALENDAR_DAYS_FILTER } from './enums'

export const useAgenda = () => {
  const [view, setView] = useState(BIG_CALENDAR_VIEW.day)
  const [smallCalendarDate, setSmallCalendarDate] = useState(new Date())
  const [filterDate, setFilterDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [bigCalendarDate, setBigCalendarDate] = useState()

  const formatDate = (date) => {
    return getDateFormatted(date, 'YYYY-MM-DD')
  }

  useEffect(() => {
    const filterDate = {
      [BIG_CALENDAR_VIEW.day]: smallCalendarDate,
      [BIG_CALENDAR_VIEW.workWeek]: moment(new Date(smallCalendarDate))
        .day(1)
        .format(),
      [BIG_CALENDAR_VIEW.month]: moment(new Date(smallCalendarDate))
        .startOf('month')
        .format(),
    }

    const filterDateView = filterDate[view]

    const date = formatDate(
      getWorkDayFromDate(moment(filterDateView), CALENDAR_DAYS_FILTER[view])
    )
    setFilterDate(filterDateView)
    setEndDate(date)
  }, [smallCalendarDate, view])

  const onChangeDate = (date) => {
    setSmallCalendarDate(date)
    setBigCalendarDate(date)
  }

  return {
    bigCalendarDate,
    smallCalendarDate,
    filterDate,
    endDate,
    formatDate,
    onChangeDate,
    view,
    setView,
  }
}
