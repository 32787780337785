import { BUTTON_SIZES, Button } from '@shared/ui'
import { getDateFormatted } from '@shared/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { inputClassName } from 'libs/social-referent/ui/src/sr-form-elements/styles'
import {
  ActionsBlock,
  ActionsBlockContentTwoCols,
  ActionsBlockTitle,
} from '../../../styles'

type AppointmentBlockProps = {
  action: () => void
  showAppointment?: boolean
  actionText?: null | string
  appointment?: { startDate: string }
  disabled?: boolean
}

export const AppointmentBlock: FC<AppointmentBlockProps> = ({
  action,
  showAppointment = false,
  actionText = null,
  appointment = { startDate: null },
  disabled = false,
}) => {
  const { t } = useTranslation()
  const TRANSLATION_BASE_KEY = 'userArea.profile.actions.appointment'
  const buttonText = showAppointment
    ? 'common:modifyAppointment'
    : 'common:createAppointment'

  const appointmentDate = appointment?.startDate
    ? getDateFormatted(appointment?.startDate, 'DD/MM/YYYY HH:mm')
    : ''

  return (
    <ActionsBlock>
      <ActionsBlockTitle>
        {t(`${TRANSLATION_BASE_KEY}.title`)}
      </ActionsBlockTitle>
      <ActionsBlockContentTwoCols>
        {showAppointment && (
          <input
            className={inputClassName}
            name="appointment"
            disabled
            defaultValue={appointmentDate}
          />
        )}
        <Button onClick={action} size={BUTTON_SIZES.small} disabled={disabled}>
          {t(actionText || buttonText)}
        </Button>
      </ActionsBlockContentTwoCols>
    </ActionsBlock>
  )
}
