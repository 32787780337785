import { flow, types } from 'mobx-state-tree'
import {
  BaseRouteState,
  POLICY_SUB_STATES,
  Policy,
  Schedule,
} from '@shared/data-access'
import { actions } from '../../common'
import { getWorkDayFromToday } from '@shared/utils'

export const ScheduleRouteState = BaseRouteState.named('ScheduleRouteState')
  .props({
    policyId: types.maybeNull(types.string),
    policy: types.maybeNull(types.reference(Policy)),
    startDate: types.optional(types.Date, getWorkDayFromToday(0).toDate()),
    noDigital: false,
    schedules: types.maybeNull(types.array(types.reference(Schedule))),
  })
  .views((self) => ({
    get isMod() {
      return (
        self.policy?.subState === POLICY_SUB_STATES.suscriptionPendingScheduled
      )
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('ScheduleRouteState')
      try {
        const policy = self.loadPolicy()
        const schedules = self.loadSchedule()
        yield Promise.all([policy, schedules])
      } finally {
        self.removeLoading('ScheduleRouteState')
      }
    }),
    ...actions(self),
  }))
