import { APPOINTMENT_TYPES } from '@shared/data-access'

const isReadOnlyForRs = (appointmentType) => {
  const readOnlyTypesForRs = [
    APPOINTMENT_TYPES.ABSENCES,
    APPOINTMENT_TYPES.INITIAL_REPORT,
    APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE,
  ]

  return readOnlyTypesForRs.some((type) => type === appointmentType)
}

const isReadOnlyForCoordinator = (appointmentType) => {
  const readOnlyTypesForCoordinator = [
    APPOINTMENT_TYPES.INITIAL_REPORT,
    APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE,
  ]

  return readOnlyTypesForCoordinator.some((type) => type === appointmentType)
}

export const getIsReadOnly = ({
  isReadOnly,
  isCoordinator,
  appointmentType,
}) => {
  return isCoordinator
    ? isReadOnly || isReadOnlyForCoordinator(appointmentType)
    : isReadOnly || isReadOnlyForRs(appointmentType)
}
