import { CONVERSATIONS_EVENT } from '@shared/utils'
import { useEffect, useState } from 'react'
import {
  conversationContainerClasses,
  conversationMessagesBoxClasses,
} from './styles'
import { ChatBubble, ConversationForm } from './components'

export const Conversation = ({
  conversation,
  identity,
  ownerName,
  otherName = null,
  className = '',
  boxClassName = '',
}) => {
  const [messages, setMessages] = useState([])

  const getMessagesWithFriendlyName = async ({ items }) => {
    const itemsModified = await Promise.all(
      items.map(async (message) => {
        const participant = await message.getParticipant()
        const { friendlyName } = await participant.getUser()
        return { ...message, friendlyName }
      }),
    )
    itemsModified.reverse()
    setMessages(itemsModified)
  }
  useEffect(() => {
    if (conversation) {
      conversation.getMessages().then(getMessagesWithFriendlyName)
      conversation.on(CONVERSATIONS_EVENT.messageAdded, () => {
        conversation.getMessages().then(getMessagesWithFriendlyName)
      })
    }
  }, [conversation])

  const onSubmit = ({ message }) => {
    conversation.sendMessage(message)
  }

  return (
    <div className={`${className}`}>
      <div className={`${conversationMessagesBoxClasses} ${boxClassName}`}>
        {!!messages.length &&
          messages.map((message, ix) => {
            return (
              <ChatBubble
                message={message}
                identity={identity}
                key={`messages-${ix}`}
                ownerName={ownerName}
                otherName={otherName}
              />
            )
          })}
      </div>
      <ConversationForm onSubmit={onSubmit} />
    </div>
  )
}
