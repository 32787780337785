import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import QidaLogo from '../../../report-pdf/assets/global/qida_logo_footer.png'
import QidaLogoCat from '../../../report-pdf/assets/global/Qida_LogoMail_Cat.png'
import { LANGUAGES, LANGUAGES_LOCALE_CODE, getDateHeadPDF } from '@shared/utils'
import { useReportPdfContext } from '../../report-pdf-provider'

export const LayoutPage = ({
  children,
  title,
  headerText,
  noSpacing,
  hasHeader = true,
  hasFooter = true,
  primaryTitle = true,
  id = null,
}) => {
  const { data, person } = useReportPdfContext()
  const isLangEs = person?.preferredLanguage === LANGUAGES.es
  const getLogoSource = isLangEs ? QidaLogo : QidaLogoCat
  const logoStyles = isLangEs ? { height: '18px' } : { height: '29px' }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      fontFamily: 'Montserrat',
    },
  })

  const date = getDateHeadPDF(
    data?.results.modificationDate,
    isLangEs ? LANGUAGES_LOCALE_CODE.es : LANGUAGES_LOCALE_CODE.ca,
  )

  const { t } = useReportPdfContext()

  return (
    <Page
      size="A4"
      style={{
        ...styles.page,
        ...tw('h-full'),
      }}
      id={id}
    >
      {hasHeader && (
        <View fixed>
          <Text
            style={{
              ...tw('tracking-wider text-[#d3d3d3] py-6 px-12 text-xs'),
            }}
          >
            {headerText || t('reportTitle')}
          </Text>
          {title && (
            <View
              style={tw('flex flex-row justify-between items-center px-12')}
            >
              <Text
                style={tw(
                  `font-semibold text-2xl leading-none ${
                    primaryTitle ? 'text-primary' : 'text-black'
                  }`,
                )}
              >
                {title}
              </Text>
              <View style={tw('text-sm text-black')}>
                <Text style={tw('ml-auto')}>
                  <Text>{date}</Text>
                </Text>
                <Text style={tw('font-bold')}>{person.fullName}</Text>
              </View>
            </View>
          )}
          {title && <View style={tw('h-px bg-lightGrey my-6 mx-12')}></View>}
        </View>
      )}
      <View
        style={{ ...tw(`${noSpacing ? 'p-0' : 'px-12'}`), fontSize: '11px' }}
      >
        {children}
      </View>
      {hasFooter && (
        <View style={tw('mt-auto p-12')} fixed>
          <View style={tw('w-full bg-lightGrey h-px')}></View>
          <View style={tw('flex flex-row items-center justify-between mt-6')}>
            <View>
              <Image
                style={{
                  paddingLeft: '5px',
                  width: '180px',
                  ...logoStyles,
                }}
                src={getLogoSource}
              />
            </View>

            <Text
              style={tw('text-sm')}
              render={({ pageNumber }) => pageNumber}
              fixed
            />
          </View>
        </View>
      )}
    </Page>
  )
}
