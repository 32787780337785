import { View } from '@react-pdf/renderer'
import { FC } from 'react'
import { Banner, SubSection } from '../../../components'
import { useReportPdfContext } from '../../../provider'
import { AreaColor, tw } from '../../../utils'
import { MedasResults } from './medas'
import { IMCResults } from './imc'

type NutritionalResultsProps = {
  sectionLetter: string
}

export const NutritionalResults: FC<NutritionalResultsProps> = ({
  sectionLetter,
}) => {
  const context = useReportPdfContext()
  const { t } = context

  const TRANSLATION_BASE_KEY = 'initial-report:sectionNutritional.results'

  return (
    <>
      <View style={tw('px-12')}>
        <SubSection
          title={t('initial-report:subSection.results.title', {
            letter: sectionLetter,
          })}
        >
          <View style={tw('flex flex-col gap-6')}>
            <IMCResults />
            <MedasResults />
          </View>
        </SubSection>
      </View>

      <Banner
        areaColor={AreaColor.nutritional}
        title={t(`${TRANSLATION_BASE_KEY}.banner`)}
      />
    </>
  )
}
