import { Link } from 'react-router-dom'
import tw from 'tailwind-styled-components'

const RouterLinkStyles = tw.div`
  text-link
`

export const RouterLink = ({
  children,
  external = false,
  to,
  className = '',
  ...rest
}) => {
  return (
    <RouterLinkStyles className={className}>
      {external ? (
        <a {...rest} href={to}>
          {children}
        </a>
      ) : (
        <Link {...rest} to={to}>
          {children}
        </Link>
      )}
    </RouterLinkStyles>
  )
}
