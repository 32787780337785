import { RootModel } from '@shared/data-access'
import { getRoot, types } from 'mobx-state-tree'

export const MODEL_NAME = 'AppointmentGenerateRoom'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    result: types.maybeNull(types.boolean),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))

export default Model
