import { LANGUAGES } from '@shared/utils'
import i18next from 'i18next'

export const loadLang = async (i18n) => {
  const instance = i18n ? i18n : i18next
  await instance.loadLanguages(Object.values(LANGUAGES))
}

export const loadNs = async ({ i18n, ns }) => {
  const instance = i18n ? i18n : i18next
  await instance.loadNamespaces(ns)
}
