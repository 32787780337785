import { FC, useMemo } from 'react'

import { ChevronLeft, ChevronRight } from '@carbon/icons-react'

import './styles.scss'

interface ArrowProps {
  direction: 'left' | 'right'
  disabled?: boolean
  size?: 'small' | 'medium'
}

export const ArrowCircle: FC<ArrowProps> = ({
  direction,
  disabled,
  size = 'medium',
}) => {
  const sizeClasses = useMemo(() => {
    return size === 'small' ? 'px-3 text-sm' : 'px-4 text-base'
  }, [size])

  const classArrow = direction === 'right' ? 'arrow__right' : 'arrow__left'

  return (
  <button 
    aria-label={direction === 'right' ? 'right' : 'left'}
    className={`
      w-10 
      h-10 
      bg-[#004039]
      rounded-full 
      flex 
      justify-center 
      items-center 
      border-0 
      cursor-pointer 
      min-h-[43px] 
      min-w-[43px] 
      outline-none 
      absolute 
      transition-all duration-500 z-[1000] 
      ${classArrow}`} 
    type="button" 
    disabled={disabled}>
      {direction === 'right' 
        ? <ChevronRight className="text-white" size={30} />
        : <ChevronLeft className="text-white" size={30} />}
  </button>

  )
}


