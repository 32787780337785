import {
  UserInfoBoxContainer,
  UserInfoBoxLabel,
  UserInfoContainer,
  UserInfoBoxTextArea,
} from '@sr/ui'
import { Button, Form } from '@shared/ui'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { getFields } from '../enum'

export const UserInfoForm = observer(
  ({ methods, onSave, user, isCoordinator, activeLanguage }) => {
    const { t } = useTranslation()

    const fields = useMemo(
      () => getFields(t, user, isCoordinator, activeLanguage),
      [t, user, isCoordinator, activeLanguage],
    )

    const textareaInputs = fields
      .filter((field) => field.isTextarea)
      .map((field) => field.input)

    return (
      <Form className="flex flex-col" methods={methods}>
        <UserInfoContainer>
          <UserInfoBoxTextArea>
            {textareaInputs.map((textarea) => {
              return textarea
            })}
          </UserInfoBoxTextArea>
          {fields.map((field, ix) => {
            if (field.isTextarea) {
              return null
            } else {
              return (
                <UserInfoBoxContainer key={field.label + ix}>
                  {field.label && (
                    <UserInfoBoxLabel>{field.label}</UserInfoBoxLabel>
                  )}
                  {field.input}
                </UserInfoBoxContainer>
              )
            }
          })}
        </UserInfoContainer>
        <Button className="mt-4 self-center" onClick={onSave} type="submit">
          {t('common:save')}
        </Button>
      </Form>
    )
  },
)
