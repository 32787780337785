import { SRNotificationTable } from '@sr/ui'
import { ChatDropdownContainer, ChatDropdownLink } from './styles'
import { useTranslation } from 'react-i18next'
import { RouterLink } from '@shared/ui'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { FC } from 'react'
import { observer } from 'mobx-react-lite'

type ChatDropdownProps = {
  list: any[]
  onViewAll: () => void
  reload: () => void
  updateItemMessages: ({
    roomSid,
    count,
  }: {
    roomSid: string
    count: number
  }) => void
}

export const ChatDropdown: FC<ChatDropdownProps> = observer(
  ({ list, onViewAll, reload, updateItemMessages }) => {
    const { t } = useTranslation()
    return (
      <ChatDropdownContainer>
        <SRNotificationTable
          list={list}
          portable
          reload={reload}
          updateItemMessages={updateItemMessages}
        />
        <ChatDropdownLink>
          <RouterLink
            className="text-primary"
            to={ROUTE_PATH.notificationsChat}
            onClick={onViewAll}
          >
            {t('notifications.chat.viewAll')}
          </RouterLink>
        </ChatDropdownLink>
      </ChatDropdownContainer>
    )
  },
)
