import { getRoot, types } from 'mobx-state-tree'
import { RootModel } from '@shared/data-access'
import { Policy } from '../../lib/policy'
import { PersonModel } from '../person'

export const MODEL_NAME = 'Client'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    firstAccess: types.boolean,
    principalPolicy: types.maybeNull(types.reference(Policy)),
    person: types.maybeNull(types.reference(PersonModel)),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
