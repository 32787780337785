export const LANGUAGES = Object.freeze({
  es: 'es',
  ca: 'ca',
})

export const LANGUAGES_LOCALE_CODE = Object.freeze({
  es: 'es-ES',
  ca: 'ca-ES',
})

export const LANGUAGES_OPTIONS_ARRAY = (t) =>
  Object.freeze([
    { label: t(`common:languages.es`), value: 'es' },
    { label: t(`common:languages.ca`), value: 'ca' },
  ])
