import { View } from '@react-pdf/renderer'
import { tw } from '../../../utils'
import { RecommendationsItem } from './components'

export const NutritionalRecommendationsTable = () => {
  return (
    <View style={tw('px-12')}>
      <View style={tw('flex flex-col gap-4')}>
        <RecommendationsItem base="vegetables" hasList />
        <RecommendationsItem base="fruit" hasSecondary hasList />
        <RecommendationsItem base="legumes" hasSecondary hasTertiary />
        <RecommendationsItem base="nuts" hasSecondary hasTertiary />
        <RecommendationsItem base="wholeGrains" hasSecondary hasTipList />
        <RecommendationsItem base="oliveOil" hasSecondary />
        <RecommendationsItem base="fishAndEggs" hasSecondary />
        <RecommendationsItem base="redMeat" hasSecondary />
        <RecommendationsItem base="salt" hasSecondary hasTipList />
        <RecommendationsItem base="sugar" hasSecondary hasTertiary hasTipList />
        <RecommendationsItem base="drinks" hasSecondary hasTipList />
        <RecommendationsItem base="milk" hasSecondary />
      </View>
    </View>
  )
}
