import { Trans, useTranslation } from 'react-i18next'
import {
  TranscriptionScriptBold,
  TranscriptionScriptBoldUnderline,
  TranscriptionScriptBox,
  TranscriptionScriptItalic,
  TranscriptionScriptItalicUnderline,
} from './styles'
import { FC } from 'react'

interface TranscriptionScriptProps {
  script: string
  scriptProps?: object
  components?: object
}

export const TranscriptionScript: FC<TranscriptionScriptProps> = ({
  script,
  scriptProps,
  components = {},
}) => {
  const { t } = useTranslation()

  return (
    <Trans
      i18nkey={script}
      values={scriptProps}
      components={{
        italic: <TranscriptionScriptItalic />,
        italicUnderline: <TranscriptionScriptItalicUnderline />,
        boldUnderline: <TranscriptionScriptBoldUnderline />,
        bold: <TranscriptionScriptBold />,
        br: <br />,
        box: <TranscriptionScriptBox />,
        p: <p />,
        ol: <ol />,
        li: <li />,
        u: <u />,
        ...components,
      }}
      defaults={t(script)}
    />
  )
}
