import { InputSearch } from '@shared/ui'
import { UserListItem } from './components'
import { inputSearchClassName, UserListContainer } from './styles'
import { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'

interface SrUserListProps {
  list: {
    fullName: string
    state: string
    stateColor: string
    id: string
  }[]
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onClick: (id: string) => void
  actions?: JSX.Element
}

export const SrUserList: FC<SrUserListProps> = ({
  list,
  onChange,
  onClick,
  actions,
}) => {
  const { t } = useTranslation()
  if (!list) return

  return (
    <UserListContainer>
      <div className="flex justify-between gap-4 items-start">
        <InputSearch
          inputSearchClassName={inputSearchClassName}
          onChange={onChange}
        />
        {actions && actions}
      </div>
      {list.length ? (
        list.map(({ fullName, state, stateColor, id }, ix) => {
          return (
            <UserListItem
              onClick={onClick}
              key={ix}
              name={fullName}
              id={id}
              state={state}
              stateColor={stateColor}
            />
          )
        })
      ) : (
        <div>{t('common:noResults')}</div>
      )}
    </UserListContainer>
  )
}
