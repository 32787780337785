import React from 'react';
import { SRRadioButtonCheckmark, SRRadioButtonContainer, SRRadioButtonInput, SRRadioButtonLabel } from './styles';
import { DS_TYPOGRAPHY_BODY_SM_REGULAR } from '@shared/ui';

interface QDInputRadioProps {
  id: string;
  name: string;
  label: string;
  value: number;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const QDInputRadio: React.FC<QDInputRadioProps> = ({
  id,
  name,
  label,
  value,
  checked,
  onChange,
  className = '',
}) => {
  return (
    <div className={`${SRRadioButtonContainer} ${className}`}>
      <label htmlFor={`${id}`} className={`${SRRadioButtonLabel} sr-radio-button`}>
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          className={`${SRRadioButtonInput} checkbox`}
        />
        <span className={`${SRRadioButtonCheckmark} checkmark`}></span>
      </label>
      <div className={DS_TYPOGRAPHY_BODY_SM_REGULAR}>{label}</div>
    </div>
   
  );
};

export default QDInputRadio;