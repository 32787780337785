import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PolicyTableObject } from '../types'
import { getDateFormatted } from '@shared/utils'

export const usePortfolioTable = () => {
  const { t } = useTranslation()

  const columns = useMemo<MRT_ColumnDef<PolicyTableObject>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('common:name'),
      },
      {
        accessorKey: 'surname',
        header: t('common:surname'),
      },
      {
        accessorKey: 'locality',
        header: t('common:locality'),
      },
      {
        accessorKey: 'postalCode',
        header: t('common:postalCode'),
      },
      {
        accessorKey: 'preferredLanguage',
        header: t('common:language'),
        Cell: ({ renderedCellValue }) => (
          <div>
            {renderedCellValue
              ? t(`common:languages.${renderedCellValue}`)
              : t('common:notIndicated')}
          </div>
        ),
      },
      {
        accessorKey: 'noDigital',
        header: t('common:modality'),
        Cell: ({ renderedCellValue }) => (
          <div>
            {renderedCellValue ? t(`common:inPerson`) : t(`common:online`)}
          </div>
        ),
      },
      {
        accessorKey: 'firstAppointmentDate',
        header: t('common:firstAppointmentDate'),
        Cell: ({ renderedCellValue }) => (
          <div>{getDateFormatted(renderedCellValue, 'DD/MM/YYYY')}</div>
        ),
        enableSorting: false,
      },
    ],
    [],
  )

  return { columns }
}
