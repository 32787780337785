import { Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { FC } from 'react'

type ListItemProps = {
  title?: string | JSX.Element
  style?: object
  list: string[] | JSX.Element[] | any
  hasBreak?: boolean
  hasComponent?: boolean
}

export const ListItem: FC<ListItemProps> = ({
  title,
  style,
  list = [],
  hasBreak = false,
  hasComponent,
}) => {
  if (!list || !list.length || !Array.isArray(list)) return null

  return (
    <View style={{ ...tw('flex flex-col gap-2'), ...style }} break={hasBreak}>
      {title && <Text style={tw('font-semibold')}>{title}</Text>}
      {list?.map((item, ix) => {
        return (
          <View
            style={tw('flex flex-row gap-2 pl-6 pt-1')}
            key={`list-item-${ix}`}
          >
            <Text>•</Text>
            {hasComponent ? item : <Text style={tw('leading-6')}>{item}</Text>}
          </View>
        )
      })}
    </View>
  )
}
