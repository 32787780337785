import { getRoot, types } from 'mobx-state-tree'
import { Office, RootModel } from '@shared/data-access'
import { SocialReferent } from '../social-referent'
import { DateString } from '../types'
import {
  POLICY_ORIGIN,
  POLICY_SIGN_UP_STATUS,
  POLICY_STATUS,
  POLICY_SUB_STATES,
  POLICY_TYPES,
  SEX,
} from './enum'
import { diffDaysFromdate, getToday } from '@shared/utils'

export const MODEL_NAME = 'Policy'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    contractNumber: types.maybeNull(types.string),
    policyNumber: types.maybeNull(types.string),
    identification: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    surname: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    postalCode: types.maybeNull(types.string),
    preferredLanguage: types.maybeNull(types.string),
    requestDate: types.maybeNull(DateString),
    firstAppointmentDate: types.maybeNull(DateString),
    resolutionDate: types.maybeNull(DateString),
    region: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    locality: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    rescheduledBySrTimes: types.maybeNull(types.number),
    noDigital: types.maybeNull(types.boolean, false),
    policyType: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(POLICY_TYPES).some((policyType) => policyType === value),
      ),
    ),
    state: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(POLICY_STATUS).some(
          (policyStatus) => policyStatus === value,
        ),
      ),
    ),
    officeControl: types.maybeNull(types.boolean),
    subscriptionOffice: types.maybeNull(types.reference(Office)),
    managerOfficeDirection: types.maybeNull(types.string),
    subscriptionOfficeId: types.maybeNull(types.string),
    derivedBy: types.maybeNull(types.string),
    socialReferent: types.maybeNull(types.reference(SocialReferent)),
    birthDay: types.maybeNull(types.string),
    sex: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(SEX).some((sex) => sex === value),
      ),
    ),
    subState: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(POLICY_SUB_STATES).some((subState) => subState === value),
      ),
    ),
    subscriptionEndDate: types.maybeNull(DateString),
    policyOrigin: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(POLICY_ORIGIN).some((origin) => origin === value),
      ),
    ),
    policySignUpStatus: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(POLICY_SIGN_UP_STATUS).some((status) => status === value),
      ),
    ),
    initialAnalysisDate: types.maybeNull(DateString),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
  .views((self) => ({
    get fullName() {
      return `${self.name} ${self.surname}`
    },
    get isSubscriptionPending() {
      return self.state === POLICY_STATUS.subscriptionPending
    },
    get isAccepted() {
      return self.state === POLICY_STATUS.accepted
    },
    get isCancelled() {
      return self.state === POLICY_STATUS.cancelled
    },
    get isRejected() {
      return self.state === POLICY_STATUS.refused
    },
    get isExpired() {
      return (
        diffDaysFromdate(getToday(), self.subscriptionEndDate) >= 1 &&
        self.isSubscriptionPending
      )
    },
    get canSendRegisterEmail() {
      return self.policySignUpStatus === POLICY_SIGN_UP_STATUS.canSendSignUpComm
    },
    get canResendEmail() {
      return self.policySignUpStatus === POLICY_SIGN_UP_STATUS.signUpCommSent
    },
    get isRequest() {
      return self.policyType === POLICY_TYPES.request
    },
    get isActive() {
      return self.policyType === POLICY_TYPES.policy
    },
    get hasScheduledDate() {
      return !!self.firstAppointmentDate
    },
    get completeOfficeAddress() {
      const buildAddress = (region, locality, street) =>
        `${region}, ${locality}, ${street}`

      const addressWithManagerDirection = () => {
        if (self.managerOfficeDirection && self.subscriptionOffice) {
          const { region, locality } = self.subscriptionOffice
          return buildAddress(region, locality, self.managerOfficeDirection)
        }
        return null
      }

      const addressWithSubscriptionOffice = () => {
        if (self.subscriptionOffice && self.subscriptionOffice.street) {
          const { region, locality, street } = self.subscriptionOffice
          return buildAddress(region, locality, street)
        }
        return null
      }

      return (
        addressWithManagerDirection() || addressWithSubscriptionOffice() || null
      )
    },
  }))

export default Model
