import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { setLocalStorageItem, getLocalStorageItem } from '@shared/utils'

import { SRCheckbox } from '@sr/ui'
import { FORM_TYPE, Input, Label } from '@shared/ui'

import { FORM_NAME } from '../../enums'
import { ScheduleNoDigitalCheckboxProps } from '../../interfaces'

export const ScheduleNoDigitalCheckbox: React.FC<ScheduleNoDigitalCheckboxProps> = ({ name, isMod, setModifyDate }) => {
  const { t } = useTranslation()
  const dateSelectedLocal = getLocalStorageItem('dateSelected')

  useEffect(() => {
    if (dateSelectedLocal === null) {
      setLocalStorageItem("dateSelected", null)
    }
  }, [dateSelectedLocal])

  const handleChange = () => {
    if (isMod) {
      setModifyDate(null)
    }
  }

  return (
    <div className='py-9 col-start-1 col-end-5' data-testid='presential-selection'>
      <div className='mb-3 font-semibold text-xl leading-6 text-dark-gray'>{t('schedule.noDigital.title')}</div>
      {t('schedule.noDigital.text')}
      <div className='flex gap-7 py-6'>
        <Input
          type={FORM_TYPE.checkbox}
          name={name}
          className='w-8 h-8 accent-[#004039]'
          onChange={handleChange}
        />
        <Label label={t('schedule.noDigital.checkbox')} name="label-new-pwd" />
      </div>
      <SRCheckbox
        name={FORM_NAME.originQida}
        label={t('schedule.noDigital.originLabel')}
        className='accent-[#004039]'
      />
    </div>
  )
}
