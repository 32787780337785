import { SRNotificationTable } from '@sr/ui'
import { NotificationsChatContainer } from './styles'
import { useLayoutContext } from '@sr/routes/common'
import { useEffect } from 'react'
import { useRouteState } from '@shared/utils'
import { NotificationsChatRouteState } from './state'
import { observer } from 'mobx-react-lite'

export const NotificationsChat = observer(() => {
  const { setBgNeutral, setBackButtonText, setIsLoading } = useLayoutContext()
  const { isLoading, conversationsList, reload, updateItemMessages } =
    useRouteState(NotificationsChatRouteState)

  useEffect(() => {
    setBgNeutral(true)
    setBackButtonText('')
    return () => {
      setBgNeutral(false)
    }
  }, [])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  if (isLoading) return

  return (
    <NotificationsChatContainer>
      <SRNotificationTable
        list={conversationsList}
        reload={reload}
        updateItemMessages={updateItemMessages}
      />
    </NotificationsChatContainer>
  )
})
