import { useTranslation } from 'react-i18next'
import { ChevronRight, ChevronLeft } from '@carbon/icons-react'
import { nextButtonClasses, previousButtonClasses } from './styles'
import './styles.scss'

export const CarouselDots = ({
  children,
  sliderRef,
  currentSlide,
  isChanging,
}) => {
  const { t } = useTranslation()

  const goNext = () => {
    sliderRef.current.slickNext()
  }

  const goPrevious = () => {
    sliderRef.current.slickPrev()
  }

  const isFirstSlide = currentSlide === 0

  const isLastSlide = currentSlide === children?.length - 1

  return (
    <ul className="DotsContainer">
      {children}
      {(isFirstSlide || !isLastSlide) && !isChanging && (
        <button
          className={nextButtonClasses}
          data-testid="next-button"
          type="button"
          onClick={goNext}
        >
          {t('common:agendaSlider.next')}
          <ChevronRight size="24" />
        </button>
      )}

      {!isFirstSlide && !isChanging && (
        <button
          className={previousButtonClasses}
          data-testid="previous-button"
          type="button"
          onClick={goPrevious}
        >
          <ChevronLeft size="24" />
          {t('common:agendaSlider.previous')}
        </button>
      )}
    </ul>
  )
}
