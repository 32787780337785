import { getDateFormatted } from '@shared/utils'
import { Carousel, CarouselDots } from '@shared/ui'
import { observer } from 'mobx-react-lite'
import { useSchedule } from './useSchedule'
import { SlotDate, SingleSchedule, Slot } from './types'
import { AvailabilityCalendarHeader } from './components/availability-calendar-header'
import { AvailabilityCalendarBody } from './components/availability-calendar-body'
import { AvailabilityCalendarBanner } from './components/availability-calendar-banner'
import { FC } from 'react'

type AvailabilityCalendarProps = {
  className?: string
  slots: Slot[]
  dateSelected: SlotDate
  setDateSelected: (dateSelected: SlotDate) => void
}

export const AvailabilityCalendar: FC<AvailabilityCalendarProps> = observer(
  ({ className = '', slots, dateSelected, setDateSelected }) => {
    const schedule = useSchedule(slots)

    if (!schedule.length) return <div>No se ha podido pintar el calendario</div>

    const dateFormatted = getDateFormatted(
      dateSelected.startDate,
      'dddd D MMMM, HH:mm'
    )

    return (
      <div className={className}>
        <Carousel DotsComponent={CarouselDots}>
          {schedule.map((slider: SingleSchedule, index: number) => {
            return (
              <div key={`availability-calendar-${index}`}>
                <table className="w-full">
                  <AvailabilityCalendarHeader header={slider} />
                  <AvailabilityCalendarBody
                    content={slider}
                    dateSelected={dateSelected}
                    setDateSelected={setDateSelected}
                  />
                </table>
              </div>
            )
          })}
        </Carousel>
        {dateSelected.startDate && (
          <AvailabilityCalendarBanner dateFormatted={dateFormatted} />
        )}
      </div>
    )
  }
)
