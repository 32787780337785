import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

type Option = {
  name?: string
  value?: string | number
}
type className = {
  inputClassName?: string
  selectClassName?: string
  inputContainerClassName?: string
}

export type SearchSelectProps = {
  options: Option[]
  onChange?: (() => void) | ((e: ChangeEvent<HTMLInputElement>) => void)
  name: string
  disabled?: boolean
  classNames?: className
  emptyError?: string
  defaultLabel?: string
  handleChange?: (value: string) => void
}
export const SearchSelect: FC<SearchSelectProps> = ({
  options = [],
  onChange,
  name,
  disabled = false,
  classNames = {},
  emptyError,
  defaultLabel,
  handleChange,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const { register, setValue, getValues, watch } = useFormContext()
  const selected = watch(name)
  const {
    inputClassName = '',
    selectClassName = '',
    inputContainerClassName = '',
  } = classNames

  const defaultOption = useMemo(() => {
    const defaultValue = getValues(name)

    const optionFind = options?.find(({ value }) => {
      return value === defaultValue
    })
    return optionFind?.name || ''
  }, [selected, options])

  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClick = (value: string | number, valueName: string) => {
    if (inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.value = valueName
    }
    if (handleChange) {
      handleChange(value.toString())
    }

    setIsVisible(false)
    setValue(name, value)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div className={`${inputContainerClassName}`} ref={ref}>
      <input
        ref={inputRef}
        onChange={onChange}
        defaultValue={defaultOption}
        placeholder={defaultOption || defaultLabel}
        onClick={() => setIsVisible(true)}
        className={`${inputClassName}`}
        disabled={disabled}
      />
      <input {...register(name)} type="hidden" />
      {!options?.length && <span className="mb-4">{emptyError}</span>}
      {isVisible && options?.length > 0 && (
        <div className="relative mt-1">
          <div className="absolute w-full">
            <ul className={`${selectClassName}`}>
              {options.map(({ value, name: optionName }, index) => {
                const isValid = (value === 0 || value) && optionName
                return (
                  isValid && (
                    <li
                      className="cursor-pointer"
                      onClick={() => handleClick(value, optionName)}
                      key={`search-select-option-${index}`}
                      data-testid="search-select-option"
                      value={value}
                    >
                      {optionName}
                    </li>
                  )
                )
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}
