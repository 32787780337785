import tw from 'tailwind-styled-components'

export const ModalAgendaFormContainer = tw.div`
    w-10/12
    flex
    flex-col
    gap-6
    mb-12
`

export const ModalAgendaActionButton = tw.div`
    flex
    ${({ $isEdit }) => ($isEdit ? 'justify-between' : 'justify-end')}
    items-center
`
