export const srCardBaseClasses = `
    rounded-3xl 
    text-neutral-100
    flex
    items-end
    text-xl
    font-raleway
`

export const srCardSmallClasses = `
    p-4
`

export const srCardMediumClasses = `
    px-8 
    py-10
`

export const srCardContentClasses = `
    flex
    flex-col
    items-start
    justify-start
`

export const srCardContainerGhostClasses = `
    ${srCardBaseClasses}
    bg-white
`

export const srCardContentGhostClasses = `
    ${srCardContentClasses}
    text-primary
`

export const srCardTitlePrimaryClasses = `
    text-left
    font-semibold
`

export const srCardTitleGhostClasses = `
    ${srCardTitlePrimaryClasses}
    text-primary
`

export const srCardLinkClasses = `
    text-secondary
    text-base
    font-normal
`

export const srCardContainerSecondaryClasses = `
    ${srCardBaseClasses}
    !bg-grayLight
    !border-white
    hover:!bg-white
    hover:!border-grayLight
`

export const srCardContentSecondaryClasses = `
    ${srCardContentClasses}
    text-primary
`

export const cardVariants = Object.freeze({
  primary: {
    container: srCardBaseClasses,
    content: srCardContentClasses,
    title: srCardTitlePrimaryClasses,
  },
  secondary: {
    container: srCardContainerSecondaryClasses,
    content: srCardContentSecondaryClasses,
    title: srCardTitlePrimaryClasses,
  },
  ghost: {
    container: srCardContainerGhostClasses,
    content: srCardContentGhostClasses,
    title: srCardTitleGhostClasses,
  },
})

export const cardSizes = Object.freeze({
  small: srCardSmallClasses,
  medium: srCardMediumClasses,
})

export const getCardVariant = (variant) => {
  return cardVariants[variant]
}

export const getCardSize = (size) => {
  return cardSizes[size]
}
