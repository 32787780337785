import { DotList } from '@shared/ui'
import { getHeadersCommunication } from './enums'
import { observer } from 'mobx-react-lite'
import { useRouteState } from '@shared/utils'
import { MonitoringAreaCommunicationsRouteState } from './state'
import { useLayoutContext } from '@sr/routes/common'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const Communications = observer(() => {
  const { id } = useParams()
  const { isLoading, communicationsFiltered } = useRouteState(
    MonitoringAreaCommunicationsRouteState,
    {
      policyId: id,
    }
  )

  const { setIsLoading } = useLayoutContext()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  return (
    <DotList
      headers={getHeadersCommunication()}
      items={communicationsFiltered}
    />
  )
})
