import { useReportPdfContext } from '../report-pdf-provider'
import {
  ExecutiveSummary,
  GeneralRecommendations,
} from '../../report-pdf/sections'

export const SectionTwo = () => {
  const { executiveSummary } = useReportPdfContext()

  return (
    <>
      {executiveSummary && <ExecutiveSummary number={2} />}
      <GeneralRecommendations number={3} />
      {/* TODO: We need to find a way to show the sections an pages dynamically
      <LayoutPage noSpacing hasHeader={false}>
        <FourthPage />
      </LayoutPage>
      */}
    </>
  )
}
