import { CALL_TYPE, CALL_DIRECTION, RootModel } from '@shared/data-access'
import { getRoot, types } from 'mobx-state-tree'

export const MODEL_NAME = 'PhoneCall'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    callDate: types.maybeNull(types.string),
    callType: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(CALL_TYPE).some((type) => type === value)
      )
    ),
    callDirection: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(CALL_DIRECTION).some((type) => type === value)
      )
    ),
    callDuration: types.maybeNull(types.number),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))

export default Model
