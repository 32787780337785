import { BaseRouteState, Policy, SocialReferent } from '@shared/data-access'
import { actions } from '@sr/routes/common'
import { flow, types } from 'mobx-state-tree'

export const MessagesState = BaseRouteState.named('MessagesState')
  .props({
    policyId: types.maybeNull(types.string),
    roomSid: types.maybeNull(types.string),
    policy: types.maybeNull(types.reference(Policy)),
    user: types.maybeNull(types.reference(SocialReferent)),
  })
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('MessagesState')
      try {
        const room = self.getTwilioRoomSeed()
        const policy = self.loadPolicy()
        const user = self.loadUserInfo()

        yield Promise.all([room, policy, user])
      } finally {
        self.removeLoading('MessagesState')
      }
    }),
    ...actions(self),
  }))
