import { useEffect } from 'react'
import { notify } from '@shared/ui'
/**
 * useHandleRoomDisconnection listener that is thrown when a participant disconnects from the call.
 * As a business section, the rest of the participants are disconnected if the person hanging up is the manager of the call
 * @param  {Object} videoOptions options for local participant
 * @param  {Object} room  options for room and all participants
 * @param  {Function} onError callback error
 * @param  {Function} removeLocalAudioTrack
 * @param  {Function} removeLocalVideoTrack
 */
export const useHandleRoomDisconnection = (
  videoOptions,
  room,
  removeLocalAudioTrack,
  removeLocalVideoTrack,
  setIsRoomFinished,
  isSharingScreen,
  toggleScreenShare
) => {
  useEffect(() => {
    if (room) {
      // When Social Referent disconnect the room, the remote participants are disconnected too
      room.on('participantDisconnected', () => {
        if (!videoOptions.isAdmin) {
          room.disconnect()
        }
      })
      const onDisconnected = (_, error) => {
        if (error) {
          notify.error(error)
        }

        setIsRoomFinished(true)

        removeLocalAudioTrack()
        removeLocalVideoTrack()
        if (isSharingScreen) {
          toggleScreenShare()
        }
      }

      room.on('disconnected', onDisconnected)
      return () => {
        room.off('disconnected', onDisconnected)
      }
    }
  }, [
    room,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
  ])
}
