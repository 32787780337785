import { camelCaseToSnakeCase } from '@shared/utils'
import {
  MRT_Row,
  MRT_RowSelectionState,
  MRT_TableInstance,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import { MRT_Localization_ES } from 'material-react-table/locales/es/index'
import { FC, ReactNode, useEffect, useState } from 'react'

export type TablePaginationProps = {
  pageSize: number
  pageIndex: number
}

export type ActionsComponentProps = {
  closeMenu: () => void
  row: MRT_Row<any>
  staticRowIndex?: number | undefined
  table: MRT_TableInstance<any>
}

type TableListProps = {
  data: any[]
  columns: any[]
  initialPagination: TablePaginationProps
  onPaginationChange: (props: TablePaginationProps) => void
  total: number
  actionsComponent?: ((props: ActionsComponentProps) => ReactNode[]) | undefined
  isLoading?: boolean
  onSortingChange: (props: { id: string; desc: boolean }[]) => void
  enableSelectAll?: boolean
  enableRowSelection?: boolean
  onRowSelection?: (param: string[]) => void
  enableMultiRowSelection?: boolean
  preSelectedRows?: string[] | null
}

export const TableList: FC<TableListProps> = ({
  data,
  columns,
  initialPagination,
  onPaginationChange,
  total,
  actionsComponent,
  isLoading,
  onSortingChange,
  enableSelectAll = false,
  enableRowSelection = false,
  onRowSelection,
  enableMultiRowSelection,
  preSelectedRows,
}) => {
  const rowSelected = preSelectedRows
    ? preSelectedRows.reduce((acc, id) => ({ ...acc, [id]: true }), {})
    : {}

  const [sorting, setSorting] = useState([])
  const [pagination, setPagination] = useState(initialPagination)
  const [rowSelection, setRowSelection] =
    useState<MRT_RowSelectionState>(rowSelected)

  const table = useMaterialReactTable({
    data: [...data],
    columns,
    localization: MRT_Localization_ES,
    manualPagination: true,
    rowCount: total ?? 0,
    state: {
      isLoading,
      pagination,
      sorting,
      rowSelection,
    },
    initialState: {
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
    onPaginationChange: setPagination,
    enableFilters: false,
    enableRowActions: Boolean(actionsComponent),
    renderRowActionMenuItems: actionsComponent,
    manualSorting: true,
    enableSelectAll,
    enableRowSelection,
    enableMultiRowSelection,
    muiTableBodyRowProps: ({ row }) => ({
      selected: rowSelection[row.id],
    }),
    onRowSelectionChange: setRowSelection,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onSortingChange: setSorting,
    getRowId: (originalRow) => originalRow.id,
    muiTablePaperProps: {
      sx: {
        border: 'none',
        boxShadow: 'none',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontFamily: 'Montserrat',
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '24px',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontFamily: 'Montserrat',
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '24px',
      },
    },
  })

  useEffect(() => {
    onPaginationChange(pagination)
  }, [pagination])

  useEffect(() => {
    const snakeCased = sorting.map(({ id, desc }) => ({
      id: camelCaseToSnakeCase(id),
      desc,
    }))
    onSortingChange(snakeCased)
  }, [sorting])

  useEffect(() => {
    const arrayOfIdsSelected = Object.keys(rowSelection).filter(
      (key) => rowSelection[key],
    )
    onRowSelection?.(arrayOfIdsSelected)
  }, [rowSelection])

  return <MaterialReactTable table={table} />
}
