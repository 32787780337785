import tw from 'tailwind-styled-components'

export const SRBigCalendarEventWeekStyle = tw.div`
  flex
  h-full
  text-xs
  flex-row
  items-center
  justify-center
  
`

export const SRBigCalendarEventWeekBlockStyle = tw.div`
  flex
  md:flex-col
  xl:flex-row
  justify-center
  items-center
  gap-1
`
