import { useTracks } from '../../../../../hooks'
import { AudioTrack } from '../../audio-track'

export const ParticipantAudioTrack = ({ participant }) => {
  const tracks = useTracks(participant)
  const audioTrack = tracks.find((track) => track.kind === 'audio')
  if (audioTrack?.kind === 'audio') return <AudioTrack track={audioTrack} />

  return null
}
