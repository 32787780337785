import React from 'react'
import { getDateFormatted } from '@shared/utils'

interface ScheduleDayProps {
  day: string
  dayClassName?: string
  containerClassName?: string
  dayNumberClassName?: string
}

export const ScheduleDay: React.FC<ScheduleDayProps> = ({
  day,
  dayClassName = '',
  containerClassName = '',
  dayNumberClassName = '',
}) => {
  const dayName = getDateFormatted(day, 'ddd').replace('.', '')
  const dayNumber = getDateFormatted(day, 'D')
  const dayMonth = getDateFormatted(day, 'MMM').replace('.', '')

  return (
    <div className={dayClassName}>
      <div data-testid="schedule-agenda-day" className={containerClassName}>
        <span data-testid="schedule-agenda-day-name">{dayName}</span>
        <span
          data-testid="schedule-agenda-day-number"
          className={dayNumberClassName}
        >
          {dayNumber}
        </span>
        <span data-testid="schedule-agenda-day-month">{dayMonth}</span>
      </div>
    </div>
  )
}
