export const TRANSLATION_BASE_KEY = 'userArea.control'

export const TEST_NAME = Object.freeze({
  health: `${TRANSLATION_BASE_KEY}.healthTest.title`,
  lobo: `${TRANSLATION_BASE_KEY}.loboTest.title`,
  clock: `${TRANSLATION_BASE_KEY}.clockTest.title`,
  summary: `${TRANSLATION_BASE_KEY}.summary.title`,
})

export const TEST_PAGE = Object.freeze({
  health: 0,
  lobo: 1,
  clock: 2,
  summary: 3,
})

export const TEST_PAGE_NAME = Object.freeze({
  [TEST_PAGE.health]: 'health',
  [TEST_PAGE.lobo]: 'lobo',
  [TEST_PAGE.clock]: 'clock',
  [TEST_PAGE.summary]: 'summary',
})
