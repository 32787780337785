import { Text, View } from '@react-pdf/renderer'
import { areaColors, tw } from '../../utils'

export const TableList = ({ tableList, tableHeader, areaColor }) => {
  return (
    <View style={tw('gap-2 flex flex-col justify-center')}>
      {tableList.map((element, index) => {
        return (
          <>
            {index === 0 && (
              <View
                fixed
                style={tw('flex flex-row justify-center gap-2')}
                key={`table-list-view-parent-${index}`}
              >
                {tableHeader.map((name) => (
                  <View
                    key={`table-list-view-header-${name}-${index}`}
                    style={{
                      width: `${(1 / tableHeader.length) * 100}%`,
                      height: '62px',
                      ...tw(
                        `rounded-md flex justify-center items-center text-center bg-[${areaColors[areaColor]}] text-white`,
                      ),
                    }}
                  >
                    <Text style={tw('uppercase font-bold text-sm')}>
                      {name}
                    </Text>
                  </View>
                ))}
              </View>
            )}
            <View
              style={tw('flex flex-row justify-center gap-2')}
              key={`table-list-views-${index}`}
            >
              {element.map((item, index) => {
                return (
                  <View
                    break
                    style={{
                      backgroundColor: '#F2F2F2',
                      width: `${(1 / tableHeader.length) * 100}%`,
                      height: '100%',
                      ...tw('p-4 pb-6 rounded-md'),
                    }}
                    key={`table-list-second-views-${index}`}
                  >
                    {index === 0 ? (
                      <Text
                        break
                        style={tw('text-black font-bold text-center')}
                      >
                        {item}
                      </Text>
                    ) : (
                      <Text break style={tw('leading-10 text-xs mb-6')}>
                        {item}
                      </Text>
                    )}
                  </View>
                )
              })}
            </View>
          </>
        )
      })}
    </View>
  )
}
