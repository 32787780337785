import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import moment from 'moment'

import { Printer, DocumentAttachment } from '@carbon/icons-react'
import { Button, BUTTON_TYPES, Switcher } from '@shared/ui'

type Props = {
  title?: string
  subTitle?: string
  dataSentNotification?:string
  notificationId?: string
  status?: 'off' | 'on' | 'disabled' | boolean | undefined
  handleToggleChange?: (state: 'off' | 'on' | 'disabled'| boolean) => void
  handleEmailModal?: () => void
  icon?: 'print' | 'attach'
  cta?: 'cta_button' | 'cta_checkbox' | 'cta_toggle'
  disabled?: boolean
}

const getIcon = (icon?: 'print' | 'attach') => {
  const iconSize = 20
  const className = "mr-2"

  switch (icon) {
    case 'print':
      return <Printer size={iconSize} className={className} />
    case 'attach':
      return <DocumentAttachment size={iconSize} className={className} />
    default:
      return null
  }
}

const getStatusLabel = (status: 'off' | 'on' | 'disabled'| boolean = 'off', t: TFunction) => {
  if (typeof status === 'boolean') {
    return t('analysis:sendButton')
  }

  const labels: Record<'off' | 'on' | 'disabled', string> = {
    'off': t('analysis:toggleOptions.offLabel'),
    'on': t('analysis:toggleOptions.onLabel'),
    'disabled': t('analysis:toggleOptions.disabledLabel'),
  }
  
  return labels[status]
}

const getCTA = (
  status: 'off' | 'on' | 'disabled' | boolean = 'off',  
  t: TFunction,
  cta?: 'cta_button' | 'cta_checkbox' | 'cta_toggle',
  handleToggleChange?: (state: 'off' | 'on' | 'disabled' | boolean) => void,
  disabled?: boolean,
  label?: string,
  dataSentNotification?: string,
  handleEmailModal?: () => void,
) => {
  
  const finalStatus = typeof status === 'boolean' ? (status ? 'on' : 'off') : status

  if (!cta) return null

  switch (cta) {
    case 'cta_button':
      
      return (
        <div className="flex flex-col w-full justify-end items-end align-top">
          <Button 
            testId={finalStatus}
            type={BUTTON_TYPES.submit}
            onClick={handleEmailModal}
            disabled={finalStatus !== 'on'}
            >{dataSentNotification ? t('common:resend') : label}</Button>
            {dataSentNotification && 
              <span className='text-xs text-gray-700 ml-2 mt-1'>{moment(dataSentNotification).format('DD/MM/YYYY')}</span>
            }
        </div>
      )
    case 'cta_toggle':
      return (
        <Switcher
          label={label}
          onChange={handleToggleChange}
          status={finalStatus}
          disabled={finalStatus === 'disabled'}
          dataSentPostal={dataSentNotification}
        />
      )
    default:
      return null
  }
}

export const NotificationsOptions: FC<Props> = observer(
  ({
    title,
    subTitle,
    dataSentNotification,
    notificationId,
    handleToggleChange,
    handleEmailModal,
    icon,
    status,
    cta,
    disabled
  }) => {

    const { t } = useTranslation()
    const iconElement = getIcon(icon)
    const label = getStatusLabel(status, t)  
    const ctaElement = getCTA(status, t, cta, handleToggleChange, disabled, label, dataSentNotification, handleEmailModal)

    return (
      <div className="mt-6 h-[100px] lg:min-h-[120px] w-full bg-white shadow-md border border-gray-200 rounded-md" data-testid={notificationId}>
        <div className="grid grid-cols-[80%_20%] lg:grid-cols-[70%_30%] items-start gap-0 p-6 md:p-3 lg:p-3 xl:p-5 custom-xl:p-6">
          <div>
            <div className="flex items-start mb-3">
              {iconElement}
              <div className="text-black text-[16px] font-medium font-montserrat leading-[20px]">
                {title}
              </div>
            </div>
            <div className="text-black text-[14px] font-normal font-montserrat leading-[21px]">
              {subTitle}
            </div>
          </div>
          <div className="flex items-end">
            {ctaElement}
          </div>
        </div>
      </div>
    )
  }
)
