import tw from 'tailwind-styled-components'

export const ChatDropdownContainer = tw.div`
    absolute
    top-16
    right-4
    xl:right-16
    w-[529px]
    bg-neutral-100
    border
    border-black
    z-10
    text-black
`

export const ChatDropdownLink = tw.div`
    bg-white
    text-center
    p-2
    border-t 
    border-zinc-300
`
