import { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { ScheduleDay } from './components/schedule-day'
import { ScheduleHour } from './components/schedule-hour'

export const Schedule = observer(
  ({
    schedules,
    noDigital,
    fieldName,
    containerClassName = '',
    dayContainerClassName = '',
    dayClassName = '',
    hourContainerClassName = '',
    hourClassName = '',
    dayInnerContainerClassName = '',
    dayNumberClassName = '',
    previousDate = localStorage.getItem('dateSelected'),
    modifyDate,
    setModifyDate = () => {},
    isMod,
  }) => {
    const [selectedDate, setSelectedDate] = useState(modifyDate)
    const [isSelected, setIsSelected] = useState(false)
    const [selectedSlots, setSelectedSlots] = useState({})

    const groupSlotsByDate = (slots) =>
      slots.reduce((groupedSlots, slot) => {
        const date = new Date(slot.slotDate)
        if (isNaN(date.getTime())) {
          console.error('Invalid Date object for slotDate:', slot.slotDate)
          return groupedSlots
        }

        const formattedDate = date.toISOString().split('T')[0]
        groupedSlots[formattedDate] = groupedSlots[formattedDate] || []
        groupedSlots[formattedDate].push(slot)
        return groupedSlots
      }, {})

    const groupedSlots = groupSlotsByDate(schedules)

    const handleModifyDate = (date, slotId) => {
      resetSlots()
      setSelectedSlots((prev) => ({
        ...prev,
        [slotId]: !prev[slotId],
      }))

      setSelectedDate(date)
      setModifyDate(date)
    }

    const resetSlots = () =>
      setSelectedSlots(
        Object.keys(selectedSlots).reduce((acc, key) => {
          acc[key] = false
          return acc
        }, {}),
      )

    return (
      <div
        className={`grid grid-cols-7 gap-2 xl:gap-6 ${containerClassName}`}
        data-testid="schedule-grid-ba"
      >
        {Object.keys(groupedSlots).map((date, groupIndex) => (
          <div
            key={date}
            className={`flex flex-col ${dayContainerClassName}`}
            data-testid={`schedule-agenda-day-${groupIndex}`}
          >
            <ScheduleDay
              dayClassName={`flex place-content-center ${dayClassName}`}
              containerClassName={`w-full flex gap-2 justify-center items-end uppercase font-semibold pb-4 ${dayInnerContainerClassName}`}
              dayNumberClassName={`font-bold text-2xl ${dayNumberClassName}`}
              day={date}
            />
            <div className={`flex flex-col gap-2 ${hourContainerClassName}`}>
              {groupedSlots[date].map((item, index) => (
                <ScheduleHour
                  noDigital={noDigital}
                  watchValue={previousDate}
                  className={`inline-flex justify-center items-center border rounded w-full text-sm ${hourClassName}`}
                  disabled={item.slotType}
                  isAvailable={item.slotType}
                  isSelected={selectedSlots[item.id] || false}
                  fieldName={fieldName}
                  key={`${item.slotDate}.${index}`}
                  slotStart={item.slotDate}
                  slotEnd={item.slotEndDate}
                  modifyDate={modifyDate}
                  setModifyDate={() => handleModifyDate(item.slotDate, item.id)}
                  isMod={isMod}
                  data-testid={index}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  },
)
