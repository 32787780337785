import { ButtonTimer } from '../button-timer/button-timer'
import { LayoutCallLogButtonTimer } from './style'

export const LayoutCallLog = ({ onEnd }) => {
  return (
    <LayoutCallLogButtonTimer>
      <ButtonTimer onEnd={onEnd} />
    </LayoutCallLogButtonTimer>
  )
}
