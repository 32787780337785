export const CONNECTION_STATE = Object.freeze({
  connecting: 'connecting',
  connected: 'connected',
  disconnecting: 'disconnecting',
  disconnected: 'disconnected',
  denied: 'denied',
})

export const CONVERSATIONS_EVENT = Object.freeze({
  connectionStateChanged: 'connectionStateChanged',
  conversationJoined: 'conversationJoined',
  conversationLeft: 'conversationLeft',
  messageAdded: 'messageAdded',
  conversationUpdated: 'conversationUpdated',
})
