import { Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { FC } from 'react'

type TipListProps = {
  color: string
  title: string
  children: JSX.Element
}
export const TipList: FC<TipListProps> = ({ color, title, children }) => {
  return (
    <View style={tw('flex flex-row gap-4 mb-4')}>
      <View style={tw(`w-1 h-full bg-${color}`)} break />
      <View style={tw('flex flex-col gap-2')} break>
        <Text style={tw(`text-${color} pt-2 font-semibold`)}>{title}</Text>
        <View style={tw('pl-6 mr-10')}>{children}</View>
      </View>
    </View>
  )
}
