import {
  tabBorderClasses,
  tabClasses,
  tabTitleContainerClasses,
} from './styles'

export const Tab = ({
  title,
  link,
  ix,
  activeTab,
  onClick,
  titleSuffix = null,
}) => {
  return (
    <div
      className={`${tabClasses} ${activeTab === ix ? 'text-secondary' : ''}`}
      onClick={() => onClick({ link, ix })}
    >
      <div className={tabTitleContainerClasses}>
        <span>{title}</span>
        {titleSuffix}
      </div>
      <div
        className={`${tabBorderClasses} ${
          activeTab === ix ? 'bg-secondary' : ''
        }`}
      ></div>
    </div>
  )
}
