export const notificationBubbleClasses = `
    flex
    justify-center
    items-center
    border
    border-solid
    border-current
    rounded-full
    w-[20px]
    h-[20px]
    text-xs
`
