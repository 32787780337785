import { ChangeEvent, FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface SelectProps {
  id?: string
  dataTestid?: string
  name: string
  selectClassName?: string
  defaultValue?: undefined | string | number | readonly string[]
  disabled?: boolean
  options: {
    name: string
    disabled?: boolean
    value: string | number
  }[]
  hasNoneOption?: boolean
  placeholder?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const Select: FC<SelectProps> = ({
  id,
  dataTestid,
  name,
  options = [],
  selectClassName = '',
  hasNoneOption = false,
  defaultValue,
  disabled,
  placeholder = null,
  onChange,
}) => {
  const { register } = useFormContext()
  const { t } = useTranslation()

  const noneOptionValue = hasNoneOption ? 'none' : undefined
  const placeHolderValue = placeholder ? 'placeholder' : undefined
  const defaultSelected = placeHolderValue || noneOptionValue || defaultValue

  return (
    <select
      id={id}
      data-testid={dataTestid}
      {...register(name, { disabled, onChange })}
      className={selectClassName}
      defaultValue={defaultSelected}
    >
      {hasNoneOption && (
        <option disabled value="none">
          {t('common:select')}
        </option>
      )}
      {placeholder && (
        <option value="placeholder" disabled hidden>
          {placeholder}
        </option>
      )}
      {options.map(({ value, name, disabled }, index) => {
        const isValid = (value === 0 || value) && name
        return (
          isValid && (
            <option
              key={`select-option-${index}`}
              data-testid="select-option"
              value={value}
              disabled={disabled}
            >
              {name}
            </option>
          )
        )
      })}
    </select>
  )
}
