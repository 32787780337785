import { NotificationsContainer } from '@shared/ui'
import { VideoCall } from './video-call'
import PropTypes from 'prop-types'

export const VideoCallContainer = ({ token, roomName }) => {
  return (
    <>
      <NotificationsContainer />
      <VideoCall token={token} roomName={roomName} />
    </>
  )
}

VideoCallContainer.propTypes = {
  token: PropTypes.string.isRequired,
  roomName: PropTypes.string.isRequired,
}
