import { useEffect } from 'react'
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  getDeviceInfo,
  SELECTED_VIDEO_INPUT_KEY,
} from '../../../../utils'

/** useRestartVideoTrackOnDeviceChange
 * @param  {Object} localTracks  options for room and all participants
 */

export const useRestartVideoTrackOnDeviceChange = (localTracks) => {
  const localVideoTrack = localTracks.find((track) => track.kind === 'video')

  useEffect(() => {
    const handleDeviceChange = async (e) => {
      const { hasVideoInputDevices } = await getDeviceInfo()
      if (hasVideoInputDevices) {
        const selectedVideoDeviceId = window.localStorage.getItem(
          SELECTED_VIDEO_INPUT_KEY,
        )
        localVideoTrack?.restart({
          ...DEFAULT_VIDEO_CONSTRAINTS,
          deviceId: { exact: selectedVideoDeviceId },
        })
      }
    }

    if (navigator?.mediaDevices?.addEventListener) {
      navigator.mediaDevices.addEventListener(
        'devicechange',
        handleDeviceChange,
      )
    }

    return () => {
      if (navigator?.mediaDevices?.removeEventListener) {
        navigator.mediaDevices.removeEventListener(
          'devicechange',
          handleDeviceChange,
        )
      }
    }
  }, [localVideoTrack])
}
