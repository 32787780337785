import { GENERIC_ANALYSIS_TEST_TYPE } from '@sr/data-access'
import { BasicHealthTestRouteState } from './health-state'
import { LoboTestRouteState } from './lobo-state'
import { ClockTestRouteState } from './clock-state'
import { FamilyTestRouteState } from './family-state'
import { ClinicalTestRouteState } from './clinical-state'
import { FunctionalTestRouteState } from './functional-state'
import { CognitiveTestRouteState } from './cognitive-state'
import { NutritionalTestRouteState } from './nutritional-state'
import { SocialTestRouteState } from './social-state'
import { SummaryTestRouteState } from './summary-state'
import { SummaryExecutiveTestRouteState } from './summary-executive-state'
import { PfeifferTestRouteState } from './pfeiffer-state'
import { MOCKED_CONTROL_TEST, MOCKED_INITIAL_TESTS } from './mock-data'

export const STATE_BY_TEST_TYPE = Object.freeze({
  [GENERIC_ANALYSIS_TEST_TYPE.basicHealth]: BasicHealthTestRouteState,
  [GENERIC_ANALYSIS_TEST_TYPE.lobo]: LoboTestRouteState,
  [GENERIC_ANALYSIS_TEST_TYPE.clock]: ClockTestRouteState,
  [GENERIC_ANALYSIS_TEST_TYPE.family]: FamilyTestRouteState,
  [GENERIC_ANALYSIS_TEST_TYPE.clinical]: ClinicalTestRouteState,
  [GENERIC_ANALYSIS_TEST_TYPE.functional]: FunctionalTestRouteState,
  [GENERIC_ANALYSIS_TEST_TYPE.cognitive]: CognitiveTestRouteState,
  [GENERIC_ANALYSIS_TEST_TYPE.nutritional]: NutritionalTestRouteState,
  [GENERIC_ANALYSIS_TEST_TYPE.social]: SocialTestRouteState,
  [GENERIC_ANALYSIS_TEST_TYPE.summary]: SummaryTestRouteState,
  [GENERIC_ANALYSIS_TEST_TYPE.summary_executive]: SummaryExecutiveTestRouteState,
  [GENERIC_ANALYSIS_TEST_TYPE.pfeiffer]: PfeifferTestRouteState,
})

export const MOCKED_DATA_BY_TEST_TYPE = Object.freeze({
  [GENERIC_ANALYSIS_TEST_TYPE.basicHealth]: MOCKED_CONTROL_TEST.health,
  [GENERIC_ANALYSIS_TEST_TYPE.lobo]: MOCKED_CONTROL_TEST.lobo,
  [GENERIC_ANALYSIS_TEST_TYPE.clock]: MOCKED_CONTROL_TEST.clock,
  [GENERIC_ANALYSIS_TEST_TYPE.family]: MOCKED_INITIAL_TESTS.familyBackground,
  [GENERIC_ANALYSIS_TEST_TYPE.clinical]:
    MOCKED_INITIAL_TESTS.clinicalAssessment,
  [GENERIC_ANALYSIS_TEST_TYPE.functional]:
    MOCKED_INITIAL_TESTS.functionalAssessment,
  [GENERIC_ANALYSIS_TEST_TYPE.cognitive]:
    MOCKED_INITIAL_TESTS.cognitiveAssessment,
  [GENERIC_ANALYSIS_TEST_TYPE.nutritional]:
    MOCKED_INITIAL_TESTS.nutritionalAssessment,
  [GENERIC_ANALYSIS_TEST_TYPE.social]: MOCKED_INITIAL_TESTS.socialAssessment,
  [GENERIC_ANALYSIS_TEST_TYPE.summary]: MOCKED_INITIAL_TESTS.summary,
})
