import { SORT_VALUES } from './enums'

export const sortByKey = ({ array = [], key = '' } = {}) => {
  if (!array || !key) return null
  return array.sort((a, b) => {
    if (a[key] < b[key]) {
      return SORT_VALUES.lessThan
    }
    if (a[key] > b[key]) {
      return SORT_VALUES.greaterThan
    }
    return SORT_VALUES.equal
  })
}
