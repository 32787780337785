import { View } from '@react-pdf/renderer'
import { FC } from 'react'
import { SubSection, TranscriptionScriptPDF } from '../../../components'
import { tw } from '../../../utils/styles'
import { useReportPdfContext } from '../../../provider'

type SocialObjectivesProps = {
  sectionLetter: string
}

export const SocialObjectives: FC<SocialObjectivesProps> = ({
  sectionLetter,
}) => {
  const { t, person } = useReportPdfContext()

  const TRANSLATION_BASE_KEY = 'initial-report:sectionSocial.objectives'

  return (
    <View style={tw('px-12')}>
      <SubSection
        title={t('initial-report:subSection.objectives.title', {
          letter: sectionLetter,
        })}
      >
        <TranscriptionScriptPDF
          script={`${TRANSLATION_BASE_KEY}.text`}
          scriptProps={{ patientName: person?.name }}
        />
      </SubSection>
    </View>
  )
}
