export type SR_CARD_VARIANT_TYPES = 'primary' | 'ghost'
export type SR_CARD_SIZE_TYPES = 'small' | 'medium'

export const SR_CARD_VARIANTS = Object.freeze({
  primary: 'primary',
  secondary: 'secondary',
  ghost: 'ghost',
})

export const SR_CARD_SIZE = Object.freeze({
  small: 'small',
  medium: 'medium',
})
