import * as ReactDOM from 'react-dom/client'
import * as Sentry from "@sentry/react"

import App from './app/app'

const shouldInitializeSentry = () => {
    const hostname = window.location.hostname;
    const env = process.env.NODE_ENV;
    const isProduction = env === 'production';
    const isNotTestOrStage = !hostname.includes('test') && !hostname.includes('stage');
    return isProduction && isNotTestOrStage;
}

if (shouldInitializeSentry()) {
    Sentry.init({
        dsn: "https://fcd7e9549dbd36bf24dfcb1621a0fc3c@o426068.ingest.us.sentry.io/4507453209903104",
        integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        ],
        environment: "production",
        tracesSampleRate: 1.0, 
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)
