import { View } from '@react-pdf/renderer'
import { getRecommendations, tw } from '../../../../utils'
import { useReportPdfContext } from '../../../../provider'
import { TranscriptionScriptPDF } from '../../../../components'

export const TobaccoAndAlcoholFeedback = () => {
  const { recommendations, person } = useReportPdfContext()

  const TRANSLATION_BASE_KEY =
    'initial-report:sectionTobaccoAndAlcohol.feedback'

  const defaultKeyTobacco = 'rec_tobacco_congratulations_no_smoking'
  const defaultKeyAlcohol = 'rec_alcohol_congratulations_no_drinking'

  const tobaccoFeedback = getRecommendations({
    recKeys: recommendations?.TOBACCO,
    defaultKey: defaultKeyTobacco,
  })
  const alcoholFeedback = getRecommendations({
    recKeys: recommendations?.ALCOHOL,
    defaultKey: defaultKeyAlcohol,
  })

  const feedback = [tobaccoFeedback[0], alcoholFeedback[0]]

  return (
    <View wrap={false}>
      {feedback.map((key) => {
        return (
          <View style={tw('mb-8')}>
            <TranscriptionScriptPDF
              script={`${TRANSLATION_BASE_KEY}.${key}`}
              scriptProps={{ patientName: person?.name }}
            />
          </View>
        )
      })}
    </View>
  )
}
