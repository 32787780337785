type FagerstromScoreProps = {
  score: number | string
}

export enum FagerstromScoreResult {
  low,
  medium,
  high,
}

interface SCORE_NAME {
  [key: number]: string
}

export const FagerstromScoreName: SCORE_NAME = Object.freeze({
  [FagerstromScoreResult.low]: 'low',
  [FagerstromScoreResult.medium]: 'medium',
  [FagerstromScoreResult.high]: 'high',
})

export class FagerstromScore {
  score: number | string
  result: number
  low: number
  medium: number
  high: number
  scoreName: string
  scale: string

  constructor({ score }: FagerstromScoreProps) {
    this.score = score
    this.result = FagerstromScoreResult.low
    this.low = 2
    this.medium = 4
    this.high = 6
    this.scoreName = ''
    this.scale = `${score}/${this.high}`
    this.init()
  }

  private init() {
    this.setResult()
    this.setScoreName()
  }

  private setResult() {
    if (typeof this.score === 'string') {
      return
    }
    if (this.score <= this.low) {
      this.result = FagerstromScoreResult.low
    }
    if (this.score > this.low && this.score <= this.medium) {
      this.result = FagerstromScoreResult.medium
    }
    if (this.score > this.medium && this.score <= this.high) {
      this.result = FagerstromScoreResult.high
    }
  }

  private setScoreName() {
    this.scoreName = FagerstromScoreName[this.result]
  }
}
