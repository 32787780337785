import tw from 'tailwind-styled-components'

export const UserProfileContainer = tw.div`
    w-full
    py-5
`

export const UserProfileContent = tw.div`
    pt-8
`
export const UserProfilesWrapper = tw.div`
    grid
    grid-cols-1
    gap-8
    lg:gap-16
    md:grid-cols-[200px_1fr]
`

export const UserProfileBox = tw.div`
    flex
    flex-col
    gap-10
`

export const UserProfileBoxTitle = tw.div`
    flex
    items-center
    gap-6
    text-primary
    text-xl
    font-medium
    mb-5
`
