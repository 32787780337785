import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  ButtonRetry,
  CountDownUnavailable,
  PreviewScreen,
  UnSupportedBrowser,
} from '@shared/ui'
import { differenceBetweenDateAndToday } from '@shared/utils'
import { isSupported } from 'twilio-video'

export const MeetNotAvailable = () => {
  let [searchParams] = useSearchParams()

  const startDate = searchParams.get('start_date')
  const urlRetry = searchParams.get('url_retry')

  const [navigateUrlRetry, setNavigateUrlRetry] = useState(false)

  const [diffMinorThanZero, setDiffMinorThanZero] = useState(
    differenceBetweenDateAndToday(startDate) <= 0
  )

  useEffect(() => {
    let interval

    if (diffMinorThanZero) {
      setNavigateUrlRetry(true)
    } else {
      interval = setInterval(() => {
        if (diffMinorThanZero) {
          setNavigateUrlRetry(true)
          clearInterval(interval)
        } else {
          setDiffMinorThanZero(differenceBetweenDateAndToday(startDate) <= 0)
        }
      }, 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [diffMinorThanZero])

  if (!isSupported) return <UnSupportedBrowser />

  return (
    <div className="flex h-full">
      <PreviewScreen isAvailable={false}>
        {navigateUrlRetry && urlRetry ? (
          <ButtonRetry urlRetry={urlRetry} />
        ) : (
          <CountDownUnavailable startDate={startDate} />
        )}
      </PreviewScreen>
    </div>
  )
}
