import { getRoot, types } from 'mobx-state-tree'
import { RootModel } from '../core/root-model'
import { DateString } from '../types'
import { SLOT_TYPE } from './enum'

export const MODEL_NAME = 'AvailableSlot'

export const Model = RootModel.named(MODEL_NAME)
  .props({

    id: types.identifier,
    slotDate: types.maybeNull(DateString),
    slotEndDate: types.maybeNull(DateString),
    slotType: types.maybeNull(
      types.refinement(types.integer, (value) =>
        Object.values(SLOT_TYPE).some((type) => type === value)
      )
    ),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))

export default Model
