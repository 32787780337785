import { createContext, useContext, useEffect } from 'react'

const StoreContext = createContext(null)

const useRouteState = (routeState, initialData = {}) => {
  const context = useContext(StoreContext)

  useEffect(() => {
    // cleanup function (on unmount)
    return () => {
      context.clearRouteState(routeState)
    }
  }, [])

  return context.getRouteState(routeState, initialData)
}

export { StoreContext, useRouteState }
