import { useRouteState } from '@shared/utils'
import { useLayoutContext } from '@sr/routes/common'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { SRUserName } from '@sr/ui'
import { CampaignAppointmentManagerContainer } from './styles'
import { NewEvent } from './new-event'
import { UserAreaAgendaRouteState } from '../agenda/state'

export const CampaignAppointmentManager = observer(() => {
  const { id: annuityId, serviceId } = useParams()

  const { setIsLoading } = useLayoutContext()

  const { isLoading, annuity } = useRouteState(UserAreaAgendaRouteState, {
    annuityId,
    serviceId,
  })

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  if (!annuity) return

  return (
    <CampaignAppointmentManagerContainer>
      <SRUserName fullName={annuity.person?.fullName} />

      <NewEvent id={annuity.id} />
    </CampaignAppointmentManagerContainer>
  )
})
