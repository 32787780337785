type RichmondScoreProps = {
  score: number | string
}

export enum RichmondScoreResult {
  low,
  doubt,
  medium,
  high,
}

interface SCORE_NAME {
  [key: number]: string
}

export const RichmondScoreName: SCORE_NAME = Object.freeze({
  [RichmondScoreResult.low]: 'low',
  [RichmondScoreResult.doubt]: 'doubt',
  [RichmondScoreResult.medium]: 'medium',
  [RichmondScoreResult.high]: 'high',
})

export class RichmondScore {
  score: number | string
  result: number
  low: number
  doubt: number
  medium: number
  high: number
  scoreName: string
  scale: string

  constructor({ score }: RichmondScoreProps) {
    this.score = score
    this.result = RichmondScoreResult.low
    this.low = 3
    this.doubt = 5
    this.medium = 7
    this.high = 10
    this.scoreName = ''
    this.scale = `${score}/${this.high}`
    this.init()
  }

  private init() {
    this.setResult()
    this.setScoreName()
  }

  private setResult() {
    if (typeof this.score === 'string') {
      return
    }
    if (this.score <= this.low) {
      this.result = RichmondScoreResult.low
    }
    if (this.score > this.low && this.score <= this.doubt) {
      this.result = RichmondScoreResult.doubt
    }
    if (this.score > this.doubt && this.score <= this.medium) {
      this.result = RichmondScoreResult.medium
    }
    if (this.score > this.medium && this.score <= this.high) {
      this.result = RichmondScoreResult.high
    }
  }

  private setScoreName() {
    this.scoreName = RichmondScoreName[this.result]
  }
}
