type LoboScoreProps = {
  score: number
}

export enum LoboScoreResult {
  none,
  mild,
  moderate,
  severe,
}

export const LoboScoreName = Object.freeze({
  [LoboScoreResult.none]: 'none',
  [LoboScoreResult.mild]: 'mild',
  [LoboScoreResult.moderate]: 'moderate',
  [LoboScoreResult.severe]: 'severe',
})

export class LoboScore {
  score: number
  result: number
  none: number
  mild: number
  moderate: number
  severe: number
  scoreName: string
  scale: string

  constructor({ score }: LoboScoreProps) {
    this.score = score
    this.result = LoboScoreResult.none
    this.none = 30
    this.mild = 24
    this.moderate = 19
    this.severe = 14
    this.scoreName = ''
    this.scale = `${score}/${this.none}`
    this.init()
  }

  private init() {
    this.setResult()
    this.setScoreName()
  }

  private setResult() {
    if (typeof this.score === 'string') {
      return
    }
    if (this.score <= this.severe) {
      this.result = LoboScoreResult.severe
    }
    if (this.score > this.severe && this.score <= this.moderate) {
      this.result = LoboScoreResult.moderate
    }
    if (this.score > this.moderate && this.score <= this.mild) {
      this.result = LoboScoreResult.mild
    }
    if (this.score > this.mild && this.score <= this.none) {
      this.result = LoboScoreResult.none
    }
  }

  private setScoreName() {
    this.scoreName = LoboScoreName[this.result as keyof typeof LoboScoreName]
  }
}
