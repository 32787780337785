import { getEnv, types } from 'mobx-state-tree'
import { ModelStore } from '@shared/data-access'
import { Model, MODEL_NAME } from './model'

const API_URL = '/clients/userinfo'

export const Store = ModelStore.named(`${MODEL_NAME}Store`)
  .props({
    url: API_URL,
    map: types.map(Model),
  })
  .actions((self) => ({
    logout() {
      getEnv(self).client.logout()
    },
  }))

export default Store
