import { SRSelect, SRSelectOptions } from '@sr/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ServiceBlockFormValues,
  TRANSLATION_BASE_KEY,
  WantServiceValues,
  wantServiceOptions,
} from './enums'
import { useFormContext } from 'react-hook-form'
import { AcceptSelect, RejectSelect } from './components'
import {
  ActionsBlock,
  ActionsBlockContent,
  ActionsBlockTitle,
} from '../../../styles'

type ServicesBlockProps = {
  project: string
  onRejection: () => void
  onAccept: (serviceId: string) => void
  disabled: boolean
  serviceOptions: SRSelectOptions
  rejectOptions: SRSelectOptions
  state?: string
}

export const ServicesBlock: FC<ServicesBlockProps> = ({
  project,
  onRejection,
  onAccept,
  disabled,
  serviceOptions,
  rejectOptions,
  state = null,
}) => {
  const { t } = useTranslation()

  const { watch } = useFormContext()

  const wantService = watch(ServiceBlockFormValues.wantService)
  const placeholder = disabled
    ? t(`${TRANSLATION_BASE_KEY}.form.${state}`)
    : t(`${TRANSLATION_BASE_KEY}.form.placeholder`)

  return (
    <ActionsBlock>
      <ActionsBlockTitle>
        {t(`${TRANSLATION_BASE_KEY}.title`, { project })}
      </ActionsBlockTitle>
      <ActionsBlockContent>
        <SRSelect
          placeholder={placeholder}
          name={ServiceBlockFormValues.wantService}
          options={wantServiceOptions(t)}
          disabled={disabled}
        />

        {wantService === WantServiceValues.yes &&
          Boolean(serviceOptions.length) && (
            <AcceptSelect action={onAccept} serviceOptions={serviceOptions} />
          )}
        {wantService === WantServiceValues.no &&
          Boolean(rejectOptions.length) && (
            <RejectSelect action={onRejection} rejectOptions={rejectOptions} />
          )}
      </ActionsBlockContent>
    </ActionsBlock>
  )
}
