import tw from 'tailwind-styled-components'

export const NotificationItemContainer = tw.div`
    flex
    justify-between
    items-center
    p-3
    border-b
    border-neutral-100
    text-sm
    cursor-pointer
    hover:bg-[#E9EEED]
`

export const NotificationItemContentContainer = tw.div`
    flex
    items-center
    gap-2
`

export const NotificationItemName = tw.span`
    truncate
    max-w-[190px]
`

export const NotificationItemDate = tw.div`
    text-[10px]
    text-zinc-500
`

export const chatBubbleClassName = `
    !w-[24px]
    !h-[24px]
    border
`

export const NotificationItemBubbleContainer = tw.div`

`

export const NotificationItemBubble = tw.div`
    bg-secondary
    text-[8px]
    rounded
    rounded-full
    w-[13px]
    h-[13px]
    flex
    justify-center
    items-center
`
