import { ModalMediator } from './mediator'
import { getDateFormatted, getTime } from '@shared/utils'
import { ModalProvider } from './provider'
import { APPOINTMENT_TYPES } from '@shared/data-access'
import { getDefaultModality } from './common'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'

export const ModalAgenda = ({
  event,
  dateSelected,
  onClose,
  isReadOnly = false,
  isCoordinator,
  actionAfterSubmit,
  isEdit: isPropEdit = false,
  onGenerateRoom,
}) => {
  const {
    appointmentType: { reference } = {
      reference: APPOINTMENT_TYPES.CONSULTATION,
    },
    start,
    end,
    unavailableSlotType,
    id,
    meetingUrl,
    policy,
    personId,
    appointmentNeedsTrackingChange,
    appointmentModality,
    isAppointmentManagerEdit,
    isPostCs,
  } = event
  const navigate = useNavigate()

  const {
    noDigital,
    id: policyId,
    managerOfficeDirection,
    street,
    officeControl,
  } = policy || {}

  const isEdit = isPropEdit || isAppointmentManagerEdit

  const defaultDate = start
    ? getDateFormatted(start, 'YYYY-MM-DD')
    : getDateFormatted(dateSelected, 'YYYY-MM-DD')

  const defaultStartTime = getTime(start)
  const defaultEndTime = getTime(end)

  const modality =
    appointmentModality || appointmentModality === 0
      ? appointmentModality
      : getDefaultModality({ type: reference, noDigital })

  const actionAfterSubmitAppointmentManager = () => {
    navigate(`${ROUTE_PATH.userArea(policyId)}`)
  }

  return (
    <ModalProvider
      eventId={id}
      policyId={policyId}
      personId={personId}
      appointmentType={reference}
      appointmentDay={defaultDate}
      unavailableSlotType={unavailableSlotType}
      startTime={defaultStartTime}
      endTime={defaultEndTime}
      modality={modality}
      meetingUrl={meetingUrl}
      appointmentNeedsTrackingChange={appointmentNeedsTrackingChange}
      onGenerateRoom={onGenerateRoom}
      noDigital={noDigital}
      managerOfficeDirection={managerOfficeDirection}
      street={street}
      officeControl={officeControl}
    >
      <ModalMediator
        isReadOnly={isReadOnly}
        isCoordinator={isCoordinator}
        isEdit={isEdit}
        actionAfterSubmit={
          isAppointmentManagerEdit || isPostCs
            ? actionAfterSubmitAppointmentManager
            : actionAfterSubmit
        }
        onClose={onClose}
      />
    </ModalProvider>
  )
}
