import { isValidPhoneNumber } from 'react-phone-number-input'
import * as yup from 'yup'

export const yupPhone = (t) => {
  const invalidPhone = t('common:errors.phone')
  return yup
    .string()
    .nullable()
    .defined()
    .required()
    .test({
      name: 'is-valid',
      test(value, ctx) {
        if (!isValidPhoneNumber(value || '')) {
          return ctx.createError({ message: invalidPhone })
        }
        return true
      },
    })
    .label(t('common:phone'))
}
