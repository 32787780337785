import * as yup from 'yup'
import { MY_PROFILE_FORM_FIELDS } from './enum'

export const useSchema = (t, user) => {
  return yup.object().shape({
    [MY_PROFILE_FORM_FIELDS.phone]: yup
      .string()
      .required()
      .label(t('common:phone')),
    [MY_PROFILE_FORM_FIELDS.activeFrom]: yup
      .string()
      .required()
      .label(t('common:activeFrom')),
    [MY_PROFILE_FORM_FIELDS.academicTitle]: yup
      .string()
      .required()
      .label(t('common:academicTitle')),
    ...(user?.isCoordinator && {
      [MY_PROFILE_FORM_FIELDS.employeeState]: yup
        .string()
        .required()
        .label(t('common:employeeState.label')),
    }),
    [MY_PROFILE_FORM_FIELDS.country]: yup
      .string()
      .required()
      .label(t('common:country')),
    [MY_PROFILE_FORM_FIELDS.region]: yup
      .string()
      .required()
      .label(t('common:region')),
    [MY_PROFILE_FORM_FIELDS.locality]: yup
      .string()
      .required()
      .label(t('common:locality')),
    [MY_PROFILE_FORM_FIELDS.street]: yup
      .string()
      .required()
      .label(t('common:street')),
    [MY_PROFILE_FORM_FIELDS.professionalDescEs]: yup
      .string()
      .required()
      .label(t('common:professionalDesc')),
    [MY_PROFILE_FORM_FIELDS.professionalDescCa]: yup
      .string()
      .label(t('common:professionalDesc')),
  })
}
