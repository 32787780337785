import {
  GenericAnalysisAvailableTypesModel,
  GenericAnalysisModel,
} from '@sr/data-access'
import { actionsCampaign } from '@sr/routes/common'
import { t } from 'i18next'
import { flow, getRoot, isRoot, types } from 'mobx-state-tree'
import { TEST_TYPE_NAME } from './enum'
import { BaseRouteState } from '@shared/data-access'

export const GenericAnalysisListState = BaseRouteState.named(
  'GenericAnalysisListState'
)
  .props({
    personId: types.maybeNull(types.string),
    genericAnalysisAvailableList: types.array(
      types.reference(GenericAnalysisAvailableTypesModel)
    ),
    genericAnalysisList: types.array(types.reference(GenericAnalysisModel)),
  })
  .views((self) => ({
    get hasAvailableAnalysis() {
      return (
        !!self.genericAnalysisAvailableList &&
        !!self.genericAnalysisAvailableList.length
      )
    },
    get genericAnalysisAvailableParsed() {
      return self.genericAnalysisAvailableList?.map(({ availableTest }) => {
        return {
          name: t(TEST_TYPE_NAME[availableTest]),
          value: availableTest,
        }
      })
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('GenericAnalysisListState')
      try {
        yield self.loadGenericAnalysis()
        yield self.loadAvailableGenericAnalysis()
      } finally {
        self.removeLoading('GenericAnalysisListState')
      }
    }),
    createTest: flow(function* createTest(testType) {
      if (isRoot(self)) return
      self.setLoading('createTest')
      try {
        yield getRoot(self).GenericAnalysisStore.create(
          {
            genericAnalysisType: testType,
            person: self.personId,
          },
          {
            throwError: true,
            avoidUpdate: true,
          }
        )
      } catch (error) {
        console.error(error)
      } finally {
        self.removeLoading('createTest')
      }
    }),
    ...actionsCampaign(self),
  }))
