import { View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { FC } from 'react'
import { Html } from 'react-pdf-html'

type TipsProps = {
  classes: any
  children: string
}

export const Tips: FC<TipsProps> = ({ classes, children }) => {
  return (
    <View>
      <Html style={tw('text-sm p-4')} stylesheet={classes}>
        {children}
      </Html>
    </View>
  )
}
