import { flow } from 'mobx-state-tree'

export const stateActions = (self) => ({
  afterAttach() {
    self.loadDependencies()
  },
  loadDependencies: flow(function* loadDependencies() {
    self.setLoading('DashboardRouteState')
    try {
      yield self.loadUserInfo()
      yield self.loadPendingAnnuities()
    } finally {
      self.removeLoading('DashboardRouteState')
    }
  }),
})
