import { SRModal } from '@sr/ui'
import { FC } from 'react'
import {
  ButtonContainer,
  ConflictMessageContainer,
  ModalContainer,
} from './styles'
import { WarningFilled } from '@carbon/icons-react'
import { useTranslation } from 'react-i18next'
import { Button, BUTTON_SIZES, BUTTON_VARIANTS } from '@shared/ui'

type GenericConflictModalProps = {
  onClose: () => void
  onRetry: () => void
  conflicts: number
  canContinue: boolean
}

export const GenericConflictModal: FC<GenericConflictModalProps> = ({
  onClose,
  onRetry,
  conflicts,
  canContinue,
}) => {
  const { t } = useTranslation()

  return (
    <SRModal onClose={onClose} className="!w-[800px]">
      <ModalContainer>
        <ConflictMessageContainer>
          <div className="w-[80px] h-[80px] text-primary">
            <WarningFilled className="w-full h-full fill-primary" />
          </div>
          <div>
            {t('privateArea.portfolioManagement.modal.conflictMessage', {
              count: conflicts,
            })}
          </div>
        </ConflictMessageContainer>

        <div>
          {t('privateArea.portfolioManagement.modal.conflictDescription')}
        </div>

        <ButtonContainer>
          <Button
            size={BUTTON_SIZES.small}
            variant={BUTTON_VARIANTS.outlined}
            onClick={onClose}
          >
            {t('common:cancel')}
          </Button>
          <Button
            disabled={!canContinue}
            size={BUTTON_SIZES.small}
            onClick={onRetry}
          >
            {t('common:continue')}
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </SRModal>
  )
}
