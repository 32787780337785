import { FC } from 'react'
import { Phone } from '@carbon/icons-react'
import {
  OPPreviewContainer,
  OPPreviewParagraph,
  OPPreviewNumber,
  OPPreviewAvailableHours,
  OPPreviewNumberContainer,
} from './style'
import { useSearchParams } from 'react-router-dom'
import { BRANDING_KEYS } from '../../../../utils/branding'
import { formatPhoneNumber } from '../../../../utils'
import { Button, TranscriptionScript } from '@shared/ui'

interface Branding {
  branding?: string
}
interface OPPreviewProps {
  onClose: () => void
  branding: Branding
  setOpNumberView: (value: boolean) => void
}
export const OPPreview: FC<OPPreviewProps> = ({
  onClose,
  branding,
  setOpNumberView,
}) => {
  const [searchParams] = useSearchParams()
  const socialReferentPhone = searchParams.get('social_referent_phone')

  const { phoneWithoutSpaces, phoneWithSpaces } =
    formatPhoneNumber(socialReferentPhone)

  const nextStep = () => {
    onClose()
    setOpNumberView(true)
  }
  return (
    <div className="h-full flex flex-col justify-center">
      <div
        className={`${OPPreviewContainer} sm:border-solid sm:border-2 ${
          branding?.branding === BRANDING_KEYS.VCX
            ? 'border-caixa-primary'
            : 'border-primary'
        }`}
      >
        <p className={OPPreviewParagraph}>
          <TranscriptionScript script="common:videoCall.opNumber.lineOne" />
        </p>
        <p className={`${OPPreviewParagraph} w-full max-w-[760px]`}>
          <TranscriptionScript script="common:videoCall.opNumber.lineTwo" />
        </p>
        <div
          className={`${OPPreviewNumberContainer} ${OPPreviewContainer} !p-0`}
        >
          <p className={OPPreviewParagraph}>
            <TranscriptionScript script="common:videoCall.opNumber.lineThree" />
          </p>
          <a
            href={`tel:${phoneWithoutSpaces}`}
            className={`text-lg ${OPPreviewNumber}`}
          >
            <Button
              rounded
              className={`!p-2 mr-2 ${
                branding?.branding === BRANDING_KEYS.VCX
                  ? '!border-caixa-primary !bg-caixa-primary'
                  : 'bg-primary'
              }`}
            >
              <Phone className={`inline-block`} size={30} />
            </Button>
            <span className="text-4xl">{phoneWithSpaces}</span>
          </a>
          <div className={OPPreviewAvailableHours}>
            <TranscriptionScript script="common:videoCall.opNumber.lineFour" />
          </div>
        </div>
        <p className={OPPreviewParagraph}>
          <TranscriptionScript script="common:videoCall.opNumber.lineFive" />{' '}
          <strong
            className={`cursor-pointer underline underline-offset-4 ${
              branding?.branding === BRANDING_KEYS.VCX
                ? 'text-caixa-primary'
                : 'text-primary'
            }`}
            onClick={nextStep}
          >
            <TranscriptionScript script="common:videoCall.opNumber.lineFiveInstructions" />
          </strong>
        </p>
      </div>
    </div>
  )
}
