import { FC } from 'react'
import { Building, Home } from '@carbon/icons-react'
import { useTranslation } from 'react-i18next'

type SRCheckboxProps = {
  officeControl: boolean
  officeAddress: string
  homeAddress?: string
}

export const SRFormAdress: FC<SRCheckboxProps> = ({
  officeControl,
  officeAddress = '',
  homeAddress = '',
}) => {
  const { t } = useTranslation()
  const Icon = officeControl ? Building : Home
  const address = officeControl ? officeAddress : homeAddress
  const addressKey = officeControl ? 'common:office' : 'common:home'

  return (
    <div className="flex items-center text-gray-400">
      <Icon className="mr-2 w-1/12" size={30} />
      {t(addressKey) + ':'} {address || t('common:noAddress')}
    </div>
  )
}
