import { types } from 'mobx-state-tree'
import moment from 'moment'

export const DateString = types.custom({
  name: 'Date',
  fromSnapshot: (string) => {
    if (!string) return null
    return new Date(string)
  },
  toSnapshot: (date) => {
    if (!date) return null
    return moment(date).utc().format()
  },
  isTargetType: (maybeDate) => {
    return !!maybeDate?.getDate
  },
  getValidationMessage: (snapshot) => {
    if (snapshot === undefined) return ''
    return ''
  },
})
