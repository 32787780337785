import { useState } from 'react'
import {
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
} from '@carbon/icons-react'
import { titleClasses } from './styles'

export const Accordion = ({
  title = '',
  children,
  innerText = '',
  containerClassName = '',
  textContainerClassName = '',
  textClassName = '',
  titleClassName = '',
  arrowClasses = '',
  isVertical = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  let toggleOpen = () => {
    setIsOpen(!isOpen)
  }
  const textClasses = isOpen ? 'block' : 'hidden'

  const width = isOpen || !isVertical ? 'w-full' : 'w-min'
  const height = isVertical ? 'h-full' : 'h-min'

  const iconWhenIsOpen = isVertical ? (
    <ChevronLeft
      className={`ml-auto ${arrowClasses}`}
      data-testid="chevron-left"
      size="28"
    />
  ) : (
    <ChevronUp className={arrowClasses} data-testid="chevron-up" size="28" />
  )
  const iconWhenIsClosed = isVertical ? (
    <ChevronRight
      className={arrowClasses}
      data-testid="chevron-right"
      size="28"
    />
  ) : (
    <ChevronDown
      className={arrowClasses}
      data-testid="chevron-down"
      size="28"
    />
  )

  return (
    <div
      data-testid="accordion-container"
      className={`${width} ${height} ${containerClassName}`}
    >
      <div
        data-testid="accordion-title"
        className={`${titleClasses} ${titleClassName}`}
        onClick={toggleOpen}
      >
        {title}
        {isOpen ? iconWhenIsOpen : iconWhenIsClosed}
      </div>
      <div
        data-testid="accordion-text"
        className={`${textClasses} ${textContainerClassName} `}
      >
        {innerText ? (
          <div
            className={textClassName}
            dangerouslySetInnerHTML={{ __html: innerText }}
          />
        ) : (
          <div className={textClassName}>{children}</div>
        )}
      </div>
    </div>
  )
}
