export const CONTROL_TEST_NAME = Object.freeze({
  health: 'health',
  lobo: 'lobo',
  clock: 'clock',
})

export const CONTROL_TEST_TYPE = Object.freeze({
  [CONTROL_TEST_NAME.health]: 0,
  [CONTROL_TEST_NAME.lobo]: 1,
  [CONTROL_TEST_NAME.clock]: 2,
})
