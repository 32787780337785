import { BaseRouteState } from '@shared/data-access'
import { flow, getEnv } from 'mobx-state-tree'

export const ProfileDropdownRouteState = BaseRouteState.named(
  'ProfileDropdownRouteState',
).actions((self) => ({
  logout: flow(function* logout() {
    self.setLoading('ProfileDropdownRouteState')
    try {
      yield getEnv(self).client.logout()
    } finally {
      self.removeLoading('ProfileDropdownRouteState')
    }
  }),
}))
