import { SRModal } from '@sr/ui'
import { Trans, useTranslation } from 'react-i18next'
import { NextStepsButtonContainer } from '../../styles'
import { BUTTON_COLORS, Button } from '@shared/ui'
import { CloseModalTextContainer } from './styles'

export const CompleteModal = ({ onClose, onComplete }) => {
  const TRANSLATION_BASE_KEY = 'userArea.initialReport.summary'
  const { t } = useTranslation()

  return (
    <SRModal onClose={onClose}>
      <CloseModalTextContainer>
        <Trans
          i18nkey={`${TRANSLATION_BASE_KEY}.completeText`}
          components={{ br: <br /> }}
          defaults={t(`${TRANSLATION_BASE_KEY}.completeText`)}
        />
      </CloseModalTextContainer>
      <NextStepsButtonContainer>
        <Button color={BUTTON_COLORS.secondary} onClick={onClose}>
          {t('common:exit')}
        </Button>
        <Button onClick={onComplete} testId="next-steps-finish">
          {t('common:confirm')}
        </Button>
      </NextStepsButtonContainer>
    </SRModal>
  )
}
