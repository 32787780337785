export const SERVICE_TYPES = Object.freeze({
  PERSONAL_COUNSELOR: 0,
  CONTENT_PLATFORM: 1,
  RISK_MANAGEMENT: 2,
  PREVENTIVE_POWERS: 3,
  GUIDELINES: 4,
  PREMIUM_CARE_ACCESS: 5,
  DEPENDENCY_MANAGEMENT: 6,
  HOME_ANALYSIS: 7,
  PREVENTION_PLAN: 8,
  OTHER: 99,
})

export const SERVICE_TRANSLATION = Object.freeze({
  [SERVICE_TYPES.PERSONAL_COUNSELOR]: 'serviceType.personalCounselor',
  [SERVICE_TYPES.CONTENT_PLATFORM]: 'serviceType.contentPlatform',
  [SERVICE_TYPES.RISK_MANAGEMENT]: 'serviceType.riskManagement',
  [SERVICE_TYPES.PREVENTIVE_POWERS]: 'serviceType.preventivePowers',
  [SERVICE_TYPES.GUIDELINES]: 'serviceType.guidelines',
  [SERVICE_TYPES.PREMIUM_CARE_ACCESS]: 'serviceType.premiumCareAccess',
  [SERVICE_TYPES.DEPENDENCY_MANAGEMENT]: 'serviceType.dependencyManagement',
  [SERVICE_TYPES.HOME_ANALYSIS]: 'serviceType.homeAnalysis',
  [SERVICE_TYPES.PREVENTION_PLAN]: 'serviceType.preventionPlan',
  [SERVICE_TYPES.OTHER]: 'serviceType.other',
})
