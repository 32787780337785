import { ReportPdfContextProps } from '../../../../provider'
import { FagerstromScoreResult } from '../../../../scales'
import { scoreIsNotAvailable } from '../../../../utils'

export const getFagerstromScaleList = (context: ReportPdfContextProps) => {
  const { t, testDate, scores, person } = context
  const { score, scale, scoreName } = scores?.fagerstromScore || {}

  return [
    {
      label: t('initial-report:generic.resultTable.scaleDescription'),
      content: t('initial-report:scores.fagerstrom.description'),
    },
    {
      label: t('initial-report:generic.resultTable.testDate'),
      content: testDate,
    },
    {
      label: t('initial-report:generic.resultTable.result'),
      content: scoreIsNotAvailable(score)
        ? t('initial-report:resultNotAvailable.fagerstrom', {
            patientName: person?.name,
          })
        : t(`initial-report:scores.fagerstrom.${scoreName}.value`, {
            scale,
          }),
    },
  ]
}

export const getFagerstromPerYearList = (context: ReportPdfContextProps) => {
  const { t, scores } = context
  const { result } = scores?.fagerstromScore || {}

  return [
    {
      text: 'A:0-2',
      subText: t('initial-report:scores.fagerstrom.scaleValues.low'),
      selected: result === FagerstromScoreResult.low,
    },
    {
      text: 'B:3-4',
      subText: t('initial-report:scores.fagerstrom.scaleValues.medium'),
      selected: result === FagerstromScoreResult.medium,
    },
    {
      text: 'C:5-6',
      subText: t('initial-report:scores.fagerstrom.scaleValues.high'),
      selected: result === FagerstromScoreResult.high,
    },
  ]
}
