import { Image, Text, View } from '@react-pdf/renderer'
import CheckboxChecked from '../../assets/global/checkbox--checked.png'
import { FC } from 'react'
import { tw } from '../../utils'

type TickListProps = {
  list: string[]
  styles?: string
}

export const TickList: FC<TickListProps> = ({ list, styles = '' }) => {
  if (!list || !list.length) return null

  return (
    <View style={tw(`flex flex-col gap-2 ${styles}`)} break>
      {list.map((tip) => (
        <View style={tw('flex flex-row gap-2')}>
          <Image src={CheckboxChecked} style={tw('w-4 h-4')} />
          <Text>{tip}</Text>
        </View>
      ))}
    </View>
  )
}
