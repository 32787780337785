import { Text, View } from '@react-pdf/renderer'
import { areaColors, tw } from '../../utils'

export const Tips = ({ title, areaColor, children }) => {
  return (
    <View style={tw('flex flex-row gap-4')}>
      <View style={tw(`w-1 h-full bg-[${areaColors[areaColor]}]`)} break />
      <View style={tw('flex flex-col gap-2')} break>
        <Text style={tw(`text-[${areaColors[areaColor]}] pt-2 font-semibold`)}>
          {title}
        </Text>
        <View style={tw('pl-6 mr-10')}>{children}</View>
      </View>
    </View>
  )
}
