import { flow, getRoot, isRoot, types } from 'mobx-state-tree'
import {
  ControlTest,
  CONTROL_TEST_NAME,
  CONTROL_TEST_TYPE,
  TestResultModel,
} from '@sr/data-access'
import { FEATURE_FLAG, featureFlags } from '@shared/utils'
import { actions } from '@sr/routes/common'
import { BaseRouteState, Policy } from '@shared/data-access'
import { MOCKED_CONTROL_TEST } from '@sr/routes/common/analysis/generic-analysis/components/states/mock-data'

export const SubscriptionControlRouteState = BaseRouteState.named(
  'SubscriptionControlRouteState',
)
  .props({
    policyId: types.string,
    health: types.maybeNull(types.reference(ControlTest)),
    lobo: types.maybeNull(types.reference(ControlTest)),
    clock: types.maybeNull(types.reference(ControlTest)),
    policy: types.maybeNull(types.reference(Policy)),
    testResults: types.maybeNull(types.reference(TestResultModel)),
  })
  .views((self) => ({
    get tests() {
      return [self.health, self.lobo, self.clock]
    },
    get hasAllTests() {
      return self.health && self.lobo && self.clock
    },
    get allTestCompleted() {
      return self.tests.every((test) => test?.testCompleted)
    },
    get lastTestNotCompleted() {
      return self.tests.findIndex((test) => !test?.testCompleted)
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('SubscriptionControlRouteState')
      try {
        yield self.loadTest({ name: CONTROL_TEST_NAME.health })
        yield self.loadTest({ name: CONTROL_TEST_NAME.lobo })
        yield self.loadTest({ name: CONTROL_TEST_NAME.clock })
        yield self.loadPolicy()
      } finally {
        self.removeLoading('SubscriptionControlRouteState')
      }
    }),
    loadTest: flow(function* loadTest({ name }) {
      if (isRoot(self)) return
      self.setLoading('loadTest')
      try {
        const test = yield getRoot(self).ControlTestStore.get(null, {
          customUrl: `/policytest_meta/${self.policyId}/${CONTROL_TEST_TYPE[name]}`,
        })

        if (test) {
          if (featureFlags.hasFeatureFlag(FEATURE_FLAG.control)) {
            MOCKED_CONTROL_TEST[name] &&
              test.setPrevData(MOCKED_CONTROL_TEST[name])
          }

          self[name] = test
        }
      } finally {
        self.removeLoading('loadTest')
      }
    }),
    updateTest: flow(function* updateTest(name, data) {
      data.test_type = CONTROL_TEST_TYPE[name]
      data.policy_id = self.policyId
      data.id = self[name].id
      self[name].setTestValues(data)
      yield getRoot(self).ControlTestStore.save(self[name], {
        customUrl: `/policytest_save/${self.policyId}`,
        withoutId: true,
        avoidUpdate: true,
      })
    }),
    completeTest: flow(function* completeTest(name) {
      self.setLoading('completeTest')
      try {
        yield getRoot(self).ControlTestStore.save(self[name], {
          customUrl: `/policytest_save/${self.policyId}`,
          withoutId: true,
          avoidUpdate: true,
        })

        self[name] = yield getRoot(self).ControlTestStore.patch(self[name], {
          customUrl: `/policytest_close/${self[name].id}`,
          withoutId: true,
        })
      } finally {
        self.removeLoading('completeTest')
      }
    }),
    ...actions(self),
  }))
