import { APPOINTMENT_MODAL, getIsReadOnly } from './utils'
import { useModalContext } from '../provider'
import { SRModal } from '@sr/ui'

export const ModalMediator = ({
  isReadOnly,
  isEdit,
  isCoordinator,
  actionAfterSubmit,
  onClose,
}) => {
  const { appointmentType, modality } = useModalContext()
  const ModalComponent =
    APPOINTMENT_MODAL[appointmentType] ||
    (() => (
      <SRModal onClose={onClose}>
        Este tipo de evento no permite su modificación
      </SRModal>
    ))

  const readOnlyEvent = getIsReadOnly({
    isReadOnly,
    isCoordinator,
    appointmentType,
  })

  return (
    <ModalComponent
      isReadOnly={readOnlyEvent}
      isEdit={isEdit}
      actionAfterSubmit={actionAfterSubmit}
      modality={modality}
      isCoordinator={isCoordinator}
      onClose={onClose}
    />
  )
}
