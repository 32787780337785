import { useRouteState } from '@shared/utils'
import { useLayoutContext } from '@sr/routes/common'
import { SRCardsContainer } from '@sr/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { SRPrivateAreaRouteState } from './state'

export const PrivateArea = observer(() => {
  const { id } = useParams()
  const { setIsLoading, setBackButtonText } = useLayoutContext()
  const { socialReferent, isLoading, cards } = useRouteState(
    SRPrivateAreaRouteState,
    {
      socialReferentId: id,
    }
  )

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    setBackButtonText(socialReferent?.fullName)
  }, [socialReferent])

  if (!socialReferent) return

  return <SRCardsContainer cards={cards} />
})
