import {
  Appointment,
  GenerateRoomModel,
  UnavailableSlotModel,
} from '@sr/data-access'
import { convertToDate } from '@shared/utils'
import { flow, types } from 'mobx-state-tree'
import {
  APPOINTMENT_TYPES,
  APPOINTMENT_TYPES_COLORS,
  APPOINTMENT_TYPES_TRANSLATION,
  BaseRouteState,
  LEGEND_COLORS,
  LEGEND_TEXT_COLOR,
  LEGEND_TYPES,
  SocialReferent,
} from '@shared/data-access'
import { actions } from '../common'

export const CalendarAppointments = BaseRouteState.named('CalendarAppointments')
  .props({
    userId: types.maybeNull(types.string),
    appointments: types.maybeNull(
      types.array(types.safeReference(Appointment), {}),
    ),
    unavailableSlots: types.maybeNull(
      types.array(types.safeReference(UnavailableSlotModel), {}),
    ),
    user: types.maybeNull(types.reference(SocialReferent)),
    generatedRoom: types.maybeNull(types.reference(GenerateRoomModel)),
  })
  .views((self) => ({
    get appointmentsFormatted() {
      const slots = [
        ...(self.appointments ? self.appointments : []),
        ...(self.unavailableSlots
          ? self.formatUnavailableSlots(self.unavailableSlots)
          : []),
      ]
      return slots?.map((slot) => {
        let {
          id,
          startDate,
          endDate,
          appointmentType,
          isInitialReport,
          isInitialAnalysis,
          isPhoneCall,
          isOnline,
          isFaceToFace,
          policy,
          meetingUrl,
        } = slot

        let personalizedColor = null
        if (isInitialReport && isFaceToFace) {
          appointmentType = APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE
        }
        if (isInitialAnalysis && isPhoneCall) {
          personalizedColor = LEGEND_COLORS[LEGEND_TYPES.initialAnalysisCall]
        }
        if (isInitialAnalysis && isFaceToFace) {
          personalizedColor =
            LEGEND_COLORS[LEGEND_TYPES.initialAnalysisFaceToFace]
        }
        if (isInitialAnalysis && isOnline) {
          personalizedColor = LEGEND_COLORS[LEGEND_TYPES.initialAnalysisOnline]
        }
        if (
          appointmentType === APPOINTMENT_TYPES.FOLLOW_UP_ANALYSIS &&
          isPhoneCall
        ) {
          personalizedColor = LEGEND_COLORS[LEGEND_TYPES.yearlyFollowUpCall]
        }

        return {
          ...slot,
          id,
          start: convertToDate(startDate),
          end: convertToDate(endDate),
          appointmentType: {
            reference: appointmentType,
            text: APPOINTMENT_TYPES_TRANSLATION[appointmentType],
            color:
              personalizedColor || APPOINTMENT_TYPES_COLORS[appointmentType],
            textColor: LEGEND_TEXT_COLOR[appointmentType] || '',
          },
          policy,
          meetingUrl,
        }
      })
    },
  }))
  .actions((self) => ({
    loadDependencies: flow(function* loadDependencies(startDate, endDate) {
      self.setLoading('CalendarAppointments')
      try {
        yield self.loadUserInfo()
        yield self.loadAppointments({ startDate, endDate, id: self.userId })
        yield self.loadUnavailableSlots(startDate, endDate, self.userId)
      } finally {
        self.removeLoading('CalendarAppointments')
      }
    }),
    setUserId(id) {
      self.userId = id
    },
    ...actions(self),
  }))
