import { BaseRouteState, SocialReferent } from '@shared/data-access'
import { types } from 'mobx-state-tree'
import { stateActions } from './actions'
import { actions, actionsCampaign } from '@sr/routes/common'
import { AnnuityModel } from '@sr/data-access'
import { globalNavigate } from 'apps/social-referent/src/routes/globalHistory'
import { ROUTE_PATH_CAMPAIGN } from 'apps/social-referent/src/routes/enums'

export const DashboardRouteState = BaseRouteState.named('DashboardRouteState')
  .props({
    user: types.maybeNull(types.reference(SocialReferent)),
    annuities: types.maybeNull(types.array(types.reference(AnnuityModel))),
  })
  .views((self) => ({
    get annuityList() {
      if (!self.annuities?.length) return []

      return self.annuities?.map(
        ({ id, person }) => ({
          text: <p className="mt-0.5">{person?.fullName}</p>,
          action: () => {
            globalNavigate(ROUTE_PATH_CAMPAIGN.userArea(id))
          },
        }),
        []
      )
    },
  }))
  .actions((self) => ({
    ...stateActions(self),
    ...actions(self),
    ...actionsCampaign(self),
  }))
