import { SRModal } from '@sr/ui'
import { useState } from 'react'
import { TestCancelFirst } from './test-cancel-first'
import { TestCancelConfirmation } from './test-cancel-confirmation'

export const TestCancelModal = ({ onClose }) => {
  const [isOnCancel, setIsOnCancel] = useState(false)

  const onCancel = () => {
    setIsOnCancel(true)
  }
  return (
    <SRModal onClose={onClose}>
      {isOnCancel ? (
        <TestCancelConfirmation />
      ) : (
        <TestCancelFirst onCancel={onCancel} onClose={onClose} />
      )}
    </SRModal>
  )
}
