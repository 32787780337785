import { LANGUAGES } from '@shared/utils'
import { useFormContext } from 'react-hook-form'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import 'react-phone-number-input/style.css'
import flags from 'react-phone-number-input/flags'
import './phone-input-styles.css'

export const InputPhone = ({
  className,
  value,
  name,
  defaultCountry = LANGUAGES.es.toUpperCase(),
  setIsChanged,
}) => {
  const { control } = useFormContext()

  const handleChange = (newValue) => {
    if (newValue !== value) {
      if (setIsChanged) {
        setIsChanged(true)
      }
    }
  }

  return (
    <PhoneInputWithCountry
      className={className}
      defaultValue={value}
      // For prevent app error
      onFocus={() => {}}
      country={defaultCountry}
      defaultCountry={defaultCountry}
      countries={[defaultCountry]}
      international={false}
      flags={flags}
      name={name}
      control={control}
      onChange={handleChange}
    />
  )
}
