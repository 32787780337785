import tw from 'tailwind-styled-components'

export const modalContainerClassName = `
    w-[780px!important]
`

export const DoubleModalContainer = tw.div`
    flex
    gap-12
    mb-8
`

export const DoubleModalFormContainer = tw.div`
    ${({ $isEdit }) => ($isEdit ? 'w-1/2' : 'w-10/12')}
    flex
    flex-col
    gap-6
    mb-12
`

export const DoubleModalInfoContainer = tw.div`
    flex
    flex-col
    gap-6
    w-1/2
`
export const DoubleModalWarningTextContainer = tw.div`
    flex
    flex-col
`
export const DoubleModalWarningText = tw(DoubleModalWarningTextContainer)`
    mx-auto
    mb-4
`

export const ModalAgendaActionButtonContainer = tw.div`
    flex
    justify-between
    items-center
    w-1/2
`
