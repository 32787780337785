import Calendar from 'react-calendar'
import './calendar.css'
import moment from 'moment'
import { BREAK_POINTS, useBreakpoint } from '@shared/ui'
import { useTranslation } from 'react-i18next'

export const SRCalendar = ({ onChange, date = new Date() }) => {
  const { isXl } = useBreakpoint(BREAK_POINTS.xl)

  const {
    i18n: { language },
  } = useTranslation()

  const navigationLabel = (date) =>
    isXl ? moment(date).format('MMMM YYYY') : moment(date).format('MMM YY')

  const formatShortWeekday = (date) => moment(date).format('dd')

  return (
    <Calendar
      locale={language}
      view="month"
      navigationLabel={({ date }) => navigationLabel(date)}
      formatShortWeekday={(locale, date) => formatShortWeekday(date)}
      onChange={onChange}
      value={date}
    />
  )
}
