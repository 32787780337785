import * as yup from 'yup'
import { SR_CREATE_FORM_FIELDS } from './enum'

export const useSchema = (t) => {
  return yup.object().shape({
    [SR_CREATE_FORM_FIELDS.email]: yup
      .string()
      .required()
      .label(t('common:email')),
    [SR_CREATE_FORM_FIELDS.postalCode]: yup
      .string()
      .required()
      .label(t('common:postalCode')),
  })
}
