import { I18NProvider, LANGUAGES } from '@shared/utils'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Router } from '../routes'
import { StoreProvider } from '../store'
import { Login } from './routes'
import { GlobalHistory } from '../routes/globalHistory'

export const App = () => {
  return (
    <I18NProvider ns={['s-referent', 'analysis']} lang={LANGUAGES.es}>
      <BrowserRouter>
        <GlobalHistory />
        <StoreProvider apiURL={process.env.NX_SOCIAL_REFERENT_API_URL}>
          {({ user }) => (
            <Routes>
              <Route path="/auth/*">
                <Route path="login" element={<Login />} />
              </Route>
              <Route path="/*" element={user && <Router user={user} />}></Route>
            </Routes>
          )}
        </StoreProvider>
      </BrowserRouter>
    </I18NProvider>
  )
}

export default App
