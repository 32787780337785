import { FC } from 'react'

import { UserInfoItem } from '../user-info'
import { SRInput, SRSelect } from '../../../sr-form-elements'
import { useTranslation } from 'react-i18next'
import { EditableType } from '../enums'

interface UserInfoBoxProps extends UserInfoItem {
  isEditing?: boolean
  hasToGrow?: boolean
}

export const UserInfoBox: FC<UserInfoBoxProps> = ({
  name = '',
  label,
  value,
  isEditing = false,
  hasToGrow = false,
  editableType = EditableType.input,
  selectOptions = [],
  selectValue,
  formattedValue,
  isTextarea,
}) => {
  const { t } = useTranslation()

  const EditableComponent = () =>
    ({
      [EditableType.date]: (
        <SRInput
          name={name || label}
          defaultValue={value}
          placeholder={value}
          type="date"
        />
      ),
      [EditableType.input]: (
        <SRInput
          name={name || label}
          defaultValue={value}
          placeholder={value}
        />
      ),
      [EditableType.select]: (
        <SRSelect
          name={name}
          options={selectOptions}
          defaultValue={selectValue}
          placeholder={label}
        />
      ),
    })[editableType]

  return (
    <div className={`text-dark-gray mb-4 ${hasToGrow ? 'md:col-span-2 lg:col-span-3 xl:col-span-3' : ''}`}>
      <div className={`text-primary ${hasToGrow ? 'mb-4': 'mb-6 '} font-medium md:items-center`}>{label}</div>
      {isEditing && <EditableComponent />}
      {!isEditing &&
        (isTextarea ? (
          <div className='border rounded-md border-black px-8 py-5'>{value}</div>
        ) : (
          <div className='break-words'>
            {formattedValue || value || t('common:notIndicated')}
          </div>
        ))}
    </div>
  )
}
