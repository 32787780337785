import { FC, useEffect } from 'react'
import { BUTTON_COLORS, Button } from '../../../../../button'
import { useTranslation } from 'react-i18next'
import { caixaBankButton, getImageByBrowser } from '../../../../utils'
import { BRANDING_KEYS } from '../../../../utils/branding'
import { TranscriptionScript } from '../../../../../test-script'
import {
  prelandingScreenBorder,
  prelandingScreenButton,
  prelandingScreenImg,
  prelandingScreenParagraph,
  prelandingScreenStep,
  prelandingScreenWrapper,
} from './styles'
import { VIDEO_CALL_PAGE_VIEW, analytics } from '@shared/utils'

interface Branding {
  branding?: string
}

interface PrelandingScreenProps {
  handleCheckPermissions: () => void
  branding: Branding
}

export const PrelandingScreen: FC<PrelandingScreenProps> = ({
  handleCheckPermissions,
  branding,
}) => {
  const { t } = useTranslation()
  const { image: imageSrc } = getImageByBrowser()

  const buttonPermissions = (
    <Button
      onClick={handleCheckPermissions}
      color={
        branding?.branding === BRANDING_KEYS.VCX
          ? BUTTON_COLORS.transparent
          : BUTTON_COLORS.primary
      }
      className={`${prelandingScreenButton} ${
        branding?.branding === BRANDING_KEYS.VCX && caixaBankButton
      }`}
    >
      {t('common:continue')}
    </Button>
  )

  useEffect(() => {
    analytics.pageView({
      path: VIDEO_CALL_PAGE_VIEW.pageViewPermissionsRequest,
    })
  }, [])

  return (
    <div className={prelandingScreenWrapper}>
      <div
        className={`${prelandingScreenBorder} ${
          branding?.branding === BRANDING_KEYS.VCX
            ? 'border-caixa-primary'
            : 'border-primary'
        }`}
      >
        <div className={prelandingScreenStep}>
          <p className="mb-8 sm:mb-4 text-base sm:text-xl">
            <TranscriptionScript script="common:videoCall.prelanding" />
          </p>
          <div className="hidden sm:block text-center">
            <p className={prelandingScreenParagraph}>
              <TranscriptionScript script="common:videoCall.prelandingButton" />
            </p>
            {buttonPermissions}
          </div>
        </div>
        <div className={`${prelandingScreenStep} sm:flex-row  items-center`}>
          <img
            className={prelandingScreenImg}
            alt="browser-permissions"
            src={imageSrc}
          />

          <div className="block sm:hidden text-center">
            <p className={prelandingScreenParagraph}>
              <TranscriptionScript script="common:videoCall.prelandingButton" />
            </p>
            {buttonPermissions}
          </div>
        </div>
      </div>
    </div>
  )
}
