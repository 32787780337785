import { Link, Text, View } from '@react-pdf/renderer'
import { AreaHexColor, tw } from '../../utils'
import {
  areaColorKey,
  areaColors,
  areaColorsLighter,
} from '../../../sr-initial-report-pdf/utils'
import { FC } from 'react'
import { useReportPdfContext as UseReportPdfContext } from '../../provider'
import { SubSection } from '../subsection'
import { RightArrowSvg } from '../../assets/svg'
import { SECTION_VALUES } from './enum'

type ListItem = {
  text: string
  src: string
  description: string
}

type SectionKey = keyof typeof SECTION_VALUES

type WorkSheetListPDFProps = {
  title: string
  list: Array<ListItem>
  areaColor?: string
  section?: SectionKey
}
export const WorkSheetListPDF: FC<WorkSheetListPDFProps> = ({
  title,
  list,
  areaColor = '',
  section = 'default',
}) => {
  const { t, isCare } = UseReportPdfContext()

  // eslint-disable-next-line
  const sectionKey: keyof typeof SECTION_VALUES = SECTION_VALUES.hasOwnProperty(
    section,
  )
    ? section
    : 'default'
  const actualTitle = isCare ? title || SECTION_VALUES[sectionKey].title : ''
  const actualAreaColor = areaColor
    ? areaColors[areaColor]
      ? areaColor
      : 'white'
    : SECTION_VALUES[sectionKey].sectionColor
  const actualList = list || []

  const backgroundColor = areaColor
    ? `bg-[${areaColorsLighter[actualAreaColor]}]`
    : `bg-[${AreaHexColor[actualAreaColor]}]`

  const borderColor = areaColor
    ? `border-[${areaColors[actualAreaColor]}]`
    : `border-[${AreaHexColor[actualAreaColor]}]`

  const buttonColor = areaColor
    ? `bg-[${areaColors[actualAreaColor]}]`
    : `bg-[${AreaHexColor[actualAreaColor]}]`

  return (
    <View break>
      <SubSection title={actualTitle}>
        <View style={tw('flex flex-row w-full flex-wrap justify-between')}>
          {actualList.map((item, index) => {
            const itemTitle = `${index + 1}. ${item.text}`
            return (
              <View
                key={`${index}_worksheet`}
                style={tw(
                  `w-[47%] ${backgroundColor} border-b-4 p-3 ${borderColor} mb-6`,
                )}
              >
                <Text style={tw('leading-6 font-bold mt-3 text-base')}>
                  {itemTitle}
                </Text>

                <Text style={tw('leading-6 mt-3 text-base')}>
                  {item.description}
                </Text>
                <View>
                  <Link
                    style={tw(
                      `${buttonColor} 
                      w-32 py-2 px-3 rounded-full my-6
                      text-white no-underline text-base 
                      flex flex-row items-center justify-between`,
                    )}
                    src={item.src}
                  >
                    <Text>{t('initial-report:openWorksheet')}</Text>
                    <RightArrowSvg
                      style={tw('ml-4 mb-1')}
                      width="18px"
                      height="18px"
                      color={areaColors[areaColorKey.white]}
                    />
                  </Link>
                </View>
              </View>
            )
          })}
        </View>
      </SubSection>
    </View>
  )
}
