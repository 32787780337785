import * as yup from 'yup'
import { ProfileForm } from './enums'
import { yupEmail, yupPhone } from '@shared/utils'

export const useSchema = (t: (translation: string) => string) => {
  return yup.object().shape({
    [ProfileForm.phone]: yupPhone(t),
    [ProfileForm.email]: yupEmail(t),
    [ProfileForm.sex]: yup.string().required().label(t('common:language')),
    [ProfileForm.preferredLanguage]: yup
      .string()
      .required()
      .label(t('common:sex')),
  })
}
