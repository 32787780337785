import { useBreakpoint } from 'libs/shared/ui/src/lib/hooks'
import { useState, useEffect } from 'react'

const fifteenPercent = 0.15
const twentyPercent = 0.2
const fortyPercent = 0.4
const sixtyPercent = 0.6

export const useVideoCalculator = ({ isLocalParticipant, ref }) => {
  const { isLg } = useBreakpoint('lg')
  const { isMd } = useBreakpoint('md')

  const [containerHeight, setContainerHeight] = useState(null)
  const [containerWidth, setContainerWidth] = useState(null)

  useEffect(() => {
    setInterval(() => {
      setContainerHeight(ref?.current?.offsetParent?.offsetHeight)
      setContainerWidth(ref?.current?.offsetParent?.offsetWidth)
    }, 0)
  }, [ref])

  const [height, setHeight] = useState(null)

  const toPixel = (value) => {
    return `${value}px`
  }

  useEffect(() => {
    // Why 2? -->
    const containerHalfHeight = toPixel(containerHeight / 1.5)
    const containerHeightTabletRemoteParticipant = toPixel(
      containerHeight * sixtyPercent
    )

    const containerHeightTabletLocalParticipant = toPixel(
      containerWidth * twentyPercent
    )
    const containerHeightMobileLocalParticipant = toPixel(
      containerWidth * fortyPercent
    )

    if (isLocalParticipant) {
      if (isLg) {
        setHeight(containerHalfHeight)
      } else {
        setHeight(
          isMd
            ? containerHeightTabletLocalParticipant
            : containerHeightMobileLocalParticipant
        )
      }
    } else {
      if (isLg) {
        setHeight(containerHalfHeight)
      } else {
        setHeight(
          isMd ? containerHeightTabletRemoteParticipant : containerHalfHeight
        )
      }
    }
  }, [isLg, isMd, containerWidth, containerHeight])

  const [width, setWidth] = useState(null)

  useEffect(() => {
    // Why 2? -->
    const containerHalfWidth = toPixel(containerWidth / 2)
    const containerWidthTabletRemoteParticipant = toPixel(
      containerWidth * fortyPercent
    )

    if (isLocalParticipant) {
      if (isLg) {
        setWidth(containerHalfWidth)
      } else {
        setWidth(
          isMd ? containerWidthTabletRemoteParticipant : containerHalfWidth
        )
      }
    } else {
      if (isLg) {
        setWidth(containerHalfWidth)
      } else {
        setWidth(toPixel(containerWidth))
      }
    }
  }, [isLg, isMd, containerWidth])

  const [top, setTop] = useState(null)

  useEffect(() => {
    const containerHeightMobileOrTabletTopRemoteParticipant = toPixel(
      containerHeight * fifteenPercent
    )

    if (!isLocalParticipant) {
      if (isLg) {
        setTop(null)
      } else {
        setTop(containerHeightMobileOrTabletTopRemoteParticipant)
      }
    }
  }, [isLg, containerHeight])

  return { top, height, width }
}
