import tw from 'tailwind-styled-components'

export const AgendaChipContainer = tw.div`
    flex
    items-center
    gap-2
`

export const AgendaChipGroupContainer = tw.div`
    flex
    flex-col
    gap-2
    grow
`
