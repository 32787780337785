import tw from 'tailwind-styled-components'

export const UserAreaContainer = tw.div`
    flex
    justify-center
    items-center
    gap-8
    h-full
    flex-wrap
`
export const srCardContainerClasses = `
    h-56
    w-64
    flex
    items-center
`
