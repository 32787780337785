import { SectionSocial } from '../../report-pdf/sections/social'
import { SecondPage } from './components/second-page'

export const SectionSeven = () => {
  return (
    <SectionSocial sectionNumber="05">
      <SecondPage />
    </SectionSocial>
  )
}
