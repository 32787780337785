export const options = (t: (translation: string) => string) => [
  {
    name: t('appointmentModality.online'),
    value: 1,
  },
  {
    name: t('appointmentModality.phoneCall'),
    value: 2,
  },
]
