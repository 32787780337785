export const multiSelectControlStyles = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderColor: '',
    backgroundColor: '',
    borderRadius: '',
    borderStyle: '',
    borderWidth: '',
    color: '',
    '&:hover': {
      borderColor: '',
    },
    '&:active': {
      borderColor: '',
    },
    '&:focus': {
      borderColor: '',
    },
  }),
  valueContainer: (baseStyles: any, state: any) => ({
    ...baseStyles,
    padding: '',
    color: '',
  }),
  clearIndicator: (baseStyles: any, state: any) => ({
    ...baseStyles,
    padding: '0px 8px',
  }),
  dropdownIndicator: (baseStyles: any, state: any) => ({
    ...baseStyles,
    padding: '0px 8px',
  }),
  multiValue: (baseStyles: any, state: any) => ({
    ...baseStyles,
    backgroundColor: '',
    color: '',
  }),
  multiValueLabel: (baseStyles: any, state: any) => ({
    ...baseStyles,
    color: '',
  }),
  option: (baseStyles: any, state: any) => ({}),
  multiValueRemove: (baseStyles: any, state: any) => ({}),
}
