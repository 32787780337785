import { TranscriptionScript } from '@shared/ui'
import {
  ControlTestScriptContainer,
  ScriptBlue,
  ScriptGray,
  ScriptLi,
  ScriptOl,
  TestContainer,
  TestScript,
} from './styles'

export const ControlTestScript = ({
  children,
  script = null,
  scriptProps = {},
  components = {},
}) => {
  return (
    <ControlTestScriptContainer>
      {script && (
        <TestScript>
          <TranscriptionScript
            script={script}
            scriptProps={scriptProps}
            components={{
              ol: <ScriptOl />,
              li: <ScriptLi />,
              gray: <ScriptGray />,
              blue: <ScriptBlue />,
              ...components,
            }}
          />
        </TestScript>
      )}
      <TestContainer $hasScript={script}>{children}</TestContainer>
    </ControlTestScriptContainer>
  )
}
