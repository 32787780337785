import { getDateFormatted } from '@shared/utils'
import { SlotDate, DaySlot } from '../types'

export const AvailabilityCalendarButton = ({
  daySlot,
  dateSelected,
  setDateSelected,
}: {
  daySlot: DaySlot
  dateSelected: SlotDate
  setDateSelected: (dateSelected: SlotDate) => void
}) => {
  const hourStart = getDateFormatted(daySlot.startDate, 'HH:mm')
  const hourEnd = getDateFormatted(daySlot.endDate, 'HH:mm')

  const selected =
    dateSelected.startDate.toString() === daySlot.startDate.toString()

  const selectedClasses = selected
    ? 'bg-primary text-white'
    : 'bg-vcx-dark-white'

  return (
    <button
      onClick={() =>
        setDateSelected({
          startDate: daySlot.startDate,
          endDate: daySlot.endDate,
        })
      }
      className={`${selectedClasses} flex justify-center gap-1 w-full px-2 py-1 my-2 disabled:opacity-60 border rounded`}
      disabled={daySlot.isUnavailable}
    >
      {hourStart} - {hourEnd}
    </button>
  )
}
