import { SRModal } from '@sr/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutline } from '@carbon/icons-react'
import './styles.scss'
import { BUTTON_COLORS, Button } from '@shared/ui'
import { useNavigate } from 'react-router-dom'

type ErrorModalProps = {
  onRetry?: () => void
  mainMessage?: string
  backUrl?: any
  onClose?: () => void
  noRoute?: boolean
  hideActions?: boolean
}

export const SRErrorModal: FC<ErrorModalProps> = ({
  onClose,
  onRetry,
  mainMessage = 'analysis:errorModal.generic',
  backUrl = -1,
  noRoute,
  hideActions = false,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goToPreviousPage = () => {
    navigate(backUrl)
  }

  return (
    <SRModal onClose={noRoute ? onClose : goToPreviousPage}>
      <div className="sr-error-modal">
        <div className="sr-error-modal__item">
          <CloseOutline size={32} />
        </div>
        <div>{t(mainMessage)}</div>
        <div>{t('analysis:errorModal.footText')}</div>
      </div>
      {!hideActions && (
        <div className="sr-error-modal__button">
          <Button
            color={BUTTON_COLORS.secondary}
            onClick={noRoute ? onClose : goToPreviousPage}
          >
            {t('analysis:errorModal.goBack')}
          </Button>
          <Button onClick={onRetry}>{t('analysis:errorModal.retry')}</Button>
        </div>
      )}
    </SRModal>
  )
}
