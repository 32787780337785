import { SectionPhysicalActivity } from '../../report-pdf/sections/physical-activity'
import { SecondPage } from './components/second-page'

export const SectionThree = () => {
  return (
    <SectionPhysicalActivity sectionNumber="01">
      <SecondPage />
    </SectionPhysicalActivity>
  )
}
