import { areaColorKey, tw } from '../../utils'
import { SubSection } from '../../components/SubSection'
import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'
import { Image, View } from '@react-pdf/renderer'
import { TitleIcon } from '../../components/TitleIcon'
import { Tips } from '../../components/Tips'
import hearing from '../../assets/hearing--white.png'
import sight from '../../assets/sight.png'
import { FollowRecomendations } from '../../components/FollowRecomendations'
import { useReportPdfContext } from '../../report-pdf-provider'
import {
  AUDITORY_KEYS,
  AUDITORY_KEY_GUIDELINES,
  OCULAR_KEYS_GUIDELINES,
  getKey,
  getOcularKey,
} from './enum'

export const SecondPage = () => {
  const { data, t, person, type } = useReportPdfContext()
  const areaColor = areaColorKey.orange

  const {
    recommendations: { OCULAR, AUDITORY },
  } = data.results.initialAnalysisList

  const ocular = OCULAR_KEYS_GUIDELINES()[getOcularKey(OCULAR)]
  const auditoryList = AUDITORY_KEY_GUIDELINES(type)[getKey(AUDITORY)]

  const keepInMind = [
    <TranscriptionScriptPDF
      script={'initial-report:sectionEight.linksAndWorkSheet.keepInMind.one'}
    />,
    <TranscriptionScriptPDF
      script={'initial-report:sectionEight.linksAndWorkSheet.keepInMind.two'}
    />,
  ]

  const links = [0, 1, 2, 3, 4, 5]
  const workSheet = [0, 1]

  return (
    <>
      <View style={tw('px-12')}>
        <SubSection
          title={t('initial-report:sectionEight.keyGuidelines.title')}
        >
          <View style={tw('flex flex-col gap-6')}>
            <View style={tw('flex flex-col gap-6')}>
              {AUDITORY_KEYS.recAuditoryOcularReferPrimaryCareAuditory ===
              getKey([
                ...(OCULAR ? OCULAR : []),
                ...(AUDITORY ? AUDITORY : []),
              ]) ? (
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.auditory.rec_auditory_ocular_refer_primary_care_auditory.text'
                  }
                />
              ) : (
                <TranscriptionScriptPDF
                  script={
                    'initial-report:sectionEight.keyGuidelines.auditory.text'
                  }
                />
              )}

              {auditoryList && (
                <TitleIcon
                  title={t(
                    'initial-report:sectionEight.keyGuidelines.auditory.title',
                  )}
                  icon={<Image style={tw('w-8 h-8')} src={hearing} />}
                  areaColor={areaColor}
                />
              )}
              {auditoryList && (
                <View style={tw('flex flex-col gap-6')}>
                  {auditoryList?.map((tip, index) => {
                    return (
                      <Tips
                        areaColor={areaColor}
                        index={index}
                        title={tip.title}
                      >
                        {tip.content}
                      </Tips>
                    )
                  })}
                </View>
              )}
            </View>
            <View style={tw('flex flex-col gap-6')}>
              <TranscriptionScriptPDF
                script={'initial-report:sectionEight.keyGuidelines.ocular.text'}
              />
              <TitleIcon
                title={t(
                  'initial-report:sectionEight.keyGuidelines.ocular.title',
                )}
                icon={<Image style={tw('w-8 h-8')} src={sight} />}
                areaColor={areaColor}
              />
              <View style={tw('flex flex-col gap-6')}>
                {ocular?.map((tip, index) => {
                  return (
                    <Tips areaColor={areaColor} index={index} title={tip.title}>
                      {tip.content}
                    </Tips>
                  )
                })}
              </View>
            </View>
          </View>
        </SubSection>
      </View>
      <FollowRecomendations
        translateFrom="initial-report:sectionEight.linksAndWorkSheet"
        areaColor={areaColor}
        linksAndWorkSheetTitle={t(
          `initial-report:generic.linksAndWorkSheet.title.${type}`,
        )}
        linksAndWorkSheetText={
          <TranscriptionScriptPDF
            script={`initial-report:sectionEight.linksAndWorkSheet.text.${type}`}
            scriptProps={{ patientName: person.name }}
          />
        }
        links={links}
        keepInMindList={keepInMind}
        followRecommendationsText={t(
          'initial-report:sectionEight.linksAndWorkSheet.followRecommendations.text',
        )}
        omsText={t(
          'initial-report:sectionEight.linksAndWorkSheet.followRecommendations.omsText',
        )}
        workSheetList={workSheet}
        worksheetId={`worksheet-auditiveAndOcular`}
      />
    </>
  )
}
