import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  formatDate,
  getLocalStorageItem,
} from '@shared/utils'
import {
  dateBannerContainerClasses,
  dateBannerDateClasses,
  dateBannerTitleClasses,
} from './styles'

export const DateBanner = ({
  date,
  noDigital,
  dateBannerClasses = '',
  isMod,
  appointmentDateWatched,
}) => {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const [dateSelectedFormatted, setDateSelectedFormatted] = useState(null)
  const [timeSlotSelected] = useState(getLocalStorageItem('slotsSelected'))

  useEffect(() => {
    if (appointmentDateWatched) {
      setMessage(t('common:dateBannerTitle'))
    } else {
      setMessage(t('common:lastDateTitle'))
    }
  }, [appointmentDateWatched, t])

  useEffect(() => {
    if (date) {
      setDateSelectedFormatted(`${formatDate(date)} - ${getLocalStorageItem('slotsSelected')}`)
    }
  }, [date, timeSlotSelected])


  return (
    <div className={`${dateBannerContainerClasses} ${dateBannerClasses}`}>
      <div className={dateBannerTitleClasses}>
        {isMod ? message : t('common:dateBannerTitle')}
      </div>
      <div className={dateBannerDateClasses} data-testid="ba-agenda-banner-date">
        {dateSelectedFormatted}
      </div>
    </div>
  )
}
