import { SRModal } from '@sr/ui'
import { FC } from 'react'
import { CheckmarkFilled } from '@carbon/icons-react'
import './styles.scss'
import { useNavigate } from 'react-router-dom'

type SuccessModalProps = {
  mainMessage: string
  secondaryMessage?: string
  backUrl?: any
  onClose?: () => void
  noRoute?: boolean
}

export const SRSuccessModal: FC<SuccessModalProps> = ({
  mainMessage,
  secondaryMessage,
  backUrl = -1,
  onClose,
  noRoute,
}) => {
  const navigate = useNavigate()

  const goToPreviousPage = () => {
    navigate(backUrl)
  }

  return (
    <SRModal onClose={noRoute ? onClose : goToPreviousPage}>
      <div className="sr-success-modal">
        <div className="sr-success-modal__item">
          <CheckmarkFilled size={32} />
        </div>
        <div className='text-base font-normal'>{mainMessage}</div>
        {secondaryMessage && <div>{secondaryMessage}</div>}
      </div>
    </SRModal>
  )
}
