import { useTranslation } from 'react-i18next'
import {
  ProfileDivider,
  ProfileDropdownContainer,
  ProfileDropdownOption,
} from './styles'
import { Credentials, Logout } from '@carbon/icons-react'
import { LANGUAGE_NAMESPACES, useRouteState } from '@shared/utils'
import { FC } from 'react'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { ProfileDropdownRouteState } from './state'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { SocialReferent } from 'libs/shared/data-access/src/lib/social-referent/types'

type ProfileDropdownProps = {
  onAction: () => void
  user?: SocialReferent | null
}

export const ProfileDropdown: FC<ProfileDropdownProps> = observer(
  ({ onAction, user = null }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { logout } = useRouteState(ProfileDropdownRouteState)

    const goToProfile = () => {
      onAction()
      navigate(ROUTE_PATH.srProfile(user?.id))
    }

    const goToLogout = async () => {
      onAction()
      await logout()
      navigate(ROUTE_PATH.login)
    }
    return (
      <ProfileDropdownContainer>
        <ProfileDropdownOption
          data-testid="profile-button"
          onClick={goToProfile}
        >
          <Credentials size={24} />
          {t('myProfile.title')}
        </ProfileDropdownOption>
        <ProfileDivider />
        <ProfileDropdownOption data-testid="logout-button" onClick={goToLogout}>
          <Logout size={24} />
          {t('logout', { ns: LANGUAGE_NAMESPACES.common })}
        </ProfileDropdownOption>
      </ProfileDropdownContainer>
    )
  },
)
