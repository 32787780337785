import { useTranslation } from 'react-i18next'
import { SRUserName } from '../sr-user-name'
import {
  UserProfileBox,
  UserProfileBoxTitle,
  UserProfileContainer,
  UserProfileContent,
  UserProfilesWrapper,
} from './styles'
import { FC, ReactNode } from 'react'
import { LanguageToggle, UserInfoBoxLabel } from '@sr/ui'

interface SRProfileProps {
  fullName: string
  image?: string
  actionButton?: ReactNode
  children: ReactNode
  isTranslated?: boolean
  activeLanguage: string | null
  toggleLanguage: () => void
}

export const SrProfile: FC<SRProfileProps> = ({
  fullName,
  image,
  actionButton,
  children,
  toggleLanguage,
  activeLanguage,
}) => {
  const { t } = useTranslation()
  const TRANSLATION_BASE_KEY = 'userArea.profile'

  return (
    <UserProfileContainer>
      <div className="flex justify-between">
        <SRUserName fullName={fullName} />
        {actionButton && actionButton}
      </div>
      <UserProfilesWrapper>
        <UserProfileContent>
          <UserProfileBoxTitle>
            {t(`${TRANSLATION_BASE_KEY}.personalData.title`)}
          </UserProfileBoxTitle>
          <UserProfileBox>
            {image && (
              <img
                className="rounded-full w-48 h-48 self-center"
                src={image}
                alt="Google"
              />
            )}
            <div className="text-center">
              <UserInfoBoxLabel>Nombre y apellidos</UserInfoBoxLabel>
              {fullName}
            </div>
          </UserProfileBox>
        </UserProfileContent>
        <UserProfileContent>
          <UserProfileBoxTitle>
            {t('common:professionalDesc')}
            <LanguageToggle
              activeLanguage={activeLanguage}
              onClick={toggleLanguage}
            />
          </UserProfileBoxTitle>
          <UserProfileBox>{children}</UserProfileBox>
        </UserProfileContent>
      </UserProfilesWrapper>
    </UserProfileContainer>
  )
}
