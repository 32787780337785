import { AnnuitySubState } from '@sr/data-access'
import { TFunction } from 'i18next'

export const rejectOptions = (t: TFunction) => [
  {
    name: t(`userArea.profile.actions.services.form.notInterested`),
    label: t(`userArea.profile.actions.services.form.notInterested`),
    value: AnnuitySubState.notInterested,
  },
  {
    name: t(`userArea.profile.actions.services.form.notAvailable`),
    label: t(`userArea.profile.actions.services.form.notAvailable`),
    value: AnnuitySubState.noShowAppointment,
  },
]
