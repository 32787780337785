import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PersonTableObject } from '../types'
import { AnnuityStateCell } from '../cells/annuity-state'
import { getDateFormatted } from '@shared/utils'
import { ANNUITY_SUB_STATE } from '@sr/data-access'

export const useCampaignTable = () => {
  const { t } = useTranslation()

  const columns = useMemo<MRT_ColumnDef<PersonTableObject>[]>(
    () => [
      {
        accessorKey: 'startDate',
        header: t('common:requestDate'),
        Cell: ({ renderedCellValue }) => (
          <div>{getDateFormatted(renderedCellValue, 'DD/MM/YYYY')}</div>
        ),
      },
      {
        accessorKey: 'person__name',
        accessorFn: (row) => row.person.name,
        header: t('common:name'),
      },
      {
        accessorKey: 'person__surname',
        accessorFn: (row) => row.person.surname,
        header: t('common:surname'),
      },
      {
        accessorKey: 'person__locality',
        accessorFn: (row) => row.person.locality,
        header: t('common:locality'),
      },
      {
        accessorKey: 'person__postalCode',
        accessorFn: (row) => row.person.postalCode,
        header: t('common:postalCode'),
      },
      {
        accessorKey: 'person__preferredLanguage',
        accessorFn: (row) => row.person.preferredLanguage,
        header: t('common:language'),
        Cell: ({ renderedCellValue }) => (
          <div>
            {renderedCellValue
              ? t(`common:languages.${renderedCellValue}`)
              : t('common:notIndicated')}
          </div>
        ),
      },
      {
        accessorKey: 'state',
        header: t('common:state'),
        Cell: ({ renderedCellValue, row }) => (
          <AnnuityStateCell state={renderedCellValue} row={row} />
        ),
      },
      {
        accessorKey: 'subState',
        header: t('common:subState'),
        Cell: ({ renderedCellValue }) => (
          <div>
            {renderedCellValue
              ? t(
                  `common:annuitySubstate.${
                    ANNUITY_SUB_STATE[
                      renderedCellValue as keyof typeof ANNUITY_SUB_STATE
                    ]
                  }`,
                )
              : ''}
          </div>
        ),
      },
      {
        accessorKey: 'socialReferent__name',
        accessorFn: (row) => row?.socialReferent?.fullName,
        header: t('common:socialReferent'),
        Cell: ({ row }) => <div>{row?.original?.socialReferent?.fullName}</div>,
      },
      {
        accessorKey: 'analysisDate',
        header: t('common:testModality.campaign'),
        Cell: ({ renderedCellValue }) => (
          <div>{getDateFormatted(renderedCellValue, 'DD/MM/YYYY')}</div>
        ),
        enableSorting: false,
      },
    ],
    [],
  )

  return { columns }
}
