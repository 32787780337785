import tw from 'tailwind-styled-components'

export const CampaignUserAgendaContainer = tw.div`
    flex
    flex-col
    gap-2
    py-10
`

export const CampaignUserAgendaHeader = tw.div`
    flex
    gap-4
`

export const campaignUserAgendaFormClasses = `
    grid
    grid-cols-12
    gap-12
`

export const CampaignUserAgendaSchedule = tw.div`
    py-6
    flex
    flex-col
    gap-4
    col-start-4
    2xl:col-start-5
    col-end-13
`

export const CampaignUserAgendaTitle = tw.div`
    text-xl
    text-dark-gray 
    font-semibold 
    leading-6
`

export const CampaignUserAgendaSubtitle = tw.div`
    text-dark-gray
`
export const campaignUserAgendaButtonClasses = `
    col-start-5
    2xl:col-start-5
    col-end-5
    2xl:col-end-7
`

export const CampaignUserAgendaModalityContainer = tw.div`
    py-6
    flex
    flex-col
    gap-4
    col-start-1
    col-end-4
`
