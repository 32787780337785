import { FC, useMemo } from 'react'
import { SrTagsWrapper } from './styles'

interface SRTagProps {
  onClick?: () => void
  children: React.ReactNode
  color: string
  disabled: boolean
  size?: 'small' | 'medium'
}

export const SrTag: FC<SRTagProps> = ({
  onClick,
  children,
  color,
  disabled,
  size = 'medium',
}) => {
  const sizeClasses = useMemo(() => {
    return size === 'small' ? 'px-3 text-sm' : 'px-4 text-base'
  }, [size])

  return (
    <button
      onClick={onClick}
      className={`${SrTagsWrapper} ${sizeClasses} ${color}`}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
