import { FEATURE_FLAG, featureFlags, useRouteState } from '@shared/utils'
import { SRErrorModal, SRTestWithScript, SrTest } from '@sr/ui'
import { FC, useEffect } from 'react'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import { STATE_BY_TEST_TYPE } from '../states'
import { observer } from 'mobx-react-lite'

type Props = {
  id: string
  name?: string
  script?: string
  scriptProps?: object
  testType: number
  autoValidate?: boolean
}

export const TestWithScript: FC<Props> = observer(
  ({ id, name, script, scriptProps, testType, autoValidate }) => {
    const routeState = STATE_BY_TEST_TYPE[testType] || {}
    const hasMockedData = featureFlags.hasFeatureFlag(FEATURE_FLAG.generic)
    const { hasError, reload, isLoading, test, updateTest, setMockedData } =
      useRouteState(routeState, {
        testId: id,
      })

    const { setIsLoading } = useLayoutContext()

    useEffect(() => {
      if (hasMockedData) {
        setMockedData()
      }
    }, [test])

    useEffect(() => {
      setIsLoading(isLoading)
    }, [isLoading])

    if (isLoading) return

    return hasError ? (
      <SRErrorModal onRetry={reload} />
    ) : (
      <SRTestWithScript
        data-testId={`test-with-script-${id}`}
        name={name}
        script={script}
        scriptProps={scriptProps}
      >
        <SrTest
          type={testType}
          test={test?.testMetaJson}
          values={test?.prevData}
          updateTest={updateTest}
          autoValidate={autoValidate}
          hideNavigation
        />
      </SRTestWithScript>
    )
  }
)
