import { TranscriptionScriptPDF } from '../../../../../sr-initial-report-pdf/components/TranscriptionScriptPDF'

export const ADDITIONAL_GUIDELINES = Object.freeze({
  recCognitiveTactSensitivity: 'rec_cognitive_tact_sensitivity',
  recCognitiveTactSensitivityDiagnosed: 'rec_cognitive_tact_sensitivity_diagnosed',
  recCognitiveHallucinations: 'rec_cognitive_hallucinations',
  recCognitiveHallucinationsDiagnosed: 'rec_cognitive_hallucinations_diagnosed',
})

export const ADDITIONAL_GUIDELINES_SENSITIVITY = {
  description: (
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionCognitive.guidelines.rec_cognitive_tact_sensitivity.description'
      }
    />
  ),
  list: [
    {
      id: 'tip-1',
      title: (
        <TranscriptionScriptPDF
          script={
            'initial-report:sectionCognitive.guidelines.rec_cognitive_tact_sensitivity.one.title'
          }
        />
      ),
      content: (
        <TranscriptionScriptPDF
          script={
            'initial-report:sectionCognitive.guidelines.rec_cognitive_tact_sensitivity.one.text'
          }
        />
      ),
    },
    {
      id: 'tip-2',
      title: (
        <TranscriptionScriptPDF
          script={
            'initial-report:sectionCognitive.guidelines.rec_cognitive_tact_sensitivity.two.title'
          }
        />
      ),
      content: (
        <TranscriptionScriptPDF
          script={
            'initial-report:sectionCognitive.guidelines.rec_cognitive_tact_sensitivity.two.text'
          }
        />
      ),
    },
  ],
}
export const ADDITIONAL_GUIDELINES_SENSITIVITY_DIAGNOSED = {
  description: (
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionCognitive.guidelines.rec_cognitive_tact_sensitivity_diagnosed.description'
      }
    />
  ),
  list: [
    {
      id: 'tip-1',
      title: (
        <TranscriptionScriptPDF
          script={
            'initial-report:sectionCognitive.guidelines.rec_cognitive_tact_sensitivity_diagnosed.one.title'
          }
        />
      ),
      content: (
        <TranscriptionScriptPDF
          script={
            'initial-report:sectionCognitive.guidelines.rec_cognitive_tact_sensitivity_diagnosed.one.text'
          }
        />
      ),
    }
  ],
}

export const ADDITIONAL_GUIDELINES_HALLUCINATIONS = {
  description: (
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionCognitive.guidelines.rec_cognitive_hallucinations.description'
      }
    />
  ),
  list: [
    {
      id: 'tip-3',
      title: (
        <TranscriptionScriptPDF
          script={
            'initial-report:sectionCognitive.guidelines.rec_cognitive_hallucinations.one.title'
          }
        />
      ),
      content: (
        <TranscriptionScriptPDF
          script={
            'initial-report:sectionCognitive.guidelines.rec_cognitive_hallucinations.one.text'
          }
        />
      ),
    },
    {
      id: 'tip-4',
      title: (
        <TranscriptionScriptPDF
          script={
            'initial-report:sectionCognitive.guidelines.rec_cognitive_hallucinations.two.title'
          }
        />
      ),
      content: (
        <TranscriptionScriptPDF
          script={
            'initial-report:sectionCognitive.guidelines.rec_cognitive_hallucinations.two.text'
          }
        />
      ),
    },
  ],
}

export const ADDITIONAL_GUIDELINES_HALLUCINATIONS_DIAGNOSED = {
  description: (
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionCognitive.guidelines.rec_cognitive_hallucinations_diagnosed.description'
      }
    />
  ),
  list: [
    {
      id: 'tip-3',
      title: (
        <TranscriptionScriptPDF
          script={
            'initial-report:sectionCognitive.guidelines.rec_cognitive_hallucinations_diagnosed.one.title'
          }
        />
      ),
      content: (
        <TranscriptionScriptPDF
          script={
            'initial-report:sectionCognitive.guidelines.rec_cognitive_hallucinations_diagnosed.one.text'
          }
        />
      ),
    }
  ],
}
