import { DateString, PersonModel, RootModel } from '@shared/data-access'
import { getRoot, types } from 'mobx-state-tree'
import {
  GENERIC_ANALYSIS_STATUS,
  GENERIC_ANALYSIS_TEST_TYPE,
  GENERIC_ANALYSIS_TYPE,
  GENERIC_ANALYSIS_TYPE_NAME,
} from './enums'

export const MODEL_NAME = 'GenericAnalysis'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    genericAnalysisDate: types.maybeNull(DateString),
    genericAnalysisType: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(GENERIC_ANALYSIS_TYPE).some((type) => type === value)
      )
    ),
    status: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(GENERIC_ANALYSIS_STATUS).some((type) => type === value)
      )
    ),
    testType: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(GENERIC_ANALYSIS_TEST_TYPE).some((type) => type === value)
      )
    ),
    person: types.maybeNull(types.reference(PersonModel))
  })
  
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
  .views((self) => ({
    get isFilled() {
      return (
        self.status === GENERIC_ANALYSIS_STATUS.filled ||
        self.status === GENERIC_ANALYSIS_STATUS.closed
      )
    },
    get testTypeName() {
      return GENERIC_ANALYSIS_TYPE_NAME[self.genericAnalysisType]
    },
  }))

export default Model
