import { FC } from 'react'
import { SearchSelect, SearchSelectProps } from '@shared/ui'
import { inputClassName } from '../styles'
import { selectClassName } from './styles'

export const SRSearchSelect: FC<SearchSelectProps> = ({
  emptyError,
  disabled,
  options,
  name,
  onChange,
  defaultLabel
}) => {
  return (
    <SearchSelect
      name={name}
      options={options}
      disabled={disabled}
      emptyError={emptyError}
      onChange={onChange}
      classNames={{ inputClassName, selectClassName }}
      defaultLabel={defaultLabel}
    />
  )
}
