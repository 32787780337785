import { isValidPhoneNumber } from 'react-phone-number-input'
import * as yup from 'yup'
export const resendCommunicationsValues = Object.freeze({
  sameEmail: 'sameEmail',
  otherEmail: 'otherEmail',
})

export const resendCommunicationsNames = Object.freeze({
  resendCommunications: 'resendCommunications',
  phone: 'phone',
  email: 'email',
})

export const emailValidation = (t: (key: string) => string) => yup
  .string()
  .nullable()
  .test(
    'email-format',
    t('common:invalidEmailFormat'),
    value => !value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
  )
  .email(t('common:invalidEmail'))
  .label(t('common:email'));

export const phoneValidation = (t: (key: string) => string) => yup
  .string()
  .nullable()
  .test(
    'is-valid',
    t('common:errors.phone'),
    value => !value || isValidPhoneNumber(value)
  );

  export const useSchema = (t: (key: string) => string) => yup
  .object({
    email: emailValidation(t),
    phone: phoneValidation(t),
  })
  .test(
    'emailOrPhoneRequired',
    t('common:errors.emailOrPhoneRequired'),
    data => {
      if (!!data.email || isValidPhoneNumber(data.phone || '')) {
        return true; // Si alguno es válido, retorna true.
      }
      return new yup.ValidationError(
        t('common:errors.emailOrPhoneRequired'), // Mensaje de error personalizado.
        null, // Valor no válido.
        'emailOrPhone' // La clave del error que será usada en la interfaz de usuario.
      );
    }
  );
