export const wrapperClassName = ({ primary, secondary }) => `
  flex
  ${secondary ? 'justify-center' : 'justify-between'}
  h-16
  px-4
  xl:px-16
  w-full
  ${primary ? 'bg-primary text-white' : ''}
  ${secondary ? 'bg-transparent text-primary' : ''}
`

export const flexCenterClassName = `
  flex
  items-center
  gap-4
  [&>svg]:cursor-pointer
`

export const logoMainClassName = `
  w-16
`

export const logoSecondaryClassName = `
  w-40
`
