import { FC, useEffect, useState } from 'react'
import { useContentSteps } from '../../../../hooks/useSteps'
import { useTranslation } from 'react-i18next'
import { Button, BUTTON_COLORS } from '../../../../../button'
import { caixaBankButton, getBrowserAndOS } from '../../../../utils'
import { BRANDING_KEYS } from '../../../../utils/branding'
import { permissionsSteps, permissionsMainWrapper } from './styles'
import { OPPreview } from '../OPPreview/OPPreview'
import { VIDEO_CALL_PAGE_VIEW, analytics } from '@shared/utils'

interface Step {
  text: string
  firstStepIcons?: string[]
  browserIcon?: string
  barImages?: string[]
  secondImages?: string[]
  thirdImages?: string[]
  anotherIcons?: string[]
  isButton?: boolean
}
interface PermissionsDeniedViewProps {
  branding: {
    branding: keyof typeof BRANDING_KEYS
  }
  setOpNumberView: (value: boolean) => void
}

export const PermissionsDeniedView: FC<PermissionsDeniedViewProps> = ({
  branding,
  setOpNumberView,
}) => {
  const { t } = useTranslation()
  const { contentStep } = useContentSteps()
  const [openModal, setOpenModal] = useState(true)
  const closeModal = () => setOpenModal(false)
  const { os, browser } = getBrowserAndOS()

  const TRANSLATION_BASE_URL = 'common:videoCall.permissionsWarning'

  useEffect(() => {
    analytics.pageView({
      path: VIDEO_CALL_PAGE_VIEW.pageViewPermissionsIssue,
    })
  }, [])

  const renderHTMLVersions = () => {
    return {
      __html: t(`${TRANSLATION_BASE_URL}.title`, {
        browserVer: browser,
        osVer: os,
      }),
    }
  }
  const renderStep = (step: Step, index: number) => {
    const {
      text,
      firstStepIcons,
      browserIcon,
      barImages,
      secondImages,
      thirdImages,
      anotherIcons,
      isButton,
    } = step

    const renderHTMLContent = () => {
      return {
        __html: t(`${TRANSLATION_BASE_URL}.${text}`, {
          iconOne: firstStepIcons?.[0],
          iconTwo: browserIcon,
          iconThree: anotherIcons?.[0],
          iconFour: anotherIcons?.[1],
          branding:
            branding?.branding === BRANDING_KEYS.VCX ? '#007EAE' : '#004039',
        }),
      }
    }

    return (
      <li
        className={`${permissionsSteps} ${
          isButton ? 'md:flex md:items-center' : ''
        } sm:border-solid sm:border-2 ${
          branding?.branding === BRANDING_KEYS.VCX
            ? 'border-caixa-primary'
            : 'border-primary'
        }`}
        key={index}
      >
        {isButton ? (
          <>
            <div dangerouslySetInnerHTML={renderHTMLContent()} />
            <div className="flex justify-center">
              <Button
                onClick={() => window.location.reload()}
                color={
                  branding?.branding === BRANDING_KEYS.VCX
                    ? BUTTON_COLORS.transparent
                    : BUTTON_COLORS.primary
                }
                className={`w-full md:ml-6 max-w-[130px] md:mt-0 md:max-w-[300px] mt-6 self-center ${
                  branding?.branding === BRANDING_KEYS.VCX && caixaBankButton
                }`}
              >
                <div className="text-xl font-medium">
                  {t('common:videoCall.reload')}
                </div>
              </Button>
            </div>
          </>
        ) : (
          <div dangerouslySetInnerHTML={renderHTMLContent()} />
        )}
        {barImages?.map((image) => (
          <div key={image} className="flex justify-center mt-6 w-full">
            <img src={image} alt="Bar" />
          </div>
        ))}
        {secondImages && (
          <div
            className={`flex mt-6 w-full ${
              secondImages.length === 1 ? 'justify-center' : 'justify-around'
            }`}
          >
            {secondImages.map((image, index, array) => (
              <img
                key={image}
                className={array.length > 1 ? 'w-full md:w-5/12' : ''}
                src={image}
                alt="Second"
              />
            ))}
          </div>
        )}

        {thirdImages && (
          <div
            className={`flex mt-6 w-full ${
              thirdImages.length === 1 ? 'justify-center' : 'justify-around'
            }`}
          >
            {thirdImages.map((image, index, array) => (
              <img
                key={image}
                className={array.length > 1 ? 'w-full md:w-5/12' : ''}
                src={image}
                alt="Third"
              />
            ))}
          </div>
        )}
      </li>
    )
  }

  if (openModal) {
    return (
      <OPPreview
        branding={branding}
        setOpNumberView={setOpNumberView}
        onClose={closeModal}
      />
    )
  }

  return (
    <div className={permissionsMainWrapper}>
      <div className="mb-6">
        <div dangerouslySetInnerHTML={renderHTMLVersions()} />
      </div>
      <ul className="row-start-2 row-end-4">
        {contentStep.map((step, index) => renderStep(step, index))}
      </ul>
    </div>
  )
}
