import { Text, View } from '@react-pdf/renderer'
import { useReportPdfContext } from '../../../provider'
import { SubSection, TranscriptionScriptPDF } from '../../../components'
import { FC } from 'react'
import { tw } from '../../../utils/styles'
import { getRecommendations } from '../../../utils'

type AuditiveAndOcularObjectivesProps = {
  sectionLetter: string
}

export const AuditiveAndOcularObjectives: FC<
  AuditiveAndOcularObjectivesProps
> = ({ sectionLetter }) => {
  const { t, recommendations, person } = useReportPdfContext()
  const { AUDITORY, OCULAR } = recommendations || { AUDITORY: [], OCULAR: [] }

  const TRANSLATION_BASE_KEY =
    'initial-report:sectionAuditiveAndOcular.objectives'

  const ocularObjectives: string[] = getRecommendations({
    recKeys: OCULAR,
    generalKey: '',
    defaultKey: 'rec_auditory_ocular_generic_vision',
  })

  const auditoryObjectives: string[] = getRecommendations({
    recKeys: AUDITORY,
    generalKey: '',
    defaultKey: 'rec_auditory_ocular_generic_vision',
  })

  // At the moment they only want to show one objective per type
  const objectives = [auditoryObjectives[0], ocularObjectives[0]]

  return (
    <View style={tw('px-12')}>
      <SubSection
        title={t('initial-report:subSection.objectives.title', {
          letter: sectionLetter,
        })}
      >
        <Text style={tw('pb-4')}>
          {t('initial-report:generic.objectives.doubleObjective')}
        </Text>

        {objectives.map((key, ix) => {
          if (!key) return null
          return (
            <View
              style={
                (tw('flex flex-row items-center gap-2 pl-4 pt-2'),
                { position: 'relative' })
              }
              key={`list-item-${ix}`}
            >
              <Text
                style={
                  (tw('font-bold'),
                  { position: 'absolute', top: '1px', left: '0' })
                }
              >
                •
              </Text>
              <TranscriptionScriptPDF
                key={`objective-${key}`}
                script={`${TRANSLATION_BASE_KEY}.objectiveList.${key}`}
                scriptProps={{ patientName: person?.name }}
                style={
                  (tw('font-bold'),
                  { position: 'relative', left: '10px', marginBottom: '10px' })
                }
              />
            </View>
          )
        })}
      </SubSection>
    </View>
  )
}
