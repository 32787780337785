import { FC } from 'react'

interface DividerProps {
  vertical?: boolean
  className?: string
}

export const Divider: FC<DividerProps> = ({ vertical = false, className }) => {
  return (
    <hr
      data-testid="Divider"
      className={`bg-gray-200 ${
        vertical ? 'w-px h-auto' : 'w-full h-px'
      } ${className}`}
    ></hr>
  )
}
