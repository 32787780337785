import { FORM_TYPE, Input, Label } from '@shared/ui'
import { FC } from 'react'

type SRRadioButtonProps = {
  name: string
  label: string
  className?: string
  value?: number | boolean | string | null 
}

export const SRRadioButton: FC<SRRadioButtonProps> = ({
  name,
  label = '',
  className = '',
  value
}) => {
  return (
    <div className={`flex items-center gap-2 py-1 ${className}`}>
      <Input
        type={FORM_TYPE.radio}
        name={name}
        className="w-4 h-4"
        id={name}
        value={value?.toString()}
      />
      <Label name={name} label={label} />
    </div>
  )
}
