import {
  ActionsComponentProps,
  TableList,
  TablePaginationProps,
} from '@shared/ui'
import { FC, ReactNode } from 'react'

type SRTableListProps = {
  data: any[]
  columns: any[]
  initialPagination: TablePaginationProps
  onPaginationChange: (props: TablePaginationProps) => void
  total: number
  actionsComponent?: ((props: ActionsComponentProps) => ReactNode[]) | undefined
  isLoading?: boolean
  onSortingChange: (props: { id: string; desc: boolean }[]) => void
  enableSelectAll?: boolean
  enableRowSelection?: boolean
  onRowSelection?: (row: string[]) => void
  enableMultiRowSelection?: boolean
  preSelectedRows?: string[] | null
}

export const SRTableList: FC<SRTableListProps> = ({
  data,
  columns,
  initialPagination,
  onPaginationChange,
  total,
  actionsComponent,
  isLoading,
  onSortingChange,
  enableSelectAll,
  enableRowSelection,
  onRowSelection,
  enableMultiRowSelection,
  preSelectedRows,
}) => {
  return (
    <TableList
      data={data}
      columns={columns}
      initialPagination={initialPagination}
      onPaginationChange={onPaginationChange}
      total={total}
      actionsComponent={actionsComponent}
      isLoading={isLoading}
      onSortingChange={onSortingChange}
      enableSelectAll={enableSelectAll}
      enableRowSelection={enableRowSelection}
      onRowSelection={onRowSelection}
      enableMultiRowSelection={enableMultiRowSelection}
      preSelectedRows={preSelectedRows}
    />
  )
}
