import { BIG_CALENDAR_VIEW } from '@shared/ui'
import { useEffect, useState } from 'react'
import { AgendaViewPickerTab } from './style'

export const AgendaViewPicker = ({ setView }) => {
  const [localView, setLocalView] = useState(BIG_CALENDAR_VIEW.workWeek)

  useEffect(() => {
    setView(localView)
  }, [localView])

  const setDay = () => setLocalView(BIG_CALENDAR_VIEW.day)
  const setWeek = () => setLocalView(BIG_CALENDAR_VIEW.workWeek)
  const setMonth = () => setLocalView(BIG_CALENDAR_VIEW.month)

  return (
    <div className="flex gap-4">
      <AgendaViewPickerTab
        data-testid="AgendaViewPickerTabDay"
        $selected={localView === BIG_CALENDAR_VIEW.day}
      >
        <button onClick={setDay}>Day</button>
      </AgendaViewPickerTab>
      <AgendaViewPickerTab
        data-testid="AgendaViewPickerTabWeek"
        $selected={localView === BIG_CALENDAR_VIEW.workWeek}
      >
        <button onClick={setWeek}>Week</button>
      </AgendaViewPickerTab>
      <AgendaViewPickerTab
        data-testid="AgendaViewPickerTabMonth"
        $selected={localView === BIG_CALENDAR_VIEW.month}
      >
        <button onClick={setMonth}>Month</button>
      </AgendaViewPickerTab>
    </div>
  )
}
