import { observer } from 'mobx-react-lite'
import { OPGenericList } from '../../common'
import { FC } from 'react'
import { PortfolioManagementOPListCampaignRouteState } from './state'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { useParams } from 'react-router-dom'
import { useRouteState } from '@shared/utils'

type ListProps = {
  filters?: any
}

export const ListCampaign: FC<ListProps> = observer(({ filters }) => {
  const { id: socialReferentId } = useParams()

  const state = useRouteState(PortfolioManagementOPListCampaignRouteState, {
    socialReferentId,
  })

  return (
    <OPGenericList
      state={state}
      onCancelUrl={ROUTE_PATH.portfolioManagementCampaign(socialReferentId)}
      filters={filters}
    />
  )
})
