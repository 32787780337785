import {
  APPOINTMENT_TYPES,
  APPOINTMENT_TYPES_TRANSLATION,
  APPOINTMENT_TYPE_ICON,
} from '@shared/data-access'
import { getDateFormatted, getToday, getWorkDayFromDate } from '@shared/utils'
import { t } from 'i18next'
import { flow } from 'mobx-state-tree'

export const stateActions = (self) => ({
  afterAttach() {
    self.loadDependencies()
  },
  loadDependencies: flow(function* loadDependencies() {
    self.setLoading('DashboardSRRouteState')
    try {
      const startDate = getDateFormatted(getToday(), 'YYYY-MM-DD')
      const endDate = getDateFormatted(
        getWorkDayFromDate(startDate, 1),
        'YYYY-MM-DD',
      )
      const appointments = self.loadAppointments({
        startDate,
        endDate,
        id: self.user?.id,
      })
      const unavailableSlots = self.loadUnavailableSlots(
        startDate,
        endDate,
        self.user?.id,
      )
      const policies = self.loadPoliciesByRequestDate(startDate, endDate)

      yield Promise.all([appointments, unavailableSlots, policies])
    } finally {
      self.removeLoading('DashboardSRRouteState')
    }
  }),
  getAppointmentListStyled(appointment) {
    let {
      appointmentType,
      startDate,
      endDate,
      policy,
      personId,
      meetingUrl,
      isInitialReport,
      isFaceToFace,
    } = appointment

    if (isInitialReport && isFaceToFace) {
      appointmentType = APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE
    }
    return {
      icon: APPOINTMENT_TYPE_ICON[appointmentType],
      text: `${getDateFormatted(startDate, 'LT')} - ${t(
        APPOINTMENT_TYPES_TRANSLATION[appointmentType],
      )}`,
      action: () =>
        self.onSelectEvent({
          appointmentType: { reference: appointmentType },
          start: startDate,
          end: endDate,
          policy,
          personId,
          meetingUrl,
        }),
    }
  },
  setOnSelectCallback({ onSelectEvent, onSelectUser }) {
    self.onSelectEvent = onSelectEvent
    self.onSelectUser = onSelectUser
  },
})
