import { FC, ReactElement } from 'react'
import { useReportPdfContext } from '../../provider'
import { LayoutPage } from '../../components'
import { AreaColor } from '../../utils'
import { SocialResults } from './results'
import { SocialObjectives } from './objectives'
import { SocialGuideLines } from './guidelines'

type SectionSocialProps = {
  children: ReactElement
  sectionNumber: string
}

export const SectionSocial: FC<SectionSocialProps> = ({
  children,
  sectionNumber,
}) => {
  const { t } = useReportPdfContext()

  return (
    <LayoutPage
      headerText={t('initial-report:sectionSocial.layout.headerText', {
        number: sectionNumber,
      })}
      noSpacing
      section={AreaColor.social}
      sectionNumber={sectionNumber}
    >
      <SocialResults sectionLetter="A" />
      <SocialObjectives sectionLetter="B" />
      <SocialGuideLines sectionLetter="C" />
      {children}
    </LayoutPage>
  )
}
