import { SrCampaignType } from '@shared/ui'
import {
  CampaignActions,
  GenericProfile,
  useLayoutContext,
} from '@sr/routes/common'
import { SRUserName } from '@sr/ui'
import { useRouteState } from '@shared/utils'
import { UserProfileCampaignRouteState } from './state'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import {
  CampaignUserProfileContainer,
  CampaignUserProfileHeader,
} from './styles'

export const CampaignUserProfile = observer(() => {
  const { id: annuityId } = useParams()
  const {
    isLoading,
    annuity,
    userPersonalData,
    isEditing,
    setEditingMode,
    saveAnnuityPerson,
    reload,
    defaultValues,
  } = useRouteState(UserProfileCampaignRouteState, {
    annuityId,
  })

  const { setIsLoading } = useLayoutContext()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  if (!annuity) return

  return (
    <CampaignUserProfileContainer>
      <CampaignUserProfileHeader>
        <SRUserName fullName={annuity?.person?.fullName} />
        <SrCampaignType annuity={annuity} />
      </CampaignUserProfileHeader>
      <GenericProfile
        personalData={userPersonalData}
        defaultValues={defaultValues}
        isEditing={isEditing}
        setEditingMode={setEditingMode}
        onSaveData={saveAnnuityPerson}
        ActionsComponent={<CampaignActions reload={reload} />}
      />
    </CampaignUserProfileContainer>
  )
})
