import { FC, ReactElement } from 'react'
import { LayoutPage } from '../../components'
import { useReportPdfContext } from '../../provider'
import { AreaColor } from '../../utils'
import { TobaccoAndAlcoholResults } from './results/results'
import { TobaccoAndAlcoholObjectives } from './objectives'

type SectionTobaccoAndAlcoholProps = {
  children: ReactElement
  sectionNumber: string
}

export const SectionTobaccoAndAlcohol: FC<SectionTobaccoAndAlcoholProps> = ({
  children,
  sectionNumber,
}) => {
  const { t } = useReportPdfContext()

  return (
    <LayoutPage
      headerText={t(
        'initial-report:sectionTobaccoAndAlcohol.layout.headerText',
        {
          number: sectionNumber,
        },
      )}
      noSpacing
      section={AreaColor.tobaccoAlcohol}
      sectionNumber={sectionNumber}
    >
      <TobaccoAndAlcoholResults sectionLetter="A" />
      <TobaccoAndAlcoholObjectives sectionLetter="B" />
      {children}
    </LayoutPage>
  )
}
