import { getWorkDayFromToday } from '@shared/utils'
import {
  EXTRA_DAY_DIGITAL_FIRST_WEEK,
  EXTRA_DAY_NO_DIGITAL_FIRST_WEEK,
  OP_EXTRA_DAY_NO_DIGITAL_FIRST_WEEK,
  OP_EXTRA_DAY_DIGITAL_FIRST_WEEK
} from './enums'
export const getFiltersObject = ({ noDigital, isSr }) => {
  const dates = noDigital
  ? isSr
    ? OP_EXTRA_DAY_NO_DIGITAL_FIRST_WEEK
    : EXTRA_DAY_NO_DIGITAL_FIRST_WEEK
  : isSr
    ? OP_EXTRA_DAY_DIGITAL_FIRST_WEEK
    : EXTRA_DAY_DIGITAL_FIRST_WEEK

  const date = getWorkDayFromToday(dates).toDate()

  return {
    date,
    noDigital
  }
}
