import { getRoot, types } from 'mobx-state-tree'
import { BaseAppointment, Policy } from '@shared/data-access'

export const MODEL_NAME = 'Appointment'

export const Model = BaseAppointment.named(MODEL_NAME)
  .props({
    id: types.identifier,
    policy: types.maybeNull(types.reference(Policy)),
    policyId: types.maybeNull(types.string),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))

export default Model
