import tw from 'tailwind-styled-components'

export const ModalContainer = tw.div`
    flex
    flex-col
    gap-6
    justify-center
    items-center
`
export const ConflictMessageContainer = tw.div`
    flex
    gap-4
    items-center
`

export const ButtonContainer = tw.div`
    flex
    gap-4
    self-end
    justify-end
    items-center
`
