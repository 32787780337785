import { Text, View } from '@react-pdf/renderer'
import { hasInnerItemsInArray, tw } from '../../utils'
import { ListItem } from '../ListItem'
import { SubSection } from '../SubSection'
import { LinkList } from '../LinkList'
import { OmsLogo } from '../OmsLogo'
import { useReportPdfContext } from '../../report-pdf-provider'
import { ReportType } from '../../../report-pdf/enums'
import { WorkSheetListPDF } from '../../../report-pdf/components'

export const FollowRecomendations = ({
  areaColor,
  followRecommendations,
  followRecommendationsText,
  linksAndWorkSheetTitle,
  linksAndWorkSheetText,
  workSheetList,
  links,
  keepInMindList,
  omsText,
  style,
  translateFrom = null,
  linksMultipleSections,
  worksheetId,
}) => {
  const { t, type, data } = useReportPdfContext()

  const portalBaseUrl = data?.results.initialAnalysisList.portal_base_url

  let newLinks = links

  let newWorksheet = workSheetList

  const parseList = ({
    list,
    translateFrom,
    isMultipleSections,
    portalBaseUrl,
    hasInnerItemsFn,
    type,
  }) => {
    if (!list || (isMultipleSections && !hasInnerItemsFn(list))) return []

    return isMultipleSections
      ? list.flatMap((key) =>
          Object.entries(key).flatMap(([key, value]) =>
            value.map((position) => ({
              text: t(`${translateFrom}.${type}.list.${key}.${position}.name`),
              description: t(
                `${translateFrom}.${type}.list.${key}.${position}.description`,
              ),
              src: t(`${translateFrom}.${type}.list.${key}.${position}.link`, {
                portalBaseUrl,
              }),
            })),
          ),
        )
      : list.map((item) => ({
          text: t(`${translateFrom}.${type}.list.${item}.name`),
          description: t(`${translateFrom}.${type}.list.${item}.description`),
          src: t(`${translateFrom}.${type}.list.${item}.link`, {
            portalBaseUrl,
          }),
        }))
  }

  if (translateFrom) {
    newLinks = parseList({
      list: links,
      translateFrom,
      isMultipleSections: linksMultipleSections,
      portalBaseUrl,
      hasInnerItemsFn: hasInnerItemsInArray,
      type: 'links',
    })
    newWorksheet = parseList({
      list: workSheetList,
      translateFrom,
      isMultipleSections: linksMultipleSections,
      portalBaseUrl,
      hasInnerItemsFn: hasInnerItemsInArray,
      type: 'workSheet',
    })
  }

  return (
    <>
      <View
        style={{
          ...tw('flex flex-col gap-6 px-12'),
          ...style,
        }}
      >
        {newWorksheet && newWorksheet.length > 0 && (
          <SubSection title={linksAndWorkSheetTitle}>
            {linksAndWorkSheetText && <Text>{linksAndWorkSheetText}</Text>}
          </SubSection>
        )}
        {ReportType[type] === ReportType.care && (
          <View style={tw('pr-12')}>
            {newLinks && newLinks.length && (
              <LinkList
                list={newLinks}
                icon="idea"
                areaColor={areaColor}
                title={t('initial-report:linksTitle')}
                translateFrom={translateFrom}
              />
            )}
          </View>
        )}
        <View style={tw('pr-12')} id={worksheetId}>
          {newWorksheet && newWorksheet.length > 0 && (
            <WorkSheetListPDF
              list={newWorksheet}
              icon="request"
              areaColor={areaColor}
              title={t('initial-report:workSheetTitle')}
              translateFrom={translateFrom}
            />
          )}
        </View>
        {keepInMindList && (
          <View break>
            <SubSection title={t('initial-report:keepInMindTitle')}>
              <ListItem list={keepInMindList} hasBreak />
            </SubSection>
          </View>
        )}

        <SubSection title={t('initial-report:followRecommendationsTitle')}>
          <View style={tw('flex flex-col gap-6')}>
            {typeof followRecommendationsText === 'string' ? (
              <Text style={tw('pt-2')}>{followRecommendationsText}</Text>
            ) : (
              followRecommendationsText
            )}
            {followRecommendations && <ListItem list={followRecommendations} />}
          </View>
        </SubSection>
      </View>
      <OmsLogo omsText={omsText} />
    </>
  )
}
