import { getRoot, types } from 'mobx-state-tree'
import { RootModel } from '../core/root-model'

export const MODEL_NAME = 'BaseConsent'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    privacyPolicy: false,
    dataProcessing: false,
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
  .views((self) => ({
    get hasSigned() {
      return self.privacyPolicy && self.dataProcessing
    },
  }))
