import { BANNER, BannerIconContainer } from './styles'
import { BANNER_TYPE } from './enums'

export const Banner = ({ children, type }) => {
  const bannerStyle = BANNER[type] || BANNER[BANNER_TYPE.info]

  return (
    <bannerStyle.container>
      <BannerIconContainer>
        <bannerStyle.icon size="50" className={bannerStyle.iconClasses} />
      </BannerIconContainer>
      <bannerStyle.textClasses data-testid="banner-children">
        {children}
      </bannerStyle.textClasses>
    </bannerStyle.container>
  )
}
