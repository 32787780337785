export const COMMUNICATION_TYPES = Object.freeze({
  CONFIRMATION: 0,
  APPOINTMENT_REMAINDER: 1,
  APPOINTMENT_MODIFICATION: 2,
  APPOINTMENT_CANCELLATION: 3,
  SCHEDULING_INVITATION: 4,
  CONFIRMATION_REQUEST: 5,
})

export const COMMUNICATION_TYPES_NAME = Object.freeze({
  [COMMUNICATION_TYPES.CONFIRMATION]: 'common:communication.confirmation',
  [COMMUNICATION_TYPES.APPOINTMENT_REMAINDER]:
    'common:communication.appointmentRemainder',
  [COMMUNICATION_TYPES.APPOINTMENT_MODIFICATION]:
    'common:communication.appointmentModification',
  [COMMUNICATION_TYPES.APPOINTMENT_CANCELLATION]:
    'common:communication.appointmentCancellation',
  [COMMUNICATION_TYPES.SCHEDULING_INVITATION]:
    'common:communication.schedulingInvitation',
  [COMMUNICATION_TYPES.CONFIRMATION_REQUEST]:
    'common:communication.confirmationRequest',
})
