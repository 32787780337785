import tw from 'tailwind-styled-components'

export const SRBigCalendarEventDayStyle = tw.div`
  flex
  flex-col
  h-full
  text-xs
  justify-between p-4
  
`

export const SRBigCalendarEventDayBlockStyle = tw.div`
  flex
  flex-row
  gap-1
`
