import { View } from '@react-pdf/renderer'
import {
  DisclaimerBanner,
  ScalePerYear,
  TableHeaderBlack,
  TranscriptionScriptPDF,
} from '../../../../components'
import { AreaColor, scoreIsNotAvailable, tw } from '../../../../utils'
import { useReportPdfContext } from '../../../../provider'
import { getAuditCPerYearList, getAuditCScaleList } from './utils'
import { AuditCScoreResult } from '../../../../scales'
import { Sex } from '@shared/data-access'

export const AuditCTable = () => {
  const context = useReportPdfContext()
  const { t, scores, person } = context

  const riskMidValue = person?.sex === Sex.male ? 5 : 4

  return (
    <View style={tw('flex flex-col gap-6')}>
      <TableHeaderBlack
        title={t(`initial-report:scores.auditC.title`)}
        list={getAuditCScaleList(context)}
      />
      <DisclaimerBanner />
      <ScalePerYear
        scales={getAuditCPerYearList(context)}
        areaColor={AreaColor.tobaccoAlcohol}
        none={
          scoreIsNotAvailable(scores?.auditCScore?.score) ||
          scores?.auditCScore.result === AuditCScoreResult.no
        }
      />
      <View>
        <TranscriptionScriptPDF
          script={`initial-report:scores.auditC.low.description`}
          scriptProps={{ riskMidValue }}
        />
        <TranscriptionScriptPDF
          script={`initial-report:scores.auditC.risk.description`}
          scriptProps={{ riskMidValue }}
        />
      </View>
    </View>
  )
}
