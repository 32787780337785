import { differenceBetweenDates } from '@shared/utils'

export const views = (self) => ({
  get myBoxCarePolicyList() {
    return [
      ...(self.noScheduledPolicies || []),
      ...(self.noDigitalPolicies || []),
    ]
      .sort((a, b) => {
        return differenceBetweenDates(a.requestDate, b.requestDate)
      })
      .map(self.myBoxCarePolicyListStyled)
  },
})
