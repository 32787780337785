import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import ChainedBackend from 'i18next-chained-backend'
import intervalPlural from 'i18next-intervalplural-postprocessor'

import moment from 'moment'
import { setLocale as setYupLocale } from 'yup'
import { useEffect, useState } from 'react'
import { LANGUAGES } from './enums'

export const setLocales = (lang) => {
  moment.locale(lang)
  window.__locale__ = lang
  document.documentElement.setAttribute('lang', lang)
  i18n.loadNamespaces(['validations']).then(() => {
    const validationJson = i18n.t('validations:errors', { returnObjects: true })
    setYupLocale(validationJson)
  })
}

export const I18NProvider = ({ ns = [], lang, children }) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const language = LANGUAGES[lang] || LANGUAGES.es
    i18n
      .use(intervalPlural)
      .use(ChainedBackend)
      .use(initReactI18next)
      .init({
        preload: [LANGUAGES.es, LANGUAGES.ca],
        ns: ['common', 'validations', ...ns],
        supportedLngs: [LANGUAGES.es, LANGUAGES.ca],
        defaultNS: ns[0],
        debug: false,
        lng: language,
        fallbackLng: language,
        returnObjects: true,
        interpolation: {
          escapeValue: false,
        },
        backend: {
          backends: [HttpBackend],
          backendOptions: [
            {
              expirationTime: 1 * 24 * 60 * 60 * 1000,
              loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
            },
          ],
        },
        react: { useSuspense: false },
      })
      .then(() => {
        setLocales(language)
        setIsLoading(false)
      })
  }, [])

  return isLoading ? null : children
}

export default I18NProvider
