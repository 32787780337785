import { useCallback } from 'react'
import { useVideoContext } from '../../VideoProvider'
import { useIsTrackEnabled } from '../useIsTrackEnabled'

/**
 * useLocalAudioToggle provides a function to mute or not the microphone,
 * as well as whether or not it is enabled and the audio track
 * @return  {Boolean} isEnabled of audio or video
 * @return  {Function} toggleAudioEnabled
 * @return  {Object} audioTrack
 */
export const useLocalAudioToggle = () => {
  const { localTracks } = useVideoContext()
  const audioTrack = localTracks.find((track) => track.kind === 'audio')
  const isEnabled = useIsTrackEnabled(audioTrack)

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable()
    }
  }, [audioTrack])

  return [isEnabled, toggleAudioEnabled, audioTrack]
}
