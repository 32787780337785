import { getRoot, types } from 'mobx-state-tree'
import { DateString, RootModel } from '@shared/data-access'

import { ServiceModel as Service } from '../service'
import { PersonModel } from '../person'

import {
  PERSON_SERVICE_STATES,
  PERSON_SERVICE_STATES_TRANSLATION,
} from './enum'

export const MODEL_NAME = 'PersonService'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    service: types.maybeNull(types.reference(Service)),
    requestDate: types.optional(DateString),
    effectiveDate: types.optional(DateString),
    resolutionDate: types.optional(DateString),
    cancellationDate: types.optional(DateString),
    person: types.maybeNull(types.reference(PersonModel)),
    personServiceState: types.maybeNull(
      types.refinement(types.integer, (value) =>
        Object.values(PERSON_SERVICE_STATES).some((type) => type === value)
      )
    ),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
  .views((self) => ({
    get personServiceStateName() {
      return PERSON_SERVICE_STATES_TRANSLATION[self.personServiceState]
    },
  }))
export default Model
