import { Images } from '@shared/assets'
import { Button } from '@shared/ui'
import { useTranslation } from 'react-i18next'
import { LoginBox, LoginContainer, LoginText, QidaLogo } from './styles'

export const Login = () => {
  const { t } = useTranslation()

  const onLogin = () => {
    window.location = process.env.NX_LOGIN_GOOGLE_URL
  }

  return (
    <LoginContainer>
      <LoginBox>
        <QidaLogo src={Images.mainLogo} alt="Qida" />
        <Button className="w-full" onClick={onLogin}>
          {t('login.button')}
        </Button>
      </LoginBox>
      <LoginText>{t('login.text')}</LoginText>
    </LoginContainer>
  )
}
