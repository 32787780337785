import { flow, types } from 'mobx-state-tree'
import { BaseRouteState, SocialReferent } from '@shared/data-access'
import { actions } from '@sr/routes/common'

export const AgendaSrSelectRouteState = BaseRouteState.named(
  'AgendaSrSelectRouteState'
)
  .props({
    socialReferent: types.maybeNull(
      types.array(types.reference(SocialReferent), [])
    ),
  })
  .views((self) => ({
    get socialReferentsListFormatted() {
      return self.socialReferent?.map(({ fullName, id }) => ({
        name: fullName,
        value: id,
      }))
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('AgendaSrSelectRouteState')
      try {
        yield self.loadSocialReferent()
      } finally {
        self.removeLoading('AgendaSrSelectRouteState')
      }
    }),
    ...actions(self),
  }))
