import { useTranslation } from 'react-i18next'
import { MeetLogo } from '../meet-logo'
import {
  TwilioCallErrorLogoText,
  TwilioCallErrorLogoWrapper,
  TwilioCallErrorScript,
  TwilioCallErrorWrapper,
} from './styles'
import { useParams } from 'react-router-dom'
import { TranscriptionScript } from '../../../test-script'
import { findError } from './enum'
import { useVideoContext } from '../../hooks'
import { BRANDING_KEYS } from '../../utils/branding'

export const TwilioCallError = () => {
  const { t } = useTranslation()

  const { branding } = useVideoContext()

  const { error } = useParams()

  const errorText = findError(error)
    ? t(`common:videoCall.errors.${error}`)
    : t(`common:videoCall.errors.generic`)

  return (
    <div className={TwilioCallErrorWrapper}>
      <div className={TwilioCallErrorLogoWrapper}>
        <MeetLogo />
        {branding?.branding === BRANDING_KEYS.VCX && (
          <p className={TwilioCallErrorLogoText}>
            {t('common:videoCall.title')}
          </p>
        )}
      </div>
      <div className={`${TwilioCallErrorScript} ${branding?.borderColor}`}>
        <TranscriptionScript script={errorText} />
      </div>
    </div>
  )
}
