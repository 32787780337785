import { FC, useEffect, useState } from 'react'
import { appointmentListContainerClasses } from './styles'
import { sortAppointments } from './enums'
import { AppointmentSection } from './appointment-section'

interface Item {
  title?: string
  type?: string
  dateHour?: string
  opName?: string
  modality?: string
  place?: string
}

interface AppointmentListProps {
  events: Item[]
  policyId?: string
  isCare?: boolean
  onClick?: (dataToModify?: { [key: string]: any }) => void
}

export const AppointmentList: FC<AppointmentListProps> = ({
  events = [],
  isCare,
  onClick,
}) => {
  const [futureAppointments, setFutureAppointments] = useState<Item[]>([])
  const [pastAppointments, setPastAppointments] = useState<Item[]>([])

  useEffect(() => {
    const { future, past } = sortAppointments(events)
    setFutureAppointments(future)
    setPastAppointments(past)
  }, [events])

  return (
    <div className={appointmentListContainerClasses} data-testid="appointment-table">
      <AppointmentSection
        appointments={futureAppointments}
        sectionTitle="appointmentManager.dates.future"
        isCare={isCare}
        onClick={onClick}
      />
      {pastAppointments.length > 0 && (
        <AppointmentSection
          appointments={pastAppointments}
          sectionTitle="appointmentManager.dates.past"
          isPast
          isCare={isCare}
        />
      )}
    </div>
  )
}
