import { BaseRouteState, SocialReferent } from '@shared/data-access'
import { flow, getEnv, getRoot, types } from 'mobx-state-tree'
import { actions } from '../common'
import moment from 'moment-timezone'

export const LayoutRouteState = BaseRouteState.named('LayoutRouteState')
  .props({
    user: types.maybeNull(types.reference(SocialReferent)),
    chatToken: types.maybeNull(types.string),
  })
  .actions((self) => ({
    afterAttach() {
      self.loadAppDependencies()
    },
    loadAppDependencies: flow(function* loadAppDependencies() {
      self.setLoading('LayoutRouteState')
      try {
        yield self.loadUserInfo()
        yield self.getTwilioToken()
      } finally {
        self.removeLoading('LayoutRouteState')
      }
    }),
    createCallRecord: flow(function* createCallRecord({
      time,
      callType,
      callReason,
      id: policyId,
    }) {
      self.setLoading('createCallRecord')
      try {
        const seconds = ((time % 60000) / 1000).toFixed(0)
        const oneMinute = 1
        const sumMinute = seconds > 0 ? oneMinute : 0
        const minutes = Math.floor(time / 60000) + sumMinute

        yield getRoot(self).PhoneCallStore.create(
          {
            callDate: moment.tz(new Date(), 'Madrid/Spain'),
            callDuration: minutes,
            callType: Number(callReason),
            callDirection: Number(callType),
          },
          {
            customUrl: `/policies/${policyId}/phone_calls`,
            withoutId: true,
          }
        )
      } finally {
        self.removeLoading('createCallRecord')
      }
    }),
    getTwilioToken: flow(function* getTwilioToken() {
      self.setLoading('getTwilioToken')
      try {
        const response = yield getEnv(self).client.get(
          `policies/get_conversation_token`
        )
        if (response?.data?.data) {
          self.chatToken = response.data.data.token
        }
      } finally {
        self.removeLoading('getTwilioToken')
      }
    }),
    ...actions(self),
  }))
