import { Image, Text, View } from '@react-pdf/renderer'
import { useReportPdfContext } from '../../report-pdf-provider'
import { areaColorKey, tw } from '../../utils'
import { SubSection } from '../../components/SubSection'
import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'
import { TitleIcon } from '../../components/TitleIcon'
import { FollowRecomendations } from '../../components/FollowRecomendations'
import bar from '../../assets/bar-white.png'
import { useMemo } from 'react'
import {
  ALCOHOL_ASSESMENT_FEEDBACK,
  ALCOHOL_KEYS,
  ALCOHOL_KEY_GUIDELINES,
  ALCOHOL_OBJETIVES,
  TOBACCO_ASSESMENT_FEEDBACK,
  TOBACCO_KEYS,
  TOBACCO_KEY_GUIDELINES,
  TOBACCO_OBJECTIVES,
  linksAlcoholAndTobacco,
  worksheetsAlcoholAndTobacco,
} from './enum'
import { Tips } from '../../components/Tips'
import { TickList } from '../../components/TickList'

export const SecondPage = () => {
  const { t, data, person, type } = useReportPdfContext()

  const areaColor = areaColorKey.blue

  const {
    initialAnalysisList: { recommendations },
  } = data.results

  const getTobaccoKey = () => {
    let tobaccoKeyIncluded = ''
    Object.values(TOBACCO_KEYS).forEach((tobaccoKey) => {
      if (recommendations.TOBACCO.includes(tobaccoKey)) {
        tobaccoKeyIncluded = tobaccoKey
      }
    })

    return tobaccoKeyIncluded
  }

  const getAlcoholKey = () => {
    let alcoholKeyIncluded = ''
    Object.values(ALCOHOL_KEYS).forEach((alcoholKey) => {
      if (recommendations.ALCOHOL.includes(alcoholKey)) {
        alcoholKeyIncluded = alcoholKey
      }
    })
    return alcoholKeyIncluded
  }

  const tobaccoAssesment = useMemo(() => {
    let feedback = ''
    let objetives = ''
    let guidelines = ''
    let key = ''

    key = getTobaccoKey()
    feedback = TOBACCO_ASSESMENT_FEEDBACK(person.name)[key]
    objetives = TOBACCO_OBJECTIVES[key]
    guidelines = TOBACCO_KEY_GUIDELINES[key]

    return {
      feedback,
      objetives,
      guidelines,
      key,
    }
  }, [recommendations])

  const alcoholAssesment = useMemo(() => {
    let feedback = ''
    let objetives = ''
    let guidelines = ''
    let key = ''

    key = getAlcoholKey()
    feedback = ALCOHOL_ASSESMENT_FEEDBACK(person.name)[key]
    objetives = ALCOHOL_OBJETIVES[key]
    guidelines = ALCOHOL_KEY_GUIDELINES[key]

    return {
      feedback,
      objetives,
      guidelines,
      key,
    }
  }, [recommendations])

  const tobaccoTickList = [
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.tobacco.list.one'
      }
    />,
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.tobacco.list.two'
      }
    />,
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.tobacco.list.three'
      }
    />,
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.tobacco.list.four'
      }
    />,
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.tobacco.list.five'
      }
    />,
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.tobacco.list.six'
      }
    />,
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.tobacco.list.seven'
      }
    />,
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.tobacco.list.eight'
      }
    />,
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.tobacco.list.nine'
      }
    />,
  ]
  const alcoholTickList = [
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.alcohol.list.one'
      }
    />,
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.alcohol.list.two'
      }
    />,
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.alcohol.list.three'
      }
    />,
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.alcohol.list.four'
      }
    />,
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionNine.followRecommendations.alcohol.list.five'
      }
    />,
  ]

  const followRecommendationsText = (
    <View style={tw('flex flex-col gap-6')}>
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionNine.followRecommendations.tobacco.description'
        }
      />
      <TickList list={tobaccoTickList} />
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionNine.followRecommendations.alcohol.description'
        }
      />
      <TickList list={alcoholTickList} />
    </View>
  )

  const keepInMind = [
    <TranscriptionScriptPDF
      script={'initial-report:sectionNine.keepInMind.one'}
    />,
    <TranscriptionScriptPDF
      script={'initial-report:sectionNine.keepInMind.two'}
    />,
  ]

  return (
    <>
      <View style={tw('px-12')}>
        <View>
          {(tobaccoAssesment.guidelines || alcoholAssesment.guidelines) && (
            <SubSection
              title={t('initial-report:sectionNine.keyGuidelinesTobacco.title')}
            >
              <View style={tw('flex flex-col gap-6')}>
                <Text>{tobaccoAssesment.guidelines?.description}</Text>
                {tobaccoAssesment.guidelines?.tipList && (
                  <>
                    <TitleIcon
                      title={t(
                        'initial-report:sectionNine.keyGuidelinesTobacco.tipListTitle',
                      )}
                      icon={<Image style={tw('w-8 h-8')} src={bar} />}
                      areaColor={areaColor}
                    />
                    {tobaccoAssesment.guidelines?.tipList.map((tip, index) => {
                      return (
                        <Tips
                          key={`tobaccoAssesment-tips-${index}`}
                          areaColor={areaColor}
                          index={index}
                          title={tip.title}
                        >
                          {tip.text}
                        </Tips>
                      )
                    })}
                  </>
                )}
              </View>
              <View style={tw('flex flex-col gap-6')} break>
                <Text style={tw('pt-6')}>
                  {alcoholAssesment.guidelines?.description}
                </Text>
                {alcoholAssesment.guidelines?.tipList && (
                  <>
                    <TitleIcon
                      title={t(
                        'initial-report:sectionNine.keyGuidelinesAlcohol.tipListTitle',
                      )}
                      icon={<Image style={tw('w-8 h-8')} src={bar} />}
                      areaColor={areaColor}
                    />
                    {alcoholAssesment.guidelines?.tipList.map((tip, index) => {
                      return (
                        <Tips
                          key={`alcoholAssesment-tips-${index}`}
                          areaColor={areaColor}
                          index={index}
                          title={tip.title}
                        >
                          {tip.text}
                        </Tips>
                      )
                    })}
                  </>
                )}
              </View>
            </SubSection>
          )}
        </View>
      </View>
      {
        <FollowRecomendations
          areaColor={areaColor}
          translateFrom="initial-report:sectionNine.linksAndWorkSheet"
          linksMultipleSections
          linksAndWorkSheetTitle={
            linksAlcoholAndTobacco({
              alcoholKey: alcoholAssesment.key,
              tobaccoKey: tobaccoAssesment.key,
            }).length
              ? t(`initial-report:generic.linksAndWorkSheet.title.${type}`)
              : null
          }
          linksAndWorkSheetText={
            linksAlcoholAndTobacco({
              alcoholKey: alcoholAssesment.key,
              tobaccoKey: tobaccoAssesment.key,
            }).length ? (
              <TranscriptionScriptPDF
                script={'initial-report:sectionNine.linksText'}
                scriptProps={{ patientName: person.name }}
              />
            ) : null
          }
          links={linksAlcoholAndTobacco({
            alcoholKey: alcoholAssesment.key,
            tobaccoKey: tobaccoAssesment.key,
          })}
          keepInMindList={keepInMind}
          followRecommendationsText={followRecommendationsText}
          omsText={t(
            'initial-report:sectionNine.followRecommendations.omsText',
          )}
          workSheetList={worksheetsAlcoholAndTobacco({
            alcoholKey: alcoholAssesment.key,
            tobaccoKey: tobaccoAssesment.key,
          })}
          worksheetId={`worksheet-tobaccoAlcohol`}
        />
      }
    </>
  )
}
