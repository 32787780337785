import { Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { FC } from 'react'

type Scale = {
  selected: boolean
  text: string
  subText?: string
}

type ScalePerYearProps = {
  scales: Scale[]
  areaColor: string
  none?: boolean
}

export const ScalePerYear: FC<ScalePerYearProps> = ({
  scales,
  areaColor,
  none,
}) => {
  const currentYear = new Date().getFullYear()

  return (
    <View break>
      <View style={tw('flex flex-row items-center')}>
        <View>
          <View style={tw('w-full h-px bg-lightGrey')}></View>
          <Text style={tw('px-2 py-1')}>{currentYear}</Text>
          <View style={tw('w-full h-px bg-lightGrey')}></View>
        </View>
        <View
          style={tw('flex flex-row justify-between items-center h-10 flex-1')}
        >
          <View style={tw('flex justify-center text-center')}></View>
          {scales.map(({ selected, text }, index) => {
            const background =
              selected && !none ? `bg-${areaColor}` : 'bg-lightGrey'

            const textColor =
              selected && !none ? 'text-white font-semibold' : 'text-gray-400'
            const height = selected && !none ? 'h-10' : 'h-8'

            return (
              <View
                key={index}
                style={tw(
                  `${background} ${height} ${textColor} flex-1 flex justify-center text-center`,
                )}
              >
                <Text>{text}</Text>
              </View>
            )
          })}
        </View>
      </View>
      <View
        style={tw(
          'flex flex-row justify-between pt-1 px-2 gap-4 items-center text-xs',
        )}
      >
        <Text style={tw('w-10')}></Text>
        {scales.map(({ selected, subText }, index) => {
          const textColor =
            selected && !none ? `text-${areaColor} font-bold` : 'text-gray-400'

          return (
            <Text
              style={tw(`flex-1 text-center ${textColor}`)}
              key={`scale-per-year-${index}`}
            >
              {(!none && subText) || ''}
            </Text>
          )
        })}
      </View>
    </View>
  )
}
