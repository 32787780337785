import { useCallback, useState } from 'react'
import { useVideoContext } from '../../VideoProvider'
import { notify } from '@shared/ui'
/**
 * useLocalVideoToggle provides a function, to turn off or not the camera,
 * in addition to whether it is enabled or not
 * @return  {Boolean} videoTrack
 * @return  {Function} toggleVideoEnabled
 */
export const useLocalVideoToggle = () => {
  const { room, localTracks, getLocalVideoTrack, removeLocalVideoTrack } =
    useVideoContext()

  const localParticipant = room?.localParticipant
  const videoTrack = localTracks.find(
    (track) => !track.name.includes('screen') && track.kind === 'video'
  )
  const [isPublishing, setIspublishing] = useState(false)

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication =
          localParticipant?.unpublishTrack(videoTrack)

        localParticipant?.emit('trackUnpublished', localTrackPublication)
        removeLocalVideoTrack()
      } else {
        setIspublishing(true)
        getLocalVideoTrack()
          .then((track) =>
            localParticipant?.publishTrack(track, { priority: 'low' })
          )
          .catch((err) => notify.error(err))
          .finally(() => {
            setIspublishing(false)
          })
      }
    }
  }, [
    videoTrack,
    localParticipant,
    getLocalVideoTrack,
    isPublishing,
    removeLocalVideoTrack,
  ])

  return [!!videoTrack, toggleVideoEnabled]
}
