import { getRoot, types } from 'mobx-state-tree'
import { RootModel } from '@shared/data-access'

export const MODEL_NAME = 'ConversationMetaData'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    unreadMessagesCount: types.maybeNull(types.number),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
