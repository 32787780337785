import { SrFollowUpReportPdf } from '@shared/ui'
import { FC } from 'react'
import { ReportData } from '../../types'
import { ReportType } from '../../enums'

type FollowUpReportProps = {
  data: ReportData
  type: ReportType
}

export const FollowUpReport: FC<FollowUpReportProps> = ({ data, type }) => {
  return <SrFollowUpReportPdf data={data} type={type} />
}
