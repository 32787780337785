import { SRWizard } from '@sr/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type OnChangePageParams = {
  activePage: React.ReactElement
  prevPage: React.ReactElement
}

interface TestProps {
  id: string
  name?: string
  script?: string
  scriptProps?: object
  testType: number
  testOrder: number
  autoValidate?: boolean
}

interface Test extends TestProps {
  Component: FC<TestProps>
}

type Props = {
  testList: Test[]
  children?: null | React.ReactNode
  activePageNumber?: number
  onChangePage?: (pages: OnChangePageParams) => void
  onComplete?: () => void
  generateInform?:boolean
}

export const GenericAnalysis: FC<Props> = ({
  testList = [],
  children = null,
  activePageNumber = 0,
  onChangePage,
  onComplete,
  generateInform,
}) => {
  const { t } = useTranslation()

  if (!testList.length) return <div>{t('analysis:noDisposableTests')}</div>

  return (
    <SRWizard
      timeline
      activePageNumber={activePageNumber}
      onChangePage={onChangePage}
      onComplete={onComplete}
      completeText={t('analysis:generateInformButton')}
      generateInform={generateInform}
    >
      {testList.map(
        ({
          id,
          name,
          script,
          scriptProps,
          testType,
          Component,
          testOrder,
          autoValidate,
        }) => {
          return (
            <Component
              id={id}
              key={`ga-test-${testType}`}
              name={name && t(name)}
              script={script && t(script)}
              scriptProps={scriptProps && scriptProps}
              testType={testType}
              testOrder={testOrder}
              autoValidate={autoValidate}
            />
          )
        }
      )}
      {children}
    </SRWizard>
  )
}
