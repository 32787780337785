import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useUpdateHistory = () => {
  const location = useLocation()

  useEffect(() => {
    const updateHistory = (path) => {
      const history =
        JSON.parse(localStorage.getItem('navigationHistory')) || []
      history.push(path)
      localStorage.setItem('navigationHistory', JSON.stringify(history))
    }

    updateHistory(location.pathname)
  }, [location])
  useEffect(() => {
    localStorage.setItem(
      'navigationHistory',
      JSON.stringify([location.pathname]),
    )
  }, [])
}
