import { useTranslation } from 'react-i18next'
import { SubmitHandler, UseFormHandleSubmit } from 'react-hook-form'

import { getLocalStorageItem, setLocalStorageItem } from '@shared/utils'

import { Button } from '@shared/ui'
import { ScheduleBanner } from '../schedule-banner'
import { CarouselAgenda } from '../carousel-agenda/CarouselAgenda'

import { FORM_NAME } from '../../enums'
import { ScheduleAgendaProps, FormValues } from '../../interfaces'

interface ExtendedScheduleAgendaProps extends ScheduleAgendaProps {
  handleSubmit: UseFormHandleSubmit<FormValues>;
  onSubmit: SubmitHandler<FormValues>;
}

export const ScheduleAgendaLayout: React.FC<ExtendedScheduleAgendaProps> = ({
  handleSubmit,
  noDigital,
  dateSelected,
  firstDateSelected,
  previousDate,
  onSubmit,
  schedules,
  buttonText,
  isMod,
  setModifyDate,
  modifyDate,
}) => {

  const { t } = useTranslation()

  if (!schedules) return null

  if (dateSelected !== null) {
    setLocalStorageItem('dateSelected', dateSelected)
  } else {
    dateSelected = getLocalStorageItem('dateSelected')
  }

  const modifyDateAsDate = modifyDate ? new Date(modifyDate) : null

  return (
    <div className='py-9 col-start-5 col-end-13' data-testid='disposable-schedule'>
      <div className='mb-3 font-semibold text-xl leading-6 text-dark-gray'>{t('schedule.agenda.title')}</div>
      <CarouselAgenda
        dateBannerClasses='bg-modal-overlay mb-9'
        noDigital={noDigital}
        schedules={schedules}
        dateSelected={dateSelected || getLocalStorageItem('dateSelected')}
        previousDate={previousDate}
        fieldName={FORM_NAME.firstAppointmentDate}
        isMod={isMod}
        modifyDate={modifyDateAsDate}
        setModifyDate={setModifyDate}
        isSr={true}
      />
      {/* Banner */}
      {(dateSelected || modifyDate || firstDateSelected) && (
        <ScheduleBanner
          dateBannerClasses='bg-modal-overlay mb-9'
          date={dateSelected}
          appointmentDateWatched={dateSelected}
          noDigital={noDigital}
          isMod={isMod}
        />
      )}
      {/* Submit Button */}
      {(dateSelected || modifyDate || firstDateSelected) && (
        <Button onClick={handleSubmit(onSubmit)}>{t(buttonText)}</Button>
      )}
    </div>
  )
}
