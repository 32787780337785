import { Document, Page, Text, View } from '@react-pdf/renderer'

export const PreviewError = () => {
  return (
    <Document title="Preview unavailable">
      <Page>
        <View>
          <Text>No se ha podido previsualizar</Text>
        </View>
      </Page>
    </Document>
  )
}
