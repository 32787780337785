import { areaColorKey } from '../../utils'
import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'
import { FollowRecomendations } from '../../components/FollowRecomendations'
import { useReportPdfContext } from '../../report-pdf-provider'

export const SecondPage = () => {
  const { t, person, type } = useReportPdfContext()

  const areaColor = areaColorKey.green

  const keepInMind = [
    <TranscriptionScriptPDF script="initial-report:sectionFour.linksAndWorkSheet.keepInMind.one" />,
    <TranscriptionScriptPDF script="initial-report:sectionFour.linksAndWorkSheet.keepInMind.two" />,
  ]
  const workSheet = [0, 1, 2]
  const linksList = [0, 1, 2, 3, 4, 5, 6, 7, 8]

  return (
    <FollowRecomendations
      translateFrom={'initial-report:sectionFour.linksAndWorkSheet'}
      areaColor={areaColor}
      linksAndWorkSheetTitle={t(
        `initial-report:generic.linksAndWorkSheet.title.${type}`,
      )}
      linksAndWorkSheetText={
        <TranscriptionScriptPDF
          script={`initial-report:sectionFour.linksAndWorkSheet.text.${type}`}
          scriptProps={{ patientName: person.name }}
        />
      }
      links={linksList}
      keepInMindList={keepInMind}
      followRecommendationsText={t(
        'initial-report:sectionFour.linksAndWorkSheet.followRecommendations.text',
      )}
      omsText={t(
        'initial-report:sectionFour.linksAndWorkSheet.followRecommendations.omsText',
      )}
      workSheetList={workSheet}
      worksheetId={`worksheet-nutritional`}
    />
  )
}
