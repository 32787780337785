import tw from 'tailwind-styled-components'

export const ModalConfirmUnsuscribeWrapper = tw.div`
flex flex-col justify-center items-center gap-8 h-72
`
export const ModalConfirmUnsuscribeContent = tw.div`
flex items-center gap-2
`

export const ModalConfirmUnsuscribeBoldText = tw.span`
    font-semibold
`
