import { Button } from '@shared/ui'
import { AddButtonClasses } from './styles'
import { Add } from '@carbon/icons-react'

export const SRAddButton = ({ action, disabled = false, className = '' }) => {
  return (
    <Button
      testId="add-button"
      rounded
      className={`${AddButtonClasses} ${className}`}
      onClick={action}
      disabled={disabled}
    >
      <Add size={36} />
    </Button>
  )
}
