import { useEffect, useRef } from 'react'
import { useMediaStreamTrack, useVideoTrackDimensions } from '../../../../hooks'
import { Images, useVideoContext } from '@shared/ui'
import { BRANDING_KEYS } from '../../../../utils/branding'

export const VideoTrack = ({
  track,
  isLocal,
  videoPriority,
  className,
  showLogo,
}) => {
  const ref = useRef(null)
  const mediaStreamTrack = useMediaStreamTrack(track)
  const dimensions = useVideoTrackDimensions(track)
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0)
  const { branding } = useVideoContext()

  useEffect(() => {
    if (track) {
      const el = ref.current
      el.muted = true
      if (track.setPriority && videoPriority) {
        track.setPriority(videoPriority)
      }
      track.attach(el)
      return () => {
        track.detach(el)

        // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
        el.srcObject = null
        if (track.setPriority && videoPriority) {
          // Passing `null` to setPriority will set the track's priority to that which it was published with.
          track.setPriority(null)
        }
      }
    }
  }, [track, videoPriority])

  const isFrontFacing =
    mediaStreamTrack?.getSettings().facingMode !== 'environment'

  const style = {
    width: '100%',
    height: '100%',
    transform: isLocal && isFrontFacing ? 'scaleX(-1)' : '',
    objectFit:
      isPortrait || track?.name.includes('screen') ? 'contain' : 'cover',
  }

  const isVCX = branding?.branding === BRANDING_KEYS.VCX

  const logoStyle = {
    position: 'absolute',
    top: isVCX ? '20px' : '',
    right: isVCX ? '20px' : '',
    bottom: isVCX ? '' : '20px',
    left: isVCX ? '' : '20px',
    transform: isLocal && isFrontFacing ? 'scaleX(-1)' : '',
    width: '100px',
  }
  return showLogo ? (
    <div className="relative w-full h-full">
      <video
        data-testid="VideoTrack"
        className={className}
        ref={ref}
        style={style}
      />
      <img
        src={isVCX ? Images.CaixaLogoPNG : Images.MainLogoWhite}
        alt={isVCX ? 'Vidacaixa' : 'Qida'}
        style={logoStyle}
      />
    </div>
  ) : (
    <video
      data-testid="VideoTrack"
      className={className}
      ref={ref}
      style={style}
    />
  )
}
