import { GenericAnalysisTest } from '@sr/data-access'
import { flow, types } from 'mobx-state-tree'
import { actionsAnalysis } from '@sr/routes/common'
import { BaseRouteState } from '@shared/data-access'

export const GenericTestRouteState = BaseRouteState.named(
  'GenericTestRouteState'
)
  .props({
    testId: types.string,
    test: types.maybeNull(types.reference(GenericAnalysisTest)),
    hasError: false,
  })
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('GenericTestRouteState')
      try {
        yield self.getTestById()
      } finally {
        self.removeLoading('GenericTestRouteState')
      }
    }),
    ...actionsAnalysis(self),
  }))
