import { flow, types } from 'mobx-state-tree'
import { actions, actionsCampaign } from '@sr/routes/common'
import { t } from 'i18next'
import { getDateFormatted } from '@shared/utils'
import {
  APPOINTMENT_MODALITY_TRANSLATION,
  APPOINTMENT_TYPES_TRANSLATION,
  BaseRouteState,
  PersonService,
} from '@shared/data-access'
import { AnnuityModel, Appointment } from '@sr/data-access'

export const CampaignAppointmentManagerRouteState = BaseRouteState.named(
  'CampaignAppointmentManagerRouteState',
)
  .props({
    annuity: types.maybeNull(types.reference(AnnuityModel)),
    annuityId: types.maybeNull(types.string),
    appointments: types.maybeNull(types.array(types.reference(Appointment))),
    personId: types.maybeNull(types.string),
    personServices: types.maybeNull(
      types.array(types.reference(PersonService)),
    ),
  })
  .views((self) => ({
    get appointmentsFiltered() {
      return self.appointments?.map(
        ({ startDate, appointmentType, appointmentModality }) => {
          if (!startDate && !appointmentType) return {}
          return {
            dateHour: getDateFormatted(startDate, 'LLLL'),
            type: t(APPOINTMENT_TYPES_TRANSLATION[appointmentType]),
            opName: `${self.annuity?.socialReferent?.name} ${self.annuity?.socialReferent?.surname}`,
            modality: t(APPOINTMENT_MODALITY_TRANSLATION[appointmentModality]),
            place: '',
            dataToModify: {
              appointmentType: appointmentType,
            },
          }
        },
        [],
      )
    },
    get firstServiceId() {
      return self.personServices?.[0]?.id
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadMonitoringAgendaDependencies()
    },
    loadMonitoringAgendaDependencies: flow(
      function* loadMonitoringAgendaDependencies() {
        self.setLoading('AppointmentManagerRouteState')
        try {
          yield self.loadAnnuity()
          yield self.loadAnnuityAppointment()
          yield self.loadPersonServices()
        } finally {
          self.removeLoading('AppointmentManagerRouteState')
        }
      },
    ),
    ...actionsCampaign(self),
    ...actions(self),
  }))
