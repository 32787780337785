import { AddressProps } from '@sr/routes/common/generic-profile/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const ReadOnlyFields: FC<AddressProps> = ({
  street,
  postalCode,
  locality,
  region,
  country,
}) => {
  const { t } = useTranslation()
  const isEmpty = !street && !postalCode && !locality && !region && !country

  if (isEmpty) return <div>{t('common:notIndicated')}</div>

  return (
    <>
      <div className='w-full'>
        {street && <div className='w-full break-words'>{`${street}`}</div>}
        {locality && region && <div className='break-words'><span>{`${postalCode}, ${locality}, ${region}`}</span></div>}
        {country && <span>{country}</span>}
      </div>
    </>
  )
}
