import tw from 'tailwind-styled-components'

export const ScriptPreControlContainer = tw.div`
    mx-3
    whitespace-pre-line
    leading-6
    text-justify
`
export const ScriptBlue = tw.span`
    text-sky-500
`

export const ScriptOl = tw.ol`
    list-decimal
    ml-8
    mb-8
`
