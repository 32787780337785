import tw from 'tailwind-styled-components'

export const ControlTestScriptContainer = tw.div`
    grid
    grid-cols-12
`

export const TestScript = tw.div`
    col-start-1
    col-end-4
    xl:col-end-5
    px-6
    whitespace-pre-line
    text-sm
    xl:text-base
    border-r
    border-zinc-300
    leading-[50px]
`

export const TestContainer = tw.div`
   ${({ $hasScript }) =>
     $hasScript
       ? 'col-start-4 xl:col-start-5 col-end-13'
       : 'md:col-start-1 md:col-end-13 xl:col-start-3 xl:col-end-11'}
`

export const ScriptOl = tw.ol`
    list-decimal
    ml-4
    mb-4
    [counter-reset:section]
`

export const ScriptLi = tw.li`
  pl-2
  [counter-increment:section]
  marker:[content:counters(section,'.')]
`

export const ScriptGray = tw.div`
  text-zinc-400
`

export const ScriptBlue = tw.span`
    text-sky-500
`
