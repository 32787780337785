import { View } from '@react-pdf/renderer'
import { FC } from 'react'
import {
  Banner,
  SubSection,
  TranscriptionScriptPDF,
} from '../../../../components'
import { useReportPdfContext } from '../../../../provider'
import { AreaColor, tw } from '../../../../utils/styles'
import { getRecommendations } from '../../../../utils'

type PfeifferObjectivesProps = {
  sectionLetter: string
}

export const PfeifferObjectives: FC<PfeifferObjectivesProps> = ({
  sectionLetter,
}) => {
  const { t, recommendations, person } = useReportPdfContext()

  const TRANSLATION_BASE_KEY = 'initial-report:sectionCognitive.objectives'

  const defaultKey = 'rec_cognitive_pfeiffer_not_available'

  const objectives = getRecommendations({
    recKeys: recommendations?.COGNITIVE,
    generalKey: '',
    defaultKey,
  })

  const bannerText = objectives[0].includes('diagnosed')
    ? 'diagnosedText'
    : 'common'

  return (
    <>
      <View style={tw('px-12')}>
        <SubSection
          title={t('initial-report:subSection.objectives.title', {
            letter: sectionLetter,
          })}
        >
          {objectives.sort().map((key) => {
            if (!key) return null

            return (
              <TranscriptionScriptPDF
                key={`objective-${key}`}
                script={`${TRANSLATION_BASE_KEY}.objectiveList.pfeiffer.${key}`}
                scriptProps={{ patientName: person?.name }}
              />
            )
          })}
        </SubSection>
      </View>

      <Banner
        areaColor={AreaColor.cognitive}
        title={t(`${TRANSLATION_BASE_KEY}.banner.${bannerText}`)}
      />
    </>
  )
}
