import { FC, useMemo, useState } from 'react'
import {
  NotificationItem,
  NotificationItemType,
} from './components/notification-item'
import {
  SRNotificationsTableContainer,
  SRNotificationsTableContent,
  SRNotificationsTableHeader,
  SRNotificationsTableTitle,
  SRNotificationsTableTabsContainer,
  SRNotificationsTableTabsButton,
} from './styles'
import { useTranslation } from 'react-i18next'
import { FilterType } from './enums'
import { observer } from 'mobx-react-lite'

type SRNotificationTableProps = {
  list: NotificationItemType[]
  portable?: boolean
  reload: () => void
  updateItemMessages: ({
    roomSid,
    count,
  }: {
    roomSid: string
    count: number
  }) => void
}

export const SRNotificationTable: FC<SRNotificationTableProps> = observer(
  ({ list, portable = false, reload, updateItemMessages }) => {
    const [filter, setFilter] = useState(FilterType.all)
    const { t } = useTranslation()

    const onClickFilter = (type: FilterType) => {
      if (filter === type) return
      setFilter(type)
    }

    const unreadList = useMemo(() => {
      return list.filter(({ unreadMessagesCount }) =>
        Boolean(unreadMessagesCount),
      )
    }, [filter, list])

    const List = {
      [FilterType.all]: list,
      [FilterType.unread]: unreadList,
    }

    return (
      <SRNotificationsTableContainer $portable={portable}>
        <SRNotificationsTableHeader $portable={portable}>
          <SRNotificationsTableTitle>
            {t('notifications.chat.title')}
          </SRNotificationsTableTitle>
          <SRNotificationsTableTabsContainer>
            <SRNotificationsTableTabsButton
              $isSelected={filter === FilterType.all}
              onClick={() => onClickFilter(FilterType.all)}
            >
              {t('notifications.chat.all', { number: list.length })}
            </SRNotificationsTableTabsButton>
            <SRNotificationsTableTabsButton
              $isSelected={filter === FilterType.unread}
              onClick={() => onClickFilter(FilterType.unread)}
            >
              {t('notifications.chat.unread', { number: unreadList.length })}
            </SRNotificationsTableTabsButton>
          </SRNotificationsTableTabsContainer>
        </SRNotificationsTableHeader>

        <SRNotificationsTableContent $portable={portable}>
          {List[filter].length ? (
            List[filter].map((item) => (
              <NotificationItem
                key={item.sid}
                item={item}
                reload={reload}
                updateItemMessages={updateItemMessages}
              />
            ))
          ) : (
            <div className="p-3"> {t('notifications.chat.emptyList')}</div>
          )}
        </SRNotificationsTableContent>
      </SRNotificationsTableContainer>
    )
  },
)
