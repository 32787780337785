import { FC, useEffect, useMemo, useState, forwardRef, Ref } from 'react'
import { ButtonLoadingSvg } from './button-loading'
import { buttonClasses } from './button-classes'
import {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
  BUTTON_VARIANTS,
} from './enum'

interface ButtonProps {
  loading?: boolean
  className?: string
  children?: React.ReactNode
  type?: BUTTON_TYPES
  color?: BUTTON_COLORS
  variant?: BUTTON_VARIANTS
  size?: BUTTON_SIZES
  onClick?: () => void
  testId?: string
  rounded?: boolean
  disabled?: boolean
  contentClassName?: string
  ref?: Ref<HTMLButtonElement>
}

export const Button: FC<ButtonProps> = forwardRef((props, ref) => {
  const {
    loading = false,
    className = '',
    children,
    type,
    color = BUTTON_COLORS.primary,
    variant = BUTTON_VARIANTS.contained,
    size = BUTTON_SIZES.medium,
    onClick,
    testId = null,
    rounded = false,
    disabled = false,
    contentClassName = '',
  } = props

  const [localLoading, setLocalLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setLocalLoading(false)
      }, 1500)
    } else {
      setLocalLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const classes: string = useMemo(
    () =>
      buttonClasses({
        className,
        rounded,
        variant,
        color,
        size,
      }),
    [className, rounded, variant],
  )

  return (
    <button
      ref={ref}
      data-testid={testId}
      type={type || BUTTON_TYPES.button}
      className={classes}
      onClick={onClick}
      disabled={disabled}
    >
      <div
        className={`flex justify-center items-center gap-4 ${contentClassName}`}
      >
        {children}
        {localLoading && <ButtonLoadingSvg size={size} />}
      </div>
    </button>
  )
})
