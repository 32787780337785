import { Text, View } from '@react-pdf/renderer'
import { FC } from 'react'
import { tw } from '../../utils'
import { Bulb } from '../../assets/global/bulb'

type ColoredTableProps = {
  title: string
  content: string | JSX.Element
  color: string
  icon?: JSX.Element
}

export const ColoredTable: FC<ColoredTableProps> = ({
  title,
  content,
  color,
  icon,
}) => {
  return (
    <View style={tw('mb-4')}>
      <View
        style={tw(
          `p-4 flex flex-row justify-between text-white items-center text-lg font-semibold bg-${color}`,
        )}
      >
        <Text style={tw('leading-5')}>{title}</Text>
        {icon ? icon : <Bulb height="20" width="20" />}
      </View>
      <View style={tw(`bg-${color}Light p-4`)}>{content}</View>
    </View>
  )
}
