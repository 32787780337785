import tw from 'tailwind-styled-components'

export const SummaryContainer = tw.div`
    p-8
    pl-40
    font-medium
`

export const SummaryBoxContainer = tw.div`
    flex
    items-center
    justify-between
`

export const SummaryBoxResultSuccess = tw.span`
    text-success
`

export const SummaryBoxResultError = tw.span`
    text-error  
`

export const SummaryTotal = tw(SummaryBoxContainer)`
    mt-14
    px-4
    py-5
    border
    border-primary
    bg-gainsboro
    rounded-md
    mb-28
`

export const SummaryTotalSuccess = tw(SummaryBoxResultSuccess)`
    uppercase
`

export const SummaryTotalError = tw(SummaryBoxResultError)`
    uppercase
`

export const SummaryActions = tw.div`
    flex
    justify-end
    gap-6
`
