import { SRCalendar } from '@sr/ui'
import { AgendaLegend, AgendaSrSelect, AgendaViewPicker } from './components'
import { AgendaAsideContainer } from './styles'

export const AgendaAside = ({
  setView,
  smallCalendarDate,
  onChangeDate,
  isCoordinator,
}) => {
  return (
    <AgendaAsideContainer>
      <AgendaViewPicker setView={setView} />
      {isCoordinator && <AgendaSrSelect />}
      <SRCalendar date={smallCalendarDate} onChange={onChangeDate} />
      <AgendaLegend />
    </AgendaAsideContainer>
  )
}
