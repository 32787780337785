import { Sex } from '@shared/data-access'
import { ReportPdfContextProps } from '../../../../provider'
import { AuditCScoreResult } from '../../../../scales'
import { scoreIsNotAvailable } from '../../../../utils'

export const getAuditCScaleList = (context: ReportPdfContextProps) => {
  const { t, testDate, scores, person } = context
  const { score, scale, scoreName } = scores?.auditCScore || {}

  return [
    {
      label: t('initial-report:generic.resultTable.scaleDescription'),
      content: t('initial-report:scores.auditC.description'),
    },
    {
      label: t('initial-report:generic.resultTable.testDate'),
      content: testDate,
    },
    {
      label: t('initial-report:generic.resultTable.result'),
      content: scoreIsNotAvailable(score)
        ? t('initial-report:resultNotAvailable.auditC', {
            patientName: person?.name,
          })
        : t(`initial-report:scores.auditC.${scoreName}.value`, {
            scale,
          }),
    },
  ]
}

export const getAuditCPerYearList = (context: ReportPdfContextProps) => {
  const { t, scores, person } = context
  const { result } = scores?.auditCScore || {}
  const isMale = Boolean(person?.sex === Sex.male)

  return [
    {
      text: isMale ? '0-4' : '0-3',
      subText: t('initial-report:scores.auditC.scaleValues.low'),
      selected: result === AuditCScoreResult.low,
    },
    {
      text: isMale ? '5-12' : '4-12',
      subText: t('initial-report:scores.auditC.scaleValues.risk'),
      selected: result === AuditCScoreResult.risk,
    },
  ]
}
