import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  BackButton,
  LogoHeader,
  LOGO_HEADER_TYPE,
  NotificationsContainer,
} from '@shared/ui'
import {
  ROUTE_NAME,
  ROUTE_PATH,
  campaignRoute,
  userAreaRoute,
} from 'apps/social-referent/src/routes/enums'
import { useTranslation } from 'react-i18next'
import { HeaderActions, RoleChip } from './components'
import { MessagesProvider, useRouteState } from '@shared/utils'
import { LayoutRouteState } from './state'
import { observer } from 'mobx-react-lite'
import { LayoutContent } from './styles'
import { LayoutCallLog } from './components/LayoutCallLog'
import { extractPathname } from './utils'
import { useEffect } from 'react'
import { useLayoutContext } from '../common'
import { SrLoading } from '@sr/ui'
import { useUpdateHistory } from '@shared/ui'

export const Layout = observer(() => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { id } = useParams()
  useUpdateHistory()

  const navigate = useNavigate()

  const { user, createCallRecord, chatToken } = useRouteState(LayoutRouteState)

  const { backButtonText, setBackButtonText, isLoading, bgNeutral } =
    useLayoutContext()

  useEffect(() => {
    const pathnameExtracted = extractPathname(pathname).find((item) => {
      return Object.keys(ROUTE_NAME).includes(item) ? item : null
    })

    if (pathname === '/') {
      setBackButtonText('')
    }

    if (pathnameExtracted) {
      setBackButtonText(t(ROUTE_NAME[pathnameExtracted]))
    }
  }, [pathname, backButtonText])

  const isUserArea = extractPathname(pathname).includes(userAreaRoute)
  const isCampaign = extractPathname(pathname).includes(campaignRoute)
  const canUseCallLog = isUserArea && !isCampaign && id

  if (!user) return

  const roleChip = user?.isCoordinator ? (
    <RoleChip label={t('layout.role')} />
  ) : null

  return (
    <MessagesProvider
      token={chatToken}
      messagesPath={ROUTE_PATH.monitoringChat}
    >
      <NotificationsContainer />
      <SrLoading loading={isLoading} />
      <LogoHeader
        type={LOGO_HEADER_TYPE.primary}
        actionsComponent={<HeaderActions user={user} />}
        middleComponent={roleChip}
        onClickLogo={() => navigate(ROUTE_PATH.home)}
      />

      {backButtonText && (
        <BackButton text={backButtonText} containerClasses="lg:px-16" />
      )}
      <LayoutContent $hasTitle={backButtonText} $bgNeutral={bgNeutral}>
        <Outlet />
        {canUseCallLog && (
          <LayoutCallLog
            onEnd={({ time, callReason, callType }) =>
              createCallRecord({ time, callReason, callType, id })
            }
          />
        )}
      </LayoutContent>
    </MessagesProvider>
  )
})
