import { flow, types } from 'mobx-state-tree'
import { BaseRouteState, SocialReferent } from '@shared/data-access'
import { actions } from '@sr/routes/common'
import { t } from 'i18next'

export const SrListRouteState = BaseRouteState.named('SrListRouteState')
  .props({
    socialReferentId: types.maybeNull(types.string),
    socialReferent: types.maybeNull(
      types.array(types.reference(SocialReferent))
    ),
  })
  .views((self) => ({
    get parsedSocialReferent() {
      return self.socialReferent?.map((sr) => {
        return {
          ...sr,
          fullName: sr.fullName,
          state: sr.isActive
            ? t('common:employeeState.active')
            : t('common:employeeState.inactive'),
        }
      }, [])
    },
  }))

  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('SrListRouteState')
      try {
        yield self.loadSocialReferent()
      } finally {
        self.removeLoading('SrListRouteState')
      }
    }),
    setSearch: flow(function* setSearch(search) {
      self.search = search
      yield self.loadDependencies()
    }),
    ...actions(self),
  }))
