import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PersonTableObject } from '../types'
import { getDateFormatted } from '@shared/utils'

export const usePortfolioCampaignTable = () => {
  const { t } = useTranslation()

  const columns = useMemo<MRT_ColumnDef<PersonTableObject>[]>(
    () => [
      {
        accessorKey: 'person__name',
        accessorFn: (row) => row.person.name,
        header: t('common:name'),
      },
      {
        accessorKey: 'person__surname',
        accessorFn: (row) => row.person.surname,
        header: t('common:surname'),
      },
      {
        accessorKey: 'person__locality',
        accessorFn: (row) => row.person.locality,
        header: t('common:locality'),
      },
      {
        accessorKey: 'person__postalCode',
        accessorFn: (row) => row.person.postalCode,
        header: t('common:postalCode'),
      },
      {
        accessorKey: 'person__preferredLanguage',
        accessorFn: (row) => row.person.preferredLanguage,
        header: t('common:language'),
        Cell: ({ renderedCellValue }) => (
          <div>
            {renderedCellValue
              ? t(`common:languages.${renderedCellValue}`)
              : t('common:notIndicated')}
          </div>
        ),
      },
      {
        accessorKey: 'analysisDate',
        header: t('common:testModality.campaign'),
        Cell: ({ renderedCellValue }) => (
          <div>{getDateFormatted(renderedCellValue, 'DD/MM/YYYY')}</div>
        ),
        enableSorting: false,
      },
    ],
    [],
  )

  return { columns }
}
