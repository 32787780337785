import { View } from '@react-pdf/renderer'
import { FC } from 'react'
import { tw } from '../../../utils'
import { SubSection } from '../../../components'
import { useReportPdfContext } from '../../../provider'
import { FagerstromTable } from './fagerstrom'
import { RichmondTable } from './richmond'
import { AuditCTable } from './audit-c'
import { TobaccoAndAlcoholFeedback } from './feedback'

type TobaccoAndAlcoholResultsProps = {
  sectionLetter: string
}

export const TobaccoAndAlcoholResults: FC<TobaccoAndAlcoholResultsProps> = ({
  sectionLetter,
}) => {
  const { t } = useReportPdfContext()

  return (
    <View style={tw('px-12')}>
      <SubSection
        title={t('initial-report:subSection.results.title', {
          letter: sectionLetter,
        })}
      >
        <View style={tw('flex flex-col gap-6')}>
          <FagerstromTable />
          <RichmondTable />
          <AuditCTable />
          <TobaccoAndAlcoholFeedback />
        </View>
      </SubSection>
    </View>
  )
}
