import {
  AppointmentInfo,
  DoubleModal,
  EVENT_FORM,
  useModalForm,
} from '../../common'
import { useModalContext } from '../../provider'
import { useSchemaGeneric } from './schema'
import { useTranslation } from 'react-i18next'
import { GenericForm } from './generic-analysis-form'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import { useEffect, useState } from 'react'
import {
  addMinutes,
  getDateFromExactTime,
  getTime,
  useRouteState,
} from '@shared/utils'
import { GenericAppointmentState } from './state'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { ROUTE_PATH_CAMPAIGN } from 'apps/social-referent/src/routes/enums'

export const GenericAnalysis = observer(
  ({ onClose, isEdit, isReadOnly, isCoordinator, actionAfterSubmit }) => {
    const { t } = useTranslation()
    const { id: socialReferentId } = useParams()
    const { setIsLoading } = useLayoutContext()

    const {
      appointmentType,
      appointmentDay,
      startTime,
      modality,
      eventId,
      personId,
      cancelEvent,
      toggleCancelEvent,
    } = useModalContext()

    const {
      isLoading,
      annuityList,
      annuities,
      canGenerateRoom,
      isOwner,
      saveAppointment,
      createAppointment,
      deleteAppointment,
    } = useRouteState(GenericAppointmentState, {
      isEdit,
      eventId,
      socialReferentId,
    })

    const schema = useSchemaGeneric(t)

    const getDefaultEndTime = (startTime) => {
      const startDate = getDateFromExactTime(appointmentDay, startTime)
      const newEndTime = getTime(addMinutes({ date: startDate, minutes: 120 }))

      return newEndTime
    }

    const defaultValues = {
      [EVENT_FORM.appointmentType]: appointmentType,
      [EVENT_FORM.appointmentDay]: appointmentDay,
      [EVENT_FORM.startDate]: startTime,
      [EVENT_FORM.endDate]: getDefaultEndTime(startTime),
      [EVENT_FORM.appointmentModality]: modality,
      [EVENT_FORM.formPersonId]: personId || '',
    }

    const { methods } = useModalForm({ schema, defaultValues })

    const { handleSubmit, watch, setValue } = methods
    const formStartTime = watch(EVENT_FORM.startDate)

    const onSubmit = handleSubmit((form) => {
      const { appointmentType, formPersonId, appointmentModality } = form
      const { startDate } = form
      const newStartDate = getDateFromExactTime(appointmentDay, startDate)
      const newEndDate = addMinutes({ date: newStartDate, minutes: 120 })

      const eventForm = {
        startDate: newStartDate,
        endDate: newEndDate,
        appointmentType,
        id: eventId || '',
        personId: formPersonId || personId,
        socialReferentId,
        appointmentModality: Number(appointmentModality),
      }

      const action = isEdit ? saveAppointment : createAppointment
      action(eventForm).then(() => {
        actionAfterSubmit()
      })
    })

    const onDelete = () => {
      deleteAppointment(eventId).then(() => {
        actionAfterSubmit()
      })
    }

    const [isEditing, setIsEditing] = useState(false)
    const toggleEdit = () => {
      setIsEditing(!isEditing)
    }

    const annuityId = annuities.find(
      (annuity) => annuity.person.id === personId,
    )?.id

    useEffect(() => {
      if (!formStartTime) return
      setValue(EVENT_FORM.endDate, getDefaultEndTime(formStartTime))
    }, [formStartTime])

    useEffect(() => {
      setIsLoading(isLoading)
    }, [isLoading])

    if (isLoading) return

    return (
      <DoubleModal
        onClose={onClose}
        isEdit={isEdit}
        isReadOnly={isReadOnly}
        methods={methods}
        onDelete={onDelete}
        cancelEvent={cancelEvent}
        toggleCancelEvent={toggleCancelEvent}
        toggleEdit={toggleEdit}
        isEditing={isEditing}
        formSlot={
          <GenericForm
            isEdit={isEdit}
            isReadOnly={isReadOnly}
            isCoordinator={isCoordinator}
            annuityList={annuityList}
            isEditing={isEditing}
          />
        }
        infoSlot={
          <AppointmentInfo
            canGenerateRoom={canGenerateRoom?.result}
            profileUrl={ROUTE_PATH_CAMPAIGN.userArea(annuityId)}
            isCoordinator={isCoordinator}
            isOwner={isOwner}
            isEditing={isEditing}
            showResendComms
          />
        }
        onSubmit={onSubmit}
      />
    )
  },
)
