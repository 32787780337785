export const EXTRA_DAY_DIGITAL_FIRST_WEEK = 1
export const EXTRA_DAY_NO_DIGITAL_FIRST_WEEK = 2

export const DAYS_OF_THE_WEEK = 7

export const EXTRA_DAY_DIGITAL_SECOND_WEEK =
  EXTRA_DAY_DIGITAL_FIRST_WEEK + DAYS_OF_THE_WEEK // 8

export const EXTRA_DAY_NO_DIGITAL_SECOND_WEEK =
  EXTRA_DAY_NO_DIGITAL_FIRST_WEEK + DAYS_OF_THE_WEEK // 9
  

export const OP_EXTRA_DAY_DIGITAL_FIRST_WEEK = 0
export const OP_EXTRA_DAY_NO_DIGITAL_FIRST_WEEK = 1