import {
  menuIcon,
  locked,
  menuHorizontalIcon,
  menuVerticalIcon,
  settingsIcon,
  refreshIcon,
  videoIcon,
  iconChrome,
  iconEdge,
  iconFirefox,
  iconSafari,
  pcChromeBars,
  pcFirefoxBars,
  pcEdgeBars,
  appleSafariBars,
  applePopUpSafariOne,
  applePopUpSafariTwo,
  pcPopUpChromeOne,
  pcPopUpChromeTwo,
  pcPopUpChromeThree,
  pcPopUpFirefoxOne,
  pcPopUpFirefoxTwo,
  pcPopUpEdgeOne,
  pcPopUpEdgeTwo,
  mobileApplePopupChromeOne,
  mobileApplePopupChromeTwo,
  mobileApplePopupSafariOne,
  mobileApplePopupSafariTwo,
  mobileAppleChromeBars,
  mobileSafariBars,
  mobileChromeBars,
  mobileFirefoxBars,
  mobilePopupChromeOne,
  mobilePopupFirefoxOne,
  mobilePopupFirefoxTwo,
  mobilePopupFirefoxThree,
} from '../../utils/images'
import { browsersType, osType, getBrowserAndOS } from '../../utils'

export const useContentSteps = () => {
  const { browser, os } = getBrowserAndOS()
  const isAppleOrMac = browser === browsersType.Safari || os === osType.iOS
  const isMobile = os === osType.Android || os === osType.iOS
  const steps = isAppleOrMac ? 'appleSteps' : 'pcSteps'
  let barImages,
    firstStepIcons,
    secondImages,
    thirdImages,
    browserIcon,
    anotherIcons

  if (isAppleOrMac) {
    switch (browser) {
      case browsersType.Chrome:
        browserIcon = iconChrome
        firstStepIcons = isMobile ? [menuHorizontalIcon] : [videoIcon]
        anotherIcons = [menuVerticalIcon]
        barImages = isMobile ? [mobileAppleChromeBars] : [pcChromeBars]
        secondImages = isMobile
          ? [mobileApplePopupChromeOne]
          : [pcPopUpChromeOne]
        thirdImages = isMobile
          ? [mobileApplePopupChromeTwo]
          : [pcPopUpChromeTwo, pcPopUpChromeThree]
        break
      case browsersType.Firefox:
        browserIcon = iconFirefox
        firstStepIcons = isMobile ? [] : [videoIcon]
        anotherIcons = [menuIcon]
        barImages = isMobile ? [] : [pcFirefoxBars]
        secondImages = isMobile ? [] : [pcPopUpFirefoxOne]
        thirdImages = isMobile ? [] : [pcPopUpFirefoxTwo]
        break
      case browsersType.Safari:
        browserIcon = iconSafari
        firstStepIcons = [refreshIcon]
        anotherIcons = []
        barImages = isMobile ? [mobileSafariBars] : [appleSafariBars]
        secondImages = isMobile
          ? [mobileApplePopupSafariOne]
          : [applePopUpSafariOne]
        thirdImages = isMobile
          ? [mobileApplePopupSafariTwo]
          : [applePopUpSafariTwo]
        break
    }
  } else {
    switch (browser) {
      case browsersType.Chrome:
        browserIcon = iconChrome
        firstStepIcons = isMobile ? [locked] : [videoIcon]
        anotherIcons = [menuVerticalIcon]
        barImages = isMobile ? [mobileChromeBars] : [pcChromeBars]
        secondImages = isMobile ? [mobilePopupChromeOne] : [pcPopUpChromeOne]
        thirdImages = isMobile ? [] : [pcPopUpChromeTwo, pcPopUpChromeThree]
        break
      case browsersType.Firefox:
        browserIcon = iconFirefox
        firstStepIcons = isMobile ? [menuVerticalIcon] : [videoIcon]
        anotherIcons = [menuIcon]
        barImages = isMobile ? [mobileFirefoxBars] : [pcFirefoxBars]
        secondImages = isMobile
          ? [mobilePopupFirefoxOne, mobilePopupFirefoxTwo]
          : [pcPopUpFirefoxOne]
        thirdImages = isMobile ? [mobilePopupFirefoxThree] : [pcPopUpFirefoxTwo]
        break
      case browsersType.Edge:
        browserIcon = iconEdge
        firstStepIcons = [videoIcon]
        anotherIcons = [menuHorizontalIcon, settingsIcon]
        barImages = isMobile ? [] : [pcEdgeBars]
        secondImages = isMobile ? [] : [pcPopUpEdgeOne]
        thirdImages = isMobile ? [] : [pcPopUpEdgeTwo]
        break
    }
  }
  const contentStep = !isMobile
    ? [
        {
          text: `${steps}.${browser}.desktop.one`,
          browserIcon: browserIcon,
          firstStepIcons: firstStepIcons,
          barImages: barImages,
        },
        {
          text: `${steps}.${browser}.desktop.two`,
          secondImages: secondImages,
        },
        {
          text: `${steps}.${browser}.desktop.three`,
          isButton: true,
        },
        {
          text: `${steps}.${browser}.desktop.four`,
          anotherIcons: anotherIcons,
          thirdImages: thirdImages,
        },
        {
          text: `${steps}.${browser}.desktop.five`,
          isButton: true,
        },
      ]
    : [
        {
          text: `${steps}.${browser}.mobile.one`,
          browserIcon: browserIcon,
          firstStepIcons: firstStepIcons,
          barImages: barImages,
        },
        {
          text: `${steps}.${browser}.mobile.two`,
          secondImages: secondImages,
        },
        {
          text: `${steps}.${browser}.mobile.three`,
          thirdImages: thirdImages,
          anotherIcons: anotherIcons,
        },
        {
          text: `${steps}.${browser}.mobile.four`,
          isButton: true,
        },
      ]
  return { contentStep, isMobile }
}
