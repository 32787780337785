import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { PostCsTabs, PreCSTabs, TAB_LINK } from './enums'
import { NotificationBubble, TabList } from '@shared/ui'
import { SRUserName } from '@sr/ui'
import { useMessages, useRouteState } from '@shared/utils'
import { MonitoringAreaRouteState } from './state'
import { observer } from 'mobx-react-lite'
import { useLayoutContext } from '../common'
import { useEffect, useState } from 'react'
import {
  MonitoringAreaContainer,
  MonitoringAreaContentContainer,
} from './styles'

export const MonitoringArea = observer(() => {
  const { id } = useParams()
  const { pathname, state } = useLocation()
  const { preCS } = state || {}
  const [activeTab, setActiveTab] = useState()
  const defaultTabList = preCS ? PreCSTabs() : PostCsTabs()
  const navigate = useNavigate()
  const [tabList, setTabList] = useState(defaultTabList)

  const { newMessages, getMessagesUnread } = useMessages()
  const { setIsLoading } = useLayoutContext()
  const { isLoading, policy, roomSid } = useRouteState(
    MonitoringAreaRouteState,
    {
      policyId: id,
    },
  )
  const onChangeTab = (name) => {
    navigate(TAB_LINK[name], { state: { preCS } })
  }

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    const canGetNumberOfMessagesUnread = !preCS && roomSid && getMessagesUnread
    if (canGetNumberOfMessagesUnread) {
      getMessagesUnread({ roomSid }).then((result) => {
        if (result) {
          defaultTabList[0].titleSuffix = <NotificationBubble number={result} />
        } else if (result === 0) {
          defaultTabList[0].titleSuffix = null
        }
        setTabList(defaultTabList)
      })
    }
  }, [roomSid, getMessagesUnread, newMessages])

  useEffect(() => {
    const pageByPath = tabList.findIndex(({ link }) => {
      return pathname.includes(link)
    })
    if (pageByPath) {
      setActiveTab(pageByPath)
    }
  }, [pathname])

  if (isLoading || !policy) return

  return (
    <MonitoringAreaContainer>
      <SRUserName fullName={policy.fullName} />
      <MonitoringAreaContentContainer>
        <TabList
          defaultActiveTab={activeTab}
          activeTab={activeTab}
          tabList={tabList}
          onClickTab={onChangeTab}
        />
        <Outlet />
      </MonitoringAreaContentContainer>
    </MonitoringAreaContainer>
  )
})
