type IMCScoreProps = {
  score: number
  height: number
  weight: number
}

export enum IMCScoreResult {
  insufficient,
  healthy,
  overweightOne,
  overweightTwo,
  obesityOne,
  obesityTwo,
  obesityThree,
  obesityFour,
}

interface SCORE_NAME {
  [key: number]: string
}

export const IMCScoreName: SCORE_NAME = Object.freeze({
  [IMCScoreResult.insufficient]: 'insufficient',
  [IMCScoreResult.healthy]: 'healthy',
  [IMCScoreResult.overweightOne]: 'overweightOne',
  [IMCScoreResult.overweightTwo]: 'overweightTwo',
  [IMCScoreResult.obesityOne]: 'obesityOne',
  [IMCScoreResult.obesityTwo]: 'obesityTwo',
  [IMCScoreResult.obesityThree]: 'obesityThree',
  [IMCScoreResult.obesityFour]: 'obesityFour',
})

export class IMCScore {
  score: number
  result: number
  insufficient: number
  healthy: number
  overweightOne: number
  overweightTwo: number
  obesityOne: number
  obesityTwo: number
  obesityThree: number
  obesityFour: number
  scoreName: string
  scale: string
  height: number
  weight: number

  constructor({ score, height, weight }: IMCScoreProps) {
    this.score = score
    this.result = IMCScoreResult.healthy
    this.insufficient = 18.59
    this.healthy = 24.99
    this.overweightOne = 26.99
    this.overweightTwo = 29.9
    this.obesityOne = 34.9
    this.obesityTwo = 39.99
    this.obesityThree = 49.9
    this.obesityFour = 50
    this.scoreName = ''
    this.scale = `${score}`
    this.height = height
    this.weight = weight
    this.init()
  }

  private init() {
    this.setResult()
    this.setScoreName()
  }

  private setResult() {
    if (typeof this.score === 'string') {
      return
    }
    if (this.score <= this.insufficient) {
      this.result = IMCScoreResult.insufficient
    }
    if (this.score > this.insufficient && this.score <= this.healthy) {
      this.result = IMCScoreResult.healthy
    }
    if (this.score > this.healthy && this.score <= this.overweightOne) {
      this.result = IMCScoreResult.overweightOne
    }
    if (this.score > this.overweightOne && this.score <= this.overweightTwo) {
      this.result = IMCScoreResult.overweightTwo
    }
    if (this.score > this.overweightTwo && this.score <= this.obesityOne) {
      this.result = IMCScoreResult.obesityOne
    }
    if (this.score > this.obesityOne && this.score <= this.obesityTwo) {
      this.result = IMCScoreResult.obesityTwo
    }
    if (this.score > this.obesityTwo && this.score <= this.obesityThree) {
      this.result = IMCScoreResult.obesityThree
    }
    if (this.score > this.obesityThree) {
      this.result = IMCScoreResult.obesityFour
    }
  }

  private setScoreName() {
    this.scoreName = IMCScoreName[this.result]
  }
}
