import { PolicySubStates } from '@shared/data-access'
import { TFunction } from 'i18next'

const TRANSLATION_BASE_FORM_KEY = 'userArea.profile.actions.services.form'

export enum ProfileForm {
  phone = 'phone',
  email = 'email',
  street = 'street',
  postalCode = 'postalCode',
  locality = 'locality',
  region = 'region',
  preferredLanguage = 'preferredLanguage',
  sex = 'sex',
  identification = 'identification',
  birthDay = 'birthDay',
  country= 'country',
}

export enum EditableType {
  input = 1,
  select,
  date,
}

export const POLICY_SUB_STATES = Object.freeze({
  suscriptionPendingRescheduled: 1,
  suscriptionPendingNotLocatable: 3,
  suscriptionPendingPhoneError: 4,
  suscriptionPendingNoInterest: 7,
  suscriptionPendingNoShow: 9,
  acceptedConfirmed: 31,
  suscriptionPending: 0,
} as const)

export const POLICY_SUB_STATE_NAME = Object.freeze({
  [POLICY_SUB_STATES.acceptedConfirmed]: 'acceptedConfirmed',
  [POLICY_SUB_STATES.suscriptionPendingNoInterest]: 'suscriptionPendingNoInterest',
  [POLICY_SUB_STATES.suscriptionPendingNoShow]: 'suscriptionPendingNoShow',
  [POLICY_SUB_STATES.suscriptionPendingNotLocatable]: 'suscriptionPendingNotLocatable',
  [POLICY_SUB_STATES.suscriptionPendingPhoneError]: 'suscriptionPendingPhoneError',
  [POLICY_SUB_STATES.suscriptionPendingRescheduled]: 'suscriptionPendingRescheduled',
  [POLICY_SUB_STATES.suscriptionPending]: 'suscriptionPending',
} as const)

export const rejectOptions = (t: TFunction) => [
  {
    label: t(`${TRANSLATION_BASE_FORM_KEY}.locatable`),
    value: PolicySubStates.subscriptionPendingScheduled,
  },
  {
    label: t(`${TRANSLATION_BASE_FORM_KEY}.notLocatable`),
    value: PolicySubStates.subscriptionPendingNotLocatable,
  },
  {
    label: t(`${TRANSLATION_BASE_FORM_KEY}.noShow`),
    value: PolicySubStates.subscriptionPendingNoShow,
  },
  {
    label: t(`${TRANSLATION_BASE_FORM_KEY}.phoneError`),
    value: PolicySubStates.subscriptionPendingPhoneError,
  },
  {
    label: t(`${TRANSLATION_BASE_FORM_KEY}.noInterest`),
    value: PolicySubStates.subscriptionPendingNoInterest,
  },
]
