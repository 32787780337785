import { BUTTON_COLORS, BUTTON_SIZES, Button, RouterLink } from '@shared/ui'
import { useTranslation } from 'react-i18next'
import { Video, User, Screen, Email } from '@carbon/icons-react'
import { useNavigate } from 'react-router-dom'
import { APPOINTMENT_MODALITY } from '@shared/data-access'
import { observer } from 'mobx-react-lite'
import { useModalContext } from '../../../provider'
import { useRouteState } from '@shared/utils'
import { AppointmentInfoState } from './state'
import { useState } from 'react'
import { ConfirmResendCommunicationsModal } from './components'

export const AppointmentInfo = observer(
  ({
    canGenerateRoom,
    profileUrl,
    isCoordinator,
    isOwner,
    isEditing,
    showResendComms,
  }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { onGenerateRoom, meetingUrl, modality, eventId } = useModalContext()
    const [confirmModal, setConfirmModal] = useState(null)
    const { resendCommunications } = useRouteState(AppointmentInfoState, {
      eventId,
    })

    const canView = !isCoordinator || isOwner
    const showVideoCall =
      meetingUrl && canView && modality === APPOINTMENT_MODALITY.online

    const goToProfile = () => {
      navigate(profileUrl)
    }

    const onCloseModal = () => {
      setConfirmModal(null)
    }

    const onResendEmail = () => {
      setConfirmModal(
        <ConfirmResendCommunicationsModal
          onClose={onCloseModal}
          action={resendCommunications}
        />,
      )
    }

    return (
      <>
        {showVideoCall && (
          <RouterLink external to={meetingUrl} target="_blank">
            <Button
              size={BUTTON_SIZES.small}
              className="w-full text-sm"
              contentClassName="!justify-start"
              disabled={isEditing}
            >
              <Video size={16} />
              {t('agenda.formModal.meetingUrl')}
            </Button>
          </RouterLink>
        )}
        <Button
          onClick={goToProfile}
          size={BUTTON_SIZES.small}
          className="text-sm"
          contentClassName="!justify-start"
          disabled={isEditing}
        >
          <User size={16} />
          {t('agenda.formModal.profileUrl')}
        </Button>
        {showResendComms && (
          <Button
            onClick={onResendEmail}
            size={BUTTON_SIZES.small}
            color={BUTTON_COLORS.secondary}
            className="text-sm"
            contentClassName="!justify-start"
            disabled={isEditing}
          >
            <Email size={16} />
            {t('agenda.formModal.resendEmail')}
          </Button>
        )}
        {canGenerateRoom && (
          <Button
            onClick={onGenerateRoom}
            size={BUTTON_SIZES.small}
            color={BUTTON_COLORS.secondary}
            className="text-sm"
            contentClassName="!justify-start"
            disabled={isEditing}
          >
            <Screen size={16} />
            {t('agenda.formModal.generateRoom')}
          </Button>
        )}
        {confirmModal}
      </>
    )
  },
)
