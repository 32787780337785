export const CALL_DIRECTION = Object.freeze({
  toClient: 0,
  fromClient: 1,
})

export const CALL_TYPE_NAME = Object.freeze({
  [CALL_DIRECTION.toClient]: 'common:callType.toClient',
  [CALL_DIRECTION.fromClient]: 'common:callType.fromClient',
})

export const CALL_TYPE_OPTIONS = [
  {
    name: CALL_TYPE_NAME[CALL_DIRECTION.toClient],
    value: CALL_DIRECTION.toClient,
  },
  {
    name: CALL_TYPE_NAME[CALL_DIRECTION.fromClient],
    value: CALL_DIRECTION.fromClient,
  },
]

export const CALL_TYPE = Object.freeze({
  locatedWithAppointment: 0,
  locatedWithoutAppointment: 1,
  notLocatable: 2,
  wrongPhone: 3,
  phoneMailbox: 4,
  notInterested: 5,
  locatedButUnavailable: 6,
  updateAppointment: 7,
  technicalIssues: 8,
  changeSr: 9,
  policyQuestion: 10,
  serviceQuestion: 11,
  usabilityQuestion: 12,
  technicalIssuesVideoCall: 13,
  contentQuestion: 14,
  preventionPlanDoubt: 15,
  noLinkReceived: 16,
  updatePhone: 17,
  updatePersonalData: 18,
  sinisterReference: 19,
  complain: 20,
  prevPlanUpdateAppointment: 21,
  prevPlanAppointmentReminder: 22,
  prevPlanAppointmentConfirmation: 23,
  prevPlanAgenda: 24,
  preventivePowersInfo: 25,
  preventivePowersHelp: 26,
  preventivePowersEnd: 27,
  clientPortalProblems: 28,
  createUserProblems: 29,
  dependencyInfo: 30,
  dependencyHelp: 31,
  guidelinesDoubts: 32,
  welcomeCall: 33,
  others: 99,
})

export const CALL_REASON_NAME = Object.freeze({
  [CALL_TYPE.locatedWithAppointment]:
    'common:callReason.locatedWithAppointment',
  [CALL_TYPE.locatedWithoutAppointment]:
    'common:callReason.locatedWithoutAppointment',
  [CALL_TYPE.notLocatable]: 'common:callReason.notLocatable',
  [CALL_TYPE.wrongPhone]: 'common:callReason.wrongPhone',
  [CALL_TYPE.phoneMailbox]: 'common:callReason.phoneMailbox',
  [CALL_TYPE.notInterested]: 'common:callReason.notInterested',
  [CALL_TYPE.locatedButUnavailable]: 'common:callReason.locatedButUnavailable',
  [CALL_TYPE.updateAppointment]: 'common:callReason.updateAppointment',
  [CALL_TYPE.technicalIssues]: 'common:callReason.technicalIssues',
  [CALL_TYPE.changeSr]: 'common:callReason.changeSr',
  [CALL_TYPE.policyQuestion]: 'common:callReason.policyQuestion',
  [CALL_TYPE.serviceQuestion]: 'common:callReason.serviceQuestion',
  [CALL_TYPE.usabilityQuestion]: 'common:callReason.usabilityQuestion',
  [CALL_TYPE.technicalIssuesVideoCall]:
    'common:callReason.technicalIssuesVideoCall',
  [CALL_TYPE.contentQuestion]: 'common:callReason.contentQuestion',
  [CALL_TYPE.preventionPlanDoubt]: 'common:callReason.preventionPlanDoubt',
  [CALL_TYPE.noLinkReceived]: 'common:callReason.noLinkReceived',
  [CALL_TYPE.updatePhone]: 'common:callReason.updatePhone',
  [CALL_TYPE.updatePersonalData]: 'common:callReason.updatePersonalData',
  [CALL_TYPE.sinisterReference]: 'common:callReason.sinisterReference',
  [CALL_TYPE.complain]: 'common:callReason.complain',
  [CALL_TYPE.prevPlanUpdateAppointment]:
    'common:callReason.prevPlanUpdateAppointment',
  [CALL_TYPE.prevPlanAppointmentReminder]:
    'common:callReason.prevPlanAppointmentReminder',
  [CALL_TYPE.prevPlanAppointmentConfirmation]:
    'common:callReason.prevPlanAppointmentConfirmation',
  [CALL_TYPE.prevPlanAgenda]: 'common:callReason.prevPlanAgenda',
  [CALL_TYPE.preventivePowersInfo]: 'common:callReason.preventivePowersInfo',
  [CALL_TYPE.preventivePowersHelp]: 'common:callReason.preventivePowersHelp',
  [CALL_TYPE.preventivePowersEnd]: 'common:callReason.preventivePowersEnd',
  [CALL_TYPE.clientPortalProblems]: 'common:callReason.clientPortalProblems',
  [CALL_TYPE.createUserProblems]: 'common:callReason.createUserProblems',
  [CALL_TYPE.dependencyInfo]: 'common:callReason.dependencyInfo',
  [CALL_TYPE.dependencyHelp]: 'common:callReason.dependencyHelp',
  [CALL_TYPE.guidelinesDoubts]: 'common:callReason.guidelinesDoubts',
  [CALL_TYPE.welcomeCall]: 'common:callReason.welcomeCall',
  [CALL_TYPE.others]: 'common:callReason.others',
})

export const CALL_REASON_OPTIONS = Object.values(CALL_TYPE).map((type) => ({
  name: CALL_REASON_NAME[type],
  value: type,
}))
