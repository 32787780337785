import {
  APPOINTMENT_MODALITY,
  APPOINTMENT_MODALITY_TRANSLATION,
  APPOINTMENT_MODIFICATION_ORIGIN,
  APPOINTMENT_MODIFICATION_REASON,
  APPOINTMENT_ORIGIN_TRANSLATION,
  APPOINTMENT_REASON_TRANSLATION,
  APPOINTMENT_TYPES,
  APPOINTMENT_TYPES_TRANSLATION,
} from '@shared/data-access'
import { t } from 'i18next'

export const getDefaultModality = ({ type, noDigital = false }) => {
  const modalityType = noDigital
    ? APPOINTMENT_MODALITY.faceToFace
    : APPOINTMENT_MODALITY.online

  const defaultPhoneModalities = [
    APPOINTMENT_TYPES.MONITORING_REPORT,
    APPOINTMENT_TYPES.CONSULTATION,
    APPOINTMENT_TYPES.WELCOME_CALL,
  ]

  return defaultPhoneModalities.some((defaultType) => type === defaultType)
    ? APPOINTMENT_MODALITY.phoneCall
    : modalityType
}
const getTranslationAppointmentModality = (value) => {
  if (value === APPOINTMENT_TYPES.FOLLOW_UP_ANALYSIS)
    return t('appointmentType.yearlyFollowUp')
  return t(APPOINTMENT_TYPES_TRANSLATION[value])
}

export const getAppointmentOptions = (isEdit) => {
  const disabledTypes = [
    APPOINTMENT_TYPES.INITIAL_REPORT,
    APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE,
    APPOINTMENT_TYPES.WELCOME_CALL,
    APPOINTMENT_TYPES.SERVICE_APPOINTMENT,
    APPOINTMENT_TYPES.GENERIC_ANALYSIS,
  ]

  return Object.values(APPOINTMENT_TYPES)
    .map((value) => {
      if (value !== APPOINTMENT_TYPES.FOLLOW_UP_ANALYSIS_CALL) {
        return {
          value,
          name: getTranslationAppointmentModality(value) || null,
          disabled: disabledTypes.some((type) => type === value) || isEdit,
        }
      }
      return null
    })
    .filter((el) => el !== null)
}

export const getAbsencesOptions = () => {
  return [
    {
      value: APPOINTMENT_TYPES.ABSENCES,
      name:
        t(APPOINTMENT_TYPES_TRANSLATION[APPOINTMENT_TYPES.ABSENCES]) || null,
    },
  ]
}

export const getSRAppointmentOptions = (isEdit) => {
  const srCanEditOrCreate = [
    APPOINTMENT_TYPES.MONITORING_REPORT,
    APPOINTMENT_TYPES.CONSULTATION,
    APPOINTMENT_TYPES.FOLLOW_UP_ANALYSIS,
    APPOINTMENT_TYPES.INITIAL_ANALYSIS,
    APPOINTMENT_TYPES.GENERIC_ANALYSIS,
    APPOINTMENT_TYPES.WELCOME_CALL,
  ]

  return getAppointmentOptions(isEdit).filter(({ value }) => {
    return srCanEditOrCreate.some((type) => type === value)
  })
}

const blockedModalities = {
  [APPOINTMENT_TYPES.MONITORING_REPORT]: [
    APPOINTMENT_MODALITY.faceToFace,
    APPOINTMENT_MODALITY.online,
  ],
  [APPOINTMENT_TYPES.CONSULTATION]: [
    APPOINTMENT_MODALITY.faceToFace,
    APPOINTMENT_MODALITY.online,
  ],
  [APPOINTMENT_TYPES.FOLLOW_UP_ANALYSIS]: [APPOINTMENT_MODALITY.faceToFace],
}

const isDisabledByAppointmentType = (modality, appointmentType) => {
  const blockedForType = blockedModalities[appointmentType] || []
  return blockedForType.includes(modality)
}

export const getAppointmentModalityOptionsGeneric = () => {
  return Object.values(APPOINTMENT_MODALITY).map((value) => {
    return {
      value,
      name: t(APPOINTMENT_MODALITY_TRANSLATION[value]) || null,
      disabled: value === APPOINTMENT_MODALITY.faceToFace,
    }
  })
}

export const getAppointmentModalityOptions = (appointmentType) => {
  return Object.values(APPOINTMENT_MODALITY).map((value) => {
    return {
      value,
      name: t(APPOINTMENT_MODALITY_TRANSLATION[value]) || null,
      disabled: isDisabledByAppointmentType(value, appointmentType),
    }
  })
}

export const getChangeOriginOptions = () => {
  const APPOINTMENT_ORIGIN = [
    APPOINTMENT_MODIFICATION_ORIGIN.fromClient,
    APPOINTMENT_MODIFICATION_ORIGIN.fromQida,
  ]

  return Object.values(APPOINTMENT_ORIGIN).map((value) => {
    return {
      value,
      name: t(APPOINTMENT_ORIGIN_TRANSLATION[value]) || null,
    }
  })
}

export const getChangeReasonOptions = () => {
  return Object.values(APPOINTMENT_MODIFICATION_REASON).map((value) => {
    return {
      value,
      name: t(APPOINTMENT_REASON_TRANSLATION[value]) || null,
    }
  })
}
