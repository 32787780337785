import { getRoot, types } from 'mobx-state-tree'
import {
  APPOINTMENT_MODALITY,
  DateString,
  PersonModel,
  RootModel,
  SocialReferent,
} from '@shared/data-access'
import { CampaignModel } from '../campaign'
import { ProductModel } from '../product'
import { CollectiveModel } from '../collective'
import { AnnuityState, AnnuitySubState } from './enums'

export const MODEL_NAME = 'Annuity'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    contractNumber: types.maybeNull(types.string),
    policyNumber: types.maybeNull(types.string),
    policyProduct: types.maybeNull(types.string),
    person: types.maybeNull(types.reference(PersonModel)),
    socialReferent: types.maybeNull(types.reference(SocialReferent)),
    socialReferentId: types.maybeNull(types.string),
    analysisDate: types.maybeNull(DateString),
    startDate: types.maybeNull(DateString),
    campaign: types.maybeNull(types.reference(CampaignModel)),
    product: types.maybeNull(types.reference(ProductModel)),
    collective: types.maybeNull(types.reference(CollectiveModel)),
    state: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(AnnuityState).some((state) => state === value),
      ),
    ),
    subState: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(AnnuitySubState).some((subState) => subState === value),
      ),
    ),
    analysisModality: types.maybeNull(
      types.refinement(types.integer, (value) =>
        Object.values(APPOINTMENT_MODALITY).some((type) => type === value),
      ),
    ),
  })
  .views((self) => ({
    get canCancelService() {
      return (
        self.state === AnnuityState.pending ||
        self.state === AnnuityState.accepted
      )
    },
  }))
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
