import { ProfileForm } from '@sr/routes/common/generic-profile/enums'
import { AddressProps } from '@sr/routes/common/generic-profile/types'
import { SRInput } from '@sr/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const EditableFields: FC<AddressProps> = ({
  street,
  postalCode,
  locality,
  region,
  country,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="flex flex-col w-full">
        <SRInput
          name={ProfileForm.street}
          defaultValue={street}
          placeholder={t('common:addressEdit')}
        />
        <div className="grid grid-cols-6 gap-1 mt-2 w-full">
          <SRInput
            className="col-span-2"
            name={ProfileForm.postalCode}
            defaultValue={postalCode}
            placeholder={t('common:postalCode')}
          />
          <SRInput
            className="col-span-4"
            name={ProfileForm.locality}
            defaultValue={locality}
            placeholder={t('common:locality')}
          />
        </div>
        <SRInput
          name={ProfileForm.region}
          defaultValue={region}
          placeholder={t('common:region')}
          className="mt-2"
        />
        <SRInput
          name={ProfileForm.country}
          defaultValue={country}
          placeholder={t('common:country')}
          className="mt-2"
        />
      </div>
    </>
  )
}
