import React, { ReactNode } from 'react'

import { speechBubble } from './styles'

interface SpeechBubbleProps {
  children: ReactNode
  className?: string
  background?: string
}

export const SpeechBubble: React.FC<SpeechBubbleProps> = ({
  children,
  background = '',
}) => {
  return (
    <div className={speechBubble}>
      <div className="w-3 overflow-hidden">
        <div
          className={`${background} h-4 border-2  rotate-45 transform origin-bottom-right rounded-sm`}
        ></div>
      </div>
      <div className={`${background} border-2 p-4 my-6 rounded-lg flex-1`}>
        {children}
      </div>
    </div>
  )
}
