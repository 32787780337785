import { useRouteState } from '@shared/utils'
import { SRCardsContainer, SRUserName } from '@sr/ui'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Params, useParams } from 'react-router-dom'
import { UserAreaCampaignRouteState } from './state'
import { useLayoutContext } from '@sr/routes/common'
import { observer } from 'mobx-react-lite'
import { UserAreaInfo, UserAreaWrapper } from './styles'
import { userCards } from './enum'
import { SrCampaignType } from '@shared/ui'

export const UserArea: FC = observer(() => {
  const { id }: Readonly<Params<string>> = useParams()
  const { t } = useTranslation()

  const { setIsLoading, setBackButtonText } = useLayoutContext()

  const { annuity, isLoading } = useRouteState(UserAreaCampaignRouteState, {
    annuityId: id,
  })

  useEffect(() => {
    setBackButtonText(t('userArea.title'))
  }, [])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  return (
    <div className={UserAreaWrapper}>
      <div className={UserAreaInfo}>
        <SRUserName fullName={annuity?.person?.fullName} />
        <SrCampaignType annuity={annuity} />
      </div>
      <SRCardsContainer cards={userCards(t)} />
    </div>
  )
})
