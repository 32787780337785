import { getRoot, types } from 'mobx-state-tree'
import { DateString, RootModel } from '@shared/data-access'
import { UNAVAILABLE_SLOTS_TYPES } from './enums'

export const MODEL_NAME = 'UnavailableSlot'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    startDate: types.maybeNull(DateString),
    endDate: types.maybeNull(DateString),
    socialReferentId: types.maybeNull(types.string),
    unavailableSlotType: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(UNAVAILABLE_SLOTS_TYPES).some(
          (slotType) => slotType === value
        )
      )
    ),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))

export default Model
