import { useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { CloudUpload } from '@carbon/icons-react'
import { UploadModal } from './upload-modal'
import { useTranslation } from 'react-i18next'
import { UploadFileInfo, UploadFileWrapper } from './styles'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: '#004039',
  borderStyle: 'dashed',
  backgroundColor: '#F8F8F8',
  color: '#004039',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

export const UploadFile = ({ onDrop }) => {
  const { t } = useTranslation()
  const [openUploadModal, setUploadModal] = useState(false)
  const [videoEnded, setVideoEnded] = useState(false)
  const [videoError, setVideoError] = useState(false)

  const [videoTitle, setVideoTitle] = useState('')

  const [controller, setController] = useState()

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { 'video/mp4': [] },
      maxFiles: 1,
      onDrop: async (acceptedFiles) => {
        if (acceptedFiles.length) {
          const newController = new AbortController()
          setController(newController)
          setUploadModal(true)
          setVideoEnded(false)
          setVideoError(false)
          const video = await onDrop(acceptedFiles, newController)
          if (video.videoTitle) setVideoTitle(video.videoTitle)
          !video.ok && setVideoError(true)
          setVideoEnded(true)
        } else {
          setVideoTitle('')
          setUploadModal(true)
          setVideoEnded(true)
          setVideoError(true)
        }
      },
    })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )
  return (
    <div>
      {openUploadModal && (
        <UploadModal
          videoTitle={videoTitle}
          error={videoError}
          videoEnd={videoEnded}
          controller={controller}
          onClose={() => {
            setVideoEnded(false)
            setVideoError(false)
            setUploadModal(false)
          }}
        />
      )}
      <UploadFileWrapper>
        <span>{t('userArea.profile.uploadVideo.uploadText')}</span>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <UploadFileInfo>
            <CloudUpload size={50} />
            <p>{t('userArea.profile.uploadVideo.dragOrClick')}</p>
          </UploadFileInfo>
        </div>
      </UploadFileWrapper>
    </div>
  )
}
