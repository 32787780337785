import Axios from 'axios'
import { values } from 'mobx'
import { destroy, flow, getEnv, getRoot, getType, types } from 'mobx-state-tree'
import { RootModel } from '../root-model'

const DEFAULT_PAGE = 1
const DEFAULT_PAGE_SIZE = 20

export const MODEL_NAME = 'Model'

export const Store = types
  .model(`${MODEL_NAME}Store`, {
    loading: types.optional(types.boolean, false),
    map: types.map(RootModel),
  })
  .volatile(() => ({
    _searchSignal: null,
  }))
  .views((self) => ({
    get list() {
      return values(self.map)
    },
    get sorted() {
      return self.list.sort((a, b) => {
        const sortAttr = a.name ? 'name' : 'id'
        return a[sortAttr] > b[sortAttr] ? 1 : -1
      })
    },
    getOptions() {
      return self.sorted.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    },
    get modelName() {
      return getType(self.map)._subType.name
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.setSerializer()
    },
    setSerializer(options = {}) {
      getEnv(self).client.serializer.register(self.modelName, {
        afterDeserialize: (data) => {
          const item = self.map.get(data.id)
          item ? item.update(data) : self.put(data)
          return data.id
        },
        ...options,
      })
    },
    deserialize(data) {
      return getEnv(self).client.serializer.deserialize(self.modelName, data)
    },
    serialize(data) {
      return getEnv(self).client.serializer.serialize(self.modelName, data)
    },
    get: flow(function* get(id, options) {
      let item = self.map.get(id)
      if (!item) item = yield self.fetch(id, options)
      return item
    }),
    refreshItem: flow(function* refreshItem(id) {
      return yield self.fetch(id)
    }),
    deleteItem: flow(function* deleteItem(id, options) {
      return yield self.delete(id, options)
    }),
    putIncluded(includedModels = []) {
      return includedModels.map((item) => {
        const includedModelStore = getRoot(self).getModelStoreByModelName(
          item.type,
        )
        return includedModelStore.putItem(item)
      })
    },
    // TODO rethink this method naming
    putItems(items = [], url) {
      return items.map((item) => {
        return self.putItem(item, url)
      })
    },
    putItem(item, url) {
      if (!item) return null
      const createdItemId = self.deserialize({ data: item })
      const createdItem = self.map.get(createdItemId)
      if (!createdItem) return null
      createdItem.setLinks({ related: url })
      createdItem.setRelationships(item.relationships)

      return createdItem
    },
    removeItem(id) {
      destroy(self.map.get(id))
    },
    fetchAll: flow(function* fetchAll(options) {
      self.loading = true
      try {
        const url = options?.customUrl || self.url
        const response = yield getEnv(self).client.get(url, {
          action: `fetchAll${self.modelName}`,
          ...options,
        })
        if (!options?.noClear) {
          self.clear()
        }
        if (response) {
          self.putIncluded(response.data?.included)
          const fetchedItems = self.putItems(response.data?.data, url)
          return fetchedItems
        }
      } catch (error) {
        console.log(error)
        if (options?.throwError) {
          throw error
        }
      } finally {
        self.loading = false
      }
    }),
    fetchPage: flow(function* fetchPage(page, options = {}) {
      self.loading = true
      try {
        const url = options?.customUrl || self.url
        const response = yield getEnv(self).client.get(url, {
          action: `fetchPage${page}${self.modelName}`,
          ...options,
          params: {
            'page[size]': options.pageSize || DEFAULT_PAGE_SIZE,
            'page[number]': page || DEFAULT_PAGE,
            ...options.params,
          },
        })
        if (response) {
          self.page = self.page + 1
          self.pages = response?.data?.meta?.pagination?.pages || 1
          self.putIncluded(response.data?.included)
          const fetchedItems = self.putItems(response.data?.data, url)
          self.loading = false
          return {
            items: fetchedItems,
            pagination: response.data?.meta?.pagination,
          }
        }
      } catch (error) {
        console.log(error)
        if (options?.throwError) {
          throw error
        }
      } finally {
        self.loading = false
      }
    }),
    delete: flow(function* f(id, options) {
      self.loading = true
      try {
        const url = options?.customUrl || self.url
        const response = yield getEnv(self).client.delete(`${url}/${id}`, {
          action: `delete${self.modelName}`,
          ...options,
        })
        if (response) {
          self.removeItem(id)
          self.loading = false
        }
      } catch (error) {
        console.log(error)
        if (options?.throwError) {
          throw error
        }
      } finally {
        self.loading = false
      }
    }),
    fetch: flow(function* fetch(id, options) {
      self.loading = true
      try {
        const url = options?.customUrl || self.url
        const idUrlParam = id && !options?.withoutId ? `/${id}` : ''
        const response = yield getEnv(self).client.get(`${url}${idUrlParam}`, {
          action: `fetch${self.modelName}`,
          ...options,
        })
        if (response && !options?.avoidUpdate) {
          self.putIncluded(response.data?.included)
          return self.putItem(response.data?.data, url)
        }
      } catch (error) {
        console.log(error)
        if (options?.throwError) {
          throw error
        }
      } finally {
        self.loading = false
      }
    }),
    search: flow(function* search(filterText, options) {
      self.loading = true
      if (self._searchSignal) {
        self._searchSignal.cancel()
      }
      self._searchSignal = Axios.CancelToken.source()
      try {
        const url = options?.customUrl || self.url
        const response = yield getEnv(self).client.get(url, {
          action: `search${self.modelName}`,
          search: filterText,
          cancelToken: self._searchSignal.token,
          ...options,
        })
        if (response) {
          self.putIncluded(response.data?.included)
          const searchedItems = self.putItems(response.data?.data, url)
          self.loading = false
          self._searchSignal = null
          return {
            items: searchedItems,
            pagination: response.data?.meta?.pagination,
          }
        }
      } catch (error) {
        console.log(error)
        if (options?.throwError) {
          throw error
        }
      } finally {
        self.loading = false
      }
    }),
    post: flow(function* post(data, options) {
      self.loading = true
      try {
        const url = options?.customUrl || self.url
        const serializedData = self.serialize(data)
        const response = yield getEnv(self).client.post(
          `${url}/`,
          serializedData,
          {
            action: `post${self.modelName}`,
            ...options,
          },
        )
        if (response && !options?.avoidUpdate) {
          if (response.data?.data instanceof Array) {
            const items = self.putItems(response.data?.data, url)
            self.loading = false
            return items
          } else {
            const updatedItem = self.putItem(response.data?.data, url)
            self.loading = false
            return updatedItem
          }
        }
      } catch (error) {
        console.log(error)
        if (options?.throwError) {
          throw error
        }
      } finally {
        self.loading = false
      }
    }),
    action: flow(function* action(id, verb, data, options) {
      self.loading = true
      try {
        const url = options?.customUrl || self.url
        const urlIdParam = id ? `/${id}` : ''
        const verbUrlParam = verb ? `/${verb}` : ''
        const serializedData = self.serialize(data)
        const response = yield getEnv(self).client.post(
          `${url}${urlIdParam}${verbUrlParam}`,
          serializedData,
          {
            action: `${self.modelName}${verb}`,
            ...options,
          },
        )
        if (response) {
          const updatedItem = self.putItem(response.data?.data, url)
          self.loading = false
          return updatedItem
        }
      } catch (error) {
        console.log(error)
        if (options?.throwError) {
          throw error
        }
      } finally {
        self.loading = false
      }
    }),
    create(data, options) {
      return self.post(data, { action: `create${self.modelName}`, ...options })
    },
    patchFormData: flow(function* patchFormData(data, options) {
      self.loading = true
      try {
        const url = options?.customUrl || self.url
        const id = options?.customId || data.id
        const response = yield getEnv(self).client.patch(`${url}/${id}`, data, {
          headers: { 'Content-Type': 'multipart/form-data;' },
          action: `patch${self.modelName}`,
          ...options,
        })
        if (response) {
          const updatedItem = self.putItem(response.data?.data, url)
          self.loading = false
          return updatedItem
        }
      } catch (error) {
        console.log(error)
        if (options?.throwError) {
          throw error
        }
      } finally {
        self.loading = false
      }
    }),
    patch: flow(function* patch(data, options) {
      self.loading = true
      try {
        const url = options?.customUrl || self.url
        const id = options?.customId || data.id
        const idUrlParam = id && !options?.withoutId ? `/${id}` : ''
        const serializedItem = self.serialize(data)
        const response = yield getEnv(self).client.patch(
          `${url}${idUrlParam}/`,
          serializedItem,
          {
            action: `patch${self.modelName}`,
            ...options,
          },
        )
        if (response) {
          const updatedItem = self.putItem(response.data?.data, url)
          self.loading = false
          return updatedItem
        }
      } catch (error) {
        console.log(error)
        if (options?.throwError) {
          throw error
        }
      } finally {
        self.loading = false
      }
    }),
    save: flow(function* save(item, options) {
      if (!item._dirtyFields.length) {
        return item
      }
      self.loading = true
      try {
        const url = options?.customUrl || self.url
        const id = options?.customId || item.id
        const idUrlParam = id && !options?.withoutId ? `/${id}` : ''
        const dirtyFields = item.dirtyFields()
        const serializedItem = self.serialize({ id: item.id, ...dirtyFields })
        // TODO only used for contractTokenId
        if (options?.customId) {
          serializedItem.id = options.customId
          serializedItem.data.id = options.customId
        }

        const response = yield getEnv(self).client.patch(
          `${url}${idUrlParam}/`,
          serializedItem,
          {
            ...options,
          },
        )
        if (response && !options?.avoidUpdate) {
          const updatedItem = self.putItem(response.data?.data, url)
          item.clearDirtyFields()
          self.loading = false
          return updatedItem
        }
      } catch (error) {
        console.log(error)
        if (options?.throwError) {
          throw error
        }
      } finally {
        self.loading = false
      }
    }),
    put(data, options) {
      const item = self.map.put(data, options)
      return item
    },
    clear() {
      self.map.clear()
    },
  }))

export default Store
