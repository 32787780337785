import { BaseRouteState, Policy } from '@shared/data-access'
import { actions } from '@sr/routes/common'
import { types } from 'mobx-state-tree'
import { stateActions } from './actions'
import { views } from './views'

export const DashboardCoordinatorRouteState = BaseRouteState.named(
  'DashboardCoordinatorRouteState',
)
  .props({
    noScheduledPolicies: types.maybeNull(types.array(types.reference(Policy))),
    noDigitalPolicies: types.maybeNull(types.array(types.reference(Policy))),
  })
  .volatile(() => ({
    onSelectUser: null,
  }))
  .views(views)
  .actions((self) => ({
    ...stateActions(self),
    ...actions(self),
  }))
