import { useRouteState } from '@shared/utils'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { SRAddButton } from '@sr/ui'
import { AddButtonClasses, DotListClasses } from './styles'
import { useTranslation } from 'react-i18next'
import { useLayoutContext } from '@sr/routes/common'
import { CampaignAppointmentManagerRouteState } from './state'
import { ROUTE_PATH_CAMPAIGN } from 'apps/social-referent/src/routes/enums'
import { hasFutureInitialReport } from '@sr/routes/care/user-area/appointment-manager/new-event/enums'
import { AppointmentList } from '@sr/routes/common/appointment-list'

type NewEventProps = {
  id: string
  userAreaUrl?: string
}

export const NewEvent: FC<NewEventProps> = observer(({ id }) => {
  const { isLoading, appointmentsFiltered, firstServiceId } = useRouteState(
    CampaignAppointmentManagerRouteState,
    {
      annuityId: id,
    },
    )

  const { setIsLoading } = useLayoutContext()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const agendaRoute = () =>
    `../${ROUTE_PATH_CAMPAIGN.userAgenda(firstServiceId)}`

  const goAgenda = () => {
    navigate(agendaRoute(), {
      state: { postCs: true, annuityId: id },
    })
  }
  const handleClick = () => {
    goAgenda()
  }

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  return (
    <>
      <div className="relative">
        <h2 className="text-primary text-xl absolute right-[85px] top-[35px]">
          {t(`appointmentManager.createEvent`)}
        </h2>
        <SRAddButton
          action={async () => {
            handleClick()
          }}
          className={`${AddButtonClasses} ${
            hasFutureInitialReport(appointmentsFiltered)
              ? 'cursor-not-allowed'
              : ''
          } `}
          disabled={hasFutureInitialReport(appointmentsFiltered)}
        />
      </div>

      <div className={DotListClasses}>
        <AppointmentList
          events={appointmentsFiltered}
          onClick={goAgenda}
        />
      </div>
    </>
  )
})
