import { Badge, Finance, Events } from '@carbon/icons-react'
import { SrTag } from '@shared/ui'
import { SRModal } from '@sr/ui'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalData, SrCampaignTypeProps } from './types'
import './styles.scss'
import { LANGUAGES } from '@shared/utils'

export const SrCampaignType: FC<SrCampaignTypeProps> = ({
  annuity,
  hasClick = true,
  size = 'medium',
}) => {
  const { t } = useTranslation()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<ModalData>({
    title: '',
    description: '',
  })

  const campaign = useMemo(() => {
    const campaignName = annuity?.campaign?.campaignName
    const campaignDesc = annuity?.campaign?.campaignMeta?.campaignDesc
    const lang = annuity?.person?.preferredLanguage

    return {
      name: campaignName,
      description: campaignDesc?.[lang] || campaignDesc?.[LANGUAGES.es],
    }
  }, [annuity])

  const product = useMemo(() => {
    const productName = annuity?.product?.productMeta?.productName
    const lang = annuity?.person?.preferredLanguage

    return {
      name: productName?.[lang] || productName?.[LANGUAGES.es],
    }
  }, [annuity])

  const collective = useMemo(() => {
    return {
      name: annuity?.collective?.companyName,
    }
  }, [annuity])

  const showCampaignModalDescription = () => {
    if (!campaign.description) return
    setModalData({
      title: t('campaign.userArea.tagsModal.campaignDescription'),
      description: campaign.description,
    })
    setShowModal(true)
  }

  return (
    <div className="campaign-type">
      {campaign.name && (
        <SrTag
          size={size}
          onClick={showCampaignModalDescription}
          color="bg-[#52796F]"
          disabled={!hasClick}
        >
          <Badge size={20} />
          <p>{campaign.name}</p>
        </SrTag>
      )}
      {product.name && (
        <SrTag size={size} color={'bg-[#84A98C]'} disabled>
          <Finance size={20} />
          <p>{product.name}</p>
        </SrTag>
      )}
      {collective.name && (
        <SrTag size={size} color={'bg-[#95D5B2]'} disabled>
          <Events size={20} />
          <p>{collective.name}</p>
        </SrTag>
      )}
      {showModal && (
        <SRModal
          className="campaign-type__modal"
          onClose={() => setShowModal(false)}
        >
          <p className="campaign-type__modal-title">{modalData.title}</p>
          <p className="campaign-type__modal-description">
            {modalData.description}
          </p>
        </SRModal>
      )}
    </div>
  )
}
