import {
  buttonSizeMedium,
  variantContainedPrimaryClasses,
} from 'libs/shared/ui/src/lib/components/button/styles'

export const cssClassesForSurvey = Object.freeze({
  root: 'sr-root',
  body: 'my-0 mx-auto w-11/12',
  page: {
    root: '',
  },
  bodyNavigationButton: 'sr-btn',
  question: {
    header: 'w-[45%] xl:w-[50%] whitespace-pre-line',
    title: 'font-montserrat text-sm xl:text-base',
    content: 'w-[45%] xl:w-[40%]',
  },
  radiogroup: {
    root: 'grid grid-cols-2 gap-2.5',
    rootRow: 'flex gap-2.5 flex-wrap',
    column: '',
    label:
      'sr-radio-box flex items-center flex-wrap p-2 gap-2 text-sm xl:text-base xl:p-2.5 xl:gap-2.5 border border-zinc-300 text-zinc-500 rounded w-full',
    controlLabel: '',
    item: '',
  },
  panel: {
    header: 'my-3 py-2 border-b border-[#D9D9D9]',
    content: 'sd-panel__content',
    titleExpandable: '',
    titleExpanded: 'sr-element__title--expanded sr-element__title',
    titleCollapsed: 'sr-element__title--collapsed sr-element__title',
  },
  text: {
    root: 'flex items-center p-2.5 gap-2.5 border border-zinc-300 text-zinc-500 rounded w-full',
  },
  actionBar: {
    root: 'sd-action-bar justify-center mt-8 relative',
  },
  navigation: {
    complete: `${variantContainedPrimaryClasses} ${buttonSizeMedium} rounded cursor-pointer`,
  },
})
