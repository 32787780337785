import { flow, types } from 'mobx-state-tree'
import { BaseRouteState, Policy } from '@shared/data-access'
import { actions } from '@sr/routes/common'
import { InitialAnalysis } from '@sr/data-access'

export const PreInitialReportRouteState = BaseRouteState.named(
  'PreInitialReportRouteState'
)
  .props({
    policyId: types.maybeNull(types.string),
    policy: types.maybeNull(types.reference(Policy)),
    familyBackground: types.maybeNull(types.reference(InitialAnalysis)),
  })
  .views((self) => ({
    get hasStartedTest() {
      return !!self.resultsParsed?.policy_id
    },
    get resultsParsed() {
      return self.familyBackground && JSON.parse(self.familyBackground.prevData)
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('PreInitialReportRouteState')
      try {
        yield self.loadPolicy()
        yield self.getAllInitialTest()
      } finally {
        self.removeLoading('PreInitialReportRouteState')
      }
    }),
    ...actions(self),
  }))
