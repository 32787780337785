import { BaseRouteState, Policy, SocialReferent, ClientPreferences } from '@shared/data-access'
import { flow, getEnv, types, getRoot } from 'mobx-state-tree'
import { actions, getCarePersonalData } from '@sr/routes/common'
import { t } from 'i18next'

export const UserProfileRouteState = BaseRouteState.named(
  'UserProfileRouteState',
)
  .props({
    policyId: types.maybeNull(types.string),
    policy: types.maybeNull(types.reference(Policy)),
    user: types.maybeNull(types.reference(SocialReferent)),
    clientPreferences: types.maybeNull(types.reference(ClientPreferences)),
    clientPreferencesId: types.maybeNull(types.string),
    isEditing: false,
  })
  .views((self) => ({
    get userPersonalData() {
      if (!self.policy) return []    
      return getCarePersonalData({
        t,
        isEditing: self.isEditing,
        ...self.policy,
        socialReferent: self.policy.socialReferent?.fullName,
        policyNumber: self.policy.policyNumber,
        contractNumber: self.policy.contractNumber,
      }).filter((val) => val)
    },
    get defaultValues() {
      if (!self.policy) return {}
      return {
        preferredLanguage: self.policy.preferredLanguage,
        sex: self.policy.sex,
      }
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('UserProfileRouteState')
      try {
        const userInfo = self.loadUserInfo()
        const policy = self.loadPolicy()
        const clientPreferences = self.getClientPreferences()

        yield Promise.all([userInfo, policy, clientPreferences])

      } finally {
        self.removeLoading('UserProfileRouteState')
      }
    }),
    getClientPreferences: flow(function* getClientPreferences() {
      self.setLoading('getClientPreferences')
      try {   
        const clientPreferencesData = yield getRoot(self).ClientPreferencesStore.fetch(
          null,
          {
            withoutId: true,
            customUrl: `/policies/${self.policyId}/get_client_preferences`,
          })

        if (clientPreferencesData) { 
          self.clientPreferences = clientPreferencesData
          self.clientPreferencesId = clientPreferencesData.id
        }
      } catch (error) {
        console.error('Error Fetching Client Preferences sent data:', error)
      } finally {
        self.removeLoading('getClientPreferences')}
    }),    
    setEditingMode(mode) {
      self.isEditing = mode
    },
    reload() {
      self.loadDependencies()
    },
    ...actions(self),
  }))
