import { DotList } from '@shared/ui'
import { useRouteState } from '@shared/utils'
import { observer } from 'mobx-react-lite'
import { MonitoringAreaAgendaRouteState } from './state'
import { useParams } from 'react-router-dom'
import { useLayoutContext } from '@sr/routes/common'
import { useEffect } from 'react'

export const MonitoringAgenda = observer(() => {
  const { id } = useParams()
  const { isLoading, appointmentsFiltered } = useRouteState(
    MonitoringAreaAgendaRouteState,
    {
      policyId: id,
    },
  )

  const { setIsLoading } = useLayoutContext()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  return <DotList items={appointmentsFiltered} />
})
