import { SRInput, SRMultiSelect, SRSelect, SRTextarea } from '@sr/ui'
import { FORM_TYPE, InputPhone } from '@shared/ui'
import { EMPLOYEE_STATE, EMPLOYEE_STATE_TEXT } from '@shared/data-access'
import { LANGUAGES_OPTIONS_ARRAY, getDateFormatted } from '@shared/utils'

export const MY_PROFILE_FORM_FIELDS = Object.freeze({
  name: 'name',
  surname: 'surname',
  phone: 'phone',
  email: 'email',
  postalCode: 'postalCode',
  collegiateNo: 'collegiateNo',
  activeFrom: 'activeFrom',
  academicTitle: 'academicTitle',
  employeeState: 'employeeState',
  country: 'country',
  region: 'region',
  locality: 'locality',
  street: 'street',
  professionalDescEs: 'professionalDescEs',
  professionalDescCa: 'professionalDescCa',
  knownLanguages: 'knownLanguages',
})

const createSRInput = (type, defaultValue, name) => (
  <SRInput type={type} defaultValue={defaultValue} name={name} />
)

export const getFields = (t, user, isCoordinator, activeLanguage) => {
  const options = [
    {
      name: t(`common:${EMPLOYEE_STATE_TEXT[EMPLOYEE_STATE.ACTIVE]}`),
      value: EMPLOYEE_STATE.ACTIVE,
    },
    {
      name: t(`common:${EMPLOYEE_STATE_TEXT[EMPLOYEE_STATE.INACTIVE]}`),
      value: EMPLOYEE_STATE.INACTIVE,
    },
  ]

  const languageDesc = Object.keys(MY_PROFILE_FORM_FIELDS).filter((key) =>
    key.startsWith('professionalDesc'),
  )

  const language = {
    professionalDescEs: 'ES',
    professionalDescCa: 'CAT',
  }

  const getDescriptionFields = () => {
    return languageDesc.map((description) => ({
      label: '',
      isTextarea: true,
      input: (
        <SRTextarea
          type={FORM_TYPE.text}
          defaultValue={user[description]}
          name={MY_PROFILE_FORM_FIELDS[description]}
          isVisible={language[description] === activeLanguage}
        />
      ),
    }))
  }
  return [
    ...getDescriptionFields(),
    {
      label: t('common:phone'),
      input: (
        <InputPhone
          className="h-16"
          type={FORM_TYPE.phone}
          value={user.phone}
          name={MY_PROFILE_FORM_FIELDS.phone}
        />
      ),
    },
    {
      label: t('common:postalCode'),
      input: createSRInput(
        FORM_TYPE.text,
        user.postalCode,
        MY_PROFILE_FORM_FIELDS.postalCode,
      ),
    },
    {
      label: t('common:country'),
      input: createSRInput(
        FORM_TYPE.text,
        user.country,
        MY_PROFILE_FORM_FIELDS.country,
      ),
    },
    {
      label: t('common:region'),
      input: createSRInput(
        FORM_TYPE.text,
        user.region,
        MY_PROFILE_FORM_FIELDS.region,
      ),
    },
    {
      label: t('common:locality'),
      input: createSRInput(
        FORM_TYPE.text,
        user.locality,
        MY_PROFILE_FORM_FIELDS.locality,
      ),
    },
    {
      label: t('common:street'),
      input: createSRInput(
        FORM_TYPE.text,
        user.street,
        MY_PROFILE_FORM_FIELDS.street,
      ),
    },
    {
      label: t('common:employeeState.label'),
      input: (
        <SRSelect
          disabled={!isCoordinator || !user.canSuspendSr}
          options={options}
          defaultValue={user.employeeState}
          name={MY_PROFILE_FORM_FIELDS.employeeState}
        />
      ),
    },
    {
      label: t('common:academicTitle'),
      input: createSRInput(
        FORM_TYPE.text,
        user.academicTitle,
        MY_PROFILE_FORM_FIELDS.academicTitle,
      ),
    },
    {
      label: t('common:collegiateNo'),
      input: createSRInput(
        FORM_TYPE.text,
        user.collegiateNo,
        MY_PROFILE_FORM_FIELDS.collegiateNo,
      ),
    },
    {
      label: t('common:activeFrom'),
      input: (
        <SRInput
          type={FORM_TYPE.date}
          disabled={!isCoordinator}
          defaultValue={getDateFormatted(user.activeFrom, 'YYYY-MM-DD')}
          name={MY_PROFILE_FORM_FIELDS.activeFrom}
        />
      ),
    },
    {
      label: t('common:languagesLabel'),
      input: (
        <SRMultiSelect
          options={LANGUAGES_OPTIONS_ARRAY(t)}
          name={MY_PROFILE_FORM_FIELDS.knownLanguages}
          isMulti
          isRequired
          defaultValue={user.knownLanguages}
          placeholder={t('common:chooseLanguage')}
        />
      ),
    },
  ]
}

export default getFields
