import { View } from '@react-pdf/renderer'
import { SubSection, TranscriptionScriptPDF } from '../../../components'
import { useReportPdfContext } from '../../../provider'
import { FC } from 'react'
import { getRecommendations, tw } from '../../../utils'

type AuditiveAndOcularResultsProps = {
  sectionLetter: string
}

export const AuditiveAndOcularResults: FC<AuditiveAndOcularResultsProps> = ({
  sectionLetter,
}) => {
  const context = useReportPdfContext()
  const { t, person, recommendations } = context

  const TRANSLATION_BASE_KEY = 'initial-report:sectionAuditiveAndOcular.results'

  const auditiveFeedback: string[] = getRecommendations({
    recKeys: recommendations?.AUDITORY,
    generalKey: '',
    defaultKey: 'rec_auditory_ocular_auditory_no_issues',
  })

  const ocularFeedback: string[] = getRecommendations({
    recKeys: recommendations?.OCULAR,
    generalKey: 'rec_auditory_ocular_generic_vision',
    defaultKey: 'rec_auditory_ocular_ophthalmological',
  })

  return (
    <View style={tw('px-12')}>
      <SubSection
        title={t('initial-report:subSection.results.title', {
          letter: sectionLetter,
        })}
      >
        {/** Auditive and Ocular Feedback **/}
        <>
          {auditiveFeedback.map((key) => {
            if (!key) return null
            return (
              <TranscriptionScriptPDF
                key={key}
                script={`${TRANSLATION_BASE_KEY}.feedback.${key}`}
                scriptProps={{ patientName: person?.name }}
              />
            )
          })}
          <TranscriptionScriptPDF script={'\n'} />
          {ocularFeedback.map((key) => {
            if (!key) return null
            return (
              <TranscriptionScriptPDF
                key={key}
                script={`${TRANSLATION_BASE_KEY}.feedback.${key}`}
                scriptProps={{ patientName: person?.name }}
              />
            )
          })}
        </>
      </SubSection>
    </View>
  )
}
