import { SRSelect, SRSelectOptions } from '@sr/ui'
import { ServiceBlockFormValues, TRANSLATION_BASE_KEY } from '../enums'
import { useTranslation } from 'react-i18next'
import { BUTTON_SIZES, Button } from '@shared/ui'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import { useFormContext } from 'react-hook-form'
import { FC } from 'react'

type RejectSelectProps = {
  action: () => void
  rejectOptions: SRSelectOptions
}

export const RejectSelect: FC<RejectSelectProps> = ({
  action,
  rejectOptions,
}) => {
  const { t } = useTranslation()

  const { isLoading } = useLayoutContext()
  const { watch } = useFormContext()

  const rejectValue = watch(ServiceBlockFormValues.subState)

  return (
    <>
      <SRSelect
        placeholder={t(`${TRANSLATION_BASE_KEY}.form.serviceRejectPlaceholder`)}
        name={ServiceBlockFormValues.subState}
        options={rejectOptions}
      />
      <Button
        onClick={action}
        size={BUTTON_SIZES.small}
        disabled={isLoading || !rejectValue}
      >
        {t('common:save')}
      </Button>
    </>
  )
}
