import { ROUTE_PATH } from './enums'
import {
  InitialReport,
  PreSubscriptionControl,
  SubscriptionControl,
  MyProfile,
  UserList,
  MonitoringArea,
  Schedule,
} from '@sr/routes/index'

import { PreInitialReport } from '@sr/routes/initial-report/pre-initial-report'
import { Messages } from '@sr/routes/monitoring-area/messages'
import { Communications } from '@sr/routes/monitoring-area/communications'
import { Register } from '@sr/routes/monitoring-area/register'
import { MonitoringAgenda } from '@sr/routes/monitoring-area/agenda'
import { Services } from '@sr/routes/monitoring-area/services'
import { ServiceDetail } from '@sr/routes/monitoring-area/services/detail'
import {
  CareAnalysis,
  CareAppointmentManager,
  CareUserProfile,
  TestListCare,
  UserAreaCare,
} from '@sr/routes/care/user-area'
import { TestScript } from '@sr/routes/common'

export const myBoxCareRoutes = (userAreaRoutes = []) => [
  {
    path: ROUTE_PATH.userList,
    element: <UserList />,
  },
  {
    path: ROUTE_PATH.srProfile(':id'),
    element: <MyProfile />,
  },
  {
    path: ROUTE_PATH.userArea(':id'),
    children: [
      {
        index: true,
        element: <UserAreaCare />,
      },
      {
        path: ROUTE_PATH.preSubscriptionControl,
        element: <PreSubscriptionControl />,
      },
      {
        path: ROUTE_PATH.subscriptionControl,
        element: <SubscriptionControl />,
      },
      {
        path: ROUTE_PATH.userProfile,
        element: <CareUserProfile />,
      },
      {
        path: ROUTE_PATH.monitoringArea,
        element: <MonitoringArea />,
        children: [
          {
            path: ROUTE_PATH.monitoringChat,
            element: <Messages />,
          },
          {
            path: ROUTE_PATH.monitoringAgenda,
            element: <MonitoringAgenda />,
          },
          {
            path: ROUTE_PATH.monitoringRegister,
            element: <Register />,
          },
          {
            path: ROUTE_PATH.monitoringCommunications,
            element: <Communications />,
          },
          {
            path: ROUTE_PATH.monitoringServices,
            element: <Services />,
          },
        ],
      },
      {
        path: ROUTE_PATH.monitoringServicesDetail,
        element: <ServiceDetail />,
      },
      {
        path: ROUTE_PATH.preInitialReport,
        element: <PreInitialReport />,
      },
      {
        path: ROUTE_PATH.initialReport,
        element: <InitialReport />,
      },
      {
        path: ROUTE_PATH.scriptTest(':testId'),
        element: <TestScript userAreaUrl="user-area" />,
      },
      {
        path: ROUTE_PATH.analysis(':testId'),
        element: <CareAnalysis />,
      },
      {
        path: ROUTE_PATH.newTest,
        element: <TestListCare />,
      },
      {
        path: ROUTE_PATH.schedule,
        element: <Schedule />,
      },
      {
        path: ROUTE_PATH.appointmentManager,
        element: <CareAppointmentManager />,
      },
      ...userAreaRoutes,
    ],
  },
]
