import tw from 'tailwind-styled-components'

export const PreviewScreenVideo = tw.div`
    h-[350px]
    w-full
    lg:rounded-xl
    lg:shadow-md
    lg:shadow-black
    mb-8
`
export const UnsupportedMediaVideo = tw.div`
    h-[350px]
    w-full
    bg-grayLight
    flex
    flex-col
    gap-4
    justify-center
    items-center
    p-8
`
export const UnsupportedMediaText = tw.div`
    text-center
    self-center
    text-black
    font-bold
`

export const SelectCameraContainer = tw.div`
    p-4
    flex 
    justify-center

`

export const selectClassName = `
    bg-white
    border
    border-solid
    border-gray
    w-full
    py-2.5
    px-3
    rounded
    text-zinc-500
    disabled:opacity-75
    disabled:cursor-not-allowed
`
