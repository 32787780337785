import { useParticipants } from '../../../../hooks'
import { ParticipantAudioTrack } from './participant-audio-track/participant-audio-track'

/*
  This ParticipantAudioTracks component will render the audio track for all participants in the room.
  It is in a separate component so that the audio tracks will always be rendered, and that they will never be 
  unnecessarily unmounted/mounted as the user switches between Gallery View and Speaker View.
*/
export const ParticipantAudioTracks = () => {
  const participants = useParticipants()

  return participants.map((participant) => (
    <ParticipantAudioTrack key={participant.sid} participant={participant} />
  ))
}
