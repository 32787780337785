import { List } from './list'
import { useState } from 'react'
import { Filters } from './filters'
import { PortfolioManagementListContainer } from '../common'
import { observer } from 'mobx-react-lite'

export const CareList = observer(() => {
  const [filters, setFilters] = useState({})

  return (
    <PortfolioManagementListContainer>
      <Filters onChangeFilters={setFilters} />
      <List filters={filters} />
    </PortfolioManagementListContainer>
  )
})
