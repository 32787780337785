
import { getHour, getMinute } from '@shared/utils'
import { t } from 'i18next'

export const FORM_NAME = {
    noDigital: 'noDigital',
    firstAppointmentDate: 'firstAppointmentDate',
    originQida: 'originQida',
} as const
  
export const EXTRA_DAY_DIGITAL_FIRST_WEEK = 1
export const EXTRA_DAY_NO_DIGITAL_FIRST_WEEK = 2
export const DAYS_OF_THE_WEEK = 7
export const EXTRA_DAY_DIGITAL_SECOND_WEEK = EXTRA_DAY_DIGITAL_FIRST_WEEK + DAYS_OF_THE_WEEK // 8
export const EXTRA_DAY_NO_DIGITAL_SECOND_WEEK = EXTRA_DAY_NO_DIGITAL_FIRST_WEEK + DAYS_OF_THE_WEEK // 9

interface ScheduleHour {
    startHour: number
    startMinute: number
    endHour: number
    endMinute: number
  }
  
  // Define type for the return value of getScheduleHours
  interface ScheduleOption {
    value: number
    name: string
  }
  
  // Define schedule hours
  export const SCHEDULE_HOURS: ScheduleHour[] = [
    { startHour: 9, startMinute: 0, endHour: 10, endMinute: 30 },
    { startHour: 10, startMinute: 30, endHour: 12, endMinute: 0 },
    { startHour: 12, startMinute: 0, endHour: 13, endMinute: 30 },
    { startHour: 13, startMinute: 30, endHour: 15, endMinute: 0 },
    { startHour: 15, startMinute: 0, endHour: 16, endMinute: 30 },
    { startHour: 16, startMinute: 30, endHour: 18, endMinute: 0 },
    { startHour: 18, startMinute: 0, endHour: 19, endMinute: 30 },
  ]
  
  export const SCHEDULE_FACE_TO_FACE: ScheduleHour[] = [
    { startHour: 9, startMinute: 0, endHour: 13, endMinute: 30 },
    { startHour: 13, startMinute: 30, endHour: 18, endMinute: 0 },
  ]
  
  // Function to get schedule hours with translated names
  export const getScheduleHours = (): ScheduleOption[] => {
    return SCHEDULE_HOURS.map((hours, ix) => ({
      value: ix,
      name: t(`scheduleSlotLabel.${hours.startHour}`),
    }))
  }
  
  // Function to find the index of a slot in the schedule hours
  export const getSlotInScheduleHours = (startDate: Date): number => {
    const hour = getHour(startDate)
    const minute = getMinute(startDate)
  
    const indx = SCHEDULE_HOURS.findIndex(
      (el) => el.startHour === hour && el.startMinute === minute
    )
  
    return indx !== -1 ? indx : 0
  }
