type getRecommendationsProps = {
  recKeys: string[] | undefined
  generalKey?: string
  defaultKey: string
}

export const getRecommendations = ({
  recKeys,
  generalKey = '',
  defaultKey,
}: getRecommendationsProps): string[] => {
  if (!recKeys || !recKeys.length) return [defaultKey]
  else if (recKeys.length > 1 && generalKey) return [generalKey]
  else return recKeys
}

type getExactKeyProps = {
  array: string[]
  key: string
}

export const getExactKey = ({ array = [], key = '' }: getExactKeyProps) => {
  return array.find((string: string) => key === string)
}
