export const ExclamationIconSvg = ({
  className = '',
}: {
  className?: string
}) => {
  return (
    <svg
      className={className}
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="26" cy="26" r="25.5" fill="white" stroke="#007FB3" />
      <path
        d="M27.7969 30.0312H25.0312L24.2812 14.1562H28.5469L27.7969 30.0312ZM24.0938 35.0625C24.0938 34.2083 24.3125 33.6094 24.75 33.2656C25.1979 32.9115 25.7448 32.7344 26.3906 32.7344C27.026 32.7344 27.5677 32.9115 28.0156 33.2656C28.4635 33.6094 28.6875 34.2083 28.6875 35.0625C28.6875 35.8958 28.4635 36.5 28.0156 36.875C27.5677 37.2396 27.026 37.4219 26.3906 37.4219C25.7448 37.4219 25.1979 37.2396 24.75 36.875C24.3125 36.5 24.0938 35.8958 24.0938 35.0625Z"
        fill="#007EAE"
      />
    </svg>
  )
}
