import { GLOBAL_ENVIRONMENT, useRouteState } from '@shared/utils'
import { useParams } from 'react-router-dom'
import { ServiceDetailRouteState } from './state'
import { useEffect, useState } from 'react'
import { BUTTON_COLORS, Button } from '@shared/ui'
import { observer } from 'mobx-react-lite'
import { SRUserName } from '@sr/ui'
import { useTranslation } from 'react-i18next'

import { ToolBox } from '@carbon/icons-react'
import { ConfirmAccept } from './confirm-accept/confirm-accept'
import {
  ServiceDetailInfo,
  ServiceDetailInfoService,
  ServiceDetailInfoServiceActions,
  ServiceDetailInfoServiceDescription,
  ServiceDetailInfoTitle,
  ServiceDetailWrapper,
} from './style'
import { useLayoutContext } from '@sr/routes/common'
import { ROUTE_NAME, ROUTE_PATH } from 'apps/social-referent/src/routes/enums'

export const ServiceDetail = observer(() => {
  const { t } = useTranslation()

  const { id, serviceId } = useParams()

  const { setBackButtonText, setIsLoading } = useLayoutContext()

  const {
    policy,
    personService,
    acceptPersonService,
    personServiceAccepted,
    isLoading,
  } = useRouteState(ServiceDetailRouteState, {
    policyId: id,
    personServiceId: serviceId,
  })

  useEffect(() => {
    setBackButtonText(t(ROUTE_NAME[ROUTE_PATH.monitoringServicesDetail]))

    return () => {
      setBackButtonText('')
    }
  }, [])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [showConfirmButton, setShowConfirmButton] = useState(false)

  if (!personService || !policy) return

  const acceptPolicy = async () => {
    await acceptPersonService()
    setShowModalConfirm(false)
  }

  const dasUrl =
    process.env.NX_ENV === GLOBAL_ENVIRONMENT.prod
      ? 'https://outlook.office365.com/book/Qida1@onlygal.es/'
      : 'https://outlook.office365.com/owa/calendar/test_Quida@onlygal.es/bookings/'

  return (
    <div className={ServiceDetailWrapper}>
      {showModalConfirm && (
        <ConfirmAccept
          onAccept={acceptPolicy}
          onClose={() => setShowModalConfirm(false)}
        />
      )}
      <div className={ServiceDetailInfo}>
        <SRUserName fullName={policy.fullName} />
        <div className={ServiceDetailInfoService}>
          <div className={ServiceDetailInfoTitle}>
            <ToolBox className="text-primary" size={25} />
            <p>{t(`${personService.service.serviceName}.title`)}</p>
          </div>
          <div className={ServiceDetailInfoServiceDescription}>
            <div className={ServiceDetailInfoTitle}>
              <p>{t('userArea.monitoringArea.services.state')}:</p>
              <p>
                <strong>{t(personService.personServiceStateName)}</strong>
              </p>
            </div>
            <div>{t('userArea.monitoringArea.services.description')}:</div>
            <div>{t(`${personService.service.serviceName}.description`)}</div>
            <div>{t(`userArea.monitoringArea.services.necesaryInfo`)}</div>
            <div>
              {t('common:fullName')}: <strong>{policy.fullName}</strong>
            </div>
            <div>
              {t('common:phone')}: <strong>{policy.phone}</strong>
            </div>
            <div>
              {t('common:email')}: <strong>{policy.email}</strong>
            </div>
          </div>
          <div className={ServiceDetailInfoServiceActions}>
            {!personServiceAccepted && (
              <Button
                onClick={() => {
                  window.open(dasUrl, { target: '_blank' })
                  setShowConfirmButton(true)
                }}
              >
                {t('userArea.monitoringArea.services.das')}
              </Button>
            )}
            {showConfirmButton && !personServiceAccepted && (
              <Button
                color={BUTTON_COLORS.secondary}
                onClick={() => setShowModalConfirm(true)}
              >
                {t('userArea.monitoringArea.services.confirm')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
