import { flow, types } from 'mobx-state-tree'
import { BaseRouteState, SocialReferent } from '@shared/data-access'
import { actionsAnalysis, actions } from '@sr/routes/common'
import { GenericAnalysisModel } from '@sr/data-access'

export const TestScriptRouteState = BaseRouteState.named('TestScriptRouteState')
  .props({
    testId: types.string,
    test: types.maybeNull(types.reference(GenericAnalysisModel)),
    user: types.maybeNull(types.reference(SocialReferent)),
  })
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('TestScriptRouteState')
      try {
        yield self.loadTest()
        yield self.loadUserInfo()
      } finally {
        self.removeLoading('TestScriptRouteState')
      }
    }),
    ...actions(self),
    ...actionsAnalysis(self),
  }))
