import { BUTTON_COLORS, Button } from '@shared/ui'
import { SRModal } from '@sr/ui'
import { useTranslation } from 'react-i18next'
import { ConfirmAcceptActions, ConfirmAcceptWrapper } from './style'

export const ConfirmAccept = ({ onAccept, onClose }) => {
  const { t } = useTranslation()

  return (
    <SRModal onClose={onClose}>
      <div className={ConfirmAcceptWrapper}>
        <p>{t('userArea.monitoringArea.services.confirmModal.action')}</p>
        <p>{t('userArea.monitoringArea.services.confirmModal.confirmText')}</p>
        <p>
          {t('userArea.monitoringArea.services.confirmModal.confirmAreYouSure')}
        </p>
        <div className={ConfirmAcceptActions}>
          <Button onClick={onClose}>{t('common:cancel')}</Button>
          <Button color={BUTTON_COLORS.secondary} onClick={onAccept}>
            {t('common:confirm')}
          </Button>
        </div>
      </div>
    </SRModal>
  )
}
