import React, { useMemo } from 'react'
import { getLocalStorageItem } from '@shared/utils'
import { FontSizeName } from './types'

interface IProps {
  children: React.ReactNode
  className?: string
}

const DSTitle: React.FC<IProps> = ({ children, className = '' }) => {
  const fontSize = useMemo(() => {
    return getLocalStorageItem('fontSize') as FontSizeName
  }, [])

  function getFontSize() {
    switch (fontSize) {
      case FontSizeName.large:
        return 'text-[20px]'
      case FontSizeName.extraLarge:
        return 'text-[24px]'
      default:
        return 'text-[18px]'
    }
  }

  return (
    <p
      className={`font-aeonik leading-6 text-left font-medium ${getFontSize()} ${className}`}
    >
      {children}
    </p>
  )
}

export default DSTitle
