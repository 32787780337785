import { BaseRouteState, SocialReferent } from '@shared/data-access'
import { flow, types } from 'mobx-state-tree'
import { actions } from '@sr/routes/common'
import { stateViews } from './views'

export const MyProfileState = BaseRouteState.named('MyProfileState')
  .props({
    socialReferentId: types.maybeNull(types.string),
    socialReferent: types.maybeNull(types.reference(SocialReferent)),
    user: types.maybeNull(types.reference(SocialReferent)),
    isTranslated: false,
    activeLanguage: types.optional(types.string, 'ES'),
    errors: types.optional(types.map(types.boolean), {}),
  })
  .views(stateViews)
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('MyProfileState')
      try {
        const socialReferent = self.loadSocialReferent(self.socialReferentId)
        const userInfo = self.loadUserInfo()

        yield Promise.all([userInfo, socialReferent])
      } finally {
        self.removeLoading('MyProfileState')
      }
    }),
    toggleLanguage(language) {
      self.activeLanguage = language
    },
    ...actions(self),
  }))
