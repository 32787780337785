import tw from 'tailwind-styled-components'

export const ProfileDropdownContainer = tw.div`
    absolute
    top-16
    right-4
    xl:right-16
    bg-neutral-100
    text-primary
    w-60
    px-7
    border
    border-black
    z-10
`

export const ProfileDropdownOption = tw.div`
    flex
    items-center
    gap-2
    cursor-pointer
    my-4
`

export const ProfileDivider = tw.hr`
    border-primary
`
