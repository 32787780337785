import { DateString, Policy, RootModel } from '@shared/data-access'
import { getRoot, types } from 'mobx-state-tree'
import { POLICY_REMINDER_CODE, POLICY_REMINDER_TYPE } from './enums'

export const MODEL_NAME = 'PolicyRemainder'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    policy: types.maybeNull(types.reference(Policy)),
    code: types.maybeNull(
      types.refinement(types.string, (value) =>
        Object.values(POLICY_REMINDER_CODE).some((code) => code === value)
      )
    ),
    remainderType: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(POLICY_REMINDER_TYPE).some((type) => type === value)
      )
    ),
    sentDate: types.maybeNull(DateString),
    confirmedDate: types.maybeNull(DateString),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))

export default Model
