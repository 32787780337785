import * as yup from 'yup'
import { FORM_NAME } from './enums'

export const useSchema = (t: (translation: string) => string) => {
  return yup.object().shape({
    [FORM_NAME.modality]: yup
      .number()
      .required()
      .label(t('common:modality'))
      .typeError(t('validations:errors.mixed.required')),
  })
}
