import { useTranslation } from 'react-i18next'
export const CLIENT_FORM = Object.freeze({
  email: 'email',
  phone: 'phone',
  noDigital: 'noDigital',
  firstAppointmentDate: 'firstAppointmentDate',
  officeControl: 'officeControl',
  officeName: 'officeName',
})

export const CLIENT_STATUS = Object.freeze({
  active: 0,
  inactive: 1,
  deceased: 2,
})

export const LABEL_KEY_RADIO_FORM = Object.freeze({
  home: 'home',
  office: 'office',
})

export const RADIO_SEARCH_OFFICE_NAMES = Object.freeze({
  select: 'subscriptionOfficeId',
  text: 'managerOfficeDirection',
})

export const RADIO_OFFICE_VALUE = Object.freeze({
  home: '0',
  office: '1',
})

export const BOOLEAN_STRING_VALUES = Object.freeze({
  TRUE: '1',
  FALSE: '0',
})

export const isRadioChecked = (
  name,
  value,
  fieldValue,
  noDigital,
  isNoDigital,
) => {
  if (name === CLIENT_FORM.officeControl && value !== undefined) {
    const valueAsBoolean = value === BOOLEAN_STRING_VALUES.TRUE
    return (
      (valueAsBoolean === fieldValue && isNoDigital === noDigital) ||
      (value === fieldValue && isNoDigital === noDigital)
    )
  }
  return value === fieldValue
}

export const useTranslationForUserForm = () => {
  const { t } = useTranslation()
  const TRANSLATION_BASE_KEY = 'agenda.userForm.form'
  const translate = (key) => t(`${TRANSLATION_BASE_KEY}.${key}`)
  return translate
}
