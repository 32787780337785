import { SRCard } from '../sr-card/sr-card'
import { srCardContainerClasses, UserAreaContainer } from './styles'

export const SRCardsContainer = ({ cards = [] }) => {
  return (
    <UserAreaContainer>
      {cards.map((card, index) => {
        return (
          <SRCard
            {...card}
            key={index}
            containerClasses={srCardContainerClasses}
          />
        )
      })}
    </UserAreaContainer>
  )
}
