export const setLocalStorageItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getLocalStorageItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (err) {
    return null
  }
}

export const waitForLocalStorageItem = async (key, interval = 0, timeout = 0) => {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    const checkLocalStorage = () => {
      const item = localStorage.getItem(key);
      if (item && item.length > 0) {
        resolve(item);
        clearInterval(checkInterval);
      } else if (Date.now() - startTime >= timeout) {
        reject(new Error('Timeout: localStorage key value did not become non-empty within the specified time.'));
        clearInterval(checkInterval);
      }
    };

    const checkInterval = setInterval(checkLocalStorage, interval);
  });
};
