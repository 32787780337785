import tw from 'tailwind-styled-components'

export const CampaignUserProfileContainer = tw.div`
    flex
    flex-col
    gap-8
    py-10
`

export const CampaignUserProfileHeader = tw.div`
    flex
    gap-4
`
