import { DotList } from '@shared/ui'
import { observer } from 'mobx-react-lite'
import { useRouteState } from '@shared/utils'
import { getHeadersPhoneCall } from './enums'
import { MonitoringAreaRegisterRouteState } from './state'
import { useParams } from 'react-router-dom'
import { useLayoutContext } from '@sr/routes/common'
import { useEffect } from 'react'

export const Register = observer(() => {
  const { id } = useParams()
  const { isLoading, phoneCallsFiltered } = useRouteState(
    MonitoringAreaRegisterRouteState,
    { policyId: id }
  )

  const { setIsLoading } = useLayoutContext()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  return <DotList headers={getHeadersPhoneCall()} items={phoneCallsFiltered} />
})
