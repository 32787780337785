export const wizardContainerClasses = `
    py-8
    h-5/6
    flex
    flex-col
    justify-between
    items-center
    mb-16
`

export const wizardButtonContainerClasses = `
    flex
    justify-center
    items-center
    gap-4
    p-6
`
