import { BaseRouteState } from '@shared/data-access'
import { flow, getRoot, types } from 'mobx-state-tree'

export const AppointmentInfoState = BaseRouteState.named('AppointmentInfoState')
  .props({
    eventId: types.string,
  })
  .actions((self) => ({
    resendCommunications: flow(function* resendCommunications(email, phone) {
      self.setLoading('resendCommunications')
      try {
        let url = `${self.eventId}/resend_appointment_comms/`;
        if (email || phone) {
          const params = new URLSearchParams();
          if (email) params.append('email', email);
          if (phone) params.append('phone', phone);
          url += `?${params.toString()}`;
        }
        
        yield getRoot(self).AppointmentStore.get(url, {
          throwError: true,
          avoidUpdate: true,
        })

        return true
      } catch (error) {
        throw new Error(error)
      } finally {
        self.removeLoading('resendCommunications')
      }
    }),
  }))
