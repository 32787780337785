import { View } from '@react-pdf/renderer'
import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'
import { tw } from '../../utils'
import { TickList } from '../../components/TickList'
import crossIcon from '../../assets/cross-icon.png'
import checkIcon from '../../assets/check-icon.jpg'

export const TOBACCO_KEYS = Object.freeze({
  recTobaccoCongratulationsNoSmoking: 'rec_tobacco_congratulations_no_smoking',
  recTobaccoQuitMoreOneYear: 'rec_tobacco_quit_more_1_year',
  recTobaccoStagePreContemplation: 'rec_tobacco_stage_pre_contemplation',
  recTobaccoStageContemplation: 'rec_tobacco_stage_contemplation',
  recTobaccoStagePreparation: 'rec_tobacco_stage_preparation',
  recTobaccoQuitLess6Months: 'rec_tobacco_quit_less_6_months',
  recTobaccoQuitMore6Months: 'rec_tobacco_quit_more_6_months',
  recTobaccoSpecialUnit: 'rec_tobacco_special_unit',
})

export const ALCOHOL_KEYS = Object.freeze({
  recAlcoholCongratulationsNoDrinking:
    'rec_alcohol_congratulations_no_drinking',
  recAlcoholConsumptionLow: 'rec_alcohol_consumption_low',
  alcoholConsumptionRisk: 'rec_alcohol_consumption_risk',
  alcoholConsumptionRiskSituations: 'rec_alcohol_consumption_risk_situations',
})

export const linksAlcoholAndTobacco = ({ alcoholKey, tobaccoKey }) => {
  const alcohol = TOBACCO_ALCOHOL_LINKS[alcoholKey]?.length
    ? TOBACCO_ALCOHOL_LINKS[alcoholKey]
    : []

  const tobacco = TOBACCO_ALCOHOL_LINKS[tobaccoKey]?.length
    ? TOBACCO_ALCOHOL_LINKS[tobaccoKey]
    : []

  return [{ alcohol: alcohol }, { tobacco: tobacco }]
}

const Links = Object.freeze({
  tobaccoAlcoholLinkOne: 0,
  tobaccoAlcoholLinkTwo: 1,
  tobaccoAlcoholLinkThree: 2,
  tobaccoAlcoholLinkFour: 3,
  tobaccoAlcoholLinkFive: 4,
  tobaccoAlcoholLinkSix: 5,
  tobaccoAlcoholLinkSeven: 6,
  tobaccoAlcoholLinkEight: 7,
  tobaccoAlcoholLinkNine: 8,
})

export const TOBACCO_ALCOHOL_LINKS = Object.freeze({
  [ALCOHOL_KEYS.alcoholConsumptionRiskSituations]: [
    Links.tobaccoAlcoholLinkOne,
  ],
  [ALCOHOL_KEYS.recAlcoholConsumptionLow]: [
    Links.tobaccoAlcoholLinkFour,
    Links.tobaccoAlcoholLinkThree,
    Links.tobaccoAlcoholLinkTwo,
    Links.tobaccoAlcoholLinkOne,
    Links.tobaccoAlcoholLinkSeven,
  ],
  [ALCOHOL_KEYS.alcoholConsumptionRisk]: [
    Links.tobaccoAlcoholLinkFour,
    Links.tobaccoAlcoholLinkThree,
    Links.tobaccoAlcoholLinkTwo,
    Links.tobaccoAlcoholLinkOne,
    Links.tobaccoAlcoholLinkSeven,
    Links.tobaccoAlcoholLinkSix,
  ],
  [ALCOHOL_KEYS.recAlcoholCongratulationsNoDrinking]: [
    Links.tobaccoAlcoholLinkOne,
  ],
  [TOBACCO_KEYS.recTobaccoQuitMoreOneYear]: [
    Links.tobaccoAlcoholLinkTwo,
    Links.tobaccoAlcoholLinkOne,
  ],
  [TOBACCO_KEYS.recTobaccoQuitMoreOneYear]: [
    Links.tobaccoAlcoholLinkTwo,
    Links.tobaccoAlcoholLinkOne,
  ],
  [TOBACCO_KEYS.recTobaccoStagePreContemplation]: [
    Links.tobaccoAlcoholLinkTwo,
    Links.tobaccoAlcoholLinkOne,
    Links.tobaccoAlcoholLinkThree,
  ],
  [TOBACCO_KEYS.recTobaccoStageContemplation]: [
    Links.tobaccoAlcoholLinkTwo,
    Links.tobaccoAlcoholLinkOne,
    Links.tobaccoAlcoholLinkThree,
  ],
  [TOBACCO_KEYS.recTobaccoStagePreparation]: [
    Links.tobaccoAlcoholLinkFour,
    Links.tobaccoAlcoholLinkTwo,
    Links.tobaccoAlcoholLinkFive,
    Links.tobaccoAlcoholLinkSeven,
    Links.tobaccoAlcoholLinkEight,
    Links.tobaccoAlcoholLinkSix,
  ],
  [TOBACCO_KEYS.recTobaccoQuitLess6Months]: [
    Links.tobaccoAlcoholLinkTwo,
    Links.tobaccoAlcoholLinkOne,
  ],
  [TOBACCO_KEYS.recTobaccoQuitMore6Months]: [
    Links.tobaccoAlcoholLinkTwo,
    Links.tobaccoAlcoholLinkOne,
  ],
  [TOBACCO_KEYS.recTobaccoSpecialUnit]: [Links.tobaccoAlcoholLinkOne],
})

export const ALCOHOL_ASSESMENT_FEEDBACK = (patientName) =>
  Object.freeze({
    [ALCOHOL_KEYS.recAlcoholCongratulationsNoDrinking]: (
      <TranscriptionScriptPDF
        script="initial-report:sectionNine.assesmentScale.rec_alcohol_congratulations_no_drinking"
        scriptProps={{ patientName }}
      />
    ),
    [ALCOHOL_KEYS.recAlcoholConsumptionLow]: (
      <TranscriptionScriptPDF
        script="initial-report:sectionNine.assesmentScale.rec_alcohol_consumption_low"
        scriptProps={{ patientName }}
      />
    ),
    [ALCOHOL_KEYS.alcoholConsumptionRisk]: (
      <TranscriptionScriptPDF
        script="initial-report:sectionNine.assesmentScale.rec_alcohol_consumption_risk"
        scriptProps={{ patientName }}
      />
    ),
    [ALCOHOL_KEYS.alcoholConsumptionRiskSituations]: (
      <TranscriptionScriptPDF
        script="initial-report:sectionNine.assesmentScale.rec_alcohol_consumption_risk_situations"
        scriptProps={{ patientName }}
      />
    ),
  })

export const ALCOHOL_OBJETIVES = Object.freeze({
  [ALCOHOL_KEYS.recAlcoholCongratulationsNoDrinking]: (
    <TranscriptionScriptPDF script="initial-report:sectionNine.objetives.rec_alcohol_congratulations_no_drinking" />
  ),
  [ALCOHOL_KEYS.recAlcoholConsumptionLow]: (
    <TranscriptionScriptPDF script="initial-report:sectionNine.objetives.rec_alcohol_consumption_low" />
  ),
  [ALCOHOL_KEYS.alcoholConsumptionRisk]: (
    <TranscriptionScriptPDF script="initial-report:sectionNine.objetives.rec_alcohol_consumption_risk" />
  ),
  [ALCOHOL_KEYS.alcoholConsumptionRiskSituations]: (
    <TranscriptionScriptPDF script="initial-report:sectionNine.objetives.rec_alcohol_consumption_risk_situations" />
  ),
})

export const ALCOHOL_KEY_GUIDELINES = Object.freeze({
  [ALCOHOL_KEYS.recAlcoholCongratulationsNoDrinking]: {
    description: (
      <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_congratulations_no_drinking.description" />
    ),
    tipList: [
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_congratulations_no_drinking.tipList.one.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_congratulations_no_drinking.tipList.one.text" />
        ),
      },
    ],
  },
  [ALCOHOL_KEYS.recAlcoholConsumptionLow]: {
    description: (
      <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.description" />
    ),
    tipList: [
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.one.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.one.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.two.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.two.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.three.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.three.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.four.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.four.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.five.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.five.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.six.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.six.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.seven.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_low.tipList.seven.text" />
        ),
      },
    ],
  },
  [ALCOHOL_KEYS.alcoholConsumptionRisk]: {
    description: (
      <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk.description" />
    ),
    tipList: [
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk.tipList.one.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk.tipList.one.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk.tipList.two.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk.tipList.two.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk.tipList.three.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk.tipList.three.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk.tipList.four.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk.tipList.four.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk.tipList.five.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk.tipList.five.text" />
        ),
      },
    ],
  },
  [ALCOHOL_KEYS.alcoholConsumptionRiskSituations]: {
    description: (
      <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk_situations.description" />
    ),
    tipList: [
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk_situations.tipList.one.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk_situations.tipList.one.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk_situations.tipList.two.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesAlcohol.rec_alcohol_consumption_risk_situations.tipList.two.text" />
        ),
      },
    ],
  },
})

export const TOBACCO_ASSESMENT_FEEDBACK = (patientName) =>
  Object.freeze({
    [TOBACCO_KEYS.recTobaccoCongratulationsNoSmoking]: (
      <TranscriptionScriptPDF
        script="initial-report:sectionNine.assesmentScale.rec_tobacco_congratulations_no_smoking"
        scriptProps={{ patientName }}
      />
    ),
    [TOBACCO_KEYS.recTobaccoQuitMoreOneYear]: (
      <TranscriptionScriptPDF
        script="initial-report:sectionNine.assesmentScale.rec_tobacco_quit_more_1_year"
        scriptProps={{ patientName }}
      />
    ),
    [TOBACCO_KEYS.recTobaccoStagePreContemplation]: (
      <TranscriptionScriptPDF
        script="initial-report:sectionNine.assesmentScale.rec_tobacco_stage_pre_contemplation"
        scriptProps={{ patientName }}
      />
    ),
    [TOBACCO_KEYS.recTobaccoStageContemplation]: (
      <TranscriptionScriptPDF
        script="initial-report:sectionNine.assesmentScale.rec_tobacco_stage_contemplation"
        scriptProps={{ patientName }}
      />
    ),
    [TOBACCO_KEYS.recTobaccoStagePreparation]: (
      <TranscriptionScriptPDF
        script="initial-report:sectionNine.assesmentScale.rec_tobacco_stage_preparation"
        scriptProps={{ patientName }}
      />
    ),
    [TOBACCO_KEYS.recTobaccoQuitLess6Months]: (
      <TranscriptionScriptPDF
        script="initial-report:sectionNine.assesmentScale.rec_tobacco_quit_less_6_months"
        scriptProps={{ patientName }}
      />
    ),
    [TOBACCO_KEYS.recTobaccoQuitMore6Months]: (
      <TranscriptionScriptPDF
        script="initial-report:sectionNine.assesmentScale.rec_tobacco_quit_more_6_months"
        scriptProps={{ patientName }}
      />
    ),
    [TOBACCO_KEYS.recTobaccoSpecialUnit]: (
      <TranscriptionScriptPDF
        script="initial-report:sectionNine.assesmentScale.rec_tobacco_special_unit"
        scriptProps={{ patientName }}
      />
    ),
  })

export const TOBACCO_OBJECTIVES = Object.freeze({
  [TOBACCO_KEYS.recTobaccoCongratulationsNoSmoking]: (
    <TranscriptionScriptPDF script="initial-report:sectionNine.objetives.rec_tobacco_congratulations_no_smoking" />
  ),
  [TOBACCO_KEYS.recTobaccoQuitMoreOneYear]: (
    <TranscriptionScriptPDF script="initial-report:sectionNine.objetives.rec_tobacco_quit_more_1_year" />
  ),
  [TOBACCO_KEYS.recTobaccoStagePreContemplation]: (
    <TranscriptionScriptPDF script="initial-report:sectionNine.objetives.rec_tobacco_stage_pre_contemplation" />
  ),
  [TOBACCO_KEYS.recTobaccoStageContemplation]: (
    <TranscriptionScriptPDF script="initial-report:sectionNine.objetives.rec_tobacco_stage_contemplation" />
  ),
  [TOBACCO_KEYS.recTobaccoStagePreparation]: (
    <TranscriptionScriptPDF script="initial-report:sectionNine.objetives.rec_tobacco_stage_preparation" />
  ),
  [TOBACCO_KEYS.recTobaccoQuitLess6Months]: (
    <TranscriptionScriptPDF script="initial-report:sectionNine.objetives.rec_tobacco_quit_less_6_months" />
  ),
  [TOBACCO_KEYS.recTobaccoQuitMore6Months]: (
    <TranscriptionScriptPDF script="initial-report:sectionNine.objetives.rec_tobacco_quit_more_6_months" />
  ),
  [TOBACCO_KEYS.recTobaccoSpecialUnit]: (
    <TranscriptionScriptPDF script="initial-report:sectionNine.objetives.rec_tobacco_special_unit" />
  ),
})

export const TOBACCO_KEY_GUIDELINES = Object.freeze({
  [TOBACCO_KEYS.recTobaccoCongratulationsNoSmoking]: {
    description: (
      <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_congratulations_no_smoking.description" />
    ),
    tipList: [
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_congratulations_no_smoking.tipList.one.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_congratulations_no_smoking.tipList.one.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_congratulations_no_smoking.tipList.two.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_congratulations_no_smoking.tipList.two.text" />
        ),
      },
    ],
  },
  [TOBACCO_KEYS.recTobaccoQuitMoreOneYear]: {
    description: (
      <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.description" />
    ),
    tipList: [
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.one.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.one.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.two.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.two.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.three.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.three.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.four.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.four.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.five.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.five.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.six.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.six.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.seven.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_1_year.tipList.seven.text" />
        ),
      },
    ],
  },
  [TOBACCO_KEYS.recTobaccoStagePreContemplation]: {
    description: (
      <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_pre_contemplation.description" />
    ),
    tipList: [
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_pre_contemplation.tipList.one.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_pre_contemplation.tipList.one.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_pre_contemplation.tipList.two.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_pre_contemplation.tipList.two.text" />
        ),
      },
    ],
  },
  [TOBACCO_KEYS.recTobaccoStageContemplation]: {
    description: (
      <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_contemplation.description" />
    ),
    tipList: [
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_contemplation.tipList.one.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_contemplation.tipList.one.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_contemplation.tipList.two.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_contemplation.tipList.two.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_contemplation.tipList.three.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_contemplation.tipList.three.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_contemplation.tipList.four.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_contemplation.tipList.four.text" />
        ),
      },
    ],
  },
  [TOBACCO_KEYS.recTobaccoStagePreparation]: {
    description: (
      <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.description" />
    ),
    tipList: [
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.one.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.one.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.two.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.two.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.three.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.three.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.four.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.four.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.five.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.five.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.six.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.six.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.seven.title" />
        ),
        text: (
          <View style={tw('flex flex-col gap-4')}>
            <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.seven.text" />
            <TickList
              list={[
                <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.seven.list.one" />,
                <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.seven.list.two" />,
                <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.seven.list.three" />,
                <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.seven.list.four" />,
              ]}
            />
          </View>
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.eight.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.eight.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.nine.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.nine.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.ten.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.ten.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.eleven.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_stage_preparation.tipList.eleven.text" />
        ),
      },
    ],
  },
  [TOBACCO_KEYS.recTobaccoQuitLess6Months]: {
    description: (
      <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.description" />
    ),
    tipList: [
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.one.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.one.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.two.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.two.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.three.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.three.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.four.title" />
        ),
        text: (
          <TranscriptionScriptPDF
            scriptImages={{ imageOne: crossIcon, imageTwo: checkIcon }}
            script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.four.text"
          />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.five.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.five.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.six.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.six.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.seven.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_less_6_months.tipList.seven.text" />
        ),
      },
    ],
  },
  [TOBACCO_KEYS.recTobaccoQuitMore6Months]: {
    description: (
      <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.description" />
    ),
    tipList: [
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.one.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.one.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.two.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.two.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.three.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.three.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.four.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.four.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.five.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.five.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.six.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.six.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.seven.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_quit_more_6_months.tipList.seven.text" />
        ),
      },
    ],
  },
  [TOBACCO_KEYS.recTobaccoSpecialUnit]: {
    description: (
      <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_special_unit.description" />
    ),
    tipList: [
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_special_unit.tipList.one.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_special_unit.tipList.one.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_special_unit.tipList.two.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_special_unit.tipList.two.text" />
        ),
      },
      {
        title: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_special_unit.tipList.three.title" />
        ),
        text: (
          <TranscriptionScriptPDF script="initial-report:sectionNine.keyGuidelinesTobacco.rec_tobacco_special_unit.tipList.three.text" />
        ),
      },
    ],
  },
})

export const worksheetsAlcoholAndTobacco = ({ alcoholKey, tobaccoKey }) => {
  const alcohol = TOBACCO_ALCOHOL_WORKSHEET_LINKS[alcoholKey]?.length
    ? TOBACCO_ALCOHOL_WORKSHEET_LINKS[alcoholKey]
    : []

  const tobacco = TOBACCO_ALCOHOL_WORKSHEET_LINKS[tobaccoKey]?.length
    ? TOBACCO_ALCOHOL_WORKSHEET_LINKS[tobaccoKey]
    : []

  return [{ alcohol: alcohol, tobacco: tobacco }]
}

const WorkSheet = Object.freeze({
  tobaccoAlcoholWorksheetMakeTheFirstStep: 0,
  tobaccoAlcoholWorksheetLinkTwo: 1,
  tobaccoAlcoholWorksheetLinkThree: 2,
  tobaccoAlcoholWorksheetLinkFour: 3,
})

export const TOBACCO_ALCOHOL_WORKSHEET_LINKS = Object.freeze({
  [ALCOHOL_KEYS.alcoholConsumptionRiskSituations]: [
    WorkSheet.tobaccoAlcoholWorksheetMakeTheFirstStep,
  ],
  [ALCOHOL_KEYS.recAlcoholConsumptionLow]: [
    WorkSheet.tobaccoAlcoholWorksheetMakeTheFirstStep,
  ],
  [ALCOHOL_KEYS.alcoholConsumptionRisk]: [
    WorkSheet.tobaccoAlcoholWorksheetMakeTheFirstStep,
  ],
  [TOBACCO_KEYS.recTobaccoStageContemplation]: [
    WorkSheet.tobaccoAlcoholWorksheetMakeTheFirstStep,
    WorkSheet.tobaccoAlcoholWorksheetLinkTwo,
  ],
  [TOBACCO_KEYS.recTobaccoStagePreparation]: [
    WorkSheet.tobaccoAlcoholWorksheetLinkTwo,
  ],
  [TOBACCO_KEYS.recTobaccoQuitLess6Months]: [
    WorkSheet.tobaccoAlcoholWorksheetLinkThree,
  ],
  [TOBACCO_KEYS.recTobaccoQuitMore6Months]: [
    WorkSheet.tobaccoAlcoholWorksheetLinkThree,
  ],
  [TOBACCO_KEYS.recTobaccoSpecialUnit]: [
    WorkSheet.tobaccoAlcoholWorksheetLinkThree,
  ],
  [TOBACCO_KEYS.recTobaccoStagePreContemplation]: [
    WorkSheet.tobaccoAlcoholWorksheetLinkThree,
  ],
})
