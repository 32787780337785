export const POLICY_TYPES = Object.freeze({
  request: 0,
  policy: 1,
})

export const POLICY_STATUS = Object.freeze({
  subscriptionPending: 0,
  refused: 1,
  cancelled: 2,
  accepted: 3,
})

export const POLICY_STATE_NAME = Object.freeze({
  [POLICY_STATUS.subscriptionPending]: 'subscriptionPending',
  [POLICY_STATUS.refused]: 'refused',
  [POLICY_STATUS.cancelled]: 'cancelled',
  [POLICY_STATUS.accepted]: 'accepted',
})

export const POLICY_STATE_COLOR = Object.freeze({
  [POLICY_STATUS.subscriptionPending]: 'text-policy-state',
  [POLICY_STATUS.accepted]: 'text-[#226F54]',
  [POLICY_STATUS.refused]: 'text-error',
  [POLICY_STATUS.cancelled]: 'text-error',
})

export const POLICY_SUB_STATES = Object.freeze({
  suscriptionPending: 0,
  suscriptionPendingRescheduled: 1,
  suscriptionPendingNoAppointment: 2,
  suscriptionPendingNotLocatable: 3,
  suscriptionPendingPhoneError: 4,
  suscriptionPendingRescheduledManagement: 5,
  suscriptionPendingIssue: 6,
  suscriptionPendingNoInterest: 7,
  suscriptionPendingScheduled: 8,
  acceptedPending: 30,
  acceptedConfirmed: 31,
  suscriptionPendingNoShow: 9,
  refusedNoControlWanted: 10,
  refusedNoRecordingAccepted: 11,
})

export const POLICY_SUB_STATE_NAME = Object.freeze({
  [POLICY_SUB_STATES.suscriptionPending]: 'suscriptionPending',
  [POLICY_SUB_STATES.suscriptionPendingRescheduled]:
    'suscriptionPendingRescheduled',
  [POLICY_SUB_STATES.suscriptionPendingNoAppointment]:
    'suscriptionPendingNoAppointment',
  [POLICY_SUB_STATES.suscriptionPendingNotLocatable]:
    'suscriptionPendingNotLocatable',
  [POLICY_SUB_STATES.suscriptionPendingPhoneError]:
    'suscriptionPendingPhoneError',
  [POLICY_SUB_STATES.suscriptionPendingRescheduledManagement]:
    'suscriptionPendingRescheduledManagement',
  [POLICY_SUB_STATES.suscriptionPendingIssue]: 'suscriptionPendingIssue',
  [POLICY_SUB_STATES.suscriptionPendingNoInterest]:
    'suscriptionPendingNoInterest',
  [POLICY_SUB_STATES.suscriptionPendingScheduled]:
    'suscriptionPendingScheduled',
  [POLICY_SUB_STATES.acceptedPending]: 'acceptedPending',
  [POLICY_SUB_STATES.acceptedConfirmed]: 'acceptedConfirmed',
  [POLICY_SUB_STATES.suscriptionPendingNoShow]: 'suscriptionPendingNoShow',
  [POLICY_SUB_STATES.refusedNoControlWanted]: 'refusedNoControlWanted',
  [POLICY_SUB_STATES.refusedNoRecordingAccepted]: 'refusedNoRecordingAccepted',
})

export const POLICY_STATE_ACCEPTED_UNIFIED_NAME = Object.freeze({
  [POLICY_STATUS.accepted]: {
    [POLICY_SUB_STATES.acceptedPending]: 'acceptedPending',
    [POLICY_SUB_STATES.acceptedConfirmed]: 'acceptedConfirmed',
  },
})

export const SEX = Object.freeze({
  male: 1,
  female: 2,
})

export const GENDER_NAMES = Object.freeze({
  [SEX.male]: 'male',
  [SEX.female]: 'female',
})

export const POLICY_ORIGIN = Object.freeze({
  now: 0,
  noNow: 1,
  batch: 2,
})

export const SCHEDULE_FILTER_OPTION = Object.freeze({
  false: 0,
  true: 1,
})

export const POLICY_SIGN_UP_STATUS = Object.freeze({
  notCandidate: 0,
  canSendSignUpComm: 1,
  signUpCommSent: 2,
  clientRegistered: 3,
})

export const getTestControlCancelSubStates = () => {
  return [
    {
      value: POLICY_SUB_STATES.suscriptionPendingNoShow,
      name: `common:policySubState.${
        POLICY_SUB_STATE_NAME[POLICY_SUB_STATES.suscriptionPendingNoShow]
      }`,
    },
    {
      value: POLICY_SUB_STATES.suscriptionPendingNoInterest,
      name: `common:policySubState.${
        POLICY_SUB_STATE_NAME[POLICY_SUB_STATES.refusedNoControlWanted]
      }`,
    },
    {
      value: POLICY_SUB_STATES.suscriptionPendingNoInterest,
      name: `common:policySubState.${
        POLICY_SUB_STATE_NAME[POLICY_SUB_STATES.refusedNoRecordingAccepted]
      }`,
    },
  ]
}

export const OFFICE_CONTROL = Object.freeze({
  home: 0,
  office: 1,
})
