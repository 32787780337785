import { undoAppointmentManagerFormatDate } from '@shared/utils'

interface Item {
  type?: string
  dateHour?: string
  opName?: string
  modality?: string
  place?: string
}

interface SortedAppointments {
  future: Item[]
  past: Item[]
}

enum AppointmentHeaders {
  type = 'Tipo de cita',
  dateHour = 'Fecha y hora',
  opName = 'Nombre OP',
  modality = 'Modalidad',
  place = 'Lugar',
}
export const AppointmentFields = {
  type: 'type',
  dateHour: 'dateHour',
  opName: 'opName',
  modality: 'modality',
  place: 'place',
  dataToModify: 'dataToModify',
  policy: 'policy',
  socialReferentId: 'socialReferentId',
}

export const AppointmentHeadersArray = [
  { text: AppointmentHeaders.type, width: 'w-[270px]',type: "type" },
  { text: AppointmentHeaders.dateHour, width: 'w-[320px]', type: "dateHour"},
  { text: AppointmentHeaders.opName, width: 'w-[280px]', type: "opName"},
  { text: AppointmentHeaders.modality, width: 'w-[280px]', type: "modality"},
  { text: AppointmentHeaders.place, width: 'w-[280px]', type: "place" },
]

export const sortAppointments = (appointments: Item[]): SortedAppointments => {
  const now = new Date()
  const future: Item[] = []
  const past: Item[] = []

  appointments.forEach((appointment) => {
    if (!appointment.dateHour) return

    const appointmentDateString = undoAppointmentManagerFormatDate(
      appointment.dateHour,
    )
    const appointmentDate = new Date(appointmentDateString)

    if (appointmentDate > now) {
      future.push(appointment)
    } else {
      past.push(appointment)
    }
  })

  return { future, past }
}
