import { FC } from 'react'
import { PortfolioManagementListContainer } from '../common'
import { Filters } from './filters'
import { ListCare } from './list-care'
import { ListCampaign } from './list-campaign'

type OPListProps = {
  type: string
}

export const OPList: FC<OPListProps> = ({ type }) => {
  return (
    <PortfolioManagementListContainer>
      <Filters />
      {type === 'care' && <ListCare />}
      {type === 'campaign' && <ListCampaign />}
    </PortfolioManagementListContainer>
  )
}
