export const snakeCaseToCamelCase = (string = '') => {
  return string
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group: string) =>
      group.toUpperCase().replace('-', '').replace('_', ''),
    )
}

export const camelCaseToSnakeCase = (string = '') => {
  return string.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

/**
 * Trims a long text to the specified length and appends ellipsis if necessary.
 *
 * @param {array} object - With label and href from Breadcrumb
 * @returns {number} Counting label key characters
 */

type DataObject = {
  label: string
  href: string
}

export function countLabelCharacters(array: DataObject[]) {
  let totalCharacters = 0

  array.forEach(obj => {
    if (Object.prototype.hasOwnProperty.call(obj, 'label') && typeof obj['label'] === 'string') {
      totalCharacters += obj['label'].length
    }
  })

  return totalCharacters
}

export function countCharacters(str:string):number {
  let count = 0;
  for (const char of str) {
    count++;
  }
  return count;
}

export function ellipsisText(text:string, maxLength:number): string {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

export const shortenString = (str: string, screenSize:number): string => {
  if (screenSize <= 1400 || str.length > 20) {
    return '...'
  }
 return str
}


export type labelsArray = {
  label: string
  href: string
}

export function extractLabelsAndCountCharacters(array: labelsArray[]): { label: string, characterCount: number }[] {
  return array.map(obj => {
      const label = obj.label
      const characterCount = label.length
      return { label, characterCount }
  })
}


export function joinLabelsWithHyphen(array: labelsArray[]): string {
  return array.map(obj => obj.label).join(' - ')
}


export function lowercaseFirstLetter(str: string): string {
  if (!str) return str
  return `${str.charAt(0).toLowerCase()}${str.slice(1)}`
}

export function replaceAccentedVowels(str: string): string {
  const accentsMap: { [key: string]: string } = {
      'á': 'a',
      'é': 'e',
      'í': 'i',
      'ó': 'o',
      'ú': 'u',
      'Á': 'A',
      'É': 'E',
      'Í': 'I',
      'Ó': 'O',
      'Ú': 'U'
  };

  return str.replace(/[áéíóúÁÉÍÓÚ]/g, (match) => accentsMap[match] || match);
}