import { useEffect, useRef } from 'react'
import { useVideoContext } from '../../../../hooks'

export const AudioTrack = ({ track }) => {
  const { activeSinkId } = useVideoContext()
  const audioEl = useRef()
  useEffect(() => {
    audioEl.current = track.attach()
    document.body.appendChild(audioEl.current)
    return () =>
      track.detach().forEach((el) => {
        el.remove()

        // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
        el.srcObject = null
      })
  }, [track])

  useEffect(() => {
    audioEl.current?.setSinkId?.(activeSinkId)
  }, [activeSinkId])

  return null
}
