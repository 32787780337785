import { SRModal } from '@sr/ui'
import PropTypes from 'prop-types'
import { BUTTON_COLORS, Button, Divider, Form } from '@shared/ui'
import { useTranslation } from 'react-i18next'
import {
  DoubleModalContainer,
  DoubleModalFormContainer,
  DoubleModalInfoContainer,
  modalContainerClassName,
  DoubleModalWarningText,
  DoubleModalWarningTextContainer,
  ModalAgendaActionButtonContainer,
} from './styles'
import { ModalAgendaActionButton } from '../../styles'

export const DoubleModal = ({
  onClose,
  isEdit,
  isReadOnly,
  methods,
  formSlot,
  infoSlot,
  onSubmit,
  onDelete,
  toggleEdit,
  cancelEvent,
  toggleCancelEvent,
  isEditing,
}) => {
  const { t } = useTranslation()

  return (
    <SRModal onClose={onClose} className={isEdit && modalContainerClassName}>
      <Form methods={methods}>
        <DoubleModalContainer>
          <DoubleModalFormContainer $isEdit={isEdit}>
            {formSlot}
          </DoubleModalFormContainer>
          {isEdit && (
            <>
              <Divider vertical />
              <DoubleModalInfoContainer>{infoSlot}</DoubleModalInfoContainer>
            </>
          )}
        </DoubleModalContainer>
        <ModalAgendaActionButton>
          <ModalAgendaActionButtonContainer>
            {onDelete && isEdit && isEditing && (
              <Button
                className="!px-4"
                testId="event-form-cancel-button"
                onClick={toggleCancelEvent}
                disabled={isReadOnly || !isEditing}
                color={BUTTON_COLORS.secondary}
              >
                {t('agenda.formModal.cancelButton')}
              </Button>
            )}
            {isEditing && (
              <Button
                className="!px-4"
                testId="event-form-submit-button"
                onClick={onSubmit}
                disabled={isReadOnly || !isEditing}
              >
                {t('agenda.formModal.saveButton')}
              </Button>
            )}
          </ModalAgendaActionButtonContainer>

          {toggleEdit && isEdit ? (
            isEditing ? (
              <Button
                className="ml-auto"
                testId="event-form-edit-button"
                onClick={onClose}
                disabled={isReadOnly}
              >
                {t('common:cancel')}
              </Button>
            ) : (
              <Button
                className="ml-auto"
                testId="event-form-edit-button"
                onClick={toggleEdit}
                disabled={isReadOnly}
              >
                {t('agenda.formModal.editButton')}
              </Button>
            )
          ) : (
            <Button
              testId="event-form-submit-button"
              onClick={onSubmit}
              disabled={isReadOnly}
            >
              {t('agenda.formModal.saveButton')}
            </Button>
          )}
        </ModalAgendaActionButton>
        {cancelEvent && (
          <SRModal onClose={toggleCancelEvent}>
            <DoubleModalWarningTextContainer>
              <DoubleModalWarningText>
                {t('common:warningCancelDate')}
              </DoubleModalWarningText>
              <Button
                testId="event-form-cancel-button"
                onClick={onDelete}
                disabled={isReadOnly}
                color={BUTTON_COLORS.secondary}
              >
                {t('agenda.formModal.cancelButton')}
              </Button>
            </DoubleModalWarningTextContainer>
          </SRModal>
        )}
      </Form>
    </SRModal>
  )
}
DoubleModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  methods: PropTypes.object.isRequired,
  formSlot: PropTypes.node.isRequired,
  infoSlot: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  cancelEvent: PropTypes.bool,
  toggleCancelEvent: PropTypes.func.isRequired,
}

DoubleModal.defaultProps = {
  isEdit: false,
  isReadOnly: false,
  onDelete: null,
  cancelEvent: false,
}
