export const chatFormContainerClasses = `
    w-full
    flex
    gap-2
`

export const chatInputClasses = `
    bg-[#F5F5F5]
    border
    border-[#999]
    flex-grow
    p-2
    w-full
`
