import { useRouteState } from '@shared/utils'
import { Analysis } from '@sr/routes/common'
import { SRUserName } from '@sr/ui'
import { useParams } from 'react-router-dom'
import { AnalysisCareRouteState } from './state'
import { observer } from 'mobx-react-lite'
import { SRUserNameContainer } from './styles'
import { ReportType } from '@shared/ui'

export const CareAnalysis = observer(() => {
  const { id: policyId } = useParams()

  const { client } = useRouteState(AnalysisCareRouteState, { policyId })

  if (!client) return

  return (
    <>
      <SRUserNameContainer>
        <SRUserName fullName={client.person.fullName} />
      </SRUserNameContainer>

      <Analysis personId={client.person.id} reportType={ReportType.care} />
    </>
  )
})
