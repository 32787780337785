import { LANGUAGES, useRouteState } from '@shared/utils'
import { SRPreSubWizard } from '@sr/ui'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ScriptPreControlRouteState } from './state'
import { ScriptBlue, ScriptOl, ScriptPreControlContainer } from './styles'
import { useLayoutContext } from '@sr/routes/common'
import { TestCancelModal } from '../control/components'
import { TranscriptionScript } from '@shared/ui'

export const PreSubscriptionControl = observer(() => {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const onComplete = () => {
    navigate(`../${ROUTE_PATH.subscriptionControl}`)
  }
  const { i18n } = useTranslation()

  const { isLoading, policy, hasStartedTest } = useRouteState(
    ScriptPreControlRouteState,
    {
      policyId: id,
    },
  )

  const [cancelModal, setCancelModal] = useState(false)

  const { setIsLoading } = useLayoutContext()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    i18n.loadLanguages(Object.values(LANGUAGES))
    if (hasStartedTest) return onComplete()
  }, [hasStartedTest])

  const lng = policy?.preferredLanguage

  const onExit = () => {
    setCancelModal(true)
  }

  const onCloseModal = () => {
    setCancelModal(false)
  }

  return (
    <>
      <SRPreSubWizard
        hidePrev
        onComplete={onComplete}
        onExit={onExit}
        completeText={t('userArea.preSubscriptionSpeech.finish')}
      >
        <ScriptPreControlContainer>
          <TranscriptionScript
            script="userArea.preSubscriptionSpeech.one"
            scriptProps={{
              clientName: policy?.fullName,
              SocialReferentName: policy?.socialReferent.name,
              academicTitle: policy?.socialReferent.academicTitle,
              lng,
            }}
            components={{ blue: <ScriptBlue />, ol: <ScriptOl /> }}
          />
        </ScriptPreControlContainer>
      </SRPreSubWizard>
      {cancelModal && <TestCancelModal onClose={onCloseModal} />}
    </>
  )
})
