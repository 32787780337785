import Axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import JSONAPISerializer from 'json-api-serializer'
import { flow, types } from 'mobx-state-tree'
import { parameterizeFilter as formatFilter } from './parameterFunctions/parameterizeFilter'

const MODEL_NAME = 'JsonApiClient'

export const Model = types
  .model(MODEL_NAME, {
    apiURL: '',
  })
  .volatile(() => ({
    serializer: null,
    axios: () => {},
    onError: () => {},
  }))
  .actions((self) => ({
    afterCreate() {
      self.initAxios()
      self.serializer = new JSONAPISerializer({
        convertCase: 'snake_case',
        unconvertCase: 'camelCase',
      })
    },
    initAxios() {
      const axios = applyCaseMiddleware(
        Axios.create({
          baseURL: self.apiURL,
          paramsSerializer: {
            indexes: null,
          },
        }),
        {
          preservedKeys: (input) => {
            if (input.includes('fields[')) return input
            if (input.includes('filter[')) return input
          },
        },
      )
      self.axios = axios
    },
    setApiURL(apiURL) {
      self.apiURL = apiURL
    },
    request: flow(function* request({ url, method, data, options = {} }) {
      const {
        noRenderError,
        action,
        params,
        headers,
        fields,
        search,
        include,
        cancelToken,
        sort,
        filter,
        signal,
      } = options
      const parameterizedFields = self.parameterizeFields(fields)
      const parameterizedSort = self.parameterizeSort(sort)
      const parameterizedFilter = self.parameterizeFilter(filter)
      const parameterizedSearch = search ? { 'filter[search]': search } : {}

      try {
        return yield self.axios.request({
          baseURL: self.apiURL,
          url,
          method,
          data,
          cancelToken,
          signal,
          params: {
            action,
            ...parameterizedFields,
            ...parameterizedSort,
            ...parameterizedFilter,
            ...parameterizedSearch,
            // TODO: serialize include attrs with the serializer
            include,
            ...params,
          },
          headers: {
            'Content-Type': 'application/vnd.api+json',
            ...headers,
          },
        })
      } catch (error) {
        if (!Axios.isCancel(error)) {
          self.onError(error, noRenderError)
          if (noRenderError) {
            return error
          }
        }
        throw error
      }
    }),
    get(url, options) {
      return self.request({ url, method: 'GET', options })
    },
    post(url, data, options) {
      return self.request({ url, method: 'POST', data, options })
    },
    put(url, data, options) {
      return self.request({ url, method: 'PUT', data, options })
    },
    patch(url, data, options) {
      return self.request({ url, method: 'PATCH', data, options })
    },
    delete(url, data, options) {
      return self.request({ url, method: 'DELETE', data, options })
    },
    setOnErrorCallback(callback) {
      self.onError = callback
    },
    getModelSchema(modelName) {
      return self.serializer.schemas[modelName]?.default
    },
    generateModelObjectWithAttributes(attributes) {
      return attributes.reduce((obj, attr, i) => {
        return { ...obj, [attr]: i }
      }, {})
    },
    serializeAttributes(modelName, attributes) {
      const modelSchema = self.getModelSchema(modelName)
      if (!modelSchema) {
        console.warn('No model schema for ' + modelName)
        return null
      }
      const data = self.generateModelObjectWithAttributes(attributes)
      const options = self.getModelSchema(modelName)

      return Object.keys({
        ...self.serializer.serializeAttributes(data, options),
        ...self.serializer.serializeRelationships(data, options),
      })
    },
    parameterizeFields(fields = {}) {
      const parameterizedFields = []
      const modelFields = Object.entries(fields)

      modelFields.forEach(([modelName, attributes]) => {
        const serializedAttributes = self.serializeAttributes(
          modelName,
          attributes,
        )
        if (serializedAttributes) {
          parameterizedFields[`fields[${modelName}]`] =
            serializedAttributes.join()
        }
      })

      return parameterizedFields
    },
    parameterizeSort(modelSortFields = {}) {
      const parameterizedSortFields = []
      const modelFields = Object.entries(modelSortFields)

      modelFields.forEach(([modelName, attributes]) => {
        const serializedAttributes = self.serializeAttributes(
          modelName,
          attributes,
        )
        if (serializedAttributes) {
          parameterizedSortFields['sort'] = serializedAttributes.join()
        }
      })

      return parameterizedSortFields
    },
    parameterizeFilter(modelFilterFields = {}) {
      return formatFilter(self, modelFilterFields)
    },
  }))

export default Model
