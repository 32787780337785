import { useTranslation } from 'react-i18next'
import { Button } from '../../../../../button'
import { caixaBankButton } from '../../../../utils'

export const ButtonRetry = ({ urlRetry }) => {
  const { t } = useTranslation()

  return (
    <Button
      className={caixaBankButton}
      onClick={() => (window.location.href = urlRetry)}
    >
      {t('common:videoCall.buttonUnavailable')}
    </Button>
  )
}
