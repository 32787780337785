export const containerClassName = `
    flex
    gap-6
    items-center
    px-4
    lg:px-6
    py-4
    bg-gray-300
`

export const routeInfoClassName = `
    flex
    items-center
    gap-6
`

export const titleClassName = `
    text-primary
    text-xl
`
