import { Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'

export const ListItem = ({ title, style, list = [], hasBreak = false }) => {
  if (!list || !list.length || !Array.isArray(list)) return

  return (
    <View style={{ ...tw('flex flex-col gap-2'), ...style }} break={hasBreak}>
      {title && <Text style={tw('font-semibold')}>{title}</Text>}
      {list?.map((item, ix) => {
        return (
          <View
            style={tw('flex flex-row gap-2 pl-2 pt-1')}
            key={`list-item-${ix}`}
          >
            <Text>•</Text>
            {typeof item === 'function' ? (
              <View>{item()}</View>
            ) : (
              <Text style={tw('leading-6')}>{item}</Text>
            )}
          </View>
        )
      })}
    </View>
  )
}
