import React from 'react';
import { Input, FORM_TYPE } from '@shared/ui';

export const ScheduleRadioButton = React.memo((props) => {
  const { children, disabled, className, name, value, id } = props;

  return (
    <Input
      className={className}
      type={FORM_TYPE.radio}
      id={id}
      value={value}
      disabled={disabled}
      name={name}
    >
      {children}
    </Input>
  );
});

