export enum PolicyTypes {
  request = 0,
  policy = 1,
}

export enum PolicyStatus {
  subscriptionPending = 0,
  refused = 1,
  cancelled = 2,
  accepted = 3,
}

export const PolicyStatusKeys: { [key: number]: keyof typeof POLICY_STATE_TEXT } = Object.keys(PolicyStatus)
  .filter(key => isNaN(Number(key)))
  .reduce((acc, key) => {
    acc[PolicyStatus[key as keyof typeof PolicyStatus]] = key as keyof typeof POLICY_STATE_TEXT;
    return acc;
  }, {} as { [key: number]: keyof typeof POLICY_STATE_TEXT })

export enum Sex {
  male = 1,
  female = 2,
}

export enum PolicyOrigin {
  now = 0,
  noNow = 1,
  batch = 2,
}
export enum PolicySubStates {
  subscriptionPending = 0,
  subscriptionPendingRescheduled = 1,
  subscriptionPendingNoAppointment = 2,
  subscriptionPendingNotLocatable = 3,
  subscriptionPendingPhoneError = 4,
  subscriptionPendingRescheduledManagement = 5,
  subscriptionPendingIssue = 6,
  subscriptionPendingNoInterest = 7,
  subscriptionPendingScheduled = 8,
  acceptedPending = 30,
  acceptedConfirmed = 31,
  subscriptionPendingNoShow = 9,
  refusedNoControlWanted = 10,
  refusedNoRecordingAccepted = 11,
}

export enum PolicySignUpStatus {
  notCandidate = 0,
  canSendSignUpComm = 1,
  signUpCommSent = 2,
  clientRegistered = 3,
}

export interface PolicyProps {
  id: string
  contractNumber: string
  policyNumber: string
  identification: string
  name: string
  surname: string
  phone: string
  email: string
  postalCode: string
  preferredLanguage: string
  requestDate: string
  firstAppointmentDate: string
  resolutionDate: string
  region: string
  locality: string
  street: string
  rescheduledBySrTimes: number
  noDigital: boolean
  policyType: PolicyTypes
  state: PolicyStatus
  derivedBy: string
  socialReferent: unknown
  birthDay: string
  sex: Sex
  subState: PolicySubStates
  subscriptionEndDate: string
  policyOrigin: PolicyOrigin
  policySignUpStatus: PolicySignUpStatus

  //VIEWS
  canSendRegisterEmail: boolean
  canResendEmail: boolean
  fullName: string
}

export const POLICY_STATE_TEXT = {
  subscriptionPending: 'subscriptionPending',
  refused: 'refused',
  cancelled: 'cancelled',
  accepted: 'accepted',
} as const

export const POLICY_STATE_COLORS = {
  subscriptionPending: 'pending',
  refused: 'error',
  cancelled: 'error',
  accepted: 'success',
} as const
