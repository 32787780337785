import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PolicyTableObject } from '../types'
import { getDateFormatted } from '@shared/utils'
import { PolicyStateCell } from '../cells'
import { POLICY_SUB_STATE_NAME } from '@shared/data-access'

export const useCareTable = () => {
  const { t } = useTranslation()

  const columns = useMemo<MRT_ColumnDef<PolicyTableObject>[]>(
    () => [
      {
        accessorKey: 'requestDate',
        header: t('common:requestDate'),
        Cell: ({ renderedCellValue }) => (
          <div>{getDateFormatted(renderedCellValue, 'DD/MM/YYYY')}</div>
        ),
      },
      {
        accessorKey: 'name',
        header: t('common:name'),
      },
      {
        accessorKey: 'surname',
        header: t('common:surname'),
      },
      {
        accessorKey: 'contractNumber',
        header: t('common:contractNumber'),
      },
      {
        accessorKey: 'locality',
        header: t('common:locality'),
      },
      {
        accessorKey: 'postalCode',
        header: t('common:postalCode'),
      },
      {
        accessorKey: 'preferredLanguage',
        header: t('common:language'),
        Cell: ({ renderedCellValue }) => (
          <div>
            {renderedCellValue
              ? t(`common:languages.${renderedCellValue}`)
              : t('common:notIndicated')}
          </div>
        ),
      },
      {
        accessorKey: 'state',
        header: t('common:state'),
        Cell: ({ renderedCellValue, row }) => (
          <PolicyStateCell state={renderedCellValue} row={row} />
        ),
      },
      {
        accessorKey: 'subState',
        header: t('common:subState'),
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {renderedCellValue
              ? t(
                  `common:policySubState.${
                    POLICY_SUB_STATE_NAME[
                      renderedCellValue as keyof typeof POLICY_SUB_STATE_NAME
                    ]
                  }`,
                )
              : ''}
          </div>
        ),
      },
      {
        accessorKey: 'noDigital',
        header: t('common:modality'),
        Cell: ({ renderedCellValue }) => (
          <div>
            {renderedCellValue ? t(`common:inPerson`) : t(`common:online`)}
          </div>
        ),
      },
      {
        accessorKey: 'officeControl',
        header: t('common:datePlace'),
        Cell: ({ renderedCellValue }) => (
          <div>{renderedCellValue ? t('common:office') : t('common:home')}</div>
        ),
      },
      {
        accessorFn: (row) =>
          row.officeControl ? row.completeOfficeAddress : row.street,
        header: t('common:address'),
        Cell: ({ renderedCellValue }) => <div>{renderedCellValue}</div>,
        enableSorting: false,
      },
      {
        accessorKey: 'socialReferent__name',
        accessorFn: (row) => row?.socialReferent?.fullName,
        header: t('common:socialReferent'),
        Cell: ({ row }) => <div>{row?.original?.socialReferent?.fullName}</div>,
      },
      {
        accessorKey: 'firstAppointmentDate',
        header: t('common:firstAppointmentDate'),
        Cell: ({ renderedCellValue }) => (
          <div>{getDateFormatted(renderedCellValue, 'DD/MM/YYYY')}</div>
        ),
        enableSorting: false,
      },
      {
        accessorKey: 'initialAnalysisDate',
        header: t('common:initialAnalysisDate'),
        Cell: ({ renderedCellValue }) => (
          <div>{getDateFormatted(renderedCellValue, 'DD/MM/YYYY')}</div>
        ),
        enableSorting: false,
      },
    ],
    [],
  )

  return { columns }
}
