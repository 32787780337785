import { Image, Text, View } from '@react-pdf/renderer'
import { areaColorKey, areaColorsLighter, tw } from '../../utils'
import { SubSection } from '../../components/SubSection'
import { TableHeaderBlack } from '../../components/TableHeaderBlack'
import { ScalePerYear } from '../../components/ScalePerYear'
import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'
import { TitleIcon } from '../../components/TitleIcon'
import { Tips } from '../../components/Tips'
import { ListItem } from '../../components/ListItem'
import { FollowRecomendations } from '../../components/FollowRecomendations'
import LocationHeartWhite from '../../../report-pdf/assets/social/location--heart-white.png'
import Moon from '../../assets/moon.png'
import { useReportPdfContext } from '../../report-pdf-provider'
import { useMemo } from 'react'
import {
  COGNITIVE_EMOTIONAL_WELLBEING,
  COGNITIVE_EMOTIONAL_WELLBEING_LINKS,
  COGNITIVE_EMOTIONAL_WELLBEING_OBJETIVE_TRANSCRIPTION_MESSAGE,
  COGNITIVE_EMOTIONAL_WELLBEING_TIPS_TRANSCRIPTION,
  COGNITIVE_EMOTIONAL_WELLBEING_TRANSCRIPTION_MESSAGE,
  COGNITIVE_SLEEP,
  COGNITIVE_SLEEP_OBJETIVE_TRANSCRIPTION_MESSAGE,
  COGNITIVE_SLEEP_TIPS_TRANSCRIPTION,
  COGNITIVE_SLEEP_TRANSCRIPTION_MESSAGE,
  COGNITIVE_SLEEP_LINKS,
} from './enum'
import { scoreIsNotAvailable } from '../../utils'
import { DisclaimerBanner } from '../../components/DisclaimerBanner'
import { LayoutPage } from '../../../report-pdf/components'
import { AreaColor } from '../../../report-pdf/utils'

export const SecondPage = () => {
  const areaColor = areaColorKey.pink

  const { data, t, testDate, person, type } = useReportPdfContext()

  const { scores, recommendations } = data.results.initialAnalysisList

  const getCognitiveWellBeing = () => {
    let cognitiveIncluded = ''
    Object.values(COGNITIVE_EMOTIONAL_WELLBEING).forEach((emotional) => {
      if (recommendations.EMOTIONAL.includes(emotional)) {
        cognitiveIncluded = emotional
      }
    })

    return cognitiveIncluded
  }

  const getCognitiveSleep = () => {
    let cognitiveIncluded = ''
    Object.values(COGNITIVE_SLEEP).forEach((emotional) => {
      if (recommendations.EMOTIONAL.includes(emotional)) {
        cognitiveIncluded = emotional
      }
    })

    return cognitiveIncluded
  }

  const cognitiveEmotionalWellBeingMessage = useMemo(() => {
    return (
      COGNITIVE_EMOTIONAL_WELLBEING_TRANSCRIPTION_MESSAGE(person.name)[
        getCognitiveWellBeing()
      ] || []
    )
  }, [recommendations])

  const cognitiveEmotionalWellBeingObjetive = useMemo(() => {
    return (
      COGNITIVE_EMOTIONAL_WELLBEING_OBJETIVE_TRANSCRIPTION_MESSAGE[
        getCognitiveWellBeing()
      ] || []
    )
  }, [recommendations])

  const cognitiveEmotionalWellBeingTips = useMemo(() => {
    return (
      COGNITIVE_EMOTIONAL_WELLBEING_TIPS_TRANSCRIPTION[
        getCognitiveWellBeing()
      ] || []
    )
  }, [recommendations])

  const cognitiveEmotionalWellBeingLinks = useMemo(() => {
    return COGNITIVE_EMOTIONAL_WELLBEING_LINKS[getCognitiveWellBeing()] || []
  }, [recommendations])

  const cognitiveSleepMessage = useMemo(() => {
    return (
      COGNITIVE_SLEEP_TRANSCRIPTION_MESSAGE(person.name)[getCognitiveSleep()] ||
      []
    )
  }, [recommendations])

  const cognitiveSleepObjetive = useMemo(() => {
    return (
      COGNITIVE_SLEEP_OBJETIVE_TRANSCRIPTION_MESSAGE[getCognitiveSleep()] || []
    )
  }, [recommendations])

  const cognitiveSleepTips = useMemo(() => {
    return COGNITIVE_SLEEP_TIPS_TRANSCRIPTION[getCognitiveSleep()] || []
  }, [recommendations])

  const cognitiveSleepLinks = useMemo(() => {
    return COGNITIVE_SLEEP_LINKS[getCognitiveSleep()] || []
  }, [recommendations])

  const assesmentScaleList = [
    {
      label: t('initial-report:sectionSix.assesmentScale.table.one.label'),
      content: t('initial-report:sectionSix.assesmentScale.table.one.content'),
    },
    {
      label: t('initial-report:sectionSix.assesmentScale.table.two.label'),
      content: testDate,
    },
    {
      label: t('initial-report:sectionSix.assesmentScale.table.three.label'),
      content: scoreIsNotAvailable(scores?.GOLDBERG?.score?.scale_a_points)
        ? 'initial-report:resultNotAvailable.anxietyDepresion'
        : `${scores?.GOLDBERG?.score?.scale_a_points}/9 ${t('common:points')}`,
    },
    {
      label: t('initial-report:sectionSix.assesmentScale.table.four.label'),
      content: scoreIsNotAvailable(scores?.GOLDBERG?.score?.scale_d_points)
        ? 'initial-report:resultNotAvailable.anxietyDepresion'
        : `${scores?.GOLDBERG?.score?.scale_d_points}/9 ${t('common:points')}`,
    },
  ]

  const assesmentAnxietyScales = [
    {
      text: '0-3',
      subText: t(
        'initial-report:sectionSix.assesmentScale.scaleAnxietySubText.one',
      ),
      selected: scores?.GOLDBERG?.score?.scale_a_points <= 3,
    },
    {
      text: '4-9',
      subText: t(
        'initial-report:sectionSix.assesmentScale.scaleAnxietySubText.two',
      ),
      selected: scores?.GOLDBERG?.score?.scale_a_points > 3,
    },
  ]

  const assesmentDepressionScales = [
    {
      text: '0-1',
      subText: t(
        'initial-report:sectionSix.assesmentScale.scaleDepressionSubText.one',
      ),
      selected: scores?.GOLDBERG?.score?.scale_d_points <= 1,
    },
    {
      text: '2-9',
      subText: t(
        'initial-report:sectionSix.assesmentScale.scaleDepressionSubText.two',
      ),
      selected: scores?.GOLDBERG?.score?.scale_d_points > 1,
    },
  ]

  const list = [
    t('initial-report:sectionSix.keyGuidelines.activitiesList.one'),
    t('initial-report:sectionSix.keyGuidelines.activitiesList.two'),
    t('initial-report:sectionSix.keyGuidelines.activitiesList.three'),
    t('initial-report:sectionSix.keyGuidelines.activitiesList.four'),
    t('initial-report:sectionSix.keyGuidelines.activitiesList.five'),
    t('initial-report:sectionSix.keyGuidelines.activitiesList.six'),
  ]

  const keepInMind = [
    <TranscriptionScriptPDF script="initial-report:sectionSix.linksAndWorkSheet.keepInMind.one" />,
    <TranscriptionScriptPDF script="initial-report:sectionSix.linksAndWorkSheet.keepInMind.two" />,
  ]
  const workSheet = [0, 1]

  return (
    <LayoutPage
      headerText={t('initial-report:sectionSix.headerText')}
      noSpacing
      section={AreaColor.emotional}
      sectionNumber={'04'}
    >
      <View style={tw('px-12')}>
        <SubSection title={t('initial-report:sectionSix.assesmentScale.title')}>
          <View style={tw('flex flex-col gap-2')}>
            <TableHeaderBlack
              title={t('initial-report:sectionSix.assesmentScale.table.title')}
              list={assesmentScaleList}
            />
            <View style={tw('flex flex-col gap-2')}>
              <DisclaimerBanner />
              <Text style={tw('underline')}>
                {t(
                  'initial-report:sectionSix.assesmentScale.scaleAnxietySubText.title',
                )}
              </Text>
              <ScalePerYear
                scales={assesmentAnxietyScales}
                areaColor={areaColor}
              />
            </View>
            <View style={tw('flex flex-col gap-2')}>
              <Text style={tw('underline')}>
                {t(
                  'initial-report:sectionSix.assesmentScale.scaleDepressionSubText.title',
                )}
              </Text>
              <ScalePerYear
                scales={assesmentDepressionScales}
                areaColor={areaColor}
              />
            </View>
          </View>
        </SubSection>
      </View>
      <View style={tw('px-12')} break>
        <View style={tw('flex flex-col gap-4')}>
          {cognitiveEmotionalWellBeingMessage}
          {cognitiveSleepMessage}
        </View>
        <SubSection title={t('initial-report:sectionSix.objetives.title')}>
          <View style={tw('flex flex-col gap-4')}>
            <TranscriptionScriptPDF
              script={'initial-report:sectionSix.objetives.text'}
            />
            <ListItem
              list={[
                cognitiveEmotionalWellBeingObjetive,
                cognitiveSleepObjetive,
              ]}
            />
          </View>
        </SubSection>
      </View>
      <View style={tw('px-12')} break>
        <SubSection title={t('initial-report:sectionSix.keyGuidelines.title')}>
          <View style={tw('flex flex-col gap-6')}>
            <View style={tw('flex flex-col gap-6')}>
              {cognitiveEmotionalWellBeingTips?.description}
              <TitleIcon
                title={t(
                  'initial-report:sectionSix.keyGuidelines.tipList.title',
                )}
                icon={<Image style={tw('w-8 h-8')} src={LocationHeartWhite} />}
                areaColor={areaColor}
              />
              <View style={tw('flex flex-col gap-6')}>
                {cognitiveEmotionalWellBeingTips?.list?.map((tip, index) => {
                  return (
                    <Tips areaColor={areaColor} index={index} title={tip.title}>
                      <Text>{tip.content}</Text>
                    </Tips>
                  )
                })}
              </View>
            </View>
            <ListItem
              hasBreak
              title={t(
                'initial-report:sectionSix.keyGuidelines.activitiesList.title',
              )}
              style={tw(`bg-[${areaColorsLighter[areaColor]}] p-4`)}
              list={list}
            />
            <View style={tw('flex flex-col gap-6')}>
              {cognitiveSleepTips?.description}
              <TitleIcon
                title={t(
                  'initial-report:sectionSix.keyGuidelines.tipListSleep.title',
                )}
                icon={<Image style={tw('w-5 h-5')} src={Moon} />}
                areaColor={areaColor}
              />
              <View style={tw('flex flex-col gap-6')}>
                {cognitiveSleepTips?.list?.map((tip, index) => {
                  return (
                    <Tips areaColor={areaColor} index={index} title={tip.title}>
                      <Text>{tip.content}</Text>
                    </Tips>
                  )
                })}
              </View>
            </View>
          </View>
        </SubSection>
      </View>
      <View break>
        <FollowRecomendations
          translateFrom={'initial-report:sectionSix.linksAndWorkSheet'}
          areaColor={areaColor}
          linksAndWorkSheetTitle={t(
            `initial-report:generic.linksAndWorkSheet.title.${type}`,
          )}
          linksAndWorkSheetText={
            <TranscriptionScriptPDF
              script={`initial-report:sectionSix.linksAndWorkSheet.text.${type}`}
              scriptProps={{ patientName: person.name }}
            />
          }
          keepInMindList={keepInMind}
          followRecommendationsText={t(
            'initial-report:sectionSix.linksAndWorkSheet.followRecommendations.text',
          )}
          omsText={t(
            'initial-report:sectionSix.linksAndWorkSheet.followRecommendations.omsText',
          )}
          links={[...cognitiveEmotionalWellBeingLinks, ...cognitiveSleepLinks]}
          workSheetList={workSheet}
          worksheetId={`worksheet-emotional`}
        />
      </View>
    </LayoutPage>
  )
}
