import { useRouteState } from '@shared/utils'
import { AppointmentBlock, ServicesBlock, ChangeOpBlock } from '../../blocks'
import { ProfileCampaignsActionsRouteState } from './state'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Form } from '@shared/ui'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTE_PATH_CAMPAIGN } from 'apps/social-referent/src/routes/enums'
import { FC, useEffect } from 'react'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import { SERVICE_STATE } from '@sr/data-access'
import { ModalType, useAnalysisModal } from '@sr/routes/common/analysis'
import { rejectOptions } from './enums'

type CampaignActionsProps = {
  reload: () => void
}

export const CampaignActions: FC<CampaignActionsProps> = observer(
  ({ reload }) => {
    const { t } = useTranslation()
    const { id: annuityId } = useParams()
    const {
      isLoading,
      cancelContract,
      annuity,
      firstServiceId,
      serviceOptions,
      user,
      availableSocialReferentListParsed,
      changeOp,
      loadAvailableSrForChangeCampaign,
      modalType,
      resend,
      setModal,
    } = useRouteState(ProfileCampaignsActionsRouteState, { annuityId })

    const navigate = useNavigate()
    const { setIsLoading } = useLayoutContext()

    const methods = useForm()
    const { handleSubmit } = methods

    const onRejection = handleSubmit((form) => {
      const { subState } = form

      cancelContract({ subState })
    })

    const onAccept = () => {
      navigate(`../${ROUTE_PATH_CAMPAIGN.userAgenda(firstServiceId)}`)
    }

    const canChangeOp = !!annuity?.analysisDate && user?.isCoordinator

    const onChangeOp = handleSubmit(async (form) => {
      await changeOp(form)
      reload()
      await loadAvailableSrForChangeCampaign()
      setModal(ModalType.success)
    })

    useEffect(() => {
      setIsLoading(isLoading)
    }, [isLoading])

    const openModal = () => {
      setModal(ModalType.confirmation)
    }
    const onClose = () => {
      setModal(null)
    }

    const Modal = useAnalysisModal({
      modalType,
      reload,
      resend,
      onChangeOp,
      onClose,
      fullName: annuity?.person?.fullName,
      onConfirmText: 'common:save',
      confirmModalTextMain:
        'campaign.userArea.profile.changeOpModalConfirmationMain',
      confirmModalTextSecond:
        'campaign.userArea.profile.changeOpModalConfirmationSecond',
      successModalTextMain:
        'campaign.userArea.profile.changeOpModalSuccessMain',
      successModalTextSecond: '',
      noRoute: true,
    })

    return (
      <Form methods={methods}>
        <ServicesBlock
          project={t('projects.campaigns')}
          onRejection={onRejection}
          onAccept={onAccept}
          serviceOptions={serviceOptions}
          rejectOptions={rejectOptions(t)}
          disabled={!annuity?.canCancelService}
          state={SERVICE_STATE[annuity?.state as keyof typeof SERVICE_STATE]}
        />

        {!!annuity?.analysisDate && (
          <AppointmentBlock
            showAppointment
            appointment={{ startDate: annuity?.analysisDate }}
            action={onAccept}
            disabled={isLoading || !firstServiceId}
          />
        )}

        {canChangeOp && (
          <ChangeOpBlock
            srList={availableSocialReferentListParsed}
            action={openModal}
          />
        )}
        {modalType && Modal}
      </Form>
    )
  },
)
