import { useState } from 'react'

export const FilterGroup = ({
  filters,
  initialValue,
  onChangeIndex,
  ChipComponent,
}) => {
  const [selectedValue, setSelectedValue] = useState(initialValue)

  const handleClick = (value) => {
    if (value === selectedValue) value = undefined
    setSelectedValue(value)
    onChangeIndex(value)
  }

  if (!filters) return

  return filters.map((filter) => {
    if (!filter.value || !filter.label) return null
    const isSelected = filter.value === selectedValue
    return (
      <ChipComponent
        key={filter.value}
        label={filter.label}
        value={filter.value}
        isSelected={isSelected}
        onClick={handleClick}
      />
    )
  })
}
