import { BUTTON_SIZES, Button } from '@shared/ui'
import { useRouteState } from '@shared/utils'
import { useLayoutContext } from '@sr/routes/common'
import { SrUserList } from '@sr/ui'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CreateSrModal } from './components/create-sr-modal'
import { SrListRouteState } from './state'

export const CoordinatorSrList = observer(() => {
  const { t } = useTranslation()

  const { parsedSocialReferent, setSearch, isLoading, createSr } =
    useRouteState(SrListRouteState)
  const { setIsLoading } = useLayoutContext()
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [openAddSrModal, setOpenAddSrModal] = useState(false)

  const navigate = useNavigate()

  const onChange = (e) => {
    setSearch(e.target.value)
  }

  const goSrProfile = (id) => {
    navigate(ROUTE_PATH.srPrivateArea(id))
  }

  const onSubmit = async (form) => {
    const socialReferent = await createSr(form)
    socialReferent ? setSuccess(true) : setError(true)
  }

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  return (
    <>
      {openAddSrModal && (
        <CreateSrModal
          onClose={() => setOpenAddSrModal(false)}
          loading={isLoading}
          onSubmit={onSubmit}
          error={error}
          success={success}
          setterSuccess={setSuccess}
          setterError={setError}
        />
      )}
      <div className="pb-20 w-10/12">
        <SrUserList
          list={parsedSocialReferent}
          onChange={onChange}
          actions={
            <Button
              onClick={() => setOpenAddSrModal(true)}
              className="w-52 h-12"
              size={BUTTON_SIZES.small}
            >
              {t('srList.addSocialReferent')}
            </Button>
          }
          onClick={goSrProfile}
        />
      </div>
    </>
  )
})
