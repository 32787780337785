import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { BIG_CALENDAR_VIEW } from './enums'

const localizer = momentLocalizer(moment)

export const BigCalendar = ({
  events,
  view,
  components,
  date,
  min,
  max,
  step = 60,
  timeslots = 1,
  formats,
  ...rest
}) => {
  return (
    <Calendar
      localizer={localizer}
      formats={formats}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ minHeight: '80vh' }}
      onView={() => {}}
      onNavigate={() => {}}
      view={view}
      views={[
        BIG_CALENDAR_VIEW.day,
        BIG_CALENDAR_VIEW.workWeek,
        BIG_CALENDAR_VIEW.month,
      ]}
      defaultView={BIG_CALENDAR_VIEW.day}
      components={components}
      date={date}
      min={min}
      max={max}
      step={step}
      timeslots={timeslots}
      {...rest}
    />
  )
}
