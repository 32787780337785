import {
  APPOINTMENT_TYPES,
  BaseRouteState,
  POLICY_TYPES,
  Policy,
  SocialReferent,
} from '@shared/data-access'
import { GenerateRoomModel } from '@sr/data-access'
import { actions } from '@sr/routes/common/store'
import { flow, types } from 'mobx-state-tree'

export const CareAppointmentState = BaseRouteState.named('CareAppointmentState')
  .props({
    isEdit: false,
    eventId: types.string,
    socialReferentId: types.maybeNull(types.string),
    appointmentType: types.number,
    policies: types.array(types.reference(Policy)),
    policy: types.maybeNull(types.reference(Policy)),
    policyId: types.maybeNull(types.string),
    user: types.maybeNull(types.reference(SocialReferent)),
    canGenerateRoom: types.maybeNull(types.reference(GenerateRoomModel)),
    search: types.maybeNull(types.string),
    page: types.maybeNull(types.integer, 0),
  })
  .views((self) => ({
    get isOwner() {
      return self.socialReferentId === self.user?.id
    },
    get isPreControl() {
      return (
        self.appointmentType === APPOINTMENT_TYPES.INITIAL_REPORT ||
        self.appointmentType === APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE
      )
    },
    get policiesWithDefault() {
      return self.policy &&
        !self.policies.some(
          (policy) => policy.id === self.policy.id,
        )
        ? [...self.policies, self.policy]
        : self.policies
    },
    get policyClients() {
      return self.policiesWithDefault?.map(({ id, fullName }) => ({
        value: id,
        name: fullName,
      }))
    },
    get isCoordinatorInHisCalendar() {
      return self.user?.isCoordinator && self.user?.id === self.socialReferentId
    },
    get paginatedId() {
      return self.isCoordinatorInHisCalendar ? null : self.socialReferentId
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('CareAppointmentState')
      try {
        yield self.loadUserInfo()

        self.policies = yield self.loadPaginatedPolicies({
          isClear: true,
          socialReferentId: self.paginatedId,
          policyType: POLICY_TYPES.policy,
        })
        if (self.policyId) {
          yield self.loadPolicy()
        }
        if (self.isEdit) {
          yield self.checkIfCanGenerateRoom(self.eventId)
        }
      } finally {
        self.removeLoading('CareAppointmentState')
      }
    }),
    setAppointmentType(appointmentType) {
      self.appointmentType = appointmentType
    },
    setSearch: flow(function* setSearch(search) {
      self.search = search
      self.page = 0
      self.policies = yield self.loadPaginatedPolicies({
        isClear: true,
        socialReferentId: self.paginatedId,
        policyType: POLICY_TYPES.policy,
      })
    }),
    ...actions(self),
  }))
