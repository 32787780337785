import {
  BUTTON_COLORS,
  Button,
  UnSupportedBrowser,
  useVideoContext,
  usePermissions,
} from '@shared/ui'
import { Information, VideoFilled } from '@carbon/icons-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MeetLogo } from '../meet-logo'
import { caixaBankButton } from '../../utils'
import {
  PreviewScreenAvailable,
  PreviewScreenContent,
  PreviewScreenInfoIcon,
  PreviewScreenInfoParagraph,
  PreviewScreenInfoWrapper,
  PreviewScreenLogoText,
  PreviewScreenLogoWrapper,
  PreviewScreenWrapper,
} from './style'
import { BRANDING_KEYS } from '../../utils/branding'
import { APPOINTMENT_TYPES } from '@shared/data-access'
import { isSupported } from 'twilio-video'
import { PermissionsDeniedView } from './components/PermissionsPreview/PermissionsDeniedView'
import { PrelandingScreen } from './components/PrelandingScreen/PrelandingScreen'
import { CameraPreview } from './components/camera-preview'
import { VIDEO_CALL_PAGE_VIEW, analytics } from '@shared/utils'

export const PreviewScreen = ({
  joinTheMeet,
  isAvailable = true,
  children,
}) => {
  const { t } = useTranslation()
  const { getAudioAndVideoTracks, branding, appointmentType, options } =
    useVideoContext()
  const [mediaError, setMediaError] = useState(false)
  const [permissionsButton, setPermissionsButton] = useState(false)
  const [opNumberView, setOpNumberView] = useState(false)

  const { hasDenied, hasPermissions, checkPermissions } = usePermissions({
    onError: setMediaError,
  })

  const { GENERIC_ANALYSIS, INITIAL_REPORT, INITIAL_ANALYSIS } =
    APPOINTMENT_TYPES

  const isCampaign = appointmentType === GENERIC_ANALYSIS

  const productName = {
    [GENERIC_ANALYSIS]: t('common:videoCall.legalTextType.campaign'),
    [INITIAL_REPORT]: t('common:videoCall.legalTextType.subscription'),
    [INITIAL_ANALYSIS]: t('common:videoCall.legalTextType.preventionPlan'),
  }

  useEffect(() => {
    if (!mediaError && hasPermissions) {
      getAudioAndVideoTracks()
        .then(() => {})
        .catch((error) => {
          console.log('Error acquiring local media:')
          console.dir(error)
          setMediaError(error)
        })
    }
  }, [mediaError, hasPermissions])

  const handleCheckPermissions = () => {
    checkPermissions()
    setPermissionsButton(true)
  }

  useEffect(() => {
    analytics.pageView({
      path: VIDEO_CALL_PAGE_VIEW.pageViewVideoCallPreview,
    })
  }, [])

  if (!isSupported) return <UnSupportedBrowser />

  return (
    <div
      className={`${PreviewScreenWrapper} ${
        !permissionsButton || !opNumberView ? '!flex' : ''
      }`}
    >
      <div className={PreviewScreenLogoWrapper}>
        <MeetLogo />
        {branding?.branding === BRANDING_KEYS.VCX && (
          <p className={PreviewScreenLogoText}>{t('common:videoCall.title')}</p>
        )}
      </div>

      {permissionsButton ? (
        <>
          {hasDenied ? (
            <PermissionsDeniedView
              branding={branding}
              setOpNumberView={setOpNumberView}
            />
          ) : (
            <>
              <div className={PreviewScreenContent}>
                <CameraPreview mediaError={mediaError} />

                {isAvailable ? (
                  <div className={PreviewScreenAvailable}>
                    <div className={PreviewScreenInfoWrapper}>
                      <Information
                        className={PreviewScreenInfoIcon}
                        size={25}
                      />
                      <p className={PreviewScreenInfoParagraph}>
                        {t('common:videoCall.legalText', {
                          productName: productName[appointmentType],
                        })}
                      </p>
                    </div>

                    <Button
                      onClick={() => joinTheMeet()}
                      color={
                        branding?.branding === BRANDING_KEYS.VCX
                          ? BUTTON_COLORS.transparent
                          : BUTTON_COLORS.primary
                      }
                      className={`w-full max-w-[200px] md:max-w-[300px] self-center ${
                        branding?.branding === BRANDING_KEYS.VCX &&
                        caixaBankButton
                      }`}
                      disabled={mediaError}
                    >
                      <VideoFilled size={25} />
                      <div className="text-xl font-medium">
                        {t('common:videoCall.buttonText')}
                      </div>
                    </Button>
                  </div>
                ) : (
                  <div>{children}</div>
                )}
              </div>
              {isCampaign && !options?.isAdmin && (
                <div className="text-center font-medium">
                  {t('common:videoCall.contact')}
                  <a href="tel:+34930467111">+34 930 46 71 11</a>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <PrelandingScreen
          branding={branding}
          handleCheckPermissions={handleCheckPermissions}
        />
      )}
    </div>
  )
}
