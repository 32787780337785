import { Text, View } from '@react-pdf/renderer'
import { areaColors, tw } from '../../utils'

const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

export const Numberedlist = ({
  list = [],
  column = false,
  withLetters = false,
  areaColor = '',
}) => {
  const title = (index, item) => {
    const bgColor = areaColor ? `bg-[${areaColors[areaColor]}]` : 'bg-secondary'
    const textColor = areaColor
      ? `text-[${areaColors[areaColor]}]`
      : 'text-secondary'

    return (
      <View style={tw(`flex flex-row items-center gap-2 py-2 ${textColor}`)}>
        <Text style={tw('font-semibold')}>
          {withLetters ? letters[index] : index + 1}
        </Text>
        <View style={tw(`w-[2px] h-full h-8 rounded-sm ${bgColor}`)}></View>
        <Text style={tw('font-semibold')}>{item.title}</Text>
      </View>
    )
  }

  return list.map((item, index) => {
    return (
      <View style={tw(`pt-2`)} key={`pdf-view-2-${index}`}>
        {column ? (
          <View style={tw('text-black leading-6 flex flex-col gap-2')} break>
            {title(index, item)}
            {item.isComponent ? (
              item.content
            ) : (
              <Text style={tw('pl-6')}>{item.content}</Text>
            )}
          </View>
        ) : (
          <Text style={tw('text-black leading-6')}>
            {title(index, item)}
            {item.isComponent ? item.content : <Text>{item.content}</Text>}
          </Text>
        )}
      </View>
    )
  })
}
