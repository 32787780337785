export const tabClasses = `
    relative
    px-8
    text-center
    cursor-pointer
`

export const tabBorderClasses = `
    absolute
    h-[3px]
    w-full
    top-[130%]
    left-[0px]
`

export const tabTitleContainerClasses = `
    flex
    items-center
    gap-2
`
