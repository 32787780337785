import { getDateFormatted } from '@shared/utils'
import { useTranslation } from 'react-i18next'
import {
  SRBigCalendarEventDayBlockStyle,
  SRBigCalendarEventDayStyle,
} from './styles'
import { SRBigCalendarEvent } from '../../../sr-big-calendar'

export const SRBigCalendarEventDay = ({
  event,
}: {
  event: SRBigCalendarEvent
}) => {
  const { t } = useTranslation()

  if (!event) return

  const { start, end, appointmentType, policy } = event

  const startDate = getDateFormatted(start, 'HH:mm A')
  const endDate = getDateFormatted(end, 'HH:mm A')

  return (
    <SRBigCalendarEventDayStyle>
      <SRBigCalendarEventDayBlockStyle>
        <div className="text-center">{startDate}</div>
        <div className="text-center">-</div>
        <div className="text-center">{endDate}</div>
      </SRBigCalendarEventDayBlockStyle>
      <SRBigCalendarEventDayBlockStyle>
        {appointmentType && <div>{t(appointmentType.text)}</div>}
        {policy && (
          <>
            <div>-</div>
            <div className="underline">{policy.fullName}</div>
          </>
        )}
      </SRBigCalendarEventDayBlockStyle>
    </SRBigCalendarEventDayStyle>
  )
}
