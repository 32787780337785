const commonButtonClasses = `
  font-bold
  disabled:text-white
  disabled:cursor-not-allowed
  disabled:border-mediumGray
  disabled:bg-mediumGray
  border-2
  text-base
`

export const variantContainedPrimaryClasses = `
  ${commonButtonClasses}
  group
  bg-primary
  border-primary
  text-white
  hover:text-primary
  hover:border-2
  hover:border-primary
  hover:bg-white
  hover:fill-white
  transition-all
  fill-primary
`

export const variantContainedTransparentClasses = `
  ${commonButtonClasses}
  group
  text-white
  disabled:bg-gray-400
  hover:border-2
  hover:bg-white
  hover:fill-white
  transition-all
`

export const variantContainedSecondaryClasses = `
  ${commonButtonClasses}
  bg-secondary
  border-secondary
  text-white
  disabled:bg-gray-400
  hover:text-secondary
  hover:border-2
  hover:border-secondary
  hover:bg-white
  hover:fill-white
  transition-all
  fill-secondary
`

export const variantContainedErrorClasses = `
  ${commonButtonClasses}
  bg-error
  border-error
  text-white
  disabled:bg-gray-400
  hover:text-error
  hover:border-2
  hover:border-error
  hover:bg-white
  hover:fill-white
  transition-all
  fill-error
`

export const variantOutlinedPrimaryClasses = `
  ${commonButtonClasses}
  text-primary
  border-primary
  disabled:bg-border-gray-400
  disabled:text-gray-400
`

export const variantOutlinedSecondaryClasses = `
  ${commonButtonClasses}
  text-secondary
  border-secondary
  disabled:bg-border-gray-400
  disabled:text-gray-400
`

export const variantOutlinedErrorClasses = `
  ${commonButtonClasses}
  text-error
  border-error
  disabled:bg-border-gray-400
  disabled:text-gray-400
`

export const variantTextPrimaryClasses = `${commonButtonClasses} text-primary`

export const variantTextSecondaryClasses = `${commonButtonClasses} text-secondary`

export const variantTextErrorClasses = `${commonButtonClasses} text-error`

export const buttonSizeSmall = `py-2 px-8`

export const buttonSizeXs = `py-2 px-3`

export const buttonSizeMedium = `py-3 px-8`

export const buttonSizeText = `p-0 border-0`

export const buttonRoundedSizeSmall = `p-3`

export const buttonRoundedSizeMedium = `p-4`

export const buttonRoundedSizeText = `p-0`
