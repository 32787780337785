import { Button, BUTTON_SIZES } from '@shared/ui'
import { useRouteState } from '@shared/utils'
import { SRSelect } from '@sr/ui'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { TestCancelModalRouteState } from './state'
import { TestModalActions, TestModalFormContainer } from './styles'
import { Form } from '@shared/ui'
import { getTestControlCancelSubStates } from '@shared/data-access'
import { useLayoutContext } from '@sr/routes/common'
import { useEffect } from 'react'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'

export const TestCancelConfirmation = observer(() => {
  const { t } = useTranslation()
  const { id } = useParams()
  const methods = useForm()
  const { watch } = methods
  const subState = watch('subState')
  const navigate = useNavigate()

  const { isLoading, policy, updateSubState } = useRouteState(
    TestCancelModalRouteState,
    {
      policyId: id,
    }
  )

  const { setIsLoading } = useLayoutContext()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  if (!policy) return

  const getTestControlSubStates = () => {
    return getTestControlCancelSubStates().map((substate) => ({
      value: substate.value,
      name: t(substate.name),
    }))
  }

  const onSave = async () => {
    await updateSubState(subState)
    navigate(ROUTE_PATH.userArea(id))
  }

  return (
    <>
      <TestModalFormContainer>
        <h3>{t('userArea.control.modal.abandoningReason')}</h3>
        <Form methods={methods}>
          <SRSelect
            hasNoneOption
            name="subState"
            options={getTestControlSubStates()}
          />
        </Form>
      </TestModalFormContainer>

      <TestModalActions>
        <Button
          size={BUTTON_SIZES.small}
          onClick={onSave}
          testId="onSave-button"
        >
          {t('userArea.control.modal.saveAndExit')}
        </Button>
      </TestModalActions>
    </>
  )
})
