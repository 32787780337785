import { BaseRouteState, Client } from '@shared/data-access'
import { actions } from '@sr/routes/common'
import { types, flow } from 'mobx-state-tree'

export const TestListCareState = BaseRouteState.named('TestListCareState')
  .props({
    policyId: types.maybeNull(types.string),
    client: types.maybeNull(types.reference(Client)),
  })
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('TestListCareState')
      try {
        yield self.loadClientByPolicy()
      } finally {
        self.removeLoading('TestListCareState')
      }
    }),
    ...actions(self),
  }))
