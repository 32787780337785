import { PfeifferGuidelines } from './guidelines'
import { PfeifferObjectives } from './objectives'
import { PfeifferResults } from './results'

export const Pfeiffer = () => {
  return (
    <>
      <PfeifferResults sectionLetter="A" />
      <PfeifferObjectives sectionLetter="B" />
      <PfeifferGuidelines sectionLetter="C" />
    </>
  )
}
