import { flow, types } from 'mobx-state-tree'
import { actions } from '@sr/routes/common'
import { t } from 'i18next'
import { getDateFormatted } from '@shared/utils'
import { BaseRouteState, CALL_REASON_NAME } from '@shared/data-access'
import { PhoneCall } from '@sr/data-access'

export const MonitoringAreaRegisterRouteState = BaseRouteState.named(
  'MonitoringAreaRegisterRouteState'
)
  .props({
    policyId: types.maybeNull(types.string),
    phoneCalls: types.maybeNull(types.array(types.reference(PhoneCall))),
  })
  .views((self) => ({
    get phoneCallsFiltered() {
      return self.phoneCalls?.map(({ callDate, callType }) => {
        if (!callDate || !callType) return {}
        return {
          callDate: getDateFormatted(callDate, 'YYYY-MM-DD'),
          callType: t(CALL_REASON_NAME[callType]),
        }
      }, [])
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadRegisterDependencies()
    },
    loadRegisterDependencies: flow(function* loadRegisterDependencies() {
      self.setLoading('MonitoringAreaRegisterRouteState')
      try {
        yield self.loadPhoneCalls()
      } finally {
        self.removeLoading('MonitoringAreaRegisterRouteState')
      }
    }),
    ...actions(self),
  }))
