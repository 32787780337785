import { useEffect } from 'react'
import { notify } from '@shared/ui'

/**
 * useHandleTrackPublicationFailed listener when a track could not be published an error callback is thrown
 * for example, the camera or microphone could not be turned on.
 * @param  {Object} room  options for room and all participants
 */
export const useHandleTrackPublicationFailed = (room) => {
  useEffect(() => {
    if (room) {
      room.localParticipant.on('trackPublicationFailed', (error) =>
        notify.error(error)
      )
      return () => {
        room.localParticipant.off('trackPublicationFailed', (error) =>
          notify.error(error)
        )
      }
    }
  }, [room])
}
