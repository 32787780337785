import { MultiSelect, MultiSelectOptions } from '@shared/ui'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { errorSpanClassName, selectErrorClassName } from '../styles'
import {
  multiSelectControlClasses,
  multiValueOptionClasses,
  multiValueRemoveClasses,
  optionClasses,
} from './styles'

type SRMultiSelectProps = {
  options: MultiSelectOptions[]
  name: string
  isMulti?: boolean
  isRequired?: boolean
  defaultValue?: string[]
  placeholder?: string | JSX.Element
  classNames?: object
  isClearable?: boolean
}

export const SRMultiSelect: FC<SRMultiSelectProps> = ({
  options,
  name,
  isMulti = false,
  isRequired = false,
  defaultValue,
  placeholder,
  classNames = '',
  isClearable,
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  const hasError = errors?.[name] && errors?.[name]?.message
  const errorMessage = String(errors?.[name]?.message)

  return (
    <>
      <MultiSelect
        options={options}
        name={name}
        isMulti={isMulti}
        isRequired={isRequired}
        defaultValue={defaultValue}
        placeholder={placeholder}
        classNames={{
          control: () =>
            `${multiSelectControlClasses} ${classNames} ${
              hasError ? selectErrorClassName : ''
            }`,
          multiValue: () => multiValueOptionClasses,
          option: () => optionClasses,
          multiValueRemove: () => multiValueRemoveClasses,
        }}
        isClearable={isClearable}
      />
      {hasError && <span className={errorSpanClassName}>* {errorMessage}</span>}
    </>
  )
}
