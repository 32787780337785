import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BUTTON_SIZES, Button } from '@shared/ui'

import { useLayoutContext } from '@sr/routes/common/layout-provider'

type ReminderBlockProps = {
  sendEmail: () => void
  sendEmailTranslation?: string
}

export const ReminderBlock: FC<ReminderBlockProps> = ({
  sendEmail,
  sendEmailTranslation = 'send',
}) => {
  const { t } = useTranslation()

  const { isLoading } = useLayoutContext()

  return (
    <div className='grid grid-cols-2 gap-2 mb-9'>
      <div className='text-base font-semibold text-black flex items-center'>
        {t('userArea.profile.welcomeEmail.send')}
      </div>
      <div className='text-base font-bold flex flex-col items-end pr-[55px] align-center'>
        <Button
          size={BUTTON_SIZES.small}
          onClick={sendEmail}
          disabled={isLoading}
          contentClassName='text-base'
        >
          {t(`userArea.profile.welcomeEmail.${sendEmailTranslation}`)}
        </Button>
      </div>
    </div>
  )
}
