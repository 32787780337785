import { flow, getEnv, getRoot, types } from 'mobx-state-tree'

import {
  APPOINTMENT_TYPES,
  BaseRouteState,
  Policy,
  SocialReferent,
  ClientPreferences,
} from '@shared/data-access'

import { ModalType, actions } from '@sr/routes/common'
import { Appointment, AvailableSR } from '@sr/data-access'
import { getDateFormatted, getToday, getWorkDayFromDate } from '@shared/utils'

export const ProfileCareActionsRouteState = BaseRouteState.named(
  'ProfileCareActionsRouteState',
)
  .props({
    policyId: types.maybeNull(types.string),
    policy: types.maybeNull(types.reference(Policy)),
    user: types.maybeNull(types.reference(SocialReferent)),
    appointments: types.maybeNull(types.array(types.reference(Appointment))),
    clientPreferences: types.maybeNull(types.reference(ClientPreferences)),
    availableSocialReferentList: types.maybeNull(
      types.array(types.safeReference(AvailableSR), {}),
    ),
    modalType: types.maybeNull(types.string),
  })
  .views((self) => ({
    get appointment() {
      return self.appointments?.find((appointment) => {
        return appointment.appointmentType === APPOINTMENT_TYPES.INITIAL_REPORT
      })
    },
    get canChangeOp() {
      return Boolean(
        self.user?.isCoordinator &&
          self.policy?.isSubscriptionPending &&
          self.policy.firstAppointmentDate,
      )
    },
    get availableSocialReferentListParsed() {
      return self.availableSocialReferentList?.map(({ id, fullName }) => ({
        name: fullName,
        value: id,
      }))
    },
    get showSendEmailButton() {
      return self.policy?.canSendRegisterEmail || self.policy?.canResendEmail
    },
    get canUploadFile() {
      return !!self.policy?.resolutionDate && self.policy?.noDigital
    },
    get isModifyAppointment() {
      return (
        self.policy?.firstAppointmentDate && self.policy?.isSubscriptionPending
      )
    },
    get isSubscriptionPending() {
      return self.policy?.isSubscriptionPending
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('ProfileCareActionsRouteState')
      const startDate = getDateFormatted(getToday(), 'YYYY-MM-DD')
      const endDate = getDateFormatted(
        getWorkDayFromDate(startDate, 25),
        'YYYY-MM-DD',
      )
      try {
        const userInfo = self.loadUserInfo()
        const policy = self.loadPolicy()
        const appointments = self.loadAppointments({
          startDate,
          endDate,
          policyId: self.policyId,
        })

        yield Promise.all([userInfo, policy, appointments])

        if (self.canChangeOp) {
          yield self.loadAvailableSrForChangeCare()
        }
      } finally {
        self.removeLoading('ProfileCareActionsRouteState')
      }
    }),
    sendSignUpCommunication: flow(function* sendSignUpCommunication() {
      self.setLoading('sendSignUpCommunication')
      try {
        yield getRoot(self).PolicyStore.get(null, {
          customUrl: `/policies/${self.policyId}/send_sign_up_communication`,
          action: 'sendSignUpCommunication',
          avoidUpdate: true,
        })
        yield getRoot(self).PolicyStore.fetch(self.policyId)
      } finally {
        self.removeLoading('sendSignUpCommunication')
      }
    }),
    setClientPreferences: flow(function* setClientPreferences(data, id) {
      try {
        self.setLoading('setClientPreferences')

        const response = yield getRoot(self).ClientPreferencesStore.patch(
          {...data, id }, 
          {
            withoutId: false,
            customUrl: `/client_preferences`,
        })
        
        if (response) {
          console.log('Successfully patched client preferences:', response)
          yield self.getClientPreferences()
          self.removeLoading("setClientPreferences")
        }
      } catch (error) {
        console.error('Error patching client preferences sent data:', error)
      } finally {
        self.removeLoading("setClientPreferences")
      }
    }),
    getClientPreferences: flow(function* getClientPreferences() {
      self.setLoading('getClientPreferences');
      try {
        const clientPreferencesData = yield getRoot(self).ClientPreferencesStore.fetch(null, {
          withoutId: true,
          customUrl: `/policies/${self.policyId}/get_client_preferences`,
        });

        if (clientPreferencesData) {
          self.clientPreferences = clientPreferencesData;
          self.clientPreferencesId = clientPreferencesData.id;
        }
      } catch (error) {
        console.error('Error fetching client preferences:', error);
      } finally {
        self.removeLoading('getClientPreferences');
      }
    }),
    uploadVideo: flow(function* uploadVideo(file, controller) {
      const { signal } = controller
      self.setLoading('uploadVideo')
      try {
        const response = yield getEnv(self).client.uploadVideoToBucket({
          policyId: self.policyId,
          file,
          signal,
        })
        return response
      } finally {
        self.removeLoading('uploadVideo')
      }
    }),
    setModal(modalType) {
      self.modalType = modalType
    },
    onError() {
      self.setModal(ModalType.genericError)
    },
    ...actions(self),
  }))
