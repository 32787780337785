import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'

export const PHYSICAL_ACTIVITY_KEYS = Object.freeze({
  recPhysicalActivityGeneric: 'rec_physical_activity_generic',
  recPhysicalActivityPrecontemplation: 'rec_physical_activity_precontemplation',
  recPhysicalActivityContemplation: 'rec_physical_activity_contemplation',
  recPhysicalActivityNoSedentary: 'rec_physical_activity_no_sedentary',
  recPhysicalActivityMedicalContrindicationExercise:
    'rec_physical_activity_medical_contrindication_excercise',
  recPhysicalActivityAction1: 'rec_physical_activity_action_1',
  recPhysicalActivityAction3: 'rec_physical_activity_action_3',
  recPhysicalActivityAction2: 'rec_physical_activity_action_2',
  recPhysicalActivityAction4: 'rec_physical_activity_action_4',
})

export const PHYSICAL_ACTIVITY_TRANSCRIPTION_MESSAGE = (patientName) =>
  Object.freeze({
    [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityPrecontemplation]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionThree.secondPage.assesmentScale.rec_physical_activity_precontemplation'
        }
        scriptProps={{ patientName }}
      />
    ),
    [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityContemplation]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionThree.secondPage.assesmentScale.rec_physical_activity_contemplation'
        }
        scriptProps={{ patientName }}
      />
    ),
    [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityNoSedentary]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionThree.secondPage.assesmentScale.rec_physical_activity_no_sedentary'
        }
        scriptProps={{ patientName }}
      />
    ),
    [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityMedicalContrindicationExercise]:
      (
        <TranscriptionScriptPDF
          script={
            'initial-report:sectionThree.secondPage.assesmentScale.rec_physical_activity_medical_contrindication_excercise'
          }
          scriptProps={{ patientName }}
        />
      ),
    [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction1]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionThree.secondPage.assesmentScale.rec_physical_activity_action_1'
        }
        scriptProps={{ patientName }}
      />
    ),
    [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction3]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionThree.secondPage.assesmentScale.rec_physical_activity_action_3'
        }
        scriptProps={{ patientName }}
      />
    ),
    [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction2]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionThree.secondPage.assesmentScale.rec_physical_activity_action_2'
        }
        scriptProps={{ patientName }}
      />
    ),
    [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction4]: (
      <TranscriptionScriptPDF
        script={
          'initial-report:sectionThree.secondPage.assesmentScale.rec_physical_activity_action_4'
        }
        scriptProps={{ patientName }}
      />
    ),
  })

export const PHYSICAL_ACTIVITY_TRANSCRIPTION_OBJETIVE = Object.freeze({
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityPrecontemplation]: (
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionThree.secondPage.objetives.rec_physical_activity_precontemplation'
      }
    />
  ),
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityContemplation]: (
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionThree.secondPage.objetives.rec_physical_activity_contemplation'
      }
    />
  ),
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityNoSedentary]: (
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionThree.secondPage.objetives.rec_physical_activity_no_sedentary'
      }
    />
  ),
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityMedicalContrindicationExercise]: (
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionThree.secondPage.objetives.rec_physical_activity_medical_contrindication_excercise'
      }
    />
  ),
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction1]: (
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionThree.secondPage.objetives.rec_physical_activity_action_1'
      }
    />
  ),
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction3]: (
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionThree.secondPage.objetives.rec_physical_activity_action_3'
      }
    />
  ),
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction2]: (
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionThree.secondPage.objetives.rec_physical_activity_action_2'
      }
    />
  ),
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction4]: (
    <TranscriptionScriptPDF
      script={
        'initial-report:sectionThree.secondPage.objetives.rec_physical_activity_action_4'
      }
    />
  ),
})

const Links = Object.freeze({
  physicalLinkOne: 0,
  physicalLinkTwo: 1,
  physicalLinkThree: 2,
  physicalLinkFour: 3,
  physicalLinkFive: 4,
  physicalLinkSix: 5,
  physicalLinkSeven: 6,
  physicalLinkEight: 7,
  physicalLinkNine: 8,
  physicalLinkTen: 9,
  physicalLinkEleven: 10,
  physicalLinkTwelve: 11,
  physicalLinkThirteen: 12,
})

export const PHYSICAL_ACTIVITY_LINKS = Object.freeze({
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityGeneric]: [Links.physicalLinkTwo],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityPrecontemplation]: [
    Links.physicalLinkOne,
    Links.physicalLinkTwo,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityContemplation]: [
    Links.physicalLinkOne,
    Links.physicalLinkThree,
    Links.physicalLinkFour,
    Links.physicalLinkFive,
    Links.physicalLinkSix,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityNoSedentary]: [
    Links.physicalLinkOne,
    Links.physicalLinkThree,
    Links.physicalLinkFour,
    Links.physicalLinkFive,
    Links.physicalLinkSix,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityMedicalContrindicationExercise]: [
    Links.physicalLinkFour,
    Links.physicalLinkSeven,
    Links.physicalLinkFive,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction1]: [
    Links.physicalLinkEleven,
    Links.physicalLinkTwelve,
    Links.physicalLinkThirteen,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction3]: [
    Links.physicalLinkOne,
    Links.physicalLinkFive,
    Links.physicalLinkSix,
    Links.physicalLinkEight,
    Links.physicalLinkNine,
    Links.physicalLinkTen,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction2]: [
    Links.physicalLinkFive,
    Links.physicalLinkSix,
    Links.physicalLinkEight,
    Links.physicalLinkNine,
    Links.physicalLinkTen,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction4]: [
    Links.physicalLinkFive,
    Links.physicalLinkSix,
    Links.physicalLinkEight,
    Links.physicalLinkNine,
    Links.physicalLinkTen,
  ],
})

const WorkSheet = Object.freeze({
  physicalWorksheetMakeTheFirstStep: 0,
  physicalWorksheetLinkTwo: 1,
  physicalWorksheetLinkThree: 2,
  physicalWorksheetLinkFour: 3,
  physicalWorksheetLinkFive: 4,
  physicalWorksheetLinkSix: 5,
  physicalWorksheetLinkSeven: 6,
  physicalWorksheetLinkEight: 7,
})

export const PHYSICAL_ACTIVITY_WORKSHEET_LINKS = Object.freeze({
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityGeneric]: [
    WorkSheet.physicalWorksheetLinkTwo,
    WorkSheet.physicalWorksheetLinkThree,
    WorkSheet.physicalWorksheetLinkFour,
    WorkSheet.physicalWorksheetLinkFive,
    WorkSheet.physicalWorksheetLinkSeven,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityPrecontemplation]: [
    WorkSheet.physicalWorksheetMakeTheFirstStep,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityContemplation]: [
    WorkSheet.physicalWorksheetLinkTwo,
    WorkSheet.physicalWorksheetLinkThree,
    WorkSheet.physicalWorksheetLinkFour,
    WorkSheet.physicalWorksheetLinkFive,
    WorkSheet.physicalWorksheetLinkSix,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityNoSedentary]: [
    WorkSheet.physicalWorksheetLinkTwo,
    WorkSheet.physicalWorksheetLinkThree,
    WorkSheet.physicalWorksheetLinkFour,
    WorkSheet.physicalWorksheetLinkFive,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityMedicalContrindicationExercise]: [
    WorkSheet.physicalWorksheetLinkFour,
    WorkSheet.physicalWorksheetLinkSeven,
    WorkSheet.physicalWorksheetLinkFive,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction1]: [
    WorkSheet.physicalWorksheetLinkTwo,
    WorkSheet.physicalWorksheetLinkThree,
    WorkSheet.physicalWorksheetLinkFour,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction3]: [
    WorkSheet.physicalWorksheetLinkTwo,
    WorkSheet.physicalWorksheetLinkThree,
    WorkSheet.physicalWorksheetLinkFour,
    WorkSheet.physicalWorksheetLinkFive,
    WorkSheet.physicalWorksheetLinkSix,
    WorkSheet.physicalWorksheetLinkSeven,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction2]: [
    WorkSheet.physicalWorksheetLinkTwo,
    WorkSheet.physicalWorksheetLinkThree,
    WorkSheet.physicalWorksheetLinkFour,
    WorkSheet.physicalWorksheetLinkFive,
    WorkSheet.physicalWorksheetLinkSix,
    WorkSheet.physicalWorksheetLinkSeven,
  ],
  [PHYSICAL_ACTIVITY_KEYS.recPhysicalActivityAction4]: [
    WorkSheet.physicalWorksheetLinkTwo,
    WorkSheet.physicalWorksheetLinkThree,
    WorkSheet.physicalWorksheetLinkFour,
    WorkSheet.physicalWorksheetLinkFive,
    WorkSheet.physicalWorksheetLinkSix,
    WorkSheet.physicalWorksheetLinkSeven,
  ],
})
