import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useBreadcrumbStore, BreadcrumbItem } from '../../hooks/useBreadCrumbStore'

import { BREAK_POINTS, useBreakpoint } from '@shared/ui';
import { ArrowLeft, ChevronRight } from '@carbon/icons-react';

import {
  BreadcrumbContainer,
  BreadcrumbListContainer,
  BreadcrumbListItemWrapper,
  BreadcrumbListItem,
  BreadcrumbListItemMobile,
  BreadcrumbItem as BreadcrumbItemComponent,
  BreadcrumbListElement,
} from './styles.js';
import { countCharacters, ellipsisText } from '@shared/utils';

interface BreadcrumbProps {
  label: string;
  path: string;
  
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ label, path }) => {
  const segments = path.split('/').filter(segment => segment !== '');
  const { breadcrumbs, setBreadcrumb, clearBreadcrumb } = useBreadcrumbStore();
  const { t } = useTranslation();
  const { isLg } = useBreakpoint(BREAK_POINTS.lg);

  useEffect(() => {
    if (segments.length === 1) {
      clearBreadcrumb();
    } else {
      setBreadcrumb(segments.map((segment, index) => ({
        label: segment,
        path: `/${segments.slice(0, index + 1).join('/')}`,
      })));
    }
  }, []);

  const renderBreadcrumbItems = () => (
    breadcrumbs.map((item: BreadcrumbItem, index: number) => (
      <React.Fragment key={index}>
        {item.path ? (
          isLg ? (
            <BreadcrumbListElement key={`${item.label}-${index}`}>
              <BreadcrumbListItemWrapper>
                <BreadcrumbListItem title={item.label} href={item.path}>
                  {item.label}
                </BreadcrumbListItem>
                <ChevronRight className="ml-2 text-orange" size={15} />
              </BreadcrumbListItemWrapper>
            </BreadcrumbListElement>
          ) : (
            <BreadcrumbListElement  key={`${item.label}-${index}`} >
              <BreadcrumbListItem href="/">
                <ArrowLeft className="mr-2 text-orange" size={15} />
              </BreadcrumbListItem>
            </BreadcrumbListElement>
          )
        ) : (
          <BreadcrumbListElement key={`${item.label}-${index}`}>
            <BreadcrumbItemComponent title={item.label}>{countCharacters(item.label) > 20 ? ellipsisText(item.label, 20): item.label}</BreadcrumbItemComponent>
          </BreadcrumbListElement>
        )}
      </React.Fragment>
    ))
  );

  const renderSinglePath = () => (
    <>
    <BreadcrumbListElement>
      <BreadcrumbListItem title={t('common.home')} href="/">
        {t('common.home')}
      </BreadcrumbListItem>
      <ChevronRight className="ml-2 text-orange" size={15}/>
      </BreadcrumbListElement>
      <BreadcrumbListElement>
        <BreadcrumbItemComponent>{t(label)}</BreadcrumbItemComponent>
      </BreadcrumbListElement>
    </>
  );

  const renderSinglePathCompact = () => (
    <BreadcrumbListElement>
      <BreadcrumbListItem href="/">
        <ArrowLeft className="mr-2 text-orange" size={15} />
      </BreadcrumbListItem>
      <BreadcrumbItemComponent>{t(label)}</BreadcrumbItemComponent>
    </BreadcrumbListElement>
  );

  return (
    <BreadcrumbContainer aria-label="Breadcrumb" data-testid="Breadcrumb">
      <BreadcrumbListContainer role="list">
        {breadcrumbs.length > 0 && segments.length !== 1 ? (
          isLg ? (
            <>
              <BreadcrumbListElement>
                <BreadcrumbListItem title={t('common.home')} href="/">
                  {t('common.home')}
                </BreadcrumbListItem>
                <ChevronRight className="ml-2 text-orange" size={15} />
              </BreadcrumbListElement>
              {renderBreadcrumbItems()}
            </>
          ) : (
            <>
              <BreadcrumbListElement>
                <BreadcrumbListItemMobile
                  href={breadcrumbs[breadcrumbs.length - 2]?.path || '/'}
                  role="link"
                >
                  <ArrowLeft className="mr-2 text-orange" size={15} />
                </BreadcrumbListItemMobile>
              </BreadcrumbListElement>
              <BreadcrumbListElement>
                <BreadcrumbItemComponent>{breadcrumbs[breadcrumbs.length - 1]?.label || t(label)}</BreadcrumbItemComponent>
              </BreadcrumbListElement>
            </>
          )
        ) : isLg ? (
          renderSinglePath()
        ) : (
          renderSinglePathCompact()
        )}
      </BreadcrumbListContainer>
    </BreadcrumbContainer>
  );
};