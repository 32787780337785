import { tw as newTw } from '../report-pdf/utils'

export const tw = newTw

export const areaColorKey = {
  yellow: 'yellow',
  green: 'green',
  blueGreen: 'blue-green',
  pink: 'pink',
  red: 'red',
  blue: 'blue',
  orange: 'orange',
  white: 'white',
}

export const areaColors = {
  [areaColorKey.yellow]: '#FDC646',
  [areaColorKey.green]: '#9ECA38',
  [areaColorKey.blueGreen]: '#14AA9C',
  [areaColorKey.pink]: '#C763B0',
  [areaColorKey.red]: '#DF3640',
  [areaColorKey.blue]: '#4BA8DF',
  [areaColorKey.orange]: '#EE5A21',
  [areaColorKey.white]: '#FFFFFF',
}

export const areaColorsLighter = {
  [areaColorKey.green]: '#D8EAAF',
  [areaColorKey.blueGreen]: '#A1DDD7',
  [areaColorKey.pink]: '#E8C1DF',
  [areaColorKey.orange]: '#FACEBD',
  [areaColorKey.red]: '#F6C3C6',
  [areaColorKey.yellow]: '#FFF2CC',
  [areaColorKey.blue]: '#CAE5F6',
}

export const scoreIsNotAvailable = (score) => {
  return score === undefined || score === 'not available'
}

export const hasInnerItemsInArray = (arr) =>
  arr.some((category) =>
    Object.values(category).some((items) => items.length > 0),
  )
