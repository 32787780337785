import { useTrack } from '../../../../hooks'
import { VideoTrack } from '../video-track'

export const Publication = ({
  videoPriority,
  publication,
  isLocalParticipant,
  showLogo,
}) => {
  const track = useTrack(publication)
  if (!track) return null

  return track.kind === 'video' ? (
    <VideoTrack
      videoPriority={videoPriority}
      isLocal={!track.name.includes('screen') && isLocalParticipant}
      track={track}
      showLogo={showLogo}
    />
  ) : null
}
