import { useState } from 'react'
import { isSupported } from 'twilio-video'
import {
  EndText,
  MeetScreen,
  PreviewScreen,
  UnSupportedBrowser,
} from './components'
import { useRoomState, useVideoContext } from './hooks'
import { determineContentKey } from './utils'

export const VideoCall = ({ token, roomName }) => {
  const [userWantJoin, setUserWantJoin] = useState(false)

  const joinTheMeet = () => {
    setUserWantJoin(true)
  }

  if (!token) {
    console.error('no token')
  }
  if (!roomName) {
    console.error('no room name')
  }

  const { isRoomFinished } = useVideoContext()
  const roomState = useRoomState()

  const contentMap = {
    unsupported: <UnSupportedBrowser />,
    endText: <EndText />,
    meetScreen: <MeetScreen token={token} roomName={roomName} />,
    previewScreen: <PreviewScreen joinTheMeet={joinTheMeet} />,
  }

  const contentKey = determineContentKey({
    isSupported,
    isRoomFinished,
    roomState,
    userWantJoin,
  })

  return <div className="w-full h-full">{contentMap[contentKey]}</div>
}
