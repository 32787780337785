import React from 'react'

import {
  additionalServicesOptions,
  clientStatusOptions,
  communicationPredisposablesOptions,
  contentPlanOptions,
  followUpOptions,
  infoPreventOptions,
  notesOptions,
  opOptions,
  preferedChannelOptions,
  riskPlanOptions,
  schedulePreferOptions,
  trustLevelOptions,
} from './enums'

import { SectionBlock } from '../section-block'
import { NotesBlock } from '../notes-block'
import { IPreferences } from '../../actions/care-actions/enums'

interface FormBlockProps {
  section: string
  data?: IPreferences
}

export const FormBlock: React.FC<FormBlockProps> = ({ section, data }) => {
  const { id } = data ?? {}

  const receivedObject = JSON.parse(JSON.stringify(data))

  const transformedObject = {
    clientStatusOptions: {
      clientLocalized: receivedObject?.clientLocalized,
    },
    communicationPredisposablesOptions: {
      contactPredisposition: receivedObject?.contactPredisposition,
    },
    preferedChannelOptions: {
      emailPreferred: receivedObject?.emailPreferred,
      phoneCallPreferred: receivedObject?.phoneCallPreferred,
      textPreferred: receivedObject?.textPreferred,
    },
    schedulePreferOptions: {
      contact_9_to_11: receivedObject?.contactNineToEleven,
      contact_11_to_13: receivedObject?.contactElevenToThirteen,
      contact_13_to_15: receivedObject?.contactThirteenToFifteen,
      contact_15_to_17: receivedObject?.contactFifteenToSeventeen,
      contact_17_to_18: receivedObject?.contactSeventeenToEighteen,
    },
    trustLevelOptions: {
      srConfidence: receivedObject?.srConfidence,
    },
    opOptions: {
      srServiceInterest: receivedObject?.srServiceInterest,
    },
    riskPlanOptions: {
      riskPlan: receivedObject?.preventionPlanServiceInterest,
    },
    contentPlanOptions: {
      contentPlatformInterest: receivedObject?.contentPlatformServiceInterest,
    },
    infoPreventOptions: {
      preventionPlanServiceInterest:
        receivedObject?.preventionPlanServiceInterest,
    },
    followUpOptions: {
      followUp: receivedObject?.followUpAnalysisInterest,
    },
    additionalServicesOptions: {
      physicalActivityInterest: receivedObject?.physicalActivityInterest,
      alcoholInterest: receivedObject?.alcoholInterest,
      auditiveCareInterest: receivedObject?.auditiveCareInterest,
      earlyDetectionInterest: receivedObject?.earlyDetectionInterest,
      emotionalBeingInterest: receivedObject?.emotionalBeingInterest,
      neurodegenerativeIllInterest:
        receivedObject?.neurodegenerativeIllInterest,
      activeMindInterest: receivedObject?.activeMindInterest,
      newLinesInterest: receivedObject?.newLinesInterest,
      nutritionInterest: receivedObject?.nutritionInterest,
      ocularCareInterest: receivedObject?.ocularCareInterest,
      sleepInterest: receivedObject?.sleepInterest,
      socialActivityInterest: receivedObject?.socialActivityInterest,
      tobaccoInterest: receivedObject?.tobaccoInterest,
    },
    notesOptions: {
      notes: receivedObject?.notes,
    },
  }

  return (
    <>
      {section === 'prefered-communication-channel' && (
        <>
          <SectionBlock
            id={id}
            field="client_localized"
            data={clientStatusOptions(transformedObject.clientStatusOptions)}
          />
          <SectionBlock
            id={id}
            field="contact_predisposition"
            data={communicationPredisposablesOptions(
              transformedObject.communicationPredisposablesOptions,
            )}
          />
          <SectionBlock
            id={id}
            field="prefered_channel"
            data={preferedChannelOptions(
              transformedObject.preferedChannelOptions,
            )}
          />
          <SectionBlock
            id={id}
            field="schedule_prefer"
            data={schedulePreferOptions(
              transformedObject.schedulePreferOptions,
            )}
          />
          <SectionBlock
            id={id}
            field="sr_confidence"
            data={trustLevelOptions(transformedObject.trustLevelOptions)}
          />
        </>
      )}

      {section === 'additional-services-interest' && (
        <>
          <SectionBlock
            id={id}
            field="sr_service_interest"
            data={opOptions(transformedObject.opOptions)}
          />
          <SectionBlock
            id={id}
            field="prevention_plan_service_interest"
            data={riskPlanOptions(transformedObject.riskPlanOptions)}
          />
          <SectionBlock
            id={id}
            field="content_platform_service_interest"
            data={contentPlanOptions(transformedObject.contentPlanOptions)}
          />
          <SectionBlock
            id={id}
            field="preventive_powers_service_interest"
            data={infoPreventOptions(transformedObject.infoPreventOptions)}
          />
          <SectionBlock
            id={id}
            field="follow_up_analysis_interest"
            data={followUpOptions(transformedObject.followUpOptions)}
          />
        </>
      )}

      {section === 'additional-services-interest-II' && (
        <>
          <SectionBlock
            id={id}
            field="additional_services"
            data={additionalServicesOptions(
              transformedObject.additionalServicesOptions,
            )}
          />
        </>
      )}

      {section === 'notes' && (
        <NotesBlock
          id={id}
          field="notes"
          data={notesOptions(transformedObject.notesOptions)}
        />
      )}
    </>
  )
}
