import { BIG_CALENDAR_VIEW } from '@shared/ui'
import { getDateFormatted, getDayFrom } from '@shared/utils'
import { ArrowLeft, ArrowRight } from '@carbon/icons-react'

export const SRBigCalendarToolbar = ({ view, date, setDate }) => {
  const isDay = view === BIG_CALENDAR_VIEW.day
  const dateFormatted = getDateFormatted(date, 'dddd D MMMM')

  const nextDay = () => {
    setDate(getDayFrom(date, +1))
  }

  const previousDay = () => {
    setDate(getDayFrom(date, -1))
  }

  return (
    isDay && (
      <div className="flex gap-2 pb-10 pl-20">
        <div className="capitalize">{dateFormatted}</div>
        <div className="flex gap-2">
          <button
            data-testid="bc-toolbar-previous-button"
            onClick={previousDay}
          >
            <ArrowLeft />
          </button>
          <button data-testid="bc-toolbar-next-button" onClick={nextDay}>
            <ArrowRight />
          </button>
        </div>
      </div>
    )
  )
}
