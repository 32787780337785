import { Form } from '@shared/ui'
import { useRouteState } from '@shared/utils'
import { SRSelect } from '@sr/ui'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { AgendaSrSelectRouteState } from './state'

export const AgendaSrSelect = observer(() => {
  const { socialReferentsListFormatted } = useRouteState(
    AgendaSrSelectRouteState,
  )
  const navigate = useNavigate()
  const { id } = useParams()
  const methods = useForm({
    defaultValues: {
      socialReferent: id,
    },
  })

  const { watch } = methods
  const socialReferent = watch('socialReferent')

  useEffect(() => {
    if (socialReferent !== id) {
      navigate(ROUTE_PATH.agenda(socialReferent))
    }
  }, [socialReferent])

  if (!socialReferentsListFormatted) return

  return (
    <Form methods={methods}>
      <SRSelect
        name={'socialReferent'}
        options={socialReferentsListFormatted}
      />
    </Form>
  )
})
