import { ReportPdfContextProps } from '../../../../provider'
import { PfeifferScoreResult } from '../../../../scales/pfeiffer'
import { scoreIsNotAvailable } from '../../../../utils'

export const getPfeifferScaleList = (context: ReportPdfContextProps) => {
  const { t, testDate, scores, person } = context
  const { score, scale, scoreName } = scores?.pfeifferScore || {}

  return [
    {
      label: t('initial-report:generic.resultTable.scaleDescription'),
      content: t('initial-report:scores.pfeiffer.description'),
    },
    {
      label: t('initial-report:generic.resultTable.testDate'),
      content: testDate,
    },
    {
      label: t('initial-report:generic.resultTable.result'),
      content: scoreIsNotAvailable(score)
        ? t('initial-report:resultNotAvailable.pfeiffer', {
            patientName: person?.name,
          })
        : t(`initial-report:scores.pfeiffer.${scoreName}.value`, {
            scale,
          }),
    },
  ]
}

export const getPfeifferPerYearList = (context: ReportPdfContextProps) => {
  const { t, scores } = context
  const { result } = scores?.pfeifferScore || {}

  return [
    {
      text: 'A:8-10',
      subText: t(
        'initial-report:sectionCognitive.results.scaleValues.possible',
      ),
      selected: result === PfeifferScoreResult.severe,
    },
    {
      text: 'B:5-7',
      selected: result === PfeifferScoreResult.moderate,
    },
    {
      text: 'C:3-4',
      selected: result === PfeifferScoreResult.mild,
    },
    {
      text: 'D:0-2',
      subText: t('initial-report:sectionCognitive.results.scaleValues.none'),
      selected: result === PfeifferScoreResult.normal,
    },
  ]
}
