export const EVENT_FORM = Object.freeze({
  appointmentType: 'appointmentType',
  appointmentDay: 'appointmentDay',
  appointmentPolicy: 'appointmentPolicy',
  unavailableSlotType: 'unavailableSlotType',
  appointmentModality: 'appointmentModality',
  startDate: 'startDate',
  endDate: 'endDate',
  allDay: 'allDay',
  formPersonId: 'formPersonId',
  changeOrigin: 'appointmentModificationOrigin',
  changeReason: 'appointmentModificationReason',
})
