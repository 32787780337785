import { getRoot, types } from 'mobx-state-tree'
import { Client } from '../client'
import { BaseConsent } from '../base-consent'

export const MODEL_NAME = 'ClientConsent'

export const Model = BaseConsent.named(MODEL_NAME)
  .props({
    client: types.maybeNull(types.reference(Client)),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
