import { SRModal } from '@sr/ui'
import { Renew, Close } from '@carbon/icons-react'
import { BUTTON_COLORS, BUTTON_SIZES, Button } from '@shared/ui'
import { useTranslation } from 'react-i18next'
import { UploadModalWrapper } from './styles'
import { useEffect, useState } from 'react'
import { getDateFormatted, getToday, getMinute, getHour } from '@shared/utils'

export const UploadModal = ({
  videoTitle,
  controller,
  onClose,
  videoEnd = false,
  error = false,
}) => {
  const { t } = useTranslation()

  const [date, setDate] = useState('')
  const [hour, setHour] = useState('')

  useEffect(() => {
    if (videoEnd) {
      const today = getToday()
      setDate(getDateFormatted(today, 'MM-DD-YYYY'))
      setHour(`${getHour(today)}:${getMinute(today)}`)
    }
  }, [videoEnd])

  return (
    <SRModal>
      <UploadModalWrapper>
        {videoEnd && (
          <Close
            role="button"
            onClick={onClose}
            className="text-black ml-auto"
            size={25}
          />
        )}
        <Renew
          size={40}
          className={`${!videoEnd && 'animate-spin rotate-180'}`}
        />
        {videoEnd ? (
          <>
            <div>
              {error
                ? t('userArea.profile.uploadVideo.error')
                : t('userArea.profile.uploadVideo.success')}
            </div>
            <div>
              {t('userArea.profile.uploadVideo.fileName', {
                videoTitle,
              })}
            </div>
            <div>{t('userArea.profile.uploadVideo.date', { date })}</div>
            <div>{t('userArea.profile.uploadVideo.hour', { hour })}</div>
          </>
        ) : (
          <>
            <div className="text-center">
              {t('userArea.profile.uploadVideo.loadingText')}
            </div>
            <Button
              onClick={() => {
                onClose()
                controller.abort()
              }}
              size={BUTTON_SIZES.small}
              color={BUTTON_COLORS.secondary}
            >
              {t('common:cancel')}
            </Button>
          </>
        )}
      </UploadModalWrapper>
    </SRModal>
  )
}
