import { useTranslation } from 'react-i18next'
import { AgendaLegendContainer } from './styles'
import { PalmTree } from '@carbon/icons-react'
import {
  LEGEND_ICONS,
  LEGEND_ONE,
  LEGEND_TRANSLATIONS,
  LEGEND_TWO,
} from './enums'
import { LEGEND_COLORS, LEGEND_TEXT_COLOR } from '@shared/data-access'
import { AgendaLegendGroupContainer } from './components'

export const AgendaLegend = () => {
  const { t } = useTranslation()

  const getChipList = (list, hasFreeSlot = false) => {
    const freeSlot = {
      icon: <PalmTree size={16} />,
      label: t('appointmentType.freeSlot'),
    }

    const types = list.map((value) => ({
      icon: LEGEND_ICONS[value],
      label: t(LEGEND_TRANSLATIONS[value]),
      bg: LEGEND_COLORS[value],
      textColor: LEGEND_TEXT_COLOR[value] || '',
    }))

    return hasFreeSlot ? [...types, freeSlot] : [...types]
  }

  const listOne = getChipList(LEGEND_ONE, true)
  const listTwo = getChipList(LEGEND_TWO, false)

  return (
    <AgendaLegendContainer>
      <AgendaLegendGroupContainer list={listOne} />
      <AgendaLegendGroupContainer list={listTwo} />
    </AgendaLegendContainer>
  )
}
