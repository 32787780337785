import { BaseRouteState } from '@shared/data-access'
import { flow, getRoot } from 'mobx-state-tree'

export const AbsencesAppointmentState = BaseRouteState.named(
  'AbsencesAppointmentState'
).actions((self) => ({
  saveAbsence: flow(function* saveAbsence(data) {
    self.setLoading('saveAbsence')
    try {
      const selectedUnavailableSlot = yield getRoot(
        self
      ).UnavailableSlotStore.get(data.id)
      selectedUnavailableSlot.update(data)
      yield selectedUnavailableSlot.save()
    } finally {
      self.removeLoading('saveAbsence')
    }
  }),
  createAbsence: flow(function* createAbsence(data) {
    self.setLoading('createAbsence')
    try {
      yield getRoot(self).UnavailableSlotStore.create(data)
    } catch (error) {
      console.error(error)
    } finally {
      self.removeLoading('createAbsence')
    }
  }),
  deleteAbsence: flow(function* deleteAbsence(id) {
    self.setLoading('deleteAbsence')
    try {
      yield getRoot(self).UnavailableSlotStore.delete(id)
    } catch (error) {
      console.error(error)
    } finally {
      self.removeLoading('deleteAbsence')
    }
  }),
}))
