import { ReportPdfContextProps } from '../../../../provider'
import { IMCScoreResult } from '../../../../scales'
import { scoreIsNotAvailable } from '../../../../utils'

export const getIMCScaleList = (context: ReportPdfContextProps) => {
  const { t, testDate, scores, person } = context
  const { score, scale, scoreName, height, weight } = scores?.imcScore || {}

  return [
    {
      label: t('initial-report:generic.resultTable.scaleDescription'),
      content: t('initial-report:scores.imc.description'),
    },
    {
      label: t('initial-report:generic.resultTable.testDate'),
      content: testDate,
    },
    {
      label: t('initial-report:scores.imc.weight'),
      content: weight ? `${weight}Kg` : '',
    },
    {
      label: t('initial-report:scores.imc.height'),
      content: height ? `${height}cm` : '',
    },
    {
      label: t('initial-report:scores.imc.result'),
      content: scoreIsNotAvailable(score)
        ? t('initial-report:resultNotAvailable.imc', {
            patientName: person?.name,
          })
        : t(`initial-report:scores.imc.${scoreName}.value`, {
            scale,
          }),
    },
  ]
}

export const getIMCPerYearList = (context: ReportPdfContextProps) => {
  const { t, scores } = context
  const { result } = scores?.imcScore || {}
  return [
    {
      text: '< 18.5',
      subText: t(
        'initial-report:sectionNutritional.results.imc.scaleValues.insufficient.value',
      ),
      selected: result === IMCScoreResult.insufficient,
    },
    {
      text: '18.5-24.9',
      subText: t(
        'initial-report:sectionNutritional.results.imc.scaleValues.healthy.value',
      ),
      selected: result === IMCScoreResult.healthy,
    },
    {
      text: '25-26.9',
      subText: t(
        'initial-report:sectionNutritional.results.imc.scaleValues.overweightOne.value',
      ),
      selected: result === IMCScoreResult.overweightOne,
    },
    {
      text: '27-29.9',
      subText: t(
        'initial-report:sectionNutritional.results.imc.scaleValues.overweightTwo.value',
      ),
      selected: result === IMCScoreResult.overweightTwo,
    },
    {
      text: '30-34.9',
      subText: t(
        'initial-report:sectionNutritional.results.imc.scaleValues.obesityOne.value',
      ),
      selected: result === IMCScoreResult.obesityOne,
    },
    {
      text: '35-39.9',
      subText: t(
        'initial-report:sectionNutritional.results.imc.scaleValues.obesityTwo.value',
      ),
      selected: result === IMCScoreResult.obesityTwo,
    },
    {
      text: '40-49.9',
      subText: t(
        'initial-report:sectionNutritional.results.imc.scaleValues.obesityThree.value',
      ),
      selected: result === IMCScoreResult.obesityThree,
    },
    {
      text: '> 50',
      subText: t(
        'initial-report:sectionNutritional.results.imc.scaleValues.obesityFour.value',
      ),
      selected: result === IMCScoreResult.obesityFour,
    },
  ]
}
