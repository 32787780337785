import { flow, types } from 'mobx-state-tree'
import { BaseRouteState } from '@shared/data-access'

export const BaseTableState = BaseRouteState.named('BaseTableState')
  .props({
    pageSize: 15,
    page: 0,
    total: 0,
    search: '',
    sort: types.maybeNull(types.array(types.frozen({}))),
    filters: types.maybeNull(types.frozen({})),
  })
  .views((self) => ({
    get pagination() {
      return {
        pageSize: self.pageSize,
        pageIndex: self.page,
      }
    },
  }))
  .actions((self) => ({
    setSearch: flow(function* setSearch(search) {
      if (self.search !== search) {
        self.search = search
        self.page = 0
        yield self.loadDependencies()
      }
    }),
    onPaginationChange: flow(function* onPaginationChange({
      pageSize,
      pageIndex,
    }) {
      if (pageSize !== self.pageSize || pageIndex !== self.page) {
        self.pageSize = pageSize
        self.page = pageIndex
        yield self.loadDependencies()
      }
    }),
    onSortingChange: flow(function* onSortingChange(sort) {
      self.sort = sort
      yield self.loadDependencies()
    }),
    onFiltersChange: flow(function* onSortingChange(filters) {
      self.filters = filters
      yield self.loadDependencies()
    }),
  }))
