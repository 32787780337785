import { createContext, useContext, useEffect, useState } from 'react'
import { useActiveSinkId } from '../useActiveSinkId'
import { useHandleRoomDisconnection } from './hooks/useHandleRoomDisconnection'
import { useHandleTrackPublicationFailed } from './hooks/useHandleTrackPublicationFailed'
import { useLocalTracks } from './hooks/useLocalTracks'
import { useRoom } from './hooks/useRoom'
import {
  useRestartAudioTrackOnDeviceChange,
  useRestartVideoTrackOnDeviceChange,
} from '@shared/ui'
import useScreenShareToggle from './hooks/useScreenShareToggle/useScreenShareToggle'
import useBackgroundSettings from './hooks/useBackgroundSettings/useBackgroundSettings'
import { BRANDING_QIDA, BRANDING_VCX } from '../../utils/branding'
import useConnectionOptions from '../../utils/useConnectOptions'
import { useSearchParams } from 'react-router-dom'
import { APPOINTMENT_TYPES } from '@shared/data-access'

/**
 * VideoContext prepare all for up the Preview Screen and VideoCall Screen
 * @param  {Object} videoOptions options for local participant
 * @param  {Node} children video component
 */

const VideoContext = createContext(null)

export function VideoContextProvider({ videoOptions, children }) {
  const options = useConnectionOptions()

  const [isRoomFinished, setIsRoomFinished] = useState(false)

  const [branding, setBranding] = useState(null)

  const {
    localTracks,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getLocalVideoTrack,
    getAudioAndVideoTracks,
    changeVideoTrack,
  } = useLocalTracks()

  const { room, isConnecting, connect } = useRoom(localTracks, options)

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room)

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    videoOptions,
    room,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    setIsRoomFinished,
    isSharingScreen,
    toggleScreenShare
  )

  useHandleTrackPublicationFailed(room)
  useRestartAudioTrackOnDeviceChange(localTracks)
  useRestartVideoTrackOnDeviceChange(localTracks)

  const [activeSinkId, setActiveSinkId] = useActiveSinkId()

  const videoTrack = localTracks.find(
    (track) => !track.name.includes('screen') && track.kind === 'video'
  )

  useBackgroundSettings(
    videoTrack,
    room,
    videoOptions.isAdmin,
    branding?.backgroundImage
  )

  let [searchParams] = useSearchParams()

  const appointmentType = Number(searchParams.get('appointment_type'))

  useEffect(() => {
    if (
      APPOINTMENT_TYPES.INITIAL_REPORT === appointmentType ||
      APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE === appointmentType
    ) {
      setBranding(BRANDING_VCX)
    } else {
      setBranding(BRANDING_QIDA)
    }
  }, [appointmentType])

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        connect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        getAudioAndVideoTracks,
        getLocalVideoTrack,
        activeSinkId,
        setActiveSinkId,
        isRoomFinished,
        isSharingScreen,
        toggleScreenShare,
        branding,
        options: videoOptions,
        appointmentType,
        changeVideoTrack,
      }}
    >
      {children}
    </VideoContext.Provider>
  )
}

export function useVideoContext() {
  return useContext(VideoContext)
}
