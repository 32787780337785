import { SRModal } from '@sr/ui'
import { FC, useState } from 'react'
import { ModalContainer } from './styles'
import { useTranslation } from 'react-i18next'
import { Button, BUTTON_SIZES, BUTTON_VARIANTS, DS_TYPOGRAPHY_BODY_MD_REGULAR, DS_TYPOGRAPHY_BODY_SM_REGULAR, DS_TYPOGRAPHY_HEADLINE_H1, DS_TYPOGRAPHY_HEADLINE_H2, DS_TYPOGRAPHY_TITLE_LG, DS_TYPOGRAPHY_TITLE_MD } from '@shared/ui'
import QDInputRadio from 'libs/shared/ui/src/lib/components/generics/QDInputRadio/qd-input-radio'

type ReassingReasonModalProps = {
  onConfirm: (reason: number) => void,
  onCancel: () => void
}

export const ReassingReasonModal: FC<ReassingReasonModalProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [selectedReason, setSelectedReason] = useState<number>(-1);

  const reasons = [
    { id: 2, reason: t('privateArea.portfolioManagement.reassingReason.reason_2') },
    { id: 3, reason: t('privateArea.portfolioManagement.reassingReason.reason_3') },
    { id: 0, reason: t('privateArea.portfolioManagement.reassingReason.reason_0') },
    { id: 1, reason: t('privateArea.portfolioManagement.reassingReason.reason_1') },
    { id: 99, reason: t('privateArea.portfolioManagement.reassingReason.reason_99') },
  ];

  return (
    <SRModal className='w-[768px]'>
      <ModalContainer className='items-start'>
        <h3 className={DS_TYPOGRAPHY_TITLE_MD}>
          {t('privateArea.portfolioManagement.modal.reassignReasonTitle')}
        </h3>

        <p className={DS_TYPOGRAPHY_BODY_MD_REGULAR}>
          {t('privateArea.portfolioManagement.modal.reassignReasonDescription')}
        </p>

        <div className="w-full grid divide-y border rounded-lg shadow-md">
          {reasons.map((reason, index) => (
            <QDInputRadio
              key={index}
              id={`reason-${index}`}
              name="reassign-reason"
              value={reason.id}
              label={reason.reason}
              checked={selectedReason === reason.id}
              onChange={(e) => setSelectedReason(Number(e.target.value))}
            />
          ))}
        </div>

        <div className="w-full py-2 flex justify-end gap-4">
          <Button
            className="self-end"
            variant={BUTTON_VARIANTS.outlined}
            size={BUTTON_SIZES.small}
            onClick={onCancel}
          >
            {t('common:cancel')}
          </Button>
          <Button
            className="self-end"
            size={BUTTON_SIZES.small}
            onClick={() => onConfirm(selectedReason)}
            disabled={selectedReason < 0}
          >
            {t('common:continue')}
          </Button>
        </div>
      </ModalContainer>
    </SRModal>
  )
}
