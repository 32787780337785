import { SrInitialReportPdf } from '@shared/ui'
import { FC } from 'react'
import { ReportData } from '../../types'
import { ReportType } from '../../enums'

type CareReportProps = {
  data: ReportData
  type: ReportType
}

export const CareReport: FC<CareReportProps> = ({ data, type }) => {
  return <SrInitialReportPdf data={data} type={type} />
}
