import { EMPLOYEE_STATE_TEXT } from '@shared/data-access'
import { LANGUAGES, getDateFormatted } from '@shared/utils'
import { t } from 'i18next'

export const stateViews = (self) => ({
  get personalData() {
    if (!self.socialReferent) return {}
    const {
      phone,
      email,
      postalCode,
      academicTitle,
      activeFrom,
      collegiateNo,
      country,
      employeeState,
      locality,
      region,
      street,
      professionalDescEs,
      professionalDescCa,
      knownLanguages = [],
    } = self.socialReferent

    const professionalDesc = { ES: professionalDescEs, CAT: professionalDescCa }

    const personalData = [
      {
        label: ' ',
        value: professionalDesc[self.activeLanguage],
        hasToGrow: true,
        isTextarea: true,
      },
      { label: t('common:phone'), value: phone },
      { label: t('common:email'), value: email },
      { label: t('common:postalCode'), value: postalCode },
      { label: t('common:country'), value: country },
      { label: t('common:region'), value: region },
      { label: t('common:locality'), value: locality },
      { label: t('common:street'), value: street },
      {
        label: t('common:employeeState.label'),
        value: t(`common:${EMPLOYEE_STATE_TEXT[employeeState]}`),
      },
      {
        label: t('common:academicTitle'),
        value: academicTitle,
      },
      { label: t('common:collegiateNo'), value: collegiateNo },
      {
        label: t('common:activeFrom'),
        value: activeFrom && getDateFormatted(activeFrom),
      },
      {
        label: t('common:languagesLabel'),
        value: knownLanguages
          ?.map((value) => t(`common:languages.${LANGUAGES[value]}`))
          ?.join(', '),
      },
    ]

    return personalData
  },
})
