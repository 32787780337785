import { Calendar, DataCheck } from '@carbon/icons-react'
import { t } from 'i18next'

export const getHeadersPhoneCall = () => [
  { icon: <Calendar size={24} />, title: t('common:date') },
  {
    icon: <DataCheck size={24} />,
    title: t('userArea.monitoringArea.headers.reason'),
  },
]
