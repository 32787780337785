import { View } from '@react-pdf/renderer'
import { FC } from 'react'
import { tw } from '../../../utils/styles'
import { SubSection, TranscriptionScriptPDF } from '../../../components'
import { useReportPdfContext } from '../../../provider'
import { getRecommendations } from '../../../utils'

type SocialResultsProps = {
  sectionLetter: string
}

export const SocialResults: FC<SocialResultsProps> = ({ sectionLetter }) => {
  const { t, person, recommendations } = useReportPdfContext()

  const TRANSLATION_BASE_KEY = 'initial-report:sectionSocial.results'

  const feedback = getRecommendations({
    recKeys: recommendations?.SOCIAL,
    defaultKey: 'rec_social_good',
  })

  return (
    <View style={tw('px-12')}>
      <SubSection
        title={t('initial-report:subSection.results.title', {
          letter: sectionLetter,
        })}
      >
        {feedback.map((key) => {
          if (!key) return null
          return (
            <TranscriptionScriptPDF
              key={key}
              script={`${TRANSLATION_BASE_KEY}.feedback.${key}`}
              scriptProps={{ patientName: person?.name }}
            />
          )
        })}
      </SubSection>
    </View>
  )
}
