import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePortfolioContext } from '../../../usePortfolioContext'
import { SRTableList, useOpTable } from '@sr/ui'
import { ButtonContainer, InputContainer, ListContainer } from './styles'
import { Button, BUTTON_SIZES, BUTTON_VARIANTS, InputSearch } from '@shared/ui'
import { MODAL_TYPE } from './enums'
import { GenericConflictModal, ReassingReasonModal, SuccessModal } from './modals'
import { observer } from 'mobx-react-lite'

type OPGenericListProps = {
  state: any
  onCancelUrl: string
  filters: any
}

export const OPGenericList: FC<OPGenericListProps> = observer(
  ({ state, onCancelUrl, filters }) => {
    const { t } = useTranslation()
    const [opSelected, setOpSelected] = useState('')
    const [reason, setReason] = useState(-1);
    const { clients, setClients } = usePortfolioContext();

    const {
      isLoading,
      socialReferentList,
      onPaginationChange,
      pagination,
      total,
      setSearch,
      onSortingChange,
      onFiltersChange,
      assignPortfolio,
      modalType,
      setModal,
      conflicts,
    } = state

    const { columns } = useOpTable()

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
    }

    const navigate = useNavigate()
    const onCancel = () => {
      navigate(onCancelUrl)
    }

    const onRowSelection = (rowSelected: string[]) => {
      setOpSelected(rowSelected[0])
    }

    const opSelectedObject = useMemo(() => {
      return socialReferentList?.find(
        ({ id }: { id: string }) => id === opSelected,
      )
    }, [opSelected])

    const onAssign = () => {
      setModal(MODAL_TYPE.reassingReason)
    }

    const onConfirmReason = (reason: number) => {
      setReason(reason)
      assignPortfolio({ list: clients, socialReferent: opSelected, reason })
    }

    const onCloseModal = () => {
      setModal(null)
      setClients([])
      onCancel()
    }

    const onRetry = () => {
      const newList = clients?.filter((id) => !conflicts.includes(id))
      setModal(null)
      if (newList?.length) {
        setClients(newList)
        assignPortfolio({ list: newList, socialReferent: opSelected, reason })
      }
    }

    useEffect(() => {
      onFiltersChange(filters)
    }, [filters])

    return (
      <ListContainer>
        <InputContainer>
          <InputSearch onChange={onChange} />
          <ButtonContainer>
            <Button
              size={BUTTON_SIZES.small}
              variant={BUTTON_VARIANTS.outlined}
              onClick={onCancel}
            >
              {t('common:back')}
            </Button>
            <Button
              size={BUTTON_SIZES.small}
              onClick={onAssign}
              disabled={!opSelected}
            >
              {t('common:confirm')}
            </Button>
          </ButtonContainer>
        </InputContainer>

        <SRTableList
          data={socialReferentList || []}
          columns={columns}
          initialPagination={pagination}
          onPaginationChange={onPaginationChange}
          total={total}
          onSortingChange={onSortingChange}
          isLoading={isLoading}
          enableRowSelection
          enableMultiRowSelection={false}
          onRowSelection={onRowSelection}
        />
        {modalType === MODAL_TYPE.reassingReason && (
          <ReassingReasonModal
            onConfirm={onConfirmReason}
            onCancel={() => setModal(null)}
          />
        )}
        {modalType === MODAL_TYPE.success && (
          <SuccessModal
            onClose={onCloseModal}
            opName={opSelectedObject?.fullName}
            count={clients?.length}
          />
        )}
        {modalType === MODAL_TYPE.conflict && (
          <GenericConflictModal
            onClose={() => setModal(null)}
            onRetry={onRetry}
            conflicts={conflicts?.length}
            canContinue={conflicts?.length !== clients?.length}
          />
        )}
      </ListContainer>
    )
  },
)
