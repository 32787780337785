import { useState } from 'react'
import { tabContainerClasses } from './styles'
import { Tab } from './components'

export const TabList = ({ tabList, onClickTab, defaultActiveTab }) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || 0)

  const onClick = ({ link, ix }) => {
    setActiveTab(ix)
    onClickTab(link)
  }

  return (
    <div className={tabContainerClasses}>
      {tabList.map(({ title, link, titleSuffix }, ix) => {
        return (
          <Tab
            title={title}
            titleSuffix={titleSuffix}
            link={link}
            ix={ix}
            activeTab={activeTab}
            onClick={onClick}
            key={`tab-${title}-${ix}`}
          />
        )
      })}
    </div>
  )
}
