export const PERSON_SERVICE_STATES = Object.freeze({
  REQUESTED: 0,
  ACCEPTED: 1,
  REFUSED: 2,
  CANCELLED: 3,
  FINISHED: 4,
  DISABLED: 99,
})

export const PERSON_SERVICE_STATE_REQUEST = Object.freeze({
  CANDIDATE_FOR_REQUEST: -1,
  ...PERSON_SERVICE_STATES,
})

export const PERSON_SERVICE_STATES_TRANSLATION = Object.freeze({
  [PERSON_SERVICE_STATES.REQUESTED]: 'policyServiceStates.requested',
  [PERSON_SERVICE_STATES.ACCEPTED]: 'policyServiceStates.accepted',
  [PERSON_SERVICE_STATES.REFUSED]: 'policyServiceStates.refused',
  [PERSON_SERVICE_STATES.CANCELLED]: 'policyServiceStates.cancelled',
  [PERSON_SERVICE_STATES.FINISHED]: 'policyServiceStates.finished',
})

export const PERSON_SERVICE_STATES_REQUEST_TRANSLATION = Object.freeze({
  [PERSON_SERVICE_STATE_REQUEST.CANDIDATE_FOR_REQUEST]:
    'policyServiceStates.candidateForRequest',
  [PERSON_SERVICE_STATE_REQUEST.REQUESTED]: 'policyServiceStates.requested',
  [PERSON_SERVICE_STATE_REQUEST.ACCEPTED]: 'policyServiceStates.accepted',
  [PERSON_SERVICE_STATE_REQUEST.REFUSED]: 'policyServiceStates.refused',
  [PERSON_SERVICE_STATE_REQUEST.CANCELLED]: 'policyServiceStates.cancelled',
  [PERSON_SERVICE_STATE_REQUEST.FINISHED]: 'policyServiceStates.finished',
})
