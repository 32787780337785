import React from 'react'

interface LabelProps {
  name: string
  className?: string
  children?: React.ReactNode
  isPhone?: boolean
}

export const SRLabel: React.FC<LabelProps> = ({
  name,
  className = '',
  children = null,
  isPhone = false,
}) => {
  return (
    <label className={className} htmlFor={name}>
      {children} {isPhone && <span className="text-red-600">*</span>}
    </label>
  )
}