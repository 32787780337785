import { useReportPdfContext } from '../../provider'
import { Image, Link, Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import User from '../../../sr-initial-report-pdf/assets/user.png'
import { LANGUAGES } from '@shared/utils'
import { SubSection } from '../subsection'
import PhoneFilled from '../../../sr-initial-report-pdf/assets/phone--filled.png'
import Chat from '../../../sr-initial-report-pdf/assets/chat.png'

export const OpBlockCare = () => {
  const { data, person, t } = useReportPdfContext()

  if (!person) return null

  const originalPhoto = person?.socialReferent?.picture_url
  const transformedPhoto = originalPhoto?.replace(/=s\d+-c/, '=s300-c')

  const srPictureUrl = transformedPhoto !== '' ? transformedPhoto : User

  const srDescription =
    person.preferred_language === LANGUAGES.es
      ? person.socialReferent.professionalDescEs
      : person.socialReferent.professionalDescCa

  const portalBaseUrl = data?.results.initialAnalysisList.portal_base_url

  return (
    <View style={tw('flex flex-col gap-6')}>
      <View style={tw('px-12')}>
        <SubSection
          classes="text-primary uppercase"
          title={t('sectionTen.audit.subtitle')}
        >
          <Text>{srDescription}</Text>
        </SubSection>
      </View>
      <View
        style={tw('bg-[#efefef] flex flex-row px-12 py-12 gap-4 items-center')}
      >
        <View style={tw('flex items-center justify-center')}>
          <Image style={tw('w-24 h-24 rounded-full')} source={srPictureUrl} />
        </View>
        <View
          style={tw('flex-1 flex flex-col gap-2 border-r border-gray-400 px-4')}
        >
          <Text style={tw('text-primary text-lg font-semibold leading-5')}>
            {person.socialReferent.fullName}
          </Text>
          <Text style={tw('text-gray-400')}>
            {t('initial-report:sectionTen.audit.professionalTitle')}
          </Text>
          <Text style={tw('mt-4')}>{person.socialReferent.academicTitle}</Text>
          <Text>
            {t('initial-report:sectionTen.audit.collegiateNo', {
              collegiateNo: person.socialReferent.collegiateNo,
            })}
          </Text>
        </View>
        <View style={tw('flex-1 flex flex-col gap-2 px-4')}>
          <View style={tw('flex flex-row gap-4')}>
            <Image style={tw('w-6 h-6')} src={PhoneFilled} />
            <View style={tw('flex flex-col gap-2')}>
              <Text style={tw('font-semibold')}>
                {person.socialReferent.phone}
              </Text>
            </View>
          </View>
          <View style={tw('flex flex-row gap-4')}>
            <Image style={tw('w-6 h-6')} src={Chat} />
            <View style={tw('flex flex-col gap-2')}>
              <Link
                style={tw('font-semibold no-underline text-black')}
                src={`${portalBaseUrl}/mensajes`}
              >
                {t(
                  'initial-report:sectionTen.nextSteps.contactUs.modes.messages.title',
                )}
              </Link>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}
