import { modalOverlayClasses, modalContainerClasses } from './styles'
import { InformationFilled } from '@carbon/icons-react'
import { useTranslation } from 'react-i18next'

// Todo use Modal componente when this task are done
// https://qidacaringwell.atlassian.net/jira/software/projects/QTV/boards/18?selectedIssue=QTV-484

export const EndText = () => {
  const { t } = useTranslation()

  return (
    <div className={modalOverlayClasses}>
      <div className={modalContainerClasses}>
        <InformationFilled size={35} />
        <div
          className="gap-3 flex flex-col justify-center items-center"
          dangerouslySetInnerHTML={{ __html: t('common:videoCall.endText') }}
        />
      </div>
    </div>
  )
}
