import {
  ClientStore,
  PersonStore,
  PersonServiceStore,
  PolicyStore,
  RootStoreProvider,
  ScheduleStore,
  ServiceStore,
  SocialReferentStore,
  VCXAuthClient,
  ServiceSlotStore,
  OfficeStore,
  ClientPreferencesStore
} from '@shared/data-access'
import {
  AnnuityStore,
  AppointmentStore,
  CampaignStore,
  CollectiveStore,
  ControlTestStore,
  GenerateRoomStore,
  GenericAnalysisAvailableTypesStore,
  GenericAnalysisStore,
  GenericAnalysisResultsStore,
  GenericAnalysisTestStore,
  InitialAnalysisResultsStore,
  InitialAnalysisStore,
  PhoneCallStore,
  PolicyRemainderStore,
  ProductStore,
  TestResultStore,
  UnavailableSlotStore,
  AvailableSRStore,
  SocialReferentChangesStore,
  ConversationMetaDataStore,
  ConversationParticipantsStore,
  ConversationRoomStore,
} from '@sr/data-access'
import { StoreContext } from '@shared/utils'
import { CalendarAppointments } from '@sr/routes/agenda'
import { SubscriptionControlRouteState } from '@sr/routes/user-area/subscription-control/control/state'
import { useEffect, useState } from 'react'
import { errorCallback } from '@shared/ui'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../routes/enums'
import { ScriptPreControlRouteState } from '@sr/routes/user-area/subscription-control/pre-control/state'
import { LayoutRouteState } from '@sr/routes/layout/state'
import { InitialReportRouteState } from '@sr/routes/initial-report'
import { TestCancelModalRouteState } from '@sr/routes/user-area/subscription-control/control/components/test-cancel-modal/state'
import { SrListRouteState } from '@sr/routes/coordinator/sr-list/state'
import { AgendaSrSelectRouteState } from '@sr/routes/agenda/components/agenda-aside/components/agenda-sr-select/state'
import { SRPrivateAreaRouteState } from '@sr/routes/coordinator/private-area/state'
import { ScheduleRouteState } from '@sr/routes/user-area/schedule/state'
import { ConfirmationModalRouteState } from '@sr/routes/user-area/schedule/components/confirmation-modal/state'
import { DashboardRouteState } from '@sr/routes/dashboard/state'
import { DashboardSRRouteState } from '@sr/routes/dashboard/sr-dashboard/state'
import { DashboardCoordinatorRouteState } from '@sr/routes/dashboard/coordinator-dashboard/state'
import { PreInitialReportRouteState } from '@sr/routes/initial-report/pre-initial-report/state'
import { MessagesState } from '@sr/routes/monitoring-area/messages/state'
import { MonitoringAreaRouteState } from '@sr/routes/monitoring-area/state'
import { MonitoringAreaCommunicationsRouteState } from '@sr/routes/monitoring-area/communications/state'
import { MonitoringAreaRegisterRouteState } from '@sr/routes/monitoring-area/register/state'
import { MonitoringAreaAgendaRouteState } from '@sr/routes/monitoring-area/agenda/state'
import { ServiceRouteState } from '@sr/routes/monitoring-area/services/state'
import { ServiceDetailRouteState } from '@sr/routes/monitoring-area/services/detail/state'
import { AbsencesAppointmentState } from '@sr/routes/common/modal-agenda/components/absences/state'
import { UserAreaCampaignRouteState } from '@sr/routes/campaign/user-area/state'
import { CareAppointmentState } from '@sr/routes/common/modal-agenda/components/care/state'
import { GenericAppointmentState } from '@sr/routes/common/modal-agenda/components/generic-analysis/state'
import { UserProfileCampaignRouteState } from '@sr/routes/campaign/user-area/profile'
import { BasicHealthTestRouteState } from '@sr/routes/common/analysis/generic-analysis/components/states/health-state'
import { LoboTestRouteState } from '@sr/routes/common/analysis/generic-analysis/components/states/lobo-state'
import { ClockTestRouteState } from '@sr/routes/common/analysis/generic-analysis/components/states/clock-state'
import { FamilyTestRouteState } from '@sr/routes/common/analysis/generic-analysis/components/states/family-state'
import { ClinicalTestRouteState } from '@sr/routes/common/analysis/generic-analysis/components/states/clinical-state'
import { FunctionalTestRouteState } from '@sr/routes/common/analysis/generic-analysis/components/states/functional-state'
import { CognitiveTestRouteState } from '@sr/routes/common/analysis/generic-analysis/components/states/cognitive-state'
import { NutritionalTestRouteState } from '@sr/routes/common/analysis/generic-analysis/components/states/nutritional-state'
import { SocialTestRouteState } from '@sr/routes/common/analysis/generic-analysis/components/states/social-state'
import { SummaryTestRouteState } from '@sr/routes/common/analysis/generic-analysis/components/states/summary-state'
import { PDFPreviewRouteState } from '@sr/routes/common/analysis/generic-analysis/components/pdf-preview/state'
import { SummaryExecutiveTestRouteState } from '@sr/routes/common/analysis/generic-analysis/components/states/summary-executive-state'
import { PfeifferTestRouteState } from '@sr/routes/common/analysis/generic-analysis/components/states/pfeiffer-state'
import { ProfileCareActionsRouteState } from '@sr/routes/common/generic-profile/components/actions/care-actions/state'
import { UserAreaAgendaRouteState } from '@sr/routes/campaign/user-area/agenda/state'
import { ProfileCampaignsActionsRouteState } from '@sr/routes/common/generic-profile/components/actions/campaign-actions/state'
import { UserAreaCareRouteState } from '@sr/routes/care/user-area/state'
import { GenericAnalysisListState } from '@sr/routes/common'
import { TestListCampaignState } from '@sr/routes/campaign/user-area'
import {
  TestListCareState,
  UserProfileRouteState,
} from '@sr/routes/care/user-area'
import { AppointmentManagerRouteState } from '@sr/routes/care/user-area/appointment-manager/new-event/state'
import { CampaignAppointmentManagerRouteState } from '@sr/routes/campaign/user-area/appointment-manager/new-event/state'
import { AnalysisRouteState } from '@sr/routes/common/analysis/analysis/state'
import { AnalysisCampaignRouteState } from '@sr/routes/campaign/user-area/analysis/state'
import { AnalysisCareRouteState } from '@sr/routes/care/user-area/analysis/state'
import { TestScriptRouteState } from '@sr/routes/common/test-script/state'
import { AppointmentInfoState } from '@sr/routes/common/modal-agenda/common/components/appointment-info/state'
import { MyProfileState } from '@sr/routes/social-referent'
import { UserNewListState } from '@sr/routes/care/user-list/components/new-list'
import { CareUserListFilterState } from '@sr/routes/care/user-list/components/filters/state'
import { CampaignUserListFilterState } from '@sr/routes/campaign/user-list/components/filters/state'
import { UserNewListCampaignState } from '@sr/routes/campaign/user-list/components/new-list/state'
import { PortfolioManagementClientListRouteState } from '@sr/routes/coordinator/private-area'
import { ProfileDropdownRouteState } from '@sr/routes/layout/components/header-actions/components/profile-dropdown/state'
import { HeaderActionsRouteState } from '@sr/routes/layout/components'
import { NotificationsChatRouteState } from '@sr/routes/notifications/chat/state'
import { PortfolioManagementOPListRouteState } from '@sr/routes/coordinator/private-area/portfolio-management/op-list/list-care'
import { PortfolioManagementCampaignListRouteState } from '@sr/routes/coordinator/private-area/portfolio-management/campaign-list/list/state'
import { PortfolioManagementOPListCampaignRouteState } from '@sr/routes/coordinator/private-area/portfolio-management/op-list/list-campaign/state'

const STORES = [
  PolicyStore,
  AppointmentStore,
  ControlTestStore,
  SocialReferentStore,
  ClientStore,
  TestResultStore,
  InitialAnalysisStore,
  PhoneCallStore,
  ScheduleStore,
  UnavailableSlotStore,
  PolicyRemainderStore,
  InitialAnalysisResultsStore,
  GenerateRoomStore,
  PersonServiceStore,
  ServiceStore,
  AnnuityStore,
  PersonStore,
  CampaignStore,
  CollectiveStore,
  ProductStore,
  GenericAnalysisStore,
  GenericAnalysisAvailableTypesStore,
  GenericAnalysisTestStore,
  GenericAnalysisResultsStore,
  ServiceSlotStore,
  AvailableSRStore,
  OfficeStore,
  SocialReferentChangesStore,
  ConversationMetaDataStore,
  ConversationParticipantsStore,
  ConversationRoomStore,
  ClientPreferencesStore
]
const STATES = [
  CalendarAppointments,
  SubscriptionControlRouteState,
  UserProfileRouteState,
  ScriptPreControlRouteState,
  MyProfileState,
  LayoutRouteState,
  DashboardRouteState,
  DashboardSRRouteState,
  DashboardCoordinatorRouteState,
  ProfileDropdownRouteState,
  InitialReportRouteState,
  TestCancelModalRouteState,
  SrListRouteState,
  AgendaSrSelectRouteState,
  SRPrivateAreaRouteState,
  ScheduleRouteState,
  ConfirmationModalRouteState,
  PreInitialReportRouteState,
  MessagesState,
  MonitoringAreaRouteState,
  MonitoringAreaCommunicationsRouteState,
  MonitoringAreaRegisterRouteState,
  MonitoringAreaAgendaRouteState,
  ServiceRouteState,
  ServiceDetailRouteState,
  AbsencesAppointmentState,
  CareAppointmentState,
  UserAreaCampaignRouteState,
  GenericAppointmentState,
  UserProfileCampaignRouteState,
  AnalysisRouteState,
  GenericAnalysisListState,
  BasicHealthTestRouteState,
  BasicHealthTestRouteState,
  LoboTestRouteState,
  ClockTestRouteState,
  FamilyTestRouteState,
  ClinicalTestRouteState,
  FunctionalTestRouteState,
  CognitiveTestRouteState,
  NutritionalTestRouteState,
  SocialTestRouteState,
  SummaryTestRouteState,
  SummaryExecutiveTestRouteState,
  PfeifferTestRouteState,
  PDFPreviewRouteState,
  ProfileCareActionsRouteState,
  UserAreaAgendaRouteState,
  ProfileCampaignsActionsRouteState,
  UserAreaCareRouteState,
  TestListCampaignState,
  TestListCareState,
  AnalysisCampaignRouteState,
  AnalysisCareRouteState,
  TestScriptRouteState,
  AppointmentInfoState,
  UserNewListState,
  UserNewListCampaignState,
  CareUserListFilterState,
  CampaignUserListFilterState,
  AppointmentManagerRouteState,
  CampaignAppointmentManagerRouteState,
  PortfolioManagementClientListRouteState,
  PortfolioManagementOPListRouteState,
  HeaderActionsRouteState,
  NotificationsChatRouteState,
  PortfolioManagementCampaignListRouteState,
  PortfolioManagementOPListCampaignRouteState,
]

export const StoreProvider = ({ children, apiURL }) => {
  const navigate = useNavigate()
  const onError = (error, self) => {
    return errorCallback({
      error,
      onBadRequest: ({ error }) => {
        throw error
      },
      onNotFoundRequest: ({ error }) => {
        throw error
      },
      onForbiddenRequest: () => navigate(ROUTE_PATH.login),
      onServerErrorRequest: () => navigate(ROUTE_PATH.error),
      self,
    })
  }
  const client = VCXAuthClient.create({
    apiURL,
  })

  client.setOnErrorCallback(onError)

  const [user, setUser] = useState(null)

  useEffect(() => {
    const getToken = async () => {
      await client.getCsrfToken()
      setUser(await client.getUserInfo())
    }

    getToken()
  }, [])

  return (
    <RootStoreProvider
      context={StoreContext}
      stores={STORES}
      states={STATES}
      client={client}
      user={user}
    >
      {children}
    </RootStoreProvider>
  )
}
