import { getRoot, types } from 'mobx-state-tree'
import {
  RootModel,
  SERVICE_TRANSLATION,
  SERVICE_TYPES,
} from '@shared/data-access'

export const MODEL_NAME = 'Service'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    serviceType: types.maybeNull(
      types.refinement(types.integer, (value) =>
        Object.values(SERVICE_TYPES).some((type) => type === value)
      )
    ),
    coolingPeriod: types.maybeNull(types.integer),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
  .views((self) => ({
    get serviceName() {
      return SERVICE_TRANSLATION[self.serviceType]
    },
  }))

export default Model
