import { flow, types } from 'mobx-state-tree'
import { BaseRouteState, Policy } from '@shared/data-access'
import { actions } from '@sr/routes/common'

export const ConfirmationModalRouteState = BaseRouteState.named(
  'ConfirmationModalRouteState'
)
  .props({
    policyId: types.maybeNull(types.string),
    policy: types.maybeNull(types.reference(Policy)),
  })
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('ConfirmationModalRouteState')
      try {
        yield self.loadPolicy()
      } finally {
        self.removeLoading('ConfirmationModalRouteState')
      }
    }),
    ...actions(self),
  }))
