import { useRouteState } from '@shared/utils'
import { NewTest, useLayoutContext } from '@sr/routes/common'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { TestListCareState } from './state'
import { SRUserName } from '@sr/ui'
import { TestListCareContainer } from './styles'

export const TestListCare = observer(() => {
  const { id: policyId } = useParams()

  const { setIsLoading } = useLayoutContext()

  const { isLoading, client } = useRouteState(TestListCareState, {
    policyId,
  })

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  if (!client) return

  return (
    <TestListCareContainer>
      <SRUserName fullName={client.person.fullName} />

      <NewTest
        personId={client.person.id}
        userAreaUrl={`user-area/${policyId}`}
      />
    </TestListCareContainer>
  )
})
