import tw from 'tailwind-styled-components'

export const DashboardCardsContainer = tw.div`
    w-3/12
    flex
    flex-col
    xl:flex-row
    xl:w-5/12
    gap-8
    flex-wrap
    h-fit
`

export const srCardContainerClasses = `
    h-44 
    w-48
`
export const DashboardContainer = tw.div`
    flex
    justify-center
    flex-wrap
    gap-8
    h-full-height-without-title
    py-4
`

export const PrimaryBackground = tw.div`
    bg-primary
    h-3/6
    w-full
    absolute
    top-16
    z-[-1]
    rounded-b-xl
`

export const DashboardWelcome = tw.div`
    w-full
    text-white
    text-xl
    font-semibold
    leading-6
`
