import {
  AgendaCampaign,
  CampaignAnalysis,
  TestListCampaign,
  UserArea,
} from '@sr/routes/campaign/user-area'
import { ROUTE_PATH_CAMPAIGN } from './enums'
import { UserListCampaign } from '@sr/routes/campaign/user-list'
import { CampaignUserProfile } from '@sr/routes/campaign/user-area/profile'
import { Services } from '@sr/routes/campaign/user-area/services'
import { TestScript } from '@sr/routes/common'
import { CampaignAppointmentManager } from '@sr/routes/campaign/user-area/appointment-manager'

export const campaignRoutes = [
  {
    path: ROUTE_PATH_CAMPAIGN.userArea(':id'),
    children: [
      {
        index: true,
        element: <UserArea />,
      },
      {
        path: ROUTE_PATH_CAMPAIGN.userProfile,
        element: <CampaignUserProfile />,
      },
      {
        path: ROUTE_PATH_CAMPAIGN.userNewTest,
        element: <TestListCampaign />,
      },
      {
        path: ROUTE_PATH_CAMPAIGN.userServices,
        element: <Services />,
      },
      {
        path: ROUTE_PATH_CAMPAIGN.userScriptTest(':testId'),
        element: <TestScript userAreaUrl="campaign/user-area" />,
      },
      {
        path: ROUTE_PATH_CAMPAIGN.userAnalysis(':testId'),
        element: <CampaignAnalysis />,
      },
      {
        path: ROUTE_PATH_CAMPAIGN.userAgenda(':serviceId'),
        element: <AgendaCampaign />,
      },
      {
        path: ROUTE_PATH_CAMPAIGN.appointmentManager,
        element: <CampaignAppointmentManager />,
      },
    ],
  },
  {
    path: ROUTE_PATH_CAMPAIGN.userList,
    element: <UserListCampaign />,
  },
]
