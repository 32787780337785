import { useRouteState } from '@shared/utils'
import { Analysis } from '@sr/routes/common'
import { SRAnnuityUserName } from '@sr/ui'
import { useParams } from 'react-router-dom'
import { AnalysisCampaignRouteState } from './state'
import { observer } from 'mobx-react-lite'
import { ReportType } from '@shared/ui'
import { SRUserNameContainer } from './styles'

export const CampaignAnalysis = observer(() => {
  const { id: annuityId } = useParams()

  const { annuity } = useRouteState(AnalysisCampaignRouteState, { annuityId })

  if (!annuity) return

  return (
    <>
      <SRUserNameContainer>
        <SRAnnuityUserName annuity={annuity} />
      </SRUserNameContainer>

      <Analysis personId={annuity.person.id} reportType={ReportType.campaign} />
    </>
  )
})
