import { getHour, getMinute } from '@shared/utils'
import { t } from 'i18next'

export const SCHEDULE_HOURS = [
  { startHour: 9, startMinute: 0, endHour: 10, endMinute: 30 },
  { startHour: 10, startMinute: 30, endHour: 12, endMinute: 0 },
  { startHour: 12, startMinute: 0, endHour: 13, endMinute: 30 },
  { startHour: 13, startMinute: 30, endHour: 15, endMinute: 0 },
  { startHour: 15, startMinute: 0, endHour: 16, endMinute: 30 },
  { startHour: 16, startMinute: 30, endHour: 18, endMinute: 0 },
  { startHour: 18, startMinute: 0, endHour: 19, endMinute: 30 },
]

export const SCHEDULE_FACE_TO_FACE = [
  { startHour: 9, startMinute: 0, endHour: 13, endMinute: 30 },
  { startHour: 13, startMinute: 30, endHour: 18, endMinute: 0 },
]

export const getScheduleHours = () => {
  return SCHEDULE_HOURS.map((hours, ix) => {
    return { value: ix, name: t(`scheduleSlotLabel.${hours.startHour}`) }
  })
}

export const getSlotInScheduleHours = (startDate) => {
  const hour = getHour(startDate)
  const minute = getMinute(startDate)

  const indx = SCHEDULE_HOURS.findIndex((el) => {
    return el.startHour === hour && el.startMinute === minute
  })

  return indx !== -1 ? indx : 0
}
