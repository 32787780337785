import { View } from '@react-pdf/renderer'
import { FC } from 'react'
import { AreaHexColor, tw } from '../../utils'
import { TranscriptionScriptPDF } from '../transcription-script-pdf'
import { RightArrowSvg } from '../../assets/svg'

type AreaTableProps = {
  list: {
    header: string[]
    rows: (string | string[])[][]
  }
  areaColor: string
}
export const AreaTable: FC<AreaTableProps> = ({ list, areaColor }) => {
  if (!list) return

  const { header, rows } = list

  return (
    <View style={tw('mb-6')}>
      <View fixed style={tw('flex flex-row justify-center gap-2 mb-2')}>
        {header?.map((translation, index) => {
          return (
            <View
              key={`table-list-view-header-${translation}-${index}`}
              style={{
                width: `${(1 / header.length) * 100}%`,
                ...tw(`pt-2 pb-4 px-4 text-center bg-${areaColor} text-white`),
              }}
            >
              <TranscriptionScriptPDF
                style={tw('uppercase font-bold text-sm')}
                script={translation}
              />
            </View>
          )
        })}
      </View>
      {rows?.map((row, index) => {
        return (
          <View
            style={tw('flex flex-row justify-center gap-2 mb-2')}
            key={`table-list-view-content-parent-${index}`}
            wrap={false}
          >
            {row.map((translation, ix) => {
              return (
                <View
                  key={`table-list-view-content-${translation}-${index}`}
                  style={{
                    width: `${(1 / row.length) * 100}%`,
                    ...tw(`bg-${areaColor}Light pt-2 pb-6`),
                  }}
                >
                  {Array.isArray(translation) ? (
                    translation.map((item) => {
                      return (
                        <View
                          style={tw(
                            `flex flex-row gap-2 pl-4 mb-4 ${
                              ix === 0 ? 'justify-center pr-4' : 'pr-8'
                            }`,
                          )}
                          key={`table-list-view-content-text-${translation}-${index}`}
                        >
                          {ix !== 0 && (
                            <RightArrowSvg
                              style={tw('mt-[2px]')}
                              width="10px"
                              height="10px"
                              color={AreaHexColor[areaColor]}
                            />
                          )}
                          <TranscriptionScriptPDF
                            style={tw('text-sm')}
                            script={item}
                          />
                        </View>
                      )
                    })
                  ) : (
                    <View
                      style={tw(
                        `flex flex-row gap-2 pl-4 mb-4 ${
                          ix === 0 ? 'justify-center pr-4' : 'pr-8'
                        }`,
                      )}
                    >
                      {ix !== 0 && (
                        <RightArrowSvg
                          style={tw('mt-[2px]')}
                          width="10px"
                          height="10px"
                          color={AreaHexColor[areaColor]}
                        />
                      )}
                      <TranscriptionScriptPDF
                        style={tw('text-sm')}
                        script={translation}
                      />
                    </View>
                  )}
                </View>
              )
            })}
          </View>
        )
      })}
    </View>
  )
}
