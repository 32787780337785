import { Wizard } from '@shared/ui'
import { wizardContainerClasses, wizardButtonContainerClasses } from './styles'

export const SRPreSubWizard = ({
  children,
  hidePrev,
  onComplete,
  onExit,
  completeText,
}) => {
  return (
    <Wizard
      wizardButtonContainerClasses={wizardButtonContainerClasses}
      wizardContainerClasses={wizardContainerClasses}
      hidePrev={hidePrev}
      onExit={onExit}
      onComplete={onComplete}
      completeText={completeText}
    >
      {children}
    </Wizard>
  )
}
