import { Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { FC } from 'react'
import { TranscriptionScriptPDF } from '../transcription-script-pdf'

type Item = {
  label: string
  content: string
}

type TableHeaderBlackProps = {
  title: string
  list: Item[]
}

export const TableHeaderBlack: FC<TableHeaderBlackProps> = ({
  title,
  list,
}) => {
  return (
    <View style={tw('flex flex-col gap-6')} wrap={false}>
      <View
        style={tw(
          'p-2 h-10 flex justify-center items-center bg-black text-white text-center font-semibold',
        )}
      >
        <Text>{title}</Text>
      </View>
      <View>
        {list.map(({ label, content }, index) => (
          <View key={`table-header-${label}`}>
            {index === 0 && (
              <View style={tw('w-full h-px bg-lightGrey')}></View>
            )}
            <View style={tw('w-full flex flex-row justify-between text-black')}>
              <View style={tw('flex-1 p-2 font-semibold h-full')}>
                <Text>{label}</Text>
              </View>
              <View
                style={{
                  ...tw('flex-1 px-2 pt-2 bg-gray-200 h-full'),
                  paddingBottom: 40,
                }}
              >
                <TranscriptionScriptPDF script={content} />
              </View>
            </View>
            <View style={tw('w-full h-px bg-lightGrey')}></View>
          </View>
        ))}
      </View>
    </View>
  )
}
