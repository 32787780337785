import { Images } from '@shared/assets'
import {
  CalendarAdd,
  RequestQuote,
  Home,
  Phone,
  NonCertified,
} from '@carbon/icons-react'

export const APPOINTMENT_TYPES = Object.freeze({
  INITIAL_REPORT: 0,
  SERVICE_APPOINTMENT: 1,
  MONITORING_REPORT: 2,
  CONSULTATION: 3,
  INITIAL_ANALYSIS: 4,
  WELCOME_CALL: 5,
  FOLLOW_UP_ANALYSIS: 7,
  FOLLOW_UP_ANALYSIS_CALL: 777,
  INITIAL_REPORT_FACE_TO_FACE: 555,
  ABSENCES: 666,
  GENERIC_ANALYSIS: 6,
})

export const LEGEND_TYPES = Object.freeze({
  initialReportOnline: APPOINTMENT_TYPES.INITIAL_REPORT,
  initialReportFaceToFace: APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE,
  welcomeCall: APPOINTMENT_TYPES.WELCOME_CALL,
  initialAnalysisCall: 441,
  initialAnalysisOnline: 442,
  initialAnalysisFaceToFace: 443,
  absences: APPOINTMENT_TYPES.ABSENCES,
  consultation: APPOINTMENT_TYPES.CONSULTATION,
  followUpCall: APPOINTMENT_TYPES.MONITORING_REPORT,
  yearlyFollowUpCall: APPOINTMENT_TYPES.FOLLOW_UP_ANALYSIS_CALL,
  yearlyFollowUpOnline: APPOINTMENT_TYPES.FOLLOW_UP_ANALYSIS,
  service: APPOINTMENT_TYPES.SERVICE_APPOINTMENT,
  genericAnalysis: APPOINTMENT_TYPES.GENERIC_ANALYSIS,
})

export const APPOINTMENT_TYPES_TRANSLATION = Object.freeze({
  [APPOINTMENT_TYPES.INITIAL_REPORT]: 'appointmentType.initialReport',
  [APPOINTMENT_TYPES.MONITORING_REPORT]: 'appointmentType.monitoringReport',
  [APPOINTMENT_TYPES.CONSULTATION]: 'appointmentType.consultation',
  [APPOINTMENT_TYPES.INITIAL_ANALYSIS]: 'appointmentType.initialAnalysis',
  [APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE]:
    'appointmentType.initialReportFaceToFace',
  [APPOINTMENT_TYPES.WELCOME_CALL]: 'appointmentType.welcomeCall',
  [APPOINTMENT_TYPES.SERVICE_APPOINTMENT]: 'appointmentType.serviceAppointment',
  [APPOINTMENT_TYPES.ABSENCES]: 'appointmentType.absences',
  [APPOINTMENT_TYPES.GENERIC_ANALYSIS]: 'appointmentType.genericAnalysis',
  [APPOINTMENT_TYPES.FOLLOW_UP_ANALYSIS_CALL]:
    'appointmentType.yearlyFollowUpCall',
  [APPOINTMENT_TYPES.FOLLOW_UP_ANALYSIS]:
    'appointmentType.yearlyFollowUpOnline',
})

export const APPOINTMENT_TYPES_COLORS = Object.freeze({
  [APPOINTMENT_TYPES.INITIAL_REPORT]: 'appointment-initial-report',
  [APPOINTMENT_TYPES.MONITORING_REPORT]: 'appointment-monitoring-report',
  [APPOINTMENT_TYPES.CONSULTATION]: 'appointment-consultation',
  [APPOINTMENT_TYPES.INITIAL_ANALYSIS]: 'appointment-initial-analysis',
  [APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE]:
    'appointment-initial-report-face-to-face',
  [APPOINTMENT_TYPES.WELCOME_CALL]: 'appointment-welcome-call',
  [APPOINTMENT_TYPES.SERVICE_APPOINTMENT]: 'appointment-service',
  [APPOINTMENT_TYPES.ABSENCES]: 'appointment-absences',
  [APPOINTMENT_TYPES.GENERIC_ANALYSIS]: 'appointment-generic-analysis',
  [APPOINTMENT_TYPES.FOLLOW_UP_ANALYSIS]: 'appointment-followUp-analysis',
  [APPOINTMENT_TYPES.FOLLOW_UP_ANALYSIS_CALL]: 'appointment-followUp-call',
})

export const LEGEND_COLORS = Object.freeze({
  [LEGEND_TYPES.initialReportOnline]:
    APPOINTMENT_TYPES_COLORS[LEGEND_TYPES.initialReportOnline],
  [LEGEND_TYPES.initialReportFaceToFace]:
    APPOINTMENT_TYPES_COLORS[LEGEND_TYPES.initialReportFaceToFace],
  [LEGEND_TYPES.welcomeCall]:
    APPOINTMENT_TYPES_COLORS[LEGEND_TYPES.welcomeCall],
  [LEGEND_TYPES.initialAnalysisCall]: 'appointment-initial-analysis-call',
  [LEGEND_TYPES.initialAnalysisOnline]: 'appointment-initial-analysis-online',
  [LEGEND_TYPES.initialAnalysisFaceToFace]:
    'appointment-initial-analysis-face-to-face',
  [LEGEND_TYPES.absences]: APPOINTMENT_TYPES_COLORS[LEGEND_TYPES.absences],
  [LEGEND_TYPES.consultation]:
    APPOINTMENT_TYPES_COLORS[LEGEND_TYPES.consultation],
  [LEGEND_TYPES.followUpCall]:
    APPOINTMENT_TYPES_COLORS[LEGEND_TYPES.followUpCall],
  [LEGEND_TYPES.service]: APPOINTMENT_TYPES_COLORS[LEGEND_TYPES.service],
  [LEGEND_TYPES.genericAnalysis]:
    APPOINTMENT_TYPES_COLORS[LEGEND_TYPES.genericAnalysis],
  [LEGEND_TYPES.yearlyFollowUpCall]:
    APPOINTMENT_TYPES_COLORS[LEGEND_TYPES.yearlyFollowUpCall],
  [LEGEND_TYPES.yearlyFollowUpOnline]:
    APPOINTMENT_TYPES_COLORS[LEGEND_TYPES.yearlyFollowUpOnline],
})

export const LEGEND_TEXT_COLOR = Object.freeze({
  [LEGEND_TYPES.genericAnalysis]: 'text-gainsboro',
  [LEGEND_TYPES.yearlyFollowUpCall]: 'text-gainsboro',
  [LEGEND_TYPES.yearlyFollowUpOnline]: 'text-gainsboro',
})

export const APPOINTMENT_MODALITY = Object.freeze({
  faceToFace: 0,
  online: 1,
  phoneCall: 2,
})

export const APPOINTMENT_MODALITY_TRANSLATION = Object.freeze({
  [APPOINTMENT_MODALITY.faceToFace]: 'appointmentModality.faceToFace',
  [APPOINTMENT_MODALITY.online]: 'appointmentModality.online',
  [APPOINTMENT_MODALITY.phoneCall]: 'appointmentModality.phoneCall',
})
const InitialReportIcon = ({ width, height }) => (
  <Images.InitialReport width={width} height={height} />
)

export const APPOINTMENT_TYPE_ICON = Object.freeze({
  [APPOINTMENT_TYPES.INITIAL_REPORT]: <CalendarAdd size={20} />,
  [APPOINTMENT_TYPES.SERVICE_APPOINTMENT]: <CalendarAdd size={20} />,
  [APPOINTMENT_TYPES.MONITORING_REPORT]: <Phone size={20} />,
  [APPOINTMENT_TYPES.CONSULTATION]: <RequestQuote size={20} />,
  [APPOINTMENT_TYPES.INITIAL_ANALYSIS]: Images?.InitialReport && (
    <InitialReportIcon width={20} height={20} />
  ),
  [APPOINTMENT_TYPES.INITIAL_REPORT_FACE_TO_FACE]: <Home size={20} />,
  [APPOINTMENT_TYPES.ABSENCES]: <NonCertified size={20} />,
})

export const APPOINTMENT_MODIFICATION_ORIGIN = Object.freeze({
  fromManager: 0,
  fromClient: 1,
  fromQida: 2,
})

export const APPOINTMENT_ORIGIN_TRANSLATION = Object.freeze({
  [APPOINTMENT_MODIFICATION_ORIGIN.fromManager]:
    'appointmentChangeOrigin.fromManager',
  [APPOINTMENT_MODIFICATION_ORIGIN.fromClient]:
    'appointmentChangeOrigin.fromClient',
  [APPOINTMENT_MODIFICATION_ORIGIN.fromQida]:
    'appointmentChangeOrigin.fromQida',
})

export const APPOINTMENT_MODIFICATION_REASON = Object.freeze({
  clientTime: 0,
  clientMediaError: 1,
  clientBadConnection: 2,
  clientNotCompatibleDevice: 3,
  clientForgotAppointment: 4,
  unavailableProfessional: 5,
  technicalError: 6,
  other: 99,
})

export const APPOINTMENT_REASON_TRANSLATION = Object.freeze({
  [APPOINTMENT_MODIFICATION_REASON.clientTime]:
    'appointmentChangeReason.clientTime',
  [APPOINTMENT_MODIFICATION_REASON.clientMediaError]:
    'appointmentChangeReason.clientMediaError',
  [APPOINTMENT_MODIFICATION_REASON.clientBadConnection]:
    'appointmentChangeReason.clientBadConnection',
  [APPOINTMENT_MODIFICATION_REASON.clientNotCompatibleDevice]:
    'appointmentChangeReason.clientNotCompatibleDevice',
  [APPOINTMENT_MODIFICATION_REASON.clientForgotAppointment]:
    'appointmentChangeReason.clientForgotAppointment',
  [APPOINTMENT_MODIFICATION_REASON.unavailableProfessional]:
    'appointmentChangeReason.unavailableProfessional',
  [APPOINTMENT_MODIFICATION_REASON.technicalError]:
    'appointmentChangeReason.technicalError',
  [APPOINTMENT_MODIFICATION_REASON.other]: 'appointmentChangeReason.other',
})
