import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Filters, NewList } from './components'
import { CampaignUserListContainer } from './styles'
import { useLayoutContext } from '@sr/routes/common'
import { useTranslation } from 'react-i18next'

export const UserListCampaign: FC = observer(() => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState({})

  const { setBackButtonText } = useLayoutContext()

  useEffect(() => {
    setBackButtonText(t('dashboard.userListCampaign.title'))
  }, [])

  return (
    <CampaignUserListContainer>
      <Filters onChangeFilters={setFilters} />
      <NewList filters={filters} />
    </CampaignUserListContainer>
  )
})
