import { Text, View } from '@react-pdf/renderer'
import { areaColors, tw } from '../../utils'
import { Bulb } from '../../../report-pdf/assets/global/bulb'

export const TitleIcon = ({ areaColor, title, icon }) => {
  return (
    <View
      break
      style={tw(
        `flex flex-row justify-between text-white items-center text-lg font-semibold bg-[${areaColors[areaColor]}] px-4 py-2`
      )}
    >
      <Text style={tw('leading-5')}>{title}</Text>
      {icon ? icon : <Bulb height="20" width="20" />}
    </View>
  )
}
