import { clsx } from 'clsx'
import { BUTTON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS } from './enum'
import {
  buttonRoundedSizeMedium,
  buttonRoundedSizeSmall,
  buttonSizeText,
  buttonSizeMedium,
  buttonSizeSmall,
  variantContainedErrorClasses,
  variantContainedPrimaryClasses,
  variantContainedTransparentClasses,
  variantContainedSecondaryClasses,
  variantOutlinedErrorClasses,
  variantOutlinedPrimaryClasses,
  variantOutlinedSecondaryClasses,
  variantTextErrorClasses,
  variantTextPrimaryClasses,
  variantTextSecondaryClasses,
  buttonRoundedSizeText,
  buttonSizeXs,
} from './styles'

const buttonContainedPrimaryClasses = {
  [BUTTON_COLORS.primary]: variantContainedPrimaryClasses,
  [BUTTON_COLORS.secondary]: variantContainedSecondaryClasses,
  [BUTTON_COLORS.error]: variantContainedErrorClasses,
  [BUTTON_COLORS.transparent]: variantContainedTransparentClasses,
}

const buttonOutlinedClasses = {
  [BUTTON_COLORS.primary]: variantOutlinedPrimaryClasses,
  [BUTTON_COLORS.secondary]: variantOutlinedSecondaryClasses,
  [BUTTON_COLORS.error]: variantOutlinedErrorClasses,
  [BUTTON_COLORS.transparent]: '',
}

const buttonTextClasses = {
  [BUTTON_COLORS.primary]: variantTextPrimaryClasses,
  [BUTTON_COLORS.secondary]: variantTextSecondaryClasses,
  [BUTTON_COLORS.error]: variantTextErrorClasses,
  [BUTTON_COLORS.transparent]: '',
}

const variantClasses = {
  [BUTTON_VARIANTS.contained]: buttonContainedPrimaryClasses,
  [BUTTON_VARIANTS.outlined]: buttonOutlinedClasses,
  [BUTTON_VARIANTS.text]: buttonTextClasses,
  [BUTTON_COLORS.transparent]: '',
}

const buttonSizes = {
  text: buttonSizeText,
  small: buttonSizeSmall,
  medium: buttonSizeMedium,
  xs: buttonSizeXs,
}

const buttonRoundedSizes = {
  text: buttonRoundedSizeText,
  small: buttonRoundedSizeSmall,
  medium: buttonRoundedSizeMedium,
  xs: buttonRoundedSizeSmall,
}

export const buttonClasses = ({
  className,
  rounded,
  variant = BUTTON_VARIANTS.contained,
  color = BUTTON_COLORS.primary,
  size = BUTTON_SIZES.medium,
}: {
  className?: string
  rounded?: boolean
  size?: BUTTON_SIZES
  variant: BUTTON_VARIANTS
  color: BUTTON_COLORS
}) => {
  const roundedClass = rounded ? 'rounded-full' : 'rounded'
  const variantButton = variantClasses[variant]
  const buttonClasses = variantButton[color]
  const sizeClasses = rounded ? buttonRoundedSizes[size] : buttonSizes[size]

  if (!variantClasses) {
    throw new Error('Invalid variant')
  }

  if (!buttonClasses) {
    throw new Error('Invalid color')
  }

  return clsx(buttonClasses, sizeClasses, roundedClass, className)
}
