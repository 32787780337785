import Video from 'twilio-video'
import { useCallback, useEffect, useRef, useState } from 'react'
import { isMobile } from '../../../../utils'
import { useNavigate } from 'react-router-dom'
window.TwilioVideo = Video

/**
 * useRoom provides the function to connect to the video call
 * @param  {Array} localTracks
 * @param  {Object} options  options for room and all participants
 * @return  {Object} room
 * @return  {Boolean} isConnecting
 * @return  {Function} connect
 */

export const useRoom = (localTracks, options) => {
  const navigate = useNavigate()

  const [room, setRoom] = useState(null)
  const [isConnecting, setIsConnecting] = useState(false)
  const optionsRef = useRef(options)

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options
  }, [options])

  const connect = useCallback(
    (token, name) => {
      setIsConnecting(true)
      return Video.connect(token, {
        name,
        tracks: localTracks,
        ...optionsRef.current,
      }).then(
        (newRoom) => {
          setRoom(newRoom)
          const disconnect = () => newRoom.disconnect()

          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(null))
            window.removeEventListener('beforeunload', disconnect)

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect)
            }
          })

          window.twilioRoom = newRoom

          setIsConnecting(false)

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect)

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect)
          }
        },
        (error) => {
          navigate(`/meet/error/${error.code}`)
          setIsConnecting(false)
        },
      )
    },
    [localTracks],
  )

  return { room, isConnecting, connect }
}
