import { inputClassName } from '../styles'

export const multiSelectControlClasses = `
   ${inputClassName}
    !py-[7px]
`

export const multiValueOptionClasses = `
    bg-primary-light
    text-dark-gray
    rounded
`

export const optionClasses = `
    py-2
    px-4
    hover:bg-primary-light
`

export const multiValueRemoveClasses = `
    px-[3px]
`
