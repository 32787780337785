import { NUTRITIONAL_KEYS } from '../enums'

export const NUTRITIONAL_TIP_PRIMARY_LIST = Object.freeze({
  [NUTRITIONAL_KEYS.recNutritionalMediterranean]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
  ],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanKeep]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
  ],
  [NUTRITIONAL_KEYS.recNutritionalHypertension]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
  ],
  [NUTRITIONAL_KEYS.recNutritionalHeartFailure]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
  ],
  [NUTRITIONAL_KEYS.recNutritionalDiabetesType1]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.carbohydrateCalculation',
    'initial-report:sectionNutritional.tips.general.beAwareOfNoSugarProducts',
    'initial-report:sectionNutritional.tips.general.avoidRefinedSugarAndJuices',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
  ],
  [NUTRITIONAL_KEYS.recNutritionalDiabetesType2]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.carbohydrateCalculation',
    'initial-report:sectionNutritional.tips.general.beAwareOfNoSugarProducts',
    'initial-report:sectionNutritional.tips.general.avoidRefinedSugarAndJuices',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
  ],
  [NUTRITIONAL_KEYS.recNutritionalDyslipidemia]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
  ],
  [NUTRITIONAL_KEYS.recNutritionalOsteoarthritisArthritis]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
  ],
  [NUTRITIONAL_KEYS.recNutritionalOsteoporosis]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
  ],
  [NUTRITIONAL_KEYS.recLactoseIntolerant]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.lactoseIntolerant.canContainLactose',
    'initial-report:sectionNutritional.tips.lactoseIntolerant.calciumRich',
    'initial-report:sectionNutritional.tips.lactoseIntolerant.vitaminD',
    'initial-report:sectionNutritional.tips.lactoseIntolerant.hiddenLactose',
  ],
  [NUTRITIONAL_KEYS.recNutritionalIrritableBowelSyndrome]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
    'initial-report:sectionNutritional.tips.irritableBowel.goodHydration',
    'initial-report:sectionNutritional.tips.irritableBowel.avoidSugarAndSpicy',
    'initial-report:sectionNutritional.tips.irritableBowel.avoidTightClothing',
  ],
  [NUTRITIONAL_KEYS.recNutritionalGastroesophagealReflux]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.avoidBigAndFatMeals',
    'initial-report:sectionNutritional.tips.general.avoidColdAndHotMeals',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidChocolate',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
    'initial-report:sectionNutritional.tips.general.avoidFriedFood',
    'initial-report:sectionNutritional.tips.general.avoidSpicyOrMint',
    'initial-report:sectionNutritional.tips.general.drinkCamomile',
    'initial-report:sectionNutritional.tips.general.eatSlowly',
    'initial-report:sectionNutritional.tips.general.avoidTightClothing',
    'initial-report:sectionNutritional.tips.general.notEatBeforeSleeping',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCeliacDisease]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.celiacDisease.removeBulkProducts',
  ],
  [NUTRITIONAL_KEYS.recNutritionalGastritis]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.avoidBigAndFatMeals',
    'initial-report:sectionNutritional.tips.general.avoidColdAndHotMeals',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidChocolate',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
    'initial-report:sectionNutritional.tips.general.avoidFriedFood',
    'initial-report:sectionNutritional.tips.general.avoidSpicyOrMint',
    'initial-report:sectionNutritional.tips.general.drinkCamomile',
    'initial-report:sectionNutritional.tips.general.eatSlowly',
    'initial-report:sectionNutritional.tips.general.eatSeated',
  ],
  [NUTRITIONAL_KEYS.recNutritionalUlcerativeColitis]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.avoidBigAndFatMeals',
    'initial-report:sectionNutritional.tips.general.avoidColdAndHotMeals',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidChocolate',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
    'initial-report:sectionNutritional.tips.general.avoidFriedFood',
    'initial-report:sectionNutritional.tips.general.avoidSpicyOrMint',
    'initial-report:sectionNutritional.tips.general.drinkCamomile',
    'initial-report:sectionNutritional.tips.general.eatSlowly',
    'initial-report:sectionNutritional.tips.general.restSitting',
    'initial-report:sectionNutritional.tips.general.avoidTightClothing',
    'initial-report:sectionNutritional.tips.general.notEatBeforeSleeping',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCrohnsDisease]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.crohnsDisease.newFoodIntroduction',
    'initial-report:sectionNutritional.tips.crohnsDisease.avoidDiscomfortFood',
    'initial-report:sectionNutritional.tips.general.avoidBigAndFatMeals',
    'initial-report:sectionNutritional.tips.general.avoidColdAndHotMeals',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidChocolate',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
    'initial-report:sectionNutritional.tips.general.avoidFriedFood',
    'initial-report:sectionNutritional.tips.crohnsDisease.avoidSpicy',
    'initial-report:sectionNutritional.tips.general.drinkWaterOrInfusions',
    'initial-report:sectionNutritional.tips.general.eatSlowly',
    'initial-report:sectionNutritional.tips.general.restSitting',
    'initial-report:sectionNutritional.tips.general.avoidTightClothing',
    'initial-report:sectionNutritional.tips.general.notEatBeforeSleeping',
    'initial-report:sectionNutritional.tips.crohnsDisease.drinkSmall',
  ],
  [NUTRITIONAL_KEYS.recNutritionalConstipation]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
    'initial-report:sectionNutritional.tips.constipation.drinkWater',
  ],
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanCopd]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidChocolate',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
    'initial-report:sectionNutritional.tips.general.avoidFriedFood',
    'initial-report:sectionNutritional.tips.general.drinkWaterOrInfusions',
    'initial-report:sectionNutritional.tips.general.eatSlowly',
    'initial-report:sectionNutritional.tips.general.restSitting',
    'initial-report:sectionNutritional.tips.general.avoidColdAndHotMeals',
    'initial-report:sectionNutritional.tips.general.avoidTightClothing',
    'initial-report:sectionNutritional.tips.general.notEatBeforeSleeping',
  ],
  [NUTRITIONAL_KEYS.recNutritionalInsufficientWeight]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
  ],
  [NUTRITIONAL_KEYS.recNutritionalOverweight]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
  ],
  [NUTRITIONAL_KEYS.recNutritionalObesity]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
  ],
  [NUTRITIONAL_KEYS.recNutritionalVegan]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.vegan.b12Deficit',
    'initial-report:sectionNutritional.tips.vegan.expert',
  ],
  [NUTRITIONAL_KEYS.recNutritionalVegetarian]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.vegan.b12Deficit',
    'initial-report:sectionNutritional.tips.vegan.expert',
  ],
  [NUTRITIONAL_KEYS.recNutritionalDysphagia]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.dysphagia.ovenOrSteam',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo0]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
    'initial-report:sectionNutritional.tips.general.avoidChocolate',
    'initial-report:sectionNutritional.tips.general.avoidFriedFood',
    'initial-report:sectionNutritional.tips.general.avoidSoup',
    'initial-report:sectionNutritional.tips.general.avoidCheese',
    'initial-report:sectionNutritional.tips.general.limitOlives',
    'initial-report:sectionNutritional.tips.general.drinkWaterOrInfusions',
    'initial-report:sectionNutritional.tips.general.waterSodium',
    'initial-report:sectionNutritional.tips.general.saltListTitle',
    'initial-report:sectionNutritional.tips.general.saltListExtra',
    'initial-report:sectionNutritional.tips.general.carbohydrateCalculation',
    'initial-report:sectionNutritional.tips.general.beAwareOfNoSugarProducts',
    'initial-report:sectionNutritional.tips.general.eatSlowly',
    'initial-report:sectionNutritional.tips.general.restSitting',
    'initial-report:sectionNutritional.tips.general.avoidColdAndHotMeals',
    'initial-report:sectionNutritional.tips.general.avoidTightClothing',
    'initial-report:sectionNutritional.tips.general.notEatBeforeSleeping',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo1]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.avoidSoup',
    'initial-report:sectionNutritional.tips.general.avoidCheese',
    'initial-report:sectionNutritional.tips.general.limitOlives',
    'initial-report:sectionNutritional.tips.general.waterSodium',
    'initial-report:sectionNutritional.tips.general.saltListTitle',
    'initial-report:sectionNutritional.tips.general.saltListExtra',
    'initial-report:sectionNutritional.tips.general.carbohydrateCalculation',
    'initial-report:sectionNutritional.tips.general.beAwareOfNoSugarProducts',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo2]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
    'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
    'initial-report:sectionNutritional.tips.general.waterSodium',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo3]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo4]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo5]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
    'initial-report:sectionNutritional.tips.general.carbohydrateCalculation',
    'initial-report:sectionNutritional.tips.general.beAwareOfNoSugarProducts',
    'initial-report:sectionNutritional.tips.general.avoidRefinedSugarAndJuices',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo6]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo7]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
    'initial-report:sectionNutritional.tips.general.reducePastries',
    'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
    'initial-report:sectionNutritional.tips.general.avoidCoffeeAndAlcohol',
    'initial-report:sectionNutritional.tips.general.carbohydrateCalculation',
    'initial-report:sectionNutritional.tips.general.beAwareOfNoSugarProducts',
    'initial-report:sectionNutritional.tips.general.avoidRefinedSugarAndJuices',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo8]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo9]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo10]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo11]: [
    'initial-report:sectionNutritional.tips.general.maintainHealthyWeight',
  ],
})

export const NUTRITIONAL_TIP_SECONDARY_LIST = Object.freeze({
  [NUTRITIONAL_KEYS.recNutritionalHypertension]: {
    title: 'initial-report:sectionNutritional.tips.general.saltListTitle',
    list: [
      'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
      'initial-report:sectionNutritional.tips.general.reducePastries',
      'initial-report:sectionNutritional.tips.general.avoidSoup',
      'initial-report:sectionNutritional.tips.general.avoidCheese',
      'initial-report:sectionNutritional.tips.general.reduceGassedDrinks',
      'initial-report:sectionNutritional.tips.general.limitOlives',
      'initial-report:sectionNutritional.tips.general.limitCoffee',
      'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
      'initial-report:sectionNutritional.tips.general.waterSodium',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalHeartFailure]: {
    title: 'initial-report:sectionNutritional.tips.general.saltListTitle',
    list: [
      'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
      'initial-report:sectionNutritional.tips.general.reducePastries',
      'initial-report:sectionNutritional.tips.general.avoidSoup',
      'initial-report:sectionNutritional.tips.general.avoidCheese',
      'initial-report:sectionNutritional.tips.general.reduceGassedDrinks',
      'initial-report:sectionNutritional.tips.general.limitOlives',
      'initial-report:sectionNutritional.tips.general.limitCoffee',
      'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
      'initial-report:sectionNutritional.tips.general.waterSodium',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalCeliacDisease]: {
    title:
      'initial-report:sectionNutritional.tips.celiacDisease.avoidGlutenFoods',
    list: [
      'initial-report:sectionNutritional.tips.celiacDisease.breadAndFlours',
      'initial-report:sectionNutritional.tips.celiacDisease.cakeFoods',
      'initial-report:sectionNutritional.tips.celiacDisease.cookieFoods',
      'initial-report:sectionNutritional.tips.celiacDisease.italianPasta',
      'initial-report:sectionNutritional.tips.celiacDisease.maltedMilk',
      'initial-report:sectionNutritional.tips.celiacDisease.avoidAlcohol',
      'initial-report:sectionNutritional.tips.celiacDisease.avoidManufactured',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanCopd]: {
    title:
      'initial-report:sectionNutritional.tips.general.messuresFacilitingFeeding',
    list: [
      'initial-report:sectionNutritional.tips.general.easyPrepareFoods',
      'initial-report:sectionNutritional.tips.general.tranquilityAtmosphere',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalDysphagia]: {
    title: 'initial-report:sectionNutritional.tips.dysphagia.foodsToAvoid',
    list: [
      'initial-report:sectionNutritional.tips.dysphagia.fibrouseTextures',
      'initial-report:sectionNutritional.tips.dysphagia.skinOrSeeds',
      'initial-report:sectionNutritional.tips.dysphagia.stickyFoods',
      'initial-report:sectionNutritional.tips.dysphagia.slipperyFoods',
      'initial-report:sectionNutritional.tips.dysphagia.hardFoods',
      'initial-report:sectionNutritional.tips.dysphagia.doubleConsistencyFoods',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalCombo2]: {
    title: 'initial-report:sectionNutritional.tips.general.saltListTitle',
    list: [
      'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
      'initial-report:sectionNutritional.tips.general.avoidSoup',
      'initial-report:sectionNutritional.tips.general.avoidCheese',
      'initial-report:sectionNutritional.tips.general.limitOlives',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalCombo3]: {
    title: 'initial-report:sectionNutritional.tips.general.saltListTitle',
    list: [
      'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
      'initial-report:sectionNutritional.tips.general.reducePastries',
      'initial-report:sectionNutritional.tips.general.avoidSoup',
      'initial-report:sectionNutritional.tips.general.avoidCheese',
      'initial-report:sectionNutritional.tips.general.reduceGassedDrinks',
      'initial-report:sectionNutritional.tips.general.limitOlives',
      'initial-report:sectionNutritional.tips.general.limitCoffee',
      'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
      'initial-report:sectionNutritional.tips.general.waterSodium',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalCombo4]: {
    title: 'initial-report:sectionNutritional.tips.general.saltListTitle',
    list: [
      'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
      'initial-report:sectionNutritional.tips.general.reducePastries',
      'initial-report:sectionNutritional.tips.general.avoidSoup',
      'initial-report:sectionNutritional.tips.general.avoidCheese',
      'initial-report:sectionNutritional.tips.general.reduceGassedDrinks',
      'initial-report:sectionNutritional.tips.general.limitOlives',
      'initial-report:sectionNutritional.tips.general.limitCoffee',
      'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
      'initial-report:sectionNutritional.tips.general.waterSodium',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalCombo0]: {
    title:
      'initial-report:sectionNutritional.tips.general.messuresFacilitingFeeding',
    list: [
      'initial-report:sectionNutritional.tips.general.easyPrepareFoods',
      'initial-report:sectionNutritional.tips.general.tranquilityAtmosphere',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalCombo8]: {
    title: 'initial-report:sectionNutritional.tips.general.saltListTitle',
    list: [
      'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
      'initial-report:sectionNutritional.tips.general.reducePastries',
      'initial-report:sectionNutritional.tips.general.avoidSoup',
      'initial-report:sectionNutritional.tips.general.avoidCheese',
      'initial-report:sectionNutritional.tips.general.reduceGassedDrinks',
      'initial-report:sectionNutritional.tips.general.limitOlives',
      'initial-report:sectionNutritional.tips.general.limitCoffee',
      'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
      'initial-report:sectionNutritional.tips.general.waterSodium',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalCombo9]: {
    title: 'initial-report:sectionNutritional.tips.general.saltListTitle',
    list: [
      'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
      'initial-report:sectionNutritional.tips.general.reducePastries',
      'initial-report:sectionNutritional.tips.general.avoidSoup',
      'initial-report:sectionNutritional.tips.general.avoidCheese',
      'initial-report:sectionNutritional.tips.general.reduceGassedDrinks',
      'initial-report:sectionNutritional.tips.general.limitOlives',
      'initial-report:sectionNutritional.tips.general.limitCoffee',
      'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
      'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
      'initial-report:sectionNutritional.tips.general.waterSodium',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalCombo10]: {
    title: 'initial-report:sectionNutritional.tips.general.saltListTitle',
    list: [
      'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
      'initial-report:sectionNutritional.tips.general.reducePastries',
      'initial-report:sectionNutritional.tips.general.avoidSoup',
      'initial-report:sectionNutritional.tips.general.avoidCheese',
      'initial-report:sectionNutritional.tips.general.reduceGassedDrinks',
      'initial-report:sectionNutritional.tips.general.limitOlives',
      'initial-report:sectionNutritional.tips.general.limitCoffee',
      'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
      'initial-report:sectionNutritional.tips.general.waterSodium',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalCombo11]: {
    title: 'initial-report:sectionNutritional.tips.general.saltListTitle',
    list: [
      'initial-report:sectionNutritional.tips.general.removeUltraProcessed',
      'initial-report:sectionNutritional.tips.general.reducePastries',
      'initial-report:sectionNutritional.tips.general.avoidSoup',
      'initial-report:sectionNutritional.tips.general.avoidCheese',
      'initial-report:sectionNutritional.tips.general.reduceGassedDrinks',
      'initial-report:sectionNutritional.tips.general.limitOlives',
      'initial-report:sectionNutritional.tips.general.limitCoffee',
      'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
      'initial-report:sectionNutritional.tips.general.avoidAlcoholDrinkWater',
      'initial-report:sectionNutritional.tips.general.waterSodium',
    ],
  },
})

export const NUTRITIONAL_TIP_TERTIARY_LIST = Object.freeze({
  [NUTRITIONAL_KEYS.recNutritionalCeliacDisease]: {
    title:
      'initial-report:sectionNutritional.tips.celiacDisease.canContainGluten',
    list: [
      'initial-report:sectionNutritional.tips.celiacDisease.sausages',
      'initial-report:sectionNutritional.tips.celiacDisease.cheese',
      'initial-report:sectionNutritional.tips.celiacDisease.variousPates',
      'initial-report:sectionNutritional.tips.celiacDisease.cannedMeats',
      'initial-report:sectionNutritional.tips.celiacDisease.cannedFish',
      'initial-report:sectionNutritional.tips.celiacDisease.candies',
      'initial-report:sectionNutritional.tips.celiacDisease.coffeeSubstitutes',
      'initial-report:sectionNutritional.tips.celiacDisease.nutsRoasted',
      'initial-report:sectionNutritional.tips.celiacDisease.iceCream',
      'initial-report:sectionNutritional.tips.celiacDisease.chocolateSubstitute',
      'initial-report:sectionNutritional.tips.celiacDisease.foodColoring',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalMediterraneanCopd]: {
    title: 'initial-report:sectionNutritional.tips.shortnessOfBreath.mesures',
    list: [
      'initial-report:sectionNutritional.tips.shortnessOfBreath.stopSmoking',
      'initial-report:sectionNutritional.tips.shortnessOfBreath.useMedicines',
      'initial-report:sectionNutritional.tips.shortnessOfBreath.restBeforeEat',
      'initial-report:sectionNutritional.tips.shortnessOfBreath.eatSeated',
      'initial-report:sectionNutritional.tips.shortnessOfBreath.oxigenUse',
    ],
  },
  [NUTRITIONAL_KEYS.recNutritionalCombo0]: {
    title: 'initial-report:sectionNutritional.tips.shortnessOfBreath.mesures',
    list: [
      'initial-report:sectionNutritional.tips.shortnessOfBreath.stopSmoking',
      'initial-report:sectionNutritional.tips.shortnessOfBreath.useMedicines',
      'initial-report:sectionNutritional.tips.shortnessOfBreath.restBeforeEat',
      'initial-report:sectionNutritional.tips.shortnessOfBreath.eatSeated',
      'initial-report:sectionNutritional.tips.shortnessOfBreath.oxigenUse',
    ],
  },
})

export const NUTRITIONAL_TIP_EXTRA_LIST = Object.freeze({
  [NUTRITIONAL_KEYS.recNutritionalHypertension]: [
    'initial-report:sectionNutritional.tips.general.saltListExtra',
  ],
  [NUTRITIONAL_KEYS.recNutritionalHeartFailure]: [
    'initial-report:sectionNutritional.tips.general.saltListExtra',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo2]: [
    'initial-report:sectionNutritional.tips.general.saltListExtra',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo3]: [
    'initial-report:sectionNutritional.tips.general.saltListExtra',
    'initial-report:sectionNutritional.tips.general.carbohydrateCalculation',
    'initial-report:sectionNutritional.tips.general.beAwareOfNoSugarProducts',
    'initial-report:sectionNutritional.tips.general.avoidRefinedSugarAndJuices',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo4]: [
    'initial-report:sectionNutritional.tips.general.saltListExtra',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo8]: [
    'initial-report:sectionNutritional.tips.general.saltListExtra',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo9]: [
    'initial-report:sectionNutritional.tips.general.saltListExtra',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo10]: [
    'initial-report:sectionNutritional.tips.general.saltListExtra',
    'initial-report:sectionNutritional.tips.general.carbohydrateCalculation',
    'initial-report:sectionNutritional.tips.general.beAwareOfNoSugarProducts',
    'initial-report:sectionNutritional.tips.general.avoidRefinedSugarAndJuices',
    'initial-report:sectionNutritional.tips.general.removeRefinedFlours',
  ],
  [NUTRITIONAL_KEYS.recNutritionalCombo11]: [
    'initial-report:sectionNutritional.tips.general.saltListExtra',
  ],
})
