import { SectionAuditiveAndOcular } from '../../report-pdf/sections/auditive-and-ocular'
import { SecondPage } from './components/second-page'

export const SectionEight = () => {
  return (
    <SectionAuditiveAndOcular sectionNumber="06">
      <SecondPage />
    </SectionAuditiveAndOcular>
  )
}
