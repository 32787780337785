export const SRBigCalendarEventDayWrapperStyle = (style) => ({
  height: `${style.height}%`,
  top: `${style.top}%`,
  width: `calc(${style.width}% - 40px)`,
  left: `${style.xOffset}%`,
  marginLeft: '20px',
})

export const SRBigCalendarEventWeekWrapperStyle = (style) => ({
  height: `${style.height}%`,
  top: `${style.top}%`,
  width: `calc(${style.width}% - 10px)`,
  left: `${style.xOffset}%`,
  marginLeft: '5px',
})
