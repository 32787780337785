import {
  containerClassName,
  routeInfoClassName,
  titleClassName,
} from './styles'
import { ArrowLeft } from '@carbon/icons-react'
import { useNavigate } from 'react-router-dom'

export const BackButton = ({
  containerClasses = '',
  titleClasses = '',
  arrowLeftClasses = '',
  arrowLeftSize = 32,
  onArrowLeftClick,
  icon,
  text,
}) => {
  const navigate = useNavigate()
  const defaultArrowLeftClick = () => {
    const history = JSON.parse(localStorage.getItem('navigationHistory')) || []
    if (history.length > 1) {
      history.pop()
      const lastRoute = history.pop()
      localStorage.setItem('navigationHistory', JSON.stringify(history))
      navigate(lastRoute)
    } else {
      localStorage.setItem('navigationHistory', JSON.stringify([]))
      navigate('/')
    }
  }

  return (
    <div className={`${containerClassName} ${containerClasses}`}>
      <ArrowLeft
        data-testid="toggleBack"
        className={`${arrowLeftClasses} cursor-pointer`}
        onClick={onArrowLeftClick || defaultArrowLeftClick}
        size={arrowLeftSize}
      />
      <div className={routeInfoClassName}>
        {icon && icon}
        <p
          className={`${titleClassName} ${titleClasses}`}
          data-testid="backButtonContentText"
        >
          {text}
        </p>
      </div>
    </div>
  )
}
