import { useParticipants, useVideoContext } from '../../../../hooks'
import Participant from '../participant/participant'

export const ParticipantList = ({ options }) => {
  const { room } = useVideoContext()
  const localParticipant = room.localParticipant
  const participants = useParticipants()

  return (
    <div className="h-full w-full lg:max-w-6xl xl:max-w-[1360px] mx-auto">
      <div className="flex flex-col lg:flex-row items-center lg:gap-4 gap-8 h-full relative overflow-x-hidden">
        {participants.length
          ? participants.map((participant) => {
              return (
                <Participant
                  data-testid="Participant"
                  key={participant.sid}
                  participant={participant}
                />
              )
            })
          : null}
        <Participant participant={localParticipant} isLocalParticipant={true} />
      </div>
    </div>
  )
}
