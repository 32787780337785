import { getRoot, types } from 'mobx-state-tree'
import { TestModel } from '../test'
import { GENERIC_ANALYSIS_STATUS } from '../generic-analysis/enums'

export const MODEL_NAME = 'GenericAnalysisTest'

export const Model = TestModel.named(MODEL_NAME)
  .props({
    testOrder: types.maybeNull(types.integer),
    testStatus: types.maybeNull(types.integer),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
  .views((self) => ({
    get isFilled() {
      return (
        self.testStatus === GENERIC_ANALYSIS_STATUS.filled ||
        self.testStatus === GENERIC_ANALYSIS_STATUS.closed
      )
    },
  }))

export default Model
