import { BaseRouteState } from '@shared/data-access'
import { ConversationParticipants } from '@sr/data-access'
import { actions } from '@sr/routes/common'
import { flow, types } from 'mobx-state-tree'

export const NotificationsChatRouteState = BaseRouteState.named(
  'NotificationsChatRouteState',
)
  .props({
    conversationParticipants: types.maybeNull(
      types.array(types.reference(ConversationParticipants)),
    ),
  })
  .views((self) => ({
    get conversationsList() {
      if (!self.conversationParticipants) return []
      return self.conversationParticipants.map(
        ({ conversation, unreadMessagesCount }) => ({
          unreadMessagesCount,
          ...conversation,
        }),
      )
    },
    getConversationBySid(roomSid) {
      if (!self.conversationParticipants) return []
      return self.conversationParticipants.findIndex(
        ({ conversation }) => conversation.sid === roomSid,
      )
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadAppDependencies()
    },
    loadAppDependencies: flow(function* loadAppDependencies() {
      self.setLoading('NotificationsChatRouteState')
      try {
        yield self.getConversations()
      } finally {
        self.removeLoading('NotificationsChatRouteState')
      }
    }),
    reload: flow(function* reload() {
      self.setLoading('reload')
      try {
        yield self.loadAppDependencies()
      } finally {
        self.removeLoading('reload')
      }
    }),
    updateItemMessages({ roomSid, count }) {
      self.setLoading('updateItemMessages')
      try {
        const itemIndex = self.getConversationBySid(roomSid)
        const conversation = self.conversationParticipants?.[itemIndex]
        if (conversation) {
          conversation.updateUnreadMessages(count)
        }
      } finally {
        self.removeLoading('updateItemMessages')
      }
    },
    ...actions(self),
  }))
