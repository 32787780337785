import { StyleSheet } from '@react-pdf/renderer'
import { createTw } from 'react-pdf-tailwind'

interface COLOR {
  [key: string]: string
}

export const AreaHexColor: COLOR = {
  nutritional: '#9eca38',
}

export enum AreaColor {
  cognitive = 'cognitive',
  cognitiveLight = 'cognitiveLight',
  auditiveAndOcular = 'auditiveAndOcular',
  social = 'social',
  physicalActivity = 'physicalActivity',
  nutritional = 'nutritional',
  nutritionalLight = 'nutritionalLight',
  emotional = 'emotional',
  tobaccoAlcohol = 'tobaccoAlcohol',
}

export const COGNITIVE_AND_SOCIAL_CLASSES = [
  StyleSheet.create({
    '.items': {
      flexDirection: 'column',
      gap: '20px',
    },
    '.items > div': {
      paddingLeft: '16px',
      borderLeftStyle: 'solid',
      borderLeftWidth: '3px',
    },
    '.cognitive-item': {
      borderLeftColor: '#14aa9c',
    },
    '.cognitive-item > span': {
      color: '#14aa9c',
      fontWeight: 'bold',
    },
    '.social-item': {
      borderLeftColor: '#df3640',
    },
    '.social-item > span': {
      color: '#df3640',
      fontWeight: 'bold',
    },
    a: {
      color: '#14aa9c',
    },
  }),
]

export const tw = createTw({
  theme: {
    extend: {
      colors: {
        primary: '#004039',
        secondary: '#FF8A5D',
        tertiary: '#FDC646',
        grey: '#999999',
        midGrey: '#efefefff',
        lightGrey: '#EFEFEF',
        cognitive: '#14aa9c',
        cognitiveLight: '#a1ddd7',
        auditiveAndOcular: '#ee5a21',
        social: '#df3640',
        physicalActivity: '#fdc646',
        nutritional: AreaHexColor['nutritional'],
        nutritionalLight: '#d8eaaf',
        emotional: '#c763b0',
        tobaccoAlcohol: '#4ba8df',
        green: '#008000',
        orange: '#FFBF00',
        darkRed: '#960018',
      },
    },
  },
})
