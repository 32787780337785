import { BUTTON_SIZES, Button, Form, Input } from '@shared/ui'
import { useForm } from 'react-hook-form'
import { chatFormContainerClasses, chatInputClasses } from './styles'
import { useTranslation } from 'react-i18next'

export const ConversationForm = ({ onSubmit }) => {
  const { t } = useTranslation()
  const methods = useForm()
  const { handleSubmit, reset, watch } = methods

  const message = watch('message')

  const onFormSubmit = handleSubmit(async (form) => {
    if (!message) return
    onSubmit(form)
    reset()
  })

  const onEnter = (event) => {
    if (message && event.key === 'Enter') {
      onFormSubmit()
    }
  }

  return (
    <Form className={chatFormContainerClasses} methods={methods}>
      <Input
        onKeyDown={onEnter}
        name="message"
        autoFocus
        className={chatInputClasses}
      />
      <Button size={BUTTON_SIZES.small} onClick={onFormSubmit}>
        {t('common:send')}
      </Button>
    </Form>
  )
}
