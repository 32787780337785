import { BaseRouteState, SocialReferent } from '@shared/data-access'
import { AnnuityModel, GenerateRoomModel } from '@sr/data-access'
import { actions, actionsCampaign } from '@sr/routes/common/store'
import { flow, types } from 'mobx-state-tree'

export const GenericAppointmentState = BaseRouteState.named(
  'GenericAppointmentState'
)
  .props({
    isEdit: false,
    socialReferentId: types.maybeNull(types.string),
    eventId: types.maybeNull(types.string),
    user: types.maybeNull(types.reference(SocialReferent)),
    canGenerateRoom: types.maybeNull(types.reference(GenerateRoomModel)),
    annuities: types.array(types.reference(AnnuityModel)),
  })
  .views((self) => ({
    get isOwner() {
      return self.socialReferentId === self.user?.id
    },
    get annuityList() {
      return self.annuities?.reduce(
        (acc, { person: { id, fullName }, socialReferentId }) => {
          if (
            self.socialReferentId === socialReferentId ||
            (self.user?.isCoordinator && self.isOwner)
          ) {
            acc.push({
              value: id,
              name: fullName,
              disabled: self.isEdit,
            })
          }
          return acc
        },
        []
      )
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('GenericAppointmentState')
      try {
        yield self.loadAnnuities()
        yield self.loadUserInfo()

        if (self.isEdit) {
          yield self.checkIfCanGenerateRoom(self.eventId)
        }

        if (!self.socialReferentId) {
          self.socialReferentId = self.user?.id
        }
      } finally {
        self.removeLoading('GenericAppointmentState')
      }
    }),
    ...actions(self),
    ...actionsCampaign(self),
  }))
