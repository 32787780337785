import { FEATURE_FLAG, featureFlags, useRouteState } from '@shared/utils'
import { SrTest, SRTestWithScript, SRUserName, SRWizard } from '@sr/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useLayoutContext } from '../common'
import { CompleteModal } from './components'
import { INITIAL_REPORT_TEST_NAME, TEST_PAGE, TEST_PAGE_NAME } from './enums'
import { InitialReportRouteState } from './state'
import { InitialReportContainer, NextStepsButtonContainer } from './styles'
import { Button, BUTTON_COLORS } from '@shared/ui'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'

export const InitialReport = observer(() => {
  const navigate = useNavigate()
  const TRANSLATION_BASE_KEY = 'userArea.initialReport'
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activePageNumber, setActivePageNumber] = useState(0)
  const [isVisible, setIsVisible] = useState(false)

  const { t } = useTranslation()
  const { id } = useParams()
  const {
    familyBackground,
    clinicalAssessment,
    functionalAssessment,
    cognitiveAssessment,
    nutritionalAssessment,
    socialAssessment,
    summary,
    updateTest,
    completeTest,
    isLoading,
    hasAllTests,
    policy,
    allTestCompleted,
    lastTestNotCompleted,
    hasStartedSummary,
    hasFinishedSummary,
    setMockedData,
  } = useRouteState(InitialReportRouteState, {
    policyId: id,
  })
  const hasMockedData = featureFlags.hasFeatureFlag(FEATURE_FLAG.initial)

  const { setIsLoading } = useLayoutContext()

  const onExit = () => {
    //console.log('exit form')
  }

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (hasAllTests) {
      if (allTestCompleted) {
        return navigate(ROUTE_PATH.userArea(id))
      } else {
        if (lastTestNotCompleted === TEST_PAGE.nextSteps && hasStartedSummary) {
          return setActivePageNumber(TEST_PAGE.summary)
        }
        setActivePageNumber(TEST_PAGE[TEST_PAGE_NAME[lastTestNotCompleted]])
      }
    }
  }, [
    familyBackground?.testCompleted,
    clinicalAssessment?.testCompleted,
    functionalAssessment?.testCompleted,
    cognitiveAssessment?.testCompleted,
    nutritionalAssessment?.testCompleted,
    socialAssessment?.testCompleted,
  ])

  useEffect(() => {
    if (hasAllTests && hasMockedData) {
      setMockedData()
    }
  }, [hasAllTests])

  const goToSummary = () => {
    setActivePageNumber(TEST_PAGE.summary)
  }

  const onFinishSummary = () => {
    navigate(ROUTE_PATH.userArea(policy?.id))
  }

  const onCompleteSummary = () => {
    setIsVisible(true)
  }

  const onCloseModal = () => {
    setIsVisible(false)
  }

  const onFinish = async () => {
    await completeTest(INITIAL_REPORT_TEST_NAME[summary?.testType])
    setIsVisible(false)
    navigate(ROUTE_PATH.userArea(policy?.id))
  }

  return (
    <InitialReportContainer>
      <SRUserName fullName={policy?.fullName} />
      <SRWizard
        disableNavigation
        timeline
        hidePrev
        hideNext
        activePageNumber={activePageNumber}
      >
        <SRTestWithScript
          name={t(`${TRANSLATION_BASE_KEY}.familyBackground.title`)}
          script={t(`${TRANSLATION_BASE_KEY}.familyBackground.script`)}
        >
          <SrTest
            hasAccordion
            name={t(`${TRANSLATION_BASE_KEY}.familyBackground.title`)}
            type={INITIAL_REPORT_TEST_NAME[familyBackground?.testType]}
            test={familyBackground?.testMetaJson}
            values={familyBackground?.prevData}
            updateTest={updateTest}
            onComplete={() =>
              completeTest(INITIAL_REPORT_TEST_NAME[familyBackground?.testType])
            }
            completeText={t(`${TRANSLATION_BASE_KEY}.buttons.next`)}
            onExit={onExit}
          />
        </SRTestWithScript>
        <SRTestWithScript
          name={t(`${TRANSLATION_BASE_KEY}.clinicalAssessment.title`)}
          script={t(`${TRANSLATION_BASE_KEY}.clinicalAssessment.script`)}
        >
          <SrTest
            hasAccordion
            type={INITIAL_REPORT_TEST_NAME[clinicalAssessment?.testType]}
            test={clinicalAssessment?.testMetaJson}
            values={clinicalAssessment?.prevData}
            updateTest={updateTest}
            onComplete={() =>
              completeTest(
                INITIAL_REPORT_TEST_NAME[clinicalAssessment?.testType],
              )
            }
            completeText={t(`${TRANSLATION_BASE_KEY}.buttons.next`)}
            onExit={onExit}
          />
        </SRTestWithScript>
        <SRTestWithScript
          name={t(`${TRANSLATION_BASE_KEY}.functionalAssessment.title`)}
          script={t(`${TRANSLATION_BASE_KEY}.functionalAssessment.script`)}
        >
          <SrTest
            hasAccordion
            type={INITIAL_REPORT_TEST_NAME[functionalAssessment?.testType]}
            test={functionalAssessment?.testMetaJson}
            values={functionalAssessment?.prevData}
            updateTest={updateTest}
            onComplete={() =>
              completeTest(
                INITIAL_REPORT_TEST_NAME[functionalAssessment?.testType],
              )
            }
            completeText={t(`${TRANSLATION_BASE_KEY}.buttons.next`)}
            onExit={onExit}
          />
        </SRTestWithScript>
        <SRTestWithScript
          name={t(`${TRANSLATION_BASE_KEY}.cognitiveAssessment.title`)}
          script={t(`${TRANSLATION_BASE_KEY}.cognitiveAssessment.script`)}
        >
          <SrTest
            hasAccordion
            type={INITIAL_REPORT_TEST_NAME[cognitiveAssessment?.testType]}
            test={cognitiveAssessment?.testMetaJson}
            values={cognitiveAssessment?.prevData}
            updateTest={updateTest}
            onComplete={() =>
              completeTest(
                INITIAL_REPORT_TEST_NAME[cognitiveAssessment?.testType],
              )
            }
            completeText={t(`${TRANSLATION_BASE_KEY}.buttons.next`)}
            onExit={onExit}
          />
        </SRTestWithScript>
        <SRTestWithScript
          name={t(`${TRANSLATION_BASE_KEY}.nutritionalAssessment.title`)}
          script={t(`${TRANSLATION_BASE_KEY}.nutritionalAssessment.script`)}
        >
          <SrTest
            hasAccordion
            type={INITIAL_REPORT_TEST_NAME[nutritionalAssessment?.testType]}
            test={nutritionalAssessment?.testMetaJson}
            values={nutritionalAssessment?.prevData}
            updateTest={updateTest}
            onComplete={() =>
              completeTest(
                INITIAL_REPORT_TEST_NAME[nutritionalAssessment?.testType],
              )
            }
            completeText={t(`${TRANSLATION_BASE_KEY}.buttons.next`)}
            onExit={onExit}
          />
        </SRTestWithScript>
        <SRTestWithScript
          name={t(`${TRANSLATION_BASE_KEY}.socialAssessment.title`)}
          script={t(`${TRANSLATION_BASE_KEY}.socialAssessment.script`)}
        >
          <SrTest
            hasAccordion
            type={INITIAL_REPORT_TEST_NAME[socialAssessment?.testType]}
            test={socialAssessment?.testMetaJson}
            values={socialAssessment?.prevData}
            updateTest={updateTest}
            onComplete={() =>
              completeTest(INITIAL_REPORT_TEST_NAME[socialAssessment?.testType])
            }
            completeText={t(`${TRANSLATION_BASE_KEY}.buttons.finish`)}
            onExit={onExit}
          />
        </SRTestWithScript>

        <div name={t(`${TRANSLATION_BASE_KEY}.nextSteps.title`)}>
          <Trans
            i18nkey={`${TRANSLATION_BASE_KEY}.nextSteps.script`}
            components={{ bold: <strong />, br: <br /> }}
            defaults={t(`${TRANSLATION_BASE_KEY}.nextSteps.script`)}
          />
          <NextStepsButtonContainer>
            <Button color={BUTTON_COLORS.secondary} onClick={onExit}>
              {t('common:exit')}
            </Button>
            <Button onClick={goToSummary} testId="next-steps-finish">
              {t(`${TRANSLATION_BASE_KEY}.buttons.finish`)}
            </Button>
          </NextStepsButtonContainer>
        </div>

        <SRTestWithScript name={t(`${TRANSLATION_BASE_KEY}.summary.title`)}>
          <SrTest
            hasAccordion
            type={INITIAL_REPORT_TEST_NAME[summary?.testType]}
            test={summary?.testMetaJson}
            values={summary?.prevData}
            updateTest={updateTest}
            onComplete={onFinishSummary}
            completeText={t(`${TRANSLATION_BASE_KEY}.buttons.finish`)}
            onExit={onExit}
            extraAction={onCompleteSummary}
            extraActionText={t(
              `${TRANSLATION_BASE_KEY}.buttons.generateReport`,
            )}
            extraActionVisible={hasFinishedSummary}
          />
        </SRTestWithScript>
      </SRWizard>
      {isVisible && (
        <CompleteModal onClose={onCloseModal} onComplete={onFinish} />
      )}
    </InitialReportContainer>
  )
})
