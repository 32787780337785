import { LayoutContextProvider } from '@sr/routes/common'
import { Agenda, Dashboard, ErrorPage, Layout, Meet } from '@sr/routes/index'
import { observer } from 'mobx-react-lite'
import { Outlet, useRoutes } from 'react-router-dom'
import { coordinatorRoutes } from './coordinator-routes'
import { ROUTE_PATH } from './enums'
import { MeetNotAvailable } from '@sr/routes/Meet/not-available'
import { TwilioCallError, VideoContextProvider } from '@shared/ui'
import { myBoxCareRoutes } from './mybox-care-routes'
import { campaignRoutes } from './campaign-routes'
import { NotificationsChat } from '@sr/routes/notifications'

export const routes = ({ externalRoutes = [], userAreaRoutes = [] }) => [
  {
    path: ROUTE_PATH.home,
    element: (
      <LayoutContextProvider>
        <Layout />
      </LayoutContextProvider>
    ),
    children: [
      ...externalRoutes,
      ...myBoxCareRoutes(userAreaRoutes),
      ...campaignRoutes,
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: ROUTE_PATH.agenda(':id'),
        element: <Agenda />,
      },
      {
        path: ROUTE_PATH.notificationsChat,
        element: <NotificationsChat />,
      },
      {
        path: '*',
        element: <ErrorPage status="404" />,
      },
    ],
  },
  {
    path: ROUTE_PATH.meet,
    element: (
      <VideoContextProvider
        videoOptions={{
          actionButtons: true,
          isAdmin: true,
        }}
      >
        <Outlet />
      </VideoContextProvider>
    ),
    children: [
      {
        path: ROUTE_PATH.meetTokenRoom,
        element: <Meet />,
      },
      {
        path: ROUTE_PATH.meetNotAvailable,
        element: <MeetNotAvailable />,
      },
      {
        path: ROUTE_PATH.meetError,
        element: <TwilioCallError />,
      },
    ],
  },
]

export const Router = observer(({ user }) => {
  const coordinatorRoutesObject = {
    externalRoutes: coordinatorRoutes,
  }
  const isCoordinator = user.socialReferentType === 1

  const appRoutes = useRoutes(
    routes(isCoordinator ? coordinatorRoutesObject : []),
  )

  return appRoutes
})
