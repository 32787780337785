type SERVICE_STATE = {
  [key: string]: string
}

export const AnnuityState = Object.freeze({
  pending: 1,
  accepted: 2,
  cancelled: 3,
  finished: 4,
  detached: 5,
})

export const SERVICE_STATE: SERVICE_STATE = Object.freeze({
  [AnnuityState.pending]: 'pending',
  [AnnuityState.accepted]: 'accepted',
  [AnnuityState.cancelled]: 'cancelled',
  [AnnuityState.finished]: 'finished',
  [AnnuityState.detached]: 'detached',
})

export const AnnuitySubState = Object.freeze({
  pendingToSchedule: 11,
  notFoundBeforeAppointment: 12,
  wrongTelNumber: 13,
  scheduled: 21,
  notInterested: 31,
  noShowAppointment: 32,
  cancelledVCX: 33,
})

export const ANNUITY_SUB_STATE = Object.freeze({
  [AnnuitySubState.pendingToSchedule]: 'pendingToSchedule',
  [AnnuitySubState.notFoundBeforeAppointment]: 'notFoundBeforeAppointment',
  [AnnuitySubState.wrongTelNumber]: 'wrongTelNumber',
  [AnnuitySubState.scheduled]: 'scheduled',
  [AnnuitySubState.notInterested]: 'notInterested',
  [AnnuitySubState.noShowAppointment]: 'noShowAppointment',
  [AnnuitySubState.cancelledVCX]: 'cancelledVCX',
})

export const ANNUITY_STATE_COLOR: SERVICE_STATE = Object.freeze({
  [AnnuityState.pending]: 'text-campaign-pending',
  [AnnuityState.accepted]: 'text-campaign-accepted',
  [AnnuityState.cancelled]: 'text-campaign-cancelled',
  [AnnuityState.finished]: 'text-campaign-finished',
  [AnnuityState.detached]: 'text-campaign-detached',
})
