import { GENERIC_ANALYSIS_TEST_TYPE } from '@sr/data-access'

export const GA_TEST_TYPE_NAME = Object.freeze({
  [GENERIC_ANALYSIS_TEST_TYPE.basicHealth]: 'analysis:basicHealth.title',
  [GENERIC_ANALYSIS_TEST_TYPE.lobo]: 'analysis:lobo.title',
  [GENERIC_ANALYSIS_TEST_TYPE.clock]: 'analysis:clock.title',
  [GENERIC_ANALYSIS_TEST_TYPE.family]: 'analysis:family.title',
  [GENERIC_ANALYSIS_TEST_TYPE.clinical]: 'analysis:clinical.title',
  [GENERIC_ANALYSIS_TEST_TYPE.functional]: 'analysis:functional.title',
  [GENERIC_ANALYSIS_TEST_TYPE.cognitive]: 'analysis:cognitive.title',
  [GENERIC_ANALYSIS_TEST_TYPE.nutritional]: 'analysis:nutritional.title',
  [GENERIC_ANALYSIS_TEST_TYPE.social]: 'analysis:social.title',
  [GENERIC_ANALYSIS_TEST_TYPE.summary]: 'analysis:summary.title',
  [GENERIC_ANALYSIS_TEST_TYPE.summary_executive]: 'analysis:summary_executive.title',
  [GENERIC_ANALYSIS_TEST_TYPE.pfeiffer]: 'analysis:pfeiffer.title',
})

export const GA_TEST_TYPE_SCRIPT = Object.freeze({
  [GENERIC_ANALYSIS_TEST_TYPE.basicHealth]: 'analysis:basicHealth.script',
  [GENERIC_ANALYSIS_TEST_TYPE.lobo]: 'analysis:lobo.script',
  [GENERIC_ANALYSIS_TEST_TYPE.clock]: 'analysis:clock.script',
  [GENERIC_ANALYSIS_TEST_TYPE.family]: 'analysis:family.script',
  [GENERIC_ANALYSIS_TEST_TYPE.clinical]: 'analysis:clinical.script',
  [GENERIC_ANALYSIS_TEST_TYPE.functional]: 'analysis:functional.script',
  [GENERIC_ANALYSIS_TEST_TYPE.cognitive]: 'analysis:cognitive.script',
  [GENERIC_ANALYSIS_TEST_TYPE.nutritional]: 'analysis:nutritional.script',
  [GENERIC_ANALYSIS_TEST_TYPE.social]: 'analysis:social.script',
  [GENERIC_ANALYSIS_TEST_TYPE.summary]: null,
  [GENERIC_ANALYSIS_TEST_TYPE.summary_executive]: null,
  [GENERIC_ANALYSIS_TEST_TYPE.pfeiffer]: null,
})

export const VALIDATE_ALL = 'all'
