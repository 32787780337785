import { useRouteState } from '@shared/utils'
import { UserAreaAgendaRouteState } from './state'
import { observer } from 'mobx-react-lite'
import { AvailabilityCalendar, Button, Form, SlotDate } from '@shared/ui'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SRErrorModal, SRSelect, SRSuccessModal, SRUserName } from '@sr/ui'
import { useLayoutContext } from '@sr/routes/common'
import { useTranslation } from 'react-i18next'
import {
  CampaignUserAgendaContainer,
  CampaignUserAgendaHeader,
  CampaignUserAgendaModalityContainer,
  CampaignUserAgendaSchedule,
  CampaignUserAgendaSubtitle,
  CampaignUserAgendaTitle,
  campaignUserAgendaButtonClasses,
  campaignUserAgendaFormClasses,
} from './styles'
import { useForm } from 'react-hook-form'
import { options } from './utils'
import { FORM_NAME } from './enums'
import { useSchema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'

enum AgendaModalType {
  error = 'error',
  success = 'success',
}

export const AgendaCampaign = observer(() => {
  const { id: annuityId, serviceId } = useParams()
  const { t } = useTranslation()
  const { isLoading, slots, annuity, scheduleFirstServiceAppointment, reload } =
    useRouteState(UserAreaAgendaRouteState, {
      annuityId,
      serviceId,
    })
  const [modalType, setModalType] = useState<AgendaModalType | null>(null)
  const [dateSelected, setDateSelected] = useState<SlotDate>({
    startDate: '',
    endDate: '',
  })

  const { setIsLoading } = useLayoutContext()

  const schema = useSchema(t)
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      [FORM_NAME.modality]: annuity?.analysisModality,
    },
  })

  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods

  const onSubmit = handleSubmit(async ({ modality }) => {
    const onError = () => {
      setModalType(AgendaModalType.error)
    }

    const onSuccess = () => {
      setModalType(AgendaModalType.success)
    }
    await scheduleFirstServiceAppointment({
      dateSelected,
      modality,
      onError,
      onSuccess,
    })
  })

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (annuity) {
      reset({
        [FORM_NAME.modality]: annuity?.analysisModality,
      })
    }
  }, [annuity])

  if (!annuity || isLoading) return

  return (
    <CampaignUserAgendaContainer>
      <CampaignUserAgendaHeader>
        <SRUserName fullName={annuity?.person?.fullName} />
      </CampaignUserAgendaHeader>

      <Form methods={methods} className={campaignUserAgendaFormClasses}>
        <CampaignUserAgendaModalityContainer>
          <CampaignUserAgendaTitle>
            {t('campaign.agenda.selectModality')}
          </CampaignUserAgendaTitle>
          <CampaignUserAgendaSubtitle>
            {t('campaign.agenda.modalityDescription')}
          </CampaignUserAgendaSubtitle>
          <SRSelect
            options={options(t)}
            name={FORM_NAME.modality}
            placeholder={t('campaign.agenda.selectModalityPlaceholder')}
            hasNoneOption
          />
        </CampaignUserAgendaModalityContainer>

        <CampaignUserAgendaSchedule>
          <CampaignUserAgendaTitle>
            {t('schedule.agenda.title')}
          </CampaignUserAgendaTitle>
          <CampaignUserAgendaSubtitle>
            {t('schedule.agenda.description')}
          </CampaignUserAgendaSubtitle>

          <AvailabilityCalendar
            dateSelected={dateSelected}
            setDateSelected={setDateSelected}
            slots={slots}
          />
        </CampaignUserAgendaSchedule>
        <Button
          className={campaignUserAgendaButtonClasses}
          onClick={onSubmit}
          disabled={
            !dateSelected.startDate ||
            !dateSelected.endDate ||
            !!errors[FORM_NAME.modality]
          }
        >
          {t('common:scheduleDate')}
        </Button>
      </Form>
      {modalType === AgendaModalType.error && <SRErrorModal onRetry={reload} />}

      {modalType === AgendaModalType.success && (
        <SRSuccessModal
          mainMessage={t('schedule.modal.confirmation.title')}
          secondaryMessage={t('schedule.modal.confirmation.userInfo', {
            userName: annuity?.person?.fullName,
            srName: annuity?.socialReferent?.fullName,
          })}
        />
      )}
    </CampaignUserAgendaContainer>
  )
})
