import { actions } from '@sr/routes/common'
import { flow, types } from 'mobx-state-tree'
import { BaseRouteState, Client } from '@shared/data-access'

export const AnalysisCareRouteState = BaseRouteState.named(
  'AnalysisCareRouteState'
)
  .props({
    policyId: types.string,
    client: types.maybeNull(types.reference(Client)),
  })
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('AnalysisCampaignsRouteState')
      try {
        yield self.loadClientByPolicy()
      } finally {
        self.removeLoading('AnalysisCampaignsRouteState')
      }
    }),
    ...actions(self),
  }))
