import { FORM_TYPE } from '@shared/ui'
import { SRInput } from '../sr-input'
import { timeInputContainerClassName } from '../styles'

export const SRTimeInput = ({
  startName,
  endName,
  disabled,
  startDisabled,
  endDisabled,
}) => {
  return (
    <div className={timeInputContainerClassName}>
      <SRInput
        name={startName}
        type={FORM_TYPE.time}
        disabled={disabled || startDisabled}
        className="w-1/2"
      />
      <SRInput
        name={endName}
        type={FORM_TYPE.time}
        disabled={disabled || endDisabled}
        className="w-1/2"
      />
    </div>
  )
}
