export const UNAVAILABLE_SLOTS_TYPES = Object.freeze({
  medicalAppointment: 0,
  vacation: 1,
  leave: 2,
  reducedSchedule: 3,
  other: 4,
})

export const UNAVAILABLE_SLOT_TRANSLATION = Object.freeze({
  [UNAVAILABLE_SLOTS_TYPES.reducedSchedule]: 'unavailableSlot.reducedSchedule',
  [UNAVAILABLE_SLOTS_TYPES.medicalAppointment]:
    'unavailableSlot.medicalAppointment',
  [UNAVAILABLE_SLOTS_TYPES.vacation]: 'unavailableSlot.vacation',
  [UNAVAILABLE_SLOTS_TYPES.leave]: 'unavailableSlot.leave',
  [UNAVAILABLE_SLOTS_TYPES.other]: 'unavailableSlot.other',
})
