import tw from 'tailwind-styled-components'

export const UserListItemContainer = tw.div`
    flex
    justify-between
    items-center
    gap-2
    text-lg
    leading-6
    p-2.5
    border-b
    border-gainsboro
    mb-2
    cursor-pointer
`

export const UserListItemName = tw.div`
    flex
    items-center
    gap-2
`

export const UserListItemState = tw.div`
    text-policy-state
`
