import { FC } from 'react'
import { EventSchedule } from '@carbon/icons-react'
import { AppointmentFields, AppointmentHeadersArray } from './enums'
import { useTranslation } from 'react-i18next'
import {
  appointmentHeaders,
  appointmentRowBorders,
  appointmentRowTypeItem,
  appointmentSectionTitle,
  appointmentTable,
} from './styles'
import { AppointmentRow } from './appointment-row'


interface Item {
  title?: string
  type?: string
  dateHour?: string
  opName?: string
  modality?: string
  place?: string
  dataToModify?: object
}

interface AppointmentSectionProps {
  appointments: Item[]
  sectionTitle: string
  isPast?: boolean
  isCare?: boolean
  onClick?: (dataToModify?: { [key: string]: any }) => void
}

export const AppointmentSection: FC<AppointmentSectionProps> = ({
  appointments,
  sectionTitle,
  isPast,
  isCare,
  onClick,
}) => {
  const { t } = useTranslation()
  const hasAppointments = appointments.length > 0
  const filteredAppointmentHeadersArray = AppointmentHeadersArray.filter(
    (header) => isCare || header.type !== AppointmentFields.place,
  )

  return (
    <>
      <h2 className={appointmentSectionTitle}>{t(sectionTitle)}</h2>
      <table className={appointmentTable}>
        <thead className={appointmentRowBorders}>
          {hasAppointments && (
            <tr>
              {filteredAppointmentHeadersArray.map((header) => (
                <th
                  key={header.text}
                  className={`${header.width} ${appointmentHeaders}`}
                >
                  {t(header.text)}
                </th>
              ))}
            </tr>
          )}
        </thead>
        <tbody>
          {appointments.map((appointment, index) => (
            <AppointmentRow
              key={index}
              appointment={appointment}
              isPast={isPast}
              isCare={isCare}
              onClick={onClick}
            />
          ))}
          {!hasAppointments && (
            <tr>
              <td
                colSpan={AppointmentHeadersArray.length + 1}
                className={appointmentRowTypeItem}
              >
                <div className='flex'>
                  <EventSchedule width={25} height={25} className="mr-2" />
                  {t('appointmentManager.noDates')}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}
