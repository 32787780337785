import { SRSelect, SRSelectOptions } from '@sr/ui'
import { ServiceBlockFormValues, TRANSLATION_BASE_KEY } from '../enums'
import { useTranslation } from 'react-i18next'
import { BUTTON_SIZES, Button } from '@shared/ui'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import { useFormContext } from 'react-hook-form'
import { FC } from 'react'

type AcceptSelectProps = {
  action: (serviceId: string) => void
  serviceOptions: SRSelectOptions
}

export const AcceptSelect: FC<AcceptSelectProps> = ({
  action,
  serviceOptions,
}) => {
  const { t } = useTranslation()

  const { isLoading } = useLayoutContext()
  const { watch } = useFormContext()

  const acceptedValue = watch(ServiceBlockFormValues.serviceType)

  const onAccept = () => {
    action?.(acceptedValue)
  }

  return (
    <>
      <SRSelect
        placeholder={t(`${TRANSLATION_BASE_KEY}.form.serviceAcceptPlaceholder`)}
        name={ServiceBlockFormValues.serviceType}
        options={serviceOptions}
      />
      <Button
        onClick={onAccept}
        size={BUTTON_SIZES.small}
        disabled={isLoading || !acceptedValue}
      >
        {t(`${TRANSLATION_BASE_KEY}.form.schedule`)}
      </Button>
    </>
  )
}
