import { useTranslation } from 'react-i18next'
import { CountDown } from '../../../../../count-down'
import { CountDownText, CountDownUnavailableWrapper } from './styles'

export const CountDownUnavailable = ({ startDate }) => {
  const { t } = useTranslation()

  return (
    <div className={CountDownUnavailableWrapper}>
      <div className={CountDownText}>
        {t('common:videoCall.textUnavailable')}
      </div>
      <CountDown date={startDate} />
    </div>
  )
}
