import { getDateFromNow } from '@shared/utils'
import { FC, useState } from 'react'
import {
  chatBubbleClassName,
  NotificationItemBubble,
  NotificationItemBubbleContainer,
  NotificationItemContainer,
  NotificationItemContentContainer,
  NotificationItemDate,
  NotificationItemName,
} from './styles'
import { useNavigate } from 'react-router-dom'
import { InitialsAvatar } from '@shared/ui'
import { MiniMenu } from './components'
import { observer } from 'mobx-react-lite'

export type NotificationItemType = {
  sid: string
  name: string
  contentObject: { name: string; surname: string }
  lastUpdatedAt: Date
  personPolicyId: string
  unreadMessagesCount: number
}

export type NotificationItemProps = {
  item: NotificationItemType
  reload: () => void
  updateItemMessages: ({
    roomSid,
    count,
  }: {
    roomSid: string
    count: number
  }) => void
}

export const NotificationItem: FC<NotificationItemProps> = observer(
  ({ item, reload, updateItemMessages }) => {
    const navigate = useNavigate()
    const [isHovered, setIsHovered] = useState(false)

    const onClick = () => {
      const chatPath = `/user-area/${item.personPolicyId}/monitoring-area/chat`

      updateItemMessages({ roomSid: item.sid, count: 0 })
      reload()

      if (window.location.pathname.includes('/monitoring-area/')) {
        navigate(chatPath)
        window.location.reload()
        return
      }

      navigate(chatPath)
    }

    const fullName = `${item.contentObject.name} ${item.contentObject.surname}`

    return (
      <NotificationItemContainer
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <NotificationItemContentContainer>
          <InitialsAvatar
            name={fullName}
            className={chatBubbleClassName}
            background="fff"
          />
          <NotificationItemName>{fullName}</NotificationItemName>
        </NotificationItemContentContainer>
        {item.unreadMessagesCount ? (
          <NotificationItemBubbleContainer>
            <NotificationItemBubble>
              {item.unreadMessagesCount}
            </NotificationItemBubble>
          </NotificationItemBubbleContainer>
        ) : isHovered ? (
          <MiniMenu
            roomSid={item.sid}
            reload={reload}
            updateItemMessages={updateItemMessages}
          />
        ) : (
          <NotificationItemDate>
            {getDateFromNow(item.lastUpdatedAt)}
          </NotificationItemDate>
        )}
      </NotificationItemContainer>
    )
  },
)
