import { Document, Font, StyleSheet } from '@react-pdf/renderer'
import { SectionOne } from './section-one'
import { SectionTwo } from './section-two'
import { SectionThree } from './section-three'
import { SectionFour } from './section-four'
import { SectionFive } from './section-five'
import { SectionSix } from './section-six'
import { SectionSeven } from './section-seven'
import { SectionEight } from './section-eight'
import { SectionNine } from './section-nine'
import { SectionTen } from './section-ten'
import { FC } from 'react'
import { ReportData } from '../report-pdf/types'
import { ReportPdfProvider } from './report-pdf-provider'
import { ReportType, ReportTypeName } from '../report-pdf/enums'

type Props = {
  data: ReportData
  type: ReportType
}

export const SrFollowUpReportPdf: FC<Props> = ({ data, type }) => {
  Font.register({
    family: 'Montserrat',
    fonts: [
      // https://www.jsdelivr.com/package/npm/@typopro/web-montserrat?nav=config
      {
        src: 'https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Thin.ttf',
        fontWeight: 300,
      },
      {
        src: 'https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Regular.ttf',
        fontWeight: 400,
      },
      {
        src: 'https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Italic.ttf',
        fontStyle: 'italic',
      },
      {
        src: 'https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-SemiBold.ttf',
        fontWeight: 600,
      },
      {
        src: 'https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Bold.ttf',
        fontWeight: 700,
      },
      {
        src: 'https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-ExtraBold.ttf',
        fontWeight: 800,
      },
      {
        src: 'https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Black.ttf',
        fontWeight: 900,
      },
    ],
  })

  Font.registerHyphenationCallback((word) => {
    return [word]
  })

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      fontFamily: 'Montserrat',
    },
  })

  const props = { styles: styles.page }

  return (
    <Document title="Plan de Prevención" {...props}>
      <ReportPdfProvider type={ReportTypeName[type]} data={data}>
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <SectionFive />
        <SectionSix />
        <SectionSeven />
        <SectionEight />
        <SectionNine />
        <SectionTen />
      </ReportPdfProvider>
    </Document>
  )
}
