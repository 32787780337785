import { FC } from 'react'
import { ListItem } from './error-list'
import { useTranslation } from 'react-i18next'
import { BUTTON_SIZES, Button } from '@shared/ui'
import { errorItemContainerClasses } from './styles'
import { GA_TEST_TYPE_NAME } from '@sr/routes/common/analysis/analysis'

type TestParams = {
  page: number
  id: string
}

type ErrorItemProps = {
  test: ListItem
  onSelectTest: (testParams: TestParams) => void
}

export const ErrorItem: FC<ErrorItemProps> = ({ test, onSelectTest }) => {
  const { t } = useTranslation()

  const testName = t(GA_TEST_TYPE_NAME[test.testType])

  const navigateToPage = () => {
    onSelectTest({ page: test.testOrder, id: test.id })
  }

  return (
    !test.isFilled && (
      <li className="list-item">
        <div className={errorItemContainerClasses}>
          <span>
            {t('analysis:pdfPreview.inCompleted', { name: testName })}
          </span>
          <Button onClick={navigateToPage} size={BUTTON_SIZES.small}>
            {t('analysis:pdfPreview.button')}
          </Button>
        </div>
      </li>
    )
  )
}
