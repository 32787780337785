import { Button } from '@shared/ui'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ErrorBox, ErrorContainer, ErrorText } from './styles'

export const ErrorPage = ({ status }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goToPreviousPage = () => {
    navigate(-1)
  }

  return (
    <ErrorContainer>
      <ErrorBox>
        <ErrorText>{t('error.generic')}</ErrorText>
        <Button testId="error-button" onClick={goToPreviousPage}>
          {t('error.button')}
        </Button>
      </ErrorBox>
    </ErrorContainer>
  )
}
