import { BUTTON_SIZES, Button } from '@shared/ui'
import { PhoneFilled } from '@carbon/icons-react'
import { useEffect, useState } from 'react'
import {
  ButtonTimerClasses,
  ButtonTimerContent,
  ButtonTimerContentPhone,
  ButtonTimerContentText,
} from './styles'
import { ModalCallType } from './components/modal-call-type'
import { ModalCallReason } from './components/modal-call-reason'
import { useTranslation } from 'react-i18next'

export const ButtonTimer = ({ onEnd }) => {
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(true)
  const [time, setTime] = useState(0)

  const [showModalCallType, setShowModalCallType] = useState(false)
  const [showModalCallReason, setShowModalCallReason] = useState(false)

  const [callType, setCallType] = useState('')

  const { t } = useTranslation()

  useEffect(() => {
    let interval = null

    if (isActive && !isPaused) {
      interval = setInterval(() => {
        setTime((time) => time + 10)
      }, 10)
    } else {
      clearInterval(interval)
    }
    return () => {
      clearInterval(interval)
    }
  }, [isActive, isPaused])

  const startTimer = (callType) => {
    setCallType(callType)
    setShowModalCallType(false)
    setIsActive(true)
    setIsPaused(false)
  }

  const openModalCallType = () => {
    setShowModalCallType(true)
  }

  const openModalCallReason = () => {
    setShowModalCallReason(true)
  }

  const resetTimer = (callReason) => {
    onEnd && onEnd({ time, callReason, callType })
    setShowModalCallReason(false)
    setIsActive(false)
    setTime(0)
  }

  const onCancel = () => {
    showModalCallType && setShowModalCallType(false)
    if (showModalCallReason) {
      setShowModalCallReason(false)
      setIsActive(false)
      setTime(0)
    }
  }

  return (
    <div className="flex flex-col gap-4">
      {showModalCallType && (
        <ModalCallType onCancel={onCancel} onClick={startTimer} />
      )}
      {showModalCallReason && (
        <ModalCallReason onCancel={onCancel} onClick={resetTimer} />
      )}

      <Button
        onClick={time ? openModalCallReason : openModalCallType}
        size={BUTTON_SIZES.small}
        rounded
        className={ButtonTimerClasses}
      >
        <ButtonTimerContent>
          <ButtonTimerContentText>
            {time ? (
              <>
                <span>{t('userArea.callLog.button.recording')}</span>
                <div>
                  <span className="text-secondary">
                    {('0' + Math.floor((time / 60000) % 60)).slice(-2)}:
                  </span>
                  <span className="text-secondary">
                    {('0' + Math.floor((time / 1000) % 60)).slice(-2)}
                  </span>
                </div>
              </>
            ) : (
              t('userArea.callLog.button.startRecord')
            )}
          </ButtonTimerContentText>
          <ButtonTimerContentPhone>
            <PhoneFilled
              className={time ? 'text-secondary' : 'text-primary'}
              size={35}
            />
          </ButtonTimerContentPhone>
        </ButtonTimerContent>
      </Button>
    </div>
  )
}
