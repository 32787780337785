import { BaseRouteState, Policy, SocialReferent } from '@shared/data-access'
import { flow, getEnv, types } from 'mobx-state-tree'
import {
  getActiveUserCards,
  getPreCSUserCards,
  getRejectedCards,
} from './cards'
import { actions } from '../../common'

export const UserAreaCareRouteState = BaseRouteState.named(
  'UserAreaCareRouteState',
)
  .props({
    user: types.maybeNull(types.reference(SocialReferent)),
    policyId: types.maybeNull(types.string),
    policy: types.maybeNull(types.reference(Policy)),
  })
  .views((self) => ({
    get nonActiveCards() {
      return self.policy?.isRejected
        ? getRejectedCards({
            isLoading: self.isLoading,
            rejectedCallback: self.createRejectedLetter,
          })
        : getPreCSUserCards({
            hasScheduledDate: self.policy?.hasScheduledDate,
            isCoordinator: self.user?.isCoordinator,
            userId: self.user?.id,
            srId: self.policy?.socialReferent?.id,
          })
    },
    get userCards() {
      return self.policy?.isActive
        ? getActiveUserCards({
            isCoordinator: self.user?.isCoordinator,
            userId: self.user?.id,
            srId: self.policy?.socialReferent?.id,
          })
        : self.nonActiveCards
    },
    get showReDoButton() {
      return (
        self.policy?.isRequest &&
        self.user?.isCoordinator &&
        self.policy.firstAppointmentDate
      )
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('UserAreaCareRouteState')
      try {
        yield self.loadUserInfo()
        yield self.loadPolicy()
      } finally {
        self.removeLoading('UserAreaCareRouteState')
      }
    }),
    unblockCS: flow(function* unblockCS() {
      self.setLoading('unblockCS')
      try {
        yield getEnv(self).client.post(
          `policies/${self.policyId}/alter_cs_result/`,
        )
        yield self.loadPolicy()
      } finally {
        self.removeLoading('unblockCS')
      }
    }),
    createRejectedLetter() {
      self.setLoading('createRejectedLetter')
      try {
        const rejectionUrl = `policies/${self.policyId}/download_rejection_letter/`
        window.open(
          `${process.env.NX_SOCIAL_REFERENT_API_URL}${rejectionUrl}`,
          '_blank',
        )
      } finally {
        self.removeLoading('createRejectedLetter')
      }
    },
    ...actions(self),
  }))
