import { flow, getRoot, types } from 'mobx-state-tree'
import { actions } from '@sr/routes/common'
import { BaseRouteState, PersonService } from '@shared/data-access'

export const ServiceRouteState = BaseRouteState.named('ServiceRouteState')
  .props({
    policyId: types.maybeNull(types.string),
    personServices: types.maybeNull(
      types.array(types.reference(PersonService))
    ),
  })
  .actions((self) => ({
    afterAttach() {
      self.loadPersonServices()
    },
    loadPersonServices: flow(function* loadPersonServices() {
      self.setLoading('ServiceRouteState')
      try {
        const personServices = yield getRoot(self).PersonServiceStore.fetchAll({
          include: 'service',
          noClear: true,
          customUrl: `/policies/${self.policyId}/services`,
        })

        if (personServices) {
          self.personServices = personServices
        }
      } finally {
        self.removeLoading('ServiceRouteState')
      }
    }),
    ...actions(self),
  }))
