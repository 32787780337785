import { SrCampaignType } from '@shared/ui'
import { SRUserName } from '../sr-user-name'
import { annuityUserNameContainerClasses } from './styles'
import { Annuity } from '@sr/data-access'

export const SRAnnuityUserName = ({ annuity }: { annuity?: Annuity }) => {
  return (
    <div className={annuityUserNameContainerClasses}>
      <SRUserName fullName={annuity?.person?.fullName || ''} />
      <SrCampaignType annuity={annuity} />
    </div>
  )
}
