import { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { createContext } from 'react'

const ModalContext = createContext(null)

const ModalProvider = ({
  children,
  eventId = '',
  policyId = '',
  personId = '',
  appointmentType: defaultType = 0,
  appointmentDay: defaultDay = new Date(),
  unavailableSlotType,
  startTime,
  endTime,
  modality,
  meetingUrl,
  onGenerateRoom,
  appointmentNeedsTrackingChange,
  noDigital,
  managerOfficeDirection,
  street,
  officeControl,
}) => {
  const [appointmentType, setAppointmentType] = useState(defaultType)
  const [appointmentDay, setAppointmentDay] = useState(defaultDay)
  const [cancelEvent, setCancelEvent] = useState(false)

  const onChangeAppointmentType = (type) => {
    setAppointmentType(type)
  }

  const onChangeAppointmentDay = (day) => {
    setAppointmentDay(day)
  }
  const toggleCancelEvent = () => {
    setCancelEvent(!cancelEvent)
  }

  return (
    <ModalContext.Provider
      value={{
        eventId,
        policyId,
        personId,
        appointmentType,
        appointmentDay,
        cancelEvent,
        unavailableSlotType,
        startTime,
        endTime,
        modality,
        meetingUrl,
        onGenerateRoom,
        needsTracking: appointmentNeedsTrackingChange,
        onChangeAppointmentType,
        onChangeAppointmentDay,
        toggleCancelEvent,
        noDigital,
        managerOfficeDirection,
        street,
        officeControl,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
  eventId: PropTypes.string,
  policyId: PropTypes.string,
  personId: PropTypes.string,
  appointmentType: PropTypes.number,
  appointmentDay: PropTypes.instanceOf(Date),
  unavailableSlotType: PropTypes.any,
  startTime: PropTypes.any,
  endTime: PropTypes.any,
  modality: PropTypes.any,
  meetingUrl: PropTypes.string,
  onGenerateRoom: PropTypes.func,
  appointmentNeedsTrackingChange: PropTypes.bool,
  noDigital: PropTypes.bool,
  managerOfficeDirection: PropTypes.string,
  street: PropTypes.string,
  officeControl: PropTypes.bool,
}

ModalProvider.defaultProps = {
  eventId: '',
  policyId: '',
  personId: '',
  appointmentType: 0,
  appointmentDay: new Date(),
  unavailableSlotType: undefined,
  startTime: undefined,
  endTime: undefined,
  modality: undefined,
  meetingUrl: '',
  onGenerateRoom: null,
  needsTracking: false,
  noDigital:false,
  officeControl:false
}

const useModalContext = () => {
  return useContext(ModalContext)
}

export { ModalProvider, useModalContext }
