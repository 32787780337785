import { BUTTON_TYPES } from '@shared/ui'
import React, { Ref } from 'react'
import { QD_BUTTON_SIZES, QD_BUTTON_TYPES, QD_BUTTON_VARIANTS } from './enums'
import { btnPrimary, btnSecondary, btnTertiary } from './styles'

interface ButtonProps {
  label?: string
  variant?: QD_BUTTON_VARIANTS
  onClick: () => void
  children: React.ReactNode
  ref?: Ref<HTMLButtonElement>
  testId?: string
  title?: string
  type?: BUTTON_TYPES
  buttonType?: QD_BUTTON_TYPES
  disabled?: boolean
  size?: QD_BUTTON_SIZES
  loading?: boolean
  icon?: {
    icon: React.ReactNode
    iconPosition: 'left' | 'right'
  }
  className?: string
}

const DSButton: React.FC<ButtonProps> = ({
  label,
  buttonType,
  icon,
  children,
  variant = QD_BUTTON_VARIANTS.primary,
  onClick,
  disabled,
  testId,
  title,
  type,
  ref,
  size = QD_BUTTON_SIZES.md,
  loading = false,
  className,
}) => {
  const variantStyles = () => {
    switch (variant) {
      case QD_BUTTON_VARIANTS.primary:
        return btnPrimary
      case QD_BUTTON_VARIANTS.secondary:
        return btnSecondary
      case QD_BUTTON_VARIANTS.tertiary:
        return btnTertiary
      default:
        return btnPrimary
    }
  }

  const variantSizes = () => {
    switch (size) {
      case QD_BUTTON_SIZES.sm:
        return 'h-6'
      case QD_BUTTON_SIZES.md:
        return 'h-10'
      case QD_BUTTON_SIZES.lg:
        return 'h-12'
      default:
        return 'h-10 '
    }
  }

  return (
    <button
      ref={ref}
      data-testid={testId}
      type={type || BUTTON_TYPES.button}
      disabled={disabled}
      className={`${variantStyles()} ${variantSizes()} ${className} `}
      onClick={onClick}
      title={title}
    >
      {children}
    </button>
  )
}

export default DSButton
