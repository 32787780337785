import { AnnuityModel } from '@sr/data-access'
import { actionsCampaign } from '@sr/routes/common'
import { flow, types } from 'mobx-state-tree'
import { BaseRouteState } from '@shared/data-access'

export const AnalysisCampaignRouteState = BaseRouteState.named(
  'AnalysisCampaignRouteState'
)
  .props({
    annuityId: types.string,
    annuity: types.maybeNull(types.reference(AnnuityModel)),
  })
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('AnalysisCampaignsRouteState')
      try {
        yield self.loadAnnuity()
      } finally {
        self.removeLoading('AnalysisCampaignsRouteState')
      }
    }),
    ...actionsCampaign(self),
  }))
