import {
  POLICY_STATE_ACCEPTED_UNIFIED_NAME,
  POLICY_STATE_COLOR,
  POLICY_STATE_NAME,
} from '@shared/data-access'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type PolicyStateCellProps = {
  state: any
  row: any
}

export const PolicyStateCell: FC<PolicyStateCellProps> = ({ state, row }) => {
  const { t } = useTranslation()
  return (
    <div
      className={POLICY_STATE_COLOR[state as keyof typeof POLICY_STATE_COLOR]}
    >
      {row?.original?.isAccepted
        ? t(
            `userList.policyStates.${
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              POLICY_STATE_ACCEPTED_UNIFIED_NAME[state][row?.original?.subState]
            }`,
          )
        : t(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            `userList.policyStates.${POLICY_STATE_NAME[state]}`,
          )}
    </div>
  )
}
