import { FC } from 'react'

type SRRadioButtonProps = {
  label: string
  className?: string
  value: string | number | boolean | null | undefined
  onChange?: (value: string | number | boolean | null, label: string) => void
  onBlur?: () => void
  name: string
  checked?: boolean
}

export const SRBlockRadioButton: FC<SRRadioButtonProps> = ({
  label,
  className = '',
  value,
  onChange,
  onBlur,
  name,
  checked,
}) => {
  const handleOnChange = () => {
    if (onChange && value !== undefined) {
      onChange(value, label)
    }
  }

  return (
    <div className={`flex items-center gap-2 py-1 ${className}`}>
      <label className="inline-flex items-center space-x-2 cursor-pointer">
        <input
          type="radio"
          name={label}
          id={name}
          value={value !== null ? value?.toString() : ''}
          checked={checked}
          onChange={handleOnChange}
          className="w-4 h-4 accent-[#004039]"
        />
        <span className="text-gray-700">{label}</span>
      </label>
    </div>
  )
}
