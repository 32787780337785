import { Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { FC } from 'react'

type BannerProps = {
  children?: JSX.Element | JSX.Element[]
  areaColor?: string
  title?: string
}

export const Banner: FC<BannerProps> = ({ children, areaColor, title }) => {
  const bgColor = areaColor ? `bg-${areaColor}` : 'bg-primary'

  return (
    <View
      style={tw(
        `flex flex-col justify-center items-center text-white text-center font-bold w-full px-12 ${bgColor} mt-4 min-h-[60px]`,
      )}
      wrap={false}
    >
      {title ? <Text>{title}</Text> : children}
    </View>
  )
}
