import { getDateFormatted } from '@shared/utils'
import { useTranslation } from 'react-i18next'

import './style.css'

export const SRBigCalendarEventMonth = ({ event = {} }) => {
  const { t } = useTranslation()
  const { start, appointmentType } = event

  if (!start || !appointmentType) return

  const startDate = getDateFormatted(start, 'HH:mm')

  return (
    <div className="pl-1 flex gap-1 items-center">
      <div
        className={`bg-${appointmentType.color}-base rounded-full w-[14px] h-[12px]`}
      />
      <div className="flex gap-1 text-[10px] w-full">
        <p>{startDate}</p>
        <p className="eventName">{t(appointmentType.text)}</p>
      </div>
    </div>
  )
}
