import {
  User,
  CalendarAdd,
  Report,
  Headset,
  WatsonHealthTextAnnotationToggle,
  ReportData,
} from '@carbon/icons-react'
import { Images } from '@shared/assets'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { t } from 'i18next'

const TRANSLATION_BASE_KEY = 'userArea'

const InitialReportIcon = ({
  width,
  height,
}: {
  width: number
  height: number
}) => <Images.InitialReport width={width} height={height} />

export const userCard = () => ({
  icon: <User width={60} height={60} />,
  title: t(`${TRANSLATION_BASE_KEY}.personal.title`),
  linkText: t(`${TRANSLATION_BASE_KEY}.personal.link`),
  path: `${ROUTE_PATH.userProfile}`,
})

export const genericAnalysisListCard = () => ({
  icon: <WatsonHealthTextAnnotationToggle width={60} height={60} />,
  title: t(`${TRANSLATION_BASE_KEY}.genericAnalysis.title`),
  linkText: t(`${TRANSLATION_BASE_KEY}.genericAnalysis.link`),
  path: `${ROUTE_PATH.newTest}`,
})

export const monitoringAreaCard = () => ({
  icon: <InitialReportIcon width={60} height={60} />,
  title: t(`${TRANSLATION_BASE_KEY}.monitoringArea.title`),
  linkText: t(`${TRANSLATION_BASE_KEY}.monitoringArea.link`),
  path: `${ROUTE_PATH.monitoringArea}/${ROUTE_PATH.monitoringChat}`,
})

export const reportCard = () => ({
  icon: <Report width={60} height={60} />,
  title: t(`${TRANSLATION_BASE_KEY}.subscription.title`),
  linkText: t(`${TRANSLATION_BASE_KEY}.subscription.link`),
  path: `${ROUTE_PATH.preSubscriptionControl}`,
})

export const subscriptionControlCard = () => ({
  icon: <CalendarAdd width={60} height={60} />,
  title: t(`${TRANSLATION_BASE_KEY}.subscription.title`),
  linkText: t(`${TRANSLATION_BASE_KEY}.subscription.link`),
  path: `${ROUTE_PATH.preSubscriptionControl}`,
})

export const rejectedLetterCard = ({
  isLoading,
  rejectedCallback,
}: {
  isLoading: boolean
  rejectedCallback: () => void
}) => ({
  icon: <CalendarAdd width={60} height={60} />,
  title: t(`${TRANSLATION_BASE_KEY}.subscription.title`),
  linkText: t(`${TRANSLATION_BASE_KEY}.rejected.link`),
  action: rejectedCallback,
  disabled: isLoading,
})

export const monitoringAreaPreCsCard = () => ({
  icon: <Headset width={60} height={60} />,
  title: t(`${TRANSLATION_BASE_KEY}.monitoringAreaPreCs.title`),
  linkText: t(`${TRANSLATION_BASE_KEY}.monitoringAreaPreCs.link`),
  path: `${ROUTE_PATH.monitoringArea}/${ROUTE_PATH.monitoringCommunications}`,
  pathParams: { preCS: true },
})

export const appointmentManager = () => ({
  icon: <ReportData width={60} height={60} />,
  title: t(`${TRANSLATION_BASE_KEY}.appointmentManager.title`),
  linkText: t(`${TRANSLATION_BASE_KEY}.appointmentManager.link`),
  path: `${ROUTE_PATH.appointmentManager}`,
})

export const getActiveUserCards = () => {
  const cards = [
    userCard(),
    monitoringAreaCard(),
    genericAnalysisListCard(),
    reportCard(),
    appointmentManager(),
  ]

  return cards
}

export const getPreCSUserCards = ({
  hasScheduledDate = false,
}: {
  hasScheduledDate: undefined | boolean
  isCoordinator: boolean
  srId: string
  userId: string
}) => {
  const cards = !hasScheduledDate
    ? [userCard(), monitoringAreaPreCsCard(), appointmentManager()]
    : [
        userCard(),
        subscriptionControlCard(),
        monitoringAreaPreCsCard(),
        appointmentManager(),
      ]

  return cards
}

export const getRejectedCards = ({
  isLoading,
  rejectedCallback,
}: {
  isLoading: boolean
  rejectedCallback: () => void
}) => [userCard(), rejectedLetterCard({ isLoading, rejectedCallback })]
