import tw from 'tailwind-styled-components'

export const ActionsBlock = tw.div`
    flex
    flex-col
    gap-2
    mb-4
`

export const ActionsBlockTitle = tw.div`
    text-base
    font-semibold
    text-black
    mb-4
`

export const ActionsBlockContent = tw.div`
    grid
    grid-cols-3
    gap-2
`

export const ActionsBlockContentTwoCols = tw.div`
    grid
    grid-cols-2
    gap-2
`
