import tw from 'tailwind-styled-components'

interface SRNotificationsPortableProp {
  $portable?: boolean
}

export const SRNotificationsTableContainer = tw.div<SRNotificationsPortableProp>`
    w-full
    flex
    flex-col
    ${({ $portable }) => ($portable ? 'gap-0' : 'gap-4')}
`

export const SRNotificationsTableHeader = tw.div<SRNotificationsPortableProp>`
    bg-white
    py-1
    px-3.5
    text-primary
    font-medium
    leading-6
    ${({ $portable }) => ($portable ? '' : 'rounded border border-zinc-300')}
    flex
    flex-col
    gap-4
`

export const SRNotificationsTableContent = tw.div<SRNotificationsPortableProp>`
    bg-white
    ${({ $portable }) =>
      $portable
        ? 'max-h-[200px] overflow-auto'
        : 'rounded border border-zinc-300 shadow-md shadow-[rgba(0, 0, 0, 0.25)]'}
`

export const SRNotificationsTableTitle = tw.div`
  flex
  flex-col
  gap-2
`

export const SRNotificationsTableTabsContainer = tw.div`
  flex
  gap-2
  text-black
  text-sm
`

interface SRNotificationsTableTabsButtonProp {
  $isSelected?: boolean
}

export const SRNotificationsTableTabsButton = tw.div<SRNotificationsTableTabsButtonProp>`
  cursor-pointer
  ${({ $isSelected }) => ($isSelected ? 'border-b-2 border-secondary' : '')}
`
