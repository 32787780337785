import _ from 'lodash'

export const flattenObject = (
  obj: Record<string, any>,
  result: Record<string, any> = {},
  isRoot = true,
): Record<string, any> => {
  _.forEach(obj, (value: any, key: string) => {
    if (_.isObject(value) && !_.isArray(value)) {
      flattenObject(value, result, false) // Recurse if value is an object, mark as not root
    } else if (!isRoot) {
      // Only add key if not at root level
      result[key] = value // Assign to result without prefix
    }
  })
  return result
}
