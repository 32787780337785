import { Text } from '@react-pdf/renderer'
import { tw } from '../../utils'

export const TextTitle = ({ style, children }) => {
  return (
    <Text
      style={{
        ...tw('text-primary leading-5 font-normal'),
        ...style,
        fontSize: '28px',
      }}
    >
      {children}
    </Text>
  )
}
