import { FC, useState } from 'react'
import { EmailNew, OverflowMenuHorizontal } from '@carbon/icons-react'
import { useTranslation } from 'react-i18next'
import { MiniMenuItemContainer } from './styles'
import { useMessages } from '@shared/utils'
import { observer } from 'mobx-react-lite'

type MiniMenuProps = {
  roomSid: string
  reload: () => void
  updateItemMessages: ({
    roomSid,
    count,
  }: {
    roomSid: string
    count: number
  }) => void
}

export const MiniMenu: FC<MiniMenuProps> = observer(
  ({ roomSid, reload, updateItemMessages }) => {
    const { t } = useTranslation()
    const [isMenuOpened, setIsMenuOpened] = useState(false)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { markConversationAsUnread } = useMessages()

    const handleMenuClick = (
      e: React.MouseEvent<HTMLDivElement>,
      bool: boolean,
    ) => {
      e.stopPropagation()
      setIsMenuOpened(bool)
    }

    const handleClickOnMarkUnread = () => {
      markConversationAsUnread({ roomSid })
      updateItemMessages({ roomSid, count: 1 })
      setIsMenuOpened(false)
    }

    return isMenuOpened ? (
      <div onClick={(e) => handleMenuClick(e, false)}>
        <MiniMenuItemContainer onClick={handleClickOnMarkUnread}>
          <EmailNew />
          {t('notifications.chat.markAsUnread')}
        </MiniMenuItemContainer>
      </div>
    ) : (
      <div onClick={(e) => handleMenuClick(e, true)}>
        <OverflowMenuHorizontal />
      </div>
    )
  },
)
