import {
  EVENT_FORM,
  getAppointmentModalityOptionsGeneric,
  getAppointmentOptions,
  getSRAppointmentOptions,
} from '../../common'
import { SRInput, SRSelect, SRTimeInput } from '@sr/ui'
import { FORM_TYPE } from '@shared/ui'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const GenericForm = observer(
  ({ isCoordinator, isReadOnly, isEdit, annuityList, isEditing }) => {
    const { t } = useTranslation()

    const appointmentTypes =
      isCoordinator || isReadOnly
        ? getAppointmentOptions(isEdit)
        : getSRAppointmentOptions(isEdit)

    const annuitiesEmptyError =
      !annuityList.length && t('agenda.formModal.formError.noUserList')

    const isBlocked = isEdit && !isEditing

    return (
      <>
        <SRSelect
          name={EVENT_FORM.appointmentType}
          options={appointmentTypes}
          disabled={isReadOnly || isBlocked}
        />
        <SRSelect
          name={EVENT_FORM.appointmentModality}
          options={getAppointmentModalityOptionsGeneric()}
          disabled={isReadOnly || isBlocked}
        />
        <SRInput
          name={EVENT_FORM.appointmentDay}
          type={FORM_TYPE.date}
          min={new Date().toISOString().split('T')[0]}
          disabled={isReadOnly || isBlocked}
        />
        <SRTimeInput
          startName={EVENT_FORM.startDate}
          endName={EVENT_FORM.endDate}
          disabled={isReadOnly || isBlocked}
          endDisabled
        />
        <SRSelect
          name={EVENT_FORM.formPersonId}
          options={annuityList}
          disabled={isReadOnly || isBlocked}
          error={annuitiesEmptyError}
        />
      </>
    )
  },
)
