export const LogoCaixaBank = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      version="1.1"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0 -1020.4)">
        <g transform="matrix(.2 0 0 .2 11.55 838.98)">
          <g
            transform="matrix(1.1303 0 0 1.1458 -325.37 560.34)"
            clip-rule="evenodd"
            fill-rule="evenodd"
          >
            <path
              d="m338.1 354.16s69.45 88.644 14.899 50.763c0 0-27.527-22.73-42.175-31.821 0 0-52.025 115.67-20.204-4.799 0 0-110.87-14.648-0.505-19.446 0 0-31.822-43.438 22.729-7.576 0 0 89.402-61.875 23.74 12.375l1.516 0.504z"
              fill="#4090ce"
            />
            <path
              d="m279 376.39c0 3.535-3.536 6.313-7.829 6.313s-7.577-2.778-7.577-6.313c0-3.283 3.284-6.062 7.577-6.062 4.293 1e-3 7.829 2.779 7.829 6.062z"
              fill="#ffc226"
            />
            <path
              d="m275.72 405.18c0 7.576-5.051 13.891-11.112 13.891-6.314 0-11.112-6.314-11.112-13.891s4.798-13.638 11.112-13.638c6.062 0 11.112 6.062 11.112 13.638z"
              fill="#f04924"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
