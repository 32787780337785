import React, { useRef, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { SRTextarea } from '@sr/ui'

import { useRouteState } from '@shared/utils'
import { configOptions } from './interfaces'

import { ProfileCareActionsRouteState } from '../../actions/care-actions/state'
import { Form } from '@shared/ui'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

interface NotesBlockProps {
  id?: string
  data?: (t: TFunction) => configOptions
  field: string
}
interface FormData {
  [key: string]: string[]
}

export const NotesBlock: React.FC<NotesBlockProps> = ({ id, data }) => {
  const { t } = useTranslation()

  const { id: policyId } = useParams()

  const { setClientPreferences } = useRouteState(ProfileCareActionsRouteState, {
    policyId,
  })

  const methodsChangePreferences = useForm<FormData>()
  const config = data ? data(t) : null
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [textareaValue, setTextareaValue] = useState<string>(
    (config?.options[0].value as string) || '',
  )

  const submit: SubmitHandler<FormData> = (data) => {
    setClientPreferences(data, id)
  }

  const handleBlur = () => {
    const formData = methodsChangePreferences.getValues()
    submit(formData)
  }

  const handleInputChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const newValue = e.currentTarget.value
    setTextareaValue(newValue)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }

    typingTimeoutRef.current = setTimeout(() => {
      const formData = methodsChangePreferences.getValues()
      submit(formData)
    }, 1000)
  }

  return (
    <>
      <Form
        methods={methodsChangePreferences}
        data-testid="section-notes-preferences"
        className="mb-3"
      >
        {config?.options && config.options.length > 0 ? (
          config.options.map((option, index) => (
            <span key={index}>{option.selected ? option.label : ''}</span>
          ))
        ) : (
          <span>{t('userArea.profile.actions.services.form.noInfo')}</span>
        )}
        <SRTextarea
          value={textareaValue}
          name="notes"
          isVisible
          onBlur={handleBlur}
          onInput={handleInputChange}
          className="font-normal text-base"
          placeholder={String(config?.options[0].value)}
        />
      </Form>
    </>
  )
}
