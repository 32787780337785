import { useEffect, useState } from 'react'

import { CarouselDots, Carousel } from '@shared/ui'

import { ScheduleGrid } from '../schedule-grid/schedule-grid'

interface CarouselAgendaProps {
  noDigital: boolean
  schedules: any[]
  dateSelected: Date | null
  previousDate: string | Date | null | undefined
  fieldName: string
  dateBannerClasses?: string
  isMod: boolean
  modifyDate: Date | null
  setModifyDate: any
  isSr: boolean
}

export const CarouselAgenda: React.FC<CarouselAgendaProps> = ({
  noDigital,
  schedules,
  dateSelected,
  previousDate,
  fieldName,
  isMod,
  modifyDate,
  setModifyDate,
  isSr,
}) => {
  const groupSchedules = () => {
    if (!noDigital) {
      const groupedByFortyNineItems = []
      for (let i = 0; i < schedules.length; i += 49) {
        groupedByFortyNineItems.push(schedules.slice(i, i + 49))
      }
      return groupedByFortyNineItems
    } else {
      const groupedBySlots = []
      let slot = []
      for (let i = 0; i < schedules.length; i++) {
        slot.push(schedules[i])
        if (slot.length === 14) {
          groupedBySlots.push(slot)
          slot = []
        }
      }
      if (slot.length > 0) {
        groupedBySlots.push(slot)
      }
      return groupedBySlots
    }
  }

  const groupedSchedules = groupSchedules()

  const [date, setDate] = useState<Date | null>(null)

  useEffect(() => {
    const newDate = isMod ? modifyDate : dateSelected
    setDate(newDate)
  }, [isMod, modifyDate, dateSelected])

  return (
    <Carousel DotsComponent={CarouselDots}>
      {groupedSchedules.map((group, index) => (
        <ScheduleGrid
          key={index}
          noDigital={noDigital}
          schedules={group}
          fieldName={fieldName}
          watchValue={previousDate}
          isMod={isMod}
          modifyDate={modifyDate}
          setModifyDate={setModifyDate}
        />
      ))}
    </Carousel>
  )
}
