import tw from 'tailwind-styled-components'

export const UserAreaWrapper = tw.div`
    flex
    flex-col
    justify-center
    items-center
    h-full
    py-10
    gap-5
`
export const UserAreaInfo = tw.div`
    flex
    gap-4
    self-start
`
