import { RootModel } from '@shared/data-access'
import { getRoot, types } from 'mobx-state-tree'
import { ContentObject } from '../typeCustom'

export const MODEL_NAME = 'GenericAnalysisAvailableTypes'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    person: types.string,
    availableTest: types.maybeNull(ContentObject),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))

export default Model
