import { useRouteState } from '@shared/utils'
import { NewTest, useLayoutContext } from '@sr/routes/common'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { TestListCampaignState } from './state'
import { useEffect } from 'react'
import { TestListCampaignContainer } from './styles'
import { SRAnnuityUserName } from '@sr/ui'

export const TestListCampaign = observer(() => {
  const { id: annuityId } = useParams()
  const { setIsLoading } = useLayoutContext()

  const { isLoading, personId, annuity } = useRouteState(
    TestListCampaignState,
    {
      annuityId,
    }
  )

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  if (!personId) return

  return (
    <TestListCampaignContainer>
      <SRAnnuityUserName annuity={annuity} />

      <NewTest
        personId={personId}
        userAreaUrl={`campaign/user-area/${annuityId}`}
      />
    </TestListCampaignContainer>
  )
})
