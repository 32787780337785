import { Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { FC } from 'react'
import { TranscriptionScriptPDF } from '../transcription-script-pdf'
import { Warning } from '../../assets/global/Warning'

type DisclaimerBannerProps = {
  text?: string | JSX.Element | JSX.Element[]
}

export const DisclaimerBanner: FC<DisclaimerBannerProps> = ({ text }) => {
  return (
    <View
      style={{
        ...tw('flex flex-row gap-2 items-center p-4 bg-[#FFF2CC]'),
        fontSize: '9.5px',
      }}
    >
      <Warning />
      {text ? (
        <Text style={tw('flex-1')}>{text}</Text>
      ) : (
        <TranscriptionScriptPDF
          style={tw('flex-1 leading-5')}
          script={'initial-report:disclaimer'}
        />
      )}
    </View>
  )
}
