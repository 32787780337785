import { getRoot, types } from 'mobx-state-tree'
import { DateString, RootModel } from '@shared/data-access'
import { SLOT_TYPE } from './enum'

export const MODEL_NAME = 'ServiceSlot'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    startDate: types.maybeNull(DateString),
    endDate: types.maybeNull(DateString),
    slotType: types.maybeNull(
      types.refinement(types.integer, (value) =>
        Object.values(SLOT_TYPE).some((type) => type === value)
      )
    ),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))

export default Model
