import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { PolicyStatusKeys, POLICY_STATE_COLORS, POLICY_STATE_TEXT  } from '@shared/data-access'

import './styles.css'

interface SRPolicyStateProps {
  statusKey: number
}
export const SRPolicyState: FC<SRPolicyStateProps> = ({ statusKey }) => {
  const { t } = useTranslation()

  const statusKeyEnum = PolicyStatusKeys[statusKey]

  if (!statusKeyEnum) {
    return <p>Invalid Status</p>
  }

  const getColorText = POLICY_STATE_COLORS[statusKeyEnum]; 
  const statusText = POLICY_STATE_TEXT[statusKeyEnum]

  return (
    <p data-testid="PolicyState" className={`text-base text-${getColorText.toLowerCase()}`}>
      {t(`userArea.profile.police.policyStates.${statusText}`)}
    </p>
  )
}
