import { FC, ReactElement } from 'react'
import { LayoutPage } from '../../components'
import { useReportPdfContext } from '../../provider'
import { AreaColor } from '../../utils'

type SectionPhysicalActivityProps = {
  children: ReactElement
  sectionNumber: string
}

export const SectionPhysicalActivity: FC<SectionPhysicalActivityProps> = ({
  children,
  sectionNumber,
}) => {
  const { t } = useReportPdfContext()

  return (
    <LayoutPage
      headerText={t(
        'initial-report:sectionPhysicalActivity.layout.headerText',
        {
          number: sectionNumber,
        },
      )}
      noSpacing
      section={AreaColor.physicalActivity}
      sectionNumber={sectionNumber}
    >
      {children}
    </LayoutPage>
  )
}
