import { View, Image, Text } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { FC } from 'react'
import { useReportPdfContext } from '../../provider'
import { LANGUAGES } from '@shared/utils'
import User from '../../../sr-initial-report-pdf/assets/user.png'
import { SubSection } from '../subsection'

export const OpBlockCampaign: FC = () => {
  const { person, t } = useReportPdfContext()

  if (!person) return null

  const originalPhoto = person?.socialReferent?.picture_url
  const transformedPhoto = originalPhoto?.replace(/=s\d+-c/, '=s300-c')

  const srPictureUrl = transformedPhoto !== '' ? transformedPhoto : User

  const srDescription =
    person.preferred_language === LANGUAGES.es
      ? person.socialReferent.professionalDescEs
      : person.socialReferent.professionalDescCa

  return (
    <View style={tw('flex flex-col gap-6')}>
      <View style={tw('px-12')}>
        <SubSection
          classes="text-primary uppercase"
          title={t('sectionTen.audit.subtitle', {
            opFullName: person.socialReferent.fullName,
          })}
        />
      </View>
      <View
        style={tw('bg-[#F2F2F2] flex flex-row gap-12 px-12 py-12 items-center')}
      >
        <View
          style={tw('flex-1 flex flex-col gap-2 items-center justify-center')}
        >
          <Image style={tw('w-24 h-24 rounded-full')} source={srPictureUrl} />
          <View style={tw('flex flex-col gap-2 items-center')}>
            <Text style={tw('text-primary text-lg font-semibold leading-5')}>
              {person.socialReferent.fullName}
            </Text>
            <Text style={tw('text-gray-400')}>
              {person.socialReferent.academicTitle}
            </Text>
            <Text style={tw('text-gray-400')}>
              {t('initial-report:sectionTen.audit.collegiateNo', {
                collegiateNo: person.socialReferent.collegiateNo,
              })}
            </Text>
          </View>
        </View>
        <View style={tw('flex-1')}>
          <Text>{srDescription}</Text>
        </View>
      </View>
    </View>
  )
}
