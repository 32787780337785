import { BaseRouteState } from '@shared/data-access'
import { AnnuityModel } from '@sr/data-access'
import { actionsCampaign, getCampaignPersonalData } from '@sr/routes/common'
import { t } from 'i18next'
import { types, flow } from 'mobx-state-tree'

export const UserProfileCampaignRouteState = BaseRouteState.named(
  'UserProfileCampaignRouteState',
)
  .props({
    annuityId: types.maybeNull(types.string),
    annuity: types.maybeNull(types.reference(AnnuityModel)),
    isEditing: false,
  })
  .views((self) => ({
    get userPersonalData() {
      if (!self.annuity) return []

      const { person } = self.annuity
      return getCampaignPersonalData({
        t,
        isEditing: self.isEditing,
        ...person,
        socialReferent: self.annuity?.socialReferent?.fullName,
      }).filter((val) => val)
    },
    get defaultValues() {
      if (!self.annuity) return {}

      const { person } = self.annuity
      return {
        preferredLanguage: person.preferredLanguage,
        sex: person.sex,
      }
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('UserProfileCampaignRouteState')
      try {
        yield self.loadAnnuity()
      } finally {
        self.removeLoading('UserProfileCampaignRouteState')
      }
    }),
    setEditingMode(mode) {
      self.isEditing = mode
    },
    reload() {
      self.loadDependencies()
    },
    ...actionsCampaign(self),
  }))
