import { getWorkDayFromToday } from '@shared/utils'
import { globalNavigate } from 'apps/social-referent/src/routes/globalHistory'
import { flow, getRoot, isRoot } from 'mobx-state-tree'

export const actionsCampaign = (self) => ({
  loadPerson: flow(function* loadPerson() {
    self.setLoading?.('loadPerson')
    try {
      const person = yield getRoot(self).PersonStore.get(self.personId, {
        throwError: true,
      })
      if (person) {
        self.person = person
      }
    } catch (error) {
      console.log(error)
      globalNavigate('/')
    } finally {
      self.removeLoading?.('loadPerson')
    }
  }),
  loadAnnuity: flow(function* loadAnnuity() {
    if (isRoot(self)) return
    self.setLoading?.('loadAnnuity')
    try {
      const annuity = yield getRoot(self).AnnuityStore.fetch(self.annuityId, {
        throwError: true,
        include: 'person,campaign,product,collective,social_referent',
        noClear: true,
      })

      if (annuity) {
        self.personId = annuity?.person?.id
        self.annuity = annuity
      }
    } catch (error) {
      console.log(error)
      globalNavigate('/')
    } finally {
      self.removeLoading?.('loadAnnuity')
    }
  }),
  loadAnnuityAppointment: flow(function* loadAnnuityAppointment() {
    if (isRoot(self)) return
    self.setLoading?.('loadAnnuityAppointment')
    try {
      if (self.annuity?.person.id) {
        const appointments = yield getRoot(self).AppointmentStore.fetchAll({
          throwError: true,
          noClear: true,
          include: 'social_referent',
          filter: {
            Appointment: {
              ...(self.annuity?.person.id && {
                personId: { value: self.annuity?.person?.id },
              }),
            },
          },
        })

        if (appointments) {
          self.appointments = appointments
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      self.removeLoading?.('loadAnnuityAppointment')
    }
  }),
  loadAnnuities: flow(function* loadAnnuities() {
    if (isRoot(self)) return
    self.setLoading?.('loadAnnuities')
    try {
      const annuities = yield getRoot(self).AnnuityStore.fetchAll({
        search: self.search || '',
        include: 'person,campaign,product,collective',
        noClear: true,
      })

      if (annuities) {
        self.annuities = annuities
      }
    } finally {
      self.removeLoading?.('loadAnnuities')
    }
  }),
  loadPendingAnnuities: flow(function* loadPendingAnnuities() {
    if (isRoot(self)) return
    self.setLoading?.('loadPendingAnnuities')
    try {
      const annuities = yield getRoot(self).AnnuityStore.fetchAll({
        search: self.search || '',
        customUrl: `/annuities/get_pending_annuities`,
        include: 'person,campaign,product,collective',
        noClear: true,
      })

      if (annuities) {
        self.annuities = annuities
      }
    } finally {
      self.removeLoading?.('loadPendingAnnuities')
    }
  }),
  loadAvailableGenericAnalysis: flow(function* loadAvailableGenericAnalysis() {
    if (isRoot(self)) return
    self.setLoading?.('loadAvailableGenericAnalysis')
    try {
      const genericAnalysisAvailableList = yield getRoot(
        self,
      ).GenericAnalysisAvailableTypesStore.fetchAll({
        customUrl: `/persons/${self.personId}/generic_analysis_available`,
      })

      if (genericAnalysisAvailableList) {
        self.genericAnalysisAvailableList = genericAnalysisAvailableList
      }
    } finally {
      self.removeLoading?.('loadAvailableGenericAnalysis')
    }
  }),
  loadGenericAnalysis: flow(function* loadGenericAnalysis() {
    if (isRoot(self)) return
    self.setLoading?.('loadGenericAnalysis')
    try {
      const genericAnalysisList = yield getRoot(
        self,
      ).GenericAnalysisStore.fetchAll({
        filter: {
          Person: { person: { value: self.personId } },
        },
      })

      if (genericAnalysisList) {
        self.genericAnalysisList = genericAnalysisList
      }
    } finally {
      self.removeLoading?.('loadGenericAnalysis')
    }
  }),
  saveAnnuityPerson: flow(function* saveAnnuityPerson(data) {
    self.setLoading?.('saveAnnuityPerson')
    if (data.sex) {
      data.sex = Number(data.sex)
    }
    try {
      self.annuity.person.update(data)
      yield self.annuity.person.save()
    } finally {
      self.removeLoading?.('saveAnnuityPerson')
    }
  }),
  loadServiceSlots: flow(function* loadServiceSlots() {
    if (isRoot(self)) return
    self.setLoading?.('loadServiceSlots')
    try {
      const slots = yield getRoot(self).ServiceSlotStore.fetchAll({
        customUrl: `/person_services/${self.serviceId}/get_available_slots/`,
        params: {
          startDate: getWorkDayFromToday(1).toDate(),
          endDate: getWorkDayFromToday(7).toDate(),
        },
        noClear: true,
      })

      if (slots) {
        self.slots = slots
      }
    } finally {
      self.removeLoading?.('loadServiceSlots')
    }
  }),
  loadPersonServices: flow(function* loadPersonServices() {
    self.setLoading?.('loadPersonServices')
    try {
      const personServices = yield getRoot(self).PersonServiceStore.fetchAll({
        noClear: true,
        include: 'service',
        filter: {
          PersonService: {
            person: { value: self.personId },
          },
        },
      })

      if (personServices) {
        self.personServices = personServices
      }
    } finally {
      self.removeLoading?.('loadPersonServices')
    }
  }),
  scheduleFirstServiceAppointment: flow(
    function* scheduleFirstServiceAppointment({
      dateSelected,
      modality,
      onError,
      onSuccess,
    }) {
      self.setLoading?.('scheduleFirstServiceAppointment')
      try {
        yield getRoot(self).PersonServiceStore.post(
          {
            id: self.serviceId,
            ...dateSelected,
            appointmentModality: modality,
          },
          {
            customUrl: `/person_services/${self.serviceId}/schedule_first_service_appointment`,
          },
        )
        yield self.loadAnnuity?.()
        onSuccess?.()
      } catch {
        onError?.()
      } finally {
        self.removeLoading?.('scheduleFirstServiceAppointment')
      }
    },
  ),
  loadPaginatedAndFilteredAnnuities: flow(
    function* loadPaginatedAndFilteredAnnuities({
      isClear = false,
      sort = null,
      filters = null,
    }) {
      if (isRoot(self)) return
      self.setLoading?.('loadPaginatedAndFilteredAnnuities')
      try {
        const annuities = yield getRoot(self).AnnuityStore.fetchPage(
          self.page + 1,
          {
            search: self.search || '',
            pageSize: self.pageSize,
            include: 'person,social_referent',
            params: {
              sort,
            },
            filter: {
              Annuity: {
                socialReferent: {
                  value: filters?.socialReferent || null,
                },
                promPlanAppointment: {
                  value: filters?.promPlanAppointment?.value || null,
                },
              },
            },
          },
        )

        if (annuities && annuities.items) {
          self.total = annuities.pagination?.count || 0
          if (self.annuities && !isClear) {
            return [...self.annuities, ...annuities.items]
          } else {
            return annuities.items
          }
        }
      } finally {
        self.removeLoading?.('loadPaginatedAndFilteredAnnuities')
      }
    },
  ),
})
