import { InitialAnalysis } from '@sr/data-access'
import { flow, getRoot, isRoot, types } from 'mobx-state-tree'
import { BaseRouteState, Policy } from '@shared/data-access'
import { actions } from '../common'
import { INITIAL_REPORT_TEST_NAME, INITIAL_REPORT_TEST_TYPE } from './enums'
import { MOCKED_INITIAL_TESTS } from '../common/analysis/generic-analysis/components/states/mock-data'

export const InitialReportRouteState = BaseRouteState.named(
  'InitialReportRouteState'
)
  .props({
    policyId: types.maybeNull(types.string),
    policy: types.maybeNull(types.reference(Policy)),
    familyBackground: types.maybeNull(types.reference(InitialAnalysis)),
    clinicalAssessment: types.maybeNull(types.reference(InitialAnalysis)),
    functionalAssessment: types.maybeNull(types.reference(InitialAnalysis)),
    cognitiveAssessment: types.maybeNull(types.reference(InitialAnalysis)),
    nutritionalAssessment: types.maybeNull(types.reference(InitialAnalysis)),
    socialAssessment: types.maybeNull(types.reference(InitialAnalysis)),
    summary: types.maybeNull(types.reference(InitialAnalysis)),
  })
  .views((self) => ({
    get hasAllTests() {
      return (
        self.familyBackground &&
        self.clinicalAssessment &&
        self.functionalAssessment &&
        self.cognitiveAssessment &&
        self.nutritionalAssessment &&
        self.socialAssessment &&
        self.summary
      )
    },
    get tests() {
      return [
        self.familyBackground,
        self.clinicalAssessment,
        self.functionalAssessment,
        self.cognitiveAssessment,
        self.nutritionalAssessment,
        self.socialAssessment,
        self.summary,
      ]
    },
    get allTestCompleted() {
      return self.tests.every((test) => test?.testCompleted)
    },
    get lastTestNotCompleted() {
      return self.tests.findIndex((test) => !test?.testCompleted)
    },
    get hasStartedSummary() {
      if (self.summary?.prevData) {
        const parsedSummary = JSON.parse(self.summary.prevData)
        return Object.keys(parsedSummary).length >= 1
      }
      return false
    },
    get hasFinishedSummary() {
      if (self.summary?.prevData) {
        const parsedSummary = JSON.parse(self.summary.prevData)
        // Maybe it would be wiser to check if required fields are filled in instead of the length
        return Object.keys(parsedSummary).length === 11
      }
      return false
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('InitialReportRouteState')
      try {
        const initialTests = self.getAllInitialTest()
        const policy = self.loadPolicy()

        yield Promise.all([policy, initialTests])
      } finally {
        self.removeLoading('InitialReportRouteState')
      }
    }),
    updateTest: flow(function* updateTest(name, data) {
      if (isRoot(self) || !self[name]) return
      const store = getRoot(self).InitialAnalysisStore
      const test = self[name]
      const testId = test.id

      data.policy_id = self.policyId

      test.setTestValues(data)
      yield store.save(test, {
        customUrl: `/tests/${testId}/save_initial_test`,
        withoutId: true,
      })
    }),
    completeTest: flow(function* completeTest(name) {
      self.setLoading('completeTest')
      try {
        if (isRoot(self) || !self[name]) return
        const store = getRoot(self).InitialAnalysisStore
        const test = self[name]
        const testId = test.id

        yield store.save(test, {
          customUrl: `/tests/${testId}/save_initial_test`,
          withoutId: true,
        })

        if (!test.testValues) test.setTestValues({ policy_id: self.policyId })
        self[name] = yield store.patch(test, {
          customUrl: `/tests/${testId}/complete_initial_test`,
          withoutId: true,
        })
      } finally {
        self.removeLoading('completeTest')
      }
    }),
    setMockedData() {
      self.tests.forEach((test) => {
        if (test.testType === INITIAL_REPORT_TEST_TYPE.summary) return
        test.setPrevData(
          MOCKED_INITIAL_TESTS[INITIAL_REPORT_TEST_NAME[test.testType]]
        )
      })
    },
    ...actions(self),
  }))
