import { AnnuityModel, AvailableSR } from '@sr/data-access'
import { flow, getRoot, types } from 'mobx-state-tree'
import {
  ModalType,
  ServiceDropdownTranslation,
  actions,
  actionsCampaign,
} from '@sr/routes/common'
import {
  BaseRouteState,
  PersonService,
  SocialReferent,
} from '@shared/data-access'
import { t } from 'i18next'

export const ProfileCampaignsActionsRouteState = BaseRouteState.named(
  'ProfileCampaignsActionsRouteState',
)
  .props({
    annuityId: types.string,
    annuity: types.maybeNull(types.reference(AnnuityModel)),
    user: types.maybeNull(types.reference(SocialReferent)),
    personServices: types.maybeNull(
      types.array(types.reference(PersonService)),
    ),
    availableSocialReferentList: types.maybeNull(
      types.array(types.safeReference(AvailableSR), {}),
    ),
    modalType: types.maybeNull(types.string),
  })
  .views((self) => ({
    get serviceOptions() {
      if (!self.personServices) return []
      return self.personServices.map((personService) => {
        const {
          service: { serviceType },
        } = personService
        return {
          name: t(`${ServiceDropdownTranslation[serviceType]}`),
          label: t(`${ServiceDropdownTranslation[serviceType]}`),
          value: personService.id,
        }
      })
    },
    get firstServiceId() {
      return self.personServices?.[0]?.id
    },
    get availableSocialReferentListParsed() {
      return self.availableSocialReferentList?.map(({ id, fullName }) => ({
        name: fullName,
        value: id,
      }))
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('ProfileCampaignsActionsRouteState')
      try {
        yield self.loadUserInfo()
        yield self.loadAnnuity()
        yield self.loadPersonServices()
        if (self.annuity?.analysisDate && self.user.isCoordinator) {
          yield self.loadAvailableSrForChangeCampaign(self.firstServiceId)
        }
      } finally {
        self.removeLoading('ProfileCampaignsActionsRouteState')
      }
    }),
    cancelContract: flow(function* cancelContract({ subState }) {
      self.setLoading('cancelContract')
      try {
        const annuity = yield getRoot(self).AnnuityStore.post(
          { ...self.annuity, reason: subState },
          {
            customUrl: `/annuities/${self.annuityId}/refuse_annuity_from_client`,
            withoutId: true,
          },
        )

        if (annuity) {
          self.annuity = annuity
        }
      } finally {
        self.removeLoading('cancelContract')
      }
    }),
    setModal(modalType) {
      self.modalType = modalType
    },
    resend() {
      self.modalType = null
      self.sendReport()
    },
    onError() {
      self.setModal(ModalType.genericError)
    },
    ...actions(self),
    ...actionsCampaign(self),
  }))
