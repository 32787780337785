import { yupResolver } from '@hookform/resolvers/yup'
import { Button, BUTTON_SIZES, Form, FORM_TYPE } from '@shared/ui'
import { SRInput, SRModal } from '@sr/ui'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SR_CREATE_FORM_FIELDS } from './enum'
import { useSchema } from './schema'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { CreateSrModalFields, CreateSrModalParagraph } from './styles'

export const CreateSrModal = observer(
  ({
    loading,
    onClose,
    onSubmit,
    error,
    success,
    setterSuccess,
    setterError,
  }) => {
    const { t } = useTranslation()

    const schema = useSchema(t)
    const methods = useForm({
      resolver: yupResolver(schema),
    })

    const [showMessage, setShowMessage] = useState(false)

    const { handleSubmit, reset } = methods

    const createSr = handleSubmit(async (form) => {
      await onSubmit(form)
      reset()
    })

    useEffect(() => {
      if (error || success) {
        setShowMessage(true)
        setTimeout(() => {
          setShowMessage(false)
          setterSuccess(false)
          setterError(false)
        }, [5000])
      }
    }, [error, success])

    return (
      <SRModal onClose={onClose}>
        <Form methods={methods} className="flex flex-col gap-8">
          <CreateSrModalParagraph>
            {t('srList.modal.gmail')} <br />
            {t('srList.modal.makeSure')}
          </CreateSrModalParagraph>
          <CreateSrModalFields>
            <SRInput
              type={FORM_TYPE.email}
              defaultValue=""
              placeholder="Introduce el email"
              name={SR_CREATE_FORM_FIELDS.email}
            />
            <SRInput
              type={FORM_TYPE.text}
              defaultValue=""
              placeholder="Introduce codigo postal"
              name={SR_CREATE_FORM_FIELDS.postalCode}
            />
          </CreateSrModalFields>
          {showMessage && error ? (
            <div>{t('srList.modal.error')}</div>
          ) : (
            showMessage && <div>{t('srList.modal.success')}</div>
          )}
          <Button
            loading={loading}
            size={BUTTON_SIZES.small}
            onClick={createSr}
            className="self-center"
          >
            {t('srList.modal.button')}
          </Button>
        </Form>
      </SRModal>
    )
  }
)
