import { View } from '@react-pdf/renderer'
import { tw } from '../../../../utils'
import {
  ListItem,
  TickList,
  TranscriptionScriptPDF,
} from '../../../../components'
import { FC } from 'react'

type ListWithTickProps = {
  title: string
  list: string[]
}

export const ListWithTick: FC<ListWithTickProps> = ({ title, list }) => {
  return (
    <ListItem
      list={[
        <View style={tw('flex flex-col gap-2')}>
          <TranscriptionScriptPDF script={title} />
          <TickList styles="ml-8" list={list} />
        </View>,
      ]}
      hasComponent
    />
  )
}
