import { Form } from '@shared/ui'
import { SRListFilterBox, SRMultiSelect } from '@sr/ui'
import { useForm } from 'react-hook-form'
import { FORM_FILTER_NAME, scheduledOptions } from './enums'
import { observer } from 'mobx-react-lite'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Filter } from '@carbon/icons-react'

type FiltersProps = {
  onChangeFilters: Dispatch<SetStateAction<any>>
}

export const Filters: FC<FiltersProps> = observer(({ onChangeFilters }) => {
  const { t } = useTranslation()

  const methods = useForm()

  const { reset, watch } = methods

  const onClear = () => {
    reset()
  }

  const values = watch()

  useEffect(() => {
    onChangeFilters(values)
  }, [values])

  const placeHolder = (translation: string) => (
    <span className="flex items-center gap-2">
      <Filter /> {t(translation)}
    </span>
  )

  return (
    <SRListFilterBox onClear={onClear}>
      <Form methods={methods} className="flex flex-col gap-4">
        <SRMultiSelect
          name={FORM_FILTER_NAME.promPlanAppointment}
          options={scheduledOptions(t)}
          placeholder={placeHolder('userList.filter.promPlanAppointment')}
          isClearable
        />
      </Form>
    </SRListFilterBox>
  )
})
