import { Svg, Path } from '@react-pdf/renderer'

export const Bulb = ({ color = 'white', width = '14', height = '14' }) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path d="M9.1875 10.5H4.8125V11.375H9.1875V10.5Z" fill={color} />
      <Path d="M8.3125 12.25H5.6875V13.125H8.3125V12.25Z" fill={color} />
      <Path
        d="M7.00044 0.875C5.84012 0.875 4.72732 1.33594 3.90685 2.15641C3.08638 2.97688 2.62544 4.08968 2.62544 5.25C2.59585 5.88583 2.71765 6.51961 2.98082 7.09918C3.24398 7.67875 3.641 8.18756 4.13919 8.58375C4.57669 8.99063 4.81294 9.2225 4.81294 9.625H5.68794C5.68794 8.82 5.20231 8.36937 4.72982 7.93625C4.32091 7.62312 3.99502 7.2145 3.78069 6.74618C3.56637 6.27786 3.47014 5.76414 3.50044 5.25C3.50044 4.32174 3.86919 3.4315 4.52557 2.77513C5.18194 2.11875 6.07218 1.75 7.00044 1.75C7.9287 1.75 8.81894 2.11875 9.47531 2.77513C10.1317 3.4315 10.5004 4.32174 10.5004 5.25C10.5302 5.76452 10.4333 6.27849 10.2182 6.74683C10.0031 7.21518 9.6764 7.62359 9.26669 7.93625C8.79856 8.37375 8.31294 8.81125 8.31294 9.625H9.18794C9.18794 9.2225 9.41981 8.99063 9.86169 8.57938C10.3595 8.18387 10.7564 7.67581 11.0195 7.09699C11.2827 6.51818 11.4047 5.88516 11.3754 5.25C11.3754 4.67547 11.2623 4.10656 11.0424 3.57576C10.8225 3.04496 10.5003 2.56266 10.094 2.15641C9.68778 1.75015 9.20548 1.42789 8.67468 1.20803C8.14388 0.988163 7.57497 0.875 7.00044 0.875Z"
        fill={color}
      />
    </Svg>
  )
}
