import { Image, Link, Text, View } from '@react-pdf/renderer'
import { LayoutPage } from '../../components/LayoutPage'
import { tw } from '../../utils'
import Iphone from '../../assets/qida-iphone.png'
import PhoneFilled from '../../assets/phone--filled.png'
import Chat from '../../assets/chat.png'
import ApplicationWeb from '../../assets/application--web 1.png'
import Bcorp from '../../assets/bcorp.png'
import facebook from '../../assets/logo--facebook.png'
import instagram from '../../assets/logo--instagram.png'
import linkedin from '../../assets/logo--linkedin.png'
import qida from '../../assets/logo--qida.png'
import { TranscriptionScriptPDF } from '../../components/TranscriptionScriptPDF'
import { SubSection } from '../../components/SubSection'
import { ListItem } from '../../components/ListItem'
import Qida from '../../assets/qida_logo.png'
import { useReportPdfContext } from '../../report-pdf-provider'
import { ReportType } from '../../../report-pdf/enums'
import { OpBlockCampaign, OpBlockCare } from '../../../report-pdf/components'

export const FirstPage = () => {
  const { data, t, person, type } = useReportPdfContext()

  const planType = t('planType')

  const bibliograpy = [
    'Organización Mundial de la Salud. Reducción del riesgo de deterioro cognitivo y demencia: directrices de la OMS. Ginebra, Suiza: Organización Mundial de la Salud; 2019.',
    'Integrated care for older people (ICOPE): Guidance for person-centred assessment and pathways in primary care. Geneva: World Health Organization; 2019 (WHO/FWC/ALC/19.1). Licence: CC BY-NC-SA 3.0 IGO.',
    'Livingston, G., Huntley, J., Sommerlad, A., Ames, D., Ballard, C., Banerjee, S., ... & Mukadam, N. (2020). Dementia prevention, intervention, and care: 2020 report of the Lancet Commission. The Lancet, 396(10248), 413-446.',
    'Yassine, H. N., Samieri, C., Livingston, G., Glass, K., Wagner, M., Tangney, C., ... & Schneider, L. S. (2022). Nutrition state of science and dementia prevention: recommendations of the Nutrition for Dementia Prevention Working Group. The Lancet Healthy Longevity, 3(7), e501-e512.',
    'Informe mundial sobre la visión [World report on vision]. Ginebra: Organización Mundial de la Salud; 2020. Licencia: CC BY-NC-SA 3.0 IGO',
    'Directrices de la OMS sobre actividad física y hábitos sedentarios: de un vistazo [WHO guidelines on physical activity and sedentary behaviour: at a glance]. Ginebra: Organización Mundial de la Salud; 2020. Licencia: CC BY-NC-SA 3.0 IGO. ',
    () => (
      <Text>
      Ministerio de Sanidad. Alimentación saludable. Disponible en:{' '}
        <Link src="https://estilosdevidasaludable.sanidad.gob.es/alimentacionSaludable">
          https://estilosdevidasaludable.sanidad.gob.es/alimentacionSaludable
        </Link>
      </Text>
    ),
    () => (
      <Text>
        Organización Mundial de la Salud. Tabaco: beneficios para la salud de
        dejar de fumar. 25 de febrero de 2020.Disponible en:
        <Link src="https://www.who.int/es/news-room/questions-and-answers/item/tobacco-health-benefits-of-smoking-cessation">
          https://www.who.int/es/news-room/questions-and-answers/item/tobacco-health-benefits-of-smoking-cessation
        </Link>
      </Text>
    ),
    () => (
      <Text>
        Ministerio de Sanidad. Estilos de vida saludables. Prevención del
        tabaquismo. Disponible en:
        <Link src="https://estilosdevidasaludable.sanidad.gob.es/tabaco/home.htm">
          https://estilosdevidasaludable.sanidad.gob.es/tabaco/home.htm
        </Link>
      </Text>
    ),
    () => (
      <Text>
        Alcohol. Organización Mundial de la salud. 9 de mayo de 2022. Disponible
        en:
        <Link src="https://www.who.int/es/news-room/fact-sheets/detail/alcohol">
          https://www.who.int/es/news-room/fact-sheets/detail/alcohol
        </Link>
      </Text>
    ),
    () => (
      <Text>
        Estilos de vida saludables. Ministerio de sanidad. Disponible en:
        <Link src="https://estilosdevidasaludable.sanidad.gob.es/consumo/falsosMitos/estres/home.htm">
          https://estilosdevidasaludable.sanidad.gob.es/consumo/falsosMitos/estres/home.htm
        </Link>
      </Text>
    ),
    'Global status report on alcohol and health 2018. Geneva: World Health Organization; 2018. Licence: CC BY-NC-SA 3.0 IGO.',
    'Manual básico de cuidado del oído y la audición [Basic ear and hearing care resource]. Ginebra: Organización Mundial de la Salud; 2020. Licencia: CC BY-NC-SA 3.0 IG',
  ]

  const opName = person.socialReferent.name

  const isCare = ReportType[type] === ReportType.care
  return (
    <>
      {isCare && (
        <LayoutPage
          title={t('initial-report:sectionTen.nextSteps.title', { number: 4 })}
          data={data}
          noSpacing
          primaryTitle
        >
          <View style={tw('flex flex-col gap-6')}>
            <View style={tw('px-12 mt-6')}>
              <TranscriptionScriptPDF
                script={'initial-report:sectionTen.nextSteps.text'}
                scriptProps={{ opName, planType }}
              />
            </View>
            <View style={tw('bg-[#F2F2F2] flex flex-col gap-12 px-12 pt-12')}>
              <View style={tw('flex flex-col gap-4')}>
                <TranscriptionScriptPDF
                  style={tw('text-xl')}
                  script="initial-report:sectionTen.nextSteps.contactUs.title"
                />
                <TranscriptionScriptPDF
                  script="initial-report:sectionTen.nextSteps.contactUs.text"
                  scriptProps={{ opName }}
                />
              </View>
              <View style={tw('flex flex-row gap-16')}>
                <Image style={tw('flex-1')} src={Iphone} />
                <View style={tw('flex-1 flex flex-col gap-8')}>
                  <View>
                    <View style={tw('flex flex-row items-center gap-4')}>
                      <Image style={tw('w-6 h-6')} src={ApplicationWeb} />
                      <Text style={tw('font-semibold')}>
                        {t(
                          'initial-report:sectionTen.nextSteps.contactUs.modes.portalAccess',
                        )}
                      </Text>
                    </View>
                  </View>
                  <View>
                    <View style={tw('flex flex-row gap-4')}>
                      <Image style={tw('w-6 h-6')} src={Chat} />
                      <View style={tw('flex flex-col gap-2')}>
                        <Text style={tw('font-semibold')}>
                          {t(
                            'initial-report:sectionTen.nextSteps.contactUs.modes.messages.title',
                          )}
                        </Text>
                        <Text>
                          {t(
                            'initial-report:sectionTen.nextSteps.contactUs.modes.messages.text',
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={tw('flex flex-row gap-4')}>
                    <Image style={tw('w-6 h-6')} src={PhoneFilled} />
                    <View style={tw('flex flex-col gap-2')}>
                      <Text style={tw('font-semibold')}>
                        {t(
                          'initial-report:sectionTen.nextSteps.contactUs.modes.phone.title',
                        )}
                      </Text>
                      <Text>
                        {t(
                          'initial-report:sectionTen.nextSteps.contactUs.modes.phone.text',
                        )}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </LayoutPage>
      )}
      <LayoutPage
        title={t('initial-report:sectionTen.audit.title', {
          number: isCare ? 5 : 4,
        })}
        data={data}
        noSpacing
        primaryTitle
      >
        {isCare ? <OpBlockCare /> : <OpBlockCampaign />}

        <View style={tw('px-12 flex flex-col gap-6')}>
          <SubSection
            classes="text-primary"
            title={t('initial-report:sectionTen.audit.qidaTitle')}
          >
            <TranscriptionScriptPDF
              script={'initial-report:sectionTen.audit.qidaTeam'}
            />
          </SubSection>
          <View break>
            <SubSection
              title={t('initial-report:sectionTen.audit.whatIsQida')}
              classes="text-primary"
            >
              <View style={tw('flex flex-col gap-6')}>
                <TranscriptionScriptPDF
                  script={'initial-report:sectionTen.audit.whatIsQidaText'}
                />
                <View style={tw('flex flex-col gap-6 px-14')}>
                  <View style={tw('w-full h-1 bg-[#F2F2F2]')} />
                  <View style={tw('flex flex-row gap-10 items-center')}>
                    <Image style={tw('h-60')} src={Bcorp} />
                    <Text style={tw('flex-1 italic')}>
                      {t('initial-report:sectionTen.audit.bCorp')}
                    </Text>
                  </View>
                  <View style={tw('w-full h-1 bg-[#F2F2F2]')} />
                </View>
              </View>
            </SubSection>
          </View>
        </View>
        <View style={tw('p-2 bg-[#F2F2F2] flex flex-row justify-evenly')}>
          <Link
            src="https://qida.es"
            style={tw(
              'flex flex-row items-center gap-1 no-underline text-primary',
            )}
          >
            <Image style={tw('w-9 h-9')} src={qida} />
            <Text>Qida.es</Text>
          </Link>
          <Link
            src="https://www.facebook.com/qidacare"
            style={tw(
              'flex flex-row items-center gap-1 no-underline text-primary',
            )}
          >
            <Image style={tw('w-10 h-10')} src={facebook} />
            <Text>Facebook</Text>
          </Link>
          <Link
            src="https://www.linkedin.com/company/qida-atencion-domiciliaria-home-care/mycompany/verification/"
            style={tw(
              'flex flex-row items-center gap-1 no-underline text-primary',
            )}
          >
            <Image style={tw('w-10 h-10')} src={linkedin} />
            <Text>Linkedin</Text>
          </Link>
          <Link
            src="https://www.instagram.com/qida.care/"
            style={tw(
              'flex flex-row items-center gap-1 no-underline text-primary',
            )}
          >
            <Image style={tw('w-10 h-10')} src={instagram} />
            <Text>Instagram</Text>
          </Link>
        </View>
      </LayoutPage>
      <LayoutPage
        title={t('initial-report:sectionTen.bibliography.title', {
          number: isCare ? 6 : 5,
        })}
        data={data}
        noSpacing
        primaryTitle
      >
        <View style={tw('px-12')}>
          <ListItem list={bibliograpy} hasBreak />
        </View>
      </LayoutPage>
      <LayoutPage data={data} noSpacing hasFooter={false} hasHeader={false}>
        <View style={tw('px-12 flex justify-center items-center h-full')}>
          <Image src={Qida} style={tw('w-52')} />
        </View>
      </LayoutPage>
    </>
  )
}
