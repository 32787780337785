import { initialReport as InitialReport } from './initialReport'
import {
  CaixaLogoWithTextSvg,
  ExclamationIconSvg,
  LogoCaixaBank,
  QidaCalendar,
} from './svg'
const mainLogo = require('./qida.png')
const CaixaLogoPNG = require('./logo_caixabank.png')
const MainLogoWhite = require('./qida-white.png')

export const Images = {
  mainLogo,
  MainLogoWhite,
  LogoCaixaBank,
  InitialReport,
  CaixaLogoWithTextSvg,
  CaixaLogoPNG,
  ExclamationIconSvg,
  QidaCalendar,
}

export default Images
