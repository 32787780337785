import { Banner } from '../../../components/Banner/Banner'
import { View, Image, Text } from '@react-pdf/renderer'
import { tw } from '../../../utils'
import { useReportPdfContext } from '../../../report-pdf-provider'
import introductionInfographyES from '../../../assets/introduction-infography-es.png'
import introductionInfographyCA from '../../../assets/introduction-infography-ca.png'

export const FourPage = ({ data }) => {
  const { t } = useReportPdfContext()
  const lang = data?.results?.initialAnalysisList?.person_data?.preferred_language

  return (
    <View>
      <View style={tw('px-12')}>
        <Image
          style={tw('w-[400px]')}
          src={lang === 'ca' ? introductionInfographyCA : introductionInfographyES}
        />
        <Text style={tw('text-[10px] mt-10 mb-10')}>{t('sectionOne.introduction.introduction-fonts')}</Text>
      </View>
       <Banner title={t('sectionOne.introduction.banner')} />
    </View>
  )
}
