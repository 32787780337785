type PfeifferScoreProps = {
  score: number | string
}

export enum PfeifferScoreResult {
  normal,
  mild,
  moderate,
  severe,
}

interface SCORE_NAME {
  [key: number]: string
}

export const PfeifferScoreName: SCORE_NAME = Object.freeze({
  [PfeifferScoreResult.normal]: 'normal',
  [PfeifferScoreResult.mild]: 'mild',
  [PfeifferScoreResult.moderate]: 'moderate',
  [PfeifferScoreResult.severe]: 'severe',
})

export class PfeifferScore {
  score: number | string
  result: number
  normal: number
  mild: number
  moderate: number
  severe: number
  scoreName: string
  scale: string

  constructor({ score }: PfeifferScoreProps) {
    this.score = score
    this.result = PfeifferScoreResult.normal
    this.normal = 2
    this.mild = 4
    this.moderate = 7
    this.severe = 10
    this.scoreName = ''
    this.scale = `${score}/${this.severe}`
    this.init()
  }

  private init() {
    this.setResult()
    this.setScoreName()
  }

  private setResult() {
    if (typeof this.score === 'string') {
      return
    }
    if (this.score <= this.normal) {
      this.result = PfeifferScoreResult.normal
    }
    if (this.score > this.normal && this.score <= this.mild) {
      this.result = PfeifferScoreResult.mild
    }
    if (this.score > this.mild && this.score <= this.moderate) {
      this.result = PfeifferScoreResult.moderate
    }
    if (this.score > this.moderate && this.score <= this.severe) {
      this.result = PfeifferScoreResult.severe
    }
  }

  private setScoreName() {
    this.scoreName = PfeifferScoreName[this.result]
  }
}
