import { useRouteState } from '@shared/utils'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { SRAddButton } from '@sr/ui'
import { AddButtonClasses, DotListClasses } from './styles'
import { useLayoutContext } from '@sr/routes/common'
import { AppointmentManagerRouteState } from './state'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { useTranslation } from 'react-i18next'
import { hasFutureInitialReport } from './enums'
import { AppointmentList } from '@sr/routes/common/appointment-list'

type NewEventProps = {
  id: string
  userAreaUrl?: string
}

export const NewEvent: FC<NewEventProps> = observer(({ id }) => {
  const { isLoading, appointmentsFiltered, isPostCs, socialReferentId } =
    useRouteState(AppointmentManagerRouteState, {
      policyId: id,
    })
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { setIsLoading } = useLayoutContext()

  const calendarRoute = () => ROUTE_PATH.agenda(socialReferentId)
  const CSAgendaRoute = () =>
    `${ROUTE_PATH.userArea(id)}/${ROUTE_PATH.schedule}`

  const goCSAgenda = () => {
    navigate(CSAgendaRoute())
  }
  const goCalendar = () => {
    navigate(calendarRoute(), {
      state: { postCs: isPostCs, policyId: id },
    })
  }
  const handleClick = () => {
    if (!isPostCs) {
      goCSAgenda()
    } else {
      goCalendar()
    }
  }
  const modifyCalender = (dataToModify?: object) => {
    if (calendarRoute) {
      navigate(calendarRoute(), {
        state: {
          postCs: true,
          dataToModify: dataToModify || {},
          isModify: true,
        },
      })
    }
  }

  const modifyCSAgenda = () => {
    if (CSAgendaRoute) {
      navigate(CSAgendaRoute(), {
        state: { postCs: false },
      })
    }
  }

  const modifyHandleClick = (dataToModify?: object) => {
    if (!isPostCs) {
      modifyCSAgenda()
    } else {
      modifyCalender(dataToModify)
    }
  }
  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  return (
    <>
      <div className="relative">
        <h2 className="text-primary text-xl absolute right-[85px] top-[35px]">
          {t(`appointmentManager.createEvent`)}
        </h2>
        <SRAddButton
          action={async () => {
            handleClick()
          }}
          className={`${AddButtonClasses} ${
            hasFutureInitialReport(appointmentsFiltered, true) &&
            'cursor-not-allowed'
          } `}
          disabled={hasFutureInitialReport(appointmentsFiltered, true)}
        />
      </div>
      <div className={DotListClasses}>
        <AppointmentList
          events={appointmentsFiltered}
          policyId={id}
          isCare
          onClick={modifyHandleClick}
        />
      </div>
    </>
  )
})
