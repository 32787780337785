import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useVideoContext } from '../../hooks'
import { ActionButtons } from './components/action-buttons'
import { MeetLogo } from '../meet-logo'
import { ParticipantAudioTracks } from './components/participant-audio-tracks'
import { ParticipantList } from './components/participant-list'
import { BRANDING_KEYS } from '../../utils/branding'
import { VIDEO_CALL_PAGE_VIEW, analytics } from '@shared/utils'

export const MeetScreen = ({ token, roomName: name }) => {
  const { connect, room, options, branding } = useVideoContext()
  const { t } = useTranslation()

  useEffect(() => {
    connect(token, name)
  }, [])

  useEffect(() => {
    analytics.pageView({
      path: VIDEO_CALL_PAGE_VIEW.pageViewVideoCallPlay,
    })
  }, [])

  return (
    <div className="h-full w-full relative flex flex-col">
      <div className="flex flex-col items-center pt-4">
        <MeetLogo />
        {branding?.branding === BRANDING_KEYS.VCX && (
          <p className="text-xs md:text-base text-center font-semibold text-black pt-2">
            {t('common:videoCall.title')}
          </p>
        )}
      </div>
      <div className="h-full w-full flex flex-col pb-2">
        {room ? (
          <>
            <ParticipantAudioTracks />
            <ParticipantList options={options} />
            {options.actionButtons ? (
              <ActionButtons className="self-center pt-2 lg:pt-0" />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  )
}
