export const NUTRITIONAL_KEYS = Object.freeze({
  recNutritionalMediterranean: 'rec_nutritional_mediterranean',
  recNutritionalMediterraneanKeep: 'rec_nutritional_mediterranean_keep',
  recNutritionalInsufficientWeight: 'rec_nutritional_insufficient_weight',
  recNutritionalHypertension: 'rec_nutritional_hypertension',
  recNutritionalHeartFailure: 'rec_nutritional_heart_failure',
  recNutritionalDiabetesType1: 'rec_nutritional_diabetes_type_1',
  recNutritionalDiabetesType2: 'rec_nutritional_diabetes_type_2',
  recNutritionalDyslipidemia: 'rec_nutritional_dyslipidemia',
  recNutritionalOsteoarthritisArthritis:
    'rec_nutritional_osteoarthritis_arthritis',
  recNutritionalOsteoporosis: 'rec_nutritional_osteoporosis',
  recLactoseIntolerant: 'rec_lactose_intolerant',
  recNutritionalIrritableBowelSyndrome:
    'rec_nutritional_irritable_bowel_syndrome',
  recNutritionalGastroesophagealReflux:
    'rec_nutritional_gastroesophageal_reflux',
  recNutritionalCeliacDisease: 'rec_nutritional_celiac_disease',
  recNutritionalGastritis: 'rec_nutritional_gastritis',
  recNutritionalUlcerativeColitis: 'rec_nutritional_ulcerative_colitis',
  recNutritionalCrohnsDisease: 'rec_nutritional_crohns_disease',
  recNutritionalConstipation: 'rec_nutritional_constipation',
  recNutritionalMediterraneanCopd: 'rec_nutritional_mediterranean_copd',
  recNutritionalOverweight: 'rec_nutritional_overweight',
  recNutritionalObesity: 'rec_nutritional_obesity',
  recNutritionalVegan: 'rec_nutritional_vegan',
  recNutritionalVegetarian: 'rec_nutritional_vegetarian',
  recNutritionalDysphagia: 'rec_nutritional_dysphagia',
  recNutritionalCombo1: 'rec_nutritional_combo_1', // (rec_nutritional_hypertension + rec_nutritional_obesity + rec_nutritional_obesity + rec_nutritional_dyslipidemia)
  recNutritionalCombo2: 'rec_nutritional_combo_2', // (rec_nutritional_hypertension + rec_nutritional_obesity)"
  recNutritionalCombo3: 'rec_nutritional_combo_3', // (rec_nutritional_hypertension + rec_nutritional_diabetes_type_2)"
  recNutritionalCombo4: 'rec_nutritional_combo_4', // (rec_nutritional_hypertension +rec_nutritional_dyslipidemia)"
  recNutritionalCombo5: 'rec_nutritional_combo_5', // (rec_nutritional_obesity + rec_nutritional_diabetes_type_2)"
  recNutritionalCombo6: 'rec_nutritional_combo_6', // (rec_nutritional_obesity + rec_nutritional_dyslipidemia)"
  recNutritionalCombo7: 'rec_nutritional_combo_7', // (rec_nutritional_diabetes_type_2 + rec_nutritional_dyslipidemia)"
  recNutritionalCombo0: 'rec_nutritional_combo_0', // (rec_nutritional_mediterranean_copd)"
  recNutritionalCombo8: 'rec_nutritional_combo_8', // (rec_nutritional_heart_failure + rec_nutritional_hypertension)"
  recNutritionalCombo9: 'rec_nutritional_combo_9', // (rec_nutritional_heart_failure + rec_nutritional_obesity)"
  recNutritionalCombo10: 'rec_nutritional_combo_10', // (rec_nutritional_heart_failure + rec_nutritional_diabetes_type_2)"
  recNutritionalCombo11: 'rec_nutritional_combo_11', // (rec_nutritional_heart_failure + rec_nutritional_dyslipidemia)"
})
