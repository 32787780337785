import { getRoot, types } from 'mobx-state-tree'
import { RootModel } from '@shared/data-access'
import { EMPLOYEE_STATE, SOCIAL_REFERENT_TYPE } from './enums'
import { DateString } from '../types'

export const MODEL_NAME = 'SocialReferent'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    name: types.maybeNull(types.string),
    surname: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    pictureUrl: types.maybeNull(types.string),
    postalCode: types.maybeNull(types.string),
    professionalDescEs: types.maybeNull(types.string),
    professionalDescCa: types.maybeNull(types.string),
    academicTitle: types.maybeNull(types.string),
    activeFrom: types.maybeNull(DateString),
    collegiateNo: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    employeeState: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(EMPLOYEE_STATE).some((type) => type === value),
      ),
    ),
    locality: types.maybeNull(types.string),
    region: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    socialReferentType: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(SOCIAL_REFERENT_TYPE).some((type) => type === value),
      ),
    ),
    canSuspendSr: types.maybeNull(types.boolean),
    knownLanguages: types.maybeNull(types.array(types.string)),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))
  .views((self) => ({
    get fullName() {
      return self.name ? `${self.name} ${self.surname}` : self.email
    },
    get isCoordinator() {
      return self.socialReferentType === SOCIAL_REFERENT_TYPE.coordinator
    },
    get isActive() {
      return self.employeeState === EMPLOYEE_STATE.ACTIVE
    },
  }))
