export const SRRadioButtonContainer = `
    h-12
    flex
    items-center
    flex
`

export const SRRadioButtonLabel = `
    block 
    relative
    mx-3
    w-6
    h-6
    cursor-pointer
    select-none
`

export const SRRadioButtonInput = `
    absolute  
    opacity-0
    h-0
    w-0
    cursor-pointer
    peer
`

export const SRRadioButtonCheckmark = `
    absolute 
    top-0
    left-0
    h-[20px]
    w-[20px]
    border-2 
    border-generic-neutral-800
    rounded-full
    peer-checked:border-4
    peer-checked:border-primary
`
