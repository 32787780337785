import { ReportPdfContextProps } from '../../../../provider'
import { RichmondScoreResult } from '../../../../scales'
import { scoreIsNotAvailable } from '../../../../utils'

export const getRichmondScaleList = (context: ReportPdfContextProps) => {
  const { t, testDate, scores, person } = context
  const { score, scale, scoreName } = scores?.richmondScore || {}

  return [
    {
      label: t('initial-report:generic.resultTable.scaleDescription'),
      content: t('initial-report:scores.richmond.description'),
    },
    {
      label: t('initial-report:generic.resultTable.testDate'),
      content: testDate,
    },
    {
      label: t('initial-report:generic.resultTable.result'),
      content: scoreIsNotAvailable(score)
        ? t('initial-report:resultNotAvailable.richmond', {
            patientName: person?.name,
          })
        : t(`initial-report:scores.richmond.${scoreName}.value`, {
            scale,
          }),
    },
  ]
}

export const getRichmondPerYearList = (context: ReportPdfContextProps) => {
  const { t, scores } = context
  const { result } = scores?.richmondScore || {}

  return [
    {
      text: 'A:0-3',
      subText: t('initial-report:scores.richmond.scaleValues.low'),
      selected: result === RichmondScoreResult.low,
    },
    {
      text: 'B:4-5',
      subText: t('initial-report:scores.richmond.scaleValues.doubt'),
      selected: result === RichmondScoreResult.doubt,
    },
    {
      text: 'C:6-7',
      subText: t('initial-report:scores.richmond.scaleValues.medium'),
      selected: result === RichmondScoreResult.medium,
    },
    {
      text: 'D:8-10',
      subText: t('initial-report:scores.richmond.scaleValues.high'),
      selected: result === RichmondScoreResult.high,
    },
  ]
}
