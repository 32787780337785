import { createContext, useContext, useEffect, useState } from 'react'
import { Client } from '@twilio/conversations'
import { CONNECTION_STATE, CONVERSATIONS_EVENT } from './enums'

const ChatProvider = createContext({})

export const MessagesProvider = ({ children, token, messagesPath }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [client, setClient] = useState(null)
  const [status, setStatus] = useState(CONNECTION_STATE.disconnected)
  const [newMessages, setNewMessages] = useState(null)
  const [conversations, setConversations] = useState([])

  const isNotConnected = () => status !== CONNECTION_STATE.connected

  const setConnection = ({ token }) => {
    if (!token) return
    const twClient = new Client(token)
    setClient(twClient)
    twClient.on(CONVERSATIONS_EVENT.connectionStateChanged, (state) => {
      setStatus(CONNECTION_STATE[state] || CONNECTION_STATE.disconnected)
    })
  }

  const getSubscribedConversations = async () => {
    if (isNotConnected()) return
    const { items } = await client.getSubscribedConversations()
    setConversations(items)
  }

  const onJoinConversations = () => {
    if (isNotConnected()) return
    client.on(CONVERSATIONS_EVENT.conversationJoined, (conversation) => {
      setConversations([...conversations, conversation])
    })
  }

  const onLeaveConversation = () => {
    if (isNotConnected()) return
    client.on(CONVERSATIONS_EVENT.conversationLeft, (conversation) => {
      setConversations([...conversations.filter((it) => it !== conversation)])
    })
  }

  const onMessageAdded = () => {
    if (isNotConnected()) return
    client.on(CONVERSATIONS_EVENT.messageAdded, async (message) => {
      const isInMessages = window.location.pathname.includes(messagesPath)
      if (isInMessages) {
        return message.conversation.setAllMessagesRead()
      }
      const isMine = client.user.identity === message.author
      if (!isMine) {
        setNewMessages(message)
      }
    })
  }

  const getConversation = ({ roomSid }) => {
    return conversations.find(({ sid }) => sid === roomSid)
  }

  const disconnect = () => {
    if (status !== CONNECTION_STATE.connected) return
    client.shutdown()
  }

  const getMessagesUnread = async ({ roomSid }) => {
    const conversation = getConversation({ roomSid })

    if (conversation) {
      const messagesUnread = await conversation.getUnreadMessagesCount()

      return messagesUnread
    }
  }

  const markConversationAsUnread = async ({ roomSid }) => {
    const conversation = getConversation({ roomSid })

    if (conversation) {
      await conversation.setAllMessagesUnread()
    }
  }

  useEffect(() => {
    if (token) {
      setConnection({ token })
    }
  }, [token])

  useEffect(() => {
    setIsLoading(true)
    getSubscribedConversations()
      .then(() => {
        onJoinConversations()
        onLeaveConversation()
        onMessageAdded()
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [status])

  if (!token) return <>{children}</>

  return (
    <ChatProvider.Provider
      value={{
        client,
        status,
        conversations,
        getConversation,
        getMessagesUnread,
        disconnect,
        newMessages,
        setNewMessages,
        markConversationAsUnread,
        isLoading,
      }}
    >
      {children}
    </ChatProvider.Provider>
  )
}

export function useMessages() {
  return useContext(ChatProvider)
}
