import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDevices } from '../useDevices'

export const useVideoInputOptions = () => {
  const { t } = useTranslation()

  const { videoInputDevices } = useDevices()

  return useMemo(() => {
    return videoInputDevices.map(({ deviceId }, ix) => {
      return {
        name: t('common:videoCall.camera', { number: ix + 1 }),
        value: deviceId || ix,
      }
    })
  }, [videoInputDevices])
}
