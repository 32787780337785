import { SRAddButton, SRBigCalendar } from '@sr/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { AgendaAside } from './components'
import {
  AgendaBigCalendarContainer,
  AgendaCalendarContainer,
  AgendaContainer,
} from './styles'
import { useAgenda } from './useAgenda'
import { CalendarAppointments } from './state'
import { useRouteState } from '@shared/utils'
import { useLayoutContext, ModalAgenda } from '@sr/routes/common'
import { useLocation, useParams } from 'react-router-dom'
import { ConfirmRegenerateRoom } from './components/confirm-regenerate-room'
import { APPOINTMENT_TYPES } from '@shared/data-access'

export const Agenda = observer(() => {
  const { id } = useParams()
  const {
    bigCalendarDate,
    smallCalendarDate,
    endDate,
    onChangeDate,
    view,
    setView,
    formatDate,
    filterDate,
  } = useAgenda()

  const {
    appointmentsFormatted,
    loadDependencies,
    isLoading,
    user,
    setUserId,
    generatedRoom,
    generateRoom,
  } = useRouteState(CalendarAppointments, {
    userId: id,
  })

  const { setIsLoading } = useLayoutContext()

  // TODO REFACTOR HANDLING ERRORS IN ROOTMODEL ROOTSTORE.
  const [errorConfirmRegenerateRoom, setErrorConfirmRegenerateRoom] =
    useState(false)
  const [endConfirmRegenerateRoom, setEndConfirmRegenerateRoom] =
    useState(false)
  const [isEditingEvent, setIsEditingEvent] = useState(false)
  const [isPostCs, setIsPostCs] = useState(null)

  useEffect(() => {
    setErrorConfirmRegenerateRoom(!generatedRoom?.result)
  }, [generatedRoom?.result])

  useEffect(() => {
    setUserId(id)
    const getloadDependencies = async () => {
      await loadDependencies(formatDate(filterDate), endDate)
    }
    if (filterDate && endDate) getloadDependencies()
  }, [filterDate, endDate, id])

  const [event, setEvent] = useState(null)

  const onSelectEvent = (eventSelected) => {
    setEvent(eventSelected)
    setIsEditingEvent(true)
    setErrorConfirmRegenerateRoom(false)
    openModal()
  }

  // TODO: Implement this method on calendar
  const onSelectSlot = (eventSelected) => {
    setEvent(eventSelected)
    setIsEditingEvent(false)
    openModal()
  }

  const actionAfterSubmit = async () => {
    await loadDependencies(formatDate(filterDate), endDate)
    closeModal()
  }

  const onGenerateRoom = async () => {
    setEndConfirmRegenerateRoom(false)
    await generateRoom(event.id)
    setEndConfirmRegenerateRoom(true)
  }

  const [
    isVisibleCofirmGenerateRoomModal,
    setIsVisibleCofirmGenerateRoomModal,
  ] = useState(false)

  const openVisibleCofirmGenerateRoomModal = () => {
    setIsVisibleCofirmGenerateRoomModal(true)
  }
  const closeVisibleCofirmGenerateRoomModal = () => {
    setEndConfirmRegenerateRoom(false)
    setErrorConfirmRegenerateRoom(false)
    setIsVisibleCofirmGenerateRoomModal(false)
  }

  const [isVisible, setIsVisible] = useState(false)

  const openModal = () => setIsVisible(true)
  const closeModal = () => setIsVisible(false)

  const locationState = useLocation().state
  useEffect(() => {
    setIsLoading(isLoading)
    if (locationState) {
      const {
        postCs = false,
        policyId = null,
        dataToModify = null,
        isModify = false,
      } = locationState
      setIsPostCs(postCs)
      if (!isModify) {
        if (isPostCs && !isLoading) {
          onSelectSlot({
            appointmentType: { reference: APPOINTMENT_TYPES.CONSULTATION },
            policy: { id: policyId },
            isPostCs: postCs,
          })
        }
      } else {
        onSelectSlot({
          appointmentType: { reference: dataToModify?.appointmentType },
          end: dataToModify?.endDate,
          start: dataToModify?.startDate,
          appointmentModality: dataToModify?.appointmentModality,
          id: dataToModify?.id,
          policy: JSON.parse(dataToModify?.policy),
          isAppointmentManagerEdit: true,
        })
      }
    }
  }, [isLoading])

  if (!appointmentsFormatted || !user) return

  return (
    <AgendaContainer>
      <AgendaAside
        setView={setView}
        smallCalendarDate={smallCalendarDate}
        onChangeDate={onChangeDate}
        isCoordinator={user?.isCoordinator}
      />
      <AgendaCalendarContainer>
        <AgendaBigCalendarContainer>
          <SRBigCalendar
            initialDate={bigCalendarDate}
            events={appointmentsFormatted}
            view={view}
            onChange={onChangeDate}
            onSelectEvent={onSelectEvent}
          />
        </AgendaBigCalendarContainer>
        <SRAddButton action={onSelectSlot} />
      </AgendaCalendarContainer>
      {isVisible && (
        <ModalAgenda
          dateSelected={smallCalendarDate}
          onClose={closeModal}
          event={event}
          isCoordinator={user?.isCoordinator}
          actionAfterSubmit={actionAfterSubmit}
          isEdit={isEditingEvent}
          onGenerateRoom={openVisibleCofirmGenerateRoomModal}
        />
      )}
      {isVisibleCofirmGenerateRoomModal && (
        <ConfirmRegenerateRoom
          onClose={closeVisibleCofirmGenerateRoomModal}
          onSubmit={onGenerateRoom}
          endedCall={endConfirmRegenerateRoom}
          error={errorConfirmRegenerateRoom}
        />
      )}
    </AgendaContainer>
  )
})
