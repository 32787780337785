export const GENERIC_ANALYSIS_TYPE = Object.freeze({
  subscription: 0,
  initialAnalysis: 1,
  genericAnalysis: 2,
  followUpAnalysis: 4,
})

export const GENERIC_ANALYSIS_TYPE_NAME = Object.freeze({
  [GENERIC_ANALYSIS_TYPE.subscription]: 'subscription',
  [GENERIC_ANALYSIS_TYPE.initialAnalysis]: 'initialAnalysis',
  [GENERIC_ANALYSIS_TYPE.genericAnalysis]: 'genericAnalysis',
  [GENERIC_ANALYSIS_TYPE.followUpAnalysis]: 'followUpAnalysis',
})

export const GENERIC_ANALYSIS_TEST_TYPE = {
  basicHealth: 0,
  lobo: 1,
  clock: 2,
  family: 3,
  clinical: 4,
  functional: 5,
  cognitive: 6,
  nutritional: 7,
  social: 8,
  summary: 9,
  summary_executive: 10,
  pfeiffer: 11,
}

export const GENERIC_ANALYSIS_TEST_TYPE_NAME = Object.freeze({
  [GENERIC_ANALYSIS_TEST_TYPE.basicHealth]: 'basicHealth',
  [GENERIC_ANALYSIS_TEST_TYPE.lobo]: 'lobo',
  [GENERIC_ANALYSIS_TEST_TYPE.clock]: 'clock',
  [GENERIC_ANALYSIS_TEST_TYPE.family]: 'family',
  [GENERIC_ANALYSIS_TEST_TYPE.clinical]: 'clinical',
  [GENERIC_ANALYSIS_TEST_TYPE.functional]: 'functional',
  [GENERIC_ANALYSIS_TEST_TYPE.cognitive]: 'cognitive',
  [GENERIC_ANALYSIS_TEST_TYPE.nutritional]: 'nutritional',
  [GENERIC_ANALYSIS_TEST_TYPE.social]: 'social',
  [GENERIC_ANALYSIS_TEST_TYPE.summary]: 'summary',
  [GENERIC_ANALYSIS_TEST_TYPE.summary_executive]: 'summary_executive',
  [GENERIC_ANALYSIS_TEST_TYPE.pfeiffer]: 'pfeiffer',
})

export const GENERIC_ANALYSIS_STATUS = Object.freeze({
  new: 0,
  pending: 1,
  filled: 2,
  closed: 3,
})
