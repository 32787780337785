import { flow, types } from 'mobx-state-tree'
import { actions } from '@sr/routes/common'
import {
  POLICY_REMINDER_TYPE,
  POLICY_REMINDER_TYPE_AND_CODE_NAMES,
  POLICY_REMINDER_TYPE_NAMES,
  PolicyRemainder,
} from '@sr/data-access'
import { BUTTON_SIZES, Button } from '@shared/ui'
import { t } from 'i18next'
import { getDateFormatted } from '@shared/utils'
import { BaseRouteState } from '@shared/data-access'

export const MonitoringAreaCommunicationsRouteState = BaseRouteState.named(
  'MonitoringAreaCommunicationsRouteState'
)
  .props({
    communications: types.maybeNull(
      types.array(types.reference(PolicyRemainder))
    ),
    policyId: types.maybeNull(types.string),
  })
  .views((self) => ({
    get communicationsFiltered() {
      return self.communications?.map(
        ({ sentDate, remainderType, confirmedDate, code, id }) => {
          const needManualConfirm =
            POLICY_REMINDER_TYPE.PHONE_CALL === remainderType && !confirmedDate

          return {
            sentDate: getDateFormatted(sentDate, 'YYYY-MM-DD'),
            remainderType: `${t(
              POLICY_REMINDER_TYPE_NAMES[remainderType]
            )} - ${t(
              POLICY_REMINDER_TYPE_AND_CODE_NAMES[remainderType][code]
            )}`,
            action: needManualConfirm && (
              <Button
                size={BUTTON_SIZES.small}
                onClick={() => self.confirmCommunication(id)}
              >
                {t('common:confirm')}
              </Button>
            ),
          }
        },
        []
      )
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadCommunicationDependencies()
    },
    loadCommunicationDependencies: flow(
      function* loadCommunicationDependencies() {
        self.setLoading('MonitoringAreaCommunicationsRouteState')
        try {
          yield self.loadCommunications()
        } finally {
          self.removeLoading('MonitoringAreaCommunicationsRouteState')
        }
      }
    ),
    ...actions(self),
  }))
