export const QidaCalendar = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="39"
      viewBox="0 0 36 39"
      fill="none"
      className={className}
    >
      <path
        d="M33 3H27V0H24V3H12V0H9V3H3C1.35 3 0 4.35 0 6V36C0 37.65 1.35 39 3 39H33C34.65 39 36 37.65 36 36V6C36 4.35 34.65 3 33 3ZM33 36H3V15H33V36ZM33 12H3V6H9V9H12V6H24V9H27V6H33V12Z"
        fill="#333333"
      />
    </svg>
  )
}
