import tw from 'tailwind-styled-components'

export const BreadcrumbContainer = tw.nav`
  flex
  gap-8
  items-center
  justify-center
  lg:flex
  max-w-4xl
  self-center
  w-full
`

export const BreadCrumbListSeparator = tw.div`
  border-b
  flex
  items-center
  justify-center
  lg:flex
  max-w-4xl
  self-center
  w-full
`

export const BreadcrumbListContainer = tw.ol`
  inline-flex
  items-center
  lg:col-end-9
  lg:col-start-1
  max-w-4xl
  md:space-x-3
  p-6
  rtl:space-x-reverse
  w-full
`
export const BreadcrumbListElement = tw.li`
  font-bold
  inline-flex
  items-center
  text-2xl
  text-black
`

export const BreadcrumbListItemWrapper = tw.div`
  inline-flex
  items-center
`


export const BreadcrumbListItem = tw.a`
  cursor-pointer
  font-medium
  text-black
  text-base
  no-underline
  hover:underline
`

export const BreadcrumbListItemMobile = tw.a`
  cursor-pointer
  inline-flex
  items-center
  font-medium
  text-black
  text-base
  no-underline
  hover:underline
`

export const BreadcrumbItem = tw.span`
  font-medium
  text-brightGreen
  text-base
  text-primary
  font-bold
  no-underline
  truncate
`
