export const INITIAL_REPORT_TEST_TYPE = Object.freeze({
  familyBackground: 3,
  clinicalAssessment: 4,
  functionalAssessment: 5,
  cognitiveAssessment: 6,
  nutritionalAssessment: 7,
  socialAssessment: 8,
  summary: 9,
})

export const INITIAL_REPORT_TEST_NAME = Object.freeze({
  [INITIAL_REPORT_TEST_TYPE.familyBackground]: 'familyBackground',
  [INITIAL_REPORT_TEST_TYPE.clinicalAssessment]: 'clinicalAssessment',
  [INITIAL_REPORT_TEST_TYPE.functionalAssessment]: 'functionalAssessment',
  [INITIAL_REPORT_TEST_TYPE.cognitiveAssessment]: 'cognitiveAssessment',
  [INITIAL_REPORT_TEST_TYPE.nutritionalAssessment]: 'nutritionalAssessment',
  [INITIAL_REPORT_TEST_TYPE.socialAssessment]: 'socialAssessment',
  [INITIAL_REPORT_TEST_TYPE.summary]: 'summary',
})

export const TEST_PAGE = Object.freeze({
  familyBackground: 0,
  clinicalAssessment: 1,
  functionalAssessment: 2,
  cognitiveAssessment: 3,
  nutritionalAssessment: 4,
  socialAssessment: 5,
  nextSteps: 6,
  summary: 7,
})

export const TEST_PAGE_NAME = Object.freeze({
  [TEST_PAGE.familyBackground]: 'familyBackground',
  [TEST_PAGE.clinicalAssessment]: 'clinicalAssessment',
  [TEST_PAGE.functionalAssessment]: 'functionalAssessment',
  [TEST_PAGE.cognitiveAssessment]: 'cognitiveAssessment',
  [TEST_PAGE.nutritionalAssessment]: 'nutritionalAssessment',
  [TEST_PAGE.socialAssessment]: 'socialAssessment',
  [TEST_PAGE.nextSteps]: 'nextSteps',
  [TEST_PAGE.summary]: 'summary',
})
