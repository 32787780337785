import { Modal } from '@shared/ui'
import { Close } from '@carbon/icons-react'
import {
  crossCancelContainerClasses,
  crossCancelIconClasses,
  crossCancelTitle,
  modalContainerClassName,
  modalOverlayClassName,
  titleInCross,
} from './styles'
import { FC } from 'react'

interface SRModalProps {
  children: React.ReactNode
  className?: string
  onClose?: () => void
  title?: string
}

export const SRModal: FC<SRModalProps> = ({
  children,
  className = '',
  onClose = null,
  title = '',
}) => {
  return (
    <Modal
      overlayClasses={modalOverlayClassName}
      containerClassName={`${modalContainerClassName} ${className}`}
    >
      {onClose && (
        <div
          className={`${crossCancelContainerClasses} ${
            title ? titleInCross : ''
          }`}
        >
          <Close
            size={32}
            onClick={onClose}
            className={crossCancelIconClasses}
          />
          <h2 className={crossCancelTitle}>{title}</h2>
        </div>
      )}
      {children}
    </Modal>
  )
}
