export const errorListContainerClasses = `
    flex
    flex-col
    gap-4
    w-3/6
`

export const errorListTitleClasses = `
    font-semibold
    mb-8
`

export const errorListUlContainerClasses = `
    ml-12
    w-9/12
    flex
    flex-col
    gap-2
    list-disc
`

export const errorItemContainerClasses = `
    flex
    justify-between
    items-center
    gap-2
`
