import { PDFDownloadLink } from '@react-pdf/renderer'
import { ReportPdf } from '../../../report-pdf'
import { ReportType } from '../../../report-pdf/enums'

export const DownloadLink = ({
  className,
  data,
  children,
  handleDownloadClick,
}) => {
  return (
    <PDFDownloadLink
      className={className}
      document={<ReportPdf type={ReportType.care} results={data} />}
      fileName="plan-prevencion.pdf"
      onClick={() => handleDownloadClick(data)}
    >
      {children}
    </PDFDownloadLink>
  )
}
