import { View } from '@react-pdf/renderer'
import warningIcon from '../../../assets/global/warning-emoji.png'
import checkIcon from '../../../assets/global/check-emoji.png'
import { TranscriptionScriptPDF } from '../../../components'
import { tw } from '../../../utils'

export const ListItem = ({
  description,
  extra,
  scriptProps,
}: {
  description: string
  extra: string[]
  scriptProps: object
}) => {
  if (!extra) return

  return (
    <View style={tw('flex flex-col gap-2 pl-6')}>
      <TranscriptionScriptPDF script={description} scriptProps={scriptProps} />
      <View style={tw('flex flex-col gap-2 pl-14')}>
        <TranscriptionScriptPDF
          script={extra[0]}
          scriptImages={{ imageOne: checkIcon }}
        />
        <TranscriptionScriptPDF
          script={extra[1]}
          scriptImages={{ imageOne: warningIcon }}
        />
      </View>
    </View>
  )
}
