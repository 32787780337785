import { FirstPage } from './components/first-page'
import { SecondPage } from './components/second-page'
import { ThirdPage } from './components/third-page'
import { useReportPdfContext } from '../report-pdf-provider'
import { SectionIndexContent } from '../../report-pdf/sections/index-content'

export const SectionOne = () => {
  const { data } = useReportPdfContext()

  return (
    <SectionIndexContent
      mainCover={<FirstPage />}
      content={<SecondPage />}
      introduction={<ThirdPage data={data} />}
    />
  )
}
