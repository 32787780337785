import { FC, ReactElement } from 'react'
import { LayoutPage } from '../../components'
import { useReportPdfContext } from '../../provider'
import { AreaColor } from '../../utils'
import { NutritionalResults } from './results'
import { NutritionalObjectives } from './objectives'
import { NutritionalGuidelines } from './guidelines'
import { NutritionalRecommendationsTable } from './recommendations-table'
import { NutritionalTips } from './tips'
import { NutritionalFrequencyTable } from './frequency-table'

type SectionNutritionalProps = {
  children: ReactElement
  sectionNumber: string
}

export const SectionNutritional: FC<SectionNutritionalProps> = ({
  children,
  sectionNumber,
}) => {
  const { t } = useReportPdfContext()

  return (
    <LayoutPage
      headerText={t('initial-report:sectionNutritional.layout.headerText', {
        number: sectionNumber,
      })}
      noSpacing
      section={AreaColor.nutritional}
      sectionNumber={sectionNumber}
    >
      <NutritionalResults sectionLetter="A" />
      <NutritionalObjectives sectionLetter="B" />
      <NutritionalGuidelines sectionLetter="C" />
      <NutritionalRecommendationsTable />
      <NutritionalTips />
      <NutritionalFrequencyTable />
      {children}
    </LayoutPage>
  )
}
