import { Button } from '@shared/ui'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ControlTestScript } from '../control-test-script'
import {
  SummaryActions,
  SummaryContainer,
  SummaryTotal,
  SummaryTotalError,
  SummaryTotalSuccess,
} from './styles'
import { ScriptBox, SummaryBox } from './components'

export const ControlSummary = ({ result, userId, policy }) => {
  const TRANSLATION_BASE_KEY = 'userArea.control'
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (!result) return

  const isHealthPassed = result.health.passed
  const isLoboPassed = result.lobo.passed
  const isClockPassed = result.clock_results.passed

  const isAccepted = isHealthPassed && isLoboPassed && isClockPassed

  const resultScript = isAccepted
    ? `${TRANSLATION_BASE_KEY}.summary.acceptedScript`
    : `${TRANSLATION_BASE_KEY}.summary.rejectedScript`

  const healthResult = isHealthPassed
    ? t(`${TRANSLATION_BASE_KEY}.summary.healthOk`)
    : t(`${TRANSLATION_BASE_KEY}.summary.healthKo`)

  const loboResultText = isLoboPassed
    ? `(${t(`${TRANSLATION_BASE_KEY}.summary.loboOk`)})`
    : `(${t(`${TRANSLATION_BASE_KEY}.summary.loboKo`)})`

  const loboResult = `${result.lobo.score} / ${
    Object.values(result.lobo.answers).length
  } ${loboResultText}`

  const clockResultText = isClockPassed
    ? t(`${TRANSLATION_BASE_KEY}.summary.clockOk`)
    : t(`${TRANSLATION_BASE_KEY}.summary.clockKo`)

  const clockResult = `${result.clock_results.score} (${clockResultText})`

  const onComplete = () => {
    navigate(ROUTE_PATH.userArea(userId))
  }
  if(!policy){
    return null
  }
  return (
    <ControlTestScript
      script={resultScript}
      scriptProps={{ clientName: policy.fullName }}
      components={{
        scriptBox: (
          <ScriptBox
            isHealthPassed={isHealthPassed}
            isLoboPassed={isLoboPassed}
            isClockPassed={isClockPassed}
          />
        ),
      }}
    >
      <SummaryContainer>
        <SummaryBox
          title={t(`${TRANSLATION_BASE_KEY}.healthTest.title`)}
          result={healthResult}
          isPositive={result.health.passed}
        />
        <SummaryBox
          title={t(`${TRANSLATION_BASE_KEY}.loboTest.title`)}
          result={loboResult}
          isPositive={result.lobo.passed}
        />
        <SummaryBox
          title={t(`${TRANSLATION_BASE_KEY}.clockTest.title`)}
          result={clockResult}
          isPositive={result.clock_results.passed}
        />
        <SummaryTotal>
          <div>{t(`${TRANSLATION_BASE_KEY}.summary.assessment`)}</div>
          {isAccepted ? (
            <SummaryTotalSuccess>
              {t(`${TRANSLATION_BASE_KEY}.summary.valid`)}
            </SummaryTotalSuccess>
          ) : (
            <SummaryTotalError>
              {t(`${TRANSLATION_BASE_KEY}.summary.invalid`)}
            </SummaryTotalError>
          )}
        </SummaryTotal>
        <SummaryActions>
          <Button onClick={onComplete} testId="complete-button">
            {t(`${TRANSLATION_BASE_KEY}.buttons.finish`)}
          </Button>
        </SummaryActions>
      </SummaryContainer>
    </ControlTestScript>
  )
}
