import { FC } from 'react'
import { initialAvatarClasses } from './styles'

type InitialsAvatarProps = {
  name: string
  className?: string
  background?: string
}

export const InitialsAvatar: FC<InitialsAvatarProps> = ({
  name,
  className = '',
  background = '',
}) => {
  return (
    <img
      src={`https://ui-avatars.com/api/?name=${name}&background=${
        background || 'random'
      }`}
      alt={name}
      className={`${initialAvatarClasses} ${className}`}
    />
  )
}
