export const wizardTimelineContainerClasses = `
    flex
    justify-items-stretch
    my-8
`

export const wizardTimelineButtonClasses = `
    py-7
    border-t-2
    border-primary
    flex
    justify-center
    grow
    text-primary
    relative
    cursor-pointer
    basis-4
`
export const wizardTimelineCircleClasses = `
    w-8
    h-8
    bg-primary
    rounded-full
    absolute
    top-[-18px]
`
