import { Text, View } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { FC } from 'react'

type SubsectionProps = {
  title?: string
  children?: JSX.Element | JSX.Element[] | any
  classes?: string
}
export const SubSection: FC<SubsectionProps> = ({
  title,
  children = null,
  classes = 'text-black',
}) => {
  return (
    <View style={tw('pb-8')}>
      {title && (
        <Text
          style={{
            ...tw(`tracking-widest font-bold py-6 ${classes} text-base`),
          }}
        >
          {title}
        </Text>
      )}
      {children}
    </View>
  )
}
