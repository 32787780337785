import { FC } from 'react'
import { LanguageToggleWrapper, LanguageToggleLabel } from '../styles'
import { toggleLanguage } from '../enum'
interface LanguageToggleProps {
  activeLanguage?: string | null
  onClick?: (lang: string | null) => void
}

export const LanguageToggle: FC<LanguageToggleProps> = ({
  activeLanguage,
  onClick,
}) => {
  const handleLabelClick = (lang: string) => {
    onClick?.(lang)
  }
  return (
    <LanguageToggleWrapper>
      {toggleLanguage.map((lang, idx) => {
        return (
          <LanguageToggleLabel
            key={lang}
            className={
              'border-black border-r ' +
              (activeLanguage === lang ? 'bg-[rgba(0,64,57,.3)]' : '')
            }
            onClick={() => handleLabelClick(lang)}
          >
            {lang}
          </LanguageToggleLabel>
        )
      })}
    </LanguageToggleWrapper>
  )
}
