import { Chat } from '@carbon/icons-react'
import { FC, useRef, useState } from 'react'
import {
  HeaderActionButton,
  HeaderActionsContainer,
  NotificationBubble,
  ProfileAvatar,
} from './styles'
import { useRouteState } from '@shared/utils'
import { useOutsideAlerter } from '@shared/ui'
import { SocialReferent } from 'libs/shared/data-access/src/lib/social-referent/types'
import { ProfileDropdown } from './components'
import { observer } from 'mobx-react-lite'
import { DropdownOpened } from './enums'
import { ChatDropdown } from './components/chat-dropdown'
import { HeaderActionsRouteState } from './state'

type HeaderActionsProps = {
  user?: SocialReferent | null
}

export const HeaderActions: FC<HeaderActionsProps> = observer(
  ({ user = null }) => {
    const [dropdownOpened, setDropdownOpened] = useState<DropdownOpened | null>(
      null,
    )
    const { unreadMessages, conversationsList, reload, updateItemMessages } =
      useRouteState(HeaderActionsRouteState)

    const selectDropdown = (type: DropdownOpened | null) => {
      if (type === dropdownOpened) return setDropdownOpened(null)
      setDropdownOpened(type)
    }

    const onClickOutside = () => {
      setDropdownOpened(null)
    }

    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, onClickOutside)

    return (
      <HeaderActionsContainer ref={wrapperRef}>
        <HeaderActionButton
          data-testid="header-chat-notification-button"
          onClick={() => selectDropdown(DropdownOpened.chat)}
        >
          <div className="w-[30px] h-[30px] relative">
            {unreadMessages > 0 && <NotificationBubble />}
            <Chat className="w-full h-full" />
          </div>
        </HeaderActionButton>

        <HeaderActionButton
          onClick={() => selectDropdown(DropdownOpened.menu)}
          data-testid="profile-dropdown-button"
        >
          <ProfileAvatar src={user?.pictureUrl} />
        </HeaderActionButton>
        {dropdownOpened === DropdownOpened.menu && (
          <ProfileDropdown user={user} onAction={onClickOutside} />
        )}
        {dropdownOpened === DropdownOpened.chat && (
          <ChatDropdown
            list={conversationsList}
            onViewAll={onClickOutside}
            reload={reload}
            updateItemMessages={updateItemMessages}
          />
        )}
      </HeaderActionsContainer>
    )
  },
)
