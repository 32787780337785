import { View } from '@react-pdf/renderer'
import {
  ScalePerYear,
  TableHeaderBlack,
  TranscriptionScriptPDF,
} from '../../../../components'
import { AreaColor, scoreIsNotAvailable, tw } from '../../../../utils'
import { getMedasPerYearList, getMedasScaleList } from './utils'
import { useReportPdfContext } from '../../../../provider'

export const MedasResults = () => {
  const context = useReportPdfContext()
  const { t, person, scores } = context

  const { score: medasScore, scoreName: medasScoreName } =
    scores?.medasScore || {}

  const medasScaleList = getMedasScaleList(context)
  const medasPerYearList = getMedasPerYearList(context)

  const TRANSLATION_BASE_KEY = 'initial-report:sectionNutritional.results'

  return (
    <View style={tw('flex flex-col gap-6')} break>
      <TableHeaderBlack
        title={t(`initial-report:scores.medas.title`)}
        list={medasScaleList}
      />
      <ScalePerYear
        scales={medasPerYearList}
        areaColor={AreaColor.nutritional}
      />
      {!scoreIsNotAvailable(medasScore) && (
        <TranscriptionScriptPDF
          script={`${TRANSLATION_BASE_KEY}.medas.result`}
          scriptProps={{
            patientName: person?.name,
            adherence: t(
              `initial-report:scores.medas.${medasScoreName}.valueLower`,
            ),
          }}
        />
      )}
    </View>
  )
}
