import { useLayoutContext } from '@sr/routes/common'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { UserAreaCareRouteState } from './state'
import { useEffect, useState } from 'react'
import { SRCardsContainer, SRConfirmModal, SRUserName } from '@sr/ui'
import { useRouteState } from '@shared/utils'
import { UserAreaInfo, UserAreaWrapper } from './styles'
import { Button } from '@shared/ui'

export const UserAreaCare = observer(() => {
  const { id } = useParams()
  const { t } = useTranslation()
  const { setBackButtonText, setIsLoading } = useLayoutContext()
  const [showModal, setShowModal] = useState(false)

  const { userCards, isLoading, policy, showReDoButton, unblockCS } =
    useRouteState(UserAreaCareRouteState, {
      policyId: id,
    })

  const confirmReDoCS = () => {
    unblockCS()
    setShowModal(false)
  }

  useEffect(() => {
    setBackButtonText(t('userArea.title'))
  }, [])

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  if (!policy) return null

  return (
    <UserAreaWrapper>
      <UserAreaInfo>
        <SRUserName fullName={policy?.fullName} />
      </UserAreaInfo>
      <SRCardsContainer cards={userCards} />
      {showReDoButton && (
        <Button onClick={() => setShowModal(true)}>
          {t('userArea.reDoCSButton')}
        </Button>
      )}
      {showModal && (
        <SRConfirmModal
          onClose={() => setShowModal(false)}
          onAction={confirmReDoCS}
        >
          <div>{t('userArea.reDoCSModalMessage')}</div>
        </SRConfirmModal>
      )}
    </UserAreaWrapper>
  )
})
