import { useMediaQuery } from 'react-responsive'
let defaultConfig = require('tailwindcss/defaultConfig')

/**
 *
 * Usage:
 * const { isSm } = useBreakpoint('sm')
 * const { isMd } = useBreakpoint('md')
 * const { isLg } = useBreakpoint('lg')
 */

export function useBreakpoint(breakpointKey) {
  const breakpoints = defaultConfig.theme.screens

  const bool = useMediaQuery({
    query: `(min-width: ${breakpoints[breakpointKey]})`,
  })
  const capitalizedKey =
    breakpointKey[0].toUpperCase() + breakpointKey.substring(1)

  return {
    [`is${capitalizedKey}`]: bool,
  }
}
