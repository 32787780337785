export enum QD_BUTTON_TYPES {
  hug,
  fullWidth,
}

export enum QD_BUTTON_VARIANTS {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  iconButton = 'iconButton',
  fab = 'floatingActionButton',
}

export enum QD_BUTTON_SIZES {
  sm,
  md,
  lg,
}
