import React from 'react'
import { useTranslation } from 'react-i18next'

import { SRPolicyState } from '@sr/ui'

interface StatusPoliceBlockProps {
  idProces: number
}

export const StatusPoliceBlock: React.FC<StatusPoliceBlockProps> = ({ idProces }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="text-primary text-xl font-semibold mb-9">
        {t('userArea.profile.police.title')}
      </div>

      <div className='grid grid-cols-2'>
        <div className='text-base font-semibold text-black mb-4'>
          {t('userArea.profile.police.state')}
        </div>
        <div className='flex flex-col items-end pr-[55px]'>
          <SRPolicyState statusKey={idProces} />
        </div>
      </div>
    </div>
  )
}
