import React, { useEffect, useRef, useState } from 'react'
import {
  useIsTrackSwitchedOff,
  useParticipantIsReconnecting,
  usePublications,
  useTrack,
  useVideoContext,
} from '../../../../hooks'
import { Publication } from '../publication'
import { UserAvatarFilled } from '@carbon/icons-react'
import { useBreakpoint } from 'libs/shared/ui/src/lib/hooks'
import { useVideoCalculator } from './hooks/useVideoCalculator'
import useScreenShareParticipant from '../../../../hooks/useScreenShareParticipant/useScreenShareParticipant'

const Participant = ({ participant, isLocalParticipant = false }) => {
  const { isSharingScreen } = useVideoContext()

  const publications = usePublications(participant)

  const [filteredPublications, setFilteredPublications] = useState(null)

  useEffect(() => {
    if (
      publications.some((publication) =>
        publication.trackName.includes('screen')
      )
    ) {
      // When displaying a screenshare track is allowed, and a screen share track exists,
      // remove all video tracks without the name 'screen'.
      const newPublications = publications.filter(
        (publication) =>
          publication.trackName.includes('screen') ||
          publication.kind !== 'video'
      )
      setFilteredPublications(newPublications)
    } else {
      // Else, remove all screenshare tracks
      const newPublications = publications.filter(
        (publication) => !publication.trackName.includes('screen')
      )
      setFilteredPublications(newPublications)
    }
  }, [isLocalParticipant, publications])

  const videoPublication = publications.find(
    (publication) =>
      !publication.trackName.includes('screen') && publication.kind === 'video'
  )
  const screenSharePublication = publications.find((publication) =>
    publication.trackName.includes('screen')
  )
  const isVideoEnabled = Boolean(videoPublication)

  const videoTrack = useTrack(screenSharePublication || videoPublication)
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack)
  const isParticipantReconnecting = useParticipantIsReconnecting(participant)
  const screenShareParticipant = useScreenShareParticipant()

  const { isLg } = useBreakpoint('lg')
  const { options } = useVideoContext()
  const isAdmin = options?.isAdmin

  const videoEnabledClasses = !isVideoEnabled
    ? 'bg-gray-background'
    : 'bg-black'

  const [classes, setClasses] = useState(null)

  const ref = useRef(null)

  const { width, height, top } = useVideoCalculator({ isLocalParticipant, ref })

  useEffect(() => {
    if (!isLg && isLocalParticipant) {
      setClasses('rounded-xl bottom-0 right-4')
    } else {
      setClasses('lg:rounded-xl')
    }
  }, [isLg, isLocalParticipant])

  const videoPriority =
    isLocalParticipant === screenShareParticipant && !isLocalParticipant
      ? 'high'
      : null

  return (
    <div
      ref={ref}
      style={{
        width,
        height,
        top,
        maxHeight: '370px',
      }}
      className={`${classes} ${videoEnabledClasses} overflow-x-hidden absolute lg:bottom-0 lg:right-0 lg:relative flex flex-col gap-4 lg:justify-center lg:items-center lg:shadow-md lg:shadow-black`}
    >
      {isSharingScreen ? null : !isVideoEnabled || isVideoSwitchedOff ? (
        <div className="flex justify-center items-center h-full">
          <UserAvatarFilled size={isLg ? 100 : 70} />
        </div>
      ) : null}
      {isParticipantReconnecting && (
        <div className="flex justify-center w-full h-full">
          <p>Reconnecting...</p>
        </div>
      )}
      {filteredPublications?.map((publication) => (
        <Publication
          videoPriority={videoPriority}
          publication={publication}
          key={publication.trackSid}
          isLocalParticipant={isLocalParticipant}
          showLogo={
            (!isLocalParticipant && !isAdmin) || (isLocalParticipant && isAdmin)
          }
        />
      ))}
    </div>
  )
}

export default React.memo(Participant)
