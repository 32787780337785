import { SRSurvey } from '@sr/ui'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { Model, SurveyModel } from 'survey-core'

type Props = {
  updateTest: (type: number, data: object) => void
  type: number
  completeText?: string
  onComplete?: () => void
  onExit?: () => void
  test: string
  values?: string
  extraAction?: () => void
  extraActionText?: string
  extraActionVisible?: boolean
  hideNavigation?: boolean
  autoValidate?: boolean
}

export const SrTest: FC<Props> = observer(
  ({
    updateTest,
    type,
    completeText,
    onComplete,
    onExit,
    test,
    values,
    extraAction,
    extraActionText,
    extraActionVisible = false,
    hideNavigation = false,
    autoValidate = false,
  }) => {
    const survey = useMemo(() => new Model(test), [test])

    if (values) {
      try {
        const json = JSON.parse(values)
        if (json)
          survey.data = {
            ...survey.data,
            ...JSON.parse(values),
          }
      } catch (err) {
        console.info('No se ha podido convertir a JSON')
      }
    }

    const onChange = (
      survey: SurveyModel,
      { name, value }: { name: string; value: string }
    ) => {
      updateTest(type, { ...survey.data, [name]: value })
    }

    const onCompleteTest = (sender: SurveyModel) => {
      updateTest(type, sender.data)
      if (onComplete) onComplete()
    }

    return (
      <SRSurvey
        completeText={completeText}
        onComplete={onCompleteTest}
        survey={survey}
        onChange={onChange}
        onExit={onExit}
        extraAction={extraAction}
        extraActionText={extraActionText}
        extraActionVisible={extraActionVisible}
        hideNavigation={hideNavigation}
        autoValidate={autoValidate}
      />
    )
  }
)
