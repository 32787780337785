import { View } from '@react-pdf/renderer'
import { AreaColor, tw } from '../../../../utils'
import {
  ColoredTable,
  ListItem,
  TickList,
  TipList,
  TranscriptionScriptPDF,
} from '../../../../components'
import { useReportPdfContext } from '../../../../provider'
import { FC } from 'react'

type RecommendationsItemProps = {
  base: string
  hasSecondary?: boolean
  hasTertiary?: boolean
  hasList?: boolean
  hasTipList?: boolean
}

export const RecommendationsItem: FC<RecommendationsItemProps> = ({
  base,
  hasSecondary,
  hasTertiary,
  hasList,
  hasTipList,
}) => {
  const { t } = useReportPdfContext()
  const TRANSLATION_BASE_KEY = `initial-report:sectionNutritional.recommendationsTable.${base}`

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const listOfTranslations: string[] = t(`${TRANSLATION_BASE_KEY}.list`, {
    returnObjects: true,
  })

  return (
    <>
      <ColoredTable
        title={t(`${TRANSLATION_BASE_KEY}.title`)}
        content={
          <View style={tw('flex flex-col gap-4')}>
            <TranscriptionScriptPDF
              script={`${TRANSLATION_BASE_KEY}.description`}
            />
            {hasSecondary && (
              <TranscriptionScriptPDF
                script={`${TRANSLATION_BASE_KEY}.secondaryDescription`}
              />
            )}
            {hasTertiary && (
              <TranscriptionScriptPDF
                script={`${TRANSLATION_BASE_KEY}.tertiaryDescription`}
              />
            )}
            {hasList && (
              <ListItem
                title={
                  <TranscriptionScriptPDF
                    script={`${TRANSLATION_BASE_KEY}.listTitle`}
                  />
                }
                list={listOfTranslations}
              />
            )}
          </View>
        }
        color={AreaColor.nutritional}
      />
      {hasTipList && (
        <TipList
          title={t(`${TRANSLATION_BASE_KEY}.listTitle`)}
          color={AreaColor.nutritional}
        >
          <TickList list={listOfTranslations} />
        </TipList>
      )}
    </>
  )
}
