import { PortfolioManagementListContainer } from '../common'
import { useState } from 'react'
import { Filters } from './filters'
import { List } from './list'

export const CampaignList = () => {
  const [filters, setFilters] = useState({})

  return (
    <PortfolioManagementListContainer>
      <Filters onChangeFilters={setFilters} />
      <List filters={filters} />
    </PortfolioManagementListContainer>
  )
}
