import { BaseRouteState, Policy } from '@shared/data-access'
import { flow, types } from 'mobx-state-tree'
import { actions } from '@sr/routes/common'

export const UserNewListState = BaseRouteState.named('UserNewListState')
  .props({
    policies: types.maybeNull(types.array(types.reference(Policy))),
    pageSize: 15,
    page: 0,
    total: 0,
    search: '',
    sort: types.maybeNull(
      types.array(
        types.frozen({
          id: 'request_date',
          desc: true,
        }),
      ),
    ),
    filters: types.maybeNull(types.frozen({})),
  })
  .views((self) => ({
    get pagination() {
      return {
        pageSize: self.pageSize,
        pageIndex: self.page,
      }
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('UserNewListState')
      try {
        const [property] = self.sort || []

        self.policies = yield self.loadPaginatedAndFilteredPolicies({
          isClear: true,
          sort: property && `${property.desc ? '-' : ''}${property.id}`,
          filters: self.filters,
        })
      } finally {
        self.removeLoading('UserNewListState')
      }
    }),
    setSearch: flow(function* setSearch(search) {
      if (self.search !== search) {
        self.search = search
        self.page = 0
        yield self.loadDependencies()
      }
    }),
    onPaginationChange: flow(function* onPaginationChange({
      pageSize,
      pageIndex,
    }) {
      if (pageSize !== self.pageSize || pageIndex !== self.page) {
        self.pageSize = pageSize
        self.page = pageIndex
        yield self.loadDependencies()
      }
    }),
    onSortingChange: flow(function* onSortingChange(sort) {
      self.sort = sort
      yield self.loadDependencies()
    }),
    onFiltersChange: flow(function* onSortingChange(filters) {
      self.filters = filters
      yield self.loadDependencies()
    }),
    ...actions(self),
  }))
