import './styles.scss'
import { AddressProps } from '../../../types'
import { EditableFields, ReadOnlyFields } from './components'

export const AddressBlock = ({
  street,
  postalCode,
  locality,
  region,
  country,
  isEditing,
}: AddressProps) => {
  
  return (
    <div className="address-block">
      {isEditing ? (
        <EditableFields
          street={street}
          postalCode={postalCode}
          locality={locality}
          region={region}
          country={country}
        />
      ) : (
        <ReadOnlyFields
          street={street}
          postalCode={postalCode}
          locality={locality}
          region={region}
          country={country}
        />
      )}
    </div>
  )
}
