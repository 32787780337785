import familyBackground from './family.json'
import clinicalAssessment from './clinical.json'
import functionalAssessment from './functional.json'
import cognitiveAssessment from './cognitive.json'
import nutritionalAssessment from './nutritional.json'
import socialAssessment from './social.json'
import clock from './clock.json'
import health from './health.json'
import lobo from './lobo.json'
import summary from './summary.json'

export const MOCKED_CONTROL_TEST = { health, lobo, clock }

export const MOCKED_INITIAL_TESTS = {
  familyBackground,
  clinicalAssessment,
  functionalAssessment,
  cognitiveAssessment,
  nutritionalAssessment,
  socialAssessment,
  summary,
}
