import React, { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { TFunction } from 'i18next'

import { Button, Form, BUTTON_SIZES, BUTTON_TYPES } from '@shared/ui'
import { SRRadioButton } from '@sr/ui'
import { rejectOptions } from '@sr/routes/common'

import { POLICY_SUB_STATE_NAME, POLICY_SUB_STATES } from '../../../enums'

interface PoliceBlockProps {
  t: TFunction
  action: (form: { 'policy-options': string }) => void
  subState: number
}

type PolicySubStateKey = keyof typeof POLICY_SUB_STATES
type PolicySubStateValue = (typeof POLICY_SUB_STATES)[PolicySubStateKey]

const POLICY_SUB_STATE_NAME_TYPE: {
  [key in PolicySubStateValue]: string
} = POLICY_SUB_STATE_NAME

const getKeyByValue = (value: number): PolicySubStateKey | undefined => {
  return (Object.keys(POLICY_SUB_STATES) as Array<PolicySubStateKey>).find(
    (key) => POLICY_SUB_STATES[key] === value,
  )
}

export const PoliceBlock: React.FC<PoliceBlockProps> = ({
  t,
  action,
  subState,
}) => {
  const methodsChangePolicy = useForm()
  const [isEditing, setIsEditing] = useState(false)

  const openEdit = () => setIsEditing(true)
  const closeEdit = () => setIsEditing(false)

  const submit: SubmitHandler<{ 'policy-options': string }> = (data) => {
    closeEdit()
    action(data)
  }

  const key = getKeyByValue(subState)
  const displayState = key
    ? t(
        `common:policySubState.${
          POLICY_SUB_STATE_NAME_TYPE[POLICY_SUB_STATES[key]]
        }`,
      )
    : t('userArea.profile.actions.services.form.noInfo')

  return (
    <div className="mb-6">
      <div className="grid grid-cols-2">
        <div className="text-base font-semibold text-black mb-2">
          {t('userArea.profile.police.controlState')}
        </div>
        <div className="flex flex-col items-end pr-[55px]">
          {isEditing ? (
            <button
              title={t('common:cancel')}
              className="underline text-primary text-base font-semibold cursor-pointer"
              onClick={closeEdit}
            >
              {t('common:cancel')}
            </button>
          ) : (
            <button
              onClick={openEdit}
              className="underline text-black text-base text-primary font-semibold cursor-pointer"
            >
              {t('userArea.profile.actions.editProfile.button')}
            </button>
          )}
        </div>
      </div>
      {!isEditing && (
        <span className="text-dark-gray mb-4 text-base font-normal md:items-center">
          {displayState}
        </span>
      )}
      {isEditing && (
        <Form
          methods={methodsChangePolicy}
          data-testid="care-activities"
          onSubmit={submit as any}
        >
          <div className="flex items-center mb-2">
            <ul className="w-full flex flex-col text-black text-base font-normal">
              {rejectOptions(t).map((option, index) => (
                <li key={index} className="w-full">
                  <SRRadioButton
                    label={option.label}
                    className="accent-[#004039]"
                    value={option.value}
                    {...methodsChangePolicy.register('policy-options')}
                  />
                </li>
              ))}
            </ul>
          </div>
          <Button type={BUTTON_TYPES.submit} size={BUTTON_SIZES.small}>
            {t('common:save')}
          </Button>
        </Form>
      )}
    </div>
  )
}
