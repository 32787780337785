import { FC, ReactElement } from 'react'
import { useReportPdfContext } from '../../provider'
import { LayoutPage } from '../../components'
import { AreaColor } from '../../utils'
import { AuditiveAndOcularResults } from './results'
import { AuditiveAndOcularObjectives } from './objectives'

type SectionAuditiveAndOcularProps = {
  children: ReactElement
  sectionNumber: string
}

export const SectionAuditiveAndOcular: FC<SectionAuditiveAndOcularProps> = ({
  children,
  sectionNumber,
}) => {
  const { t } = useReportPdfContext()

  return (
    <LayoutPage
      headerText={t(
        'initial-report:sectionAuditiveAndOcular.layout.headerText',
        {
          number: sectionNumber,
        },
      )}
      noSpacing
      section={AreaColor.auditiveAndOcular}
      sectionNumber={sectionNumber}
    >
      <AuditiveAndOcularResults sectionLetter="A" />
      <AuditiveAndOcularObjectives sectionLetter="B" />
      {children}
    </LayoutPage>
  )
}
