import { BaseRouteState } from '@shared/data-access'
import { flow, getRoot, isRoot, types } from 'mobx-state-tree'
import { actionsCampaign } from '@sr/routes/common'
import { AnnuityModel } from '@sr/data-access'

export const UserNewListCampaignState = BaseRouteState.named(
  'UserNewListCampaignState',
)
  .props({
    annuities: types.maybeNull(types.array(types.reference(AnnuityModel))),
    pageSize: 15,
    page: 0,
    total: 0,
    search: '',
    sort: types.maybeNull(types.array(types.frozen({}))),
    filters: types.maybeNull(types.frozen({})),
  })
  .views((self) => ({
    pagination() {
      return {
        pageSize: self.pageSize,
        pageIndex: self.page,
      }
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('UserNewListCampaignState')
      try {
        const [property] = self.sort || []

        self.annuities = yield self.loadPaginatedAndFilteredAnnuities({
          isClear: true,
          sort: property && `${property.desc ? '-' : ''}${property.id}`,
          filters: self.filters,
        })
      } finally {
        self.removeLoading('UserNewListCampaignState')
      }
    }),
    setSearch: flow(function* setSearch(search) {
      if (self.search !== search) {
        self.search = search
        self.page = 0
        yield self.loadDependencies()
      }
    }),
    onPaginationChange: flow(function* onPaginationChange({
      pageSize,
      pageIndex,
    }) {
      if (pageSize !== self.pageSize || pageIndex !== self.page) {
        self.pageSize = pageSize
        self.page = pageIndex
        yield self.loadDependencies()
      }
    }),
    onSortingChange: flow(function* onSortingChange(sort) {
      self.sort = sort
      yield self.loadDependencies()
    }),
    onFiltersChange: flow(function* onSortingChange(filters) {
      self.filters = filters
      yield self.loadDependencies()
    }),
    loadPaginatedAndFilteredAnnuities: flow(
      function* loadPaginatedAndFilteredAnnuities({
        isClear = false,
        sort = null,
        filters = null,
      }) {
        if (isRoot(self)) return
        self.setLoading?.('loadPaginatedAndFilteredAnnuities')
        try {
          const annuities = yield getRoot(self).AnnuityStore.fetchPage(
            self.page + 1,
            {
              search: self.search || '',
              pageSize: self.pageSize,
              include: 'person,social_referent',
              params: {
                sort,
              },
              filter: {
                Annuity: {
                  socialReferent: {
                    value: filters?.socialReferent || null,
                  },
                  promPlanAppointment: {
                    value: filters?.promPlanAppointment?.value || null,
                  },
                },
              },
            },
          )

          if (annuities && annuities.items) {
            self.total = annuities.pagination?.count || 0
            if (self.annuities && !isClear) {
              return [...self.annuities, ...annuities.items]
            } else {
              return annuities.items
            }
          }
        } finally {
          self.removeLoading?.('loadPaginatedAndFilteredAnnuities')
        }
      },
    ),
    ...actionsCampaign(self),
  }))
