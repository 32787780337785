import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { SCHEDULE_FACE_TO_FACE, SCHEDULE_HOURS } from '@shared/ui'
import {
  getDateFormatted,
  changeTimeZoneDate,
  adjustDateString,
  formatDate,
  getLocalStorageItem,
} from '@shared/utils'


interface ScheduleBannerProps {
  date: string | Date | null | undefined
  noDigital: boolean
  dateBannerClasses?: string
  isMod: boolean
  appointmentDateWatched: string | Date | null | undefined
}

export const ScheduleBanner: React.FC<ScheduleBannerProps> = ({
  date,
  noDigital,
  isMod,
  appointmentDateWatched,
}) => {
  const { t } = useTranslation()
  const [message, setMessage] = useState<string>('')

  useEffect(() => {
    if (appointmentDateWatched) {
      setMessage(t('common:dateBannerTitle'))
    } else {
      setMessage(t('common:lastDateTitle'))
    }
  }, [appointmentDateWatched, t])

  const [dateSelectedFormatted, setDateSelectedFormatted] = useState<string | null>(null)
  const [timeSlotSelected] = useState<string | null>(getLocalStorageItem('slotsSelected'))

  useEffect(() => {
    if (date) {
      setDateSelectedFormatted(`${formatDate(date)} - ${getLocalStorageItem('slotsSelected')}`)
    }
  }, [date, timeSlotSelected])

  return (
    <div className='p-6 text-center text-dark-gray bg-modal-overlay mb-9' data-testid="ba-agenda-banner">
      <div className='text-sm'>
        {isMod ? message : t('common:dateBannerTitle')}
      </div>
      <div className='capitalize text-2xl font-semibold' data-testid="ba-agenda-banner-date">
        {dateSelectedFormatted}
      </div>
    </div>
  )
}
