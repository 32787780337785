import { Button, BUTTON_SIZES, InputSearch } from '@shared/ui'
import { SRTableList, usePortfolioTable } from '@sr/ui'
import { PortfolioManagementClientListRouteState } from './state'
import { useRouteState } from '@shared/utils'
import { ChangeEvent, FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'
import { usePortfolioContext } from '../../usePortfolioContext'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { useTranslation } from 'react-i18next'
import { InputContainer, ListContainer } from '../../common'

type ListProps = {
  filters?: any
}

export const List: FC<ListProps> = observer(({ filters }) => {
  const { t } = useTranslation()
  const { clients, setClients } = usePortfolioContext()
  const { id: socialReferentId } = useParams()
  const {
    isLoading,
    policies,
    onPaginationChange,
    pagination,
    total,
    setSearch,
    onSortingChange,
    onFiltersChange,
  } = useRouteState(PortfolioManagementClientListRouteState, {
    socialReferentId,
    filters,
  })

  const { columns } = usePortfolioTable()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const onRowSelection = (rowSelection: string[]) => {
    setClients(rowSelection)
  }
  const navigate = useNavigate()
  const onAssign = () => {
    navigate(ROUTE_PATH.portfolioManagementOp)
  }

  useEffect(() => {
    onFiltersChange(filters)
  }, [filters])

  return (
    <ListContainer>
      <InputContainer>
        <InputSearch onChange={onChange} />
        <Button
          size={BUTTON_SIZES.small}
          onClick={onAssign}
          disabled={!clients?.length}
        >
          {t('privateArea.portfolioManagement.assign')}
        </Button>
      </InputContainer>

      <SRTableList
        data={policies || []}
        columns={columns}
        initialPagination={pagination}
        onPaginationChange={onPaginationChange}
        total={total}
        onSortingChange={onSortingChange}
        isLoading={isLoading}
        enableRowSelection
        enableSelectAll
        onRowSelection={onRowSelection}
        preSelectedRows={clients}
      />
    </ListContainer>
  )
})
