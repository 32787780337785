import { Trans } from 'react-i18next'
import { Text, Image } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { FC } from 'react'
import { useReportPdfContext } from '../../provider'

interface ScriptProps {
  imageOne?: string
  imageTwo?: string
}

type TranscriptionScriptPDFProps = {
  script: string
  scriptProps?: object
  scriptImages?: ScriptProps
  style?: object
}

export const TranscriptionScriptPDF: FC<TranscriptionScriptPDFProps> = ({
  script,
  scriptProps,
  scriptImages,
  style,
}) => {
  const { t } = useReportPdfContext()

  return (
    <Text style={{ ...tw('leading-6'), ...style }}>
      <Trans
        i18nkey={script}
        values={scriptProps}
        components={{
          imageOne: <Image src={scriptImages?.imageOne} />,
          imageTwo: <Image src={scriptImages?.imageTwo} />,
          italic: <Text style={tw('italic')} />,
          italicUnderline: <Text style={tw('italic underline')} />,
          boldUnderline: <Text style={tw('font-semibold underline')} />,
          underline: <Text style={tw('underline')} />,
          bold: <Text style={tw('font-semibold')} />,
          br: <Text>{'\n'}</Text>,
        }}
        defaults={t(script)}
      />
    </Text>
  )
}
