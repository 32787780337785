import { getRoot, types } from 'mobx-state-tree'
import { DateString, RootModel } from '@shared/data-access'
import { TEST_MODALITY } from './enums'

export const MODEL_NAME = 'InitialAnalysisResults'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    modality: types.maybeNull(
      types.refinement(types.number, (value) =>
        Object.values(TEST_MODALITY).some((modality) => modality === value)
      )
    ),
    initialAnalysisList: types.maybeNull(types.string),
    testDate: types.maybeNull(DateString),
  })
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))

export default Model
