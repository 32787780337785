export const wizardContainerClasses = `
    py-8
`

export const wizardButtonContainerClasses = `
    flex
    justify-center
    items-center
    gap-4
    p-6
`
