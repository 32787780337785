import { Button } from '@shared/ui'
import { FC, ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  SR_CARD_SIZE,
  SR_CARD_SIZE_TYPES,
  SR_CARD_VARIANTS,
  SR_CARD_VARIANT_TYPES,
} from './enums'
import { getCardSize, getCardVariant, srCardLinkClasses } from './styles'

interface SRCardProps {
  icon?: ReactElement
  title?: string
  linkText?: string
  path: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pathParams?: any
  variant?: SR_CARD_VARIANT_TYPES
  size?: SR_CARD_SIZE_TYPES
  containerClasses?: string
  action?: () => void
  disabled?: boolean
}

export const SRCard: FC<SRCardProps> = ({
  icon,
  title,
  linkText,
  path,
  pathParams = {},
  variant = SR_CARD_VARIANTS.primary,
  size = SR_CARD_SIZE.medium,
  containerClasses = '',
  action,
  disabled = false,
}) => {
  const navigate = useNavigate()
  const [cardVariantClass, setCardVariantClass] = useState(
    getCardVariant(variant)
  )
  const [cardSizeClass, setCardSizeClass] = useState(getCardSize(size))

  useEffect(() => {
    setCardVariantClass(getCardVariant(variant))
    setCardSizeClass(getCardSize(size))
  }, [variant, size])

  const onClick = () => {
    if (action) action()
    navigate(path, { state: pathParams })
  }

  return (
    <Button
      testId="sr-card-button"
      className={`${cardSizeClass} ${cardVariantClass.container} ${containerClasses}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={cardVariantClass.content}>
        {icon ?? icon}
        <div className={cardVariantClass.title}>{title}</div>
        <span className={srCardLinkClasses}>{linkText}</span>
      </div>
    </Button>
  )
}
