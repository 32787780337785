import tw from 'tailwind-styled-components'

export const FilterBoxContainer = tw.div`
    flex
    flex-col
    gap-4
    w-[320px]
    min-w-[320px]
    mt-[13px]
`

export const FilterBoxHeader = tw.div`
    flex
    justify-between
    items-center
`

export const FilterBoxHeaderIconContainer = tw.div`
    flex
    items-center
    gap-2
    font-semibold
`

export const FilterBoxHeaderRemoveContainer = tw.div`
    cursor-pointer
`
