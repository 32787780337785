import { MultiSelectOptions } from '@shared/ui'

export const FORM_FILTER_NAME = Object.freeze({
  socialReferent: 'socialReferent',
  subscriptionAppointment: 'subscriptionAppointment',
  initialAnalysisAppointment: 'initialAnalysisAppointment',
})

export const scheduledOptions = (
  t: (trans: string) => string,
): MultiSelectOptions[] => [
  { label: t('common:scheduled'), value: '1' },
  { label: t('common:noScheduled'), value: '0' },
]
