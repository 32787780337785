import { Image, Link, Text, View } from '@react-pdf/renderer'
import { FC } from 'react'
import { tw } from '../../utils'
import { Html } from 'react-pdf-html'
import { SummaryLevel } from '../../provider/types'
import { SummaryLevels } from '../summary-levels'
import { executiveSummaryClasses } from './styles'
import { useReportPdfContext } from '../../provider'
import arrow from '../../assets/global/Arrow-right.png'

type SectionSummaryBlockProps = {
  number: number
  color: string
  title: string
  sectionLink: string
  worksheetLink: string
  content: string
  level: SummaryLevel
}

export const SectionSummaryBlock: FC<SectionSummaryBlockProps> = ({
  number,
  color,
  title,
  sectionLink,
  worksheetLink,
  content,
  level,
}) => {
  const { t } = useReportPdfContext()

  return (
    <>
      <View style={tw('mb-8')} wrap={false}>
        <View
          style={tw(
            'py-2 flex flex-row justify-between items-center border-t border-b border-lightGrey',
          )}
        >
          <View
            style={tw('flex flex-row gap-2 justify-start items-stretch w-1/2')}
          >
            <View style={tw(`mb-[-12px] text-${color} font-black text-2xl`)}>
              <Text>{String(number).padStart(2, '0')}</Text>
            </View>
            <View
              style={tw(`flex flex-col justify-center items-center font-bold`)}
            >
              <Link src={sectionLink} style={tw('no-underline text-black')}>
                {title}
              </Link>
            </View>
          </View>
          <View style={tw('w-1/2 pl-4')}>
            <SummaryLevels level={level} />
          </View>
        </View>
        <View style={tw('text-sm my-4')}>
          {content ? (
            <View>
              <Html
                stylesheet={executiveSummaryClasses}
              >{`<html><body>${content}</body></html>`}</Html>
            </View>
          ) : (
            <View>
              <Text>{t('initial-report:executiveSummary.noContent')}</Text>
            </View>
          )}
        </View>
      </View>
      {level !== SummaryLevel.optimal && (
        <View style={tw('text-sm mb-8')}>
          <Link
            src={worksheetLink}
            style={tw(
              'flex flex-row justify-between items-center no-underline text-black',
            )}
          >
            <View
              style={tw(
                'flex flex-row gap-2 items-center bg-midGrey px-4 py-2 font-bold rounded-2xl',
              )}
            >
              <Text>{t('initial-report:executiveSummary.showWorksheet')}</Text>
              <Image src={arrow} style={tw('w-4 h-4')} />
            </View>
          </Link>
        </View>
      )}
    </>
  )
}
