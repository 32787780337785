export const srCardListContainerClasses = `
    w-4/12
    xl:w-3/12
    bg-white
    border-2
    border-primary
    rounded-2xl
    p-3
    font-raleway
    leading-6
    h-5/6
`

export const srCardListTitleClasses = `
    text-center
    text-primary
    text-xl
    text-semibold
    border-b
    border-zinc-300
    pb-4
    pt-2

`
export const srCardListItemsContainer = `
    h-[90%]
    overflow-auto
    box-content
`

export const srCardListItemClasses = `
    flex
    items-center
    gap-3
    p-2
    border-b
    border-white
    border-b-zinc-300
    rounded
    hover:border
    hover:border-primary
    hover:bg-primary-opacity
`
