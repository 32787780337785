import { Button, Form } from '@shared/ui'
import { SRModal, SRSelect, SRSelectOptions } from '@sr/ui'
import { useTranslation } from 'react-i18next'
import { FORM_FIELDS } from '../enum'
import { NewTestForm } from './styles'

type CreateTestModalProps = {
  onClose: () => void
  onSubmit: () => void
  options: SRSelectOptions
  methods: any
  disabled?: boolean
}

export const CreateTestModal = ({
  onClose,
  onSubmit,
  options,
  methods,
  disabled = false,
}: CreateTestModalProps) => {
  const { t } = useTranslation()

  const {
    formState: { errors },
  } = methods

  const isDisabled = disabled || !options || !options.length

  return (
    <SRModal onClose={onClose}>
      <Form methods={methods} className={NewTestForm}>
        <SRSelect
          name={FORM_FIELDS.testType}
          options={options}
          hasNoneOption
          error={errors[FORM_FIELDS.testType] && t('testList.requiredError')}
        />
        <Button
          onClick={onSubmit}
          className="self-center"
          disabled={isDisabled}
        >
          {t('testList.buttonCreate')}
        </Button>
      </Form>
    </SRModal>
  )
}
