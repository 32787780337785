
import { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'

import { useRouteState } from '@shared/utils'
import {
  useLayoutContext,
  CareActions,
  GenericProfile,
} from '@sr/routes/common'
import { SRUserName } from '@sr/ui'

import { UserProfileRouteState } from './state'

import './styles.scss'

export const CareUserProfile: FC = observer(() => {
  const { id: policyId } = useParams()
  const {
    isLoading,
    policy,
    userPersonalData,
    isEditing,
    setEditingMode,
    savePolicyOnlyData,
    reload,
    defaultValues,
    clientPreferences,
  } = useRouteState(UserProfileRouteState, {
    policyId,
  })
    
  const { setIsLoading } = useLayoutContext()

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading, policyId])

  if (!policy) return

  return (
    <div className="care-user-profile">
      <SRUserName fullName={policy.fullName} />
      <GenericProfile
        personalData={userPersonalData}
        defaultValues={defaultValues}
        isEditing={isEditing}
        setEditingMode={setEditingMode}
        onSaveData={savePolicyOnlyData}
        ActionsComponent={
          <CareActions 
            reload={reload} 
            isEditing={isEditing} 
            preferences={clientPreferences}  
        />}
      />
    </div>
  )
})
