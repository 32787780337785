import { Sex } from '@shared/data-access'

type AuditCScoreProps = {
  score: number | string
  sex: number
}

export enum AuditCScoreResult {
  no,
  low,
  risk,
}

interface SCORE_NAME {
  [key: number]: string
}

export const AuditCScoreName: SCORE_NAME = Object.freeze({
  [AuditCScoreResult.no]: 'no',
  [AuditCScoreResult.low]: 'low',
  [AuditCScoreResult.risk]: 'risk',
})

export class AuditCScore {
  score: number | string
  result: number
  isMale: boolean
  no: number
  low: number
  risk: number
  scoreName: string
  scale: string

  constructor({ score, sex }: AuditCScoreProps) {
    this.score = score
    this.result = AuditCScoreResult.no
    this.isMale = Boolean(sex === Sex.male)
    this.no = 0
    this.low = this.isMale ? 5 : 4
    this.risk = 12
    this.scoreName = ''
    this.scale = `${score}/${this.risk}`
    this.init()
  }

  private init() {
    this.setResult()
    this.setScoreName()
  }

  private setResult() {
    if (typeof this.score === 'string') {
      return
    }
    if (this.score === this.no) {
      this.result = AuditCScoreResult.no
    }
    if (this.score > this.no && this.score < this.low) {
      this.result = AuditCScoreResult.low
    }
    if (this.score >= this.low && this.score <= this.risk) {
      this.result = AuditCScoreResult.risk
    }
  }

  private setScoreName() {
    this.scoreName = AuditCScoreName[this.result]
  }
}
