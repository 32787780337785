import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OPTableObject } from '../types'

export const useOpTable = () => {
  const { t } = useTranslation()

  const columns = useMemo<MRT_ColumnDef<OPTableObject>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('common:name'),
      },
      {
        accessorKey: 'surname',
        header: t('common:surname'),
      },
      {
        accessorKey: 'preferredLanguage',
        header: t('common:language'),
        Cell: ({ renderedCellValue }) => (
          <div>
            {renderedCellValue
              ? t(`common:languages.${renderedCellValue}`)
              : t('common:notIndicated')}
          </div>
        ),
      },
      {
        accessorKey: 'locality',
        header: t('common:locality'),
      },
      {
        accessorKey: 'postalCode',
        header: t('common:postalCode'),
      },
    ],
    [],
  )

  return { columns }
}
