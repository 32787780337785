import { Image, Text, View } from '@react-pdf/renderer'
import { SubSection, Tips, TitleIcon } from '../../../components'
import { FC } from 'react'
import {
  AreaColor,
  COGNITIVE_AND_SOCIAL_CLASSES,
  tw,
} from '../../../utils/styles'
import { useReportPdfContext } from '../../../provider'
import socialIcon from '../../../assets/social/location--heart-white.png'

type SocialGuideLinesProps = {
  sectionLetter: string
}

export const SocialGuideLines: FC<SocialGuideLinesProps> = ({
  sectionLetter,
}) => {
  const { t, recommendations } = useReportPdfContext()

  const TRANSLATION_BASE_KEY = 'initial-report:sectionSocial.guidelines'

  return (
    <View style={tw('px-12')}>
      <SubSection
        title={t('initial-report:subSection.guidelines.title', {
          letter: sectionLetter,
        })}
      >
        <View style={tw('flex flex-col gap-6')}>
          <Text>{t(`${TRANSLATION_BASE_KEY}.description`)}</Text>
          <TitleIcon
            title={t(`${TRANSLATION_BASE_KEY}.title`)}
            icon={<Image style={tw('w-5 h-5')} src={socialIcon} />}
            areaColor={AreaColor.social}
          />
          <Tips classes={COGNITIVE_AND_SOCIAL_CLASSES}>
            {recommendations?.SOCIAL_GUIDELINES || ''}
          </Tips>
        </View>
      </SubSection>
    </View>
  )
}
