import { types } from 'mobx-state-tree'
import { Model, MODEL_NAME } from './model'
import { ModelStore } from '../core/model-store'

const API_URL = '/persons'

export const Store = ModelStore.named(`${MODEL_NAME}Store`).props({
  url: API_URL,
  map: types.map(Model),
})

export default Store
