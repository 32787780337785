import menuIcon from './menu.png'
import locked from './locked.png'
import settingsIcon from './settings.png'
import refreshIcon from './rotate--360.png'
import videoIcon from './video-icon-permissions.png'
import menuHorizontalIcon from './menu--horizontal.png'
import menuVerticalIcon from './menu--vertical.png'
import iconChrome from './pcSteps-icon-chrome.png'
import iconEdge from './pcSteps-icon-edge.png'
import iconFirefox from './pcSteps-icon-firefox.png'
import iconSafari from './appleSteps-icon-Safari.png'
import pcChromeBars from './pcSteps-bars-chorme-permissions.png'
import pcFirefoxBars from './pcSteps-bars-firefox-permissions.png'
import pcEdgeBars from './pcSteps-bars-edge-permissions.png'
import appleSafariBars from './appleSteps-bars-safari-permissions.png'
import mobileChromeBars from './pcSteps-bars-mobile-chrome-permissions.png'
import mobileFirefoxBars from './pcSteps-bars-mobile-firefox-permissions.png'
import mobileSafariBars from './appleSteps-bars-mobile-safari-permissions.png'
import mobileAppleChromeBars from './appleSteps-bars-mobile-chrome-permissions.png'
import applePopUpSafariOne from './appleSteps-popup-safari-permissions-one.png'
import applePopUpSafariTwo from './appleSteps-popup-safari-permissions-two.png'
import applePromptFirefox from './appleSteps-prompt-firefox.png'
import pcPopUpChromeOne from './pcSteps-popup-chrome-permissions-one.png'
import pcPopUpChromeTwo from './pcSteps-popup-chrome-permissions-two.png'
import pcPopUpChromeThree from './pcSteps-popup-chrome-permissions-three.png'
import pcPopUpEdgeOne from './pcSteps-popup-edge-permissions-one.png'
import pcPopUpEdgeTwo from './pcSteps-popup-edge-permissions-two.png'
import pcPopUpFirefoxOne from './pcSteps-popup-firefox-permissions-one.png'
import pcPopUpFirefoxTwo from './pcSteps-popup-firefox-permissions-two.png'
import pcPromptChrome from './pcSteps-prompt-chrome.png'
import pcPromptFirefox from './pcSteps-prompt-firefox.png'
import pcPromptMobileFirefox from './pcSteps-prompt-mobile-firefox.png'
import pcPromptEdge from './pcSteps-prompt-edge.png'
import mobileApplePopupSafariOne from './appleSteps-popup-mobile-safari-permissions-one.png'
import mobileApplePopupSafariTwo from './appleSteps-popup-mobile-safari-permissions-two.png'
import mobileApplePopupChromeOne from './appleSteps-popup-mobile-chrome-permissions-one.png'
import mobileApplePopupChromeTwo from './appleSteps-popup-mobile-chrome-permissions-two.png'
import mobilePopupChromeOne from './pcSteps-popup-mobile-chrome-permissions-one.png'
import mobilePopupFirefoxOne from './pcSteps-popup-mobile-firefox-permissions-one.png'
import mobilePopupFirefoxTwo from './pcSteps-popup-mobile-firefox-permissions-two.png'
import mobilePopupFirefoxThree from './pcSteps-popup-mobile-firefox-permissions-thre.png'
import mobileChromePermissionsPrompt from './chrome-mobile-permissions-prompt.png'

export {
  menuIcon,
  locked,
  menuHorizontalIcon,
  menuVerticalIcon,
  settingsIcon,
  refreshIcon,
  videoIcon,
  iconChrome,
  iconEdge,
  iconFirefox,
  iconSafari,
  pcChromeBars,
  pcFirefoxBars,
  pcEdgeBars,
  appleSafariBars,
  applePopUpSafariOne,
  applePopUpSafariTwo,
  pcPopUpChromeOne,
  pcPopUpChromeTwo,
  pcPopUpChromeThree,
  pcPopUpFirefoxOne,
  pcPopUpFirefoxTwo,
  pcPopUpEdgeOne,
  pcPopUpEdgeTwo,
  mobileApplePopupChromeOne,
  mobileApplePopupChromeTwo,
  mobileApplePopupSafariOne,
  mobileApplePopupSafariTwo,
  mobileAppleChromeBars,
  mobileSafariBars,
  mobileChromeBars,
  mobileFirefoxBars,
  mobilePopupChromeOne,
  mobileChromePermissionsPrompt,
  mobilePopupFirefoxOne,
  mobilePopupFirefoxTwo,
  mobilePopupFirefoxThree,
  pcPromptChrome,
  pcPromptFirefox,
  pcPromptMobileFirefox,
  pcPromptEdge,
  applePromptFirefox,
}
