import tw from 'tailwind-styled-components'

export const TestModalActions = tw.div`
    flex
    justify-center
    items-center
    gap-4
    mb-10
`
export const TestModalContent = tw(TestModalActions)`
    p-4
`

export const TestModalFormContainer = tw.div`
    flex
    flex-col
    justify-center
    items-center
    gap-4
    p-4
    mb-32
    font-medium
`
