import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import './styles.scss'

interface SwitcherProps {
  label?: string
  dataSentPostal?: string
  initialChecked?: 'off' | 'on' | 'disabled'
  status?: 'off' | 'on' | 'disabled'
  disabled?: boolean
  onChange?: (state: 'off' | 'on' | 'disabled') => void
}

export const Switcher: React.FC<SwitcherProps> = ({
  label,
  initialChecked = 'off',
  dataSentPostal = '',
  status,
  disabled = false,
  onChange,
}) => {
  const { t } = useTranslation()

  const [state, setState] = useState<'off' | 'on' | 'disabled'>(status ?? initialChecked)

  const [labelChanged, setLabelChanged] = useState(label)

  useEffect(() => {
    const getLabelKey = () => {
      if (status === 'disabled' && dataSentPostal) return 'analysis:toggleOptions.onLabel'
      if (status === 'disabled') return 'analysis:toggleOptions.disabledLabel'
      if (status === 'off') return 'analysis:toggleOptions.offLabel'
      if (status === 'on') return 'analysis:toggleOptions.onLabel'
      return 'analysis:toggleOptions.disabledLabel'
    }
  
    const labelKey = getLabelKey()
    setLabelChanged(t(labelKey))
  }, [status, t, dataSentPostal])

  const handleToggle = useCallback(() => {
    if (status !== 'disabled') {
      const newState = status === 'off' ? 'on' : 'off'
      setState(newState)
      onChange?.(newState)
    }
  }, [status, onChange])

  const getContainerClass = () =>
    `flex items-center ${status === 'disabled' || status === 'on' ? 'cursor-not-allowed' : 'cursor-pointer'}`
  const getSwitcherClass = () =>
    `block w-14 h-8 rounded-full transition-colors ${status === 'on' ? 'bg-primary' : 'bg-input-disabled-bg'}`
  const getDotClass = () =>
    `dot absolute left-[4px] top-[4px] w-6 h-6 rounded-full transition-transform 
      ${status === 'on' ? 'translate-x-full bg-white' : status === 'off' ? 'translate-x-0 bg-white' : 'bg-dot-disabled-bg'}`
  
  return (
    <div className="flex w-full justify-end items-start align-top">
      <label htmlFor="toggle" className={getContainerClass()}>
        <div className="relative">
          <input
            type="checkbox"
            id="toggle"
            checked={status === 'on'}
            onChange={handleToggle}
            className="sr-only"
            disabled={status === 'disabled' || status === 'on'}
          />
          <div className={getSwitcherClass()}></div>
          <div className={getDotClass()}></div>
        </div>
        {label && (
          <div
            className={`flex flex-col text-xs items-end self-end w-[75px] ${
              state === 'disabled' ? 'text-gray-500 ml-2' : 'text-gray-700'
            }`}
          >
            <span className="min-h-[16px] len-4">{labelChanged}</span>
            <span className="min-h-[16px] len-4">{dataSentPostal && moment(dataSentPostal).format('DD/MM/YYYY')}</span>
          </div>
        )}
      </label>
    </div>
  )
}
