import { AccountCircle } from '@mui/icons-material'
import { ListItemIcon, MenuItem } from '@mui/material'
import { ActionsComponentProps } from '@shared/ui'
import { ROUTE_PATH_CAMPAIGN } from 'apps/social-referent/src/routes/enums'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const ActionsComponent: (
  props: ActionsComponentProps,
) => ReactNode[] = ({ closeMenu, row }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return [
    <MenuItem
      key={0}
      onClick={() => {
        navigate(ROUTE_PATH_CAMPAIGN.userArea(row?.original?.id))
        closeMenu()
      }}
      sx={{ m: 0 }}
    >
      <ListItemIcon>
        <AccountCircle />
      </ListItemIcon>
      {t('userList.table.actions.goToProfile')}
    </MenuItem>,
  ]
}
