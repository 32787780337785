import { useRouteState } from '@shared/utils'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useEffect } from 'react'
import { UserNewListCampaignState } from './state'
import { SRTableList, useCampaignTable } from '@sr/ui'
import { InputSearch } from '@shared/ui'
import { ActionsComponent } from './components'

type NewListProps = {
  filters?: any
}

export const NewList: FC<NewListProps> = observer(({ filters }) => {
  const {
    isLoading,
    annuities,
    onPaginationChange,
    pagination,
    total,
    setSearch,
    onSortingChange,
    onFiltersChange,
  } = useRouteState(UserNewListCampaignState)

  const { columns } = useCampaignTable()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    onFiltersChange(filters)
  }, [filters])

  return (
    <div className="flex flex-col pb-20 w-[80%]">
      <InputSearch onChange={onChange} />
      <SRTableList
        data={annuities || []}
        columns={columns}
        initialPagination={pagination}
        onPaginationChange={onPaginationChange}
        total={total}
        actionsComponent={ActionsComponent}
        isLoading={isLoading}
        onSortingChange={onSortingChange}
      />
    </div>
  )
})
