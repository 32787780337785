import { BaseRouteState, Policy, SocialReferent } from '@shared/data-access'
import { Appointment, UnavailableSlotModel } from '@sr/data-access'
import { actions } from '@sr/routes/common'
import { types } from 'mobx-state-tree'
import { stateActions } from './actions'
import { views } from './views'

export const DashboardSRRouteState = BaseRouteState.named(
  'DashboardSRRouteState'
)
  .props({
    appointments: types.maybeNull(
      types.array(types.reference(Appointment), {})
    ),
    unavailableSlots: types.maybeNull(
      types.array(types.reference(UnavailableSlotModel), {})
    ),
    policies: types.maybeNull(types.array(types.reference(Policy))),
    user: types.maybeNull(types.reference(SocialReferent)),
  })
  .volatile(() => ({
    onSelectEvent: null,
    onSelectUser: null,
  }))
  .views(views)
  .actions((self) => ({
    ...stateActions(self),
    ...actions(self),
  }))
