import { Images } from '../../assets/images'
import { LOGO_HEADER_TYPE } from './enums'
import {
  wrapperClassName,
  flexCenterClassName,
  logoMainClassName,
  logoSecondaryClassName,
} from './styles'

export const LogoHeader = ({
  wrapperClasses = '',
  logoContainerClasses = '',
  actionsClasses = '',
  logoChildren = null,
  onClickLogo = null,
  actionsComponent = null,
  middleComponent = null,
  type = LOGO_HEADER_TYPE.primary,
}) => {
  const logoImage = {
    primary: Images.MainLogoWhite,
    secondaryQida: Images.mainLogo,
  }[type]

  return (
    <header
      className={`${wrapperClassName({ [type]: true })} ${wrapperClasses}`}
    >
      <div className={`${flexCenterClassName} ${logoContainerClasses}`}>
        {logoChildren}
        {type === LOGO_HEADER_TYPE.secondary ? (
          <Images.CaixaLogoWithTextSvg
            className={`${logoSecondaryClassName} ${
              onClickLogo ? 'cursor-pointer' : ''
            }`}
            onClick={onClickLogo}
          />
        ) : (
          <img
            src={logoImage}
            className={`${logoMainClassName} ${
              onClickLogo ? 'cursor-pointer' : ''
            }`}
            alt="Qida"
            onClick={onClickLogo}
          />
        )}
      </div>
      {middleComponent}
      <div className={`${flexCenterClassName} ${actionsClasses}`}>
        {actionsComponent}
      </div>
    </header>
  )
}
