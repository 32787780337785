export const SECTION_VALUES = Object.freeze({
  "nutritional": {
    title: '',
    sectionColor: '',
    list: [],
  },
  "default": {
    title: '',
    sectionColor: '',
    list: [],
  },
})
