import tw from 'tailwind-styled-components'

export const LoginContainer = tw.div`
    flex
    flex-col
    justify-center
    items-center
    w-full
    h-full
    gap-5
`

export const LoginBox = tw.div`
    w-[300px]
`

export const QidaLogo = tw.img`
    mb-36
`

export const LoginText = tw.div`
    text-center
    font-montserrat
    font-light
    text-primary
    text-xl
`
