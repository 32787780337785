import { SRModal } from '@sr/ui'
import { BUTTON_COLORS, BUTTON_SIZES, Button } from '@shared/ui'
import {
  ModalConfirmUnsuscribeContent,
  ModalConfirmUnsuscribeWrapper,
} from './styles'
import { useTranslation } from 'react-i18next'

export const ModalConfirmUnsuscribe = ({ onClose, user, onConfirm }) => {
  const { t } = useTranslation()

  return (
    <SRModal>
      <ModalConfirmUnsuscribeWrapper>
        <ModalConfirmUnsuscribeContent>
          <p className="font-semibold">{t('myProfile.areYouSure')}</p>
        </ModalConfirmUnsuscribeContent>
        <div className="flex gap-4 justify-center">
          <Button
            color={BUTTON_COLORS.secondary}
            size={BUTTON_SIZES.small}
            onClick={onClose}
          >
            {t('common:cancel')}
          </Button>
          <Button size={BUTTON_SIZES.small} onClick={onConfirm}>
            {t('common:confirm')}
          </Button>
        </div>
      </ModalConfirmUnsuscribeWrapper>
    </SRModal>
  )
}
