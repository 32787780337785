import { inputSearchClasses } from './style'
import './style.css'

export const InputSearch = ({
  inputSearchClassName = '',
  onChange,
  name = 'InputSearch',
}) => {
  return (
    <input
      className={`${inputSearchClasses} ${inputSearchClassName}`}
      data-testid="InputSearch"
      name={name}
      type="search"
      onChange={onChange}
    />
  )
}
