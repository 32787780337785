import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { useSchema } from './schema'

import { useLayoutContext } from '@sr/routes/common/layout-provider'

import { UserInfo, EditableType, } from '@sr/ui'
import { Button, Form, BUTTON_SIZES } from '@shared/ui'

import './styles.scss'

type FormProps = {
  [x: string]: unknown
}

type GenericProfileProps = {
  personalData: []
  isEditing: boolean
  editableType?: EditableType
  setEditingMode: (mode: boolean) => void
  onSaveData: (form: FormProps) => void
  ActionsComponent?: ReactNode
  defaultValues?: any
  name?: string
  label?: string
  value?: string
  selectOptions?: []
  selectValue?: string
}

export const GenericProfile: FC<GenericProfileProps> = ({
  personalData,
  isEditing,
  setEditingMode,
  onSaveData,
  ActionsComponent,
  defaultValues = {},
}) => {
  const { t } = useTranslation()

  const { isLoading } = useLayoutContext()

  const onStartEditing = () => {
    setEditingMode(true)
  }

  const schema = useSchema(t)
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { dirtyFields },
  } = methods

  const onSubmit = handleSubmit((form) => {
    const modifiedFormData = Object.fromEntries(
      Object.entries(form).filter(([key]) => dirtyFields[key]),
    )

    onSaveData(modifiedFormData)
    setEditingMode(false)
  })

  const onCancel = () =>{
    setEditingMode(false)
  }

  return (
    <div className="w-full grid grid-cols-1 max-w-4xl items-center justify-center self-center">
      <Form methods={methods} className="w-full" dataTestId='profile'>
        <div className="grid grid-cols-2 w-full">
          <div className="text-primary text-xl font-semibold mb-5">
            {t('userArea.profile.personalData.title')}
          </div>
          <div className='flex flex-col items-end pr-[55px]'>
            {isEditing ? (
              <a onClick={onCancel} title={t('common:cancel')} className='underline text-primary font-semibold cursor-pointer'>
                {t('common:cancel')}
              </a>
            ) : (
              <a onClick={onStartEditing} className='underline text-primary font-semibold cursor-pointer'>{t('userArea.profile.actions.editProfile.button')}</a>
            )}
          </div>
        </div>
        <UserInfo data={personalData} />
        { isEditing && (
          <div className='mb-6'>
            <Button
              onClick={onSubmit}
              size={BUTTON_SIZES.small}
              disabled={isLoading}
            >
              {t('common:save')}
            </Button>
          </div>
        )}
      </Form>
      <hr className='bg-gray-200 w-full h-px' />
      <div className="text-primary text-xl font-bold mt-5" data-testid="actions-profile">
        {ActionsComponent}
      </div>
    </div>
  )
}
