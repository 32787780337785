export const modalOverlayClasses = `
    overflow-y-auto
    overflow-x-hidden
    fixed
    top-0
    right-0
    left-0
    z-50
    w-full
    h-full
    flex
    items-center
    justify-center
    bg-modal-overlay
`

export const modalContainerClasses = `
    p-6
    bg-white
    text-dark-gray
    rounded-lg
    md:w-[650px]
    md:h-[350px]
    gap-3
    flex
    flex-col
    justify-center
    items-center
`
