import { FC, ReactElement } from 'react'
import { SRCardListItem } from './sr-card-list-item'
import {
  srCardListTitleClasses,
  srCardListContainerClasses,
  srCardListItemsContainer,
} from './styles'

interface SRCardListProps {
  title: string
  list: {
    icon: ReactElement
    text: string
    action: () => void
  }[]
  defaultIcon?: ReactElement
}

export const SRCardList: FC<SRCardListProps> = ({
  title = '',
  list = [],
  defaultIcon = null,
}) => {
  return (
    <div className={srCardListContainerClasses}>
      <div className={srCardListTitleClasses}>{title}</div>
      <div className={srCardListItemsContainer}>
        {list.map(({ icon, text = '', action }, ix) => {
          if (!text) return null
          const leftIcon = icon || defaultIcon
          return (
            <SRCardListItem
              key={`card-list-${ix}`}
              icon={leftIcon}
              action={action}
              text={text}
            />
          )
        })}
      </div>
    </div>
  )
}
