import { ReportPdfContextProps } from '../../../../provider'
import { MedasScoreResult } from '../../../../scales'
import { scoreIsNotAvailable } from '../../../../utils'

export const getMedasScaleList = (context: ReportPdfContextProps) => {
  const { t, testDate, scores } = context
  const { score, scale, scoreName } = scores?.medasScore || {}

  return [
    {
      label: t('initial-report:generic.resultTable.scaleDescription'),
      content: t('initial-report:scores.medas.description'),
    },
    {
      label: t('initial-report:generic.resultTable.testDate'),
      content: testDate,
    },
    {
      label: t('initial-report:generic.resultTable.result'),
      content: scoreIsNotAvailable(score)
        ? t('initial-report:resultNotAvailable.medas')
        : t(`initial-report:scores.medas.${scoreName}.value`, {
            scale,
          }),
    },
  ]
}

export const getMedasPerYearList = (context: ReportPdfContextProps) => {
  const { t, scores } = context
  const { result } = scores?.medasScore || {}

  return [
    {
      text: '< 9',
      subText: t('initial-report:scores.medas.lowAdherence.value'),
      selected: result === MedasScoreResult.lowAdherence,
    },
    {
      text: '>= 9',
      subText: t('initial-report:scores.medas.highAdherence.value'),
      selected: result === MedasScoreResult.highAdherence,
    },
  ]
}
