export const AvailabilityCalendarBanner = ({
  dateFormatted,
}: {
  dateFormatted: string
}) => {
  return (
    <div className="p-6 mt-12 text-center text-white bg-modal-overlay rounded-md">
      <p className="text-xs">Cita seleccionada</p>
      <p className="text-2xl capitalize">{dateFormatted}</p>
    </div>
  )
}
