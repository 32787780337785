import { Image, Link, Text, View } from '@react-pdf/renderer'
import { SubSection } from '../SubSection'
import { areaColors, tw } from '../../utils'
import { useReportPdfContext } from '../../report-pdf-provider'

const iconName = {
  idea: 'idea',
  request: 'request',
}

export const LinkList = ({ title, list, areaColor, icon }) => {
  const { t } = useReportPdfContext()

  const image = require(`../../assets/${iconName[icon]}-${areaColor}.png`)

  return (
    <View break>
      <SubSection title={title}>
        <View style={tw('flex flex-col gap-2')}>
          {list.map((link, index) => (
            <View key={index}>
              {index === 0 && (
                <View style={tw('w-full h-px bg-lightGrey mx-10 mb-2')}></View>
              )}
              <View
                style={tw('flex flex-row items-center gap-16 justify-between')}
              >
                <View
                  style={tw(
                    'flex-1 flex flex-row items-center gap-2 text-black'
                  )}
                >
                  <Image
                    src={image}
                    style={{
                      width: '25px',
                      height: '25px',
                    }}
                  />
                  <Text style={tw('leading-6')}>{link.text}</Text>
                </View>
                <View>
                  <Link
                    style={tw(
                      `text-[${areaColors[areaColor]}] font-semibold no-underline`
                    )}
                    src={link.src}
                  >
                    {t('initial-report:linkButton')}
                  </Link>
                </View>
              </View>
              <View style={tw('w-full h-px bg-lightGrey mx-10 mt-2')}></View>
            </View>
          ))}
        </View>
      </SubSection>
    </View>
  )
}
