import * as yup from 'yup';

export const yupEmailVCX = (t) => {
  const invalidDomain = t('common:errors.emailVCXNotSupported');
  
  return yup
    .string()
    .email()
    .nullable()
    .test({
      name: 'is-domain-valid',
      test(value, ctx) {
        const domainRegex = /@(caixabank|vidacaixa)\.[a-zA-Z]{2,}$/;
        if (value && domainRegex.test(value)) {
          return ctx.createError({ message: invalidDomain });
        }
        return true;
      },
    })
    .label(t('common:email'));
};
