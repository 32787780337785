import { Fragment, useEffect, useState } from 'react'
import { Star, StarFilled } from '@carbon/icons-react'
import { useTranslation } from 'react-i18next'

export const Rating = ({ className, score = 0, onClickScore }) => {
  const { t } = useTranslation()
  const [rating, setRating] = useState([])

  useEffect(() => {
    generateRatingStars()
  }, [score])

  const generateRatingStars = () => {
    let rating = []
    for (let i = 0; i < 5; i++) {
      if (i < score) {
        rating.push(
          <StarFilled
            id={`StarFilled${i}`}
            data-testid="StarFilled"
            className="text-secondary cursor-pointer"
            size={40}
            onClick={() => onClickScore(i + 1)}
          />
        )
      } else {
        rating.push(
          <Star
            id={`Star${i}`}
            data-testid="Star"
            className="text-gray-400 cursor-pointer"
            size={40}
            onClick={() => onClickScore(i + 1)}
          />
        )
      }
    }
    setRating(rating)
  }

  return (
    <div className={`${className} flex flex-col items-center gap-4`}>
      <p className="text-xl">{t('rating.like')}</p>
      <div className="flex gap-4">
        {rating.map((star, index) => {
          return <Fragment key={index}>{star}</Fragment>
        })}
      </div>
    </div>
  )
}
