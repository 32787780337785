import { VideoOff } from '@carbon/icons-react'

import { useTranslation } from 'react-i18next'
import { MeetLogo } from '../meet-logo'
import {
  UnsupportedBrowserAvailable,
  UnsupportedBrowserContent,
  UnsupportedBrowserLogoText,
  UnsupportedBrowserLogoWrapper,
  UnsupportedBrowserVideo,
  UnsupportedBrowserWrapper,
} from './style'
import { TranscriptionScript } from '../../../test-script'
import { useVideoContext } from '../../hooks'
import { BRANDING_KEYS } from '../../utils/branding'

export const UnSupportedBrowser = () => {
  const { t } = useTranslation()
  const { branding } = useVideoContext()

  return (
    <div className={UnsupportedBrowserWrapper}>
      <div className={UnsupportedBrowserLogoWrapper}>
        <MeetLogo />
        {branding?.branding === BRANDING_KEYS.VCX && (
          <p className={UnsupportedBrowserLogoText}>
            {t('common:videoCall.title')}
          </p>
        )}
      </div>
      <div className={UnsupportedBrowserContent}>
        <div className={UnsupportedBrowserVideo}>
          <VideoOff
            size={150}
            className={`mx-auto h-full ${branding?.primaryColor}`}
          />
        </div>
        <div className={UnsupportedBrowserAvailable}>
          <TranscriptionScript
            script={t('common:videoCall.unsupportedBrowser')}
          />
        </div>
      </div>
    </div>
  )
}
