import { TFunction } from 'i18next'
import { configOptions } from './interfaces'

const TRANSLATION_BASE_FORM_KEY = 'userArea.profile.preferences'

interface OptionValues {
    alcoholInterest?: boolean
    activeMindInterest?: boolean
    auditiveCareInterest?: boolean
    clientLocalized?: number
    contactPredisposition?: number
    contact_11_to_13?: boolean
    contact_13_to_15?: boolean
    contact_15_to_17?: boolean
    contact_17_to_18?: boolean
    contact_9_to_11?: boolean
    contentPlatformInterest?: number
    earlyDetectionInterest?: boolean
    emailPreferred?: boolean
    emotionalBeingInterest?: boolean
    followUp?: number
    notes?: string
    neurodegenerativeIllInterest?: boolean
    newLinesInterest?: boolean
    nutritionInterest?: boolean
    ocularCareInterest?: boolean
    phoneCallPreferred?: boolean
    physicalActivityInterest?: boolean
    preventionPlanServiceInterest?: number
    riskPlan?: number
    sleepInterest?: boolean
    socialActivityInterest?: boolean
    srConfidence?: number
    srServiceInterest?: number
    textPreferred?: boolean
    tobaccoInterest?: boolean
}

export function updateConfigValues(options: configOptions, updatedValues: string | undefined) {
    return {
        ...options,
        value: updatedValues !== undefined ? updatedValues : '',
    }
}

// Client status options [RADIO]
export const clientStatusOptions = (values: OptionValues) => (t: TFunction): configOptions => {
    return {
        title: {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.statusClientTitle`),
        },
        options: [
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.statusClient.locatable`),
                type: 'radio',
                value: 0,
                selected: values.clientLocalized === 0,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.statusClient.notLocatable`),
                type: 'radio',
                value: 1,
                selected: values.clientLocalized === 1,
            },
        ],
        type: 'radio',
        selected: values.clientLocalized,
        selectedRadioLabel: 
            values.clientLocalized === 0 
            ? t(`${TRANSLATION_BASE_FORM_KEY}.statusClient.locatable`) 
            : values.clientLocalized === 1 
            ? t(`${TRANSLATION_BASE_FORM_KEY}.statusClient.notLocatable`) 
            : '', 
    }
}

// Predisposable communication options [RADIO]
export const communicationPredisposablesOptions = (values: any) => (t: TFunction): configOptions => {


    return {
        title: {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.predisposableCommunicationTitle`),
        },
        options: [
            {   label: t(`${TRANSLATION_BASE_FORM_KEY}.predisposableCommunication.yes`), 
                type: 'radio', 
                value: 0,
                selected: values.contactPredisposition === 0
            },
            {   label: t(`${TRANSLATION_BASE_FORM_KEY}.predisposableCommunication.no`), 
                type: 'radio', 
                value: 1,
                selected: values.contactPredisposition === 1
            },
        ],
        type: 'radio',
        selected: values.contactPredisposition,
        selectedRadioLabel: 
            values.contactPredisposition === 0 
            ? t(`${TRANSLATION_BASE_FORM_KEY}.predisposableCommunication.yes`) 
            : values.contactPredisposition === 1 
            ? t(`${TRANSLATION_BASE_FORM_KEY}.predisposableCommunication.no`) 
            : '', 
    }
}

// Preferred channel options [CHECKBOX]
export const preferedChannelOptions = (values: OptionValues) => (t: TFunction): configOptions => {
    const selectedCheckboxLabels = [
        values?.textPreferred === true ? t(`${TRANSLATION_BASE_FORM_KEY}.preferedChannel.text`) : '', 
        values?.emailPreferred === true ? t(`${TRANSLATION_BASE_FORM_KEY}.preferedChannel.email`) : '', 
        values?.phoneCallPreferred === true ? t(`${TRANSLATION_BASE_FORM_KEY}.preferedChannel.call`) : '', 
    ]
    .filter(label => label)

    return {
        title: {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.preferedChannelTitle`),
        },
        options: [
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.preferedChannel.call`),
                type: 'checkbox',
                value: 'phone_call_preferred',
                selected: values.phoneCallPreferred || false, 
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.preferedChannel.email`),
                type: 'checkbox',
                value: 'email_preferred',
                selected: values.emailPreferred || false, 
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.preferedChannel.text`),
                type: 'checkbox',
                value: 'text_preferred',
                selected: values.textPreferred || false, 
            },
        ],
        type: 'checkbox',
        selectedCheckboxLabels: selectedCheckboxLabels.length > 0 ? selectedCheckboxLabels : undefined,
    }
}

// Schedule preferences options [CHECKBOX]
export const schedulePreferOptions = (values: OptionValues) => (t: TFunction): configOptions => {
    const selectedCheckboxLabels =[
        values?.contact_9_to_11 === true ? t(`${TRANSLATION_BASE_FORM_KEY}.preferedTimetable.9a11`) : '', 
        values?.contact_11_to_13 === true ? t(`${TRANSLATION_BASE_FORM_KEY}.preferedTimetable.11a13`) : '', 
        values?.contact_13_to_15 === true ? t(`${TRANSLATION_BASE_FORM_KEY}.preferedTimetable.13a15`) : '',
        values?.contact_15_to_17 === true ? t(`${TRANSLATION_BASE_FORM_KEY}.preferedTimetable.15a17`) : '', 
        values?.contact_17_to_18 === true ? t(`${TRANSLATION_BASE_FORM_KEY}.preferedTimetable.17a18`) : '', 
    ]
    .filter(label => label)

    return {
        title: {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.preferedTimetableTitle`),
        },
        options: [
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.preferedTimetable.9a11`),
                type: 'checkbox',
                value:'contactNineToEleven',
                selected: values.contact_9_to_11 || false,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.preferedTimetable.11a13`),
                type: 'checkbox',
                value: 'contactElevenToThirteen',
                selected: values.contact_11_to_13 || false, 
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.preferedTimetable.13a15`),
                type: 'checkbox',
                value: 'contactThirteenToFifteen',
                selected: values.contact_13_to_15 || false, 
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.preferedTimetable.15a17`),
                type: 'checkbox',
                value: 'contactFifteenToSeventeen',
                selected: values.contact_15_to_17 || false, 
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.preferedTimetable.17a18`),
                type: 'checkbox',
                value: 'contactSeventeenToEighteen',
                selected: values.contact_17_to_18 || false, 
            },
        ],
        type: 'checkbox',
        selectedCheckboxLabels: selectedCheckboxLabels.length > 0 ? selectedCheckboxLabels : undefined,
    }
}

// Trust level options [RADIO]
export const trustLevelOptions = (values: OptionValues) => (t: TFunction): configOptions => {
return {
    title: {
        label: t(`${TRANSLATION_BASE_FORM_KEY}.levelTrusteeTitle`),
    },
    options: [
        {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.levelTrustee.low`),
            type: 'radio',          
            value: 0,
            selected: values.srConfidence === 0,
        },
        {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.levelTrustee.medium`),
            type: 'radio',
            value: 1,
            selected: values.srConfidence === 1,
        },
        {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.levelTrustee.high`),
            type: 'radio',
            value: 2,
            selected: values.srConfidence === 2,
        },
    ],
    type: 'radio',
    selected: values.srConfidence,
    selectedRadioLabel: (() => {
        switch (values.srConfidence) {
            case 0:
                return t(`${TRANSLATION_BASE_FORM_KEY}.levelTrustee.low`)
            case 1:
                return t(`${TRANSLATION_BASE_FORM_KEY}.levelTrustee.medium`)
            case 2:
                return t(`${TRANSLATION_BASE_FORM_KEY}.levelTrustee.high`)
            default:
                return undefined
        }
    })(),
    }
}

// Information OP options [RADIO]
export const opOptions = (values: OptionValues) => (t: TFunction): configOptions => {
    return {
        title: {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.opTitle`),
        },
        options: [
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.op.interested`),
                type: 'radio',
                value: 0,
                selected: values.srServiceInterest === 0,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.op.noInterested`),
                type: 'radio',
                value: 1,
                selected: values.srServiceInterest === 1,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.op.doubts`),
                type: 'radio',
                value: 2,
                selected: values.srServiceInterest === 2,
            },
        ],
        type: 'radio',
        selected: values.srServiceInterest,
        selectedRadioLabel: (() => {
            switch (values.srServiceInterest) {
                case 0:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.op.interested`)
                case 1:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.op.noInterested`)
                case 2:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.op.doubts`)
                default:
                    return undefined
            }
        })(),
    }
}

// Information Prevent and reduce options [RADIO]
export const riskPlanOptions = (values: OptionValues) => (t: TFunction): configOptions => {
    return {
        title: {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.risksTitle`),
        },
        options: [
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.risks.interested`),
                type: 'radio',
                value: 0,
                selected: values.riskPlan === 0,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.risks.noInterested`),
                type: 'radio',
                value: 1,
                selected: values.riskPlan === 1,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.risks.doubts`),
                type: 'radio',
                value: 2,
                selected: values.riskPlan === 2,
            },
        ],
        type: 'radio',
        selected: values.riskPlan,
        selectedRadioLabel: (() => {
            switch (values.riskPlan) {
                case 0:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.risks.interested`)
                case 1:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.risks.noInterested`)
                case 2:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.risks.doubts`)
                default:
                    return undefined
            }
        })(),
    }
}

// Information Content Platform [RADIO]
export const contentPlanOptions = (values: OptionValues) => (t: TFunction): configOptions => {  
    return {
        title: {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.contentTitle`),
        },
        options: [
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.content.interested`),
                type: 'radio',
                value: 0,
                selected: values.contentPlatformInterest === 0,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.content.noInterested`),
                type: 'radio',
                value: 1,
                selected: values.contentPlatformInterest === 1,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.content.doubts`),
                type: 'radio',
                value: 2,
                selected: values.contentPlatformInterest === 2,
            },
        ],
        type: 'radio',
        selected: values.contentPlatformInterest,
        selectedRadioLabel: (() => {
            switch (values.contentPlatformInterest) {
                case 0:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.content.interested`)
                case 1:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.content.noInterested`)
                case 2:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.content.doubts`)
                default:
                    return undefined
            }
        })(),
    }
}

// Information FollowUp Platform [RADIO]
export const followUpOptions = (values: OptionValues) => (t: TFunction): configOptions => {
    return {
        title: {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.followUpTitle`),
        },
        options: [
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.followUp.interested`),
                type: 'radio',
                value: 0,
                selected: values.followUp === 0,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.followUp.noInterested`),
                type: 'radio',
                value: 1,
                selected: values.followUp === 1,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.followUp.doubts`),
                type: 'radio',
                value: 2,
                selected: values.followUp === 2,
            },
        ],
        type: 'radio',
        selected: values.followUp,
        selectedRadioLabel: (() => {
            switch (values.followUp) {
                case 0:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.followUp.interested`)
                case 1:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.followUp.noInterested`)
                case 2:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.followUp.doubts`)
                default:
                    return undefined
            }
        })(),
    }
}

// Information prevention options [RADIO]
export const infoPreventOptions = (values: OptionValues) => (t: TFunction): configOptions => {
    return {
        title: {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.preventitle`),
        },
        options: [
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.prevent.interested`),
                type: 'radio',
                value: 0,
                selected: values.preventionPlanServiceInterest === 0,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.prevent.noInterested`),
                type: 'radio',
                value: 1,
                selected: values.preventionPlanServiceInterest === 1,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.prevent.doubts`),
                type: 'radio',
                value: 2,
                selected: values.preventionPlanServiceInterest === 2,
            },
        ],
        type: 'radio',
        selected: values.preventionPlanServiceInterest,
        selectedRadioLabel: (() => {
            switch (values.preventionPlanServiceInterest) {
                case 0:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.prevent.interested`)
                case 1:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.prevent.noInterested`)
                case 2:
                    return t(`${TRANSLATION_BASE_FORM_KEY}.prevent.doubts`)
                default:
                    return undefined
            }
        })(),
    }
}

// Additional services options [CHECKBOX]
export const additionalServicesOptions = (values: OptionValues) => (t: TFunction): configOptions => {
    const selectedCheckboxLabels =[
        values?.physicalActivityInterest === true ? t(`${TRANSLATION_BASE_FORM_KEY}.categories.physicalActivity`) : '', 
        values?.nutritionInterest === true ? t(`${TRANSLATION_BASE_FORM_KEY}.categories.food`) : '', 
        values?.alcoholInterest === true ? t(`${TRANSLATION_BASE_FORM_KEY}.categories.alcohol`) : '', 
        values?.emotionalBeingInterest === true ? t(`${TRANSLATION_BASE_FORM_KEY}.categories.wellness`) : '', 
        values?.auditiveCareInterest === true ? t(`${TRANSLATION_BASE_FORM_KEY}.categories.earCare`) : '', 
        values?.earlyDetectionInterest === true ? t(`${TRANSLATION_BASE_FORM_KEY}.categories.earlyDetection`) : '', 
        values?.neurodegenerativeIllInterest === true ? t(`${TRANSLATION_BASE_FORM_KEY}.categories.neurodegenerativeDiseases`) : '',
        values?.activeMindInterest === true ? t(`${TRANSLATION_BASE_FORM_KEY}.categories.activeMind`) : '',
        values?.tobaccoInterest === true ? t(`${TRANSLATION_BASE_FORM_KEY}.categories.tobacco`) : '', 
        values?.sleepInterest === true ? t(`${TRANSLATION_BASE_FORM_KEY}.categories.sleep`) : '', 
        values?.newLinesInterest === true ? t(`${TRANSLATION_BASE_FORM_KEY}.categories.research`) : '', 
        values?.ocularCareInterest === true ? t(`${TRANSLATION_BASE_FORM_KEY}.categories.eyeCare`) : '', 
        values?.socialActivityInterest === true ? t(`${TRANSLATION_BASE_FORM_KEY}.categories.social`) : '',
    ]
    .filter(label => label)

    return {
        title: {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.categoriesTitle`),
        },
        options: [
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.physicalActivity`),
                type: 'checkbox',
                value: 'physical_activity_interest',
                selected: values.physicalActivityInterest || false,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.food`),
                type: 'checkbox',
                value: 'nutritionInterest',
                selected: values.nutritionInterest || false,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.alcohol`),
                type: 'checkbox',
                value: 'alcoholInterest',
                selected: values.alcoholInterest || false, 
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.wellness`),
                type: 'checkbox',
                value: 'emotionalBeingInterest',
                selected: values.emotionalBeingInterest || false,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.earCare`),
                type: 'checkbox',
                value: 'auditiveCareInterest',
                selected: values.auditiveCareInterest || false,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.earlyDetection`),
                type: 'checkbox',
                value: 'earlyDetectionInterest',
                selected: values.earlyDetectionInterest || false,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.neurodegenerativeDiseases`),
                type: 'checkbox',
                value: 'neurodegenerativeIllInterest',
                selected: values.neurodegenerativeIllInterest || false,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.activeMind`),
                type: 'checkbox',
                value: 'activeMindInterest',
                selected: values.activeMindInterest || false,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.tobacco`),
                type: 'checkbox',
                value: 'tobaccoInterest',
                selected: values.tobaccoInterest || false,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.sleep`),
                type: 'checkbox',
                value: 'sleepInterest',
                selected: values.sleepInterest || false,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.research`),
                type: 'checkbox',
                value: 'newLinesInterest',
                selected: values.newLinesInterest || false,
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.eyeCare`),
                type: 'checkbox',
                value: 'ocularCareInterest',
                selected: values.ocularCareInterest || false, 
            },
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.social`),
                type: 'checkbox',
                value: 'socialActivityInterest',
                selected: values.socialActivityInterest || false,
            },
        ],
        type: 'checkbox',
        selectedCheckboxLabels: selectedCheckboxLabels.length > 0 ? selectedCheckboxLabels : undefined,     
    }
}

export const notesOptions = (values: OptionValues) => (t: TFunction): configOptions => {
    return {
        title: {
            label: t(`${TRANSLATION_BASE_FORM_KEY}.categoriesTitle`),
        },
        options: [
            {
                label: t(`${TRANSLATION_BASE_FORM_KEY}.categories.physicalActivity`),
                type: 'textarea',
                value: values.notes || ''
            },
        ],
        type: 'textarea',
    }
}
