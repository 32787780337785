export const isServerError = (status) => {
  return status >= 500
}

export const isBadRequest = (status) => {
  return status === 400
}

export const isUnauthorized = (status) => {
  return status === 401
}

export const isForbidden = (status) => {
  return status === 403
}

export const isNotFound = (status) => {
  return status === 404
}
