import { User, Calendar, Catalog } from '@carbon/icons-react'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { t } from 'i18next'

interface Card {
  icon: JSX.Element
  title: string
  linkText: string
  path: string
}

const TRANSLATION_BASE_KEY = 'privateArea'

const userCard = ({ id }: { id: string }): Card => ({
  icon: <User width={60} height={60} />,
  title: t(`${TRANSLATION_BASE_KEY}.personal.title`),
  linkText: t(`${TRANSLATION_BASE_KEY}.personal.link`),
  path: ROUTE_PATH.srProfile(id),
})

const agendaCard = ({ id }: { id: string }): Card => ({
  icon: <Calendar width={60} height={60} />,
  title: t(`${TRANSLATION_BASE_KEY}.agenda.title`),
  linkText: t(`${TRANSLATION_BASE_KEY}.agenda.link`),
  path: ROUTE_PATH.agenda(id),
})

const portfolioManagementCard = ({ id }: { id: string }): Card => ({
  icon: <Catalog width={60} height={60} />,
  title: t(`${TRANSLATION_BASE_KEY}.portfolioManagement.title`),
  linkText: t(`${TRANSLATION_BASE_KEY}.portfolioManagement.linkCare`),
  path: ROUTE_PATH.portfolioManagementCare(id),
})

const portfolioManagementCampaignCard = ({ id }: { id: string }): Card => ({
  icon: <Catalog width={60} height={60} />,
  title: t(`${TRANSLATION_BASE_KEY}.portfolioManagement.title`),
  linkText: t(`${TRANSLATION_BASE_KEY}.portfolioManagement.linkCampaign`),
  path: ROUTE_PATH.portfolioManagementCampaign(id),
})

export const getSocialReferentUserCards = ({ id }: { id: string }): Card[] => [
  userCard({ id }),
  agendaCard({ id }),
  portfolioManagementCard({ id }),
  portfolioManagementCampaignCard({ id }),
]
