export const initialReport = ({ width, height, ...rest }) => {
  return (
    <svg
      {...rest}
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1080_10446)">
        <path
          d="M10.8074 13.0384H10.0074C9.93673 13.0383 9.86895 13.0102 9.81896 12.9602C9.76897 12.9102 9.74085 12.8424 9.74077 12.7717V12.105H9.0741C9.0034 12.105 8.93562 12.0768 8.88563 12.0269C8.83564 11.9769 8.80752 11.9091 8.80743 11.8384V11.0384C8.8075 10.9677 8.83562 10.8999 8.88562 10.8499C8.93561 10.7999 9.0034 10.7718 9.0741 10.7717H9.74077V10.105C9.74084 10.0343 9.76896 9.96656 9.81895 9.91656C9.86894 9.86657 9.93673 9.83845 10.0074 9.83838H10.8074C10.8781 9.83846 10.9459 9.86658 10.9959 9.91657C11.0459 9.96657 11.074 10.0343 11.0741 10.105V10.7717H11.7408C11.8115 10.7718 11.8792 10.7999 11.9292 10.8499C11.9792 10.8999 12.0074 10.9677 12.0074 11.0384V11.8384C12.0073 11.9091 11.9792 11.9768 11.9292 12.0268C11.8792 12.0768 11.8115 12.105 11.7408 12.105H11.0741V12.7717C11.074 12.8424 11.0459 12.9102 10.9959 12.9602C10.9459 13.0102 10.8781 13.0383 10.8074 13.0384V13.0384ZM9.0741 11.0384V11.8384H10.0074V12.7717H10.8074V11.8384H11.7408V11.0384H10.8074V10.105H10.0074V11.0384H9.0741Z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0074 15.5054H3.00745C2.74232 15.5051 2.48815 15.3996 2.30068 15.2121C2.1132 15.0247 2.00775 14.7705 2.00745 14.5054V2.50537C2.00775 2.24025 2.1132 1.98607 2.30068 1.7986C2.48815 1.61113 2.74232 1.50568 3.00745 1.50537H13.0074C13.2726 1.50568 13.5267 1.61113 13.7142 1.7986C13.9017 1.98607 14.0071 2.24025 14.0074 2.50537V14.5054C14.0071 14.7705 13.9017 15.0247 13.7142 15.2121C13.5267 15.3996 13.2726 15.5051 13.0074 15.5054ZM3.00745 2.50537V14.5054H13.0074V2.50537H3.00745ZM5.00745 5.00537H11.0074V4.00537H5.00745V5.00537ZM10.0074 7.50537H6.00745V6.50537H10.0074V7.50537Z"
        fill="currentColor"
      />
      <defs>
        <clipPath id="clip0_1080_10446">
          <rect
            width="4.26667"
            height="4.26667"
            fill="currentColor"
            transform="translate(8.27411 9.30518)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
