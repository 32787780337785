export const selectClassName = `
    bg-white
    rounded
    p-2
    text-zinc-500
    border-solid
    border-gray
    border
    overflow-auto
    max-h-[95px]
`
