import { useTranslation } from 'react-i18next'
import {
  SummaryBoxContainer,
  SummaryBoxResultError,
  SummaryBoxResultSuccess,
} from '../../styles'

export const SummaryBox = ({ title, result, isPositive }) => {
  const TRANSLATION_BASE_KEY = 'userArea.control.summary'
  const { t } = useTranslation()

  return (
    <SummaryBoxContainer>
      <div>{title}</div>
      <div>
        <span>{t(`${TRANSLATION_BASE_KEY}.score`)}</span>
        {isPositive ? (
          <SummaryBoxResultSuccess data-testid="summary-box-result-success">
            {result}
          </SummaryBoxResultSuccess>
        ) : (
          <SummaryBoxResultError data-testid="summary-box-result-error">
            {result}
          </SummaryBoxResultError>
        )}
      </div>
    </SummaryBoxContainer>
  )
}
