import { FILTER_OPTIONS_TYPE, FILTER_SUFIX } from '../enums'

export const parameterizeFilter = (self, modelFilterFields) => {
  const parameterizedFilterFields = []
  const modelsFilters = Object.entries(modelFilterFields)

  modelsFilters.forEach(([modelName, filters]) => {
    const filterAttributes = Object.entries(filters)
    const attributes = []
    const values = []
    const optionsByAttr = []

    filterAttributes.forEach(([attr, { value, options = {} }]) => {
      attributes.push(attr)
      values.push(value)
      optionsByAttr.push(options)
    })

    const serializedAttributes = self.serializeAttributes(modelName, attributes)

    if (serializedAttributes) {
      serializedAttributes.forEach((serializedAttribute, i) => {
        let valuesArray = getValuesArray(values[i], optionsByAttr[i])

        const key = optionsByAttr[i].withoutFilter
          ? `${serializedAttribute}`
          : constructFilter(serializedAttribute, optionsByAttr[i].type)

        valuesArray.length > 1
          ? (parameterizedFilterFields[key] = valuesArray)
          : (parameterizedFilterFields[key] = values[i])
      })
    } else {
      // TODO CATCH ERROR IF NOT PASS ATTRIBUTES OR MODEL NAME
    }
  })
  return parameterizedFilterFields
}

const constructFilter = (serializedAttribute, type = null) => {
  const filterSuffix = FILTER_SUFIX[type] || null

  const getFormattedFilter = (filterString) => {
    return `filter[${filterString}]`
  }

  if (filterSuffix) {
    return getFormattedFilter(`${serializedAttribute}${filterSuffix}`)
  } else {
    return getFormattedFilter(`${serializedAttribute}`)
  }
}

const getValuesArray = (value, option) => {
  if (option.type === FILTER_OPTIONS_TYPE.range) {
    if (value instanceof Array) {
      return value.join(',')
    } else {
      throw new Error(
        'RootModel --> parameterizeFilter(): If you selected range type filter, the attribute value must be an array',
      )
    }
  } else {
    return String(value).split(',')
  }
}
