import { CampaignReport, CareReport, FollowUpReport } from './reports'

export enum ReportType {
  care = 1,
  campaign = 2,
  followup = 4,
}

export const ReportTypeName = Object.freeze({
  [ReportType.care]: 'care',
  [ReportType.campaign]: 'campaign',
  [ReportType.followup]: 'followup',
})

export const ReportComponent = Object.freeze({
  [ReportType.care]: CareReport,
  [ReportType.campaign]: CampaignReport,
  [ReportType.followup]: FollowUpReport,
})
