import { types } from 'mobx-state-tree'
import { RootModel } from '@shared/data-access'

export const MODEL_NAME = 'ClientPreferences'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    activeMindInterest: types.maybeNull(types.boolean),
    alcoholInterest: types.maybeNull(types.boolean),
    auditiveCareInterest: types.maybeNull(types.boolean),
    clientLocalized: types.maybeNull(types.number),
    contactPredisposition: types.maybeNull(types.number),
    contactNineToEleven:types.maybeNull(types.boolean),
    contactElevenToThirteen:types.maybeNull(types.boolean),
    contactThirteenToFifteen:types.maybeNull(types.boolean),
    contactFifteenToSeventeen:types.maybeNull(types.boolean),
    contactSeventeenToEighteen:types.maybeNull(types.boolean),
    contentPlatformServiceInterest: types.maybeNull(types.number),
    earlyDetectionInterest: types.maybeNull(types.boolean),
    emailPreferred: types.maybeNull(types.boolean),
    emotionalBeingInterest: types.maybeNull(types.boolean),
    followUpAnalysisInterest: types.maybeNull(types.number),
    neurodegenerativeIllInterest: types.maybeNull(types.boolean),
    newLinesInterest: types.maybeNull(types.boolean),
    notes: types.maybeNull(types.string),
    nutritionInterest: types.maybeNull(types.boolean),
    ocularCareInterest: types.maybeNull(types.boolean),
    phoneCallPreferred: types.maybeNull(types.boolean),
    physicalActivityInterest: types.maybeNull(types.boolean),
    preventionPlanServiceInterest: types.maybeNull(types.number),
    preventivePowersServiceInterest: types.maybeNull(types.number),
    sleepInterest: types.maybeNull(types.boolean),
    socialActivityInterest: types.maybeNull(types.boolean),
    srConfidence: types.maybeNull(types.number),
    srServiceInterest: types.maybeNull(types.number),
    textPreferred: types.maybeNull(types.boolean),
    tobaccoInterest: types.maybeNull(types.boolean),
  })
