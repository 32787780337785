import { SingleSchedule } from '../types'

export const AvailabilityCalendarHeader = ({
  header,
}: {
  header: SingleSchedule
}) => {
  return (
    <thead>
      <tr>
        {header.map(({ day }: { day: JSX.Element }, index: number) => {
          return (
            <th className="p-2 uppercase" key={index}>
              {day}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
