import tw from 'tailwind-styled-components'

export const AgendaContainer = tw.div`
    grid
    grid-cols-12
    gap-4
    py-5
    xl:gap-8
`

export const AgendaCalendarContainer = tw.div`
    flex
    gap-4
    w-full
    col-start-4
    col-end-13
`

export const AgendaBigCalendarContainer = tw.div`flex-1`
