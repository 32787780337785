import { useEffect, useState } from 'react'
import { useVideoContext } from '../VideoProvider/VideoProvider'

/** useParticipants
 * This hook returns an array of the video room's participants. This will prevent unnecessary
 * re-renders because components that use this hook will only update when a participant connects
 * to or disconnects from the room.
 * @return  {Array} participants
 */

export const useParticipants = () => {
  const { room } = useVideoContext()
  const [participants, setParticipants] = useState(
    Array.from(room?.participants.values() ?? [])
  )
  useEffect(() => {
    if (room) {
      const participantConnected = (participant) =>
        setParticipants((prevParticipants) => [
          ...prevParticipants,
          participant,
        ])
      const participantDisconnected = (participant) =>
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => p !== participant)
        )
      room.on('participantConnected', participantConnected)
      room.on('participantDisconnected', participantDisconnected)
      return () => {
        room.off('participantConnected', participantConnected)
        room.off('participantDisconnected', participantDisconnected)
      }
    }
  }, [room])

  return participants
}
