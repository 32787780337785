import React, { useMemo } from 'react'
import { getLocalStorageItem } from '@shared/utils'
import { FontSizeName, FontStyle } from './types'

interface IProps {
  children: React.ReactNode
  fontStyle?: FontStyle
  className?: string
}

const BodyText: React.FC<IProps> = ({
  children,
  className = '',
  fontStyle = FontStyle.regular,
}) => {
  const fontSize = useMemo(() => {
    return getLocalStorageItem('fontSize') as FontSizeName
  }, [])

  function getFontStyle() {
    switch (fontStyle) {
      case FontStyle.medium:
        return 'font-medium'
      default:
        return 'font-normal'
    }
  }

  function getFontSize() {
    switch (fontSize) {
      case FontSizeName.large:
        return 'text-[18px]'
      case FontSizeName.extraLarge:
        return 'text-[20px]'
      default:
        return 'text-[16px]'
    }
  }

  return (
    <p
      className={`font-aeonik leading-6 text-left text-generic-neutral-900 ${getFontStyle()} ${getFontSize()} ${className}`}
    >
      {children}
    </p>
  )
}

export default BodyText
