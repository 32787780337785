import { Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer'
import { tw } from '../../utils'
import { FC } from 'react'

type SectionIntroProps = {
  number: string
  title?: string
  image?: string
  areaColor: string
  id: string
}

export const SectionIntro: FC<SectionIntroProps> = ({
  number,
  title = '',
  image = '',
  areaColor,
  id,
}) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      fontFamily: 'Montserrat',
      fontSize: '11px',
    },
  })

  return (
    <Page
      size="A4"
      style={{
        ...styles.page,
        ...tw('h-full'),
      }}
      id={id}
    >
      <View
        style={tw(
          `bg-${areaColor} flex flex-col justify-between h-full py-32 px-24`,
        )}
      >
        <View>
          <Text style={tw('text-8xl font-semibold')}>{number}</Text>
          <Text style={tw('text-6xl font-semibold mt-4')}>{title}</Text>
        </View>
        <Image src={image} style={tw(`w-24 h-24 self-end`)} />
      </View>
    </Page>
  )
}
