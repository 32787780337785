import { Image, View } from '@react-pdf/renderer'
import { tw } from '../../../../utils'
import CampaignFrontPage from '../../../../assets/campaignFrontpage.png'
import CareFrontPage from '../../../../assets/careFrontpage.png'

export const HeroComponent = ({ isCare }) => {

  return (
    <View style={tw(`py-8`)}>
      <View style={tw(`flex flex-row flex-wrap`)}>
        <Image src={isCare ? CareFrontPage : CampaignFrontPage} />
      </View>
    </View>
  )
}
