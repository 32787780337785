import { Children, useEffect, useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, BUTTON_COLORS, BUTTON_VARIANTS } from '../button'
import { WizardTimeline } from './wizard-timeline'
import { Props } from './types'
import { usePrevious } from '@shared/utils'

export const Wizard: FC<Props> = ({
  wizardContainerClasses,
  wizardContentClasses,
  wizardButtonContainerClasses,
  children,
  hidePrev = false,
  hideNext = false,
  timeline = false,
  prevText,
  nextText,
  onComplete,
  completeText,
  onChangePage,
  onNextPage,
  onPreviousPage,
  onExit,
  disableNavigation = false,
  activePageNumber = 0,
  generateInform
}) => {
  const [activePage, setActivePage] = useState(activePageNumber)
  const prevPage: undefined | number = usePrevious(activePage)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const pages: React.ReactElement[] = Children.toArray(children)
  const { t } = useTranslation()

  const url = window.location.href
  const userId = url.split('/')
  const uuid = userId[4]

  const navigate = useNavigate()


  useEffect(() => {
    setActivePage(activePageNumber)
  }, [activePageNumber])

  const showPrev = !hidePrev && activePage > 0
  const showNext = !hideNext && activePage + 1 < pages.length
  const showComplete = !!onComplete && activePage + 1 === pages.length
  const showExit = !!onExit && activePage + 1 < pages.length

  const onCancel = () => {
    if (onExit) onExit()
  }

  const onPrev = () => {
    if (onPreviousPage) onPreviousPage()
    setActivePage((page) => --page)
  }

  const onNext = () => {
    if (onNextPage) onNextPage()
    setActivePage((page) => ++page)
  }

  const onBackToUserMenu= () => {
    navigate(`/user-area/${uuid}`)
  }

  const onClickTimeline = (page: number) => {
    setActivePage(page)
  }

  useEffect(() => {
    if (onChangePage)
      onChangePage({
        activePage: pages[activePage],
        prevPage: prevPage ? pages[prevPage] : <div />,
      })
  }, [activePage])

  return (
    <div className={wizardContainerClasses}>
      {timeline && (
        <WizardTimeline
          disableNavigation={disableNavigation}
          pages={pages}
          onClickTimeline={onClickTimeline}
          activePage={activePage}
        />
      )}
      <div className={wizardContentClasses}>{pages[activePage]}</div>
      <div className={wizardButtonContainerClasses}>
        {showExit && (
          <Button
            color={BUTTON_COLORS.secondary}
            onClick={onCancel}
            testId="wizard-on-exit"
          >
            {prevText || t('common:exit')}
          </Button>
        )}
        {showPrev && (
          <Button
            variant={BUTTON_VARIANTS.outlined}
            onClick={onPrev}
            testId="wizard-on-prev"
          >
            {prevText || t('common:previous')}
          </Button>
        )}
        {showNext && (
          <Button onClick={onNext} testId="wizard-on-next">
            {nextText || t('common:next')}
          </Button>
        )}
        {generateInform && (
          <Button onClick={onBackToUserMenu} testId="wizard-on-generateInform-exit">
            {nextText || t('common:exit')}
          </Button>
        )}
        {(showComplete && (generateInform === null || generateInform === undefined)) && (
          <Button onClick={onComplete} testId="wizard-on-complete" disabled={generateInform}>
            {completeText || t('common:complete')}
          </Button>
        )}

      </div>
    </div>
  )
}

