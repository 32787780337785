import { BUTTON_TYPES } from '../button'

export const Chip = ({
  label = '',
  value = '',
  onClick = null,
  isSelected = false,
  className = '',
  selectedClassName = '',
  unSelectedClassName = '',
}) => {
  const handleClick = () => {
    if (onClick) onClick(value)
  }

  const selectedClasses = isSelected ? selectedClassName : unSelectedClassName
  const buttonClasses = `cursor-pointer ${className} ${selectedClasses}`

  return (
    <button
      data-testid="chip-button"
      className={buttonClasses}
      type={BUTTON_TYPES.button}
      onClick={handleClick}
    >
      {label}
    </button>
  )
}
