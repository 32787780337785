import tw from 'tailwind-styled-components'

export const UserInfoContainer = tw.div`
    md:grid
    md:grid-cols-2
    lg:grid-cols-3
    xl:grid-cols-4
    gap-x-8
    relative
`
export const UserInfoBoxTextArea = tw.div`
    mb-4
`
export const UserInfoBoxContainer = tw.div`
    text-dark-gray
    mb-4
`

export const UserInfoBoxLabel = tw.div`
    text-primary
    mb-2
    font-medium
    md:items-center
`

export const UserProfileMeetingInfo = tw.div`
    text-primary
`

export const UserProfileMeetingLabel = tw.div`
    font-medium
`
export const LanguageToggleWrapper = tw.div`
    text-primary
    border	
    border-solid
    border-black
    flex
    h-fit
    rounded-md
    first:border-r
    cursor-pointer	
`
export const LanguageToggleLabel = tw.div`
    text-base	
    px-5
    bg-opacity-75
`
export const TextareaContainer = tw.div`
    border
    rounded-md
    border-black
    px-8
    py-5
`
export const UserInfoValue = tw.div`
    break-words
`
