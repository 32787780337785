import { FC, ReactElement } from 'react'
import { srCardListItemClasses } from './styles'

interface SRCardListItemProps {
  icon?: ReactElement
  text: string
  action: () => void
}

export const SRCardListItem: FC<SRCardListItemProps> = ({
  action,
  icon,
  text,
}) => {
  return (
    <div role="button" onClick={action} className={srCardListItemClasses}>
      {icon && <span>{icon}</span>}
      <span>{text}</span>
    </div>
  )
}
