import { Text, View } from '@react-pdf/renderer'
import { areaColors, tw } from '../../utils'

export const ScalePerYear = ({ scales, areaColor }) => {
  return (
    <View break>
      <View style={tw('flex flex-row items-center')}>
        <View>
          <View style={tw('w-full h-px bg-lightGrey')}></View>
          <Text style={tw('px-2 py-1')}>{new Date().getFullYear()}</Text>
          <View style={tw('w-full h-px bg-lightGrey')}></View>
        </View>
        <View
          style={tw('flex flex-row justify-between items-center h-10 flex-1')}
        >
          <View style={tw('flex justify-center text-center')}></View>
          {scales.map((scale, index) => {
            const background = scale.selected
              ? `bg-[${areaColors[areaColor]}]`
              : 'bg-lightGrey'

            const textColor = scale.selected
              ? 'text-white font-semibold'
              : 'text-gray-400'
            const height = scale.selected ? 'h-10' : 'h-8'

            return (
              <View
                key={index}
                style={tw(
                  `${background} ${height} ${textColor} flex-1 flex justify-center text-center`,
                )}
              >
                <Text>{scale.text}</Text>
              </View>
            )
          })}
        </View>
      </View>
      <View
        style={tw(
          'flex flex-row justify-between pt-1 px-2 gap-4 items-center text-xs',
        )}
      >
        <Text style={tw('w-10')}></Text>
        {scales.map((scale, index) => {
          const textColor = scale.selected
            ? `text-[${areaColors[areaColor]}] font-bold`
            : 'text-gray-400'

          return (
            <Text style={tw(`flex-1 text-center ${textColor}`)} key={index}>
              {scale.subText}
            </Text>
          )
        })}
      </View>
    </View>
  )
}
