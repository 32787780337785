import { flow, types } from 'mobx-state-tree'
import { BaseRouteState, SocialReferent } from '@shared/data-access'
import { actions } from '@sr/routes/common'
import { getSocialReferentUserCards } from './cards'

export const SRPrivateAreaRouteState = BaseRouteState.named(
  'SRPrivateAreaRouteState'
)
  .props({
    socialReferentId: types.maybeNull(types.string),
    socialReferent: types.maybeNull(types.reference(SocialReferent)),
  })
  .views((self) => ({
    get cards() {
      return getSocialReferentUserCards({ id: self.socialReferentId })
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.loadDependencies()
    },
    loadDependencies: flow(function* loadDependencies() {
      self.setLoading('SRPrivateAreaRouteState')
      try {
        yield self.loadSocialReferent()
      } finally {
        self.removeLoading('SRPrivateAreaRouteState')
      }
    }),
    ...actions(self),
  }))
