import { toast } from 'react-toastify'
import { ErrorToast, SuccessToast } from './toasts'

export class Notification {
  constructor({ position, hideProgressBar = false } = {}) {
    this.position = position || toast.POSITION.BOTTOM_CENTER
    this.hideProgressBar = hideProgressBar
  }

  success(mssg) {
    return toast.success(<SuccessToast mssg={mssg} />, {
      position: this.position,
      hideProgressBar: this.hideProgressBar,
    })
  }

  error(mssg) {
    return toast.error(<ErrorToast mssg={mssg} />, {
      position: this.position,
      hideProgressBar: this.hideProgressBar,
    })
  }
}

export const notify = new Notification()
