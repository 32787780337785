import {
  AuditCScore,
  ClockScore,
  FagerstromScore,
  IMCScore,
  LoboScore,
  MedasScore,
  PfeifferScore,
  RichmondScore,
} from '../scales'
import { ReportData } from '../types'
import { ExecutiveSummaryValues, Scores } from './types'

export const getPersonDataParsed = (data: ReportData) => {
  const personData = data?.results?.initialAnalysisList?.person_data
  return {
    ...personData,
    name: personData.name,
    birthDay: personData.birth_day,
    preferredLanguage: personData.preferred_language,
    contractNumber: personData.contract_number,
    fullName: `${personData.name} ${personData.surname}`,
    sex: Number(personData.sex),
    socialReferent: {
      name: personData.social_referent__name,
      surname: personData.social_referent__surname,
      phone: personData.social_referent__phone,
      academicTitle: personData.social_referent__academic_title,
      fullName: `${personData.social_referent__name} ${personData.social_referent__surname}`,
      professionalDescEs: personData.social_referent__professional_desc_es,
      professionalDescCa: personData.social_referent__professional_desc_ca,
      collegiateNo: personData.social_referent__collegiate_no,
      picture_url: personData.social_referent__picture_url,
    },
  }
}

export const getScores = (data: ReportData): Scores => {
  const { scores, person_data } = data?.results?.initialAnalysisList || {}

  const loboScore = new LoboScore({
    score: scores?.LOBO?.score,
  })

  const clockScore = new ClockScore({
    score: scores?.CLOCK?.score,
  })

  const pfeifferScore = new PfeifferScore({
    score: scores?.PFEIFFER?.score,
  })

  const medasScore = new MedasScore({
    score: scores?.MEDAS?.score,
  })

  const imcScore = new IMCScore({
    score: scores?.BMI?.BMI,
    height: scores?.BMI?.height,
    weight: scores?.BMI?.weight,
  })

  const fagerstromScore = new FagerstromScore({
    score: scores?.FAGERSTRON?.score,
  })

  const richmondScore = new RichmondScore({
    score: scores?.RICHMOND?.score,
  })

  const auditCScore = new AuditCScore({
    score: scores?.AUDIT_C?.points,
    sex: Number(person_data?.sex),
  })

  return {
    loboScore,
    clockScore,
    pfeifferScore,
    medasScore,
    imcScore,
    fagerstromScore,
    richmondScore,
    auditCScore,
  }
}

export const getExecutiveSummaryParsed = (
  data: ReportData,
): ExecutiveSummaryValues => {
  const { summary_executive } = data?.results?.initialAnalysisList || {}

  return {
    physicalActivity: {
      score: Number(
        summary_executive.physical_activity_score_summary_executive,
      ),
      content: summary_executive.physical_activity_area_summary_executive,
    },
    nutritional: {
      score: Number(summary_executive.nutritional_score_summary_executive),
      content: summary_executive.nutritional_area_summary_executive,
    },
    cognitive: {
      score: Number(summary_executive.cognitive_score_summary_executive),
      content: summary_executive.cognitive_area_summary_executive,
    },
    emotional: {
      score: Number(
        summary_executive.emotional_wellbeing_score_summary_executive,
      ),
      content: summary_executive.emotional_wellbeing_area_summary_executive,
    },
    social: {
      score: Number(summary_executive.social_score_summary_executive),
      content: summary_executive.social_area_summary_executive,
    },
    auditiveAndOcular: {
      score: Number(summary_executive.auditory_ocular_score_summary_executive),
      content: summary_executive.auditory_ocular_area_summary_executive,
    },
    tobaccoAlcohol: {
      score: Number(summary_executive.tobacco_alcohol_score_summary_executive),
      content: summary_executive.tobacco_alcohol_area_summary_executive,
    },
  }
}
