import { observer } from 'mobx-react-lite'

import {
  getDateFormatted,
  getDateFormattedFromSchedule,
  setLocalStorageItem,
} from '@shared/utils'

import { ScheduleRadioButton } from './schedule-radio-button'

export const ScheduleHour = observer(
  ({
    className = '',
    disabled = false,
    fieldName,
    noDigital,
    slotStart,
    slotEnd,
    isSelected,
    setModifyDate,
  }) => {
    const startHour = getDateFormattedFromSchedule(slotStart, 'HH:mm')
    const endHour = getDateFormattedFromSchedule(slotEnd, 'HH:mm')
    const dateFormatted = getDateFormatted(slotStart, '')

    const disabledClasses = disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
    const heightClasses = noDigital ? 'h-28' : 'h-8'
    const checkClasses = isSelected ? 'bg-primary text-white font-bold' : 'bg-vcx-dark-white'

    const handleClick = () => {
      if (isSelected) return

      const previousDate = localStorage.getItem('dateSelected')
      if (previousDate) {
        setLocalStorageItem('previousDateSelected', previousDate)
      }

      setLocalStorageItem('dateSelected', dateFormatted)
      setLocalStorageItem('slotsSelected', `${startHour} - ${endHour}`)
      setLocalStorageItem('clicked', true)
      setModifyDate(dateFormatted)
    }

    return (
      <button
        type="button"
        className={`${checkClasses} ${heightClasses} ${className} ${disabledClasses}`}
        data-testid={`schedule-hour-${dateFormatted}`}
        onClick={handleClick}
        disabled={disabled}
      >
        {`${startHour} - ${endHour}`}
        <ScheduleRadioButton
          id={`schedule-hour-${dateFormatted}`}
          className="opacity-0 fixed w-0"
          name={fieldName}
          value={dateFormatted}
          disabled={disabled}
        >
          {`${startHour} - ${endHour}`}
        </ScheduleRadioButton>
      </button>
    )
  }
)
