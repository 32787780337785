import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { observer } from 'mobx-react-lite'
import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useRouteState, setLocalStorageItem } from '@shared/utils'

import { AnalysisRouteState } from './state'
import { ModalType, useAnalysisModal } from './hooks'

import {
  GenericAnalysis,
  PDFPreview,
  useLayoutContext,
} from '@sr/routes/common'

import { VALIDATE_ALL } from './enums'

type OnChangePageParams = {
  activePage: React.ReactElement
  prevPage: React.ReactElement
}

type SelectParams = {
  page: number
  id: string
}

type AnalysisProps = {
  personId: string
  reportType: number
}

export const Analysis: FC<AnalysisProps> = observer(
  ({ personId, reportType }) => {
    const { testId } = useParams()
    const { t } = useTranslation()
    const {
      isLoading,
      typeList,
      setAutoValidate,
      modalType,
      reload,
      resend,
      test,
      setModal,
      person,
      fetchHistoricalSentByPostal,
      sendByPostal,
      generateInform,
      fetchHistoricalSentByEmail,
      sendByEmail,
      datePostal,
      dateEmail,
      optionEmail,
      optionPostal
    } = useRouteState(AnalysisRouteState, {
      personId,
      reportType,
      testId,
    })

    const location = useLocation()

    const [isCompleting, setIsCompleting] = useState<boolean>(false)

    const [initialStatusPostal, setInitialStatusPostal]= useState<'on'| 'off' | 'disabled'>(optionPostal)
    const [initialStatusEmail, setInitialStatusEmail]= useState<boolean| undefined>()

    const [activePageNumber, setActivePageNumber]: [
      number,
      Dispatch<SetStateAction<number>>,
    ] = useState(0)

    const { setIsLoading } = useLayoutContext()

    const onSelectTest = ({ page, id }: SelectParams) => {
      setAutoValidate(id)
      setActivePageNumber(page)
    }

    const onPreviewError = () => {
      setAutoValidate(VALIDATE_ALL)
      setActivePageNumber(0)
    }

    const onChangePage = ({ activePage }: OnChangePageParams) => {
      setActivePageNumber(activePage?.props?.testOrder)
    }

    const onClose = () => {
      setModal(null)
      setIsCompleting(false)
    }

    const Modal = useAnalysisModal({
      modalType,
      reload,
      resend,
      onClose,
      fullName: person?.fullName,
    })

    const generateReport = () => {
      setIsCompleting(false)
      setInitialStatusEmail(true)
      setInitialStatusPostal('off')
      setModal(ModalType.confirmation)
    }

    useEffect(() => {
      setIsLoading(isLoading)
      if (testId) {
        fetchHistoricalSentByPostal(testId)
        fetchHistoricalSentByEmail(testId)
      }
    }, [testId])
    
    useEffect(() => {
      const queryParams = new URLSearchParams(location.search)
      const passFakedTest = queryParams.get('passFakedTest')

      if (passFakedTest) {
        setLocalStorageItem('annuity', true)
        setLocalStorageItem('generic', true)
        setLocalStorageItem('control', true)
        setLocalStorageItem('analysis', true)
        setLocalStorageItem('initial', true)
      }
    }, [location])

    return (
      <>
        <GenericAnalysis
          testList={typeList}
          activePageNumber={activePageNumber}
          onChangePage={onChangePage}
          onComplete={test?.isFilled && generateReport}
          generateInform={generateInform}
        >
          <PDFPreview
            name={t('analysis:end.title')}
            onSelectTest={onSelectTest}
            onPreviewError={onPreviewError}
            testOrder={typeList?.length}
            isCompleting={isCompleting || isLoading}
            statusPostal={optionPostal}
            sendByPostal={() => sendByPostal(testId)}
            dataPostal={datePostal}
            statusEmail={optionEmail}
            sendByEmail={() => sendByEmail()}
            dataEmail={dateEmail}
            reportType={reportType}
            generateInform={generateInform}
          />
        </GenericAnalysis>
        {modalType && Modal}
      </>
    )
  }

)
