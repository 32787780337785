import tw from 'tailwind-styled-components'

export const ErrorContainer = tw.div` 
    w-full
    h-[94vh]
    flex
    justify-center
    items-center
`

export const ErrorBox = tw.div`
    w-8/12
    h-48
    border
    border-primary
    rounded
    flex
    flex-col
    gap-2
    justify-center
    items-center
    p-5
`

export const ErrorText = tw.div`
    text-lg 
    mb-6
`
