import { Text, View } from '@react-pdf/renderer'
import { useReportPdfContext } from '../../../provider'
import { ListItem, TranscriptionScriptPDF } from '../../../components'
import { getRecommendations, tw } from '../../../utils'
import {
  NUTRITIONAL_TIP_PRIMARY_LIST,
  NUTRITIONAL_TIP_SECONDARY_LIST,
  NUTRITIONAL_TIP_TERTIARY_LIST,
  NUTRITIONAL_TIP_EXTRA_LIST,
} from './enums'
import { ListWithTick } from './components'

export const NutritionalTips = () => {
  const { t, recommendations } = useReportPdfContext()

  const TRANSLATION_BASE_KEY = 'initial-report:sectionNutritional.tips'

  const defaultKey = 'rec_nutritional_mediterranean_keep'

  const keyTips = getRecommendations({
    recKeys: recommendations?.NUTRITIONAL,
    defaultKey,
  })[0]

  const listOneFormatted =
    NUTRITIONAL_TIP_PRIMARY_LIST[
      keyTips as keyof typeof NUTRITIONAL_TIP_PRIMARY_LIST
    ].map((script: string) => <TranscriptionScriptPDF script={script} />) || []

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const listTwoFormatted: string[] =
    NUTRITIONAL_TIP_SECONDARY_LIST[
      keyTips as keyof typeof NUTRITIONAL_TIP_SECONDARY_LIST
    ]?.list.map((script: string) => (
      <TranscriptionScriptPDF script={script} />
    )) || []

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const listThreeFormatted: string[] =
    NUTRITIONAL_TIP_TERTIARY_LIST[
      keyTips as keyof typeof NUTRITIONAL_TIP_TERTIARY_LIST
    ]?.list.map((script: string) => (
      <TranscriptionScriptPDF script={script} />
    )) || []

  const listExtraFormatted =
    NUTRITIONAL_TIP_EXTRA_LIST[
      keyTips as keyof typeof NUTRITIONAL_TIP_EXTRA_LIST
    ]?.map((script: string) => <TranscriptionScriptPDF script={script} />) || []

  return (
    <View style={tw('px-12 mb-4')}>
      <View style={tw('flex flex-col gap-4')}>
        <Text style={tw('underline')}>
          {t(`${TRANSLATION_BASE_KEY}.title`)}
        </Text>
        {listOneFormatted?.length && <ListItem list={listOneFormatted} />}
        {listTwoFormatted?.length && (
          <ListWithTick
            title={
              NUTRITIONAL_TIP_SECONDARY_LIST[
                keyTips as keyof typeof NUTRITIONAL_TIP_SECONDARY_LIST
              ].title
            }
            list={listTwoFormatted}
          />
        )}
        {listThreeFormatted?.length && (
          <ListWithTick
            title={
              NUTRITIONAL_TIP_TERTIARY_LIST[
                keyTips as keyof typeof NUTRITIONAL_TIP_TERTIARY_LIST
              ].title
            }
            list={listThreeFormatted}
          />
        )}
        {listExtraFormatted.length && <ListItem list={listExtraFormatted} />}
      </View>
    </View>
  )
}
