import { SRChip } from '@sr/ui'
import { AgendaChipContainer, AgendaChipGroupContainer } from './styles'

export const AgendaLegendGroupContainer = ({ list = [] }) => {
  return (
    list.length && (
      <AgendaChipGroupContainer>
        {list.map(({ label, bg, textColor = '', icon = null }, index) => {
          const background = bg ? `bg-${bg}-base` : 'bg-appointment-free-slot'
          return (
            <SRChip className={`${background} ${textColor}`} key={index}>
              <AgendaChipContainer>
                {icon} {label}
              </AgendaChipContainer>
            </SRChip>
          )
        })}
      </AgendaChipGroupContainer>
    )
  )
}
