import { getEnv, types } from 'mobx-state-tree'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

export const generateRootStore = ({
  i18n = { language: 'esEs' },
  stores = [],
  states = [],
  ...attrs
}) => {
  const { dataStores, modelStoresNames } = generateDataStores(stores)

  return types
    .model('RootStore', {
      ...attrs,
      ...dataStores,
      ...generateRouteStates(states),
    })
    .volatile(() => ({
      modelStoresNames,
      i18n,
    }))
    .views((self) => ({
      get locale() {
        return self.i18n.language
      },
    }))
    .actions((self) => ({
      getModelStoreByModelName(modelName) {
        return self[self.modelStoresNames[modelName]]
      },
      getRouteState(routeState, initialData) {
        const routeStateName = routeState.name
        if (!self[routeStateName]) {
          self[routeStateName] = routeState.create(initialData)
        }
        return self[routeStateName]
      },
      clearRouteState(routeState) {
        self[routeState.name] = null
      },
      setOnErrorCallback(callback) {
        getEnv(self).client.setOnErrorCallback(callback)
      },
    }))
}

export function generateDataStores(stores) {
  const modelStoresNames = {}
  const dataStores = {}
  stores.forEach((item) => {
    let store = item
    let defaultValues = {}
    const mapModelName = store?.properties?.map?._subtype?._subType?.name
    if (Array.isArray(item)) {
      store = item[0]
      defaultValues = item[1]
    }
    if (!store || !store.name) {
      console.error('Invalid store object:', store);
      return;
    }
    
    dataStores[store.name] = types.optional(store, () =>
      store.create(defaultValues)
    )
    if (mapModelName) {
      modelStoresNames[mapModelName] = store.name
    }
  })
  return { dataStores, modelStoresNames }
}

export function generateRouteStates(states) {
  const routeStates = {}
  states.forEach((state) => {
    routeStates[state.name] = types.maybeNull(state)
  })
  return routeStates
}

export const createRootStore = ({
  i18n,
  stores,
  states,
  client,
  dependencies = {},
  defaultValues = {},
  onLogout = () => ({}),
  ...attrs
}) => {
  const rootStore = generateRootStore({
    i18n,
    stores,
    states,
    ...attrs,
  }).create(defaultValues, {
    client,
    ...dependencies,
  })

  if (rootStore.AuthStore && rootStore.AuthStore.setOnLogout) {
    rootStore.AuthStore.setOnLogout(onLogout)
  }

  return rootStore
}

export const RootStoreProvider = observer(
  ({ user, context, stores, states, client, dependencies, children }) => {
    const { i18n } = useTranslation()
    const [rootStore, setRootStore] = useState()

    useEffect(() => {
      if (!rootStore) {
        setRootStore(
          createRootStore({
            i18n,
            stores,
            states,
            client,
            dependencies,
            onLogout: () => {
              setRootStore(null)
            },
          })
        )
      }
    }, [rootStore])

    if (!rootStore) {
      return <div></div>
    }

    return (
      <context.Provider value={rootStore}>
        {children({ store: rootStore, user })}
      </context.Provider>
    )
  }
)

export const persistJWTTokens = ({ access, refresh }) => {
  localStorage.setItem('tokens', JSON.stringify({ access, refresh }))
}

export const clearPersistedTokens = () => {
  localStorage.removeItem('tokens')
}
