import ReactGA from 'react-ga4'
import { PageViewParams, InitParams, EventParams } from './types'

class Analytics {
  private isOptedOut = false

  public init({ userId }: InitParams) {
    const GAId = process.env['NX_GA_ID']

    // FAKE USER: 00000000T
    this.isOptedOut = userId ? userId.endsWith('0be1bc24-162c-4acf-a19c-be72f33a666b') : false

    if (GAId && !this.isOptedOut) {
      ReactGA.initialize(GAId, {
        gaOptions: {
          userId,
        },
      })
    }

    if (this.isOptedOut && GAId) {
      (window as any)[`ga-disable-${GAId}`] = true;
    }
  }

  public pageView({ path, title, data, page, referrer }: PageViewParams) {
    if (!this.isOptedOut) {
      ReactGA.send({ 
        hitType: 'pageview', 
        path: path, 
        page: page, 
        referrer: referrer,
        title, 
        ...data })
    }
  }

  public event({ category, action, value, label }: EventParams) {
    if (!this.isOptedOut) {
      ReactGA.event({ 
        category, 
        action, 
        value,
        label })
    }
  }
}

export const analytics = new Analytics()
