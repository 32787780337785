import { FC, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { multiSelectControlStyles } from './styles'

export type MultiSelectOptions = {
  label: string
  value: string
}

type MultiSelectProps = {
  options: MultiSelectOptions[]
  name: string
  isMulti?: boolean
  isRequired?: boolean
  defaultValue?: string[]
  placeholder?: string | JSX.Element
  classNames?: object
  isClearable?: boolean
}

export const MultiSelect: FC<MultiSelectProps> = ({
  options,
  name,
  isMulti = false,
  isRequired = false,
  defaultValue,
  placeholder,
  classNames = {},
  isClearable,
}) => {
  const { t } = useTranslation()
  const { control, setValue, setError, clearErrors } = useFormContext()

  const onChangeValue = (value: any) => {
    if ((!value || !value.length) && isRequired) {
      setError(name, {
        type: 'custom',
        message: t('common:errors.genericRequiredField'),
      })
    } else {
      clearErrors(name)
    }

    let newValue: any = value

    if (isMulti) {
      newValue = value.map(({ value }: { value: any }) => value)
    }

    setValue(name, newValue)
  }

  const defaultOptions = options.filter((option) => {
    return !!defaultValue?.some((val) => val === option.value)
  })

  const valueComputed = ({ value }: any) => {
    if (defaultOptions.length) return
    return isMulti
      ? options.filter((option) => value?.includes(option.value))
      : options.filter((c) => c.value === value?.value)
  }

  useEffect(() => {
    if (isRequired && (!defaultOptions || !defaultOptions?.length)) {
      setError(name, {
        type: 'custom',
        message: t('common:errors.genericRequiredField'),
      })
    }
  }, [])

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => (
        <Select
          options={options}
          value={valueComputed({ value })}
          onChange={onChangeValue}
          isMulti={isMulti}
          defaultValue={defaultOptions}
          placeholder={placeholder}
          noOptionsMessage={() => t('common:multi-select.noOptionsMessage')}
          classNames={classNames}
          styles={multiSelectControlStyles}
          isClearable={isClearable}
        />
      )}
    />
  )
}
