import { types } from 'mobx-state-tree'
import { ModelStore } from '@shared/data-access'
import { Model, MODEL_NAME } from './model'

const API_URL = `/generic_analysis`

export const Store = ModelStore.named(`${MODEL_NAME}Store`).props({
  url: API_URL,
  map: types.map(Model),
})

export default Store
