import { useEffect, useState } from 'react'
import { BounceLoader } from 'react-spinners'

export const SrLoading = ({ loading }) => {
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (loading) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, [1000])
    }
  }, [loading])

  return (
    isLoading && (
      <div
        data-testid="Loading"
        className="fixed z-50 flex justify-center items-center w-full h-screen top-0 left-0 right-0 bottom-0"
      >
        <div className="z-50 absolute bg-modal-overlay opacity-50 w-full h-full top-0 left-0 right-0 bottom-0"></div>
        <BounceLoader
          className="z-50 absolute"
          loading={isLoading}
          color="#F46A25"
        />
      </div>
    )
  )
}
