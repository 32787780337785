import { getRoot, types } from 'mobx-state-tree'
import { RootModel } from '../core/root-model'
import { DateString } from '../types'
import {
  APPOINTMENT_MODALITY,
  APPOINTMENT_MODIFICATION_ORIGIN,
  APPOINTMENT_MODIFICATION_REASON,
  APPOINTMENT_TYPES,
} from './enums'

export const MODEL_NAME = 'BaseAppointment'

export const Model = RootModel.named(MODEL_NAME)
  .props({
    id: types.identifier,
    startDate: types.maybeNull(DateString),
    endDate: types.maybeNull(DateString),
    meetingUrl: types.maybeNull(types.string),
    visibleByClient: types.maybeNull(types.boolean, true),
    appointmentType: types.maybeNull(
      types.refinement(types.integer, (value) =>
        Object.values(APPOINTMENT_TYPES).some((type) => type === value),
      ),
    ),
    appointmentModality: types.maybeNull(
      types.refinement(types.integer, (value) =>
        Object.values(APPOINTMENT_MODALITY).some((type) => type === value),
      ),
    ),
    personId: types.maybeNull(types.string),
    socialReferentId: types.maybeNull(types.string),
    appointmentNeedsTrackingChange: types.maybeNull(types.boolean),
    appointmentModificationOrigin: types.maybeNull(
      types.refinement(types.integer, (value) =>
        Object.values(APPOINTMENT_MODIFICATION_ORIGIN).some(
          (type) => type === value,
        ),
      ),
    ),
    appointmentModificationReason: types.maybeNull(
      types.refinement(types.integer, (value) =>
        Object.values(APPOINTMENT_MODIFICATION_REASON).some(
          (type) => type === value,
        ),
      ),
    ),
  })
  .views((self) => ({
    get isInitialReport() {
      return self.appointmentType === APPOINTMENT_TYPES.INITIAL_REPORT
    },
    get isFaceToFace() {
      return self.appointmentModality === APPOINTMENT_MODALITY.faceToFace
    },
    get isInitialAnalysis() {
      return self.appointmentType === APPOINTMENT_TYPES.INITIAL_ANALYSIS
    },
    get isOnline() {
      return self.appointmentModality === APPOINTMENT_MODALITY.online
    },
    get isPhoneCall() {
      return self.appointmentModality === APPOINTMENT_MODALITY.phoneCall
    },
  }))
  .volatile((self) => ({
    _store: getRoot(self)[`${MODEL_NAME}Store`],
  }))

export default Model
