import { SectionNutritional } from '../../report-pdf/sections/nutritional'
import { SecondPage } from './components/second-page'

export const SectionFour = () => {
  return (
    <SectionNutritional sectionNumber="02">
      <SecondPage />
    </SectionNutritional>
  )
}
