import { flow, types } from 'mobx-state-tree'
import { JsonApiClient } from '../json-api-client'

const MODEL_NAME = 'VCXAuthClient'

export const Model = JsonApiClient.named(MODEL_NAME)
  .props({
    locale: types.maybeNull(types.string),
  })
  .volatile(() => ({
    onError: null,
  }))
  .actions((self) => ({
    afterCreate() {
      self.axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
      self.axios.defaults.withCredentials = true
      self.setInterceptors()
    },
    setInterceptors() {
      self.axios.interceptors.response.use(
        (response) => response,
        (error) => self.onError && self.onError(error, self),
      )
    },
    patch: flow(function* patch(url, data, options) {
      const optionsWithCookie = {
        ...options,
        headers: {
          ...options?.headers,
          'X-CSRFTOKEN': window.localStorage.getItem('csrftoken'),
        },
      }

      const response = yield self.request({
        url,
        method: 'PATCH',
        data,
        options: optionsWithCookie,
      })
      return response
    }),
    post: flow(function* post(url, data, options) {
      const optionsWithCookie = {
        ...options,
        headers: {
          ...options?.headers,
          'X-CSRFTOKEN': window.localStorage.getItem('csrftoken'),
        },
      }
      const response = yield self.request({
        url,
        method: 'POST',
        data,
        options: optionsWithCookie,
      })
      return response
    }),
    delete: flow(function* post(url, options) {
      const optionsWithCookie = {
        ...options,
        headers: {
          ...options?.headers,
          'X-CSRFTOKEN': window.localStorage.getItem('csrftoken'),
        },
      }
      const response = yield self.request({
        url,
        method: 'DELETE',
        options: optionsWithCookie,
      })
      return response
    }),
    getCsrfToken: flow(function* getCsrfToken() {
      const response = yield self.get('auth')

      if (response?.data?.data?.attributes.csrfToken) {
        window.localStorage.setItem(
          'csrftoken',
          response.data.data.attributes.csrfToken,
        )
      }
    }),
    getPublicCsrfToken: flow(function* getPublicCsrfToken() {
      const response = yield self.get('public_auth')

      if (response?.data?.data?.attributes.csrfToken) {
        window.localStorage.setItem(
          'csrftoken',
          response.data.data.attributes.csrfToken,
        )
      }
    }),
    getUserInfo: flow(function* getUserInfo() {
      const response = yield self.get('social_referents/userinfo')
      if (response?.data?.data?.attributes) {
        return response.data.data.attributes
      }
    }),
    uploadVideoToBucket: flow(function* uploadVideoToBucket({
      policyId,
      file,
      signal,
    }) {
      const signedUrl = yield self.get(
        `policies/${policyId}/get_video_upload_signedURL/`,
      )

      if (signedUrl.data.data?.fields) {
        let formDataObject = new FormData()

        formDataObject.append('key', signedUrl.data.data.fields.key)
        formDataObject.append('signature', signedUrl.data.data.fields.signature)
        formDataObject.append('policy', signedUrl.data.data.fields.policy)
        formDataObject.append(
          'awsAccessKeyId',
          signedUrl.data.data.fields.awsAccessKeyId,
        )
        formDataObject.append(
          'file',
          new Blob([file[0]], { type: 'text/plain;charset=utf-8' }),
        )

        try {
          const response = yield fetch(signedUrl.data.data.url, {
            signal,
            method: 'POST',
            body: formDataObject,
          })
          return { ok: response.ok, videoTitle: signedUrl.data.data.fields.key }
        } catch {
          return { ok: false }
        }
      } else {
        return { ok: false }
      }
    }),
    logout: flow(function* logout() {
      yield self.get('logout')
      window.localStorage.removeItem('csrftoken')
      self.removeCookie('csrftoken')
    }),
    setOnErrorCallback(callback) {
      self.onError = callback
    },
    removeCookie(name) {
      document.cookie =
        name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    },
  }))

export default Model
