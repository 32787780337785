import { SrInitialReportPdf } from '@shared/ui'
import { FC } from 'react'
import { ReportData } from '../../types'
import { ReportType } from '../../enums'

type CampaignReportProps = {
  data: ReportData
  type: ReportType
}

export const CampaignReport: FC<CampaignReportProps> = ({ data, type }) => {
  return <SrInitialReportPdf data={data} type={type} />
}
